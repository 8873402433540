import React from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { baseURL } from '../../http';
import { customAlert } from '../SweetAlertCommon/Custom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  getDeviceInfo,
  getCurrentDateTime,
  GoogleSignin,
  getLocationInfo
} from '../Common/Utilities_Function';

function UploadFromTemplate({ DBApi, close, setUpdate }) {
  console.log(DBApi)

  const endpointString = DBApi.endpoint;
  console.log(endpointString)

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
  
      // Assuming the data is present in the first sheet (index 0)
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
  
      // Get the column headers dynamically
      const columnHeaders = getDynamicColumnHeaders(worksheet);
  
      // Convert the worksheet to JSON format
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      // Get additional info from utility functions
      const deviceInfo =  getDeviceInfo();
      const currentTime = getCurrentDateTime();
      const locationInfo = await getLocationInfo();
    
  
      // Modify JSON data to include all column headers with additional info
      const modifiedData = jsonData.map(row => {
        const modifiedRow = {};
        columnHeaders.forEach(header => {
          modifiedRow[header] = row[header] || ''; // Set value from uploaded data or empty string if missing
        });
        modifiedRow.usertype = 'General'; // Set default user type to 'General'
  
        // Include additional info
        modifiedRow.Latitude = locationInfo.latitude;
        modifiedRow.Longitude = locationInfo.longitude;
        modifiedRow.Current_Location = locationInfo.locationName;
        modifiedRow.PinCode_Login = locationInfo.pincode;
        modifiedRow.last_Login_time = getCurrentDateTime();
        modifiedRow.last_login_device_name =  getDeviceInfo();
        return modifiedRow;
      });
  
      try {
        // Send the modified JSON data to the backend
        const response = await axios.post(baseURL + DBApi, modifiedData);
        console.log(response);
  
        if (response.status === 200) {
          customAlert('', 'Data uploaded successfully and removed the duplicate Users', 'success');
          setUpdate(true);
          close();
        } else {
          customAlert('', 'Error uploading data. Please check the template and database connectivity', 'error');
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          customAlert('', 'Duplicate record found. Please check and try again.', 'warning');
        } else {
          customAlert('', 'Error uploading data. Please check the template and database connectivity', 'error');
        }
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  

  const getDynamicColumnHeaders = (worksheet) => {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    const headers = [];
    let column;
    for (column = range.s.c; column <= range.e.c; column++) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: range.s.r, c: column })];
      headers.push(cell.v);
    }
    return headers;
  };

  return (
    <div>
      <input
        type="file"
        accept=".xlsx, .xls,.csv"
        onChange={handleUpload}
        style={{ display: 'none' }}
        id="upload-file"
      />
      <label htmlFor="upload-file">
        <div className="addEducationalhoverandcolor">
          <div style={{ cursor: 'pointer' }}>
            <FileUploadIcon className="addEducationaladdicon" color='primary' fontSize='large' /><br />
            <span>Upload New Users</span>
          </div>
        </div>
      </label>
    </div>
  );
}

export default UploadFromTemplate;
