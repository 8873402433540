import React, { useState, useEffect } from "react";
import TableHeaderTemplate from "../../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import WorkIcon from "@mui/icons-material/Work";
import { baseURL } from "../../../http";
import AddJobPostPage from "./AddJobPostPage";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { customAlert } from "../../SweetAlertCommon/Custom";
import "../JobPortal.css";

function JobPostMain() {
  const [searchTerm, setSearchTerm] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call

  const [numericError, setNumericError] = useState(false);
  const [jobPostData, setJobPostData] = useState({
    id: "",
    Designation: "",
    CompanyName: "",
    RequiredMinExperience: 0,
    RequiredMaxExperience: 1,
    DesignationSalaryMin: 0,
    DesignationSalaryMax: 1,

    JobLocations: [],
    MinimumOpenings: "",
    MaximumOpenings: "",
    JobResposibilities_NormalContent: "",
    JobResposibilities_HtmlContent: "",
    JobDescription_NormalContent: null,
    JobDescription_HtmlContent: null,

    IndustryType: [],
    EmploymentType: [],
    RoleCategory: "",

    RequiredKeyskills: [],
    RequiredEducation: [],
    ApplicationStartDate: "",
    ApplicationEndDate: "",
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [mode, setMode] = useState();
  const [view, setView] = useState("renderView");
  const [invalidFields, setInvalidFields] = useState({});

  const [descriptionAlert, setDescriptionAlert] = useState(false);

  const [
    roles_Responsibilities_NormalContent,
    setRoles_Responsibilities_NormalContent,
  ] = useState("");
  const [
    roles_Responsibilities_HtmlContent,
    setRoles_Responsibilities_HtmlContent,
  ] = useState("");

  const [job_Description_NormalContent, setJob_Description_NormalContent] =
    useState("");
  const [job_Description_HtmlContent, setJob_Description_HtmlContent] =
    useState("");

  const [perks_NormalContent, setPerks_NormalContent] = useState("");
  const [perks_HtmlContent, setPerks_HtmlContent] = useState("");

  //____________Fetch data when the component mounts_________________//
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/jobPost_fetch_all`);
        const data = response.data;

        // Extract column names from the data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);

        // Set the login data and filtered data
        setJobPostData(data);

        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata, id, mode) => {
    console.log("handle Edit functions", rowdata);

    setJobPostData({
      id: rowdata.id,
      CompanyName: rowdata.CompanyName,
      CompanyRating: rowdata.CompanyRating,
      RequiredMinExperience: rowdata.RequiredMinExperience,
      RequiredMaxExperience: rowdata.RequiredMaxExperience,
      Designation: rowdata.Designation,
      DesignationSalaryMin: rowdata.DesignationSalaryMin,
      DesignationSalaryMax: rowdata.DesignationSalaryMax,

      JobLocations: JSON.parse(rowdata.JobLocations),

      Posted: rowdata.Posted,
      // Openings: rowdata.Openings,
      MinimumOpenings: rowdata.MinimumOpenings,
      MaximumOpenings: rowdata.MaximumOpenings,

      Applicants: rowdata.Applicants,
      // IndustryType: rowdata.IndustryType,
      IndustryType: JSON.parse(rowdata.IndustryType),
      // EmploymentType: rowdata.EmploymentType,
      EmploymentType: JSON.parse(rowdata.EmploymentType),
      RoleCategory: rowdata.RoleCategory,
      // ExpiryDate: rowdata.ExpiryDate,
      ApplicationStartDate: rowdata.ApplicationStartDate,
      ApplicationEndDate: rowdata.ApplicationEndDate,

      RequiredKeyskills: JSON.parse(rowdata.RequiredKeyskills),
      RequiredEducation: JSON.parse(rowdata.RequiredEducation),
    });

    setRoles_Responsibilities_NormalContent(
      rowdata.JobResposibilities_NormalContent
    );
    setRoles_Responsibilities_HtmlContent(
      rowdata.JobResposibilities_HtmlContent
    );

    setJob_Description_NormalContent(rowdata.Job_Description_NormalContent);
    setJob_Description_HtmlContent(rowdata.Job_Description_HtmlContent);
    setPerks_NormalContent(rowdata.perks_NormalContent);
    setPerks_HtmlContent(rowdata.perks_HtmlContent);
    setView("addEditView");
  };

  //--------------------------------------------------------------------------------
  //Handle View function on Table
  //--------------------------------------------------------------------------------
  const handleView = (rowdata, id, mode) => {};

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setView("addEditView");
    setJobPostData({
      id: 0,
      Designation: null,
      CompanyName: null,
      RequiredMinExperience: null,
      RequiredMaxExperience: null,
      DesignationSalaryMin: null,
      DesignationSalaryMax: null,

      JobLocations: [],
      MinimumOpenings: "",
      MaximumOpenings: "",
      JobResposibilities_NormalContent: null,
      JobResposibilities_HtmlContent: null,
      JobDescription_NormalContent: null,
      JobDescription_HtmlContent: null,

      JobRole: null,
      IndustryType: [],
      Department: null,
      EmploymentType: [],
      RoleCategory: null,

      ExpiryDate: null,
      RequiredKeyskills: [],
      RequiredEducation: [],
      ApplicationStartDate: "",
      ApplicationEndDate: "",
    });

    setRoles_Responsibilities_NormalContent(null);
    setRoles_Responsibilities_HtmlContent(null);
    setJob_Description_NormalContent(null);
    setJob_Description_HtmlContent(null);
    setPerks_HtmlContent(null);
    setPerks_NormalContent(null);
  };

  //--------------------------------------------------------------------------------
  //  API to Save Data
  //--------------------------------------------------------------------------------
  const handleSave = async () => {
    const user = localStorage.getItem("HTES_user_id");
    const requiredFields = [
      "Designation",
      "CompanyName",
      "RequiredMinExperience",
      "RequiredMaxExperience",
      "DesignationSalaryMin",
      "DesignationSalaryMax",
      "JobLocations",
      "MinimumOpenings",
      "MaximumOpenings",
      "ApplicationStartDate",
      "ApplicationEndDate",
      "MaximumOpenings",
      "IndustryType",
      "EmploymentType",
      "RequiredKeyskills",
      "RequiredEducation",
      "ApplicationStartDate",
      "ApplicationEndDate",
    ];
    const parseHTML = (htmlString) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;
      return tempDiv.innerText;
    };
    const sendData = {
      id: jobPostData.id ? jobPostData.id : 0,
      Designation: jobPostData.Designation,
      CompanyName: jobPostData.CompanyName,
      RequiredMinExperience: jobPostData.RequiredMinExperience,
      RequiredMaxExperience: jobPostData.RequiredMaxExperience,
      DesignationSalaryMin: jobPostData.DesignationSalaryMin,
      DesignationSalaryMax: jobPostData.DesignationSalaryMax,

      JobLocations: jobPostData.JobLocations,
      MinimumOpenings: jobPostData.MinimumOpenings,
      MaximumOpenings: jobPostData.MaximumOpenings,

      JobResposibilities_NormalContent: parseHTML(
        roles_Responsibilities_NormalContent
      ),
      JobResposibilities_HtmlContent: roles_Responsibilities_NormalContent,

      Job_Description_NormalContent: parseHTML(job_Description_NormalContent),
      Job_Description_HtmlContent: job_Description_NormalContent,

      perks_NormalContent: parseHTML(perks_NormalContent),
      perks_HtmlContent: perks_NormalContent,

      IndustryType: jobPostData.IndustryType || [], // Ensure IndustryType is an array
      EmploymentType: jobPostData.EmploymentType || [], // Ensure EmploymentType is an array
      RequiredKeyskills: jobPostData.RequiredKeyskills || [], // Ensure RequiredKeyskills is an array
      RequiredEducation: jobPostData.RequiredEducation || [],

      ApplicationStartDate: jobPostData.ApplicationStartDate,
      ApplicationEndDate: jobPostData.ApplicationEndDate,
    };

    if (jobPostData.id > 0) {
      sendData.Modified_By = user;
    } else {
      sendData.Created_By = user;
    }

    // Validate required fields
    const newInvalidFields = {};
    const invalidFieldNames = []; // Array to collect the names of invalid fields

    for (const field of requiredFields) {
      if (
        field === "JobDescription_NormalContent" ||
        field === "JobDescription_HtmlContent"
      ) {
        continue;
      }
      if (
        !sendData[field] ||
        (Array.isArray(sendData[field]) && sendData[field].length === 0)
      ) {
        newInvalidFields[field] = true;
        invalidFieldNames.push(field);
      }
    }

    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      return;
    }
    setInvalidFields({});

    if (parseHTML(roles_Responsibilities_NormalContent) == "") {
      customAlert("", `Enter the Roles & Responsibilities`, "warning");
      setDescriptionAlert(true);
      return;
    } else {
      setDescriptionAlert(false);
    }

    if (parseHTML(job_Description_NormalContent) == "") {
      customAlert("", `Enter the Job Description`, "warning");
      setDescriptionAlert(true);
      return;
    } else {
      setDescriptionAlert(false);
    }
    if (parseHTML(perks_NormalContent) == "") {
      customAlert("", `Enter the Perks`, "warning");
      setDescriptionAlert(true);
      return;
    } else {
      setDescriptionAlert(false);
    }

    // if (numericError) {
    //   return;
    // }

    let alertMessage = jobPostData.id > 0 ? `Edit` : "Add";
    try {
      let api;
      let response;
      if (jobPostData.id > 0) {
        api = "jobPost_edit";
        response = await axios.put(
          `${baseURL}/${api}/${jobPostData.id}`,
          sendData
        );
      } else {
        api = "jobPost_insert";
        response = await axios.post(`${baseURL}/${api}`, sendData);
      }

      if (response.status === 200) {
        customAlert("", `Job Post ${alertMessage}ed`, "success");
        setView("renderView");
        SetDataBaseUpdate((prevState) => !prevState);
      } else {
        customAlert("", `API Error`, "warning");
      }
    } catch (error) {
      customAlert(
        "",
        `Server Down - while ${alertMessage}ing Job Post`,
        "error"
      );
    }
  };

  return (
    <>
      {/*_______________________________ Render view _______________________________*/}
      {view == "renderView" ? (
        <TableHeaderTemplate
          columns={renderColumns} // Columns render with default column if empty
          rows={renderRows} // Rows render
          // customMenuItems={customMenuItems} // Addition menu items
          handleEdit={handleEdit} //Handle the Edit function
          deleteApi="jobPost_delete" // Delete Api
          tableNameForExport="Post Job" // Export the Data to excel/csv (If name requred for the Excel or Csv)
          is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
          ManageButtonIcon={WorkIcon}
          handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
          Manage_button_name="Post Job" //Name of the Manage button(in center of the header)
          SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
          DefaultColumnNames={[
            "id",
            "CompanyName",
            "CompanyRating",
            "Designation",
            "JobLocations",
            "Openings",
          ]} // Add to show the columns in a default render
          // CloseActionDialog={CloseActionDialog} //Handle the action dialog close when the functionality complete(action menu container)
          switchColumnName="isApproved" // switch toggle
          swithcUpdateApi="jobPost_approve" // api name -  .put(`${baseURL}/${swithcUpdateApi}/${row.id}`
        />
      ) : (
        <div className="companyProflie_editor_page_Parent">
          {/*_______________________________ Editor view _______________________________*/}

          {/*_______________________________ Editor Header _______________________________*/}
          <div className="companyProflie_editor_page_child">
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              className="addJobEditorBackBtn"
              onClick={() => setView("renderView")}
            >
              <ArrowBackIosIcon />

              <span className="edt_saveBtn">Back</span>
            </Button>

            <div className="AddPageHeader">
              <WorkIcon className="common-icon-designs" />
              <span className="common-filter-and-sort-responsive-manage headerbtn">
                &nbsp;{jobPostData.id > 0 ? "Edit Job" : "Add Job"}
              </span>
            </div>

            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleSave}
              className="addJobEditorSaveBtn"
            >
              <SaveIcon />
              <span className="edt_saveBtn">
                {jobPostData.id > 0 ? "Update" : "Save"}
              </span>
            </Button>
          </div>
          <hr className="companyProfileHeaderSeparator" />

          {/* ______________________________ Editor Area ________________________________ */}

          <div className="companyProflie_Editor_Section">
            <AddJobPostPage
              jobPostData={jobPostData}
              setJobPostData={setJobPostData}
              SetDataBaseUpdate={SetDataBaseUpdate}
              numericError={numericError}
              setNumericError={setNumericError}
              invalidFields={invalidFields}
              setInvalidFields={setInvalidFields}
              roles_Responsibilities_NormalContent={
                roles_Responsibilities_NormalContent
              }
              setRoles_Responsibilities_NormalContent={
                setRoles_Responsibilities_NormalContent
              }
              roles_Responsibilities_HtmlContent={
                roles_Responsibilities_HtmlContent
              }
              setRoles_Responsibilities_HtmlContent={
                setRoles_Responsibilities_HtmlContent
              }
              JobDescription_NormalContent={job_Description_NormalContent}
              setJob_Description_NormalContent={
                setJob_Description_NormalContent
              }
              JobDescription_HtmlContent={job_Description_HtmlContent}
              setJob_Description_HtmlContent={setJob_Description_HtmlContent}
            
              perks_NormalContent={perks_NormalContent}
              setPerks_NormalContent={setPerks_NormalContent}
              perks_HtmlContent={perks_HtmlContent}
              setPerks_HtmlContent={setPerks_HtmlContent}
            />
          </div>
        </div>
      )}

      {/*_______________________________ Add view _______________________________*/}
    </>
  );
}

export default JobPostMain;
