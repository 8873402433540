import React, { useState, useEffect } from "react";
import { MDBCol } from "mdb-react-ui-kit";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  IconButton,
} from "@mui/material";
import { keySkillOptions } from "./PmCommonFunction";

import { customAlert } from "../SweetAlertCommon/Custom";
import { baseURL } from "../../http";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";

function PmTab4KeySkillsModal({ togglingModal, fetchStudentKeySkills, callPercentage }) {
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  //---------------------------------------------------------------------------
  // State Handles
  //---------------------------------------------------------------------------
  const [selectedOptionsKeySkills, setSelectedOptionsKeySkills] = useState([]);
  const [buttonName, setButtonName] = useState("Save");


  //---------------------------------------------------------------------------
  // Fetch Data from API
  //---------------------------------------------------------------------------
  useEffect(() => {
    fetchStudentSkillsList();
  }, []);

  const fetchStudentSkillsList = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/getStudentKeySkills`,
        { email: userEmail },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const mappedSkills = response.data.map((skill) => (
          { value: skill, label: skill, }
        ));

        // console.log(JSON.stringify("Raka"+mappedSkills))
        setSelectedOptionsKeySkills(mappedSkills);

        // if(response.data==null){ setButtonName('Save') } else { setButtonName('Update')}
      
        if (typeof response.data === 'object' && Object.keys(response.data).length === 0) {
          setButtonName('Save');
        } else { setButtonName('Update')}
      } else {
        console.error("Error fetching Keyskills by student");
      }
    } catch (error) {
      console.error("Error fetching Keyskills by student:", error);
    }
  };

  //---------------------------------------------------------------------------
  // Send Data to API
  //---------------------------------------------------------------------------
  async function sendKeySkills() {
    try {
      const updates = {
        email: userEmail,
        keyskills: selectedOptionsKeySkills.map((option) => option.value),
      };
      const response = await axios.post(
        `${baseURL}/updateStudentKeySkills`,
        updates
      );
      if (response.status === 200) {
        customAlert("", "KeySkills Updated", "success");
        fetchStudentKeySkills();
        callPercentage();
        togglingModal();
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
  
  return (
    <div>
      <div className="AddKeyskillContainer">
        <>
          {/* ___________________________________ Header _______________________________ */}
          <h5 className="pmTabHeadings">Key Skills</h5>
          <IconButton
            id="button-zoom"
            className="modal-close-iconButton-right-top-corner"
            onClick={togglingModal}
          >
            <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
          </IconButton>
          <p className="modalHeader">
            Tell recruiters what you know or what you are known for, e.g.,
            Direct Marketing, Oracle, Java, etc. We will send you job
            recommendations based on these skills.
          </p>
          <br />
        </>

        {/* ________________ Select Skills (Calling Component with Props) _____________ */}
        <MDBCol>
          <CustomMultiSelect
            options={keySkillOptions}                       // send dropdown options
            selectedOption={selectedOptionsKeySkills}       // for value prop
            setSelectedOption={setSelectedOptionsKeySkills} // for onChange prop
            // chipClassName="pmKeySkillChips"
             chipClassName="jobPostLocationtag"
          chipDeleteClassName="jobPostLocationDeleteX"
          placeholder="Type to Select KeySkill(s)"
          />

        </MDBCol>
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          className="saveButton"
          color="success"
          onClick={sendKeySkills}
        >
          <SaveIcon className="buttonicons" />
          {buttonName}
        </Button>
      </div>
      </div>

    </div>
  );
}

export default PmTab4KeySkillsModal;

