import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import QuestionBankTemplates from "./QuestionBankTemplates";
import AddtoQuestionBank from "./AddtoQuestionBank";

function QuestionBankLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, seteditData] = useState(false);

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/getAllAiGeneratedQuestions`
        );
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    seteditData(rowdata);
    setopenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setopenEditModal(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    seteditData("");
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      {!openEditModal && (
        <TableHeaderTemplate
          columns={renderColumns} // Columns render with default column if empty
          rows={renderRows} // Rows render
          handleEdit={handleEdit} //Handle the Edit function
          deleteApi="deletequestionquestionbank" // Delete Api
          tableNameForExport="Question_Bank" // Export the Data to excel/csv (If name requred for the Excel or Csv)
          is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
          handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
          Manage_button_name="Manage Table" //Name of the Manage button(in center of the header)
          switchColumnName="isAdminApproved" // Swithc required in the Table(one of the column)
          swithcUpdateApi="updatethequestions" // Api to handle the Toggle of the swithc
          SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
          DefaultColumnNames={["QuestionType", "Questions", "Answer"]} // Add to show the columns in a default render
          CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
        />
      )}
      {/* _______________________End_____________________________ */}

      {/* ______________Additional functionality template modal________ */}
      <QuestionBankTemplates
        openModal={openModal}
        handleModalClose={handleModalClose}
        columns={renderColumns}
        update1={SetDataBaseUpdate}
        renderRows={renderRows}
        setopenEditModal={setopenEditModal}
      />

      {/* _______________Edit the modal ___________ */}
      {openEditModal && (
        <AddtoQuestionBank
          onClose={handleCloseEditModal}
          update1={SetDataBaseUpdate}
          editData={editData}
          renderRows={renderRows}
        />
      )}
    </div>
  );
}

export default QuestionBankLandingPage;
