import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function DownloadTemplate() {
  const [anchorEl, setAnchorEl] = useState(null);

  const columns = [
    'name',
    'email',
    'password',
    'mobile',
  ]

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async (format) => {
    try {
      // Fetch data to get column names
      // This assumes you have already fetched the column names and stored them in the 'columns' prop
      const columnNames = columns.map((column) => column);
  
      // Create a new workbook
      const wb = XLSX.utils.book_new();
  
      // Create the first worksheet with column names
      const ws1 = XLSX.utils.aoa_to_sheet([columnNames]);
      XLSX.utils.book_append_sheet(wb, ws1, "Template");
  
      // Create the second worksheet with an example row
      
      const exampleRow = ["Vinod", "xyz@gmail.com", "xyz", "990756XXXXX"];
      const exampleRow1 = ["Manoj", "ryz@gmail.com", "xyz", "990756XXXXX"]; // Example data
      const ws2 = XLSX.utils.aoa_to_sheet([columnNames,exampleRow,exampleRow1]);
      //const ws2 = XLSX.utils.aoa_to_sheet([exampleRow]);
      XLSX.utils.book_append_sheet(wb, ws2, "Example");
  
      // Convert workbook to binary string
      const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
  
      // Convert binary string to Blob
      const blob = new Blob([s2ab(wbBinary)], {
        type: "application/octet-stream",
      });
  
      // Determine the file extension based on the selected format
      const extension = format === "csv" ? "csv" : "xlsx";
  
      // Save Blob as file
      saveAs(blob, `template.${extension}`);
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };
  

  // Convert string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
    <div className="addEducationalhoverandcolor">
      <div   onClick={handleMenuOpen}>
        <FileDownloadIcon
          className="addEducationaladdicon"
          fontSize="large"
          color="primary"
        />
        <br></br>
        <span >Dowload Template</span>
      </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ marginTop: "-50px" }}
      >
        <MenuItem onClick={() => handleDownload("csv")}>
          Download as CSV
        </MenuItem>
        <MenuItem onClick={() => handleDownload("excel")}>
          Download as Excel
        </MenuItem>
      </Menu>
    </>
  );
}

export default DownloadTemplate;
