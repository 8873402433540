import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Modal, TextField, Button, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update"; // Import the appropriate icon

import "./review.css";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";

function ReviewAddandEdit({
  isModalOpen,
  setIsModalOpen,
  SetDataBaseUpdate,
  editData,
  setEditData,
}) {
  //--------------------------------------------------------------------------------
  //Default formdata seting after the update and add
  //--------------------------------------------------------------------------------

  const defaultFormData = {
    Name: "",
    Designation: "",
    Description: "",
    Image: null,
    Created_By: localStorage.getItem("HTES_user_id"),
    Created_date: new Date().toISOString(),
    Modified_By: localStorage.getItem("HTES_user_id"),
    Modified_date: new Date().toISOString(),
    IsApproved: "0",
  };

  //--------------------------------------------------------------------------------
  //States
  //--------------------------------------------------------------------------------
  const [formData, setFormData] = useState(defaultFormData);
  const [isHovered, setIsHovered] = useState(false);
  const [errors, setErrors] = useState({});
  const imageRef = useRef(null);

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit to prefill data
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      setFormData({
        Name: editData.Name || "",
        Designation: editData.Designation || "",
        Description: editData.Description || "",
        Image: editData.Image || null,
        Modified_By:
          editData.Modified_By || localStorage.getItem("HTES_user_id"),
        Modified_date: editData.Modified_date || new Date().toISOString(),
      });
    }
  }, [editData, isModalOpen]);

  //--------------------------------------------------------------------------------
  //Open the Image add
  //--------------------------------------------------------------------------------

  const handleEditClick = () => setIsModalOpen(true);

  //--------------------------------------------------------------------------------
  //close the Modal
  //--------------------------------------------------------------------------------
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(defaultFormData);
    setEditData("");
  };

  //--------------------------------------------------------------------------------
  //Handle the input change in the text field
  //--------------------------------------------------------------------------------

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //--------------------------------------------------------------------------------
  //Handle the image change and save as a data url
  //--------------------------------------------------------------------------------
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, Image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  //--------------------------------------------------------------------------------
  //Handle delete the The image
  //--------------------------------------------------------------------------------
  const handleDeleteClick = () => {
    setFormData({ ...formData, profileImage: null });
  };

  //--------------------------------------------------------------------------------
  //Validataion for the Name and Descripretions
  //--------------------------------------------------------------------------------
  const validateForm = () => {
    const newErrors = {};
    if (!formData.Name) newErrors.Name = "Name is required";
    if (!formData.Description)
      newErrors.Description = "Description is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //--------------------------------------------------------------------------------
  //For handel the both the Edit and Add
  //--------------------------------------------------------------------------------
  const handleSave = async () => {
    if (validateForm()) {
      try {
        if (!editData) {
          const updatedFormData = {
            ...formData,
            Created_By: localStorage.getItem("HTES_user_id"),
            Created_date: new Date().toISOString(),
            Modified_By: localStorage.getItem("HTES_user_id"),
            Modified_date: new Date().toISOString(),
          };
          const response = await axios.post(
            baseURL + "/review",
            updatedFormData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          customAlert("", "Review Added Successfully", "success");
        } else {
          const updatedFormData = {
            ...formData,

            Modified_By: localStorage.getItem("HTES_user_id"),
            Modified_date: new Date().toISOString(),
          };
          const response = await axios.put(
            baseURL + `/review_edit/${editData.id}`,
            updatedFormData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          customAlert("", "Review Updated Successfully", "success");
        }
        SetDataBaseUpdate(true);
        handleCloseModal();
      } catch (error) {
        console.error(
          "Error saving data:",
          error.response?.data || error.message
        );
        // Handle errors here (e.g., display error messages)
      }
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        className="review-and-edit-modal-outside-container"
      >
        <div className="review-modal-container">
          <IconButton
            id="button-zoom"
            className="review-modal-close-button"
            onClick={handleCloseModal}
          >
            <CloseIcon className="review-modal-close-button-x" />
          </IconButton>

          <h2 className="modal-heading">
            {editData ? "Edit Review" : "Add Review"}
          </h2>
          {/* ______________Image section______________ */}
          <div className="all-content-section-scroll-conatiner">
            <div className="image-section">
              <p className="image-add-section-heading">Image</p>
              <div
                className="image-container"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {formData.Image && (
                  <img
                    src={formData.Image} // Use Data URL directly
                    alt=""
                    onClick={handleEditClick}
                    className="profile-image"
                  />
                )}
                {(isHovered || !formData.Image) && (
                  <div className="image-edit-delete-buttons">
                    <Tooltip
                      title={`${!formData.Image ? "Add Image" : "Edit Image"}`}
                      arrow
                    >
                      <IconButton
                        className="review-delete-and-edit-icon-background-color"
                        onClick={() => imageRef.current.click()}
                      >
                        {!formData.Image ? (
                          <AddIcon className="review-add-icon-color" />
                        ) : (
                          <EditIcon className="review-edit-icon-color" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <input
                      type="file"
                      accept="image/*"
                      ref={imageRef}
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    {formData.Image && (
                      <Tooltip title="Delete Image" arrow>
                        <IconButton
                          className="review-delete-and-edit-icon-background-color"
                          onClick={handleDeleteClick}
                        >
                          <DeleteIcon className="review-delete-icon-color" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* ______________end______________ */}
            {/* ______________Text fileds ______________ */}
            <TextField
              label="Name"
              name="Name"
              fullWidth
              value={formData.Name}
              onChange={handleInputChange}
              margin="normal"
              error={!!errors.Name}
              helperText={errors.Name}
            />
            <TextField
              label="Designation"
              name="Designation"
              fullWidth
              value={formData.Designation}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              label="Description"
              name="Description"
              fullWidth
              multiline
              rows={5}
              value={formData.Description}
              onChange={handleInputChange}
              margin="normal"
              error={!!errors.Description}
              helperText={errors.Description}
            />
          </div>
          {/* ___________________end____________________ */}
          {/* ___________________Button Sections_________________ */}
          <div className="modal-buttons">
            <Button
              variant="contained"
              color="success"
              onClick={handleSave}
              id="button-zoom"
            >
              {editData ? (
                <UpdateIcon className="buttonicons" />
              ) : (
                <SaveIcon className="buttonicons" />
              )}
              {editData ? "Update" : "Save"}
            </Button>
            {/* ___________________end____________________ */}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ReviewAddandEdit;
