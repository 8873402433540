import React, { useState, useEffect } from "react";
import { TextField, Modal, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import SaveIcon from "@mui/icons-material/Save";
import "./ExamPattern.css";

function ExamPatternName({ open, handleClose, setPatternName, editData }) {
  //--------------------------------------------------------------------------------
  // Local state to manage the exam pattern name
  //--------------------------------------------------------------------------------
  const [patternName, setPatternNameState] = useState(
    editData.PatternName || ""
  );
  //--------------------------------------------------------------------------------
  // Effect to set the pattern name if editData is provided
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      setPatternNameState(editData.PatternName || ""); // Assume editData has a property named patternName
    } else {
      setPatternNameState(""); // Reset when not in edit mode
    }
  }, [editData]);
  //--------------------------------------------------------------------------------
  // Function to handle save
  //--------------------------------------------------------------------------------
  const handleSave = () => {
    setPatternName(patternName); // Set the pattern name in parent
    console.log("Saving new pattern name:", patternName);
    handleClose(); // Close the modal after saving
  };
  //--------------------------------------------------------------------------------
  // Function to handle update
  //--------------------------------------------------------------------------------
  const handleUpdate = () => {
    setPatternName(patternName); // Update the pattern name in parent
    // Logic to update the existing pattern name (e.g., API call)
    console.log("Updating pattern name:", patternName);
    handleClose(); // Close the modal after updating
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modelcontainer">
        <div className="examPatternModalBox">
          {/* _________Header____________ */}
          <div className="headerofthemodel">
            <h5 className="exam-pattern-header-name">
              {editData ? "Update" : "Add"} Test Pattern Name
            </h5>
          </div>
          <IconButton
            id="button-zoom"
            onClick={handleClose}
            className="addeducationalMapmodalcancel"
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>
          {/* _____________Textfield_________________ */}
          <div>
            <TextField
              id="outlined-basic"
              label="Exam Pattern Name"
              variant="outlined"
              fullWidth
              value={patternName} // Bind the TextField value to local state
              onChange={(e) =>
                setPatternNameState(e.target.value.toUpperCase())
              } // Update local state on change
            />
          </div>

          {/* ___________________Button sections______________ */}
          <div className="buttoncontainerexampattern">
            {editData ? ( // Conditional rendering based on editData
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleUpdate}
              >
                <UpdateIcon className="buttonicons" />
                &nbsp;Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleSave}
              >
                <SaveIcon className="buttonicons" />
                &nbsp;Save
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ExamPatternName;
