import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  IconButton,
  Tooltip,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import "./coursetemplate2.css"; // Ensure your CSS file is imported

const Coursetemplate2Tags = ({
  tags,
  isChecked,
  courseTags,
  setCourseTags,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isExpand, setIsExpand] = useState(true);

  //--------------------------------------------------------------------------------
  // Toggle expand/collapse (when clikc the icons)
  //--------------------------------------------------------------------------------

  const handleExpand = () => {
    setIsExpand((prev) => !prev);
  };

  //--------------------------------------------------------------------------------
  // Handle category selection
  //--------------------------------------------------------------------------------

  const handleCategorySelect = (event, newValue) => {
    setCourseTags(newValue); // Store selected tags in courseTags
  };

  //--------------------------------------------------------------------------------
  // Add new category
  //--------------------------------------------------------------------------------

  const handleAddOption = (value) => {
    if (value && !tags.includes(value)) {
      setCourseTags([...courseTags, value]);
      tags.push(value);
    }
  };

  //--------------------------------------------------------------------------------
  // Remove selected category chip
  //--------------------------------------------------------------------------------

  const handleRemoveCategory = (category) => {
    const updatedCategories = courseTags.filter((cat) => cat !== category);
    setCourseTags(updatedCategories); // Update courseTags when removing a category
  };

  //--------------------------------------------------------------------------------
  // Toggle expand/collapse(auto after select and click ok)
  //--------------------------------------------------------------------------------
  const toggleAutocomplete = () => {
    setIsAdding(!isAdding);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            isExpand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpand}
            >
              <h6 className="coursetemplete2-title">Course Tags</h6>
              <div>
                <IconButton>
                  {isExpand ? (
                    <ExpandLessIcon className="course-template-expand-icon-color" />
                  ) : (
                    <ExpandMoreIcon className="expand-icon-color" />
                  )}
                </IconButton>
                {isChecked && (
                  <Tooltip title={isAdding ? "Close" : "Add Category"} arrow>
                    <IconButton onClick={toggleAutocomplete}>
                      {isAdding ? (
                        <CloseIcon className="courseTemplate2-edit-icon-color" />
                      ) : (
                        <AddIcon className="courseTemplate2-edit-icon-color" />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          {/* ______________________________Auto complete text field_____________ */}
          {isAdding && (
            <div>
              <Autocomplete
                disableClearable
                multiple
                options={tags}
                value={courseTags}
                onChange={handleCategorySelect}
                disableCloseOnSelect
                renderTags={() => null} // Disable default tags rendering
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select or Add Category"
                    variant="outlined"
                    fullWidth
                    onClick={(event) => event.target.focus()} // This ensures dropdown opens on click
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {params.inputProps.value &&
                            !tags.includes(params.inputProps.value) && ( // Check if value is not in options
                              <Tooltip title="Add New Category" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleAddOption(params.inputProps.value)
                                  }
                                >
                                  <AddIcon className="FAQ-addicon-design-for-icon" />
                                </IconButton>
                              </Tooltip>
                            )}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          )}
          {/* ______________________________Chip render Section____________ */}
          {isExpand && (
            <div className="selected-categories-container">
              {courseTags.map((category, index) => (
                <Chip
                  key={index}
                  className="coursetemplate2-chip-background-color"
                  label={category}
                  onDelete={() => handleRemoveCategory(category)}
                />
              ))}
            </div>
          )}
          {/* ______________________________end_________________________ */}
        </div>
      </div>
    </>
  );
};

export default Coursetemplate2Tags;
