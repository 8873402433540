import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import Rating from "@mui/material/Rating";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  Button,
  Chip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import "./coursecreatetemplate.css";

const HeaderTab = ({
  courseNamesDropdown,
  selectedCourse,
  tags,
  courseFields,
  setCourseFields,
  isEditing,
  setIsEditing,
}) => {
  const [durationUnit, setDurationUnit] = useState("Hours");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAllEditIcons, setShowAllEditIcons] = useState(false);

  //--------------------------------------------------------------------------------
  // Handle Edit function for each filed edit icon click
  //--------------------------------------------------------------------------------
  const handleEditClick = (field) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  //--------------------------------------------------------------------------------
  //Handle save function for each filed save icon click
  //--------------------------------------------------------------------------------
  const handleSaveClick = (field) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  //--------------------------------------------------------------------------------
  // Handle the text chnage in the text fieds
  //--------------------------------------------------------------------------------
  const handleChange = (field, value) => {
    setCourseFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  //--------------------------------------------------------------------------------
  // Handle the houes weeks, monts dropdown
  //--------------------------------------------------------------------------------
  const handleUnitChange = (event) => {
    setDurationUnit(event.target.value);
  };

  //--------------------------------------------------------------------------------
  // Separate Function for the fileds have same smilarity
  //--------------------------------------------------------------------------------
  const renderField = (
    label,
    field,
    icon,
    isTextField = true,
    isMultiLine = false
  ) => (
    <div className="Header-tab-course-name">
      <div className="course-name-and-icon-align">
        <h6 className="make-header-of-the-container-color">{label}</h6>
        <div className="headertab-icon-alignmet">
          
            {showAllEditIcons || isEditing[field] ? (
              isEditing[field] ? (
            <Tooltip title="Save" arrow>
              <IconButton>
                <SaveIcon
                  onClick={() => handleSaveClick(field)}
                  className="buttoncolorsave"
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Edit" arrow>
              <IconButton>
                <EditIcon
                  onClick={() => handleEditClick(field)}
                  className="buttoncolorEdit"
                />
              </IconButton>
            </Tooltip>
          )):null}
        </div>
      </div>
      {isEditing[field] ? (
        isTextField ? (
          <TextField
            placeholder={`Enter ${label}`}
            value={courseFields[field]}
            onChange={(event) => handleChange(field, event.target.value)}
            variant="outlined"
            fullWidth
            multiline={isMultiLine}
            type="number"
            inputProps={field !== "courseTagLine" ? { min: 1 } : undefined}
          />
        ) : (
          <Select
            value={courseFields[field]}
            onChange={(event) => handleChange(field, event.target.value)}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="Online">Online</MenuItem>
            <MenuItem value="Offline">Offline</MenuItem>
            <MenuItem value="Hybrid">Hybrid</MenuItem>
          </Select>
        )
      ) : (
        <h5 className="header-name-text-color">
          {icon}
          {courseFields[field] || "NA"}
        </h5>
      )}
    </div>
  );

  //--------------------------------------------------------------------------------
  // Handle the image change
  //--------------------------------------------------------------------------------

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCourseFields((prevFields) => ({
          ...prevFields,
          courseImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  return (
    <>
      {/* _____________________Course Name,Image, Duration,teaching mode grid block_______ */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className="Header-tab-course-name">
            <div className="course-name-and-icon-align">
              <h6 className="make-header-of-the-container-color">
                *Course Name
              </h6>
              <div className="headertab-icon-alignmet">
              {showAllEditIcons || isEditing.courseName ? (
                  isEditing.courseName ? (
                  <Tooltip title="Save" arrow>
                    <IconButton onClick={() => handleSaveClick("courseName")}>
                      <SaveIcon
                       
                        className="buttoncolorsave"
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit" arrow>
                    <IconButton>
                      <EditIcon
                        onClick={() => handleEditClick("courseName")}
                        className="buttoncolorEdit"
                      />
                    </IconButton>
                  </Tooltip>
                 )
                ) : null}
              </div>
            </div>
            {isEditing.courseName ? (
              <Autocomplete
                disableClearable
                options={courseNamesDropdown}
                value={courseFields.courseName}
                onChange={(event, newValue) =>
                  handleChange("courseName", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Course Name"
                    variant="outlined"
                  />
                )}
              />
            ) : (
              <h5 className="header-name-text-color-alignment">
                {courseFields.courseName || "NA"}
              </h5>
            )}
          </div>
          <div className="Header-tab-course-name">
            <div className="course-name-and-icon-align">
              <h6 className="make-header-of-the-container-color">
                *Duration of Course
              </h6>
              <div className="headertab-icon-alignmet">
              {showAllEditIcons || isEditing.durationOfCourse ? (
                  isEditing.durationOfCourse ? (
                  <Tooltip title="Save" arrow>
                    <IconButton>
                      <SaveIcon
                        onClick={() => handleSaveClick("durationOfCourse")}
                        className="buttoncolorsave"
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit" arrow>
                    <IconButton>
                      <EditIcon
                        onClick={() => handleEditClick("durationOfCourse")}
                        className="buttoncolorEdit"
                      />
                    </IconButton>
                  </Tooltip>
                )
              ):null}
              </div>
            </div>
            {isEditing.durationOfCourse ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  required
                  placeholder="Duration of Course"
                  value={courseFields.durationOfCourse}
                  onChange={(event) =>
                    handleChange("durationOfCourse", event.target.value)
                  }
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 0 }}
                  
                />
                <FormControl variant="outlined">
                  <Select
                    value={durationUnit}
                    onChange={handleUnitChange}
                    style={{ minWidth: 100 }}
                  >
                    <MenuItem value="Hours">Hours</MenuItem>
                    <MenuItem value="Weeks">Weeks</MenuItem>
                    <MenuItem value="Months">Months</MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : (
              <h5 className="header-name-text-color">
                <AccessTimeIcon className="buttonicons-Headertab" />{" "}
                {courseFields.durationOfCourse
                  ? `${courseFields.durationOfCourse} ${durationUnit}`
                  : "NA"}
              </h5>
            )}
          </div>
          <div>
            {renderField(
              "Teaching Mode",
              "teachingMode",
              <ModelTrainingIcon className="buttonicons-Headertab" />,
              false
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="Header-tab-course-name">
            <div className="course-name-and-icon-align">
              <h6 className="make-header-of-the-container-color">
                *Course Image
              </h6>
              <div className="headertab-icon-alignmet">
              {showAllEditIcons || isEditing.courseImage ? (
                  isEditing.courseImage ? (
                  <Tooltip title="Save" arrow>
                    <IconButton>
                      <SaveIcon
                        onClick={() => handleSaveClick("courseImage")}
                        className="buttoncolorsave"
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit" arrow>
                    <IconButton>
                      <EditIcon
                        onClick={() => handleEditClick("courseImage")}
                        className="buttoncolorEdit"
                      />
                    </IconButton>
                  </Tooltip>
                )):null}
              </div>
            </div>
            {isEditing.courseImage ? (
              <div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" color="success" component="span">
                    Choose Image
                  </Button>
                </label>
                {selectedImage && (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "227px",
                        height: "98%",
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                {courseFields.courseImage ? (
                  <img
                    src={courseFields.courseImage}
                    alt="Course"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "227px",
                      height: "98%",
                    }}
                  />
                ) : (
                  <h5 className="header-name-text-color-alignment">NA</h5>
                )}
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      {/* ___________________________end__________________________________ */}

      {/* ___________________________call the Function for Course Tag line__________________________________ */}
      {renderField("*Course Tag Line", "courseTagLine", null, true, true)}
      {/* ___________________________end__________________________________ */}

      {/* ___________________________After the course tag line fileds__________________________________ */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          {renderField(
            "Price of Course",
            "priceOfCourse",
            <CurrencyRupeeIcon className="buttonicons-Headertab" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {renderField(
            "Number of Student Enrolled",
            "numberOfStudentEnrolled",
            <PersonIcon className="buttonicons-Headertab" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <div className="Header-tab-course-name">
            <div className="course-name-and-icon-align">
              <h6 className="make-header-of-the-container-color">
                Google Ratings
              </h6>
              <div className="headertab-icon-alignmet">
              {showAllEditIcons || isEditing.googleRatings ? (
                  isEditing.googleRatings ? (
                  <Tooltip title="Save" arrow>
                    <IconButton>
                      <SaveIcon
                        onClick={() => handleSaveClick("googleRatings")}
                        className="buttoncolorsave"
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit" arrow>
                    <IconButton>
                      <EditIcon
                        onClick={() => handleEditClick("googleRatings")}
                        className="buttoncolorEdit"
                      />
                    </IconButton>
                  </Tooltip>
                )):null}
              </div>
            </div>
            {isEditing.googleRatings ? (
              <TextField
                placeholder="Enter Google Ratings"
                value={courseFields.googleRatings}
                onChange={(event) =>
                  handleChange("googleRatings", event.target.value)
                }
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{ min: 0, max: 5 }}
              />
            ) : (
              <Rating
                name="simple-controlled"
                value={courseFields.googleRatings}
                readOnly
                precision={0.5}
              />
            )}
          </div>
        </Grid>
        {/* ___________________________End__________________________________ */}
        {/* ___________________________Course tag__________________________________ */}
        <Grid item xs={12} md={12}>
          <div className="Header-tab-course-name">
            <div className="course-name-and-icon-align">
              <h6 className="make-header-of-the-container-color">
                Course Tags
              </h6>
              <div className="headertab-icon-alignmet">
              {showAllEditIcons || isEditing.courseTags ? (
                  isEditing.courseTags ? (
                  <Tooltip title="Save" arrow>
                    <IconButton>
                      <SaveIcon
                        onClick={() => handleSaveClick("courseTags")}
                        className="buttoncolorsave"
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit" arrow>
                    <IconButton>
                      <EditIcon
                        onClick={() => handleEditClick("courseTags")}
                        className="buttoncolorEdit"
                      />
                    </IconButton>
                  </Tooltip>
                )):null}
              </div>
            </div>
            {isEditing.courseTags ? (
              <div>
                <Autocomplete
                  multiple
                  disableClearable
                  options={tags || []}
                  value={courseFields.courseTags}
                  onChange={(event, newValue) => {
                    const uniqueTags = newValue.filter(
                      (tag, index) => newValue.indexOf(tag) === index
                    );
                    handleChange("courseTags", uniqueTags);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        className="chip-background-color"
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Add Course Tags"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                            {!tags.includes(params.inputProps.value) &&
                              params.inputProps.value && (
                                <Tooltip title="Add" arrow>
                                  <IconButton
                                    onClick={() => {
                                      handleChange("courseTags", [
                                        ...courseFields.courseTags,
                                        params.inputProps.value,
                                      ]);
                                    }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            ) : (
              <div>
                {courseFields.courseTags.length > 0 ? (
                  courseFields.courseTags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      className="chip-background-color"
                      style={{ marginRight: "5px", marginBottom: "5px" }}
                    />
                  ))
                ) : (
                  <h5 className="header-name-text-color-alignment">NA</h5>
                )}
              </div>
            )}
          </div>
          {/* ___________________________end__________________________________ */}
        </Grid>
      </Grid>
      <div className="check-box-design-select-all">
      
            <Checkbox
            className="checkbox-alignment-and-size-controll"
              checked={showAllEditIcons}
              onChange={(e) => setShowAllEditIcons(e.target.checked)}
            />
         
          Show All Edit Icons
     
      </div>
    </>
  );
};

export default HeaderTab;
