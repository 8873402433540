import React, { useState } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NearMeIcon from "@mui/icons-material/NearMe";

function EmailEnterModal({
  handleModalClose,
  email,
  setEmail,
  open,
  handleSubmit,
  emailError,
  setEmailError,
}) {
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear error when user starts typing
  };

  const handleModalCloseAndNavigate = () => {
    try {
      // Attempt to close the current tab
      window.close();
    } catch (e) {
      console.error("Error attempting to close the tab:", e);
    }
    // Optionally navigate to another route if needed, though this is redundant if closing the tab
    // navigate('/desired-path');
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <div className="mail-container">
        <div className="mail-modal-heading-alignment">
          <h5>Please Add Your UserMailId</h5>
        </div>

        <IconButton
          id="button-zoom"
          className="employeemodalcancel"
          onClick={handleModalCloseAndNavigate}
        >
          <CloseIcon className="employeemodalcancelX" />
        </IconButton>

        <div className="email-input-container">
          <TextField
            label="Enter User MailId"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleEmailChange}
            type="email"
            required
            error={!!emailError}
            helperText={emailError}
          />
        </div>

        <div className="email-submit-container">
          <Button
            variant="contained"
            color="success"
            onClick={handleSubmit}
            id="button-zoom"
          >
            <NearMeIcon className="buttonicons" />
            &nbsp;Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EmailEnterModal;
