import React, { useState, useEffect, useRef } from "react";
import ToolBar from "./ToolBar";
import "./Editor.css";
import TextField from "@mui/material/TextField";
import CodeViewer from "./CodeViewer";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Autocomplete } from "@mui/material";
import { IconButton, Chip, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const Swal = require("sweetalert2");

function Editor1({
  Button_Name = "Save",
  isHeadingRequird = true,
  is_tag_required = true,
  tag_value = null,
  onSave,
  editID,
  idProp,
  htmlContentProp,
  showHyperlink = true,
  showImageInsert = true,
  showCodeViewer = true,
  showButtonInsert = true,
  setContent,
  showButton = false,
  label = "Tag",
  isAutocomplete = true,
  inputValue,
  selectedTag,
  setSelectedTag,
  showAddIcon,
  handleInputChange_tag,
  handleAddNewItem,
  tagOptions,
  headingValue,
  setHeadingValue,
  header_label,
  content, 
  mobileHeight,
  desktopHeight,
}) {
  //-------------------------------------------------------------------------
  // Props Unfold
  //-------------------------------------------------------------------------
  // const [headingValue, setHeadingValue] = useState(Heading_value || "");
  // const [content, setContent] = useState(Content_Value || "");
  const [Tag_value, setTag_value] = useState(tag_value || "");
  // const [output_content, setOutputContent] = useState("");
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState("select");
  const [codeString, setCodeString] = useState("");
  const [contextMenu, setContextMenu] = useState(null);
  const [currentCodeViewer, setCurrentCodeViewer] = useState(null);
  const [mode, setMode] = useState("add");

  const [isInitialized, setIsInitialized] = useState(false);

  const Editor_hight = {
    height: window.innerWidth <= 768 ? mobileHeight : desktopHeight,
  };

  //-------------------------------------------------------------------------
  // State Handle
  //-------------------------------------------------------------------------
  const contentEditorRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ x: 0, y: 0 });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#000");
  const [selectionRange, setSelectionRange] = useState(null);
  const [currentProperty, setCurrentProperty] = useState(null);
  const colorPickerRef = useRef(null);

  //-------------------------------------------------------------------------
  //Paste Handle in content Editor
  //-------------------------------------------------------------------------
  const handlePaste = (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    const editor = document.getElementById("Content-editor");
    let imagePasted = false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.kind === "file" && item.type.indexOf("image") !== -1) {
        e.preventDefault();
        const file = item.getAsFile();
        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const container = document.createElement("div");
            if (!imagePasted) {
              container.className = "editor-image-container";
              container.contentEditable = false;
              container.style.position = "relative";
              container.style.userSelect = "none";
              container.style.resize = "both";
              img.addEventListener("dragstart", (e) => {
                e.preventDefault();
              });
              container.appendChild(img);
              container.addEventListener("click", () => {
                handleImageClick(container);
              });

              const selection = window.getSelection();
              if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const commonAncestor = range.commonAncestorContainer;

                const ancestorElement =
                  commonAncestor.nodeType === Node.ELEMENT_NODE
                    ? commonAncestor
                    : commonAncestor.parentElement;
                if (
                  ancestorElement &&
                  ancestorElement.closest(".code-viewer-container")
                ) {
                  console.warn(
                    "Selection is within a code viewer container. Image paste is not allowed."
                  );
                  return;
                }

                if (editor && editor.contains(commonAncestor)) {
                  range.deleteContents();
                  range.insertNode(container);
                  range.setStartAfter(container);
                  range.collapse(true);
                  selection.removeAllRanges();
                  selection.addRange(range);
                } else {
                  console.warn("Selection is not within the content editor.");
                }
              } else {
                console.error("No valid selection range found.");
              }
              imagePasted = true;
            }
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            range.deleteContents();
            range.insertNode(container);
            range.setStartAfter(container);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
          };
        };
        reader.readAsDataURL(file);
      }
    }
  };

  //-------------------------------------------------------------------------
  //handle Insert Image  in content Editor
  //-------------------------------------------------------------------------
  const handleImageInsert = (e) => {
    e.preventDefault();
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = document.createElement("img");
        img.src = e.target.result;
        const imgContainer = document.createElement("div");
        imgContainer.className = "editor-image-container";
        imgContainer.contentEditable = false;
        imgContainer.style.position = "relative";
        imgContainer.style.userSelect = "none";
        imgContainer.style.resize = "both";
        imgContainer.appendChild(img);
        imgContainer.addEventListener("click", () => {
          handleImageClick(imgContainer);
        });

        if (contentEditorRef.current) {
          const selection = window.getSelection();
          if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const contentEditor = document.getElementById("edt");
            const commonAncestor = range.commonAncestorContainer;

            const ancestorElement =
              commonAncestor.nodeType === Node.ELEMENT_NODE
                ? commonAncestor
                : commonAncestor.parentElement;
            if (
              ancestorElement &&
              ancestorElement.closest(".code-viewer-container")
            ) {
              return;
            }
            if (contentEditor && contentEditor.contains(commonAncestor)) {
              range.deleteContents();
              range.insertNode(imgContainer);
              range.setStartAfter(imgContainer);
              range.collapse(true);
              selection.removeAllRanges();
              selection.addRange(range);
            } else {
              console.warn("Selection is not within the content editor.");
            }
          }
        } else {
          console.error("contentEditorRef.current is null");
        }
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  //-------------------------------------------------------------------------
  //Content_Editor_handleClick - Toolbar Disappear
  //-------------------------------------------------------------------------
  const Content_Editor_handleClick = (event) => {
    setShowToolbar(false);
  };

  //-------------------------------------------------------------------------
  //contentRightClickToolbar
  //-------------------------------------------------------------------------
  const Content_Editor_handleContextMenu = (event) => {
    event.preventDefault();
    const selection = window.getSelection();
    const target = event.target;

    //-------------------------------------------------------------------------
    //ContextMenuForImage
    //-------------------------------------------------------------------------
    if (target.nodeName === "IMG") {
      let imageLink = target.closest("a") ? target.closest("a").href : null;

      const dialog = document.createElement("div");
      dialog.classList.add("dialog");

      //-------------------------------------------------------------------------
      //Option 1: Add hyperlink
      //-------------------------------------------------------------------------
      const addLinkOption = createDialogItem("Add Hyperlink", "fa-link", () => {
        let url = prompt("Enter the URL:");
        if (url) {
          if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = "http://" + url;
          }

          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          target.replaceWith(link);
          link.appendChild(target);

          imageLink = url;
        }
        dialog.remove();
      });

      //-------------------------------------------------------------------------
      //Option 2: Delete image
      //-------------------------------------------------------------------------
      const deleteImageOption = createDialogItem(
        "Delete Image",
        "fa-trash-alt",
        () => {
          const confirmation = window.confirm(
            "Are you sure you want to delete this image?"
          );
          if (confirmation) {
            target.remove();
            dialog.remove();
          }
        }
      );

      dialog.appendChild(addLinkOption);
      dialog.appendChild(deleteImageOption);

      // Add Navigate to Link and Edit Link options if imageLink exists
      if (imageLink) {
        const navigateToLinkOption = createDialogItem(
          "Navigate to Link",
          "fa-external-link-alt",
          () => {
            window.open(imageLink, "_blank");
            dialog.remove();
          }
        );

        const editLinkOption = createDialogItem("Edit Link", "fa-edit", () => {
          let newUrl = prompt("Enter the new URL for the image:", imageLink);
          if (newUrl) {
            if (
              !newUrl.startsWith("http://") &&
              !newUrl.startsWith("https://")
            ) {
              newUrl = "http://" + newUrl;
            }

            const link = document.createElement("a");
            link.href = newUrl;
            link.target = "_blank";
            target.replaceWith(link);
            link.appendChild(target);

            imageLink = newUrl;
          }
          dialog.remove();
        });

        dialog.appendChild(navigateToLinkOption);
        dialog.appendChild(editLinkOption);
      }

      dialog.style.position = "absolute";
      dialog.style.left = `${event.clientX}px`;
      dialog.style.top = `${event.clientY}px`;

      document.body.appendChild(dialog);

      document.addEventListener("click", (e) => {
        if (!dialog.contains(e.target) && e.target !== target) {
          dialog.remove();
        }
      });

      function createDialogItem(text, iconClass, onClick) {
        const item = document.createElement("div");
        item.classList.add("dialog-item");

        const icon = document.createElement("i");
        icon.classList.add("fas", iconClass);
        item.appendChild(icon);

        const label = document.createElement("span");
        label.textContent = text;
        item.appendChild(label);

        item.addEventListener("click", onClick);

        return item;
      }
    }

    //-------------------------------------------------------------------------
    //ContextMenuForTexts
    //-------------------------------------------------------------------------
    else if (selection.type === "Range" && selection.rangeCount > 0) {
      setShowToolbar(true);
      const range = selection.getRangeAt(0);
      const selectedNode = range.commonAncestorContainer;
      const offsetX = 500;
      const offsetY = 80;

      setToolbarPosition({
        x: event.clientX - offsetX,
        y: event.clientY - offsetY,
      });
    } else {
      setShowToolbar(false);
    }
  };

  //-------------------------------------------------------------------------
  //handleImageClick
  //-------------------------------------------------------------------------
  const handleImageClick = (container) => {
    if (selectedImage === container) {
      selectedImage.classList.remove("selected");
      setSelectedImage(null);
    } else {
      if (selectedImage) {
        selectedImage.classList.remove("selected");
      }
      setSelectedImage(container);
      container.classList.add("selected");
    }
  };

  //-------------------------------------------------------------------------
  //handleContentClick
  //-------------------------------------------------------------------------
  const handleContentClick = (e) => {
    if (!e.target.closest(".image-container")) {
      if (selectedImage) {
        selectedImage.classList.remove("selected");
        setSelectedImage(null);
      }
    }
  };

  //-------------------------------------------------------------------------
  //handleImage Delete
  //-------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Delete" && selectedImage) {
        selectedImage.remove();
        setSelectedImage(null);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage]);

  //-------------------------------------------------------------------------
  //HandlingColorPickerButtonOutsideClick
  //-------------------------------------------------------------------------
  const handleClickOutside = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //-------------------------------------------------------------------------
  //ToolbarResize according to windowsize
  //-------------------------------------------------------------------------
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //-------------------------------------------------------------------------
  // CodeViewer Dialog box open
  //-------------------------------------------------------------------------
  const handleOpen = () => {
    setMode("add");
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      const contentEditor = document.getElementById("edt");
      if (!contentEditor) return;

      const commonAncestor = range.commonAncestorContainer;
      if (!contentEditor.contains(commonAncestor)) {
        console.warn("Selection is not within the content editor.");
        return;
      }

      setSelectionRange(range);
      setLanguage("");
      setCodeString("");
      setOpen(true);
    }
  };

  //-------------------------------------------------------------------------
  // CodeViewer Dialog box close
  //-------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const handleContentChange = () => {
    setContent(contentEditorRef.current.innerHTML);
  };

  useEffect(() => {
    if (contentEditorRef.current && contentEditorRef.current.innerHTML !== content) {
      contentEditorRef.current.innerHTML = content;
    }
  }, [content]);
  

  const getIconSize = () => {
    if (windowWidth < 400) return 10;
    if (windowWidth < 600) return 12;
    if (windowWidth < 800) return 15;
    return 20;
  };
  //-------------------------------------------------------------------------
  // Making resizable when edit mode
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (contentEditorRef.current && editID) {
      const contentEditor = contentEditorRef.current;
      // Add resize style to images in the prefilled data
      const images = contentEditor.querySelectorAll("img");
      images.forEach((img) => {
        const imgContainer = img.parentNode;
        if (
          imgContainer &&
          imgContainer.className === "editor-image-container"
        ) {
          imgContainer.style.resize = "both";
          imgContainer.style.position = "relative";
          imgContainer.style.userSelect = "none";
        }
      });
      // Find divs with className 'language-display' and 'code-viewer-content' and set contenteditable to true
      const editableDivs = contentEditor.querySelectorAll(
        ".language-display, .code-viewer-content"
      );
      editableDivs.forEach((div) => {
        div.setAttribute("contenteditable", "true");

        // Wrap the contenteditable div with a resizable container
        const parentContainer = div.parentNode;
        parentContainer.style.resize = "both";
        parentContainer.style.overflow = "auto";
        parentContainer.style.minWidth = "200px"; // Set minimum width
        parentContainer.style.minHeight = "200px"; // Set minimum height
      });
    }
  }, [editID]);

   // Function to save the current cursor position
    const saveCursorPosition = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return null;
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(contentEditorRef.current);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const cursorPosition = preCaretRange.toString().length;
    return cursorPosition;
  };

  // Function to restore the cursor position after re-render
  const restoreCursorPosition = (position) => {
    if (position === null) return;
    const selection = window.getSelection();
    const range = document.createRange();
    let currentNode = contentEditorRef.current;
    let charIndex = 0;

    // Traverse the content and restore the cursor position
    const traverseNodes = (node) => {
      if (node.nodeType === 3) { // Text node
        const textLength = node.length;
        if (charIndex + textLength >= position) {
          range.setStart(node, position - charIndex);
          range.setEnd(node, position - charIndex);
          return true;
        } else {
          charIndex += textLength;
        }
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          if (traverseNodes(node.childNodes[i])) return true;
        }
      }
      return false;
    };

    traverseNodes(currentNode);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <div className="text_editor_Main_container" onClick={handleContentClick}>
      <div>
        {/* __________________ToolBar Start ___________________________________________ */}
        <div className="toolbar">
          <ToolBar
            windowWidth={windowWidth}
            color={color}
            showColorPicker={showColorPicker}
            handleImageInsert={showImageInsert ? handleImageInsert : null}
            handleOpen={showCodeViewer ? handleOpen : null}
            idProp={editID}
            selectionRange={selectionRange}
            colorPickerRef={colorPickerRef}
            currentProperty={currentProperty}
            showHyperlink={showHyperlink}
            showCodeViewer={showCodeViewer}
            showButtonInsert={showButtonInsert}
            showImageInsert={showImageInsert}
          />

          {showButton && (
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={onSave}
            >
              <SaveIcon style={{ fontSize: getIconSize() }} />
              {Button_Name}
            </Button>
          )}
        </div>
        {/* __________________ToolBar End  ___________________________________________ */}
        {/* _____________________Heading  Start _______________________________________*/}
        {isHeadingRequird ? (
          <div className="Heading">
            <TextField
              id="outlined-basic"
              label={header_label}
              variant="outlined"
              fullWidth
              value={headingValue}
              onChange={(e) => setHeadingValue(e.target.value)}
            />
          </div>
        ) : (
          <></>
        )}
        {/* _____________________Heading  End  _______________________________________*/}
        {/* _____________________Editor   Start  _______________________________________*/}
        <div className="before_editor">
          <div
            className="Content-editor"
            style={Editor_hight}
            id="edt"
            contentEditable
            ref={contentEditorRef}
            onPaste={handlePaste}
            onContextMenu={Content_Editor_handleContextMenu}
            onClick={Content_Editor_handleClick}
            onInput={handleContentChange}
          >
          </div>
          <CodeViewer
              open={open}
              handleClose={handleClose}
              contextMenu={contextMenu}
              language={language}
              setLanguage={setLanguage}
              codeString={codeString}
              setCodeString={setCodeString}
              mode={mode}
              setMode={setMode}
              currentCodeViewer={currentCodeViewer}
              setCurrentCodeViewer={setCurrentCodeViewer}
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              setOpen={setOpen}
            />
        </div>

        {/* _____________________Editor   End  _______________________________________*/}
      </div>

      {/* _____________________ Tag  Start _______________________________________*/}
      {is_tag_required ? (
        <div className="tagStart">
          {/* Check if the input type should be Autocomplete or TextField */}
          {isAutocomplete ? (
            <Autocomplete
              multiple
              value={selectedTag}
              onChange={(event, newValue) => setSelectedTag(newValue)}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) =>
                handleInputChange_tag(newInputValue)
              }
              options={tagOptions}
              getOptionLabel={(option) => option.title}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>{option.title}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {showAddIcon ? (
                          <Tooltip title="Add New Tag">
                            <IconButton onClick={handleAddNewItem}>
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </>
                    ),
                  }}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.title}
                    {...getTagProps({ index })}
                    className="chip-background-color-and-alignment"
                    deleteIcon={
                      <Tooltip title="Remove">
                        <CloseIcon
                          className="chip-close-icon"
                        />{" "}
                        {/* Inline style for background color */}
                      </Tooltip>
                    }
                  />
                ))
              }
            />
          ) : (
            <TextField
              id="outlined-basic"
              label={label}
              variant="outlined"
              fullWidth
              value={Tag_value}
              onChange={(e) => setTag_value(e.target.value)}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Editor1;
