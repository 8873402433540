import React from "react";
import { Modal } from "@mui/material";
import InstructorTemplateForAddEdit from "./InstructorTemplateForAddEdit";
import "./instructor.css";

function AddEditInstructor({
  openAddInstructor,
  closeAddInstructor,
  SetDataBaseUpdate,
  editData,
}) {
  return (
    <Modal
      open={openAddInstructor}
      onClose={closeAddInstructor}
      className="instructor-screen-center-alignment"
    >
      <div className="instructor-container">
        {/* ______________________Instructor template call_______________________ */}
        <InstructorTemplateForAddEdit
          SetDataBaseUpdate={SetDataBaseUpdate}
          editData={editData}
          onClose={closeAddInstructor}
        />
        {/* ______________________end_______________________ */}
      </div>
    </Modal>
  );
}

export default AddEditInstructor;
