import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Button,
  Divider,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import "./Quiz.css";
import QuizTime from "./QuizTime";
import SendIcon from "@mui/icons-material/Send";
import QuizNav from "./QuizNav";
import QuizQuestion from "./QuizQuestion";
import QuizRightNav from "./QuizRightNav";
import { Drawer, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation } from "react-router-dom";
import { baseURL } from "../../http";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import { useNavigate } from "react-router-dom";
import DropDown from "../DropDownWithProps/DropDown";
import DynamicImageLogo from "../UserSettingAPI/DynamicImageLogo";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";

function QuizMainLandingPage() {
  return (
    <div>
        <Grid item xs={12}>
          <div className="quiz-main-header-design">
            <div className="quiz-main-header-second-container">
              {/* Image */}
              <div
               
              >
                <DynamicImageLogo
                  images={''}
                  height={"42px"}
                  width={"57px"}
                />
                {/* <img
                  src="HTES_LOGO.png"
                  alt="Logo"
                  style={{
                    maxWidth: "57px",
                    maxHeight: "42px",
                    padding: "1px",
                    objectFit: "contain",
                  }}
                /> */}
              </div>
              {/* Test Name */}
              <div
                
              >
                <span
                 
                >
                  Manogahare
                </span>
              </div>
              {/* Quiz Time */}
             
              <div
                
              >
                {/* <QuizTime
                  loadingTimeoff={loading}
                  timeLeft={timeLeft}
                  setTimeLeft={setTimeLeft}
                  reportModalOpen={reportModalOpen}
                /> */}
              </div>
              {/* Language Select */}
              <div
                
              >
                <div
                 
                >
                  Language:
                  
                </div>
              </div>
              {/* Submit Button */}
              <div
                
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green", marginLeft: "5px" }}
                  id="button-zoom"
                //   onClick={handleSubmit}
                >
                  <SendIcon className="buttonicons" />
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Grid>
        </div>
  )
}

export default QuizMainLandingPage