import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../../Components/GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import EnquiryFormModal from "./EnquiryForm/EnquiryFormModal";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignedFormModal from "./EnquiryAssignedForm/AssignedFormModal";

function CourseNamePage() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [initialFormData, setInitialFormData] = useState();
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [CloseActionDialog, setCloseActionDialog] = useState(false);

  //--------------------------------------------------------------------------------
  //Fetch the data from the database initially
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/enquiry_fetch_all`);
        const data = response.data;
        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle edit Function (in table action icon)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata, id) => {
    setInitialFormData(rowdata);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //--------------------------------------------------------------------------------
  //Handle edit Function (in table action icon)
  //--------------------------------------------------------------------------------

  const handleEditAssign = (rowdata, id) => {
    setInitialFormData(rowdata);
    setOpenAssign(true);
  };

  const handleCloseAssign = () => {
    setOpenAssign(false);
  };

  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: AssignmentIndIcon, //icon Name (import from the Mui)
      label: "Assign To", //Name of the MenuItem
      onClick: handleEditAssign, //Handle the Function(for working )
      IconColor: "blue", //Color of the Icon
    },
  ];

  //--------------------------------------------------------------------------------
  //Handle More/manage Function (in table )
  //--------------------------------------------------------------------------------
  const handleAddMoreFunctionality = () => {
    setOpenTemplateModal(true);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        customMenuItems={customMenuItems}
        columns={renderColumns} // Columns render
        rows={renderRows} // Rows render
        handleEdit={handleEdit} //Edit Row function
        deleteApi="enquiry_delete" // Delete API
        tableNameForExport="Enquiry" // optional
        is_Manage_Button_Required={true} // Manage button view if addition items added(additional functionality)
        handleManageButtonClick={handleAddMoreFunctionality} // if optional data required(additional functionality function)
        Manage_button_name="Manage Enquiry" //additional button name
        SetDataBaseUpdate={SetDataBaseUpdate} //To maintain the table updated
        DefaultColumnNames={["id", "EnquiryType", "Name", "Email"]} //DefaultColumnsnameshows
        CloseActionDialog={CloseActionDialog}
      />
      {/* _______________________End_____________________________ */}

      {/* _______________________Enquiry Form_____________________________ */}

      <EnquiryFormModal
        open={open}
        handleActionClose={handleActionClose}
        handleClose={handleClose}
        SetDataBaseUpdate={SetDataBaseUpdate}
        initialFormData={initialFormData}
      />

      {/* _______________________End_____________________________ */}
      {/* _______________________Assigned Form modal_____________________________ */}
      <AssignedFormModal
        open={openAssign}
        handleActionClose={handleActionClose}
        handleClose={handleCloseAssign}
        SetDataBaseUpdate={SetDataBaseUpdate}
        initialFormData={initialFormData}
      />
      {/* ____________________________end______________________ */}
    </div>
  );
}

export default CourseNamePage;
