import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import axios from "axios";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";


function AppliedJobNotification() {
    const [renderColumns, setRenderColumns] = useState([]);
    const [renderRows, setRenderRows] = useState([]);
    const [dataBaseUpdate,SetDataBaseUpdate]=useState(false)
    console.log("renderColumns", renderColumns, "renderRows", renderRows);
    const [open, setOpen] = useState(false);
    const[editData,setEditData]=useState()
    const [openEdit, setOpenEdit] = useState(false);
    const [CloseActionDialog, setCloseActionDialog] = useState(false);//Need to close the Action menu dropdown(once fuctionlaity triggered)
  
  
      useEffect(() => {
        const fetchTableData = async () => {
          
          try {
            const response = await axios.get(`${baseURL}/Apply_Job_fetch_all`);
            const data = response.data;
    
            // Assuming data contains `fillable` and the row data
            const columnNames = Object.keys(data[0] || {});
            const columns = columnNames.map((columnName) => ({
              field: columnName,
              headerName: columnName,
            }));
            setRenderColumns(columns);
      
            // Format rows data
            const rows = data.map((row, index) => ({
              id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
              ...row,
            }));
            setRenderRows(rows);
            SetDataBaseUpdate(false)
    
          } catch (error) {
            console.error("Error fetching table data:", error);
          }
        };
    
        fetchTableData();
      }, [dataBaseUpdate]);
    const handleView = (row, rowid) => {
      console.log("data", row, rowid);
    };
    const handleView1 = (row, rowid) => {
      console.log("data", row, rowid);
    };
  
   
    const handleEdit = (rowdata) => {
      setEditData(rowdata)
      setOpenEdit(true)

      console.log("handle Edit functions", rowdata);
    };
  
    const handleAddMoreFunctionality=()=>{
      
      setOpen(true)
    }

    const handleClose=()=>{
      setOpen(false)
    }

    const handleCloseEdit=()=>{
      setOpenEdit(false)
    }

      //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

    return (
      <>
      <div>
        {/* _______________________Main Component call____________ */}
        <TableHeaderTemplate
          columns={renderColumns} //Columns render
          rows={renderRows} //rows render
          handleEdit={handleEdit} 
          deleteApi="Apply_Job_delete" //Delete Api
          tableNameForExport='AppliedJob'//optionl
          is_Manage_Button_Required={true}//Mange button view if addition Items add
          handleManageButtonClick={handleAddMoreFunctionality}//if optional data required
          Manage_button_name='Apply Job Notification'
          SetDataBaseUpdate={SetDataBaseUpdate}          
          DefaultColumnNames={["StudentId", "JobId",'ApplyDate']} // Add to show the columns in a default render
          CloseActionDialog={handleActionClose} //Handle the action dialog close when the
        />
        {/* _______________________End_____________________________ */}
      </div>

     
      </>
    );
}

export default AppliedJobNotification
