import React, { useState, useEffect } from "react";
import QuizMain from "./QuizMain1";
import {
  Button,
  Modal,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DropDown from "../DropDownWithProps/DropDown";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

function QuizDashboard() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [keypressCounter, setKeyPressCounter] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "English",
    code: "en",
  });

  const [hasRendered, setHasRendered] = useState(true);
  const history = useNavigate();

  //--------------------------------------------------------------------------------
  //Exist the Full screen and Route back to Home page
  //--------------------------------------------------------------------------------
  const handlesaveclick = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    // Route to dashboard
    history("/dashboard");
  };

  //--------------------------------------------------------------------------------
  //Make the Full screen View in all the Browser
  //--------------------------------------------------------------------------------
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, []);

  //--------------------------------------------------------------------------------
  //Make the Full screen View in all the Browser
  //--------------------------------------------------------------------------------
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [testStarted, reportModalOpen]);

  //--------------------------------------------------------------------------------
  //Handle Full screen Exit change
  //--------------------------------------------------------------------------------
  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  //--------------------------------------------------------------------------------
  //Enter the fullScreen
  //--------------------------------------------------------------------------------
  const enterFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
      setIsFullScreen(true);
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
      setIsFullScreen(true);
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
      setIsFullScreen(true);
    }
  };

  //--------------------------------------------------------------------------------
  //Handle close the Modal
  //--------------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Enter the fullscreen
  //--------------------------------------------------------------------------------
  const handleEnterFullScreen = () => {
    enterFullScreen();
    handleClose();
    setTestStarted(true);
    setHasRendered(false);
    document.addEventListener("fullscreenchange", handleFullScreenExit);
    document.addEventListener("webkitfullscreenchange", handleFullScreenExit);
    document.addEventListener("mozfullscreenchange", handleFullScreenExit);
    document.addEventListener("MSFullscreenChange", handleFullScreenExit);
  };

  //--------------------------------------------------------------------------------
  //Handle Enter the fullscreen exit
  //--------------------------------------------------------------------------------
  const handleFullScreenExit = () => {
    if (!document.fullscreenElement) {
      setOpen1(true);
    }
  };

  //--------------------------------------------------------------------------------
  //Handle cancel
  //--------------------------------------------------------------------------------
  const handleCancel = () => {
    setOpen(true);
    handleClose1();
  };

  //--------------------------------------------------------------------------------
  //close the modal
  //--------------------------------------------------------------------------------
  const handleClose1 = () => {
    setOpen1(false);
  };

  //--------------------------------------------------------------------------------
  //Handle the key press check
  //--------------------------------------------------------------------------------
  const handleKeyDown = (event) => {
    const key = event.key.toLowerCase(); // Convert the pressed key to lowercase
    const alphabetRegex = /^[a-zA-Z]$/; // Regular expression to match alphabets

    if (testStarted) {
      if (reportModalOpen) {
        // Ignore Caps Lock and Backspace key presses
        if (
          key === "capslock" ||
          key === "backspace" ||
          key === " " ||
          key === "enter" ||
          key === "shift"
        ) {
          return;
        }
        // If reportModalOpen is true, allow only alphabet key presses
        if (!alphabetRegex.test(key)) {
          console.log(
            "Non-alphabet key pressed during the test in report modal open state."
          );
          // Show error message for non-alphabet key press
          setSnackbarOpen(true);
        }
      } else {
        // If reportModalOpen is false, show error for all key presses
        console.log(
          "Any key pressed during the test in report modal closed state."
        );
        setSnackbarOpen(true);
      }
      // Increment keypress counter
      setKeyPressCounter((prevCounter) => prevCounter + 1);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEscapeKeyDown>
        <div className="quizboard1-modal-importent">
          <div className="quizboard1-modal-container-importent">
            <h5 className="quizboard1-header">Important!</h5>
            <div>
              {/* ____Render the block only if the component has not yet rendered______*/}
              {hasRendered && (
                <div className="quizboard1-modal-choose-laanguage">
                  <span>Choose Your Default Language:</span>
                  &nbsp;
                  <DropDown
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                  />
                </div>
              )}
              {/*_________Rest of your component_____________ */}
            </div>
            <div className="quizboard1-note-container">
              <span className="quizboard1-note-text">Note:</span>
              <span className="quizboard1-note-content">
                &nbsp;Please make sure that Don't exit the fullscreen{" "}
              </span>
            </div>
            <div className="quizboard1-alignment-modal">
              <Button
                onClick={handleEnterFullScreen}
                variant="contained"
                color="success"
                id="button-zoom"
              >
                <PlayArrowIcon className="buttonicons" />
                &nbsp;Start Test
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* ___________________Quiz main_________________ */}
      {testStarted && (
        <QuizMain
          reportModalOpen={reportModalOpen}
          setReportModalOpen={setReportModalOpen}
          keypressCounter={keypressCounter}
          setSelectedLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
        />
      )}

      {/* ___________________Screen exit error_______________ */}
      <Modal
        open={open1}
        onClose={handleClose1}
        disableEscapeKeyDown
        className="quizboard1-screen-exit-modal"
      >
        <div className="quizboard1-screen-exit-modal1">
          <div className="quizboard1-screen-exit-modal2">
            <IconButton
              className="addeducationalMapmodalcancel"
              onClick={handleCancel}
            >
              <CloseIcon className="addeducationalMapmodalcancelX" />
            </IconButton>
            <h5 className="quizboard1-alert-heading">Alert!!!</h5>
            <div className="quizboard1-content-of-modal">
              {" "}
              Are you sure you want to exit fullscreen mode?
              <strong> Exiting the fullscreen test will Terninated.</strong>
            </div>
            <div className="quizbashboard1-ok-cancel-button-alignment">
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleCancel}
              >
                <CloseIcon className="buttonicons" />
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handlesaveclick}
              >
                {" "}
                <LibraryAddCheckIcon className="buttonicons" />
                &nbsp;OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/*_________Snackbar for key press validation__________*/}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <SnackbarContent
          className="quizdashboard1-snackbar-info"
          message="*Test is considered malfunctioning. Please refrain from any key presses during the test."
          sx={{
            backgroundColor: "white", // Set red background for alert
            color: "red", // White text for contrast
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "unset",
          }}
        />
      </Snackbar>
    </>
  );
}

export default QuizDashboard;
