import React, { useRef, useState, useEffect } from "react";
import "./myemployeedata.css"; // Import the CSS for styling
import salary from "../EmployeeData/Images/HTES_LOGO.png"; // Import the logo
import { Button } from "@mui/material";
import { toWords } from "number-to-words";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";

function SalaryStructureDownload({
  downloadPdf,
  setDownloadPdf,
  editData,
  employeeData,
  setEditData,
  setEmployeeData,
  setEmployeeId,
}) {
  // State management using useState

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const salaryData = JSON.parse(employeeData.EmployeeSalary || "{}");


  const toIndianWords = (num) => {
    const words = [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen",
        "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];
    
    if (num < 20) return words[num];
    if (num < 100) return words[20 + Math.floor(num / 10) - 2] + (num % 10 ? " " + words[num % 10] : "");
    if (num < 1000) return words[Math.floor(num / 100)] + " hundred" + (num % 100 ? " " + toIndianWords(num % 100) : "");
    if (num < 100000) return toIndianWords(Math.floor(num / 1000)) + " thousand" + (num % 1000 ? " " + toIndianWords(num % 1000) : "");
    if (num < 10000000) return toIndianWords(Math.floor(num / 100000)) + " lakh" + (num % 100000 ? " " + toIndianWords(num % 100000) : "");
    return toIndianWords(Math.floor(num / 10000000)) + " crore" + (num % 10000000 ? " " + toIndianWords(num % 10000000) : "");
};

  useEffect(() => {
    if (employeeData && editData) {
      setIsLoading(false);
    }
  }, [employeeData, editData]);

  const slipRef = useRef(null);

  // PDF download functionality
  // Function to download PDF directly
  const handleDownloadPdf = () => {
    setIsLoading(true);
    const slipElement = slipRef.current;

    if (!slipElement) {
      console.error("Slip element is not defined");
      return; // Exit the function if the ref is not valid
    }

    html2canvas(slipElement, {
      scale: 2, // Increase scale for better quality
      width: 610, // Fixed width for desktop view
      height: 900, // Adjust height based on the content
      windowWidth: 1200, // Set window width for rendering like desktop
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Calculate image size and center alignment
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 3;
      const imgY = 10; // Position the content slightly down from the top

      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );

      pdf.save(
        `SalarySlip_${employeeData.EmployeeID}_${editData.EmployeeSalaryMonth}.pdf`
      );

      // Resetting state after PDF download
      setEditData("");
      setEmployeeData("");
      setEmployeeId("");
      setIsLoading(false);
      setDownloadPdf(false);
    });
  };

  useEffect(() => {
    if (downloadPdf && slipRef.current && editData && employeeData) {
      handleDownloadPdf();
    }
  }, [downloadPdf, slipRef.current, editData, employeeData]);

  const dailySalary =
    parseInt(Math.round(salaryData.BasicMonthly || 0)) /
    parseInt(editData.NoOfWorkingDays); // where workingDaysInMonth is the number of days the employee is expected to work in a month.

  const leaveSalaryDeduction = dailySalary * editData.NoOfAbsentDays;

  console.log("dailySalary", dailySalary, leaveSalaryDeduction);
  const getDaysInPreviousMonth = (monthYear) => {
    const [monthName, year] = monthYear.split("-");
    const date = new Date(`${monthName} 1, ${year}`);
    date.setMonth(date.getMonth() - 1);
    const daysInPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    return daysInPreviousMonth;
  };

  const noOfDaysInMonth = getDaysInPreviousMonth(
    editData && editData.EmployeeSalaryMonth
  );

  // Calculation for gross earnings and net salary
  // Calculation for gross earnings and net salary
  let grossEarnings = parseInt(
    Math.round(salaryData.BasicMonthly || 0) -
      leaveSalaryDeduction +
      parseInt(Math.round(salaryData.HRAMonthly || 0)) +
      parseInt(Math.round(salaryData.OtherAllowanceMonthly || 0))
  );

  const grossDeductions =
    parseInt(Math.round(salaryData.PFEmployeeMonthly || 0)) +
    parseInt(Math.round(salaryData.ProfessionalTaxMonthly || 0)) +
    parseInt(Math.round(salaryData.TaxMonthly || 0));

  // Parse Other Benefits
  const otherBenefits = JSON.parse(editData.OtherBenefits || "{}");

  if (Object.keys(otherBenefits).length > 0) {
    const additionalBenefitsTotal = Object.values(otherBenefits).reduce((acc, curr) => acc + parseInt(Math.round(curr)), 0);
    grossEarnings += additionalBenefitsTotal;
  }

  const netSalary = grossEarnings - grossDeductions;

  const netSalaryInWords = isFinite(netSalary)
  ? toIndianWords(netSalary).replace(/^\w/, (c) => c.toUpperCase())
  : "";

  return (
    <>
      {isLoading ? (
        <ProcessProgressCircle />
      ) : (
        <div className="salary-slip-scroll-container">
          <div className="salary-slip-conatiner">
            <div className="salary-slip-conatiner" ref={slipRef}>
              <div className="salary-slip">
                <div className="salary-slip-header-and-address-and-name">
                  <div className="salry-slip-logo-container">
                    <img src={salary} alt="Company Logo" />
                  </div>
                  <div className="salary-company-name-and-address">
                    <div className="salry-slip-company-name">
                      Happy Technovation
                    </div>
                    <div>
                      16th Main Rd, SBI Staff Colony,Hosalli Extention,Stage
                      1,Vijayanagara Bengaluru, Karnataka 560040
                    </div>
                  </div>
                </div>

                <div className="Payslip-month-information">
                  <p>Pay slip for month of {editData.EmployeeSalaryMonth}</p>
                </div>

                <div className="pay-details">
                  <table>
                    <tbody>
                      <tr>
                        <td>Emp Code</td>
                        <td className="Content-of-table-bold">
                          {employeeData.EmployeeID}
                        </td>
                        <td>Aadhaar Number</td>
                        <td className="Content-of-table-bold">
                          {employeeData.AdhaarNumber}
                        </td>
                      </tr>
                      <tr>
                        <td>Employee Name</td>
                        <td className="Content-of-table-bold">
                          {employeeData.EmployeeName}
                        </td>
                        <td>PAN Number</td>
                        <td className="Content-of-table-bold">
                          {employeeData.PanNumber}
                        </td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td className="Content-of-table-bold">
                          {employeeData.Designation}
                        </td>
                        <td>Department</td>
                        <td className="Content-of-table-bold">
                          {employeeData.Department}
                        </td>
                      </tr>
                      <tr>
                        <td>Date Of Joining</td>
                        <td className="Content-of-table-bold">
                          {employeeData.DateOfJoining}
                        </td>
                        <td>No.Of Days In Month</td>
                        <td className="Content-of-table-bold">
                          {noOfDaysInMonth}
                        </td>
                      </tr>
                      <tr>
                        <td>Employee Type</td>
                        <td className="Content-of-table-bold">
                          {employeeData.EmployeeType}
                        </td>
                        <td>No.Of Working Days</td>
                        <td className="Content-of-table-bold">
                          {editData.NoOfWorkingDays}
                        </td>
                      </tr>
                      <tr>
                        <td>Bank A/C Number</td>
                        <td className="Content-of-table-bold">
                          {employeeData.BankAccountNumber}
                        </td>
                        <td>No.Of Present Days</td>
                        <td className="Content-of-table-bold">
                          {(editData && editData.NoOfPresentDays) || 0}
                        </td>
                      </tr>
                      <tr>
                        <td>IFSC Code</td>
                        <td className="Content-of-table-bold">
                          {employeeData.IfscCode}
                        </td>
                        <td>No.Of Absent Days</td>
                        <td className="Content-of-table-bold">
                          {(editData && editData.NoOfAbsentDays) || 0}
                        </td>
                        {/* Empty cell for alignment */}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="earnings-section">
                  <table>
                    <thead>
                      <tr>
                        <th>Earnings</th>
                        <th>Amount (Rs)</th>
                        <th>Deductions</th>
                        <th>Amount (Rs)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Basic</td>
                        <td className="Content-of-table-bold">
                        {parseInt(
                              Math.round(salaryData.BasicMonthly) || 0
                            ).toFixed(2) -  (Math.round(leaveSalaryDeduction)).toFixed(2)}.00
                        </td>
                        <td>Professional Tax</td>
                        <td className="Content-of-table-bold">
                          {parseInt(
                            Math.round(salaryData.ProfessionalTaxMonthly) || 0
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>HRA</td>
                        <td className="Content-of-table-bold">
                          {parseInt(
                            Math.round(salaryData.HRAMonthly) || 0
                          ).toFixed(2)}
                        </td>
                        <td>Tax</td>
                        <td className="Content-of-table-bold">
                          {parseInt(
                            Math.round(salaryData.TaxMonthly) || 0
                          ).toFixed(2)}
                        </td>
                        {/* Empty cell for alignment */}
                      </tr>
                      <tr>
                        <td>Other Allowance</td>
                        <td className="Content-of-table-bold">
                          {parseInt(
                            Math.round(salaryData.OtherAllowanceMonthly) || 0
                          ).toFixed(2)}
                        </td>
                        <td>PF Employee</td>
                        <td className="Content-of-table-bold">
                          {parseInt(
                            Math.round(salaryData.PFEmployeeMonthly) || 0
                          ).toFixed(2)}
                        </td>
                        {/* Empty cell for alignment */}
                      </tr>



                       {/* _________________Rnder the spiciall allowance____________ */}
                       {Object.entries(otherBenefits).map(
                          ([benefitName, benefitAmount]) => (
                            <tr key={benefitName}>
                              <td>{benefitName}</td>
                              <td className="Content-of-table-bold">
                                {parseInt(Math.round(benefitAmount)).toFixed(2)}
                              </td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          )
                        )}

                      <tr>
                        <td className="Content-of-table-bold">
                          Gross Earnings
                        </td>
                        <td className="Content-of-table-bold">
                          {grossEarnings}.00
                        </td>
                        <td className="Content-of-table-bold">
                          Gross Deductions
                        </td>
                        <td className="Content-of-table-bold">
                          {grossDeductions}.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="net-salary">
                  <p>
                    Net Salary (In Figures):{" "}
                    <strong>Rs. {netSalary}.00/-</strong>
                  </p>
                  <p>
                    Net Salary (In Words):{" "}
                    <strong>{netSalaryInWords} only</strong>
                  </p>
                </div>

                <p className="note-for-file">
                  Note: This is a computer generated pay slip. Hence, no
                  signature is required.
                </p>
              </div>
            </div>

            <div className="download-btn">
              <Button
                variant="contained"
                color="success"
                onClick={handleDownloadPdf}
              >
                Download PDF
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SalaryStructureDownload;
