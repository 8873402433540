import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import navItems from "../DashboardAdmin/navBarItems";
import UpdateIcon from "@mui/icons-material/Update";
import axios from "axios";
import { baseURL } from "../../http";
import Chip from "@mui/material/Chip";
import {
  IconButton,
  Avatar,
  Typography,
  Tooltip,
  CircularProgress,
  Button,
  Checkbox,
} from "@mui/material";
import { customAlert } from "../SweetAlertCommon/Custom";

function GrantAccess2({
  GiveAccesModalStatus,
  close,
  selectedRowId,
  loginData,
}) {
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [accessTabs, setAccessTabs] = useState([]);
  const [readOnlyTabs, setReadOnlyTabs] = useState([]);

  useEffect(() => {
    if (loginData && loginData.length > 0) {
      const user = loginData.find((user) => user.id === selectedRowId);
      setSelectedUser(user);
      setLoading(false);

      const parseArrayFromString = (str) => {
        try {
          // Remove the first and last double quotes if they exist
          const cleanedStr = str.replace(/^"|"$/g, "");
          // Parse the cleaned string into a JSON array
          const parsedArray = JSON.parse(cleanedStr);
          return Array.isArray(parsedArray) ? parsedArray : [];
        } catch (error) {
          console.error("Failed to parse array:", error);
          return [];
        }
      };

      if (user && user.tabids) {
        const parsedTabIds = parseArrayFromString(user.tabids);
        setAccessTabs(parsedTabIds);
      } else {
        setAccessTabs([]);
      }

      if (user && user.readonlytabs) {
        const parsedReadOnlyTabs = parseArrayFromString(user.readonlytabs);
        setReadOnlyTabs(parsedReadOnlyTabs);
      } else {
        setReadOnlyTabs([]);
      }
    } else {
      setLoading(false);
    }
  }, [loginData, selectedRowId, GiveAccesModalStatus]);

  console.log("accessTabs", accessTabs);
  console.log("readOnlyTabs", readOnlyTabs);
  const handleCloseGiveAccess = () => {
    close(false);
  };

  const handleCheckboxChange = (mainId, subId = null) => {
    setAccessTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      const mainTabIndex = newTabs.findIndex((tab) => tab[0] === mainId);

      if (subId === null) {
        if (mainTabIndex === -1) {
          newTabs.push([mainId]);
        } else {
          newTabs.splice(mainTabIndex, 1);
        }
      } else {
        if (mainTabIndex === -1) {
          newTabs.push([mainId, subId]);
        } else {
          const subTabIndex = newTabs[mainTabIndex].indexOf(subId);
          if (subTabIndex === -1) {
            newTabs[mainTabIndex].push(subId);
          } else {
            newTabs[mainTabIndex].splice(subTabIndex, 1);
            if (newTabs[mainTabIndex].length === 1) {
              newTabs.splice(mainTabIndex, 1);
            }
          }
        }
      }

      return newTabs;
    });
  };

  const handleReadAccessChange = (mainId, subId = null) => {
    setReadOnlyTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      const mainTabIndex = newTabs.findIndex((tab) => tab[0] === mainId);

      if (subId === null) {
        if (mainTabIndex === -1) {
          newTabs.push([mainId]);
        } else {
          newTabs.splice(mainTabIndex, 1);
        }
      } else {
        if (mainTabIndex === -1) {
          newTabs.push([mainId, subId]);
        } else {
          const subTabIndex = newTabs[mainTabIndex].indexOf(subId);
          if (subTabIndex === -1) {
            newTabs[mainTabIndex].push(subId);
          } else {
            newTabs[mainTabIndex].splice(subTabIndex, 1);
            if (newTabs[mainTabIndex].length === 1) {
              newTabs.splice(mainTabIndex, 1);
            }
          }
        }
      }

      return newTabs;
    });
  };

  const handleupadeAccesstabs = async () => {
    try {
      await axios.put(baseURL + `/updatetabs/${selectedUser.id}`, {
        tabids: JSON.stringify(accessTabs),
        readonlytabs: JSON.stringify(readOnlyTabs),
      });
      customAlert(
        "",
        "Dash Board Tabs Access For the Selected User Updated",
        "success"
      );
      handleCloseGiveAccess();
    } catch (error) {
      console.error("Error updating tabs:", error);
    }
  };

  const filteredItems = navItems
    .map((item) => {
      const isMainItemIncluded = accessTabs.some((tab) => tab[0] === item.id);

      const filteredSubItems = item.subItems
        ? item.subItems.filter(
            (subItem) => !accessTabs.some((tab) => tab.includes(subItem.id))
          )
        : [];

      if (filteredSubItems.length === 0 && isMainItemIncluded) {
        return null;
      }

      return {
        ...item,
        subItems: filteredSubItems,
      };
    })
    .filter((item) => item !== null);

  return (
    <>
      <Modal
        open={GiveAccesModalStatus}
        onClose={handleCloseGiveAccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="giveaccessmodaloutercontainer"
      >
        <div className="giveaccessmodaloutercontainer">
          <div className="filtermodalcontent-grant-access2">
            <div>
              <IconButton
                id="button-zoom"
                className="EducationalTableViewmodalcloseicon"
                onClick={handleCloseGiveAccess}
              >
                <CloseIcon className="EducationalTableViewmodalcloseiconX" />
              </IconButton>
            </div>
            {loading ? (
              <div className="loading-container">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <div className="filterheaderdiv-grant-access2">
                  <h5 className="filterheader"> Manage Access</h5>
                </div>
                <div className="devide-two-conatiner-grid-grant-access2">
                  <div className="grid-main-container">
                    <h5 className="Heading-for-the-grids">
                      {" "}
                      Acces Granted Tabs
                    </h5>
                    <div className="giveaccesscrollcontainerNavbar-grantAccess2">
                      {accessTabs.length > 0 ? (
                        accessTabs.map((tabids, index) => {
                          const item = navItems.find(
                            (item) => item.id === tabids[0]
                          );
                          if (item) {
                            return (
                              <div
                                className="giveaccessccontentcontainer-grant-access2"
                                key={index}
                              >
                                <div className="subitem-content-grantAccess2">
                                  <div>
                                    <Checkbox
                                      checked={true}
                                      onChange={() =>
                                        handleCheckboxChange(item.id)
                                      }
                                    />
                                    &nbsp;
                                    <span className="tabnamefont">
                                      {item.title}
                                    </span>
                                  </div>
                                  {!item.subItems?.length && (
                                    <div className="subitem-permissions">
                                      <label className="subitem-permissions">
                                        <Checkbox
                                          checked={readOnlyTabs.some(
                                            (tab) => tab[0] === item.id
                                          )}
                                          onChange={() =>
                                            handleReadAccessChange(item.id)
                                          }
                                        />
                                        <span>Read Only</span>
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {tabids.length > 1 && (
                                  <div className="subitems-container-grantAccess2">
                                    {tabids.slice(1).map((subTabId) => {
                                      const subItem = item.subItems.find(
                                        (sub) => sub.id === subTabId
                                      );
                                      return (
                                        <div
                                          className="subitem-content-grantAccess2"
                                          key={subTabId}
                                        >
                                          <div>
                                            <Checkbox
                                              checked={true}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  subTabId
                                                )
                                              }
                                            />
                                            &nbsp;
                                            <span className="tabnamefont">
                                              {subItem.subtitle}
                                            </span>
                                          </div>
                                          <div>
                                            <div className="subitem-permissions">
                                              <label className="subitem-permissions">
                                                <Checkbox
                                                  checked={readOnlyTabs.some(
                                                    (tab) =>
                                                      tab[0] === item.id &&
                                                      tab.includes(subTabId)
                                                  )}
                                                  onChange={() =>
                                                    handleReadAccessChange(
                                                      item.id,
                                                      subTabId
                                                    )
                                                  }
                                                />
                                                <span>Read Only</span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return null;
                        })
                      ) : (
                        <div className="notabscinatainer">
                          No access tabs selected
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="devider"></div>
                  <div className="grid-main-container1">
                    <h5 className="Heading-for-the-grids">Available Tabs</h5>
                    <div className="giveaccesscrollcontainerNavbar-grantAccess2">
                      {filteredItems.map((item) => (
                        <div
                          className="giveaccessccontentcontainer-grant-access2"
                          key={item.id}
                        >
                          <div className="subitem-content-grantAccess2">
                            <div>
                              {item.subItems.length === 0 && (
                                <Checkbox
                                  checked={false}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                              )}
                              &nbsp;
                              <span className="tabnamefont">{item.title}</span>
                            </div>

                            {/* {!item.subItems?.length && (
                              <div className="subitem-permissions">
                                <label className="subitem-permissions">
                                  <Checkbox
                                    checked={readOnlyTabs.some(
                                      (tab) => tab[0] === item.id
                                    )}
                                    onChange={() =>
                                      handleReadAccessChange(item.id)
                                    }
                                  />
                                  <span>Read Only</span>
                                </label>
                              </div>
                            )} */}
                          </div>
                          {item.subItems && item.subItems.length > 0 && (
                            <div className="subitems-container-grantAccess2">
                              {item.subItems.map((subItem) => (
                                <div
                                  className="subitem-content-grantAccess2"
                                  key={subItem.id}
                                >
                                  <div>
                                    <Checkbox
                                      checked={false}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          item.id,
                                          subItem.id
                                        )
                                      }
                                    />
                                    &nbsp;
                                    <span className="tabnamefont">
                                      {subItem.subtitle}
                                    </span>
                                  </div>
                                  <div>
                                    {/* <div className="subitem-permissions">
                                      <label className="subitem-permissions">
                                        <Checkbox
                                          checked={readOnlyTabs.some(
                                            (tab) =>
                                              tab[0] === item.id &&
                                              tab.includes(subItem.id)
                                          )}
                                          onChange={() =>
                                            handleReadAccessChange(
                                              item.id,
                                              subItem.id
                                            )
                                          }
                                        />
                                        <span>Read Only</span>
                                      </label>
                                    </div> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="giveaccesalignmetbutton">
                  <div className="filterheaderdiv-grant-access2">
                    <h5 className="filterheader-userinfo">
                      User<br></br> Info:
                    </h5>
                    {selectedUser ? (
                      <div className="userinformationanamephonealignment">
                        <Tooltip title={selectedUser.email} arrow>
                          <Avatar className="usermanagementavatar">
                            {selectedUser.name
                              ? selectedUser.name.charAt(0).toUpperCase()
                              : ""}
                          </Avatar>
                        </Tooltip>
                        &nbsp;&nbsp;
                        <div>
                          <div className="fontcolorbold">
                            {selectedUser.name}
                          </div>
                          <div className="fontcolor">
                            {selectedUser.mobile || "Not Available"}
                          </div>
                        </div>
                        &nbsp;
                        <Chip
                          label={selectedUser.usertype}
                          className="chipsizealignment"
                        />
                      </div>
                    ) : (
                      <div className="no-user-container">
                        <Typography>
                          No user selected or user not found.
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleupadeAccesstabs}
                      id="button-zoom"
                    >
                      <UpdateIcon className="buttonicons" />
                      Update Access
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default GrantAccess2;
