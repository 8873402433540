import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow, MDBIcon } from "mdb-react-ui-kit";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { baseURL } from "../../http";
import StudentBasicDetailModal from "./PmStudentBasicDetailModal";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import ImageCropperTemplate from "../Templates/ImageCropperTemplate";
import VerifiedIcon from "@mui/icons-material/Verified";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ImageCropperTemplate2 from "../Templates/ImageCroper/ImageCropperTemplate2";

function StudentProfilePage({ PercentageUpdate }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------

  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [pfname, setPfname] = useState("");
  const [mobile, setMobile] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [expYear, setExpYear] = useState("");
  const [email, setEmail] = useState("");
  const [expMonth, setExpmonth] = useState("");
  const [expSalary, setExpSalary] = useState("");
  const [location, setLocation] = useState("");
  const [currentEmployment, setCurrentEmployment] = useState("");
  const [profilePercentage, setProfilePercentage] = useState(0);
  const [showProfileImgEditIcon, setShowProfileImgEditIcon] = useState(false);

  const [profileImageRender, setProfileImageRender] = useState(null);
  // const [profileImageURL, setProfileImageURL] = useState(null);
  const [croppedProfileImgURL, setCroppedProfileImgURL] = useState("");

  const [basicDetailModalStatus, setBasicDetailModalStatus] = useState(false);
  const [profilePictureModalStatus, setProfilePictureModalStatus] =
    useState(false);
  const [imageEditMode, setImgEditMode] = useState(0);

  const containerWidth = 145;
  const containerHeight = 145;
  const containerSize = Math.min(containerWidth, containerHeight);
  const fontSize = containerSize * 0.65;

  //--------------------------------------------------------------------------------
  // Alternate text for Profile Image
  //--------------------------------------------------------------------------------
  const altText = userEmail.charAt(0).toUpperCase();

  //--------------------------------------------------------------------------------
  // Modal Toggles
  //--------------------------------------------------------------------------------
  function toggleBasicDetailModal() {
    setBasicDetailModalStatus(!basicDetailModalStatus);
  }

  function toggleProfilePictureModal() {
    setProfilePictureModalStatus(!profilePictureModalStatus);
    setImgEditMode(imageEditMode + 1);
  }

  //--------------------------------------------------------------------------------
  // Fetching the Profile Datas
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchUserData();
    fetchCurrentEmployment();
    fetchProfilePercentage();
    fetchProfilePercentage();
  }, []);
  useEffect(() => {
    fetchProfilePercentage();
  }, [PercentageUpdate]);

  //--------------------------------------------------------------------------------
  // Fetching the UserData
  //--------------------------------------------------------------------------------

  const fetchUserData = async () => {
    try {
      const requestBody = { email: userEmail };

      const response = await axios.post(`${baseURL}/getprofile`, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setPfname(data.name || "");
        setEmail(data.email || "");
        setMobile(data.phone || "");
        setLocation(data.location || "");
        setNoticePeriod(data.noticePeriod || "");
        setExpYear(data.experience || "");
        setExpmonth(data.experienceMonth || "");
        setExpSalary(data.expectedSalary || "");
        setProfileImageRender(data.profileImgURL || "");
        // setCroppedProfileImgURL(data.croppedProfileImgURL || "");
      } else {
        console.error("Error fetching user data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  //--------------------------------------------------------------------------------
  // Fetching the Profile Percentage
  //--------------------------------------------------------------------------------

  const fetchProfilePercentage = () => {
    axios
      .get(`${baseURL}/getProfilePercentage/${userEmail}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.profilePercentage <= 100) {
            setProfilePercentage(data.profilePercentage || "");
          } else {
            setProfilePercentage(100);
          }

          console.log("%==" + data.profilePercentage);
        } else {
          console.error(
            "Error fetching Profile Percentage:",
            response.statusText
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching Profile Percentage:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // Fetching the Profile Current Employment
  //--------------------------------------------------------------------------------
  const fetchCurrentEmployment = async () => {
    try {
      const requestBody = { email: userEmail };
      const response = await axios.post(
        `${baseURL}/getCurrentEmployment`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setCurrentEmployment(data.currentCompanyname || "");
      } else {
        console.error("Error fetching user data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Save Data -API
  //--------------------------------------------------------------------------------

  function saveProfilePicture() {
    console.log("check" + croppedProfileImgURL);
    const sendData = {
      email: userEmail,
      profileImgURL: croppedProfileImgURL,
    };

    axios
      .post(`${baseURL}/uploadProfilePicture`, sendData)
      .then((response) => {
        if (response.status === 200) {
          fetchUserData();
          customAlert("", "Photo Updated", "success");
          setProfilePictureModalStatus(false);
        } else {
          customAlert("", "Photo Update - API Error", "warning");
          console.error("Error uploading image:", response.statusText);
        }
      })
      .catch((error) => {
        customAlert("", "Photo Update - Server Down", "warning");
        console.error("Network error while uploading image:", error);
      });
  }

  //--------------------------------------------------------------------------------
  // Delete Profile Picture -API
  //--------------------------------------------------------------------------------
  function deleteSrcImage() {
    axios
      .delete(`${baseURL}/deleteProfilePicture/${userEmail}`)
      .then((response) => {
        if (response.status === 200) {
          fetchUserData();
          customAlert("", "Photo Deleted", "success");
        } else {
          customAlert("", "Photo Not Deleted  - API Error", "warning");
        }
      })
      .catch((error) => {
        customAlert("", "Photo Not Deleted - Server Down", "warning");
        console.error("Error deleting image:", error);
      });
  }

  //--------------------------------------------------------------------------------
  // Cerified Check - Email and Mobile
  //--------------------------------------------------------------------------------
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidMobile = (mobile) => {
    return mobile.length === 10 && /^\d{10}$/.test(mobile);
  };

  return (
    <div className="PPContainer">
      <br />
      {/* ____________________________________________ Render Area _____________________________________ */}
      <div className="rounded-6 bsdetail">
        <MDBRow className="bsDeatilContainer">
          {/* ___________________________________ Profile image Container _______________________________ */}

          <MDBCol className="ppImgContainer">
            {/*______________ Code progress Start____________ */}
            <div className="ppImgContainer2">
              <div
                className="profileImageWrapper"
                onMouseEnter={() => setShowProfileImgEditIcon(true)}
                onMouseLeave={() => setShowProfileImgEditIcon(false)}
                onClick={toggleProfilePictureModal}
              >
                <div className="pmProgressContainer">
                  {profileImageRender ? (
                    <img
                      src={profileImageRender}
                      // alt={altText}
                      className="ppProfileImage"
                      // style={{
                      //   fontSize: `${fontSize}px`,
                      //   lineHeight: `${containerHeight}px`,
                      // }}
                    />
                  ) : (
                    <div className="ppProfileImage-text">
                      <div>{altText}</div>
                    </div>
                  )}
                  <Tooltip title="Update Picture" arrow>
                    <label
                      onClick={toggleProfilePictureModal}
                      className="editIconWrapper"
                    >
                      <i
                        className={`fas fa-pen ${
                          showProfileImgEditIcon
                            ? "showProfilePicEditorIcon"
                            : "hideProfilePicEditorIcon"
                        }`}
                      ></i>
                    </label>
                  </Tooltip>

                  <span
                    className={
                      profilePercentage < 50
                        ? "ppPercentage_50 ppPercentageText"
                        : profilePercentage < 70
                        ? "ppPercentage_70 ppPercentageText"
                        : profilePercentage < 90
                        ? "ppPercentage_90 ppPercentageText"
                        : "ppPercentage_100 ppPercentageText"
                    }
                  >
                    {profilePercentage || ""} %
                  </span>
                </div>
              </div>
              <div
                className="pmProgressContainer1"
                style={{
                  background: `conic-gradient(
                    ${
                      profilePercentage < 50
                        ? "#FF4500" // OrangeRed
                        : profilePercentage < 70
                        ? "#FFA500" // Orange
                        : profilePercentage < 90
                        ? "#ADFF2F" // GreenYellow
                        : "#32CD32" // LimeGreen
                    } 0% ${profilePercentage}%, 
                    #f2f2f2 ${profilePercentage}% 100%
                  )`,
                }}
              ></div>
            </div>
          </MDBCol>
          {/**code progress ends*/}

          {/* ___________________________________ Basic Profile Detail Container _______________________________ */}

          <MDBCol className="pmDetailContainer">
            {/*______________ Row - 1 ( Name & Company ) __________ */}
            <MDBRow className="profiledetails ">
              <h3 className="pmTabHeadings pm_H_Pad pmcent">
                {pfname || ""}
                <Tooltip title="Edit" arrow>
                  <IconButton onClick={toggleBasicDetailModal}>
                    <EditIcon className="pmEditIcon" />
                  </IconButton>
                </Tooltip>
              </h3>
              <h6 className="pm_H_Pad ">
                <MDBIcon fas icon="building" className="pmBasicIcons" />
                &nbsp;
                {currentEmployment || ""}
              </h6>
            </MDBRow>

            <hr />

            {/*______________ Row - 2 ( Details ) __________ */}
            <MDBRow>
              <MDBCol className="pmNamefields">
                <h6>
                  <MDBIcon icon="map-marker-alt" className="pmBasicIcons" />{" "}
                  &nbsp;{location || ""}
                </h6>
                <h6>
                  <WorkOutlineIcon className="pmBasicIcons" />
                  {expYear || ""}
                  {expYear == 1 ? " Year" : " Years"}
                  {" - "}
                  {expMonth || ""} {expMonth == 1 ? "Month" : "Months"}{" "}
                </h6>

                <h6>
                  <MDBIcon icon="wallet" className="pmBasicIcons" /> &nbsp;
                  {expSalary || ""}
                </h6>
              </MDBCol>

              <MDBCol className="  pmNamefields pmNamefieldsSeparator">
                <h6>
                  <i className="fas fa-phone pmBasicIcons" /> &nbsp;
                  {mobile || ""}{" "}
                  {isValidMobile(mobile) && (
                    <VerifiedIcon className="pmVerifiedIcon" />
                  )}
                </h6>
                <h6>
                  <i className="far fa-envelope pmBasicIcons" /> &nbsp;
                  {email || ""}
                  {isValidEmail(email) && (
                    <VerifiedIcon className="pmVerifiedIcon" />
                  )}
                </h6>
                <h6>
                  <MDBIcon icon="calendar-week" className="pmBasicIcons" />{" "}
                  &nbsp;{noticePeriod || ""} Notice Period
                </h6>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </div>

      {/* ___________________________________ Basic Profile Modal Code start____________________________ */}
      <Modal
        open={basicDetailModalStatus}
        onClose={setBasicDetailModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          {/*_____________________ Modal Headings _____________________ */}
          <div className="">
            <h5 className="pmTabHeadings">Basic Details</h5>
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={toggleBasicDetailModal}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>


            {/*__________ Component call for Modal using Props __________ */}
            <StudentBasicDetailModal
              togglingModal={toggleBasicDetailModal}
              pfname={pfname}
              mobile={mobile}
              expYear={expYear}
              expMonth={expMonth}
              noticePeriod={noticePeriod}
              location={location}
              currentSalary={expSalary}
              setPfname={setPfname}
              setMobile={setMobile}
              setExpYear={setExpYear}
              setExpmonth={setExpmonth}
              setNoticePeriod={setNoticePeriod}
              expSalary={expSalary}
              setExpSalary={setExpSalary}
              setLocation={setLocation}
              fetchUserData={fetchUserData}
            />
          </div>
        </div>
      </Modal>
      {/* ___________________________________ Basic Profile Modal Code Ends  ____________________________ */}

      {/* ___________________________________  Profile Picture Modal Code start____________________________ */}

      <ImageCropperTemplate2
        open={profilePictureModalStatus}
        setOpen={setProfilePictureModalStatus}
        croppedImageDataUrl={croppedProfileImgURL}
        setCroppedImageDataUrl={setCroppedProfileImgURL}
        handleSave={saveProfilePicture}
      />
    </div>
  );
}

export default StudentProfilePage;
