import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import BatchInfoModal from "./BatchInfoModal";
import QuizIcon from "@mui/icons-material/Quiz";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageBatchTestLandingPage from "./ManageBatchTest/ManageBatchTestLandingPage";
import ManageBatchInstructorLandingPage from "./ManageBatchInstructor/ManageBatchInstructorLandingPage";
import ManageBatchStudentLandingPage from "./ManageBatchStudent/ManageBatchStudentLandingPage";
import ManageBatchSubjectLandingPage from "./ManageBatchSubjects/ManageBatchSubjectLandingPage";
import SubjectIcon from "@mui/icons-material/Subject";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ExplicitIcon from "@mui/icons-material/Explicit";
import ManageBatchTestSeriesLandingPage from "./ManageBatchTestSeries/ManageBatchTestSeriesLandingPage";
import ManageBatchExamLandingPage from "./ManageBatchExams/ManageBatchExamLandingPage";

function BatchManagementLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [openBatchInfo, setOpenBatchInfo] = useState(false);
  const [editData, setEditData] = useState([]);
  const [manageTestView, setManageTestView] = useState(false);
  const [manageInstrutorView, setManageInstrutorView] = useState(false);
  const [manageStudentView, setmanageStudentView] = useState(false);
  const [manageSubjectView, setmanageSubjectView] = useState(false);
  const [manageTestSeries, setmanageTestSeries] = useState(false);
  const [mangeExams, setmangeExams] = useState(false);
  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getbatchinfo`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Additional Functions if the custom menu added(custommenuitems)
  //--------------------------------------------------------------------------------

  const handleManageBatchTestView = (row, rowid) => {
    setEditData(row);
    setManageTestView(true);
  };

  const handleManageBatchTestClose = (row, rowid) => {
    setEditData("");
    setManageTestView(false);
    SetDataBaseUpdate(true);
  };

  const handleManageInstructorView = (row, rowid) => {
    setEditData(row);
    setManageInstrutorView(true);
  };

  const handleManageInstructorClose = (row, rowid) => {
    setEditData("");
    setManageInstrutorView(false);
  };

  const handleManageStudentView = (row, rowid) => {
    setEditData(row);
    setmanageStudentView(true);
  };

  const handleManageStudentViewClose = (row, rowid) => {
    setEditData("");
    setmanageStudentView(false);
  };

  const handleManageSubjectView = (row, rowid) => {
    setEditData(row);
    setmanageSubjectView(true);
  };

  const handleManageSubjectViewClose = (row, rowid) => {
    setEditData("");
    setmanageSubjectView(false);
  };

  const handleManageTestSeries = (row, rowid) => {
    setEditData(row);
    setmanageTestSeries(true);
  };

  const handleManageTestSeriesClose = (row, rowid) => {
    setEditData("");
    setmanageTestSeries(false);
  };

  const handleManageExams = (row, rowid) => {
    setEditData(row);
    setmangeExams(true);
  };

  const handleManageExamsClose = (row, rowid) => {
    setEditData("");
    setmangeExams(false);
  };

  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: QuizIcon, //icon Name (import from the Mui)
      label: "Manage Tests", //Name of the MenuItem
      onClick: handleManageBatchTestView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
    {
      icon: BadgeIcon,
      label: "Manage Instructors",
      onClick: handleManageInstructorView,
      IconColor: "blue",
    },
    {
      icon: GroupsIcon,
      label: "Manage Students",
      onClick: handleManageStudentView,
      IconColor: "green",
    },

    {
      icon: SubjectIcon,
      label: "Manage Subjects",
      onClick: handleManageSubjectView,
      IconColor: "blue",
    },

    {
      icon: ListAltIcon,
      label: "Manage Test Series",
      onClick: handleManageTestSeries,
      IconColor: "green",
    },

    {
      icon: ExplicitIcon,
      label: "Manage Exams",
      onClick: handleManageExams,
      IconColor: "blue",
    },
  ];

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    setEditData(rowdata);
    setOpenBatchInfo(true);
  };

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setEditData("");
    setOpenBatchInfo(true);
  };

  const handleCloseBatchInfo = () => {
    setEditData("");
    setOpenBatchInfo(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      {!manageTestView &&
        !manageInstrutorView &&
        !manageStudentView &&
        !manageSubjectView &&
        !manageTestSeries &&
        !mangeExams && (
          <TableHeaderTemplate
            columns={renderColumns} // Columns render with default column if empty
            rows={renderRows} // Rows render
            customMenuItems={customMenuItems} // Addition menu items
            handleEdit={handleEdit} //Handle the Edit function
            deleteApi="deletebatchinfo" // Delete Api
            tableNameForExport="Batch_Management" // Export the Data to excel/csv (If name requred for the Excel or Csv)
            is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
            handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
            Manage_button_name="Create Batch" //Name of the Manage button(in center of the header)
            switchColumnName="IsResultShown" // Swithc required in the Table(one of the column)
            swithcUpdateApi="updatebatchinfo" // Api to handle the Toggle of the swithc
            // dropdownColumnName="IsResultShown" // Yes or No Dropdown required in the Table(one of the column)
            // dropdownUpdateApi="updatebatchinfo"// Api to handle the Dropdown of the (yes or no)
            SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
            DefaultColumnNames={["BatchName", "StartDate", "EndDate", "Tests"]} // Add to show the columns in a default render
            CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
          />
        )}
      {/* _______________________End_____________________________ */}

      {/* ________________BatchInforamation modal___________ */}
      <BatchInfoModal
        open={openBatchInfo}
        close={handleCloseBatchInfo}
        setUpdate={SetDataBaseUpdate}
        editData={editData}
      />
      {/* ___________________end__________________ */}

      {/* ______________ManageTest__________ */}
      {manageTestView && (
        <ManageBatchTestLandingPage
          handleManageBatchTestClose={handleManageBatchTestClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}
      {/* ___________ManageInstructor__________ */}
      {manageInstrutorView && (
        <ManageBatchInstructorLandingPage
          handleManageInstructorClose={handleManageInstructorClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}

      {/* ___________ManageStudentView____________ */}
      {manageStudentView && (
        <ManageBatchStudentLandingPage
          handleManageStudentViewClose={handleManageStudentViewClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}

      {/* ___________ManageSubjectView____________ */}
      {manageSubjectView && (
        <ManageBatchSubjectLandingPage
          handleManageSubjectViewClose={handleManageSubjectViewClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}

      {/* ___________ManageTestseriesView____________ */}
      {manageTestSeries && (
        <ManageBatchTestSeriesLandingPage
          handleManageTestSeriesClose={handleManageTestSeriesClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}

      {/* ________ManageExamsViews_________ */}
      {mangeExams && (
        <ManageBatchExamLandingPage
          handleManageExamsClose={handleManageExamsClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}
    </div>
  );
}

export default BatchManagementLandingPage;
