import React, { useState, useEffect } from "react";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import { baseURL } from "../../http";
import axios from "axios";

function TPAExamMain() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);

  // Fetch exams from the database on component mount
  useEffect(() => {
    const PaymentData = async () => {
      try {
        const response = await axios.get(`${baseURL}/payment-get-all`);
        const data = response.data.data; // Access the actual array of payment data
  
        const filteredData = data.filter(item => item.product_category === "Course");

      // Get column names from the filtered data
      const columnNames = Object.keys(filteredData[0] || {});
      const columns = columnNames.map((columnName) => ({
        field: columnName,
        headerName: columnName,
      }));

      console.log("Column data:", columnNames);

      setRenderColumns(columns);

      // Format rows data
      const rows = filteredData.map((row, index) => ({
        id: index + 1, // Use index as unique ID for table row
        ...row,
      }));
      setRenderRows(rows);
      setDataBaseUpdate(false);
    } catch (error) {
      console.error("Error fetching payment data:", error);
    }
  };

  PaymentData();
}, [dataBaseUpdate]);



  return (
    <div>
          <TableHeaderTemplate
          columns={renderColumns}
          rows={renderRows}
          is_Manage_Button_Required={true}
          deleteApi="payment-delete"
          Manage_button_name="TPA Payments"
          SetDataBaseUpdate={setDataBaseUpdate}
          switchColumnName="IsApproved"
          swithcUpdateApi="blogs_update_isapproved"
          DefaultColumnNames={["user_email", "user_phone", "product_category", "product_name", "amount_paid", "status", "razorpay_payment_id", "razorpay_order_id", "razorpay_signature"]}
          />
    </div>
  );
}

export default TPAExamMain;
