import React from 'react';
import './QABlog.css';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CommentIcon from '@mui/icons-material/Comment';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const RightSidebar = ({ totalQuestions, totalAnswers }) => {
  return (
    <div className="right-section">
      <div className="right-section-content">
        <div className="statistics-header">
          <EqualizerIcon className="stat-icon" />
          <h5>Our Statistics</h5>
        </div>
        <hr className="Question_FooterLine" />

        <div className="stat-item">
          <QuestionAnswerIcon className="stat-icon" />
          <p>Questions: <span className="stat-value">{totalQuestions}</span></p>
        </div>

        <div className="stat-item">
          <CommentIcon className="stat-icon" />
          <p>Answers: <span className="stat-value">{totalAnswers}</span></p>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
