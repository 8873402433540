import React, { useState } from "react";
import Editor1 from "../../Editor/Editor1";
import DescriptionTemplate from "./DescriptionTemplate";

function Description({selectedCourse,description,setDescription}) {
  // const[description,setDescription]=useState([]);
 
  return (
    <div>
     <DescriptionTemplate 
      selectedCourse={selectedCourse}
     title="Description"
        content={description}
        setContent={setDescription}/>
    </div>
  );
}

export default Description;
