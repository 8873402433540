import React, { useState, useEffect } from "react";

import DownloadIcon from '@mui/icons-material/Download';
import TableHeaderTemplate from '../GeneralTemplateComponent/TableHeaderTemplate';
import { baseURL } from "../../http";
import axios from "axios";

import AddReceiptModalPage from "./AddReceiptModalPage";
import ReceiptDownloadPage from "./ReceiptDownloadPage";



function ReceiptMain() {
  //--------------------------------------------------------------------------------
  // State Handles
  //--------------------------------------------------------------------------------
      
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openEditMode, setopenEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState();
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false);
  const [downloadData, setDownloadData] = useState(0);
  const [downloader, setDownloader] = useState(0);

  const [Recipt_downalod, setRecipt_downalod] = useState(false);

    const [receiptFormData, setReceiptFormData] = useState({
      editItemID:"",
      studentName: "",
      email: "",
      courseCategory: "",
      paidDate: "",
      paidThrough: "",
      amount: "",
      createdBy: localStorage.getItem('HTES_user_id'),
      modifiedby:""
    });

    const [CloseActionDialog, setCloseActionDialog] = useState(false);
    //--------------------------------------------------------------------------------
    //Fetch the data from the database initially
    //--------------------------------------------------------------------------------
    useEffect(() => {
      const fetchTableData = async () => {
        try {
          const response = await axios.get(`${baseURL}/Reciept_fetch_all`);
          const data = response.data;
          // Assuming data contains `fillable` and the row data
          const columnNames = Object.keys(data[0] || {});
          const columns = columnNames.map((columnName) => ({
            field: columnName,
            headerName: columnName,
          }));
          setRenderColumns(columns);
          // Format rows data
          const rows = data.map((row, index) => ({
            id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
            ...row,
          }));
          setRenderRows(rows);
          SetDataBaseUpdate(false);
        } catch (error) {
          console.error("Error fetching table data:", error);
        }
      };
  
      fetchTableData();
    }, [dataBaseUpdate]);
  
    //--------------------------------------------------------------------------------
    //Handle edit Function (in table action icon)
    //--------------------------------------------------------------------------------
  
    const handleEdit = (rowdata, id) => {
      setInitialFormData(rowdata);
      setopenEditMode((prevMode) => !prevMode);

      setOpenTemplateModal(true);

      console.log(JSON.stringify(rowdata))
      setReceiptFormData({
        editItemID:rowdata.id,
        studentName: rowdata.studentname,
        email: rowdata.email,
        courseCategory: rowdata.courseCategory,
        paidDate: rowdata.paiddate,
        paidThrough: rowdata.paidthrough,
        amount: rowdata.amount,
        createdBy:"",
        modifiedby: localStorage.getItem('HTES_user_id'),
        
        
      });
    };
  
    //--------------------------------------------------------------------------------
    //Handle More/manage Function (in table )
    //--------------------------------------------------------------------------------
    const handleAddMoreFunctionality = () => {
      setOpenTemplateModal(true);

      setReceiptFormData({
        id:"",
        studentName: "",
        email: "",
        courseCategory: "",
        paidDate: "",
        paidThrough: "",
        amount: "",
        createdBy: localStorage.getItem('HTES_user_id'),
      });
  
    };

  //--------------------------------------------------------------------------------
  //Handle More/manage Function (in table )
  //--------------------------------------------------------------------------------
  const Recipt_close = () => {
    setRecipt_downalod(false);
  };

//--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  //--------------------------------------------------------------------------------
  //handleClose
  //--------------------------------------------------------------------------------
  const handleClose = () => {
    setOpenTemplateModal(false);
  };

  //--------------------------------------------------------------------------------
  //handleDownload
  //--------------------------------------------------------------------------------
  const handleDownload = async (itemID) => {
    setRecipt_downalod(true)
    console.log('data',JSON.stringify(itemID));
    console.log('in-handle-download');
    setDownloadData(itemID);
    setDownloader(downloader+1);
    handleActionClose();

  };

  //--------------------------------------------------------------------------------
  // customMenuItems
  //--------------------------------------------------------------------------------

  const customMenuItems = [
    {
      icon: DownloadIcon,
      label: "Download Receipt",
      onClick: handleDownload,
      IconColor: "green",
    },
  ];

  //--------------------------------------------------------------------------------
  //  return ( JSX Return)
  //--------------------------------------------------------------------------------


 
  
    return (
      <div>
        {/* _______________________Main Component call____________ */}
        <TableHeaderTemplate
          columns={renderColumns} // Columns render
          rows={renderRows} // Rows render
          customMenuItems={customMenuItems}
          handleEdit={handleEdit} //Edit Row function
          deleteApi="Reciept_delete" // Delete API
          tableNameForExport="Course" // optional
          is_Manage_Button_Required={true} // Manage button view if addition items added(additional functionality)
          handleManageButtonClick={handleAddMoreFunctionality} // if optional data required(additional functionality function)
          Manage_button_name="Manage Receipt" //additional button name
          // switchColumnName="isPublished" // optional
          swithcUpdateApi="updatecourse" //optional
          // dropdownColumnName="isPaid" //optional
          dropdownUpdateApi="updatecourse" //optional 
          SetDataBaseUpdate={SetDataBaseUpdate} //To maintain the table updated
          DefaultColumnNames={['id', 'studentname','paiddate', 'amount']} //DefaultColumnsnameshows
          CloseActionDialog={handleActionClose}
        />

        {/* _______________________End_____________________________ */}


         {/* _________________call the template modal________________ */}
          <AddReceiptModalPage open={openTemplateModal} close={handleClose} 
          receiptFormData={receiptFormData}
          setReceiptFormData={setReceiptFormData}
          SetDataBaseUpdate={SetDataBaseUpdate} //To maintain the table updated
          
          
          />
      {/* __________________________end___________________________ */}


       {/* Render the download component if an item ID is set */}
       {downloadData && (
        <ReceiptDownloadPage downloadData={downloadData}  downloader={downloader}/>
    )}
        
          
  
      </div>
    );
  }

export default ReceiptMain
