import React, { useState } from 'react';
import FAQPage from './FAQPage';


function FAQInput() {
    const [FAQData, setFAQData] = useState([
      {
        title: 'Test Series FAQs',
        content: 'Commonly asked questions about test series.',
        faqItems: [
          {
            question: 'How to enroll in a test series?',
            answer: 'To enroll in a test series, follow these steps...',
          },
          {
            question: 'How can I access my test results?',
            answer: 'You can access your test results by...',
          },
          // Add more FAQ items for this section
        ],
      },
        
          // {
          //   title: 'Testbook Pass',
          //   content: 'Know everything about Testbook Pass.',
          // },
          // {
          //   title: 'Tests FAQs',
          //   content: 'FAQs regarding mock tests.',
          // },
          // {
          //   title: 'Payment/Transaction',
          //   content: 'FAQs regarding payment issues.',
          // },
    ]);
  return (
    <div>
        {FAQData && <FAQPage FAQData={FAQData} />}
    </div>
  )
}

export default FAQInput
