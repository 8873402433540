import React, { useEffect } from "react";
import { Avatar, Tooltip, Typography } from "@mui/material";

function ResultSideNav({
  filteredQuestions,
  ResultData,
  onAvatarClick,
  selectedQuestionIndex,
}) {
  //--------------------------------------------------------------------------------
  // Check if ExamStatus exists
  //-------------------------------------------------------------------------------
  if (!ResultData?.ExamStatus) {
    return null; // Render nothing if ExamStatus is not available
  }

  // Safely parse ExamStatus if it's a string
  const examStatus =
    typeof ResultData.ExamStatus === "string"
      ? JSON.parse(ResultData.ExamStatus)
      : ResultData.ExamStatus;

  // Ensure examStatus is an array and has at least one element
  const firstExamStatus = Array.isArray(examStatus) ? examStatus[0] : null;

  if (!firstExamStatus) {
    return null; // Return nothing if the array is empty or the first element is undefined
  }

  const { SeletedOptions, resultArray } = firstExamStatus || {};

  const correctCount = resultArray.filter((result) => result === 1).length;
  const unansweredCount = SeletedOptions.filter(
    (option) => option === null
  ).length;
  const wrong = filteredQuestions.length - correctCount - unansweredCount;

  //--------------------------------------------------------------------------------
  // Helper function to determine the color of each avatar
  //--------------------------------------------------------------------------------

  const getAvatarColor = (index) => {
    if (SeletedOptions[index] === null) {
      return "orange"; // Unanswered
    } else {
      return resultArray[index] === 1 ? "green" : "red"; // Correct or Wrong
    }
  };

  //--------------------------------------------------------------------------------
  // Avatar border style for the selected question
  //--------------------------------------------------------------------------------

  const getAvatarBorder = (index) => {
    console.log("index", index, selectedQuestionIndex);
    return selectedQuestionIndex === index ? "3px solid blue" : "none";
  };

  return (
    <div className="result-sidenav-div-containr-to-view-the-side-nav">
      {/* Avatar and User Information */}
      <div className="avatar-and-name-alignemnt-font">
        <Tooltip title={ResultData.UserId} arrow>
          <Avatar
            sx={{
              backgroundColor: "skyblue",
              fontWeight: "bold",
              color: "black",
            }}
            src="/static/images/avatar/1.jpg"
            alt={
              ResultData.UserId ? ResultData.UserId.charAt(0).toUpperCase() : ""
            }
          />
        </Tooltip>
        <div className="text-content">{ResultData.UserId}</div>
      </div>

      {/* Question Status Summary */}
      <div className="question-status-grid-container">
        <div className="correcet-answer-result-side-nav-container">
          <Avatar className="correcet-answer-result-side-nav"></Avatar>Correct(
          {correctCount})
        </div>
        <div className="correcet-answer-result-side-nav-container">
          <Avatar className="wrong-answer-result-side-nav"></Avatar>Wrong(
          {wrong})
        </div>
        <div className="correcet-answer-result-side-nav-container">
          <Avatar className="unanswer-answer-result-side-nav"></Avatar>
          Unanswered({unansweredCount})
        </div>
        <div className="correcet-answer-result-side-nav-container">
          <Avatar className="user-answer-result-side-nav"></Avatar>User Answered
        </div>
      </div>

      {/* Section Label */}
      <div className="section-label">
        <Typography>
          <span className="user-answer-information-font-size">
            &nbsp;User Answer Information:
          </span>
        </Typography>
      </div>

      {/* Avatars for Each Question */}
      <div className="question-avatars-container-scroll-right-side-nav">
        <div className="question-avatars-container">
          {filteredQuestions.map((_, index) => (
            <Tooltip key={index} title={`Question ${index + 1}`} arrow>
              <Avatar
                onClick={() => onAvatarClick(index)}
                sx={{
                  bgcolor: getAvatarColor(index),
                  color: "white",
                  width: 32,
                  height: 32,
                  fontSize: 15,
                  border: getAvatarBorder(index),
                }}
              >
                {index + 1}
              </Avatar>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ResultSideNav;
