import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import axios from "axios";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import CreateBlog from "./CreateBlog";
import { customAlert } from "../SweetAlertCommon/Custom";
import BlogModal from "./BlogModal";

function Blog() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);
  const [openmanageBlog, setManageBlog] = useState(false);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [closeActionDialog, setCloseActionDialog] = useState(false);
  const LoginEmail = localStorage.getItem("HTES_user_name");

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`${baseURL}/blogs_getall`);
        const data = response.data;

        // Assuming data contains blog fields
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));

        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Use index as unique ID for table row
          ...row,
        }));
        setRenderRows(rows);
        setDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogData();
  }, [dataBaseUpdate]);

  const handleEdit = (rowData) => {
    setEditData(rowData); // Save the row data for the blog being edited
    if (LoginEmail) {
      setOpenEdit(true); // Open the edit modal
    } else {
      customAlert("", "Please Login To Edit Your Blog", "warning");
    }
  };

  const handleAddMoreFunctionality = () => {
    if (LoginEmail) {
      setManageBlog(true);
    } else {
      customAlert(
        "", // No title needed, you can adjust as per your design
        "Please Login To Add Your Blog",
        "warning" // Alert type: warning, success, etc.
      );
    }
  };

  const handleManageBlogclose = () => {
    setManageBlog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEditData(null); // Clear edit data after closing
  };

  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <>
      <div>
        {/* Main Table Rendering */}
        <TableHeaderTemplate
          columns={renderColumns}
          rows={renderRows}
          handleEdit={handleEdit}
          is_Manage_Button_Required={true}
          handleManageButtonClick={handleAddMoreFunctionality}
          deleteApi="blogs_delete"
          Manage_button_name="Blogs"
          SetDataBaseUpdate={setDataBaseUpdate}
          switchColumnName="IsApproved"
          swithcUpdateApi="blogs_update_isapproved"
          DefaultColumnNames={[
            "Category",
            "heading",
            "Content",
            "normalContent",
            "Created_by",
            "Modified_by",
            "Created_date",
            "Modified_date",
            "IsApproved",
          ]} // Default columns
          CloseActionDialog={handleActionClose} // Handle the action dialog close
        />
      </div>
      <CreateBlog
        open={openmanageBlog}
        close={handleManageBlogclose}
        setDataBaseUpdate={setDataBaseUpdate}
        editData={editData}
      />

      {openEdit && (
        <BlogModal
          open={openEdit}
          closeAddBlog={handleCloseEdit}
          setDataBaseUpdate={setDataBaseUpdate}
          editData={editData} 
        />
      )}
    </>
  );
}

export default Blog;
