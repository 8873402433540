import React, { useState, useEffect } from "react";
import {
  Paper,
  Avatar,
  Typography,
  Divider,
  Stack,
  Badge,
  Grid,
  Tooltip,
} from "@mui/material";


import "./Quiz.css";

const QuizRightNav = ({
    email,
  viewedQuestions,
  setViewedQuestions,
  selectedOptions,
  markedForReview,
  handleAvatarClick,
  currentQuestionIndex,
  totalQuestions,
}) => {
  
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const [userData, setUserData] = useState({
    selectedOptions: [],
    markedForReview: [],
    
    answeredQuestions: [],
  });

  function countNonEmptyStrings(arr) {
    let count = 0;
    for (let i = 0; i < arr?.length; i++) {
        if (arr[i].trim() !== '') { // Checking if the element is not an empty string
            count++;
        }
    }
    return count;
}

function countTrueFalse(arr) {
  let trueCount = 0;
  let falseCount = 0;
  
  for (let i = 0; i < arr?.length; i++) {
      if (arr[i] === true) {
          trueCount++;
      } else {
          falseCount++;
      }
  }
  
  return { trueCount, falseCount };
}

function countReview(arr) {
  let trueCount = 0;
  
  for (let i = 0; i < arr?.length; i++) {
      if (arr[i] === true) {
          trueCount++;
      };
  }
  
  return  trueCount;
}



const review = countReview(markedForReview);
const  counts = countTrueFalse(viewedQuestions);

//console.log("True count:", counts.trueCount);
//console.log("False count:", counts.falseCount);

const nonEmptyCount = countNonEmptyStrings(userData.selectedOptions)
const result = counts.trueCount - nonEmptyCount;
const finalResult = result < 0 ? 0 : result;

  //console.log('userData',userData,viewedQuestions)

  const handleClick = (index) => {
    handleAvatarClick(index);
    setHighlightedIndex(index);
    setViewedQuestions((prevViewedQuestions) => {
      // Mark the clicked question as viewed
      const newViewedQuestions = [...prevViewedQuestions];
      newViewedQuestions[index] = true;
      return newViewedQuestions;
    });
  };

  useEffect(() => {
    setUserData((prevData) => ({
      ...prevData,
      selectedOptions,
      markedForReview,
    }));
  }, [selectedOptions, markedForReview]);



  useEffect(() => {
    if (selectedOptions[currentQuestionIndex] !== "") {
      setUserData((prevData) => ({
        ...prevData,
        answeredQuestions: [
          ...prevData.answeredQuestions,
          currentQuestionIndex,
        ],
      }));
    }
  }, [selectedOptions, currentQuestionIndex]);

  useEffect(() => {
    setHighlightedIndex(currentQuestionIndex);
    handleClick(currentQuestionIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex]);



  const avatars = [];

  for (let i = 0; i < totalQuestions; i++) {
    let color = "grey"; // Default color is grey for all avatars
    let underlineStyle = ""; // Default underline style is empty
    // Change color if the question is answered or marked for review
    if (selectedOptions[i] !== "") {
      color = "green"; // Answered
      underlineStyle = markedForReview[i] ? "3px solid yellow" : "";
      // Yellow underline if marked for review after being answered
    } else if (markedForReview[i]) {
      color = "rgb(193, 100, 231)"; // Marked for review
    } else if (viewedQuestions[i]) {
      color = "rgb(217, 158, 69)"; // Viewed but not answered or marked for review
    }

    avatars.push(
      <Avatar
        key={i}
        onClick={() => handleClick(i)}
        id="button-zoom" // Add class for zoom effect
        sx={{
          margin: "5px",
          cursor: "pointer",
          backgroundColor: color,
          color: "white",
          fontWeight: "500",
          fontSize: "17px",
          border: highlightedIndex === i ? "4px solid  blue" : "borderStyle", // Highlight border if current index
          position: "relative", // Add position relative for absolute positioning of underline
          "&::after": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: underlineStyle === "3px solid yellow" ? "9px" : 0, // Adjust height based on underline style
            backgroundColor: "rgb(193, 60, 211)", // Yellow underline color
            zIndex: 1, // Ensure it appears above the Avatar
          },
        }}
      >
        {i + 1}
      </Avatar>
    );
  }

  return (
    <div className="quiz-right-nav-container">
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          height: "92.5vh",
          backgroundColor: "aliceblue",
          overflowY: "auto",
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
          // border: '1px solid rgb(216, 224, 240)',
          borderTop: "unset",
          marginTop:'4px',

        }}
      >
        <Grid>
          <Stack direction="column" spacing={1} margin="6px">
            <div className="avatar-and-name-alignemnt-font">
            <Tooltip title={email} arrow>
              <Avatar
                sx={{
                  backgroundColor: "skyblue",
                  fontWeight: "bold",
                  color: "black",
                }}
                src="/static/images/avatar/1.jpg"
                alt={email ? email.charAt(0).toUpperCase() : ""}
              ></Avatar>
               </Tooltip>
              <div className="text-content">{email}</div>
              
           
            </div>
          </Stack>
        </Grid>
        <Divider style={{ margin: "3px 0" }} />
        <Grid>
          <div className="badge-row">
            <BadgeContainer>
              <Badge size="small" sx={{ color: "grey", margin: "4px" }}>
              <Avatar style={{backgroundColor:"grey",width:'19px',height:'19px',fontSize:'8px',textAlign:'center',marginTop:'2px'}}>{counts.falseCount}</Avatar>
                <Typography color="black">&nbsp;Not Visited</Typography>
              </Badge>
              <Badge
                size="small"
                sx={{ color: "rgb(217, 158, 69)", marginLeft: "26px" }}
              >
                <Avatar style={{backgroundColor:"rgb(217, 158, 69)",width:'19px',height:'19px',fontSize:'8px',textAlign:'center',marginTop:'2px'}}>{finalResult}</Avatar>
                <Typography color="black">&nbsp;Not Answered</Typography>
              </Badge>
            </BadgeContainer>
            <BadgeContainer>
              <Badge size="small" sx={{ color: "green", margin: "4px" }}>
                <Avatar style={{backgroundColor:'green',width:'19px',height:'19px',fontSize:'8px',textAlign:'center',marginTop:'2px'}}>{nonEmptyCount}</Avatar>
                <Typography color="black"> &nbsp;Answered</Typography>
              </Badge>
              <Badge
                size="small"
                sx={{ color: "rgb(193, 60, 211)", marginLeft: "35px" }}
              >
                <Avatar style={{backgroundColor:"rgb(193, 60, 211)",width:'19px',height:'19px',fontSize:'8px',textAlign:'center',marginTop:'2px'}}>{review}</Avatar>
                <Typography color="black" className="text-content1">&nbsp;Marked for review</Typography>
              </Badge>

              
            </BadgeContainer>
            <BadgeContainer>
              <Badge
                size="small"
                sx={{ color: "rgb(193, 60, 211)", margin: "4px" }}
              >
                <Avatar style={{border:'3px solid blue',backgroundColor:"aliceblue",width:'19px',height:'19px',fontSize:'8px',textAlign:'center',marginTop:'2px',color:'black'}}>{highlightedIndex+1}</Avatar>
                <Typography color="black">&nbsp;Current Question</Typography>
              </Badge>
              </BadgeContainer>
          </div>
        </Grid>
        <div className="section-label">
          <Typography>
            &nbsp;SECTION:{" "}
            <span style={{ fontWeight: "bold" }}>Questions Information</span>
          </Typography>
        </div>
        <div className="avatar-container">{avatars}</div>
      </Paper>
    </div>
  );
};

const BadgeContainer = ({ children }) => (
  <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
);

export default QuizRightNav;
