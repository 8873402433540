import React, { useState, useEffect } from 'react';
import Navbar, { QuestionDrawer } from './QABlog_Navbar';
import QuestionCard from './QABlog_Question_Card';
import LeftSidebar from './QABlog_Left_SideSection';
import QuestionDetail from './QABlog_QuestionDetails';
import './QABlog.css';
import axios from 'axios';
import RightSidebar from './QABlog_Right_Section';

function QABlog_landPage({ questionData }) {

  //---------------------------------------------------------------------------------
  //State handle
  //---------------------------------------------------------------------------------

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);



  //---------------------------------------------------------------------------------
  // Fetch questions from the backend
  //---------------------------------------------------------------------------------

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:8000/api/questionblog');
        setQuestions(response.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  //----------------------------------------------------------------
  //Function to open and close the editor
  //----------------------------------------------------------------

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  //----------------------------------------------------------------
  //Function to submit the question in ask question
  //----------------------------------------------------------------

  const handleQuestionSave = async (question) => {
    try {
      const tagsArray = Array.isArray(question.tags) ? question.tags : [question.tags];
      const response = await axios.post('http://127.0.0.1:8000/api/questionblog', {
        normalContent: question.normalContent,
        htmlContent: question.htmlContent,
        tags: tagsArray
      });

      const createdQuestion = response.data;
      setQuestions((prevQuestions) => [...prevQuestions, createdQuestion]);
      setDrawerOpen(false);
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  //----------------------------------------------------------------
  //Function to handleCardClick
  //----------------------------------------------------------------

  const handleCardClick = (questionData) => {
    setSelectedQuestion(questionData);
  };

  //----------------------------------------------------------------
  //Function to handleGoBack
  //----------------------------------------------------------------

  const handleGoBack = () => {
    setSelectedQuestion(null);
  };

  //----------------------------------------------------------------
  //Function to format the date
  //----------------------------------------------------------------

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).replace(/ /g, '-');
  };

   // Calculate total number of questions and answers
   const totalQuestions = questions.length;
   const totalAnswers = questions.reduce((acc, question) => acc + (question.answers ? question.answers : 0), 0);
 

  return (
    <div className='common_Attribute'>

      {/* _______________________________________Navbar Start _______________________________________ */}

      <Navbar onAskQuestion={handleDrawerOpen} />

      <QuestionDrawer open={drawerOpen} onClose={handleDrawerClose} onSave={handleQuestionSave} />

      {/* _______________________Scrollable container___________________________ */}

      <div className="Scrablle_container">

        {/* ______________________Left section____________________________ */}
        <div className="left-section">
          <LeftSidebar />
        </div>

        {/* _______________________________________ question-card-container ___________________________________________ */}

        <div className="question-card-container">
          {selectedQuestion ? (
            <QuestionDetail questionData={selectedQuestion} onGoBack={handleGoBack} questionId={selectedQuestion.id} />

          ) : (
            questions.map((questionData, index) => (
              <QuestionCard
                key={index}
                id={index}
                question={questionData.normalContent}
                // question={questionData.content}
                votes={questionData.votes}
                answers={questionData.answers ? questionData.answers : 0}
                tags={questionData.tags}
                user={questionData.user}
                last_modified_date={formatDate(questionData.last_modified_date)}
                Like_count={questionData.Like_count}
                dis_Like_count={questionData.dis_Like_count}
                onClick={() => handleCardClick(questionData)}
              />
            ))
          )}
        </div>

        {/* _______________________ Right section____________________________ */}
        <div className='right-section'>
          <RightSidebar totalQuestions={totalQuestions} totalAnswers={totalAnswers}/>
        </div>
      </div>
    </div>
  );
}

export default QABlog_landPage;
