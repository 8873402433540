import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BlogModal from "./BlogModal";

function CreateBlog({ open, close, setDataBaseUpdate }) {
  const [isAddBlogOpen, setIsAddBlogOpen] = useState(false);

  //--------------------------------------------------------------------------------
  // Open and close Add Blog modal
  //--------------------------------------------------------------------------------
  const handleAddBlogOpen = () => {
    setIsAddBlogOpen(true);
    close(); // Close the outer modal when the add blog modal opens
  };

  const handleAddBlogClose = () => {
    setIsAddBlogOpen(false);
  };

  return (
    <div>
      <Modal open={open} onClose={close} className="course-template-modal">
        {/* _________________________Header_______________________ */}
        <div className="course-template-modal-container">
          <div className="course-name-template-container-with-content">
            <IconButton
              id="button-zoom"
              className="course-name-template-close-button"
              onClick={close}
            >
              <CloseIcon className="course-name-template-close-button-x" />
            </IconButton>
            <h5 className="course-name-template-header">Instructor Management</h5>
            {/* _________________________End_______________________ */}
            {/* _________________________Button, upload, download, add_______________________ */}
            <div className="course-name-template-add-course-icon">
              <div onClick={handleAddBlogOpen}>
                <AddBoxIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />
                <div>
                  Add<br />Blog
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <BlogModal
        open={isAddBlogOpen}
        closeAddBlog={handleAddBlogClose}
        setDataBaseUpdate={setDataBaseUpdate}
      />
    </div>
  );
}

export default CreateBlog;
