import "./App.css";
import Main from "./Main";
function App() {
  return (
    <>
      <Main />
      {/* <h1>test</h1> */}
    </>
  );
}

export default App;
