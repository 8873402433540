import React, { useState, useEffect } from "react";
import {
  IconButton,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Modal,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { baseURL } from "../../http";
import UpdateIcon from "@mui/icons-material/Update";
import { customAlert } from "../SweetAlertCommon/Custom";

function ManageTest({ open, handleClose, editTestData, SetDataBaseUpdate }) {
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    freeOrPaid: "",
    instructionLink: "",
    price: 0, // Added price field
    isNegativeMarking: "", // Now a string to match the dropdown
  });

  //--------------------------------------------------------------------------------
  // Prefill the modal fields with editTestData when the modal opens
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (editTestData) {
      setFormData({
        testName: editTestData.TestName || "",
        startDate: editTestData.StartDate || "",
        endDate: editTestData.EndDate || "",
        freeOrPaid: editTestData.FreeOrPaid || "",
        instructionLink: editTestData.InstructionLink || "",
        price: editTestData.Price || 0, // Prefill price if available
        isNegativeMarking: editTestData.IsNegativeMarking ? "Yes" : "No", // Prefill based on edit data
      });
    }
  }, [editTestData]);

  //--------------------------------------------------------------------------------
  // Watch for changes in freeOrPaid and disable/enable price input accordingly
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (formData.freeOrPaid === "Free") {
      setFormData((prev) => ({
        ...prev,
        price: 0, // Reset price when Free is selected
      }));
    }
  }, [formData.freeOrPaid]);

  //--------------------------------------------------------------------------------
  // Handle input changes
  //--------------------------------------------------------------------------------

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //--------------------------------------------------------------------------------
  // Handle form submission
  //--------------------------------------------------------------------------------

  const handleSubmit = async () => {
    try {
      const updatedData = {
        ...formData,
        isNegativeMarking: formData.isNegativeMarking === "Yes" ? true : false, // Convert dropdown value to a boolean
      };

      // Make API request
      const response = await axios.put(
        `${baseURL}/updateTest/${editTestData.id}`,
        updatedData
      );
      console.log("response", response);

      if (response.request.status === 200) {
        customAlert("", "Test updated successfully", "success");
        SetDataBaseUpdate(true); // Mark the database update
        handleClose(); // Close the modal on success
      }
    } catch (error) {
      console.error("Failed to update test:", error);
      customAlert("", "Failed to update test.", "warning");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="modal-center-alignment-to-center"
    >
      <div className="Manage-test-modal-outer-container">
        <div className="mange-test-edit-data-heading1">
          Manage Test - {editTestData?.TestName || ""}
        </div>
        <IconButton
          id="button-zoom"
          onClick={handleClose}
          className="addeducationalMapmodalcancel"
        >
          <CloseIcon className="addeducationalMapmodalcancelX" />
        </IconButton>
        <div>
          {/* Price and Free/Paid Dropdown in one row */}
          <div className="price-and-free-paid-textfiled-alignment">
            {/* Free or Paid Dropdown */}
            <FormControl fullWidth>
              <InputLabel>Free or Paid</InputLabel>
              <Select
                label="Free or Paid"
                name="freeOrPaid"
                value={formData.freeOrPaid}
                onChange={handleChange}
              >
                <MenuItem value="Free">Free</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
              </Select>
            </FormControl>

            {/* Price Input with Rupee Symbol */}
            <TextField
              disabled={formData.freeOrPaid === "Free"} // Disable when Free is selected
              fullWidth
              label="Price"
              name="price"
              type="number"
              value={formData.price}
              onChange={handleChange}
              min={0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              margin="normal"
            />
          </div>

          {/* Negative Marking Dropdown */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Negative Marking</InputLabel>
            <Select
              label="Negative Marking"
              name="isNegativeMarking"
              value={formData.isNegativeMarking}
              onChange={handleChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>

          {/* Instruction Link */}
          <TextField
            fullWidth
            label="Instruction Link"
            name="instructionLink"
            value={formData.instructionLink}
            onChange={handleChange}
            margin="normal"
          />

          {/* Start and End Date in Same Line */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Start Date"
                name="startDate"
                type="date"
                value={formData.startDate}
                onChange={handleChange}
                margin="normal"
                inputProps={{
                  min: new Date().toISOString().split("T")[0], // Restrict to today or earlier
                  style: { textTransform: "uppercase" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="End Date"
                name="endDate"
                type="date"
                value={formData.endDate}
                onChange={handleChange}
                margin="normal"
                inputProps={{
                  min: new Date().toISOString().split("T")[0], // Disable dates before today
                  style: { textTransform: "uppercase" },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="button-icons-alignments">
          <Button
            id="button-zoom"
            onClick={handleSubmit}
            color="success"
            variant="contained"
          >
            <UpdateIcon className="buttonicons" />
            &nbsp;Update
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ManageTest;
