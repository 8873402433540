import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Autocomplete,
  TextField,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { baseURL } from "../../../http";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InstructorCardTemplate from "../../InstructorManagement/InstructorCardTemplate";
import "./coursetemplate2.css";

const CourseTemplate2OInstructor = ({
  isChecked,
  selectedInstructors,
  setSelectedInstructors,
}) => {
  const [instructors, setInstructors] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const autocompleteRef = useRef();
  const [isExpand, setIsExpand] = useState(true);
  const [loginData,setLoginData]=useState([])

    //--------------------------------------------------------------------------------
  // Fetch all Login dat for validation data
  //--------------------------------------------------------------------------------

    const fetchLoginData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getLoginTable`);
        setLoginData(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error("Error fetching login data:", error);
      }
    };
  

  //--------------------------------------------------------------------------------
  // Fetch all instructor data
  //--------------------------------------------------------------------------------

  const fetchInstructors = async () => {
    try {
      const response = await axios.get(`${baseURL}/Instructor_fetch_all`);
      const allInstructors = response.data;

      // Filter instructors based on login data
      const filteredInstructors = allInstructors.filter((instructor) =>
        loginData.some(
          (login) =>
            login.email  === instructor.instructorEmail &&
            login.isBlocked === 0 && login.usertype === 'Teacher'
        )
      );

      setInstructors(filteredInstructors);
    } catch (error) {
      console.error("Error fetching instructors:", error);
    }
  };

    //--------------------------------------------------------------------------------
  //Callng the api to fix the Logic
  //--------------------------------------------------------------------------------
   
    useEffect(() => {
      fetchLoginData();
    }, []);
  
    useEffect(() => {
      if (loginData.length > 0) {
        fetchInstructors();
      }
    }, [loginData]);

    
 

  //--------------------------------------------------------------------------------
  // Add the instructor and save the row data
  //--------------------------------------------------------------------------------

  const handleAddInstructor = (event, newValue) => {
    if (newValue && !selectedInstructors.includes(newValue)) {
      setSelectedInstructors([...selectedInstructors, newValue]);
    }
    setIsAdding(false);
  };

  //--------------------------------------------------------------------------------
  // delete the instructor
  //--------------------------------------------------------------------------------

  const handleDeleteInstructor = (email) => {
    setSelectedInstructors(
      selectedInstructors.filter((inst) => inst.instructorEmail !== email)
    );
  };

  //--------------------------------------------------------------------------------
  // Handle the Auto complate toggle
  //--------------------------------------------------------------------------------

  const toggleAutocomplete = () => {
    setIsAdding(!isAdding);
  };

  //--------------------------------------------------------------------------------
  // Expand the contanier and collaps the container
  //--------------------------------------------------------------------------------

  const handleExpand = () => {
    setIsExpand((prev) => !prev);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            isExpand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpand}
            >
              <h6 className="coursetemplete2-title">Instructor</h6>

              <div>
                <IconButton>
                  {isExpand ? (
                    <ExpandLessIcon className="course-template-expand-icon-color" />
                  ) : (
                    <ExpandMoreIcon className="expand-icon-color" />
                  )}
                </IconButton>
                {!isAdding && isChecked && (
                  <Tooltip title="Add" arrow>
                    <IconButton onClick={toggleAutocomplete}>
                      <AddIcon className="courseTemplate2-edit-icon-color" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          {/* ______________________________Auto complate textfiled and chip area_____________ */}
          {isAdding ? (
            <Autocomplete
              disableClearable
              options={instructors}
              getOptionLabel={(option) => option.instructorEmail}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Instructor Email"
                  fullWidth
                />
              )}
              onChange={handleAddInstructor}
              ref={autocompleteRef}
            />
          ) : (
            <div className="selected-categories-container">
              {selectedInstructors &&
                selectedInstructors.map((instructor) => (
                  <Chip
                    className="coursetemplate2-chip-background-color"
                    key={instructor.instructorEmail}
                    label={instructor.instructorEmail}
                    onDelete={() =>
                      handleDeleteInstructor(instructor.instructorEmail)
                    }
                  />
                ))}
            </div>
          )}
          {/* ______________________________call the Instructor template component_____________ */}
          {isExpand && (
            <div className="coursetemplate2-instructor-cards-container">
              {selectedInstructors &&
                selectedInstructors.map((instructor, index) => (
                  <InstructorCardTemplate
                    key={index}
                    instructorData={instructor}
                  />
                ))}
            </div>
          )}
          {/* ______________________________end___________________________ */}
        </div>
      </div>
    </>
  );
};

export default CourseTemplate2OInstructor;
