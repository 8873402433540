import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../../Components/GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import InterViewQuestionManageTemplate from "./InterViewQuestionManageTemplate";
import InterviewQuestionGenaralInfoAddModal from "./InterViewQuestionAddEdit/InterviewQuestionGenaralInfoAddModal";

function InterviewQuestionLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openEditMode, setopenEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState();
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false);
  const [CloseActionDialog, setCloseActionDialog] = useState(false);

  //--------------------------------------------------------------------------------
  //Fetch the data from the database initially
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/Interview_Questions_fetch_all`
        );
        const data = response.data;
        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle edit Function (in table action icon)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata, id) => {
    setInitialFormData(rowdata);
    setopenEditMode(true);
  };

  const handleEditClose = () => {
    setInitialFormData("");
    setopenEditMode(false);
  };

  //--------------------------------------------------------------------------------
  //Handle More/manage Function (in table )
  //--------------------------------------------------------------------------------
  const handleAddMoreFunctionality = () => {
    setOpenTemplateModal(true);
  };

  const handleAddMoreFunctionalityCloseTemplateModal = () => {
    setOpenTemplateModal(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render
        rows={renderRows} // Rows render
        handleEdit={handleEdit} //Edit Row function
        deleteApi="Interview_Questions_delete" // Delete API
        tableNameForExport="Interview_Questions" // optional
        is_Manage_Button_Required={true} // Manage button view if addition items added(additional functionality)
        handleManageButtonClick={handleAddMoreFunctionality} // if optional data required(additional functionality function)
        Manage_button_name="Manage_Interview_Questions" //additional button name
        SetDataBaseUpdate={SetDataBaseUpdate} //To maintain the table updated
        DefaultColumnNames={[
          "UserId",
          "Designation",
          "Date",
          "Skills",
          "isApproved",
        ]} //DefaultColumnsnameshows
        CloseActionDialog={handleActionClose}
        switchColumnName="IsApproved"
        swithcUpdateApi="Interview_Questions"
      />
      {/* _______________________End_____________________________ */}
      {/* ________Manage Interview question_______  */}

      <InterViewQuestionManageTemplate
        open={openTemplateModal}
        close={handleAddMoreFunctionalityCloseTemplateModal}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />
      {/* __________________end____________________ */}

      {/* ________________Manage Edit interview questions________ */}
      <InterviewQuestionGenaralInfoAddModal
        SetDataBaseUpdate={SetDataBaseUpdate}
        open={openEditMode}
        handleCloseModal={handleEditClose}
        initialFormData={initialFormData}
      />
    </div>
  );
}

export default InterviewQuestionLandingPage;
