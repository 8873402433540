import React, { useState } from "react";
import { MDBCol, MDBRow, MDBRadio } from "mdb-react-ui-kit";
import Select from "@mui/material/Select";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Button, FormControl, FormHelperText  } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import UpdateIcon from '@mui/icons-material/Update';


function Stscroll8ProjectsAddModal({
  togglingModal,
  fetchProjects,
  projectData,
  setProjectData,
  empList,
  callPercentage
}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const [showMore, setShowMore] = useState("no");
  const [invalidFields, setInvalidFields] = useState({});

  //--------------------------------------------------------------------------------
  // Drop down options
  //--------------------------------------------------------------------------------

  const projectOptions = empList.map((companyName) => ({
    value: companyName, // Use an appropriate value for each option
    label: companyName, // Use the company name as the label
  }));


  //--------------------------------------------------------------------------------
  // Save Data API
  //--------------------------------------------------------------------------------
  const addProjectDetails = () => {
    const api =
      projectData.id !== 0
        ? `updateStudentProject/${projectData.id}`
        : "createStudentProject";

 // Fields to validate
 const requiredFields = [
    'projectTitle',
];

    // Validate required fields
    const newInvalidFields = {};
    for (const field of requiredFields) {
    console.log(!projectData[field])
    
      if (!projectData[field]) {
        newInvalidFields[field] = true;
      }
    }
    console.log(Object.keys(newInvalidFields).length)
    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      return; 
    }
    setInvalidFields({});
    
    


    fetch(`${baseURL}/${api}`, {
      method: "POST",
      body: JSON.stringify(projectData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          customAlert(
            "",
            projectData.id !== 0 ? "Project Edited" : "Project Added",
            "success"
          );
          togglingModal(0);
          fetchProjects();
          callPercentage();
        } else {
          console.error("inside API error ");
        }
      })
      .catch((error) => {
        console.error("API call error-outside:", error);
      });
  };
  return (
    <>
    <div className="pmModalContainer">
      {/* ___________________________________ Tab Heading _______________________________ */}
      {/* ___________________________________  Project title ____________________________ */}
      <MDBRow>
        <MDBCol>
          <label className="pmLabel">Project title</label>
          <TextField
            fullWidth
            value={projectData.projectTitle || ""}
            onChange={(e) =>
              setProjectData({ ...projectData, projectTitle: e.target.value })
            }
            error={invalidFields.projectTitle}
            helperText={invalidFields.projectTitle ? "* mandatory" : ""}
          />
        </MDBCol>
      </MDBRow>
      <br />
      {/* ___________________________________  Project Tag   ____________________________ */}
      <MDBRow>
        <MDBCol>
        <label className="pmLabel">
              Tag this Project with Employment/Education
            </label>
            <br />
            <FormControl fullWidth error={invalidFields.projectTag}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={projectData.projectTag}
                onChange={(e) =>
                  setProjectData({ ...projectData, projectTag: e.target.value })
                }
              >
                {projectOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {invalidFields.projectTag && (
                <FormHelperText>* mandatory</FormHelperText>
              )}
            </FormControl>
        </MDBCol>
      </MDBRow>
      <br />
      {/* ___________________________________  Client       ____________________________ */}
      <MDBRow>
        <MDBCol>
          <label className="pmLabel">Client</label>
          <br />
          <TextField
            fullWidth
            value={projectData.client || ""}
            onChange={(e) =>
              setProjectData({ ...projectData, client: e.target.value })
            }
            error={invalidFields.client}
            helperText={invalidFields.client ? "* mandatory" : ""}
          />
        </MDBCol>
      </MDBRow>
      <br />

      {/* ___________________________________  Project Status (Radio) ___________________ */}
      <MDBRow>
      <label className={`pmLabel ${invalidFields.projectStatus ? 'error_Pmlabel' : ''}`}>Project Status</label>
      {/* <label className="pmLabel">Project Status</label> */}
        <br></br>
        <MDBCol className="pmRadioModal">
          <MDBRadio
            name="projectStatus"
            id="inlineRadio1"
            value="In-Progress"
            label="In-Progress"
            inline
            checked={projectData.projectStatus == "In-Progress"}
            onChange={(e) => {
              const currentDate = new Date().toISOString().split("T")[0]; // Today's date in YYYY-MM-DD format
            
              setProjectData({
                ...projectData,
                projectStatus: "In-Progress",
                projectTill: currentDate,
              });
            }}
            
          />
          <MDBRadio
            name="projectStatus"
            id="inlineRadio2"
            value="Finished"
            label="Finished"
            inline
            checked={projectData.projectStatus == "Finished"}
            onChange={(e) =>
              setProjectData({ ...projectData, projectStatus: "Finished" })
            }
          />
        </MDBCol>
      </MDBRow>
      <br></br>

      {/* ___________________________________  Worked From and Till  ___________________________ */}
        <MDBRow>
            {/* ____   From  ____ */}
            <MDBCol>
              <label className="pmLabel">Worked From</label>
              <TextField
                required
                type="date"
                fullWidth
                value={projectData.projectFrom}
                onChange={(e) => {
                  setProjectData({ ...projectData, projectFrom: e.target.value });
                }}
                
                
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { style: { textTransform: "uppercase" },
                  max: projectData.projectTill  
                }, 
                }}
                error={invalidFields.projectFrom}
            helperText={invalidFields.projectFrom ? "* mandatory" : ""}
              />
            </MDBCol>

            <MDBCol className="pmWorkTill">
              <label className="pmLabel">Worked Till</label>
              <TextField
                disabled={projectData.projectStatus === 'In-Progress' && projectData.projectStatus !== 'Finished'}
                required
                type="date"
                fullWidth
                value={projectData.projectTill}
                onChange={(e) => {
                  setProjectData({ ...projectData, projectTill: e.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { style: { textTransform: "uppercase" },
                  min: projectData.projectFrom
                }, // Ensure input value is also uppercase
                }}
                error={invalidFields.projectTill}
            helperText={invalidFields.projectTill ? "* mandatory" : ""}
              />
            </MDBCol>

        </MDBRow>
      <br />

      {projectData.projectStatus === "Finished" ? <></> : null}

      {/* _______________________________ Details of Project   _________________________ */}
      <MDBRow>
        <MDBCol>
          <label className="pmLabel">Details of Project</label>
          <br />
            <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            minRows={2}
                  // rows={4}
            value={projectData.projectdetails || ""}
            onChange={(e) =>
              setProjectData({ ...projectData, projectdetails: e.target.value })
            }
            error={invalidFields.projectdetails}
            helperText={invalidFields.projectdetails ? "* mandatory" : ""}
           />
        </MDBCol>
      </MDBRow>
      <br />

      {/* _______________________________     Show More        _________________________ */}
      {showMore !== "yes" ? (
        <>
          <MDBRow>
            <label
              className="pmLabel pmShowMoreColor"
              onClick={(e) => setShowMore("yes")}
            >
              Add More Details
            </label>
            <br></br>
          </MDBRow>
          <br />
        </>
      ) : null}
      {showMore === "yes" ? (
        <>
          {/* _____________________     Project Location       _______________ */}
          <MDBRow>
            <MDBCol>
              <label className="pmLabel">Project Location</label>
              <br />
              <TextField
                fullWidth
                value={projectData.projectLocation || ""}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    projectLocation: e.target.value,
                  })
                }
              />
            </MDBCol>
          </MDBRow>
          <br />

          {/* _____________________     Project Site       _______________ */}
          <MDBRow>
            <label className="pmLabel">Project Site</label>
            <br></br>
            <MDBCol>
              <MDBRadio
                name="projectStatus"
                id="inlineRadio2"
                value="Offsite"
                label="Offsite"
                inline
                checked={projectData.projectSite === "Offsite"}
                onChange={(e) =>
                  setProjectData({ ...projectData, projectSite: "Offsite" })
                }
              />
              <MDBRadio
                name="projectStatus"
                id="inlineRadio2"
                value="Onsite"
                label="Onsite"
                inline
                checked={projectData.projectSite === "Onsite"}
                onChange={(e) =>
                  setProjectData({ ...projectData, projectSite: "Onsite" })
                }
              />
            </MDBCol>
          </MDBRow>
          <br></br>

          {/* ____________________ Nature of the Employment  _____________ */}
          <MDBRow>
            <label className="pmLabel">Nature of the Employment</label>
            <br></br>
            <MDBCol>
              <MDBRadio
                name="empNature"
                id="inlineRadio1"
                value="Full time"
                label="Full time"
                inline
                checked={projectData.empNature === "Onsite"}
                onChange={(e) =>
                  setProjectData({ ...projectData, empNature: "Onsite" })
                }
              />
              <MDBRadio
                name="empNature"
                id="inlineRadio2"
                value="Part time"
                label="Part time"
                inline
                checked={projectData.empNature === "Part time"}
                onChange={(e) =>
                  setProjectData({ ...projectData, empNature: "Part time" })
                }
              />
              <MDBRadio
                name="empNature"
                id="inlineRadio3"
                value="Contractual"
                label="Contractual"
                inline
                checked={projectData.empNature === "Contractual"}
                onChange={(e) =>
                  setProjectData({ ...projectData, empNature: "Contractual" })
                }
              />
            </MDBCol>
          </MDBRow>
          <br></br>

          {/* _______________________     Team Size      _________________ */}
          <MDBRow>
            <MDBCol>
              <label className="pmLabel">Team Size</label>
              <br></br>
              <TextField
                  fullWidth
                  value={projectData.teamSize || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setProjectData({
                        ...projectData,
                        teamSize: inputValue,
                      });
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: 'left' }, // Align text to the left
                    },
                  }}
                />

             
            </MDBCol>
          </MDBRow>
          <br />

          {/* _______________________   Role Description _________________ */}
          <MDBRow>
            <MDBCol>
              <label className="pmLabel">Role Description</label>

              <TextField
                fullWidth
                multiline
                // rows={3}
                minRows={3}
                value={projectData.roleDescription || ""}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    roleDescription: e.target.value,
                  })
                }
              />
            </MDBCol>
          </MDBRow>
          <br></br>
        </>
      ) : null}


    </div>
      {/* _____________________________     Save  &  Cancel   _______________________ */}
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={addProjectDetails}
        >
            {projectData.id !== 0 ? (<><UpdateIcon className="buttonicons" /> Update</>
          ) : (
              <><SaveIcon className="buttonicons" /> Save</>
          )}

        </Button>
      </div>
    </>
  );
}

export default Stscroll8ProjectsAddModal;
