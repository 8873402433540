import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import SalarySlip from "./MySalarySlip";
import SalaryStructureDownload from "./MySalaryStructureDownload";
import EmployeeOtherBenefits from "./EmployeeOtherBenefits";
import { isReadOnly } from "../CheckReadOnlyStatus";

function EmployeeLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [editData, setEditData] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const [employeeId, setEmployeeId] = useState(null);


  const readaccessManage=isReadOnly(<EmployeeLandingPage/>)
  console.log('readaccessMangae',readaccessManage)


  const fetchEmployeeId = async (row) => {
    try {
      const response = await axios.post(baseURL + "/get-employee-id-by-email", {
        email: row.EmployeeMailId,
      });

      if (response.data.success) {
        setEmployeeId(response.data.employee_id); // Assuming response contains employeeId
      } else {
        setEmployeeId(null);
      }
    } catch (err) {
      console.error("Error fetching employee ID:", err);
    }
  };

  const fetchEmployeeDetails = async (id) => {
    try {
      const response = await axios.get(baseURL + `/Employees_fetch/${id}`);

      if (response.data) {
        setEmployeeData(response.data); // Assuming response contains employeeData
      } else {
        setEmployeeData({});
      }
    } catch (err) {
      console.error("Error fetching employee details:", err);
    }
  };

  useEffect(() => {
    if (employeeId) {
      fetchEmployeeDetails(employeeId);
    }
  }, [employeeId]);

useEffect(() => {
  const fetchTableData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/Employee_salary_slips_fetch`
      );
      const data = response.data;

      // Retrieve userType and HTES_user_id from localStorage
      const userType = localStorage.getItem('userType');
      const userEmail = localStorage.getItem('HTES_user_id');

      // Determine the columns based on userType
      let columns = [];
      if (userType === "Employee") {
        // Only include EmployeeSalaryMonth column if userType is Employee
        columns = [{
          field: "EmployeeSalaryMonth",
          headerName: "Employee Salary Month",
        }];
      } else {
        // Otherwise, use all columns
        const columnNames = Object.keys(data[0] || {});
        columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
      }
      setRenderColumns(columns);

    // Format rows data based on userType
    const rows = userType === "Admin"
    ? data.map((row, index) => ({
        id: index + 1, // Use the index as a unique identifier
        ...row,
      }))
    : data
        .filter(row => row.EmployeeMailId === userEmail) // Ensure row.EmployeeEmail matches userEmail
        .map((row, index) => ({
          id: index + 1, // Use the index as a unique identifier
          ...row,
        }));

      setRenderRows(rows);
      SetDataBaseUpdate(false);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  fetchTableData();
}, [dataBaseUpdate]);
 // Dependency on dataBaseUpdate to refetch data when updated

  //--------------------------------------------------------------------------------
  //Additional Functions if the custom menu added(custommenuitems)
  //--------------------------------------------------------------------------------

  const handleView = (row, rowid) => {
    setEditData(row);
    fetchEmployeeId(row);
    setOpen(true);
    console.log("data", row, rowid);
  };

  const handleClose = () => {
    setEditData("");
    setEmployeeData("");
    setEmployeeId("");
    setOpen(false);
  };

  const handleView1 = (row, rowid) => {
    setEditData(row);
    fetchEmployeeId(row);
    setDownloadPdf(true);
    console.log("data", row, rowid);
  };

  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View Salary Slip", //Name of the MenuItem
      onClick: handleView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
    {
      icon: DownloadIcon,
      label: "Download Salary Slip",
      onClick: handleView1,
      IconColor: "blue",
    },
  ];

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    setOpen1(true)
    setEditData(rowdata)
    console.log("handle Edit functions", rowdata);
  };

  const handleClose1=()=>{
    setOpen1(false)
  }

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    console.log("handle manage button");
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render with default column if empty
        rows={renderRows} // Rows render
        customMenuItems={customMenuItems} // Addition menu items
        handleEdit={handleEdit} //Handle the Edit function
        deleteApi="deletecourse" // Delete Api
        tableNameForExport="Education-RoadMap" // Export the Data to excel/csv (If name requred for the Excel or Csv)
        is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
        handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
        Manage_button_name="Manage Table" //Name of the Manage button(in center of the header)
        // switchColumnName="isPublished" // Swithc required in the Table(one of the column)
        // swithcUpdateApi="updatecourse"// Api to handle the Toggle of the swithc
        // dropdownColumnName="isPaid" // Yes or No Dropdown required in the Table(one of the column)
        // dropdownUpdateApi="updatecourse"// Api to handle the Dropdown of the (yes or no)
        SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
        DefaultColumnNames={["EmployeeMailId", "EmployeeSalaryMonth"]} // Add to show the columns in a default render
        CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)}
      />
      {/* _______________________End_____________________________ */}

      {/* ________________Salary slip_____________ */}
      <SalarySlip
        open={open}
        handleClose={handleClose}
        editData={editData}
        employeeData={employeeData}
        setOpen={setOpen}
      />
      {downloadPdf && (
        <SalaryStructureDownload
          downloadPdf={downloadPdf}
          setDownloadPdf={setDownloadPdf}
          editData={editData}
          setEditData={setEditData}
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          setEmployeeId={setEmployeeId}
        />
      )}

      {/* _____________other benefits_________ */}
      <EmployeeOtherBenefits open={open1} handleClose={handleClose1} editData={editData} SetDataBaseUpdate={SetDataBaseUpdate} />
    </div>
  );
}

export default EmployeeLandingPage;
