import React, { useState, useEffect } from "react";
import {
  Avatar,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import UserManagement from "./UserManagementUserTablecopy";
import UserManagementTemplate from "./UserMangementTemplate";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import axios from "axios";
import { baseURL } from "../../http"; // Adjust the path as needed
import FilterModal from "./FilterModal";
import './usermanagement.css'
import ExportButton from "../GeneralTemplateComponent/ExportButton";

function UserManagementHeader() {
  const [searchTerm, setSearchTerm] = useState("");
  // const [isClicked, setIsClicked] = useState(false);
  const [loginData, setLoginData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openFilter, setFilterOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [cleraFilterColor, setClearFilterColor] = useState(false);
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dropdownOptions, setdropdownOptions] = useState([]);
  
  //--------------------------------------------------------------------------------
  // Render the Usertype
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/Roll_Default_Access_Fetch_All`
        );
        const data = response.data;
        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
  
        // Filter out "Owner" and map to required structure
        setdropdownOptions(
          rows
            .filter(row => row.UserRole !== "Owner") // Filter out "Owner"
            .map(row => ({ label: row.UserRole, value: row.UserRole }))
        );
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };
  
    fetchTableData();
  }, []);



  //____________Fetch data when the component mounts_________________//
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getLoginTable`);
        const data = response.data;
  
        // Extract column names from the data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
  
        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
  
        // Set the login data and filtered data
        setLoginData(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchTableData();
  }, [update]);

  //__________________________end_____________________________________//

  //____________________Delete selected rows__________________________//
  const handleDeleteSelected = () => {
    setDeleteSelected((prevDeleteSelected) => !prevDeleteSelected);
  };
  //_____________________________end__________________________________//

  //_____________________Search functionality___________________________//
  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    if (!loginData) return; // Check if loginData is null or undefined

    const filteredData = loginData.filter((row) => {
      const name = row.name?.toLowerCase() || "";
      const email = row.email?.toLowerCase() || "";
      const mobile = row.mobile?.toString() || ""; // Convert mobile to string if it's null
      const usertype = row.usertype?.toLowerCase() || "";

      return (
        name.includes(keyword) ||
        email.includes(keyword) ||
        mobile.includes(keyword) ||
        usertype.includes(keyword)
      );
    });

    setFilteredData(filteredData); // Update filtered data state
    //setSearchTerm(keyword); // Update search term state
  };
  //_________________________________end________________________________//

  //__________________open and close the filter modal______________________//
  const handleOpen = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  //___________________________end_____________________________________//

  //________________Function to count user types______________________//
  const countUserTypes = (data) => {
    const counts = data.reduce((acc, user) => {
      acc[user.usertype] = (acc[user.usertype] || 0) + 1;
      return acc;
    }, {});

    return counts;
  };

  const userTypes = countUserTypes(loginData);

  const tooltipContent = (
    <div className="tooltipgridalignment">
      {Object.entries(userTypes).map(([type, count]) => (
        <Typography key={type} variant="body2">
          {type}: {count}
        </Typography>
      ))}
    </div>
  );
  //____________________________end_________________________________//

  return (
    <>
      {/* ______________________Delete the Seleted users______________________ */}

      <div className="usemanagementheadercopy">
        {selectedRows.length > 0 ? (
          <Button
            variant="contained"
            color="error"
            startIcon={<Delete />}
            onClick={handleDeleteSelected}
          >
            Delete Selected
          </Button>
        ) : (
          <>
            {/* _____________________Search container_________ */}
            <div >
            <div className="search-container1">
                <span className="search-iconusermanagement">
                  <SearchIcon />
                </span>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyUp={handleSearch}
                  placeholder="Search..."
                  className="search-input"
                />
              </div>
              
            </div>
           
            {/* __________________call the template for add and download the template_______ */}
            <UserManagementTemplate setUpdate={setUpdate} dropdownOptions={dropdownOptions} /> 
              {/* __________________________________end_________________________ */}
            {/* ________________________________end________________________________ */}
            {/* ______________User Avatar_____________________ */}
            <div className="avatatandfileterflextcontainer">
            {/* <div className="usermanagementheadercopyfilterusers"> */}
            
              <Tooltip title={tooltipContent} arrow>
                <Avatar className="headeruseravatar">
                  {filteredData.length || 0}
                </Avatar>
              </Tooltip>&nbsp;
              
              {/* </div> */}
              &nbsp;
              {/* ______________________end_____________________ */}
              {/* _________________Export Button____________ */}
              <ExportButton columns={renderColumns} rows={renderRows} tableName='UserManagement'/>&nbsp;
              {/* _______________filter block_________________________ */}
              <div
                className={`usermanagementheadercopyfilter ${
                  cleraFilterColor ? "filtercolor" : ""
                }`}
                onClick={handleOpen}
              >
                <FilterAltIcon className="filtericon" />
                <span className="filltericondisplaywhenresponsive">Filter</span>
              </div>
              
              </div>
            {/*__________________________end__________________________ */}

          </>
        )}
      </div>

      {/*__________________________end__________________________ */}

      {/*___________________Call the data grid table_________________________________ */}
      <UserManagement
        loginData={filteredData}
        setLoginData={setLoginData}
        setFilteredData={setFilteredData}
        deleteSelected={deleteSelected}
        update={update}
        setUpdate={setUpdate}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        dropdownOptions={dropdownOptions}
        
      />
      {/* _________________________________end___________________________________________ */}

      {/* ________________________filter modal component_______________________________________ */}

      <FilterModal
        setClearFilterColor={setClearFilterColor}
        openFilter={openFilter}
        closefilter={handleClose}
        loginData={loginData}
        setFilteredData={setFilteredData}
      />

      {/* ______________________________________end____________________________________________ */}
    </>
  );
}

export default UserManagementHeader;
