import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Paper, Tooltip, Popover } from '@mui/material';
import './QABlog.css';
import ShareIcon from '@mui/icons-material/Share';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function QuestionCard({ id, question, votes = { likes: 0, dislikes: 0 }, answers, tags, user, last_modified_date, Like_count, dis_Like_count, onClick }) {

    //----------------------------------------------------------------
    //State handle
    //----------------------------------------------------------------

    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const [likeCount, setLikeCount] = useState(votes.likes);
    const [dislikeCount, setDislikeCount] = useState(votes.dislikes);
    const [anchorEl, setAnchorEl] = useState(null);


    //----------------------------------------------------------------
    //Function to handle like
    //----------------------------------------------------------------

    const handleLike = () => {
        if (!liked) {
            setLiked(true);
            setDisliked(false);
            setLikeCount((prevCount) => prevCount + 1);
        } else {
            setLiked(false);
            setLikeCount((prevCount) => prevCount - 1);
        }
    };


    //----------------------------------------------------------------
    //Function to handle dislike
    //----------------------------------------------------------------

    const handleDislike = () => {
        if (!disliked) {
            setDisliked(true);
            setLiked(false);
            setDislikeCount((prevCount) => prevCount + 1);
        } else {
            setDisliked(false);
            setDislikeCount((prevCount) => prevCount - 1);
        }
    };

    const handleCardClick = (event) => {
        if (event.target.classList.contains('questionDetails')) {
            onClick && onClick(); // Call the onClick function passed from the parent component
        }
    };

    const handleShareClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const idPopover = open ? 'share-popover' : undefined;

    return (
        <div>

            <div className="questionCard" onClick={handleCardClick}>
                <Paper elevation={3}>
                    <CardContent>

                        {/* _________To display the questions__________ */}

                        <div>
                            <Typography className="questionDetails">
                                {question}
                            </Typography>
                        </div>

                        {/* _____________________  Tag start   _______________________________________*/}
                        <div className="tag">
                            Tags:
                            {Array.isArray(tags) ? tags.map((tag) => (
                                <span key={tag} className="tag">
                                    {tag}
                                </span>
                            )) : null}
                        </div>

                        {/* _____________________ User Start  _______________________________________*/}

                        <div className="userDetailsContainer">
                            <span className="userDetails">
                                Asked by: {user}
                                <span className="updatedDateTime"> Asked on: {last_modified_date}</span>
                                Answers: {answers}
                            </span>

                            {/* ________________Share Icon___________________________ */}

                            <Tooltip title="Share" arrow>
                                <IconButton className="share-icon" onClick={handleShareClick}>
                                    <ShareIcon />
                                </IconButton>
                            </Tooltip>
                            <Popover
                                id={idPopover}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div className="share-options">
                                    <div className="share-option" onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(question)}`, '_blank')}>
                                        <WhatsAppIcon />
                                        <span>WhatsApp</span>
                                    </div>
                                    <div className="share-option" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, '_blank')}>
                                        <FacebookIcon />
                                        <span>Facebook</span>
                                    </div>
                                    <div className="share-option" onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(question)}`, '_blank')}>
                                        <LinkedInIcon />
                                        <span>LinkedIn</span>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </CardContent>
                </Paper>
            </div>
        </div>
    );
}

export default QuestionCard;