import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, ClickAwayListener, IconButton, TextField, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function SalaryStructure({salaryDetails, setSalaryDetails}) {

  const [isPfRequired, setIsPfRequired] = useState(true);
  const [editTax, setEditTax] = useState(false);
  const [editProfessionalTax, setEditProfessionalTax] = useState(false);

  const handleInputChange = (field, value) => {
    setSalaryDetails((prevState) => ({
      ...prevState,
      [field]: parseFloat(value) || 0,
    }));
  };

  const handlePfToggle = () => {
    setIsPfRequired(!isPfRequired);
    if (!isPfRequired) {
      // Set the original PF values when checked
      setSalaryDetails((prevState) => ({
        ...prevState,
        PFEmployerPercent: 12,
        PFEmployeePercent: 12,
      }));
    } else {
      // Set PF percentages to 0 when unchecked
      setSalaryDetails((prevState) => ({
        ...prevState,
        PFEmployerPercent: 0,
        PFEmployeePercent: 0,
      }));
    }
  };

  // Calculations for yearly values
  const basicYearly = (salaryDetails.CTC * salaryDetails.BasicPercent) / 100;
  const hraYearly = (basicYearly * salaryDetails.HRApercent) / 100;
  const pfEmployerYearly = (basicYearly * salaryDetails.PFEmployerPercent) / 100;
  const pfEmployeeYearly = (basicYearly * salaryDetails.PFEmployeePercent) / 100;
  const otherAllowance = salaryDetails.CTC - (basicYearly + hraYearly + pfEmployerYearly);
  const grossYearly = salaryDetails.CTC;
  const totalDeductionYearly = pfEmployeeYearly + salaryDetails.ProfessionalTax + salaryDetails.Tax;
  const totalYearly = grossYearly - totalDeductionYearly;

  // Monthly breakdown (move this inside useEffect to prevent infinite re-renders)
  useEffect(() => {
    const basicMonthly = basicYearly / 12;
    const hraMonthly = hraYearly / 12;
    const otherAllowanceMonthly = otherAllowance / 12;
    const pfEmployeeMonthly = pfEmployeeYearly / 12;
    const professionalTaxMonthly = salaryDetails.ProfessionalTax / 12;
    const taxMonthly = salaryDetails.Tax / 12;

    // Update salaryDetails state for monthly values only when necessary
    setSalaryDetails(prevDetails => ({
      ...prevDetails,
      BasicMonthly: basicMonthly.toFixed(2),
      HRAMonthly: hraMonthly.toFixed(2),
      OtherAllowanceMonthly: otherAllowanceMonthly.toFixed(2),
      PFEmployeeMonthly: pfEmployeeMonthly.toFixed(2),
      ProfessionalTaxMonthly: professionalTaxMonthly.toFixed(2),
      TaxMonthly: taxMonthly.toFixed(2),
    }));
  }, [
    salaryDetails.CTC, 
    salaryDetails.BasicPercent, 
    salaryDetails.HRApercent, 
    salaryDetails.ProfessionalTax, 
    salaryDetails.Tax, 
    basicYearly, 
    hraYearly, 
    otherAllowance, 
    pfEmployeeYearly
  ]);

  return (
    <div className='salary-structure-table-container'>
      <div className='salary-structure-table-heading'>
        Salary Structure
        <span className='is-pf-required'>
          <Checkbox
            checked={isPfRequired}
            onChange={handlePfToggle}
          />
          PF_Required
        </span>
      </div>

      {/* TextField for entering CTC */}
      <div className='ctc-input'>
        <TextField
          label="Enter Employee CTC"
          type="number"
          value={salaryDetails.CTC}
          onChange={(e) => handleInputChange('CTC', e.target.value)}
          variant="outlined"
          fullWidth
        />
      </div>

      <TableContainer  className='salary-structure-tabel'>
        <Table aria-label="salary structure table">
          <TableHead>
            <TableRow className='salary-structure-row-background-color'>
              <TableCell className='salary-structure-header'>Particulars</TableCell>
              <TableCell className='salary-structure-header'>%</TableCell>
              <TableCell className='salary-structure-header'>Yearly</TableCell>
              <TableCell className='salary-structure-header'>Monthly</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className='salary-structure-row-fontsize'>Basic</TableCell>
              <TableCell className='salary-structure-row-fontsize'>
                <input
                  className='input-textfield-width-control-salary-structure'
                  type="number"
                  value={salaryDetails.BasicPercent}
                  onChange={(e) => handleInputChange('BasicPercent', e.target.value)}
                />
              </TableCell>
              <TableCell className='salary-structure-row-fontsize'>{basicYearly.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{(basicYearly / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-row-fontsize'>HRA</TableCell>
              <TableCell className='salary-structure-row-fontsize'>
                <input
                  className='input-textfield-width-control-salary-structure'
                  type="number"
                  value={salaryDetails.HRApercent}
                  onChange={(e) => handleInputChange('HRApercent', e.target.value)}
                />
              </TableCell>
              <TableCell className='salary-structure-row-fontsize'>{hraYearly.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{(hraYearly / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-row-fontsize'>PF Employer</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{salaryDetails.PFEmployerPercent}</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{pfEmployerYearly.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{(pfEmployerYearly / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-row-fontsize'>Other Allowance</TableCell>
              <TableCell className='salary-structure-row-fontsize'></TableCell>
              <TableCell className='salary-structure-row-fontsize'>{otherAllowance.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{(otherAllowance / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-header'>Gross Salary</TableCell>
              <TableCell></TableCell>
              <TableCell className='salary-structure-header'>{grossYearly.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-header'>{(grossYearly / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-header1'>Deduction Part:</TableCell>
              <TableCell  className='salary-structure-header1'></TableCell>
              <TableCell  className='salary-structure-header1'></TableCell>
              <TableCell  className='salary-structure-header1'></TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-row-fontsize'>PF Employee</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{salaryDetails.PFEmployeePercent}</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{pfEmployeeYearly.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-row-fontsize'>{(pfEmployeeYearly / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-row-fontsize'>Professional Tax</TableCell>
              <TableCell></TableCell>
              <TableCell className='salary-structure-row-fontsize'>
                {editProfessionalTax ? (
                  <ClickAwayListener onClickAway={() => setEditProfessionalTax(false)}>
                    <input
                      className='input-textfield-width-control-salary-structure'
                      type="number"
                      value={salaryDetails.ProfessionalTax}
                      onChange={(e) => handleInputChange('ProfessionalTax', e.target.value)}
                    />
                  </ClickAwayListener>
                ) : (
                  <>
                    <span>{salaryDetails.ProfessionalTax.toFixed(2)}</span>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setEditProfessionalTax(true)}>
                        <EditIcon  className='salary-structure-icon-button-fontsize'/>
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
              <TableCell className='salary-structure-row-fontsize'>{(salaryDetails.ProfessionalTax / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-row-fontsize'>Tax</TableCell>
              <TableCell></TableCell>
              <TableCell className='salary-structure-row-fontsize'>
                {editTax ? (
                  <ClickAwayListener onClickAway={() => setEditTax(false)}>
                    <input
                      className='input-textfield-width-control-salary-structure'
                      type="number"
                      value={salaryDetails.Tax}
                      onChange={(e) => handleInputChange('Tax', e.target.value)}
                    />
                  </ClickAwayListener>
                ) : (
                  <>
                    <span>{salaryDetails.Tax.toFixed(2)}</span>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setEditTax(true)}>
                        <EditIcon className='salary-structure-icon-button-fontsize' />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
              <TableCell className='salary-structure-row-fontsize'>{(salaryDetails.Tax / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-header'>Total Deduction</TableCell>
              <TableCell></TableCell>
              <TableCell className='salary-structure-header'>{totalDeductionYearly.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-header'>{(totalDeductionYearly / 12).toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className='salary-structure-header'>Total Salary</TableCell>
              <TableCell></TableCell>
              <TableCell className='salary-structure-header'>{totalYearly.toFixed(2)}</TableCell>
              <TableCell className='salary-structure-header'>{(totalYearly / 12).toFixed(2)}</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SalaryStructure;
