import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import axios from "axios";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";

function HtesContact() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`${baseURL}/contact-us-get`);
        const data = response.data;

        // Assuming data contains blog fields
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));

        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Use index as unique ID for table row
          ...row,
        }));
        setRenderRows(rows);
        setDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogData();
  }, [dataBaseUpdate]);

  const handleEdit = (rowdata) => {
    // setEditData(rowdata);
    // setOpenEdit(true);
    console.log("handle Edit functions", rowdata);
  };

  return (
    <div>
      <TableHeaderTemplate
        columns={renderColumns}
        rows={renderRows}
        handleEdit={handleEdit}
        is_Manage_Button_Required={true}
        deleteApi="contact-us-delete"
        Manage_button_name="ContactMessage"
        SetDataBaseUpdate={setDataBaseUpdate}
        DefaultColumnNames={[
          "Name",
          "Email",
          "Phone",
          "Message",
          "UTC_Time",
          "Created_by",
          "Modified_by",
        ]}
      />
    </div>
  );
}

export default HtesContact;
