import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, IconButton, Autocomplete, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import axios from "axios";
import { baseURL } from "../../http";
import "./EducationalRoadmp.css";
import { customAlert } from "../SweetAlertCommon/Custom";

function AddEducationalMap({ update, open, handleClose1, editData }) {
  //--------------------------------------------------------------------------------
  //order the Textfileds
  //--------------------------------------------------------------------------------
  const fieldOrder = [
    "Syllabus",
    "Education",
    "Branch/FieldOfStudy",
    "Class/Sem",
    "Subject",
    "ChapterName",
    "TopicName",
  ];

  //--------------------------------------------------------------------------------
  //Initial fileds
  //--------------------------------------------------------------------------------
  const initialState = {
    Syllabus: "",
    Education: "",
    "Branch/FieldOfStudy": "",
    "Class/Sem": "",
    Subject: "",
    ChapterName: "",
    TopicName: "",
  };

  // Initial form state
  const [formData, setFormData] = useState(initialState);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [formErrors, setFormErrors] = useState({}); // State to track field errors

  //--------------------------------------------------------------------------------
  //text filed change data added to the States
  //--------------------------------------------------------------------------------
  const handleChange = (e, newValue, field) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: newValue || e.target.value,
    }));

    // Remove error for this field as soon as the user types or selects a value
    if (newValue || e.target.value.trim() !== "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  //--------------------------------------------------------------------------------
  //used to validate the empty text-fields
  //--------------------------------------------------------------------------------
  const validateForm = () => {
    const errors = {};
    fieldOrder.forEach((field) => {
      if (!formData[field] || formData[field].trim() === "") {
        errors[field] = true; // Mark field as error if empty
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if no errors
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return; // If form validation fails, do not proceed
    }

    const url = editData
      ? `${baseURL}/updateEdRoadMap/${editData.id}` // For updating existing data
      : `${baseURL}/manageqb`; // For adding new data

    const method = editData ? "put" : "post"; // Use 'put' for updates, 'post' for new entries

    const dataToSend = editData ? formData : [formData]; // Send formData directly for updates, and as an array for new entries

    axios[method](url, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          customAlert(
            "",
            editData ? "Data updated successfully" : "Data added successfully",
            "success"
          );
          setFormData(initialState);
          update(true); // Trigger any state updates or side-effects
          handleClose1(); // Close the modal or form
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          customAlert("", "Duplicate Data. Please add unique Data", "warning");
        } else {
          console.error("Something went wrong", error);
        }
      });
  };

  //--------------------------------------------------------------------------------
  //Fetch the Data from the Backend(DB)
  //--------------------------------------------------------------------------------

  const fetchDropdownOptions = async () => {
    try {
      const optionsResponse = await axios.get(baseURL + "/dropdownOptions");
      const optionsData = optionsResponse.data;

      const uniqueOptions = {};
      fieldOrder.forEach((field) => {
        uniqueOptions[field] = [
          ...new Set(optionsData.map((row) => row[field])),
        ];
      });

      setDropdownOptions(uniqueOptions);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  //--------------------------------------------------------------------------------
  //Call the data render function while loading the page
  //--------------------------------------------------------------------------------

  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  //--------------------------------------------------------------------------------
  //when edit the data prefill the textfileds with availbale data
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      // If editData is available, prefill the form
      setFormData({
        Syllabus: editData.Syllabus || "",
        Education: editData.Education || "",
        "Branch/FieldOfStudy": editData["Branch/FieldOfStudy"] || "",
        "Class/Sem": editData["Class/Sem"] || "",
        Subject: editData.Subject || "",
        ChapterName: editData.ChapterName || "",
        TopicName: editData.TopicName || "",
      });
    } else {
      // Reset form data if no editData is provided
      setFormData(initialState);
    }
  }, [editData]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="addeducationalMapmodal">
          <IconButton
            id="button-zoom"
            className="addeducationalMapmodalcancel"
            onClick={handleClose1}
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>
          <div className="EducationalTableViewmodalHeader1">
            {editData ? "Edit Educational Roadmap" : "Add Educational Roadmap"}
          </div>
          {/* _____________________Textfiled rendering with map fuction________ */}
          <div className="add-education-roadmap-container">
            {fieldOrder.map((field, index) => (
              <div className="addEducationalfabbuttonandtextarea" key={field}>
                <Autocomplete
                  fullWidth
                  freeSolo
                  disableClearable
                  options={dropdownOptions[field] || []}
                  value={formData[field] || ""}
                  onInputChange={(e, newValue) =>
                    handleChange(e, newValue, field)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={index + 1 + ". " + field}
                      variant="outlined"
                      error={formErrors[field] || false} // Show error state if field is empty
                      helperText={
                        formErrors[field] ? "This field is required" : ""
                      } // Show helperText for required fields
                    />
                  )}
                />
              </div>
            ))}
          </div>
          {/* _____________________end________ */}
          {/* ______________________Button section____________________ */}
          <div className="addeducationalmapgridContainer">
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={handleSubmit}
            >
              {editData ? (
                <>
                  <UpdateIcon className="buttonicons" />
                  &nbsp;Update
                </>
              ) : (
                <>
                  <SaveIcon className="buttonicons" />
                  &nbsp;Save
                </>
              )}
            </Button>
          </div>
          {/* ______________________end____________________ */}
        </div>
      </Modal>
    </div>
  );
}

export default AddEducationalMap;
