import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Grid,
  Button,
  IconButton,
  MenuItem,
  Autocomplete,
  Switch,
  Tooltip,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";
import "./tpacoursepage.css";

function AddCourseName({
  initialFormData,
  openModal,
  setOpenModal,
  SetDataBaseUpdate,
  CloseActionDialogMenu
}) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    Category: "",
    SubCategory: "",
    CourseName: "",
    isPublished: true,
    isPaid: "No",
    ...initialFormData,
  });
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [newOptions, setNewOptions] = useState({});
  const fieldOrder = ["Category", "SubCategory", "CourseName", "isPaid"];

  //--------------------------------------------------------------------------------
  // Handle Modal State open close
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (openModal) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openModal]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
    resetForm();
  };

  //--------------------------------------------------------------------------------
  // Reset Form Data
  //--------------------------------------------------------------------------------
  const resetForm = () => {
    setFormData({
      Category: "",
      SubCategory: "",
      CourseName: "",
      isPublished: true,
      isPaid: "No",
      ...initialFormData,
    });
  };

  //--------------------------------------------------------------------------------
  // Render the edit data properly when click
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (initialFormData) {
      setFormData({
        Category: "",
        SubCategory: "",
        CourseName: "",
        isPublished: true,
        isPaid: "No",
        ...initialFormData,
      });
    }
  }, [initialFormData]);

  //--------------------------------------------------------------------------------
  // Initialize formData with correct boolean values(switch)
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (openModal) {
      setFormData((prevState) => ({
        ...prevState,
        isPublished:
          initialFormData?.isPublished === "1" ||
          initialFormData?.isPublished === 1,
      }));
    }
  }, [initialFormData, openModal]);

  //--------------------------------------------------------------------------------
  // Handle Form Field Change
  //--------------------------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //--------------------------------------------------------------------------------
  // Handle Switch Toggle
  //--------------------------------------------------------------------------------
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  //--------------------------------------------------------------------------------
  // Add New Option
  //--------------------------------------------------------------------------------
  const handleAddOption = (field) => {
    const newOption = newOptions[field];
    if (newOption && !dropdownOptions[field]?.includes(newOption)) {
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        [field]: [...(prevOptions[field] || []), newOption],
      }));
      setFormData((prevState) => ({
        ...prevState,
        [field]: newOption,
      }));
      setNewOptions((prevOptions) => ({
        ...prevOptions,
        [field]: "",
      }));
    }
  };

  //--------------------------------------------------------------------------------
  // Handle Autocomplete Input Change
  //--------------------------------------------------------------------------------
  const handleAutocompleteInputChange = (event, value, field) => {
    setNewOptions((prevOptions) => ({
      ...prevOptions,
      [field]: value,
    }));
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  //--------------------------------------------------------------------------------
  // Handle Form Submission both update and post
  //--------------------------------------------------------------------------------
  const handleSubmit = () => {
    if (
      Object.values(formData).some(
        (value) => typeof value === "string" && value.trim() === ""
      )
    ) {
      customAlert("", "Please add the Required Information", "warning");
      return;
    }

    const updatedFormData = {
      ...formData,
      "%ofCompletion": "0",
      Created_By: localStorage.getItem("HTES_user_id"),
      Created_date: new Date().toISOString(),
      Modified_By: localStorage.getItem("HTES_user_id"),
      Modified_date: new Date().toISOString(),
    };

    const url =
      initialFormData && initialFormData.id
        ? `${baseURL}/updatecourse/${initialFormData.id}`
        : `${baseURL}/createcourse`;

    const axiosMethod =
      initialFormData && initialFormData.id ? axios.put : axios.post;

    axiosMethod(url, updatedFormData)
      .then((response) => {
        if (response.status === 200) {
          if (initialFormData && initialFormData.id) {
            customAlert("", "Course Updated Successfully", "success");
            setOpenModal(false);
            SetDataBaseUpdate(true);
            CloseActionDialogMenu()
            
          } else {
            customAlert("", "Course Saved Successfully", "success");
            setOpenModal(false);
            SetDataBaseUpdate(true);
          }
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          customAlert(
            "",
            "Duplicate CourseName. Please add a unique CourseName",
            "warning"
          );
        } else {
          console.error("Something went wrong", error);
        }
      });
  };

  //--------------------------------------------------------------------------------
  // Fetch Dropdown Options on Component Mount
  //--------------------------------------------------------------------------------
  const fetchDropdownOptions = async () => {
    try {
      const optionsResponse = await axios.get(`${baseURL}/getcourse`);
      const optionsData = optionsResponse.data;

      const uniqueOptions = {};
      fieldOrder.slice(0, 3).forEach((field) => {
        uniqueOptions[field] = [
          ...new Set(optionsData.map((row) => row[field])),
        ];
      });

      setDropdownOptions(uniqueOptions);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Initial Fetch of Dropdown Options
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  return (
    <div>
      {/* _______________________________Add and update model outer____________ */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Add-update-course-name"
      >
        <div className="add-update-course-name-border-avoide">
          <div className="add-course-name">
            <IconButton
              id="button-zoom"
              className="add-update-course-name-close-button"
              onClick={handleClose}
            >
              <CloseIcon className="add-update-course-name-close-button-x" />
            </IconButton>
            <div className="add-course-name-swith-header">
              <h5 className="add-course-name-header-name">
                {initialFormData && initialFormData.id
                  ? "Update Course"
                  : "Add Course"}
              </h5>
              {/* <div className="switch-name">
                isPublished:
                <Switch
                  checked={formData.isPublished}
                  onChange={handleSwitchChange}
                  name="isPublished"
                  color="primary"
                  className="switch-alignment"
                />
              </div> */}
            </div>
           
            <Grid container spacing={1}>
              {fieldOrder.map((field, index) => (
                <Grid item xs={12} key={index}>
                  <div className="add-update-course-name-textarea-and-button">
                    {["Category", "SubCategory"].includes(field) ? (
                      <Autocomplete
                        fullWidth
                       
                        disableClearable
                        options={dropdownOptions[field] || []}
                        value={formData[field] || ""}
                        onChange={(e, newValue) =>
                          handleChange({
                            target: { value: newValue, name: field },
                          })
                        }
                        onInputChange={(e, newValue) =>
                          handleAutocompleteInputChange(e, newValue, field)
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label={index + 1 + ". " + field}
                            variant="outlined"
                            Inputlabel={index + 1 + ". " + field}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {params.InputProps.endAdornment}
                                  {newOptions[field] &&
                                    !dropdownOptions[field]?.includes(
                                      newOptions[field]
                                    ) && (
                                      <Tooltip title={`Add ${field}`}>
                                        <div
                                          className="add-update-course-name-add-button"
                                          onClick={() =>
                                            handleAddOption(field)
                                          }
                                        >
                                          <AddIcon className="modal-add-additional-option-button" />
                                        </div>
                                      </Tooltip>
                                    )}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    ) : field === "isPaid" ? (
                      <TextField
                        fullWidth
                        select
                        label={field}
                        name={field}
                        value={formData[field]}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </TextField>
                    ) : (
                      <TextField
                        required
                        fullWidth
                        label={index + 1 + ". " + field}
                        name={field}
                        value={formData[field] || ""}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    )}
                  </div>
                </Grid>
              ))}
              <Grid
                item
                xs={11.7}
                spacing={2}
                className="add-update-course-name-save-update-button"
              >
                <Button
                  id="button-zoom"
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                >
                  {initialFormData && initialFormData.id ? (
                    <UpdateIcon className="buttonicons" />
                  ) : (
                    <SaveIcon className="buttonicons" />
                  )}
                  &nbsp;
                  {initialFormData && initialFormData.id ? "Update" : "Save"}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
      {/* ______________________________end_________________________________ */}
    </div>
  );
}

export default AddCourseName;
