import React from "react";
import { Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function DownloadToCompanyTemplate({ anchorEl, setAnchorEl }) {
  //--------------------------------------------------------------------------------
  //handle menu close after export
  //--------------------------------------------------------------------------------
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //--------------------------------------------------------------------------------
  //Download the template
  //--------------------------------------------------------------------------------
  const handleDownload = async (format) => {
    try {
      // Define the column names for the ToCompany template
      const columnNames = [
        'name',
        'address',
        'gst',
        'pan_number',
        'phone_number',
        'vendor_code',
        'contact_person_name',
        'designation',
        'official_mail_id',
      ];

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Create the first worksheet with column names
      const ws1 = XLSX.utils.aoa_to_sheet([columnNames]);
      XLSX.utils.book_append_sheet(wb, ws1, "Template");

      // Create example rows for the specified columns
      const exampleRow = [
        "Company ABC",
        "123 Business St",
        "1234ABC567",
        "ABCDE1234F",
        "1234567890",
        "V001",
        "John Doe",
        "Manager",
        "johndoe@company.com",
      ];

      const exampleRow2 = [
        "Company XYZ",
        "789 Corporate Ave",
        "5678XYZ123",
        "XYZAB5678D",
        "0987654321",
        "V002",
        "Jane Smith",
        "Director",
        "janesmith@companyxyz.com",
      ];

      const ws2 = XLSX.utils.aoa_to_sheet([
        columnNames,
        exampleRow,
        exampleRow2,
      ]);
      XLSX.utils.book_append_sheet(wb, ws2, "Example");

      // Convert workbook to binary string
      const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

      // Convert binary string to Blob
      const blob = new Blob([s2ab(wbBinary)], {
        type: "application/octet-stream",
      });

      // Determine the file extension based on the selected format
      const extension = format === "csv" ? "csv" : "xlsx";

      // Save Blob as file
      saveAs(blob, `To_Company_Upload_Template.${extension}`);
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Convert string to ArrayBuffer
  //--------------------------------------------------------------------------------

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
      {/* Menu items for download options */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ marginTop: "-50px" }}
      >
        <MenuItem onClick={() => handleDownload("csv")}>
          Download as CSV
        </MenuItem>
        <MenuItem onClick={() => handleDownload("excel")}>
          Download as Excel
        </MenuItem>
      </Menu>
    </>
  );
}

export default DownloadToCompanyTemplate;
