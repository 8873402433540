import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../http";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ViewHistoryDataGrid({ open, setOpen, Email }) {
  const [historyData, setHistoryData] = useState([]);

  //___________________ Close the History Modal__________________//
  const handleClose = () => {
    setOpen(false);
  };

  //__________________________end________________________________//

  //____________________Fetch the data from the Backend based on Email____________________//
  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL + "/gethistory");
      setHistoryData(response.data);
    } catch (error) {
      console.error("Error fetching login history:", error);
      // Handle error
    }
  };

  //_______________________________end____________________________________________//

  //__________________fetch the data based on the open model_________________//

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open, Email]);

  //_____________________end__________________________________________________//

  //______________________________index for the history card________________//

  let counter = 0;

  //_____________________________end_________________________________________//

  return (
    //____________________open the modal and close modal___________//
    <Modal
      open={open}
      onClose={handleClose}
      className="ViewHistoryDatagridmodal"
    >
      <div className="ViewHistoryDatagridmodaldatagrid">
        <IconButton
          id="button-zoom"
          className="EducationalTableViewmodalcloseicon"
          onClick={handleClose}
        >
          <CloseIcon className="EducationalTableViewmodalcloseiconX" />
        </IconButton>
        <h5 className="EducationalTableViewmodalHeader">Login History</h5>
        {/* _________________________View history card__________________ */}
        <div className="historycardviewhistorytopline">
          <div className="historycardviewhistoryscroll">
            {/* Render historyData here */}
            {historyData.map((item, index) => {
              // Check if the email matches the provided Email id
              if (item.email === Email) {
                counter++;
                return (
                  <div key={index} className="historycardviewhistory">
                    {/* Render individual history item properties */}
                    <p>{item.history}</p>
                    <div className="cardnumberhistoryview">
                      History: {counter}
                    </div>
                    {/* Add more properties as needed */}
                  </div>
                );
              } else {
                return null; // If email doesn't match, don't render anything
              }
            })}
          </div>
          {/* _________________________end__________________ */}
          {/* _____________Login History footer_____________________ */}
        </div>
        <div className="footerforuserhistory">User:&nbsp;{Email}</div>
      </div>
      {/* __________________________end____________________________________ */}
    </Modal>
    //____________________end___________//
  );
}

export default ViewHistoryDataGrid;
