  import React, { useState } from "react";
  import { Modal, IconButton, Tooltip, Button } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
  import SaveIcon from "@mui/icons-material/Save";
  import AddIcon from "@mui/icons-material/Add";
  import UpdateIcon from "@mui/icons-material/Update";
  import CheckIcon from "@mui/icons-material/Check";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import ExpandLessIcon from "@mui/icons-material/ExpandLess";
  import "./coursecreatetemplate.css";


  const OverViewTemplate = ({ setContent, title, content ,selectedCourse }) => {
    const [open, setOpen] = useState(false);
    const [isExpand,setIsExpand]=useState(false)
    const [text, setText] = useState("• ");



    //--------------------------------------------------------------------------------
    // Handle open and close the modal (text editor)
    //--------------------------------------------------------------------------------
    const handleOpen = () => {
      setOpen(true);
      setText(content.map((point) => `• ${point}`).join("\n"));
    };

    const handleClose = () => {
      setOpen(false);
      setText("• ");
    };

    //--------------------------------------------------------------------------------
    // Handle the text change in the text area
    //--------------------------------------------------------------------------------
    const handleTextChange = (e) => {
      const value = e.target.value;
      setText(value);
    };

    //--------------------------------------------------------------------------------
    // Call the function for add the Bullet in fornt of the each point
    //--------------------------------------------------------------------------------
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const { selectionStart, selectionEnd, value } = e.target;
        const beforeCaret = value.slice(0, selectionStart);
        const afterCaret = value.slice(selectionEnd);
        setText(`${beforeCaret}\n• ${afterCaret}`);
        setTimeout(() => {
          e.target.selectionStart = selectionStart + 3;
          e.target.selectionEnd = selectionStart + 3;
        }, 0);
      }
    };

    //--------------------------------------------------------------------------------
    // Save the Text to the form of array in the states
    //--------------------------------------------------------------------------------
    const handleSave = () => {
      const points = text
        .split("\n")
        .filter((point) => point.trim() !== "")
        .map((point) => point.replace(/^•\s*/, ""));
      setContent(points);
      handleClose();
    };

    //--------------------------------------------------------------------------------
    // Delete the Complete ection data
    //--------------------------------------------------------------------------------
    const handleDelete = () => {
      setContent([]);
    };

      //--------------------------------------------------------------------------------
    // Expan and Hide the grid div
    //--------------------------------------------------------------------------------
    const handleExpand=()=>{
      setIsExpand((prev)=>!prev)
    }

    return (
      <>
        {/* ______________________________Heading and Buttons_____________ */}
        <div className="Header-tab-course-name">
          <div className="overviewtemplate-course-name-and-icon-align" onClick={handleExpand}>
            <div className="toggleicon-expand-more-less">
          <IconButton className="expand-hide-button-alignment">
                  {isExpand ? (
                    <ExpandLessIcon className="expand-icon-color" />
                  ) : (
                    <ExpandMoreIcon className="expand-icon-color" />
                  )}
                </IconButton>
            <h6 className="overview-header-of-the-container-color">{title}</h6>
            </div>
            <div className="overviewtemplate-icons-alignment">
              {content.length === 0 ? (
                <Tooltip title="Add" arrow>
                  <IconButton onClick={() => setOpen(true)}>
                    <AddIcon className="buttoncolorEdit" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Edit" arrow>
                  <IconButton onClick={handleOpen}>
                    <EditIcon className="buttoncolorEdit" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Delete" arrow>
                <IconButton onClick={handleDelete}>
                  <DeleteIcon className="buttoncolorEdit" color="error" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {isExpand && (
          <div className="overview-grid-container">
            {content.map((point, index) => (
              <div className="overview-grid-container-content" key={index}>
                <div>
                  <CheckIcon className="buttonicons" />{" "}
                </div>
                &nbsp;
                <div>{point}</div>
              </div>
            ))}
          </div>)}
        </div>
        {/* ____________________end________________________ */}
        {/* ____________________Content Editor________________________ */}
        <Modal
          open={open}
          onClose={handleClose}
          className="Modal-screen-center-alignment-overview-template"
        >
          <div className="modal-container-overview-template">
            <div className="modal-inside-content-container-overview-template">
              <IconButton
                id="button-zoom"
                className="modal-close-iconButton-right-top-corner-overview-template"
                onClick={handleClose}
              >
                <CloseIcon className="modal-close-iconButton-right-top-corner-symbol-overview-template" />
              </IconButton>
              <div className="modal-header-and-switch-container-overview-template">
                <h5 className="modal-header-name-overview-template">{title}-{selectedCourse}</h5>
              </div>
              
              <div className="overview-template-textarea">
                <textarea
                  className="textarea-overview-template"
                  value={text}
                  onChange={handleTextChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter content here..."
                />
              </div>
              <div className="Save-Button-Alignment-container-overview-template">
                <Button
                  id="button-zoom"
                  variant="contained"
                  color="success"
                  onClick={handleSave}
                >
                  {content.length === 0 ? (
                    <SaveIcon className="buttonicons" />
                  ) : (
                    <UpdateIcon className="buttonicons" />
                  )}
                  {content.length === 0 ? "Save" : "Update"}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {/* ____________________end________________________ */}
      </>
    );
  };

  export default OverViewTemplate;
