import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseURL } from "../../../http";
import TableHeaderTemplate from "../../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import EmployeeDataEdit from "./EmployeeDataEdit";
import { useNavigate } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from '@mui/icons-material/Info';
import DownloadAllEmployeesInfo from "../DownloadAllEmployeeInfo/DownloadAllEmployeesInfo";
import DownloadEmpInfo from "../DownloadAllEmployeeInfo/DownloadEmpInfo";
import EmployeeDetailsManage from "./EmployeeDetailsManage";

function EmployeeDetailsLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [openEditModal, SetopenEditModal] = useState(false);
  const [open, SetOpen] = useState(false);
  const [open1, SetOpen1] = useState(false);
  const [editData, setEditData] = useState("");
  const [sendRequest, setSendRequest] = useState(false);
  const navigate = useNavigate();

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/Employee_Data_fetch_all`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    SetOpen1(true)
  };

  const handleCloseMore = () => {
    SetOpen1(false);
  };
  //--------------------------------------------------------------------------------
  //Additional Functions if the custom menu added(custommenuitems)
  //--------------------------------------------------------------------------------

  const handleDocumentView = (row, rowid) => {
    setEditData(row);
    // Store the rowdata.id in localStorage
    localStorage.setItem("Employee_Edit_Id", row.id);
    // Navigate to the desired route
    navigate("/Employee_Phase1_Info_Document_View");
  };

  const handleHrFormOpen = (row, rowid) => {
    // Store the row and rowid in localStorage
    localStorage.setItem("hrFormRow", JSON.stringify(row));
    localStorage.setItem("hrFormRowId", rowid);
    // Navigate to the HR form route
    navigate("/Hr_Form_and_Salary_Structure");
  };

//--------------------------------------------------------------------------------
  //Handle download the Info
  //--------------------------------------------------------------------------------
  const handleClose1=()=>{
    SetOpen(false)
  }
  
  const handleClickDownloadDoc=(row,rowid)=>{
    setEditData(row)
    console.log('row',row)
    SetOpen(true)
  }

  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "Document View", //Name of the MenuItem
      onClick: handleDocumentView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },

    {
      icon: AssignmentIcon, //icon Name (import from the Mui)
      label: "HR Form", //Name of the MenuItem
      onClick: handleHrFormOpen, //Handle the Function(for working )
      IconColor: "blue", //Color of the Icon
    },

    {
      icon: InfoIcon, //icon Name (import from the Mui)
      label: "Download Emp Info", //Name of the MenuItem
      onClick: handleClickDownloadDoc, //Handle the Function(for working )
      IconColor: "darkcyan", //Color of the Icon
    },
  ];

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    // Set rowdata for editing
    setEditData(rowdata);

    // Store the rowdata.id in localStorage
    localStorage.setItem("Employee_Edit_Id", rowdata.id);

    // Navigate to the desired route
    navigate("/Employee_Phase1_Info_edit");
  };

  const handleClose = () => {
    SetopenEditModal(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

    


  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render with default column if empty
        rows={renderRows} // Rows render
        customMenuItems={customMenuItems} // Addition menu items
        handleEdit={handleEdit} //Handle the Edit function
        deleteApi="Employee_Data_delete" // Delete Api
        is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
        handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
        Manage_button_name="Manage Employee Data" //Name of the Manage button(in center of the header)
        SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
        DefaultColumnNames={[
          "EmployeeName",
          "DateOfBirth",
          "Gender",
          "PersonalMailId",
          "PhoneNumber",
        ]} // Add to show the columns in a default render
        CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
      />
      {/* _______________________End_____________________________ */}

      {/* ____________________Edit employee data_____________ */}
      <EmployeeDataEdit
        open={openEditModal}
        handleClose={handleClose}
        editData={editData}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />

      {/* ____________Emp info Download_______ */}
      <DownloadEmpInfo editData={editData} open={open} SetOpen={SetOpen} />

      {/* ________Employee Template_________ */}
      <EmployeeDetailsManage open={open1} close={handleCloseMore}/>
    </div>

    
  );
}

export default EmployeeDetailsLandingPage;
