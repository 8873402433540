import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { baseURL } from "../../http";
import { Select, FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import UpdateIcon from "@mui/icons-material/Update";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ViewHistoryDataGrid from "./ViewHistoryDataGrid";

function EditUser({
  editUserModalStatus,
  handleCloseEditModal,
  editData,
  setEditData,
  usertypeOptions,
  editItemID,
  setEditUser,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  //________________open the History the Modal_________________//

  const handelOpen = () => {
    setOpen(true);
  };

  //_______________________________________End__________________________//

  //___________________To handle the edit input change Functions____________//

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  //_______________________________________End__________________________//

  //________________________Handle isBlocked__________________________//

  const handleSwitchChange = () => {
    setEditData((prevData) => ({
      ...prevData,
      isBlocked: !editData.isBlocked ? 1 : 0, // Toggle isBlocked value
    }));
  };

  //__________________________end______________________________________//

  //________________________Edited Used Saved to Database________________//

  const saveEdited = () => {
    axios
      .put(baseURL + `/editUser/${editItemID}`, editData)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          customAlert("", "User Edited Successfully", "success");
          handleCloseEditModal();
          setEditUser(true);
        } else {
          customAlert("", "Email or Password not matching", "error");
        }
      })
      .catch((error) => {
        console.log(error);
        customAlert("", "Server Down", "error");
      });
  };

  //______________________________End_____________________________________//

  //________________________password Visiblelity______________________//

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  //_______________________________end__________________________________//

  return (
    <>
      {/* ______________________________________Edit User Model____________________________ */}
      <Modal
        open={editUserModalStatus}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="editusermodel">
          <IconButton
            id="button-zoom"
            className="EducationalTableViewmodalcloseicon"
            onClick={handleCloseEditModal}
          >
            <CloseIcon className="EducationalTableViewmodalcloseiconX" />
          </IconButton>
          <div className="headerblockoftheedituser">
            <h5 className="EducationalTableViewmodalHeader">Edit User </h5>
            <div className="edituserheaerswith">
              <p className="edituserheaerswithheading">Block User:</p>
              <Switch
                checked={editData.isBlocked === 1}
                onChange={handleSwitchChange}
                color="success"
                style={{ color: editData.isBlocked === 1 ? "red" : "green" }}
              />
            </div>
          </div>
          <div className="edituserscroll">
            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  autoComplete="given-name"
                  variant="outlined"
                  value={editData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="mobile"
                  name="mobile"
                  label="Mobile"
                  type="number"
                  fullWidth
                  autoComplete="tel"
                  variant="outlined"
                  value={editData.mobile}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  autoComplete="email"
                  variant="outlined"
                  value={editData.email}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  autoComplete="new-password"
                  variant="outlined"
                  value={editData.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon className="visiablitycolor" />
                          ) : (
                            <VisibilityOffIcon className="visiablitycoloroff" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel id="usertype">usertype</InputLabel>
                  <Select
                    label="usertype"
                    value={editData.usertype}
                    onChange={handleChange}
                    variant="outlined"
                    name="usertype"
                  >
                    {usertypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="last_Login_time"
                  name="last_Login_time"
                  label="last_Login_time"
                  type="last_Login_time"
                  fullWidth
                  variant="outlined"
                  value={editData.last_Login_time}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="last_logout_time"
                  name="last_logout_time"
                  label="last_logout_time"
                  type="last_logout_time"
                  fullWidth
                  variant="outlined"
                  value={editData.last_logout_time}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="last_logout_Status"
                  name="last_logout_Status"
                  label="last_logout_Status"
                  type="last_logout_Status"
                  fullWidth
                  variant="outlined"
                  value={editData.last_logout_Status}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="is_LocalData_Login"
                  name="is_LocalData_Login"
                  label="is_LocalData_Login"
                  type="is_LocalData_Login"
                  fullWidth
                  variant="outlined"
                  value={editData.is_LocalData_Login}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="last_login_device_name"
                  name="last_login_device_name"
                  label="last_login_device_name"
                  type="last_login_device_name"
                  fullWidth
                  variant="outlined"
                  value={editData.last_login_device_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="number_of_device_login"
                  name="number_of_device_login"
                  label="number_of_device_login"
                  type="number_of_device_login"
                  fullWidth
                  variant="outlined"
                  value={editData.number_of_device_login}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="user_id"
                  name="user_id"
                  label="user_id"
                  type="user_id"
                  fullWidth
                  variant="outlined"
                  value={editData.user_id}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="UTC_Time"
                  name="UTC_Time"
                  label="UTC_Time"
                  type="UTC_Time"
                  fullWidth
                  variant="outlined"
                  value={editData.UTC_Time}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="IP_Addres"
                  name="IP_Addres"
                  label="IP_Addres"
                  type="IP_Addres"
                  fullWidth
                  variant="outlined"
                  value={editData.IP_Addres}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="Latitude"
                  name="Latitude"
                  label="Latitude"
                  type="Latitude"
                  fullWidth
                  variant="outlined"
                  value={editData.Latitude}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="Longitude"
                  name="Longitude"
                  label="Longitude"
                  type="Longitude"
                  fullWidth
                  variant="outlined"
                  value={editData.Longitude}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="PinCode_Login"
                  name="PinCode_Login"
                  label="PinCode_Login"
                  type="PinCode_Login"
                  fullWidth
                  variant="outlined"
                  value={editData.PinCode_Login}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                disabled
                  id="Current_Location"
                  name="Current_Location"
                  label="Current_Location"
                  type="Current_Location"
                  fullWidth
                  variant="outlined"
                  value={editData.Current_Location}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>

          {/* ______________________________________Edit User Model____________________________ */}

          {/*________________________Buttons_______________________*/}

          <div className="newuseraddbutton">
            <Tooltip title="click to ViewHistory" arrow>
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handelOpen}
              >
                <VisibilityIcon className="buttonicons" />
                &nbsp;History
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={saveEdited}
            >
              <UpdateIcon className="buttonicons" />
              &nbsp;Update
            </Button>
          </div>
          {/*________________________End_____________________________*/}
        </div>
      </Modal>

      <ViewHistoryDataGrid open={open} setOpen={setOpen} Email={editData.email} />
    </>
  );
}

export default EditUser;
