import React, { useState } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExamUploadFromTemplate from "./ExamUploadFromTemplate";
import ExamDownloadTemplate from "./ExamDownloadTemplate";

function CreateExamModal({ open, close, columns, handleAddExamClick, SetDataBaseUpdate }) {
  const [anchorEl, setAnchorEl] = useState(null);

  // Handle the menu open for the Download Template
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the modal
  const handleModalClose = () => {
    close();
  };

  return (
    <div>
       <Modal open={open} onClose={close} className="course-template-modal">
        {/* _________________________Header_______________________ */}
        <div className="course-template-modal-container">
          <div className="course-name-template-container-with-content">
            <IconButton
              id="button-zoom"
              className="course-name-template-close-button"
              onClick={close}
            >
              <CloseIcon className="course-name-template-close-button-x" />
            </IconButton>
            <h5 className="course-name-template-header">Add Exam</h5>

            {/* _________________________Button, upload, download, add_______________________ */}
            <div className="course-name-template-add-course-icon">
              <div onClick={handleAddExamClick}>
                <AddBoxIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />
                <div>
                  Add
                  <br />
                  Exam
                </div>
              </div>

              {/* _________________________Download and Upload Template_______________________ */}
              <div onClick={handleMenuOpen}>
                <FileDownloadIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />
                <div>
                  Download
                  <br />
                  Template
                </div>
              </div>

              <div>
                <ExamUploadFromTemplate
                  DBApi="/uploadexams" 
                  update={SetDataBaseUpdate}
                  close={handleModalClose}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <ExamDownloadTemplate
        columns={columns}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

    </div>
  );
}

export default CreateExamModal;
