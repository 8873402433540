import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";

function ResultCardViewHeading({ ResultData, filteredQuestions }) {
  //--------------------------------------------------------------------------------
  //Download the PDF
  //--------------------------------------------------------------------------------
  const handleDownloadPDF = () => {
    if (!ResultData?.ExamStatus) {
      console.error("ExamResult is undefined or null");
      return; // Exit if ExamResult is invalid
    }

    let parsedData;
    try {
      parsedData = JSON.parse(ResultData.ExamStatus); // Parse only if ExamResult is valid
    } catch (e) {
      console.error("Failed to parse ExamResult JSON:", e);
      return; // Exit if parsing fails
    }

    const examResults = parsedData[0]; // Access the first item in the array
    const { SeletedOptions, TestOuestionsIds, resultArray } = examResults;

    const pdf = new jsPDF();

    const headerText = `Detailed Questions View - ${
      ResultData.TestName || "NA"
    }`;
    const footerText = "Page ";

    let yPosition = 20; // Start adding content from this position
    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
    const footerHeight = 10; // Height reserved for the footer

    // Set the header style
    const addFirstPageHeader = () => {
      pdf.setFont("Sans-serif", "bold");
      pdf.setFontSize(10);
      const headerWidth = pdf.getTextWidth(headerText);
      pdf.text(headerText, (pdf.internal.pageSize.width - headerWidth) / 2, 10); // Center the header
    };

    const addFooter = (pageNumber) => {
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const footerTextWithNumber = `${footerText}${pageNumber}`;
      const footerWidth = pdf.getTextWidth(footerTextWithNumber);
      pdf.text(
        footerTextWithNumber,
        (pdf.internal.pageSize.width - footerWidth) / 2,
        pageHeight - footerHeight + 5
      );
    };

    // Add the first page header
    addFirstPageHeader();

    // Assuming `filteredQuestions` is available or passed to the function
    const filteredQuestions1 = filteredQuestions || []; // Replace with the actual questions array

    filteredQuestions1.forEach((question, index) => {
      // Check if we need to create a new page
      if (yPosition + footerHeight + 10 > pageHeight) {
        addFooter(pdf.internal.getNumberOfPages());
        pdf.addPage();
        yPosition = 20;
        addFooter(pdf.internal.getNumberOfPages());
      }

      // Add Question in bold and black
      pdf.setFont("Roboto", "bold");
      pdf.setTextColor(0, 0, 0); // Set text color to black for questions
      pdf.text(`${index + 1}. ${question.Questions}`, 10, yPosition);
      yPosition += 8;

      // Get the correct answer and the user's selected answer
      const correctAnswer = question.Answer;

      // Safely access the user's answer from SeletedOptions (check for undefined)
      const userAnswer = SeletedOptions?.[index]; // Assuming the index in SeletedOptions corresponds to the question index

      // Add options with a left margin of 20px
      const options = [
        { label: "A", text: question.OptionA },
        { label: "B", text: question.OptionB },
        { label: "C", text: question.OptionC },
        { label: "D", text: question.OptionD },
      ];

      options.forEach((option) => {
        if (option.text) {
          const isCorrectAnswer = option.text === correctAnswer;
          const isUserAnswer = option.text === userAnswer; // Compare with the user's selected option

          // Set the font and color for correct/incorrect answers
          if (isCorrectAnswer) {
            pdf.setFont("Roboto", "bold");
            pdf.setTextColor("green");
          } else if (isUserAnswer && !isCorrectAnswer) {
            pdf.setFont("Roboto", "bold");
            pdf.setTextColor("red"); // Highlight incorrect user answer in red
          } else {
            pdf.setFont("Roboto", "normal");
            pdf.setTextColor(0, 0, 0); // Default text color (black)
          }

          pdf.text(`${option.label}. ${option.text}`, 20, yPosition);
          yPosition += 5;

          // Add "User Answered" text in blue next to the selected answer
          if (isUserAnswer) {
            const userAnswerText = "(User Answered)";
            pdf.setFont("Roboto", "small");
            pdf.setTextColor(0, 0, 255); // Set text color to blue
            pdf.text(
              userAnswerText,
              pdf.getTextWidth(`${option.label}. ${option.text}`) + 25,
              yPosition - 5
            ); // Positioning the "User Answered" text
          }
        }
      });

      // Add space between questions
      yPosition += 10;
    });

    // Add footer on the last page
    addFooter(pdf.internal.getNumberOfPages());

    // Save the PDF with a name based on the test name
    const userId = ResultData.UserId.split("@")[0]; // Assuming UserId is a string with an email
    pdf.save(`${ResultData.TestName}_${userId}_Detailed_Questions_Paper.pdf`);
  };

  return (
    <div className="component-alignemnt-and-heading-in-result-view">
      Detailed Questions View -{ResultData.TestName || "NA"}
      <div>
        <Tooltip title="Download the Questions" arrow>
          <IconButton onClick={handleDownloadPDF}>
            <FileDownloadIcon className="question-card-section-download-icon-color" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default ResultCardViewHeading;
