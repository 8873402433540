import React from 'react'
import { Grid, Paper, Container, Drawer, List, ListItem, Divider, ListItemText, useMediaQuery } from '@mui/material'
import { useState, useEffect } from 'react';
import "../../App.css";
import './Side.css'
import TestCard from './TestCard';
// import { Box } from '@mui/system';
import {  TextField, InputAdornment, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from "@mui/icons-material/Menu";



const tabsData = [
    { id: 1, title: "All", content: '' },
    { id: 2, title: "Teaching Exams", content: '' },
    { id: 3, title: "SSC", content: '' },
    { id: 4, title: "Railways", content: '' },
    { id: 5, title: "Banking & Insurance", content: '' },
    { id: 6, title: "State Exams", content: 'State Exams' },
    { id: 7, title: "Defence Exams", content: 'Defence Exams' },
    { id: 8, title: "Civil Services", content: 'Civil Services' },
    { id: 9, title: "Police Exams", content: '' },
    { id: 10, title: "B.Ed Entrance Exams", content: 'B.Ed Entrance Exams' },
    { id: 11, title: "Non Teaching Exams", content: 'Non Teaching Exams' },
    { id: 12, title: "Other Engineering Exams", content: 'Other Engineering Exams' },
];


export const TestSideNav = () => {

    //Initialize the cards
    const [cards, setCards] = useState([
        {
            id: 1, logo: "https://cdn.testbook.com/resources/productionimages/Bihar%20Senior%20Secondary%20Teacher_All_1683993962.png",
            badgeText: "18.8k Users", heading: "BPSC बिहार माध्यमिक TGT (9 to 10) शिक्षक भर्ती 3.0..",
            subtitles: ["267 Total Tests | 6 Free Tests"], language: "English,Hindi,German",
            listItems: ["21 ब्रह्मास्त्र - 21 Days Challenge", "24 फुल टेस्ट", "7 करंट अफेयर्स(ST)", "+238 more tests"],
            buttonText: "View Test Series", category: 3
        },
        {
            id: 2, logo: "https://cdn.testbook.com/resources/production/test_series/ca_All_1707493263.png",
            badgeText: "83.8k Users", heading: "Current Affairs 2024 Mega Pack for..",
            subtitles: ["327 Total Tests | 4 Free Tests"], language: "English,Hindi,German",
            listItems: ["168 Topic Specific", "24 Monthly Digest", "12 NEWS to be Known", "+123 more tests"],
            buttonText: "View Test Series", category: 3
        },
        {
            id: 3, logo: "https://cdn.testbook.com/resources/productionimages/SSC_All_1594144442.png",
            badgeText: "305.9k  Users", heading: "SSC CGL (Tier I & Tier II) Mock Test 2024",
            subtitles: ["906 Total Tests | 2 Free Tests"], language: "English,Hindi,German",
            listItems: ["174 Chapter Test (Tier I)", "75 Sectional Test (Tier I)", "40 Full Test (Tier I)", "+617 more tests"],
            buttonText: "View Test Series", category: 3
        },
        {
            id: 4, logo: "https://cdn.testbook.com/resources/productionimages/DSSSB_All_1612870937.png",
            badgeText: "223.7k Users", heading: "All DSSSB Exam Non-Teaching Subject Mock..",
            subtitles: ["471 Total Tests | 4 Free Tests"], language: "English,Hindi,German",
            listItems: ["127 Chapter Test", "50 Subject Test", "41 Previous Year Paper", "+253 more tests"],
            buttonText: "View Test Series", category: 2
        },
        {
            id: 5, logo: "https://cdn.testbook.com/resources/productionimages/SBI_All_1594152722.png",
            badgeText: "305.5k  Users", heading: "SBI Clerk (Pre + Mains) Mock Test 2023",
            subtitles: ["305 Total Tests | 2 Free Tests"], language: "English,Hindi,German",
            listItems: ["14 Current Affairs Capsule", "12 Full Test (Mains)", "21 Sectional Test (Mains)", "+258 more tests"],
            buttonText: "View Test Series", category: 5
        },
        {
            id: 6, logo: "https://cdn.testbook.com/resources/production/test_series/IBPS_All_1657720528.png",
            badgeText: "288.6k Users", heading: "All Banking & Insurance Previous Year Papers..",
            subtitles: ["267 Total Tests | 6 Free Tests"], language: "English,Hindi,German",
            listItems: ["174 Chapter Test (Tier I)", "75 Sectional Test (Tier I)", "40 Full Test (Tier I)", "+617 more tests"],
            buttonText: "View Test Series", category: 5
        },
        {
            id: 7, logo: "https://cdn.testbook.com/resources/production/test_series/Indian%20Railways_All_1583407036.png",
            badgeText: "5.0k Users", heading: "Railways General Science Previous Year Paper..",
            subtitles: ["297 Total Tests | 1 Free Tests"], language: "English,Hindi,German",
            listItems: ["53 PYCT", "39 RRB ALP", "172 RRB Group D", "+33 more tests"],
            buttonText: "View Test Series", category: 4
        },
        {
            id: 8, logo: "https://cdn.testbook.com/resources/productionimages/UP%20Police%20ASI_All_1616569599.png",
            badgeText: "265.5k Users", heading: "UP Police ASI Mock Test 2023 - 24",
            subtitles: ["582 Total Tests | 4 Free Tests"], language: "English,Hindi,German",
            listItems: ["217 Chapter Test", "15 Full Test", "45 Current Affairs", "+305 more tests"],
            buttonText: "View Test Series", category: 9
        },
        {
            id: 9, logo: "https://cdn.testbook.com/resources/productionimages/UP%20Police%20ASI_All_1616569599.png",
            badgeText: "256.2k Users", heading: "UP Police SI Mock Test 2023",
            subtitles: ["567 Total Tests | 1 Free Tests"], language: "English,Hindi,German",
            listItems: ["53 PYCT", "39 RRB ALP", "172 RRB Group D", "+33 more tests"],
            buttonText: "View Test Series", category: 9
        },
    ]);

    const [searchQuery, setSearchQuery] = useState('');

    // Filter cards based on search query
    const filteredCards = cards.filter((card) =>
        card.heading.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const clearSearch = () => {
        setSearchQuery('');
    };

    const [activeTab, setActiveTab] = useState(tabsData[0].id);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [showMobileTabs, setShowMobileTabs] = useState(false);
    const [activeCategory, setActiveCategory] = useState(null);

    // Group cards by category
    const groupedCards = cards.reduce((acc, card) => {
        if (!acc[card.category]) {
            acc[card.category] = [];
        }
        acc[card.category].push(card);
        return acc;
    }, {});


    const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
        if (window.innerWidth >= 900) {
            setShowMobileTabs(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isSmallScreen = useMediaQuery("(max-width:900px)");

    useEffect(() => {
        setIsMobile(isSmallScreen);
    }, [isSmallScreen]);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        setActiveCategory(tabId === 1 ? null : tabId);
        if (isMobile) {
            setDrawerOpen(false); // Close drawer when a tab is clicked
        }
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <Container>
            {/* <Box p={2}> */}
            <Grid
                container
                justify="center"
                alignItems="flex-start"
                spacing={2} // Add spacing btn Grid items
            >
                <Grid item xs={12}>
                    <div style={{
                        display: "flex", flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}>
                        <Typography variant="subtitle1" color="black" sx={{ my: 5, marginLeft: { xs: 0, sm: "1%" } }}>
                            Test Series By Categories
                        </Typography>
                        <TextField
                            variant="outlined"
                            placeholder="Search Test Series"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                sx: {
                                    borderRadius: "5px", height: "40px", my: 4,
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
                                    width: '100%',
                                    maxWidth: isSmallScreen ? '100%' : 'auto',
                                    marginLeft: isSmallScreen ? 'auto' : '-10px',
                                    marginRight: isSmallScreen ? 'auto' : '80px',
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={clearSearch}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Paper elevation={3} className="paper-container" sx={{ width: "auto", height: "100%", margin: "1%", mt: "-0%" }}>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <div className={`vertical-tabs-container`} style={{ zIndex: "-100", marginTop: "-0%" }}>
                                    {isSmallScreen && (
                                        <IconButton color="inherit" onClick={toggleDrawer}>
                                            <MenuIcon />
                                        </IconButton>
                                    )}
                                </div>
                                {isMobile ? (
                                    <Drawer
                                        anchor="left"
                                        open={drawerOpen}
                                        onClose={handleDrawerClose}
                                        style={{ width: 400 }}
                                    >
                                        <List>
                                            {tabsData.map((tab, index) => (
                                                <React.Fragment key={tab.id}>
                                                    <ListItem button onClick={() => handleTabClick(tab.id)}>
                                                        <ListItemText primary={tab.title} />
                                                    </ListItem>
                                                    {index < tabsData.length - 1 && <Divider />}
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </Drawer>
                                ) : (
                                    <div className={`vertical-tabs-container`} style={{ zIndex: "-100" }}>
                                        <div className={`vertical-tabs`} style={{ height: "100%", marginTop: "15px", }}>
                                            {tabsData.map((tab) => (
                                                <div
                                                    key={tab.id}
                                                    className={`vertical-tab1 ${tab.id === activeCategory ? "active" : ""}`}
                                                    style={{ width: "auto"}}
                                                    onClick={() => handleTabClick(tab.id)}
                                                >
                                                    {tab.title}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12} md={9} >
                                <div className='cards-container'>
                                <Grid container spacing={2} style={{paddingRight: isMobile ? '20px' : '0px', paddingLeft: isMobile ? '80px' : '0px'}}>
                                    {/* <Grid container spacing={2}> */}
                                    {searchQuery !== '' ? (
                                        filteredCards.length === 0 ? (
                                            <Grid item xs={12}>
                                                <Typography variant="body1" style={{ marginTop: '10px' }}>No results found for "{searchQuery}"</Typography>
                                            </Grid>
                                        ) : (
                                            filteredCards.map((card) => (
                                                <Grid key={card.id} item xs={12} sm={6} md={5} lg={4}>
                                                    <TestCard {...card} />
                                                </Grid>
                                            ))
                                        )
                                    ) : (
                                        activeCategory ? (
                                            groupedCards[activeCategory].map(card => (
                                                <Grid key={card.id} item xs={12} sm={6} md={5} lg={4}>
                                                    <TestCard {...card} />
                                                </Grid>
                                            ))
                                        ) : (
                                            cards.map(card => (
                                                <Grid key={card.id} item xs={12} sm={6} md={5} lg={4}>
                                                    <TestCard {...card} />
                                                </Grid>
                                            ))
                                        )
                                    )}
                                    {/* </Grid> */}
                                </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {/* </Box> */}
        </Container>
    );
};


