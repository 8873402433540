import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Grid,
  Button,
  IconButton,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import "./ContentName.css";

function AddContentName({
  initialFormData,
  openModal,
  setOpenModal,
  SetDataBaseUpdate,
  CloseActionDialogMenu,
}) {

   //----------------------------------------------------------------
  //State Handle
  //----------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    syllabus: "",
    education: "",
    branch_field_of_study: "",
    class_sem: "",
    subject: "",
    chapter: "",
    topic: "",
    Sub_Topics: [],
    isPublished: true,
    ...initialFormData,
  });
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [newOptions, setNewOptions] = useState({});
  const fieldOrder = [
    "syllabus",
    "education",
    "branch_field_of_study",
    "class_sem",
    "subject",
    "chapter",
    "topic",
  ];
  const LoginEmail = localStorage.getItem("HTES_user_id");

  //----------------------------------------------------------------
  // Handle Modal State open close
  //----------------------------------------------------------------
  useEffect(() => {
    if (openModal) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openModal]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
    resetForm();
  };

  //----------------------------------------------------------------
  // Reset Form Data
  //----------------------------------------------------------------
  const resetForm = () => {
    setFormData({
      syllabus: "",
      education: "",
      branch_field_of_study: "",
      class_sem: "",
      subject: "",
      chapter: "",
      topic: "",
      Sub_Topics: [],
      isPublished: true,
      ...initialFormData,
    });
  };

  //----------------------------------------------------------------
  // Render the edit data properly when clicked
  //----------------------------------------------------------------
  useEffect(() => {
    if (initialFormData) {
      console.log("Initial form data received:", initialFormData);
      setFormData({
        subject: initialFormData.subject || "",
        chapter: initialFormData.chapter || "",
        topic: initialFormData.topic || "",
        Sub_Topics: initialFormData.sub_topics && initialFormData.sub_topics !== "[]"
          ? JSON.parse(initialFormData.sub_topics)
          : initialFormData.sub_topic
            ? [initialFormData.sub_topic]
            : [], // Parse array or fallback to single sub-topic
        isPublished:
          initialFormData?.isPublished === "1" || initialFormData?.isPublished === 1,
        ...initialFormData,
      });
      console.log("Updated formData:", formData);
    }
  }, [initialFormData]);

  useEffect(() => {
    console.log("Current formData state:", formData);
  }, [formData]);

  //----------------------------------------------------------------
  // Initialize formData with correct boolean values(switch)
  //----------------------------------------------------------------
  useEffect(() => {
    if (openModal) {
      setFormData((prevState) => ({
        ...prevState,
        isPublished:
          initialFormData?.isPublished === "1" ||
          initialFormData?.isPublished === 1,
      }));
    }
  }, [initialFormData, openModal]);

   //----------------------------------------------------------------
  // Fetch Dropdown Options on Component Mount
  //----------------------------------------------------------------
  const fetchDropdownOptions = async () => {
    try {
      const optionsResponse = await axios.get(`${baseURL}/getcontent`);
      const optionsData = optionsResponse.data;

      const uniqueOptions = {};
      fieldOrder.slice(0, 3).forEach((field) => {
        uniqueOptions[field] = [
          ...new Set(optionsData.map((row) => row[field])),
        ];
      });

      setDropdownOptions(uniqueOptions);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  // Initial Fetch of Dropdown Options
  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  //----------------------------------------------------------------
  // Add New Option
  //----------------------------------------------------------------
  const handleAddOption = (field) => {
    const newOption = newOptions[field];
    if (newOption && !dropdownOptions[field]?.includes(newOption)) {
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        [field]: [...(prevOptions[field] || []), newOption],
      }));
      setFormData((prevState) => ({
        ...prevState,
        [field]: newOption,
      }));
      setNewOptions((prevOptions) => ({
        ...prevOptions,
        [field]: "",
      }));
    }
  };

  //----------------------------------------------------------------
  // Add Sub topic
  //----------------------------------------------------------------
  const handleAddSubTopic = () => {
    setFormData((prevState) => ({
      ...prevState,
      Sub_Topics: [...prevState.Sub_Topics, ""],
    }));
  };

  const handleSubTopicChange = (index, value) => {
    const updatedSubTopics = [...formData.Sub_Topics];
    updatedSubTopics[index] = value;
    setFormData((prevState) => ({
      ...prevState,
      Sub_Topics: updatedSubTopics,
    }));
  };

  //----------------------------------------------------------------
  // Handle Autocomplete Input Change
  //----------------------------------------------------------------
  const handleAutocompleteInputChange = (event, value, field) => {
    setNewOptions((prevOptions) => ({
      ...prevOptions,
      [field]: value,
    }));
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  //----------------------------------------------------------------
  // Handle Form Submission both update and post
  //----------------------------------------------------------------
  const handleSubmit = async () => {
    console.log('Submitting data:', formData); // Ensure the formData has the correct values
    try {
      const hasSubTopics = formData.Sub_Topics && formData.Sub_Topics.length > 0;
      const payload = {
        syllabus: formData.syllabus,
        education: formData.education,
        branch_field_of_study: formData.branch_field_of_study,
        class_sem: formData.class_sem,
        subject: formData.subject,
        chapter: formData.chapter,
        topic: formData.topic,
        sub_topic: formData.Sub_Topics[0],
        is_sub_topic_available: hasSubTopics,
        sub_topics: hasSubTopics ? formData.Sub_Topics : [],
        chapter_order: 1,
        topic_order: 1,
        isPublished: formData.isPublished,
        Created_By: LoginEmail,
        Modified_By: LoginEmail,
        parent_id: null,
      };

      let response;
      if (initialFormData && initialFormData.id) {
        response = await axios.put(`${baseURL}/topic-update/${initialFormData.id}`, payload);
      } else {
        response = await axios.post(`${baseURL}/create-topic`, payload);
      }

      customAlert("Content saved successfully", "success");
      SetDataBaseUpdate((prev) => !prev);
      handleClose();
    } catch (error) {
      console.error("Error saving content:", error);
      customAlert("Error saving content. Please try again.", "error");
    }
  };

  return (
    <div>
      {/* Add and update modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Add-update-course-name"
      >
        <div className="add-update-content-name-border-avoide">
          <div className="add-content-name">
            {/* Close Button */}
            <IconButton
              id="button-zoom"
              className="add-update-content-name-close-button"
              onClick={handleClose}
            >
              <CloseIcon className="add-update-content-name-close-button-x" />
            </IconButton>
  
            {/* Header */}
            <div className="add-content-name-swith-header add-update-content-header">
              <h5 className="add-content-name-header-name">
                {initialFormData && initialFormData.id
                  ? "Update Content"
                  : "Add Content"}
              </h5>
            </div>
  
            {/* Scrollable Fields */}
            <div className="add-update-content-name-scrollable-fields">
              <Grid container spacing={1}>
                {fieldOrder.map((field, index) => (
                  <Grid item xs={12} key={index}>
                    <div className="add-update-content-name-textarea-and-button">
                      <Autocomplete
                        fullWidth
                        disableClearable
                        options={dropdownOptions[field] || []}
                        value={formData[field] || ""}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") return option;
                          if (option && typeof option === "object") {
                            return option.label || "";
                          }
                          return "";
                        }}
                        onChange={(e, newValue) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            [field]: newValue || "",
                          }))
                        }
                        onInputChange={(e, newValue) =>
                          handleAutocompleteInputChange(e, newValue, field)
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label={index + 1 + ". " + field}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {params.InputProps.endAdornment}
                                  {newOptions[field] &&
                                    !dropdownOptions[field]?.includes(
                                      newOptions[field]
                                    ) && (
                                      <Tooltip title={`Add ${field}`}>
                                        <div
                                          className="add-update-content-name-add-button"
                                          onClick={() => handleAddOption(field)}
                                        >
                                          <AddIcon className="modal-add-additional-option-button" />
                                        </div>
                                      </Tooltip>
                                    )}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                ))}
  
                {/* Sub Topics */}
                <Grid item xs={12}>
                  <Tooltip title="Add Sub Topic" arrow>
                    <Button endIcon={<AddIcon />} onClick={handleAddSubTopic}></Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <div className="content-sub-topic-container">
                    {formData.Sub_Topics.map((subTopic, index) => (
                      <TextField
                        fullWidth
                        label={`Sub Topic ${index + 1}`}
                        variant="outlined"
                        value={subTopic}
                        onChange={(e) => handleSubTopicChange(index, e.target.value)}
                        className="content-sub-topic-field"
                        key={index}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
  
            {/* Footer */}
            <div className="add-update-content-name-save-update-button add-update-content-footer">
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleSubmit}
              >
                {initialFormData && initialFormData.id ? (
                  <UpdateIcon className="buttonicons" />
                ) : (
                  <SaveIcon className="buttonicons" />
                )}
                &nbsp;
                {initialFormData && initialFormData.id ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddContentName;
