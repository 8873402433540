import React from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function UploadFromTemplate({ DBApi, close, update }) {
  const endpointString = DBApi.endpoint;

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the data is present in the first sheet (index 0)
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to JSON format
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Required columns
      const requiredColumns = [
        "EmployeeName",
        "EmployeeEmail",
        "EmployeePhoneNumber",
      ];

      // Validate email format using a simple regex pattern
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Check for invalid emails and collect the modified data
      const modifiedData = [];
      let invalidEmailIndices = [];

      jsonData.forEach((row, index) => {
        const modifiedRow = {};

        // Validate email format
        const email = row["EmployeeEmail"] || "";
        if (!emailPattern.test(email)) {
          invalidEmailIndices.push(index + 2); // Push the index of the invalid email row (adding 2 to match Excel's row number starting at 1 and including header)
          return; // Skip this row
        }

        // Add required columns with values from the uploaded data
        requiredColumns.forEach((header) => {
          modifiedRow[header] = row[header] || ""; // Set value from uploaded data or empty string if missing
        });

        // Add additional fields
        modifiedRow["EmployeeStatus"] = "WIP";
        modifiedRow["Created_by"] = localStorage.getItem("HTES_user_id");
        modifiedRow["Created_date"] = new Date().toISOString();
        modifiedRow["Modified_by"] = localStorage.getItem("HTES_user_id");
        modifiedRow["Modified_date"] = new Date().toISOString();
        modifiedRow["UTC_Time"] = new Date().toISOString();

        modifiedData.push(modifiedRow);
      });

      if (invalidEmailIndices.length > 0) {
        // Show alert for invalid email format with row numbers
        customAlert(
          "",
          `Invalid Email format found in row  ${invalidEmailIndices.join(
            ", "
          )}. Please check and try again.`,
          "warning"
        );
        close();
        return; // Stop further processing
      }

      try {
        // Send the modified JSON data to the backend
        const response = await axios.post(baseURL + DBApi, modifiedData);
        console.log(response);

        // Check if the response status is OK (200)
        if (response.status === 200) {
          customAlert(
            "",
            "Data uploaded successfully and dropped the duplicate Employees",
            "success"
          );
          update(true);
          close();
        } else {
          customAlert(
            "",
            "Error uploading data. Please check the template and database connectivity",
            "error"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          customAlert(
            "",
            "Duplicate record found. Please check and try again.",
            "warning"
          );
        } else {
          customAlert(
            "",
            "Error uploading data. Please check the template and database connectivity",
            "error"
          );
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleUpload}
        style={{ display: "none" }}
        id="upload-file"
      />
      <label htmlFor="upload-file">
        <div>
          <FileUploadIcon
            className="course-name-template-addboxixon-style"
            color="primary"
            fontSize="large"
          />
          <br />
          <span>
            Upload
            <br />
            Employee's
          </span>
        </div>
      </label>
    </div>
  );
}

export default UploadFromTemplate;
