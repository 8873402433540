import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import GrantAccess2 from "./GrantAccess2";
import AddUserRoll from "./AddUserRoll";

function RollAccessTabLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [role, setRole] = useState("");
  const [editData, setEditData] = useState([]);
  const [inputValue, setInputValue] = useState(); // This will track typed input
  const [selectedRole, setSelectedRole] = useState(null); // For selected options

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/Roll_Default_Access_Fetch_All`
        );
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);



  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    setEditData(rowdata);
    setOpen1(true);
  };

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function and close the modals
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRole("");
    setEditData([]);
    setInputValue();
    setSelectedRole(null);
    setOpen(false);
  };

  const handleClose1 = () => {
    setRole("");
    setEditData([]);
    setInputValue();
    setSelectedRole(null);
    setOpen1(false);
    setOpen(false);
  };
  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render with default column if empty
        rows={renderRows} // Rows render      
        handleEdit={handleEdit} //Handle the Edit function
        deleteApi="Access_Roll_Default_delete" // Delete Api
        tableNameForExport="Roll_Access_Management" // Export the Data to excel/csv (If name requred for the Excel or Csv)
        is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
        handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
        Manage_button_name="Add New Role" //Name of the Manage button(in center of the header)
        SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
        DefaultColumnNames={["id", "UserRole", "DefaultAccessTabs"]} // Add to show the columns in a default render
        CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
      />
      {/* _______________________End_____________________________ */}
      {/* _____________Add new role______________ */}
      <AddUserRoll
        open={open}
        close={handleClose}
        setRole={setRole}
        setOpen1={setOpen1}
        renderRows={renderRows}
        inputValue={inputValue}
        setInputValue={setInputValue}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />
      {/* ___________________end_____________________ */}
      {/* ____________________GrantAccessEdit/Add____________________ */}
      <div>
        <GrantAccess2
          GiveAccesModalStatus={open1}
          close={handleClose1}
          role={role}
          SetDataBaseUpdate={SetDataBaseUpdate}
          editData={editData}
        />
      </div>

      {/* _____________________end_________________ */}
    </div>
  );
}

export default RollAccessTabLandingPage;
