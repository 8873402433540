import React, { useState } from "react";
import { Modal, Tooltip, IconButton, Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import NewUserAdd from "./NewUserAdd";
import DownloadTemplate from "./DownloadTemplate";
import UploadFromTemplate from "./UploadFromTemplate";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

function UserManagementTemplate({ setUpdate, dropdownOptions }) {
  const [openModal, setOpenModal] = useState(false);

  console.log(dropdownOptions)

  //________________________open and close the usertemplatetemplate__________/

  const handleFabClick = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  //_______________________________end_______________________________//
  return (
    <div>
      {" "}
      <Tooltip title="Manage Users" arrow>
        <div
          onClick={handleFabClick}
          className="usermanagementheadercopyfilter1"
        >
          {/* <h5 className='usermanagementtemplateheader' >User Management Table</h5>  */}
          <ManageAccountsIcon className="buttonicons" id="button-zoom" />
          &nbsp;
          <span className="usertemplatebuttonamecontrol">Manage User</span>
          <MoreVertIcon className="buttonicons" id="button-zoom">
            {" "}
          </MoreVertIcon>
        </div>
      </Tooltip>
      {/* ____________________start the template modal___________________________ */}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="question-bank-modal-title"
        aria-describedby="question-bank-modal-description"
        className="educationalroadmapmodelcenteralignment"
      >
        <div className="modelcontainereducationroadmap">
          <IconButton
            id="button-zoom"
            className="EducationalTableViewmodalcloseicon"
            onClick={handleModalClose}
          >
            <CloseIcon className="EducationalTableViewmodalcloseiconX" />
          </IconButton>
          <h5 className="EducationalTableViewmodalHeader">User Management </h5>
          <div className="educationalroadmapinsidecontentgridalignment">
            <NewUserAdd setUpdate={setUpdate}  dropdownOptions={dropdownOptions}/>
            <DownloadTemplate />
            <UploadFromTemplate
              DBApi="/register"
              setUpdate={setUpdate}
              close={handleModalClose}
            />
          </div>
        </div>
      </Modal>
      {/* ____________________end___________________________ */}
    </div>
  );
}

export default UserManagementTemplate;
