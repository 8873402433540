import React, { useState } from "react";
import { TextField, Button, Box, Autocomplete } from "@mui/material"; // For Material-UI styling
import { style } from "@mui/system";

function HRForm({formValues,setFormValues}) {
  

  const [emailError, setEmailError] = useState("");

  // List of IT-related designations
  const designations = [
    "Software Engineer",
    "Senior Software Engineer",
    "Tech Lead",
    "Project Manager",
    "QA Engineer",
    "System Administrator",
    "Network Engineer",
    "Data Scientist",
    "DevOps Engineer",
    "Full Stack Developer",
  ];


  const departments=['IT Support / Help Desk','DevOps','Quality Assurance (QA) / Testing','Systems Administration','Web Development']

  // Offer letter statuses
  const offerLetterStatuses = [
    "Pending",
    "Accepted",
    "Rejected",
    "Under Review",
  ];

  // Employee types
  const employeeTypes = ["Permanent", "Contract", "Intern", "Consultant"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "CompanyMailId") {
      // Email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(value)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError("");
      }
    }

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (emailError) {
      alert("Please correct the email error before submitting.");
      return;
    }

    // Append data to FormData object
    const formData = new FormData();
    formData.append("CompanyMailId", formValues.CompanyMailId);
    formData.append("Designation", formValues.Designation);
    formData.append("DateOfJoining", formValues.DateOfJoining);
    formData.append("OfferLetterStatus", formValues.OfferLetterStatus);
    formData.append("EmployeeType", formValues.EmployeeType);
    formData.append("EmployeeSalary", formValues.EmployeeSalary);
    formData.append("Department", formValues.Department);

    // You can now send formData to your API using Axios or fetch
    console.log("Form data appended:", Object.fromEntries(formData.entries()));
  };

  return (
    <div className="hr-form-container">
      <div className="personal-info-heading">HR-Use Only</div>
      <div className="hr-grid-form-container">
        <TextField
          label="Company Mail ID"
          name="CompanyMailId"
          value={formValues.CompanyMailId}
          onChange={handleChange}
          error={!!emailError}
          helperText={emailError}
          fullWidth
        />
         <Autocomplete
          options={departments}
          freeSolo
          value={formValues.Department}
          onInputChange={(e, newValue) =>
            setFormValues({ ...formValues, Department: newValue })
          }
          renderInput={(params) => (
            <TextField {...params} label="Departement" fullWidth />
          )}
        />
        <Autocomplete
          options={designations}
          freeSolo
          value={formValues.Designation}
          onInputChange={(e, newValue) =>
            setFormValues({ ...formValues, Designation: newValue })
          }
          renderInput={(params) => (
            <TextField {...params} label="Designation" fullWidth />
          )}
        />
        <TextField
          label="Date of Joining"
          name="DateOfJoining"
          type="date"
          value={formValues.DateOfJoining}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{ shrink: true }} // Ensure the label shrinks for date input
          inputProps={{
            max: new Date().toISOString().split("T")[0], // Restrict to today
            style: { textTransform: "uppercase" }, // Add custom styling
          }}
        />

        <Autocomplete
          options={offerLetterStatuses}
          value={formValues.OfferLetterStatus}
          onChange={(e, newValue) =>
            setFormValues({ ...formValues, OfferLetterStatus: newValue })
          }
          renderInput={(params) => (
            <TextField {...params} label="Offer Letter Status" fullWidth />
          )}
        />
        <Autocomplete
          options={employeeTypes}
          value={formValues.EmployeeType}
          onChange={(e, newValue) =>
            setFormValues({ ...formValues, EmployeeType: newValue })
          }
          renderInput={(params) => (
            <TextField {...params} label="Employee Type" fullWidth />
          )}
        />
      </div>
    </div>
  );
}

export default HRForm;
