import React, { useState } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DownloadTemplate from "./DownloadTemplate";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadFromTemplate from "./UploadFromTemplate";
import InterviewQuestionGenaralInfoAddModal from "./InterViewQuestionAddEdit/InterviewQuestionGenaralInfoAddModal";

function InterViewQuestionManageTemplate({ open, close, SetDataBaseUpdate }) {
  const [openAddModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //--------------------------------------------------------------------------------
  //handle clik for the DownladTempalte
  //--------------------------------------------------------------------------------

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //--------------------------------------------------------------------------------
  //handle clik for the add course button
  //--------------------------------------------------------------------------------
  const handleClickAddCourse = () => {
    setOpenModal(true);
    close();
  };

  //--------------------------------------------------------------------------------
  //Close the Template modal
  //--------------------------------------------------------------------------------
  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal open={open} onClose={close} className="course-template-modal">
        {/* _________________________Header_______________________ */}
        <div className="course-template-modal-container">
          <div className="course-name-template-container-with-content">
            <IconButton
              id="button-zoom"
              className="course-name-template-close-button"
              onClick={close}
            >
              <CloseIcon className="course-name-template-close-button-x" />
            </IconButton>
            <h5 className="course-name-template-header">
              Interview Questions Management
            </h5>
            {/* _________________________End_______________________ */}
            {/* _________________________Button,upload,download,add_______________________ */}
            <div className="course-name-template-add-course-icon">
              <div onClick={handleClickAddCourse}>
                <AddBoxIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />

                <div>
                  Add <br></br> Questions
                </div>
              </div>

              <div onClick={handleMenuOpen}>
                <FileDownloadIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />

                <div>
                  Download <br></br>Template
                </div>
              </div>

              {/* __________________________FQAs Manually upload form Excel_____________________ */}

              <UploadFromTemplate
                DBApi="/Interview_Questions"
                close={close}
                update={SetDataBaseUpdate}
              />
              {/* __________________________FQAs Manually upload form Excel_____________________ */}
            </div>
          </div>
        </div>
        {/* ___________________________end_____________________________________ */}
      </Modal>

      {/* __________________________Add Manually FAQs_____________________ */}

      {/* <FAQTemplate openAddModal={openAddModal} handleModalClose={handleModalClose} SetDataBaseUpdate={SetDataBaseUpdate}  /> */}

      {/* ________________________________end_____________________________________*/}

      {/* __________________________FQAs Template_____________________ */}

      <DownloadTemplate anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      {/* _______________________________End_____________________________ */}

      {/*_____________Interview question Component call__________  */}
      <InterviewQuestionGenaralInfoAddModal
        open={openAddModal}
        handleCloseModal={handleModalClose}
        setOpen={setOpenModal}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />

      {/* _______________________________End_____________________________ */}
    </div>
  );
}

export default InterViewQuestionManageTemplate;
