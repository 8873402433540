import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
  Modal,
  TextField,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import { Container } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import { customAlert } from "../SweetAlertCommon/Custom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DangerousIcon from '@mui/icons-material/Dangerous';
import TranslateIcon from '@mui/icons-material/Translate';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FeedbackIcon from '@mui/icons-material/Feedback';

const QuizNav = ({
  timerActive,
  setTimerActive,
  reportModalOpen,
  setReportModalOpen,
  loadingTimeoff,
  reportDetailsArray,
  setReportDetailsArray,
  questionIndex,
  pausedTimes,
  setPausedTimes,
  isRightNavOpen,
  handleNext,
  handlePrevious,
}) => {
  // Anchor element for the report menu
  // State to control the report modal
  //console.log('reportModalOpen',reportModalOpen)





  const handleReportModalClose = () => {
    setReportModalOpen(false);
  };

  const handleReportSubmit = () => {
    const updatedReportDetailsArray = [...reportDetailsArray];
    updatedReportDetailsArray[questionIndex].details =
      reportDetailsArray[questionIndex].details;
    setReportDetailsArray(updatedReportDetailsArray);
    setReportModalOpen(false);
    // handleReportMenuClose();
    customAlert("", "FeedBack Added Successfully", "success");
  };

  useEffect(() => {
    let interval;
    if (timerActive && !loadingTimeoff && !reportModalOpen) {
      // Start the timer only if timerActive is true and loadingTimeoff is false
      interval = setInterval(() => {
        setPausedTimes((prevPausedTimes) => {
          const updatedPausedTimes = [...prevPausedTimes];
          const newPausedTime = { ...prevPausedTimes[questionIndex] };
          newPausedTime.seconds++;
          if (newPausedTime.seconds === 60) {
            newPausedTime.seconds = 0;
            newPausedTime.minutes++;
          }
          updatedPausedTimes[questionIndex] = newPausedTime;
          return updatedPausedTimes;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [
    questionIndex,
    timerActive,
    loadingTimeoff,
    setPausedTimes,
    reportModalOpen,
  ]);

  return (
    <div className="quiz-nav-box-shadow">
      <Grid>
        <Container >
          <Stack>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "40px",
              }}
            >
              <div style={{ marginTop: "10px" }}>
                <p style={{ fontSize: "10px" }}>
                  Time Spent:
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {pausedTimes[questionIndex].minutes
                      .toString()
                      .padStart(2, "0")}
                    <span style={{ color: "blue", fontSize: "8px" }}>M</span>:
                    {pausedTimes[questionIndex].seconds
                      .toString()
                      .padStart(2, "0")}
                    <span style={{ color: "blue", fontSize: "8px" }}>S</span>
                  </span>
                </p>
              </div>
</div>
          </Stack>
        </Container>
      </Grid>
      <Modal
        open={reportModalOpen}
        onClose={handleReportModalClose}
        style={{
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "550px",
            width: "90%",
            borderRadius: "8px",
          }}
        >
          <IconButton
            className="addeducationalMapmodalcancel"
            onClick={handleReportModalClose}
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight="bold"
            style={{ textAlign: "center" }}
          >
            Provide Further Details
          </Typography>
          <TextField
            id="report-text"
            placeholder="Please Provide further details about the issue(at least 6-7 words )"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            margin="normal"
            value={reportDetailsArray[questionIndex].details}
            onChange={(e) => {
              const updatedReportDetailsArray = [...reportDetailsArray];
              updatedReportDetailsArray[questionIndex].details = e.target.value;
              setReportDetailsArray(updatedReportDetailsArray);
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleReportSubmit}
            >
              <SendIcon className="buttonicons" />
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default QuizNav;
