import React, { useState } from "react";
import { Chip, IconButton, Snackbar, Alert, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TimelapseIcon from '@mui/icons-material/Timelapse';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
function TestCardView1({ loading, SelectedTest, setSelectedTest, Testall }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //--------------------------------------------------------------------------------
  // Function to handle the selection of a question
  //--------------------------------------------------------------------------------
  const handleAddQuestion = (question) => {
    if (SelectedTest.includes(question)) {
      // If the question is already selected, remove it from selectedQuestions
      setSelectedTest((prevSelected) =>
        prevSelected.filter((q) => q !== question)
      );
    } else {
      // If it's not selected, add it to the top of selectedQuestions and show Snackbar
      setSelectedTest((prevSelected) => [question, ...prevSelected]); // Add to the top
      setSnackbarOpen(true); // Open Snackbar
    }
  };

  //--------------------------------------------------------------------------------
  // Handle the "Done" action to move the question to the top
  //--------------------------------------------------------------------------------
  const handleMarkAsDone = (question) => {
    setSelectedTest((prevSelected) => {
      // Remove the question from the current position
      const updatedSelected = prevSelected.filter((q) => q !== question);
      // Add it back at the top
      return [question, ...updatedSelected];
    });
  };

  //--------------------------------------------------------------------------------
  // Handle closing the Snackbar
  //--------------------------------------------------------------------------------
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Sort rows to bring selected questions to the top
  // Sort rows to bring selected questions to the top
  // const sortedRows = Array.isArray(rows) && rows.length > 0
  //   ? [...rows].sort((a, b) => {
  //       const isASelected = selectedQuestions.includes(a);
  //       const isBSelected = selectedQuestions.includes(b);
  //       return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
  //     })
  //   : []; // Return an empty array if rows is empty or not an array

  return (
    <>
      <div>
        <div className="accordion-container-add-totest">
          {Testall &&
            Testall.map((Test, index) => (
              <div
                key={index}
                className="all-component-render-page-outer-container"
              >
                <div className="accordion-summary-of-the-question-display">
                  {Test.TestName}
                </div>
                <div className="Testcardview1-general-info-alignemnt">
                    <div className="testcardview1-icon-and-text-alignment">
               <HelpOutlineIcon className="buttonicons-test-card-view1"/>No.Of Questions:<span className="Test-card-view1-text-color">&nbsp; {Test.NoOfQuestions}</span>
               </div>
               <div className="testcardview1-icon-and-text-alignment">
               <TimelapseIcon className="buttonicons-test-card-view1"/>Test Duration: <span className="Test-card-view1-text-color">&nbsp;{Test.TestDuration}min</span>
               </div>
                </div>
                <div>
                  {/* Expanded details for each question */}
                  <div>
                    <div className="add-icon-and-right-icon-placement">
                      <Chip
                        className="difficulty-level-shown-color-font-test-card-view "
                        label={Test.FreeOrPaid}
                        style={{
                          backgroundColor:
                            Test.FreeOrPaid === "Free"
                              ? "green"
                              : Test.FreeOrPaid === "Paid"
                              ? "orange"
                              : "defaultColor", // Use a default color if none of the conditions are met
                        }}
                      />

                      {SelectedTest.includes(Test) ? (
                        <Tooltip title="Navigate the Test" arrow>
                          <IconButton
                            className="icon-button-to-disapay-done-button"
                            onClick={() => handleMarkAsDone(Test)} // Mark as done
                          >
                            <DoneAllIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add Test to Test Series" arrow>
                          <IconButton
                            className="icon-button-to-disapay-add-button"
                            onClick={() => handleAddQuestion(Test)}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={500}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">Question added to test successfully!</Alert>
      </Snackbar>
    </>
  );
}

export default TestCardView1;
