import React, { useState } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { baseURL } from "../../http"; // Ensure you have your baseURL configured
import { customAlert } from "../SweetAlertCommon/Custom"; // Assuming this is your custom alert function
import "./ProjectDetailsPage.css";

const ProjectDetailsAddProject = ({
  openModal,
  setOpenModal,
  SetDataBaseUpdate,
}) => {
  const [formData, setFormData] = useState({
    project_name: "",
    po_start_date: "",
    po_end_date: "",
    place_of_supply: "",
    deliverable: "",
    cost: "",
    from_company: "",
    to_company: "",
    multiDeliverables: [], // Initially empty array for multi-deliverables
  });

  const resetFormData = () => {
    setFormData({
      project_name: "",
      po_start_date: "",
      po_end_date: "",
      place_of_supply: "",
      deliverable: "",
      cost: "",
      from_company: "",
      to_company: "",
      multiDeliverables: [], // Reset multi-deliverables to an empty array
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMultiDeliverableChange = (index, value) => {
    const updatedMultiDeliverables = [...formData.multiDeliverables];
    updatedMultiDeliverables[index] = value;
    setFormData({ ...formData, multiDeliverables: updatedMultiDeliverables });
  };

  const addMultiDeliverable = () => {
    setFormData({
      ...formData,
      multiDeliverables: [...formData.multiDeliverables, ""], // Append a new empty input field
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      "project_name",
      "po_start_date",
      "po_end_date",
      "place_of_supply",
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      customAlert("Warning", "Please fill the required fields.", "warning");
      return;
    }

    try {
      const response = await axios.post(`${baseURL}/to-company-store`, formData);
      if (response.status === 200) {
        customAlert("Success", "Form submitted successfully!", "success");
        SetDataBaseUpdate(true);
        setOpenModal(false);
        resetFormData(); // Reset form after submission
      }
    } catch (error) {
      customAlert("Error", "There was an error submitting the form.", "error");
    }
  };

  // Ensure PO End Date is not before PO Start Date
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    if (name === "po_start_date" && value < today) {
      customAlert("Warning", "PO Start Date cannot be before today.", "warning");
      return;
    }

    if (name === "po_end_date" && (value < today || value < formData.po_start_date)) {
      customAlert("Warning", "PO End Date must be today or later, and not earlier than the PO Start Date.", "warning");
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetFormData();
        setOpenModal(false);
      }}
    >
      <div className="projectdetails-enquiry-content">
        <IconButton
          className="projectdetails-enquiry-close-btn"
          onClick={() => {
            resetFormData();
            setOpenModal(false);
          }}
        >
          <CloseIcon className="projectdetails-enquiry-close-btnX3" />
        </IconButton>
        <div className="projectdetails-enquiry-header">Project Details</div>

        <form className="projectdetails-enquiry-form" onSubmit={handleFormSubmit}>
          <TextField
            margin="dense"
            label="Project Name"
            name="project_name"
            type="text"
            fullWidth
            required
            value={formData.project_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="PO Start Date"
            name="po_start_date"
            type="date"
            fullWidth
            required
            value={formData.po_start_date}
            onChange={handleDateChange} // Use handleDateChange for validation
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: new Date().toISOString().split("T")[0] }} // Restrict past dates
          />
          <TextField
            margin="dense"
            label="PO End Date"
            name="po_end_date"
            type="date"
            fullWidth
            required
            value={formData.po_end_date}
            onChange={handleDateChange} // Use handleDateChange for validation
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: new Date().toISOString().split("T")[0] }} // Restrict past dates
          />
          <TextField
            margin="dense"
            label="Place of Supply"
            name="place_of_supply"
            type="text"
            fullWidth
            required
            value={formData.place_of_supply}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Deliverable 1"
            name="deliverable"
            type="text"
            fullWidth
            value={formData.deliverable}
            onChange={handleChange}
          />
          <div className="multi-deliverables-section">
            {formData.multiDeliverables.map((deliverable, index) => (
              <TextField
                key={index}
                margin="dense"
                label={`Deliverable ${index + 2}`}
                type="text"
                fullWidth
                value={deliverable}
                onChange={(e) =>
                  handleMultiDeliverableChange(index, e.target.value)
                }
              />
            ))}
            <IconButton color="primary" onClick={addMultiDeliverable}>
              <AddIcon />
            </IconButton>
          </div>
          <TextField
            margin="dense"
            label="Cost"
            name="cost"
            type="number"
            fullWidth
            value={formData.cost}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="From Company"
            name="from_company"
            type="text"
            fullWidth
            value={formData.from_company}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="To Company"
            name="to_company"
            type="text"
            fullWidth
            value={formData.to_company}
            onChange={handleChange}
          />
        </form>

        <div className="projectdetails-enquiry-actions">
          <Button
            type="submit"
            color="success"
            variant="contained"
            onClick={handleFormSubmit}
          >
            <SaveIcon className="projectdetails-buttoniconcommon" />
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectDetailsAddProject;
