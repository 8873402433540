import React from "react";
import ParentHeader from "./ParentHeader";
import ParentSideNav from "./1ParentSideNav";

function ParentDashboard() {
  return (
    <div>
      <ParentHeader />
      {/* <h1>ParentDashboard</h1> */}
      <ParentSideNav />
    </div>
  );
}

export default ParentDashboard;
