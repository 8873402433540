import { useEffect } from "react";
import "./ScrollProgress.css";

function ScrollProgress() {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const documentHeight = document.body.scrollHeight - window.innerHeight;
      const scrollPercentage = (scrollPosition / documentHeight) * 100;
      
      const progressBar = document.querySelector(".scroll-progress");
      if (progressBar) {
        progressBar.style.width = `${scrollPercentage}%`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="scroll-progress-bar">
      <div className="scroll-progress"></div>
    </div>
  );
}

export default ScrollProgress;
