import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQ_AccordionTemplate.css";

function FAQ_AccordionTemplate({
  faqData = [
    {
      question: "Hidwdwfw eegeg wevwg3g w3g3g",
      answer: "hello ef3 evee evege eegeg eg3 ebg 3h",
    },
  ],
}) {
  //--------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------
  const [expandedPanel, setExpandedPanel] = useState(-1); // Default to -1 so no panel is expanded initially
  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panelIndex : -1);
  };

  return (
    <div className="faqParentContainer">
      <div className="faqContainer">
        <br />
        {/* _________________FAQ Template Calling with Mapping the Props Start_______________ */}
        {faqData &&
          faqData.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expandedPanel === index}
              onChange={handleChange(index)}
              className={
                expandedPanel === index
                  ? "accordionExpanded exHOv"
                  : "accordionCollapsed exHOv"
              }
            >
              <AccordionSummary
                className="accordionSummary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <div className="accordionQuestion">{faq.question}</div>
              </AccordionSummary>

              <AccordionDetails className="accordionDetail">
                <hr className="Acc_divider" />
                {faq.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        {/* _________________FAQ Template Calling with Mapping the Props Ends _______________ */}
      </div>
    </div>
  );
}

export default FAQ_AccordionTemplate;
