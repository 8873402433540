import React, { useState } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadAllEmployeesInfo from "../DownloadAllEmployeeInfo/DownloadAllEmployeesInfo";


function EmployeeDetailsManage({ open, close,SetDataBaseUpdate }) {
  const [openAddModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

      //--------------------------------------------------------------------------------
  //handle clik for the DownladTempalte
  //--------------------------------------------------------------------------------
 

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //--------------------------------------------------------------------------------
  //handle clik for the add course button
  //--------------------------------------------------------------------------------
  const handleClickAddCourse = () => {
    setOpenModal(true);
    close()    
  };

  //--------------------------------------------------------------------------------
  //Close the Template modal
  //--------------------------------------------------------------------------------
  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal open={open} onClose={close} className="course-template-modal">
        {/* _________________________Header_______________________ */}
        <div className="course-template-modal-container">
          <div className="course-name-template-container-with-content">
            <IconButton
              id="button-zoom"
              className="course-name-template-close-button"
              onClick={close}
            >
              <CloseIcon className="course-name-template-close-button-x" />
            </IconButton>
            <h5 className="course-name-template-header">Employee Data Management</h5>
            {/* _________________________End_______________________ */}
            {/* _________________________Button,upload,download,add_______________________ */}
            <div className="course-name-template-add-course-icon">
              <div onClick={handleClickAddCourse}>
                <FileDownloadIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />

                <div>
                 Download All<br></br>Employee Data
                </div>
              </div>


              
            {/* __________________________FQAs Manually upload form Excel_____________________ */}
            </div>
          </div>
        </div>
        {/* ___________________________end_____________________________________ */}
      </Modal>

      {/* __________________________Add Manually FAQs_____________________ */}

      
<DownloadAllEmployeesInfo open={openAddModal} SetOpen={setOpenModal}  />
      {/* ________________________________end_____________________________________*/}


      {/* __________________________FQAs Template_____________________ */}

     

      {/* _______________________________End_____________________________ */}

           

{/* _______________________________End_____________________________ */}
    </div>
  );
}

export default EmployeeDetailsManage;
