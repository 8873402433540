import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { baseURL } from "../../../http";
import { jsPDF } from "jspdf";
import './pdfquestionpaper.css'
import html2canvas from "html2canvas";
import { Button } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PDFQuestionPaper = ({ editData }) => {
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [AiGeneratedQuestionBank, setAiGeneratedQuestionBank] = useState([]);
  const contentRef = useRef(null);
  
  console.log("AiGeneratedQuestionBank", AiGeneratedQuestionBank);
  console.log("editData.PatternArray", editData?.PatternArray);

  useEffect(() => {
    const fetchTablesList = async () => {
      try {
        const response = await axios.get(`${baseURL}/getAllAiGeneratedQuestions`);
        const data = response.data;
        setAiGeneratedQuestionBank(data);
        console.log("Fetched AI Generated Questions: ", data); // Debugging the response
      } catch (error) {
        console.log("Error fetching questions:", error);
        setTimeout(() => {
          fetchTablesList();
        }, 5000);
      }
    };

    fetchTablesList();
  }, []);

  // Function to filter questions based on pattern
  function filterQuestionBank(AiGeneratedQuestionBank, parsedPatternArray) {
    let filteredQuestions = [];
    console.log("Filtering with pattern: ", parsedPatternArray); // Debugging the pattern array

    for (let pattern of parsedPatternArray) {
      let selectedQuestions = AiGeneratedQuestionBank.filter((question) => {
        let criteria = true;
        for (let key of Object.keys(pattern)) {
          if (
            key !== "id" &&
            key !== "Easy" &&
            key !== "Medium" &&
            key !== "Hard"
          ) {
            if (
              question[key] == null ||
              pattern[key] == null ||
              question[key].trim() !== pattern[key].trim()
            ) {
              criteria = false;
              break;
            }
          }
        }
        return criteria;
      });

      console.log("Selected Questions after pattern match: ", selectedQuestions); // Debugging selected questions after applying pattern

      for (let difficultyLevel of ["Easy", "Medium", "Hard"]) {
        if (pattern.hasOwnProperty(difficultyLevel) && pattern[difficultyLevel] > 0) {
          let quantity = pattern[difficultyLevel];
          let questionsOfDifficultyLevel = selectedQuestions.filter(
            (question) => question.DifficultyLevels === difficultyLevel
          );
          questionsOfDifficultyLevel.sort(() => Math.random() - 0.5); // Shuffle questions
          let selectedQuestionsCount = Math.min(quantity, questionsOfDifficultyLevel.length);
          filteredQuestions.push(...questionsOfDifficultyLevel.slice(0, selectedQuestionsCount));
        }
      }
    }

    console.log("Filtered Questions: ", filteredQuestions); // Debugging final filtered questions
    return filteredQuestions;
  }

  // Function to filter and update questions
  function filterAndUpdateQuestions() {
    if (editData && editData.PatternArray && AiGeneratedQuestionBank.length > 0) {
      const filtered = filterQuestionBank(AiGeneratedQuestionBank, JSON.parse(editData.PatternArray));
      setFilteredQuestions(filtered); // Store filtered questions in state
    } else {
      console.log("Either PatternArray is missing or AiGeneratedQuestionBank is empty.");
    }
  }

  useEffect(() => {
    // Trigger filtering when editData.PatternArray changes or AI-generated questions are loaded
    filterAndUpdateQuestions();
  }, [editData, AiGeneratedQuestionBank]);


  const groupByQuestionType = () => {
    const grouped = {};
    filteredQuestions.forEach((question) => {
      const type = question.QuestionType || "Others";
      if (!grouped[type]) grouped[type] = [];
      grouped[type].push(question);
    });
    return grouped;
  };

  const groupedQuestions = groupByQuestionType();


  const handleDownloadPDF = async () => {
    const content = contentRef.current;
  
    // Temporarily set a white background for the content
    content.style.backgroundColor = "#ffffff";
  
    // Render the entire content as a canvas using html2canvas
    const originalCanvas = await html2canvas(content, {
      scale: 2.1,
      backgroundColor: "#ffffff",
      useCORS: true,
      windowWidth: 1200,
    });
  
    // Revert background color if it was changed temporarily
    content.style.backgroundColor = "";
  
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
  
    const marginTop = 10; // Top margin for header
    const marginBottom = 20; // Bottom margin for footer
    const imgWidth = pdfWidth - 20; // Width of the image (with margins)
  
    let offsetY = 0;
    const pageHeightLeft = pdfHeight - marginTop - marginBottom;
  
    let lastHeading = ""; // To track the last heading
  
    // Loop through the content and split across pages
    while (offsetY < originalCanvas.height) {
      // Create a new canvas for the page
      const pageCanvas = document.createElement("canvas");
      pageCanvas.width = originalCanvas.width;
      pageCanvas.height = pageHeightLeft * (originalCanvas.width / imgWidth);
  
      const pageCtx = pageCanvas.getContext("2d");
  
      // Fill page canvas with a white background before drawing content
      pageCtx.fillStyle = "#ffffff";
      pageCtx.fillRect(0, 0, pageCanvas.width, pageCanvas.height);
  
      // Draw the current section of the original canvas onto the page canvas
      pageCtx.drawImage(
        originalCanvas,
        0,
        offsetY,
        originalCanvas.width,
        pageCanvas.height,
        0,
        0,
        pageCanvas.width,
        pageCanvas.height
      );
  
      const pageData = pageCanvas.toDataURL("image/jpeg", 0.85);
      pdf.addImage(pageData, "JPEG", 10, marginTop, imgWidth, pageHeightLeft);
  
      // Extract the heading for the current page (if applicable)
      // Adjust this logic based on your content's structure.
      const headingDetected = extractHeadingFromCanvas(pageCtx);
  
      if (headingDetected) {
        lastHeading = headingDetected;
      } else if (isPageMostlyEmpty(pageCtx)) {
        // Re-draw the last heading if the current page is mostly empty
        pdf.setFontSize(14);
        pdf.setTextColor(0, 0, 0);
        pdf.text(lastHeading, 10, marginTop + 5); // Draw the heading at the top of the page
      }
  
      // Add footer
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      const footerTextWithNumber = `Page ${pdf.internal.getNumberOfPages()}`;
      const footerWidth = pdf.getTextWidth(footerTextWithNumber);
      pdf.text(
        footerTextWithNumber,
        (pdf.internal.pageSize.width - footerWidth) / 2,
        pdfHeight - marginBottom + 5
      );
  
      offsetY += pageCanvas.height;
  
      // Add a new page if there's more content
      if (offsetY < originalCanvas.height) {
        pdf.addPage();
      }
    }
  
    // Save the PDF with the desired name
    pdf.save(`Question_Paper.pdf`);
  };

  // Helper function to extract the heading from the canvas
const extractHeadingFromCanvas = (canvasContext) => {
  // Add logic to detect headings on the canvas
  // For example, check specific regions or text styles for headings
  return "Detected Heading"; // Replace with actual detection logic
};

// Helper function to check if a page is mostly empty
const isPageMostlyEmpty = (canvasContext) => {
  // Add logic to determine if the page has minimal content
  return false; // Replace with actual empty page detection
};


  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const items = Array.from(filteredQuestions);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    setFilteredQuestions(items); // Update the filtered questions after reordering
  };
  

  return (
    <div>
    <div className="a4-container" ref={contentRef}>
      <div className="header-container-for-question-paper">
        <div className="header-container-for-subjcet-and-name-of-test">
          <div>SSLC PreTest</div>
          <div>Subject: Kannada</div>
        </div>
        <div className="header-container-for-mark-time-std">
          <div>STD: 10th</div>
          <div>Time: 2 Hours</div>
          <div>Marks: 30</div>
        </div>
      </div>

      <div className="a4-content">
        {Object.keys(groupedQuestions).length > 0 ? (
          Object.entries(groupedQuestions).map(([type, questions]) => (
            <section key={type} className="question-section">
              <h3>{type}</h3>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={type}>
                  {(provided) => (
                    <ul
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {questions.map((question, index) => (
                        <Draggable key={index} draggableId={String(index)} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="question-item"
                            >
                              <strong>Q{index + 1}:</strong> {question.Questions}
                              {type === "MCQs" && (
                                <ul className="options-list">
                                  <li> A: {question.OptionA}</li>
                                  <li> B: {question.OptionB}</li>
                                  <li> C: {question.OptionC}</li>
                                  <li> D: {question.OptionD}</li>
                                </ul>
                              )}
                              {type === "True/False" && (
                                <ul className="options-list">
                                  <li>A: {question.OptionA || "True"}</li>
                                  <li>B: {question.OptionB || "False"}</li>
                                </ul>
                              )}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </section>
          ))
        ) : (
          <p>No questions available.</p>
        )}
      </div>
    </div>
    <div className="button-position-in-question-paper">
    <Button variant='contaned' color='green' onClick={handleDownloadPDF}>Download PDF</Button>
    </div>
  </div>
  );
};

export default PDFQuestionPaper;
