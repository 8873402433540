import React, { useState } from "react";
import Editor1 from "../../Editor/Editor1";
import { TextField, Autocomplete } from "@mui/material";
import CustomMultiSelect from "../../Templates/MultiSelect/CustomMultiSelect";
import {
  companyNameOptions,
  companyTypes,
  headQuaterLocationOptions,
  industrialTypesOptions,
  stateNameOptions,
} from "../Common";
import Tooltip from "@mui/material/Tooltip";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ImageCropperTemplate2 from "../../Templates/ImageCroper/ImageCropperTemplate2";

function AddCompanyProfilePage({
  companyProfileData,
  setCompanyProfileData,
  invalidFields,
  setInvalidFields,
  cpNormalContent,
  setcpNormalContent,
  cpHTMLContent,
  setcpHTMLContent,
}) {
  // const [logoURL, setLogoURL] = useState(null);
  const [croppedLogoURL, setCroppedLogoURL] = useState(null);

  const [imageEditMode, setImgEditMode] = useState(0);
  const [profilePictureModalStatus, setProfilePictureModalStatus] =
    useState(false);

  const containerWidth = 145;
  const containerHeight = 145;
  const containerSize = Math.min(containerWidth, containerHeight);
  const fontSize = containerSize * 0.65;

  function toggleProfilePictureModal() {
    // alert(croppedLogoURL)
    setProfilePictureModalStatus(!profilePictureModalStatus);
    setImgEditMode(imageEditMode + 1);
  }
  const handleOkCropper = () => {
    setProfilePictureModalStatus(false);

    setCompanyProfileData((prevData) => ({
      ...prevData,
      CompanyLogo: croppedLogoURL,
    }));
    // alert(croppedLogoURL)
  };

  const handleProfileDataChange = (field, newValue) => {
    if (field == "Pincode" && /^\d{0,6}$/.test(newValue)) {
      setCompanyProfileData((prevData) => ({
        ...prevData,
        [field]: newValue,
      }));
    }
    // if (field == "FoundedYear" && /^\d{0,6}$/.test(newValue)) {
    //   setCompanyProfileData((prevData) => ({
    //     ...prevData,
    //     [field]: newValue,
    //   }));
    // }
    const currentDate = new Date();
    if (field === "FoundedYear") {
      // Check if the newValue matches the "YYYY-MM-DD" format
      const datePattern = /^\d{4}-\d{2}-\d{2}$/;
      if (datePattern.test(newValue)) {
        const enteredDate = new Date(newValue);
  
        // Validate that the entered date is not in the future
        if (enteredDate <= currentDate) {
          setCompanyProfileData((prevData) => ({
            ...prevData,
            [field]: newValue,
          }));
        } else {
          // Handle invalid input here, e.g., show an error message
          console.log("Invalid date: The Founded Year cannot be in the future.");
        }
      } else {
        // Handle invalid format here, e.g., show an error message
        console.log("Invalid format: Please use 'YYYY-MM-DD'.");
      }
    }


    if (field != "Pincode" && field!='FoundedYear') {
      setCompanyProfileData((prevData) => ({
        ...prevData,
        [field]: newValue,
      }));
    }
  };

  // const [phoneNumber, setPhoneNumber] = React.useState("+91");

  const handlePhoneNumberchange = (newValue) => {
    setCompanyProfileData((prevData) => ({
      ...prevData,
      Phone: newValue,
    }));
    // const isValidPhone = newValue !== null && newValue.trim().length >= 4;
    // setInvalidFields({ ...invalidFields, Phone: !isValidPhone });
  };

  return (
    <div>
      {/* ____________________ Basic Details _______________________ */}
      <div className="CompanyProfilePageOuterContainer">
        <div className="companyProfileContainer">
          {/* ________________ Company Name & Industry Type ____________ */}
          <div className="companyProflieGroup">
            {/* Company Name */}
            <div className="item1">
              <Autocomplete
                // className= "input_bg_color"
                fullWidth
                options={companyNameOptions}
                getOptionLabel={(option) => option}
                value={companyProfileData.CompanyName || ""}
                onChange={(e, newValue) =>
                  handleProfileDataChange("CompanyName", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company Name"
                    id="cprofile"
                    fullWidth
                    value={companyProfileData.CompanyName || ""}
                    onChange={(e) =>
                      handleProfileDataChange("CompanyName", e.target.value)
                    }
                    InputProps={{ className: "input_Custom_Style" }}
                    error={invalidFields.CompanyName}
                    helperText={invalidFields.CompanyName ? "* mandatory" : ""}
                  />
                )}
              />
            </div>
            {/* Industry Type */}
            <div className="item2">
              <CustomMultiSelect
                chipDeleteClassName="jobPostLocationDeleteX"
                placeholder=" Type / Select Industry type..."
                inputPropStyleClass="input_bg_color"
                label="Select Industry Type"
                options={industrialTypesOptions}
                isMandatoryError={invalidFields.IndustryType}
                helperText="* mandatory"
                selectedOption={companyProfileData.IndustryType || ""}
                setSelectedOption={(value) =>
                  handleProfileDataChange("IndustryType", value)
                }
                errorValidattion={invalidFields.IndustryType}
              />
            </div>
          </div>

          {/* ____________________ Logo Container _______________________ */}
          <div className="companyProflieGroup cpLogo">
            {/* Company Logo */}
            <div className=" companyProfileImageContainer">
              <img
                src={companyProfileData.CompanyLogo}
                // alt={altText}
                className="companyProfileImage"
                style={{
                  fontSize: `${fontSize}px`,
                  lineHeight: `${containerHeight}px`,
                }}
              />
              <Tooltip
                title={croppedLogoURL ? "Edit Logo" : "Add / Change Logo"}
                arrow
              >
                <label onClick={toggleProfilePictureModal} className="AddLogo">
                  {/* {croppedLogoURL ? "Edit Logo" : "Add Logo"} */}
                  {"Update Logo"}
                </label>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* ____________________ Founder Year and website _______________________ */}

        <div className="companyProfileContainer">
          <div className="companyProflieGroup">
            <div className="item1">
              {/* Founded Year */}
              <TextField
                type="date"
                fullWidth
                label="Founded On"
                value={companyProfileData.FoundedYear || ""}
                onChange={
                  (e, value) =>
                    handleProfileDataChange("FoundedYear", e.target.value)
                  // alert(e.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    className: "input_custom_Date",
                    max: new Date().toISOString().split("T")[0],
                  },
                  className: "input_Custom_Style",
                }}
                error={ invalidFields.FoundedYear}
                helperText={
                  invalidFields.FoundedYear
                    ? "* mandatory"
                    : ""
                }
              />
            </div>
          </div>

          <div className="companyProflieGroup">
            <div className="item1">
              {/* website */}
              <TextField
                label="Website"
                fullWidth
                required
                value={companyProfileData.Website || ""}
                onChange={(e) =>
                  handleProfileDataChange("Website", e.target.value)
                }
                InputProps={{ className: "input_Custom_Style" }}
                error={invalidFields.Website}
                helperText={invalidFields.Website ? "* mandatory" : ""}
              />
            </div>
          </div>
        </div>
        {invalidFields.HeadQuaterLocation}
        {/* ____________________ HeadQuater Location _______________________ */}
        <div className="cpLogo">
          <CustomMultiSelect
            //   chipClassName="jobPostLocationtag"
            chipDeleteClassName="jobPostLocationDeleteX"
            placeholder=" Type / Select HeadQuaters.."
            inputPropStyleClass="input_Custom_Style"
            label="Select HeadQuaters"
            isMandatoryError={invalidFields.HeadQuaterLocation}
                helperText="* mandatory"
            options={headQuaterLocationOptions}
            selectedOption={companyProfileData.HeadQuaterLocation || ""}
            setSelectedOption={(value) =>
              handleProfileDataChange("HeadQuaterLocation", value)
            }
            errorValidattion={invalidFields.HeadQuaterLocation}
          />
        </div>

        {/* ____________________ Company Types _______________________ */}
        {/* #1d88fa !important */}
        <div className="cpLogo">
          <CustomMultiSelect
            chipDeleteClassName="jobPostLocationDeleteX"
            placeholder=" Type / Select Company type..."
            inputPropStyleClass="input_bg_color"
            label="Select Company Type"
            options={companyTypes}
               isMandatoryError={invalidFields.CompanyTypes}
                helperText="* mandatory"
            selectedOption={companyProfileData.CompanyTypes || ""}
            setSelectedOption={(value) =>
              handleProfileDataChange("CompanyTypes", value)
            }
            errorValidattion={invalidFields.CompanyTypes}
          />
        </div>

        {/* ____________________ Address, phone  _______________________ */}

        <div className="companyProfileContainer">
          <div className="companyProflieGroup">
            {/* address */}
            <div className="item1">
              <TextField
                className="input_bg_color jobProfileAddressBox"
                fullWidth
                value={companyProfileData.Address || ""}
                onChange={(e) =>
                  handleProfileDataChange("Address", e.target.value)
                }
                label="Address"
                multiline
                rows={7.8}
                InputProps={{ className: "input_Custom_Style" }}
                error={invalidFields.Address}
                helperText={invalidFields.Address ? "* mandatory" : ""}
              />
            </div>
            {/* phone */}
            <br/>
            <br/>
            <br/>
            
            <div className="item2">

              <PhoneInput
                country={"us"}
                value={companyProfileData.Phone}
                onChange={handlePhoneNumberchange}
                inputStyle={{
                  // color:'white'
                }}
                dropdownStyle={{
                  fontSize: "16px",
                }}
                style={{
                  border: (invalidFields.Phone) && '1px solid red', 
                }}
              />
              {invalidFields.Phone && (
                <div 
                // className="helper-text-phone-container"
                >
                  {'* mandatory'}
                </div>)}
              {/* {(companyProfileData.Phone).length} */}
            </div>
          </div>

          {/* ____________________ City, State & Pincode  _______________________ */}
          <div className="companyProflieGroup">
            {/* City */}
            <div className="item1">
              <TextField
                className="input_bg_color"
                fullWidth
                value={companyProfileData.City || ""}
                onChange={(e) =>
                  handleProfileDataChange("City", e.target.value)
                }
                label="City"
                InputProps={{ className: "input_Custom_Style" }}
                error={invalidFields.City}
                helperText={invalidFields.City ? "* mandatory" : ""}
              />
            </div>
            {/* State */}
            <div className="item1">
              <Autocomplete
                fullWidth
                options={stateNameOptions}
                // getOptionLabel={(option) => option}
                value={companyProfileData.State || ""}
                onChange={(e, newValue) =>
                  handleProfileDataChange("State", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    fullWidth
                    value={companyProfileData.Designation || ""}
                    onChange={(e) =>
                      handleProfileDataChange("State", e.target.value)
                    }
                    InputProps={{
                      ...params.InputProps,
                      className: "input_Custom_Style",
                    }}
                    error={invalidFields.State}
                    helperText={invalidFields.State ? "* mandatory" : ""}
                  />
                )}
              />
            </div>
            {/* Pincode */}
            <div className="item1">
              <TextField
                className="input_bg_color"
                fullWidth
                value={companyProfileData.Pincode || ""}
                onChange={(e) =>
                  handleProfileDataChange("Pincode", e.target.value)
                }
                label="Pincode "
                InputProps={{ className: "input_Custom_Style" }}
                error={invalidFields.Pincode}
                helperText={invalidFields.Pincode ? "* mandatory" : ""}
              />
            </div>
            {/* Email */}
            <div className="item1">
              <TextField
                className="input_bg_color"
                fullWidth
                value={companyProfileData.CompanyEmail || ""}
                onChange={(e) =>
                  handleProfileDataChange("CompanyEmail", e.target.value)
                }
                label="Company Email "
                InputProps={{ className: "input_Custom_Style" }}
                error={invalidFields.CompanyEmail}
                helperText={invalidFields.CompanyEmail ? "* mandatory" : ""}
              />
            </div>
          </div>
        </div>

        {/* _______________ OverView = Editor ______________ */}
        <h2 className="job_heading">Company Overview </h2>
        <Editor1
         
          isHeadingRequird={false}
          height="250px"
          content={cpNormalContent}
          setContent={setcpNormalContent}
          output_content={cpHTMLContent}
          setOutputContent={setcpHTMLContent}
          contentPlacholder={"Roles and Responsibilities . . ."}
          contentUniqueID={"comProf12"}
          // for tag as text
          is_Editor_HeaderRequired={true}
          // editor_HeaderName={"Company Overview "}
          is_tag_required={false}
          is_tag_Option_required={false}
          return_location="/dashboard"
          editID={"editID"}
          Button_Name={null} //default button
          // onSave={(('editID')?'saveEditContent':'saveContent')}
          // fieldsClassName= "input_Custom_Style"
        />
      </div>

      {/* _______________  Logo Cropper Modal Code start________ */}
          <ImageCropperTemplate2
            open={profilePictureModalStatus}
            setOpen={setProfilePictureModalStatus}
            croppedImageDataUrl={croppedLogoURL}
            setCroppedImageDataUrl={setCroppedLogoURL}
            handleSave={()=>{
              setCompanyProfileData({...companyProfileData, CompanyLogo:croppedLogoURL });
              setProfilePictureModalStatus(false);
            }}
          />
    </div>
  );
}

export default AddCompanyProfilePage;
