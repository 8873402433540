import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import ProjectDetailsAddProject from "./ProjectDetailsAddProject";
import ProjectDetailsTemplate from "./ProjectDetailsTemplate";


function ProjectDetailsMain() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openEditMode, setopenEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState();
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false);
  const [CloseActionDialog, setCloseActionDialog] = useState(false);


  //--------------------------------------------------------------------------------
  //Fetch the data from the database initially
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/to-company`);
        const data = response.data;
        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle edit Function (in table action icon)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata, id) => {
    console.log("rowdata", rowdata);
    setInitialFormData(rowdata);
    setopenEditMode((prevMode) => !prevMode);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  //--------------------------------------------------------------------------------
  //Handle More/manage Function (in table )
  //--------------------------------------------------------------------------------
  const handleAddMoreFunctionality = () => {
    setOpenTemplateModal(true);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render
        rows={renderRows} // Rows render
        handleEdit={handleEdit} //Edit Row function
        deleteApi="project-details-destroy" // Delete API
        tableNameForExport="project_details" // optional
        is_Manage_Button_Required={true} // Manage button view if addition items added(additional functionality)
        handleManageButtonClick={handleAddMoreFunctionality} // if optional data required(additional functionality function)
        Manage_button_name="Manage Projects" //additional button name
        swithcUpdateApi="project-details-update" //optional
        switchColumnName="is_approved" //optional
        dropdownUpdateApi="project-details-update" //optional
        SetDataBaseUpdate={SetDataBaseUpdate} //To maintain the table updated
        DefaultColumnNames={["project_name", "po_start_date", "po_end_date"]} //DefaultColumnsnameshows
        CloseActionDialog={CloseActionDialog} //close the Action menu dialog
      />
      {/* _______________________End_____________________________ */}

      {/* ___________________CourseNameTemplate__________________ */}
      <ProjectDetailsTemplate
        openTemplateModal={openTemplateModal}
        setOpenTemplateModal={setOpenTemplateModal}
        SetDataBaseUpdate={SetDataBaseUpdate}
        columns={renderColumns}
      />
      {/* _____________________end_____________________________ */}
      {/* ___________________Edit fuction calling the Modal__________________ */}
      <ProjectDetailsAddProject
        initialFormData={initialFormData}
        openModal={openEditMode}
        setOpenModal={setopenEditMode}
        SetDataBaseUpdate={SetDataBaseUpdate}
        CloseActionDialogMenu={handleActionClose}
      />
      {/* _____________________end_____________________________ */}
    </div>
  );
}

export default ProjectDetailsMain;
