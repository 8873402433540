import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import FromCompanyTemplate from "./FromCompanyTemplate";
import FromCompanyAddProject from "./FromCompanyAddProject";

function FromCompanyMain() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openEditMode, setopenEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false);
  const [CloseActionDialog, setCloseActionDialog] = useState(false);

  // Fetch the data from the database initially
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/from-companies-index`);
        const data = response.data;
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
        const rows = data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  // Handle edit function
  const handleEdit = (rowdata) => {
    setInitialFormData(rowdata); // Set row data to initialFormData for editing
    setopenEditMode(true); // Open modal for editing
  };

  // Handle close action
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  // Handle add more functionality
  const handleAddMoreFunctionality = () => {
    setOpenTemplateModal(true);
  };

  return (
    <div>
      <TableHeaderTemplate
        columns={renderColumns}
        rows={renderRows}
        handleEdit={handleEdit}
        deleteApi="from-company-destroy"
        tableNameForExport="from_company"
        is_Manage_Button_Required={true}
        handleManageButtonClick={handleAddMoreFunctionality}
        Manage_button_name="Manage Our Company"
        swithcUpdateApi="from-company-update"
        switchColumnName="is_approved"
        dropdownUpdateApi="from-company-update"
        SetDataBaseUpdate={SetDataBaseUpdate}
        DefaultColumnNames={["name", "address", "phone_number"]}
        CloseActionDialog={CloseActionDialog}
      />

      <FromCompanyTemplate
        openTemplateModal={openTemplateModal}
        setOpenTemplateModal={setOpenTemplateModal}
        SetDataBaseUpdate={SetDataBaseUpdate}
        columns={renderColumns}
      />

      <FromCompanyAddProject
        initialFormData={initialFormData}
        openModal={openEditMode}
        setOpenModal={setopenEditMode}
        SetDataBaseUpdate={SetDataBaseUpdate}
        CloseActionDialogMenu={handleActionClose}
      />
    </div>
  );
}

export default FromCompanyMain;
