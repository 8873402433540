import React, { useState, useEffect } from "react";
import {
  Modal,
  IconButton,
  Button,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip, // Import the Chip component
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from '@mui/icons-material/Update';
import { baseURL } from "../../http";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from '@mui/icons-material/Save';

function EmployeeOtherBenefits({
  open,
  handleClose,
  editData,
  SetDataBaseUpdate,
}) {
  // State for form fields
  const [workingDays, setWorkingDays] = useState("");
  const [absentDays, setAbsentDays] = useState("");
  const [presentDays, setPresentDays] = useState("");

  // State for additional benefits
  const [benefits, setBenefits] = useState({});

  // State for the secondary modal to add new benefits
  const [openAddBenefitModal, setOpenAddBenefitModal] = useState(false);
  const [newBenefitType, setNewBenefitType] = useState("");
  const [newBenefitAmount, setNewBenefitAmount] = useState("");

  // Prefill data when modal opens with `editData`
  useEffect(() => {
    if (editData) {
      setWorkingDays(editData.NoOfWorkingDays || "");
      setPresentDays(editData.NoOfPresentDays || "");
      setAbsentDays(editData.NoOfAbsentDays || "");
      setBenefits(
        editData.OtherBenefits ? JSON.parse(editData.OtherBenefits) : {}
      );
    }
  }, [editData]);

  // Handle opening/closing the secondary modal
  const handleOpenAddBenefitModal = () => {
    setOpenAddBenefitModal(true);
  };

  const handleCloseAddBenefitModal = () => {
    setOpenAddBenefitModal(false);
  };

  // Handle adding new benefit from secondary modal
  const handleSaveBenefit = () => {
    if (newBenefitType && newBenefitAmount) {
      setBenefits({ ...benefits, [newBenefitType]: newBenefitAmount });
      handleCloseAddBenefitModal(); // Close the secondary modal
      setNewBenefitType(""); // Clear after adding
      setNewBenefitAmount(""); // Clear after adding
    }
  };

  // Handle removing a benefit
  const handleRemoveBenefit = (benefitType) => {
    const updatedBenefits = { ...benefits };
    delete updatedBenefits[benefitType]; // Remove the benefit
    setBenefits(updatedBenefits); // Update the state
  };

  // Validate and set working, absent, and present days with logic
  const handleWorkingDaysChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setWorkingDays(value);
      const updatedAbsentDays = value - presentDays;
      setAbsentDays(updatedAbsentDays >= 0 ? updatedAbsentDays : 0);
    }
  };

  const handlePresentDaysChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0 && value <= workingDays) {
      setPresentDays(value);
      const updatedAbsentDays = workingDays - value;
      setAbsentDays(updatedAbsentDays);
    }
  };

  const handleAbsentDaysChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0 && value <= workingDays) {
      setAbsentDays(value);
      const updatedPresentDays = workingDays - value;
      setPresentDays(updatedPresentDays);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      // Prepare the data for the backend
      const data = {
        NoOfAbsentDays: absentDays,
        NoOfPresentDays: presentDays,
        OtherBenefits: JSON.stringify(benefits), // Convert benefits to JSON string
      };

      // Send the PUT request to the backend
      const response = await axios.put(
        baseURL + `/Employee_salary_slip_update/${editData.id}`,
        data
      );

      if (response.status === 200) {
        customAlert(
          "",
          "Employee salary details updated successfully",
          "success"
        );
        SetDataBaseUpdate(true);
        handleClose(); // Close the modal on success
      }
    } catch (error) {
      console.error("Error updating employee details:", error);
      customAlert("", "Failed to update employee details", "error");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="other-benefits-modal">
        <IconButton
          id="button-zoom"
          className="addeducationalMapmodalcancel"
          onClick={handleClose}
        >
          <CloseIcon className="addeducationalMapmodalcancelX" />
        </IconButton>
        <div className="other-benefits-Heading">Employee Details</div>
        <div className="other-benefits-content-container">
          <div className="other-benefits-content-container-grid">
            {/* Input fields for working, absent, and present days */}
            <TextField
            disabled
              label="No Of Working Days"
              variant="outlined"
              type="number"
              value={workingDays}
              onChange={handleWorkingDaysChange}
            />
            <TextField
              label="No Of Present Days"
              variant="outlined"
              type="number"
              value={presentDays}
              onChange={handlePresentDaysChange}
            />
            <TextField
              label="No Of Absent Days"
              variant="outlined"
              type="number"
              value={absentDays}
              onChange={handleAbsentDaysChange}
            />
          </div>
       
          {/* Display added benefits as chips */}
          <div className="Add-benefits-section">
            <div className="add-benefits-and-addicon">
              <strong>Add Benefits:</strong>
              <IconButton onClick={handleOpenAddBenefitModal}>
                <AddCircleIcon color="success" />
              </IconButton>
            </div>
            <div>
              {Object.entries(benefits).map(([key, value], index) => (
                <Chip
                  key={index}
                  label={`${key}: ${value}`}
                  onDelete={() => handleRemoveBenefit(key)}
                  style={{ margin: "5px" }} // Add margin between chips
                  color="primary"
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </div>
          </div>
        </div>
<div className="other-benefits-container-button">
        <Button onClick={handleSubmit} variant="contained" color="success" id='button-zoom'>
          <UpdateIcon className="buttonicons" />&nbsp;Update
        </Button>
        </div>
        {/* Secondary modal for adding benefit */}
        <Modal open={openAddBenefitModal} onClose={handleCloseAddBenefitModal}>
          <div className="add-benefits-modal-small">
          <IconButton
          id="button-zoom"
          className="addeducationalMapmodalcancel"
          onClick={handleCloseAddBenefitModal}
        >
          <CloseIcon className="addeducationalMapmodalcancelX" />
        </IconButton>
          <div className="other-benefits-Heading">Add New Benefit</div>
          <div className="textfileds-alignment-small-modal" >
            <TextField
              label="Benefit Type (e.g., OT, Bonus)"
              variant="outlined"
              fullWidth
              value={newBenefitType}
              onChange={(e) => setNewBenefitType(e.target.value)}
              
            />
            <TextField
              label="Benefit Amount"
              variant="outlined"
              type="number"
              fullWidth
              value={newBenefitAmount}
              onChange={(e) => setNewBenefitAmount(e.target.value)}
             
            />
          </div>
          <div  className="other-benefits-container-button1">
           
            <Button onClick={handleSaveBenefit} color="success" variant="contained" id='button-zoom'>
              <SaveIcon className="buttonicons"/>Save Benefits
            </Button>
          </div>
          </div>
        </Modal>
      </div>
    </Modal>
  );
}

export default EmployeeOtherBenefits;
