import React,{useState} from 'react';
import { Menu,
  MenuItem, CardContent, Container, Typography, RadioGroup, FormControlLabel, Radio, useMediaQuery, Tooltip, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DangerousIcon from '@mui/icons-material/Dangerous';
import TranslateIcon from '@mui/icons-material/Translate';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FeedbackIcon from '@mui/icons-material/Feedback';

const QuizQuestion = ({reportModalOpen,reportDetailsArray,setReportDetailsArray,
  setReportModalOpen, questionIndex, selectedOptions,markedForReview, setSelectedOptions, questionIds, isRightNavOpen, toggleRightNav }) => {
  const currentQuestion = questionIds[questionIndex];
  const selectedOption = selectedOptions[questionIndex];

  // For changing the selected option
  const handleOptionChange = (event) => {
    const selectedIndex = questionIndex;
    const selectedOption = event.target.value;
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[selectedIndex] = selectedOption;
    setSelectedOptions(newSelectedOptions);
  };

  const handleReportOptionClick = (option) => {
    const updatedReportDetailsArray = [...reportDetailsArray];
    updatedReportDetailsArray[questionIndex].option = option;
    setReportDetailsArray(updatedReportDetailsArray);
    setReportModalOpen(true);
  };

  const [reportMenuAnchor, setReportMenuAnchor] = useState(false);

  const handleReportMenuOpen = (event) => {
    setReportMenuAnchor(event.currentTarget);
  };

  const handleReportMenuClose = () => {
    setReportMenuAnchor(false);
  };
  const isSmallScreen = useMediaQuery('(max-width:800px)');

  return (
    <div className="quiz-question-container">
  <CardContent>
    <Container className="question-container">
      <div>
     
        <Menu
          anchorEl={reportMenuAnchor}
          open={Boolean(reportMenuAnchor)}
          onClose={handleReportMenuClose}
        >
          <MenuItem
            onClick={() => handleReportOptionClick("Wrong questions")}
          >
            <DangerousIcon color="error" className="buttonicons" />&nbsp;Wrong Question
          </MenuItem>
          <MenuItem
            onClick={() => handleReportOptionClick("Formatting issue")}
          >
            <PriorityHighIcon color="secondary" className="buttonicons" />&nbsp; Formatting issue
          </MenuItem>
          <MenuItem
            onClick={() => handleReportOptionClick("Wrong translation")}
          >
            <TranslateIcon className="buttonicons" />&nbsp;Wrong Translation
          </MenuItem>
          <MenuItem onClick={() => handleReportOptionClick("Other")}>
            <FeedbackIcon color="primary" className="buttonicons" />&nbsp;Other
          </MenuItem>
        </Menu>
      </div>
      <div className='more-icons-placement-at-the-container-end'>
          <Tooltip title="Report issues" arrow>
            <IconButton className='more-icon-background-color-and-alignment' onClick={handleReportMenuOpen}>
              <MoreVertIcon className="more-icon-color-quiznav" />{" "}
            </IconButton>
          </Tooltip>
        </div>
      <Typography  variant={isSmallScreen ? 'h6' : "h5"} gutterBottom>
        Q{questionIndex + 1}. {currentQuestion.Questions}
      </Typography>
      <br />
      <div className="options-container">
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          <FormControlLabel
            key="A"
            control={<Radio color="success" />}
            label={currentQuestion.OptionA}
            value={currentQuestion.OptionA}
            className={selectedOption === currentQuestion.OptionA ? "green-label" : ""}
          />
          <FormControlLabel
            key="B"
            control={<Radio color="success" />}
            label={currentQuestion.OptionB}
            value={currentQuestion.OptionB}
            className={selectedOption === currentQuestion.OptionB ? "green-label" : ""}
          />
          <FormControlLabel
            key="C"
            control={<Radio color="success" />}
            label={currentQuestion.OptionC}
            value={currentQuestion.OptionC}
            className={selectedOption === currentQuestion.OptionC ? "green-label" : ""}
          />
          <FormControlLabel
            key="D"
            control={<Radio color="success" />}
            label={currentQuestion.OptionD}
            value={currentQuestion.OptionD}
            className={selectedOption === currentQuestion.OptionD ? "green-label" : ""}
          />
        </RadioGroup>
      </div>
    </Container>
  </CardContent>

  {/* Right nav toggle */}
  <div className="nav-icon-container">
    {!isSmallScreen && (
      <Tooltip title={isRightNavOpen ? "Hide QuestionInfo nav" : "Open QuestionInfo nav"} arrow>
        <IconButton onClick={toggleRightNav} style={{ color: 'blue' }}>
          {isRightNavOpen ? <ChevronRightIcon fontSize="large" /> : <ChevronLeftIcon fontSize="large" />}
        </IconButton>
      </Tooltip>
    )}
  </div>
</div>

  );
}

export default QuizQuestion;
