import React, { useState, useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu'; // Menu Icon
import IconButton from '@mui/material/IconButton'; // MUI IconButton
import Drawer from '@mui/material/Drawer'; // MUI Drawer

function ContentTopNav({ subjects, setSelectedSubject, onMenuClick }) {
  const [selectedSubject, setSelectedSubjectState] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950); // Track if screen is mobile size

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    setSelectedSubjectState(subject);
  };

  // Update isMobile state when screen resizes
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 950);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="top-nav">
      {/* Menu icon visible only for mobile view */}
      {isMobile && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className="menu-icon"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>
      )}

      <ul>
        {subjects.map((subject, index) => (
          <li
            key={index}
            onClick={() => handleSubjectClick(subject)}
            className={selectedSubject === subject ? "selected" : ""}
          >
            {subject}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ContentTopNav;
