import React, { useState, useEffect ,useRef } from "react";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import Rating from "@mui/material/Rating";
import GoogleIcon from "@mui/icons-material/Google";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TextField,
  Autocomplete,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  Button,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { baseURL } from "../../../http";
import "./coursetemplate2.css";
import Checkbox from "@mui/material/Checkbox";

function Template2Header({
  setTags,
  isChecked,
  setIsChecked,
  selectedCourse,
  setSelectedCourse,
  image,
  setImage,
  courseDetails,
  setCourseDetails,
  calculateCompletionPercentage,
  handleSave,
  handleUpdae,
  editData,
  setCoursePage
}) {
  const [courseNames, setCourseNames] = useState([]);
  const [isEditingCourseName, setIsEditingCourseName] = useState(false);
  const [editingField, setEditingField] = useState("");
  const fileInputRef = useRef(null);

    //--------------------------------------------------------------------------------
  //Back button function
  //--------------------------------------------------------------------------------
const handleBackClick=()=>{
  setCoursePage(false)
}
  //--------------------------------------------------------------------------------
  //edit icon clikc to add the data
  //--------------------------------------------------------------------------------
  const handleEditClickFields = (field) => {
    setEditingField(field);
  };

  //--------------------------------------------------------------------------------
  //Handle the changed data
  //--------------------------------------------------------------------------------
  const handleFieldChange = (event) => {
    setCourseDetails({ ...courseDetails, [editingField]: event.target.value });
  };

  //--------------------------------------------------------------------------------
  //Drop-down for the tags and coursename api call
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const fetchCourseNames = async () => {
      try {
        const response = await axios.get(`${baseURL}/getcourse`);

        const uniqueTags = new Set();
        response.data.forEach((course) => {
          uniqueTags.add(course.Category);
          uniqueTags.add(course.SubCategory);
          uniqueTags.add(course.CourseName);
        });

        setTags(Array.from(uniqueTags));

        const courseNameList = response.data.map((course) => course.CourseName);
        setCourseNames(courseNameList);
      } catch (error) {
        console.error("Error fetching course names:", error);
      }
    };

    fetchCourseNames();
  }, []);

  //--------------------------------------------------------------------------------
  //Handle the image change and save as data URL
  //--------------------------------------------------------------------------------

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  //--------------------------------------------------------------------------------
  //Handle the Coursename change
  //--------------------------------------------------------------------------------

  const handleCourseNameChange = (event, newValue) => {
    setSelectedCourse(newValue);
    setIsEditingCourseName(false);
  };

  //--------------------------------------------------------------------------------
  //the Coursename edit icon click
  //--------------------------------------------------------------------------------
  const handleEditClick = () => {
    setIsEditingCourseName(true);
  };

  //--------------------------------------------------------------------------------
  //click outside after data added t the respective fields
  //--------------------------------------------------------------------------------
  const handleSaveClick = () => {
    setEditingField("");
  };

  //--------------------------------------------------------------------------------
  //Handle start rating change
  //--------------------------------------------------------------------------------

  const handleRatingChange = (event) => {
    const newValue = event.target.value;
    if (newValue <= 5 && newValue >= 0) {
      setCourseDetails({ ...courseDetails, rating: newValue });
    }
  };

  //--------------------------------------------------------------------------------
  // Function to toggle the checkbox state
  //--------------------------------------------------------------------------------

  const handleToggle = () => {
    setIsChecked((prev) => !prev);
  };

    //--------------------------------------------------------------------------------
  // Handle delete the Images
  //--------------------------------------------------------------------------------
  const handleDeleteImage = () => {
    setImage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      {/* _________________________course page  header_______________________ */}
      <div className="coursetemplate2-header">
        <div className="coursetemplate2-top-save-and-icons-area">
          <div className="coursetemplate2-check-box">
            <Tooltip title='Show All Icons' arrow>
            <Checkbox className="checkbox-size-control" checked={isChecked} onChange={handleToggle} />
            </Tooltip>
            <div className="coursetemplate2-check-box-text-align">
              <span className="hide-text-of-back-button"> &nbsp;Show Edit Icons</span>
            </div>
          </div>
          <div>
            <Tooltip title={"percentage of Course Complete"} arrow>
              <Avatar className="create-course-avatar">{`${calculateCompletionPercentage()}%`}</Avatar>
            </Tooltip>
          </div>
          <div>
            &nbsp;
            <Tooltip title='Back' arrow>
            <Button onClick={handleBackClick} variant="contained" color="success" id="button-zoom">
              <ArrowBackIcon className="buttonicons" />
              &nbsp;<span className="hide-text-of-back-button">Back</span>
            </Button></Tooltip>
            &nbsp;&nbsp;
            {!editData ? (
              <Tooltip title='save' arrow>
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleSave}
              >
                <SaveIcon className="buttonicons" />
                &nbsp;<span className="hide-text-of-back-button">Save</span>
              </Button>
              </Tooltip>
            ) : (
              <Tooltip title='Update' arrow>
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleUpdae}
              >
                <UpdateIcon className="buttonicons" />
                &nbsp;<span className="hide-text-of-back-button">Update</span>
              </Button>
              </Tooltip>
            )}
          </div>
        </div>
        <div>
          {/* _________________________Coursename selection_______________________ */}
          <div className="coursetemplate2-course-name">
            {isEditingCourseName ? (
              <Autocomplete
                disableClearable
                options={courseNames}
                value={selectedCourse}
                onChange={handleCourseNameChange}
                onBlur={() => setIsEditingCourseName(false)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Course Name"
                    variant="outlined"
                    fullWidth
                    autoFocus
                  />
                )}
              />
            ) : (
              <>
                <span>{selectedCourse}</span>
                {isChecked && (
                  <Tooltip title="Edit" arrow>
                    <IconButton className="icon-button-backgrond-fix" onClick={handleEditClick}>
                      <EditIcon className="courseTemplate2-edit-icon-color" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </div>
          {/* _________________________CoursImage change _______________________ */}
          <div className="coursetemplate2-course-image-geninfo-container">
            <div className="coursetemplate2-course-image-conatiner">
              <div className="course-image-name-add-delete-functionality">
                <h6 className="coursetemplete2-title">
                  Course Image-{selectedCourse}
                </h6>
                <div>
                  <label htmlFor="image-upload">
                    {isChecked && (
                      <Tooltip title="Edit" arrow>
                        <IconButton component="span">
                          <EditIcon className="courseTemplate2-edit-icon-color" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </label>
                  {isChecked && (
                    <Tooltip title="Delete" arrow>
                      <IconButton onClick={handleDeleteImage}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="image-edit-overlay">
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-upload"
                  type="file"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                />
              </div>
              <div className="coursetemplate2-image-container-final-view">
                <img src={image} alt="Course Image" />
              </div>
            </div>
            {/* _________________________duration change _______________________ */}
            <div className="coursetemplate2-course-General-info">
              <div className="coursetemplate2-geninfo-div">
                <div className="coursetemplate2-icons-color-alignment">
                  <AccessTimeIcon />
                </div>
                &nbsp;
                {editingField === "duration" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      value={courseDetails.duration.split(" ")[0]}
                      onChange={(e) =>
                        setCourseDetails({
                          ...courseDetails,
                          duration: `${e.target.value} ${
                            courseDetails.duration.split(" ")[1]
                          }`,
                        })
                      }
                      onBlur={handleSaveClick}
                      type="number"
                    />
                    &nbsp;
                    <Select
                      value={
                        courseDetails.duration.includes("Hours")
                          ? "Hours"
                          : courseDetails.duration.includes("Weeks")
                          ? "Weeks"
                          : "Months"
                      }
                      onChange={(e) =>
                        setCourseDetails({
                          ...courseDetails,
                          duration: `${courseDetails.duration.split(" ")[0]} ${
                            e.target.value
                          }`,
                        })
                      }
                    >
                      <MenuItem value="Hours">Hours</MenuItem>
                      <MenuItem value="Weeks">Weeks</MenuItem>
                      <MenuItem value="Months">Months</MenuItem>
                    </Select>
                  </div>
                ) : (
                  <>
                    <div>Duration: {courseDetails.duration}</div>
                    {isChecked && (
                      <Tooltip title="Edit" arrow>
                        <IconButton>
                          <EditIcon
                            className="courseTemplate2-edit-icon-color"
                            onClick={() => setEditingField("duration")}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
              {/* _________________________Teaching mode change _______________________ */}
              <div className="coursetemplate2-geninfo-div">
                <div className="coursetemplate2-icons-color-alignment">
                  <ModelTrainingIcon />
                </div>
                &nbsp;
                {editingField === "teachingMode" ? (
                  <Select
                    value={courseDetails.teachingMode}
                    onChange={handleFieldChange}
                    onBlur={handleSaveClick}
                    fullWidth
                  >
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="Offline">Offline</MenuItem>
                  </Select>
                ) : (
                  <>
                    <div>Teaching Mode: {courseDetails.teachingMode}</div>
                    {isChecked && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          onClick={() => handleEditClickFields("teachingMode")}
                        >
                          <EditIcon className="courseTemplate2-edit-icon-color" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
              {/* _________________________Price change_______________________ */}
              <div className="coursetemplate2-geninfo-div">
                <div className="coursetemplate2-icons-color-alignment">
                  <CurrencyRupeeIcon />
                </div>
                &nbsp;
                {editingField === "price" ? (
                  <TextField
                    type="number"
                    value={courseDetails.price}
                    onChange={handleFieldChange}
                    onBlur={handleSaveClick}
                    fullWidth
                  />
                ) : (
                  <>
                    <div>Price: {courseDetails.price}</div>
                    {isChecked && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          onClick={() => handleEditClickFields("price")}
                        >
                          <EditIcon className="courseTemplate2-edit-icon-color" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
              {/* _________________________Student change_______________________ */}
              <div className="coursetemplate2-geninfo-div">
                <div className="coursetemplate2-icons-color-alignment">
                  <PersonIcon />
                </div>
                &nbsp;
                {editingField === "studentsEnrolled" ? (
                  <TextField
                    type="number"
                    value={courseDetails.studentsEnrolled}
                    onChange={handleFieldChange}
                    onBlur={handleSaveClick}
                    fullWidth
                  />
                ) : (
                  <>
                    <div>
                      Students Enrolled: {courseDetails.studentsEnrolled}
                    </div>
                    {isChecked && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          onClick={() =>
                            handleEditClickFields("studentsEnrolled")
                          }
                        >
                          <EditIcon className="courseTemplate2-edit-icon-color" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
              {/* _________________________Rating change_______________________ */}
              <div className="coursetemplate2-geninfo-div">
                <GoogleIcon className="coursetemplate2-icons-color-alignment" />
                &nbsp;
                {editingField === "rating" ? (
                  <>
                    <TextField
                      type="number"
                      value={courseDetails.rating}
                      onChange={handleRatingChange}
                      onBlur={handleSaveClick}
                      inputProps={{ min: 0, max: 5, step: 0.1 }}
                      fullWidth
                    />
                  </>
                ) : (
                  <>
                    <Rating
                      precision={0.5}
                      value={parseFloat(courseDetails.rating)}
                      readOnly
                    />
                    {isChecked && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          onClick={() => handleEditClickFields("rating")}
                        >
                          <EditIcon className="courseTemplate2-edit-icon-color" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
                {/* _________________________end_______________________ */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Template2Header;
