import React, { useState } from 'react';
import './Advertisement.css';
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";
import axios from 'axios';
import Editor1 from '../../Editor/Editor1';
import { baseURL } from '../../../http';

const AdvertisementModal = () => {
  const [image, setImage] = useState(null);
  const [content, setContent] = useState('');
  const [heading, setHeading] = useState('');
  const [localHeading, setHeadingValue] = useState("");

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]); // Store the file object
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('heading', localHeading);
    formData.append('content', content);
    if (image) {
      formData.append('image_url', image); // Append the image file to the form data
    }

    try {
      const response = await axios.post(`${baseURL}/advertisements`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Advertisement saved:', response.data);
      // Reset the form or provide user feedback here
      setHeading('');
      setContent('');
      setImage(null);
    } catch (error) {
      console.error('Error saving advertisement:', error);
    }
  };

  return (
    <div className="create-exam-container">
      <div className="exam-header-buttons">
        <Button
          variant="contained"
          color="success"
          id="button-zoom"
          // onClick={handleManageExamClose}
        >
          <ArrowBackIosIcon />
          <span className="edt_saveBtn">Back</span>
        </Button>
        <h4>Add Advertisement</h4>
        <Button
          variant="contained"
          color="success"
          id="button-zoom"
          onClick={handleSave} // Call handleSave on Save button click
        >
          <SaveIcon />
          <span className="edt_saveBtn">Save</span>
        </Button>
      </div>
      <hr />
      <div className='modal-content'>
        <Editor1
          isHeadingRequird={true}
          header_label="Heading"
          desktopHeight="200px"
          content={content}
          setContent={setContent}
          setHeading={setHeadingValue} // Pass the correct setter function
          is_Editor_HeaderRequired={true}
          is_tag_required={false}
          is_tag_Option_required={false}
          return_location="/dashboard"
          editID={"editID"}
          Button_Name={null}
          headingValue={localHeading} // Use heading state directly
          setHeadingValue={setHeadingValue}
        />
      </div>
      <div className="image-section">
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div>
    </div>
  );
};

export default AdvertisementModal;
