import React from "react";

function BatchCard({ filteredBatches }) {
  return (
    <div>
      {filteredBatches.length > 0 ? (
        filteredBatches.map((batch) => (
          <div key={batch.BatchName} className="batch-card-group-added">
            <div className="batc-name-text-alignment-and-color">
              {" "}
              {batch.BatchName}
            </div>
            <div className="batchcard-group-data-render">
              <div>Start Date: {batch.StartDate}</div>
              <div>End Date: {batch.EndDate}</div>
              <div>
                No. of Students:{" "}
                {(() => {
                  try {
                    // Parse StudentEnrolled and check if it's an array
                    const studentArray = JSON.parse(batch.StudentEnrolled);
                    return Array.isArray(studentArray)
                      ? studentArray.length
                      : 0;
                  } catch (e) {
                    console.error("Error parsing StudentEnrolled:", e);
                    return 0; // Return 0 if there's an error in parsing
                  }
                })()}
              </div>

              {/* Add more batch details here if needed */}
            </div>
          </div>
        ))
      ) : (
        <div variant="body1">No batches found for this group.</div>
      )}
    </div>
  );
}

export default BatchCard;
