import React, { useState } from "react";
import "./coursecreatetemplate.css";
import OverViewTemplate from "./OvreViewTemplate";

function OverView({selectedCourse,whatYouLearn,setWhatYouLearn,requirements,setRequirements,whothisCourse,setwhothisCourse}) {


  return (
    <>
      {/* ____________________What You'll Learn_______________________*/}
      <OverViewTemplate
        selectedCourse={selectedCourse}
        title="What you will master?"
        content={whatYouLearn}
        setContent={setWhatYouLearn}
      />
      {/* ________________________end_________________________________ */}
      {/* ____________________Requirements of the course_______________*/}
      <OverViewTemplate
      selectedCourse={selectedCourse}
        title="Needed skills for the course?"
        content={requirements}
        setContent={setRequirements}
      />
      {/* ________________________end_________________________________ */}
      {/* ____________________Who this course is for?_________________ */}
      <OverViewTemplate
      selectedCourse={selectedCourse}
        title="Who can benefit from this course?"
        content={whothisCourse}
        setContent={setwhothisCourse}
      />
      {/* ________________________end_________________________________ */}
    </>
  );
}

export default OverView;
