
import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseURL } from "../../http";
import axios from "axios";
import TableHeaderTemplate from '../GeneralTemplateComponent/TableHeaderTemplate';

function WebsiteTrackTPAMain() {
    const [renderColumns, setRenderColumns] = useState([]);
    const [renderRows, setRenderRows] = useState([]);
    const [dataBaseUpdate, SetDataBaseUpdate] = useState(false)
    console.log("renderColumns", renderColumns, "renderRows", renderRows);


    useEffect(() => {
        const fetchTableData = async () => {

            try {
                const response = await axios.get(`${baseURL}/websiteTrack_fetch_all`);
                const data = response.data;

                // Assuming data contains `fillable` and the row data
                const columnNames = Object.keys(data[0] || {});
                const columns = columnNames.map((columnName) => ({
                    field: columnName,
                    headerName: columnName,
                }));
                setRenderColumns(columns);

                // Format rows data
                const rows = data.map((row, index) => ({
                    id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
                    ...row,
                }));
                setRenderRows(rows);
                SetDataBaseUpdate(false)

            } catch (error) {
                console.error("Error fetching table data:", error);
            }
        };

        fetchTableData();
    }, [dataBaseUpdate]);
    const handleView = (row, rowid) => {
        console.log("data", row, rowid);
    };
    const handleView1 = (row, rowid) => {
        console.log("data", row, rowid);
    };


    //--------------------------------------------------------------------------------
    // customMenuItems
    //--------------------------------------------------------------------------------


    const customMenuItems = [
        {
            icon: VisibilityIcon,
            label: "View",
            onClick: handleView,
            IconColor: "green",
        },

    ];

    //--------------------------------------------------------------------------------
    // handleEdit
    //--------------------------------------------------------------------------------
    const handleEdit = (rowdata) => {
        console.log("handle Edit functions", rowdata);
    };

    //--------------------------------------------------------------------------------
    // handleAddMoreFunctionality
    //--------------------------------------------------------------------------------


    const handleAddMoreFunctionality = () => {
        console.log('handle manage button')
    }


    //--------------------------------------------------------------------------------
    // return
    //--------------------------------------------------------------------------------

    return (
        <div>
            {/* _______________________Main Component call____________ */}
            <TableHeaderTemplate
                columns={renderColumns} //Columns render
                rows={renderRows} //rows render
                customMenuItems={customMenuItems}//Addition menu items
                handleEdit={handleEdit}
                deleteApi="websiteTrack_delete" //Delete Api
                tableNameForExport='Website_track'//optionl
                is_Manage_Button_Required={false}//Mange button view if addition Items add
                handleManageButtonClick={handleAddMoreFunctionality}//if optional data required
                Manage_button_name='Manage Table'
                switchColumnName=''//multiple switch
                swithcUpdateApi=''
                dropdownColumnName='' //multiple switch
                dropdownUpdateApi=''
                SetDataBaseUpdate={SetDataBaseUpdate}
                DefaultColumnNames={['ip', 'urlPage', 'device_name' , 'UTC_Time' , 'PinCode' , 'Current_Location']}//Add to shows he columns in a Default render
            />
            {/* _______________________End_____________________________ */}
        </div>
    );
}

export default WebsiteTrackTPAMain
