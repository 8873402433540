import React from "react";
import "./hrfillableform.css";

function PersonalInfo({ EditData }) {
  return (
    <div className="personal-info-conatiner">
      <div className="personal-info-heading">Personal Information</div>

      <div className="personal-info-align-container-email">
        <span className="personalinfo-sub-header">Mail ID: </span> &nbsp;
        <span className="personalinfo-data">
          {EditData?.PersonalMailId || "N/A"}
        </span>
      </div>

      <div className="personal-info-fileds-align-container">
        <div className="personal-info-align-container">
          <span className="personalinfo-sub-header">Employee ID: </span> &nbsp;
          <span className="personalinfo-data">
            {EditData?.EmployeeID || "N/A"}
          </span>
        </div>
        <div className="personal-info-align-container">
          <span className="personalinfo-sub-header">Employee Name: </span>{" "}
          &nbsp;
          <span className="personalinfo-data">
            {EditData?.EmployeeName || "N/A"}
          </span>
        </div>
        <div className="personal-info-align-container">
          <span className="personalinfo-sub-header">Date of Birth: </span>{" "}
          &nbsp;
          <span className="personalinfo-data">
            {" "}
            {EditData?.DateOfBirth || "N/A"}
          </span>
        </div>
        <div className="personal-info-align-container">
          <span className="personalinfo-sub-header">Gender: </span> &nbsp;
          <span className="personalinfo-data">{EditData?.Gender || "N/A"}</span>
        </div>
        <div className="personal-info-align-container">
          <span className="personalinfo-sub-header">Blood Group: </span> &nbsp;
          <span className="personalinfo-data">
            {EditData?.BloodGroup || "N/A"}
          </span>
        </div>
        <div className="personal-info-align-container">
          <span className="personalinfo-sub-header">Phone Number: </span> &nbsp;
          <span className="personalinfo-data">
            {EditData?.PhoneNumber || "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
