import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  IconButton,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { baseURL } from "../../../http";
import BatchCard from "./BatchCard";

function ModalBatchAddedView({ open, close, editData }) {
  const [batchInfo, setBatchInfo] = useState([]);

  //--------------------------------------------------------------------------------
  // Fetch all batch information when the modal opens
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (open) {
      axios
        .get(`${baseURL}/getbatchinfo`)
        .then((response) => {
          if (response.status === 200) {
            setBatchInfo(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching batch info:", error);
        });
    }
  }, [open]);

  //--------------------------------------------------------------------------------
  // Filter batches based on the selected group name
  //--------------------------------------------------------------------------------

  const filteredBatches = batchInfo.filter(
    (batch) => batch.AddGroup === editData.GroupName
  );

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-batch-view-outer-container"
    >
      <div className="addstudentoutercontainer1">
        <div className="batchinfomodal12">
          <IconButton
            id="button-zoom"
            onClick={close}
            className="addeducationalMapmodalcancel"
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>

          <div className="EducationalTableViewmodalHeader">
            Group Added Batches - {editData.GroupName}
          </div>
          {/* ___________batch card Component call___________ */}
          <div className="batch-card-render-conatiner-section">
            <BatchCard filteredBatches={filteredBatches} />
          </div>
          <div className="avatar-display-and-alignment">
            <Avatar className="batch-card-background-color-total">
              {filteredBatches.length}
            </Avatar>
            &nbsp; Total Batches Added
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalBatchAddedView;
