import React, { useState } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadTemplate from "./DownloadTemplate";
import UploadFromTemplate from "./UploadFromTemplate";
import AddEducationalMap from "./AddEducationalMap";
import AddBoxIcon from "@mui/icons-material/AddBox";

function EducationalRoadmapTemplate({
  manageOpen,
  handleClose,
  SetDataBaseUpdate,
  renderColumns,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  //--------------------------------------------------------------------------------
  // Modal open and close state
  //--------------------------------------------------------------------------------

  const handleOpen1 = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
    handleClose();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={manageOpen}
        onClose={handleClose}
        aria-labelledby="question-bank-modal-title"
        aria-describedby="question-bank-modal-description"
        className="educationalroadmapmodelcenteralignment"
      >
        <div className="modelcontainereducationroadmap">
          <IconButton
            id="button-zoom"
            className="EducationalTableViewmodalcloseicon"
            onClick={handleClose}
          >
            <CloseIcon className="EducationalTableViewmodalcloseiconX" />
          </IconButton>
          {/* ________________Header____________ */}
          <h5 className="EducationalTableViewmodalHeader">
            Educational Roadmap Management
          </h5>
          <div className="educationalroadmapinsidecontentgridalignment">
            <div className="addEducationalhoverandcolor">
              {/* _______________Button section________________ */}
              <div onClick={handleOpen1}>
                <AddBoxIcon
                  className="addEducationaladdicon"
                  color="primary"
                  fontSize="large"
                />
                <br />
                <span>Add Manually Educational RoadMap</span>
              </div>
            </div>
            {/* _____________Download template________________________________ */}
            <DownloadTemplate columns={renderColumns} />
            {/* _____________________upload from template______ */}
            <UploadFromTemplate
              DBApi="/manageqb"
              close={handleModalClose}
              update={SetDataBaseUpdate}
            />
          </div>
        </div>
      </Modal>

      {/*_______________________Add the manually education roadmap________________ */}
      <AddEducationalMap
        update={SetDataBaseUpdate}
        handleClose1={handleClose1}
        open={open}
      />
    </div>
  );
}

export default EducationalRoadmapTemplate;
