import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ResizableBox } from 'react-resizable';
import Editor1 from "../Editor/Editor1";
import { customAlert } from "../SweetAlertCommon/Custom";
import { baseURL } from "../../http";
import { defaultImgURL } from './DefaultImg';
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useLocation } from 'react-router-dom';


import './CA_Editor.css';
import 'react-resizable/css/styles.css';
import ImageCropperTemplate from "../Templates/ImageCropperTemplate";

function CA_Editor() {
  //-------------------------------------------------------------------------
  //State Handles
  //-------------------------------------------------------------------------
  const [selectedImage, setSelectedImage] = useState(null);
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [Tag_value, setTag_value] = useState('');
  const [Tag_Options, setTag_Options] = useState([]);
  const defaultImg = defaultImgURL;

  const [imageSrc, setImageSrc] = useState(defaultImg);
  const [imageSize, setImageSize] = useState({ width: 200, height: 200 });
  const [isImgResizing, setIsImgResizing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [resizeHandles, setResizeHandles]=useState([]);
  
  const [editID, setEditID] = useState('');
  
  const contentEditorRef = useRef(null);
  const colorPickerRef = useRef(null);

  const navigateTo = useNavigate();
  const location = useLocation();

  //-------------------------------------------------------------------------
  // Clean Content Resize
  //-------------------------------------------------------------------------
  const cleanContentImageResize = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const imgContainers = tempDiv.querySelectorAll(".image-container");
    // image-container
    imgContainers.forEach((container) => {
      // container.style.resize = null;
      container.classList.remove("imgEditorResizer"); 
    });
    return tempDiv.innerHTML;
  };

  const cleanContentCodeResize = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const codeContainers = tempDiv.querySelectorAll(".code-viewer-container");
    codeContainers.forEach((container) => {
      container.style.resize = null;
    });
    return tempDiv.innerHTML;
  };
  //-------------------------------------------------------------------------
  // Delete Image by keyboard
  //-------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Delete" && selectedImage) {
        selectedImage.remove();
        setSelectedImage(null);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage]);

  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      // setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //-------------------------------------------------------------------------
  // Parse HTML content to Normal Text
  //-------------------------------------------------------------------------
  const parseHTML = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.innerText;
  };
  //-------------------------------------------------------------------------
  // Apply DefaultStyles for font
  //-------------------------------------------------------------------------

  const applyDefaultStyles = (htmlContent) => {
    const defaultFontFamily = 'Roboto, sans-serif';
    const defaultFontSize = '14px';
  
    const wrapper = document.createElement('div');
    wrapper.innerHTML = htmlContent;
  
    const allElements = wrapper.querySelectorAll('*');
    allElements.forEach(element => {
      element.style.fontFamily = defaultFontFamily;
      element.style.fontSize = defaultFontSize;
    });
  
    return wrapper.innerHTML;
  };

  
  //-------------------------------------------------------------------------
  // Save New Content
  //-------------------------------------------------------------------------
  const saveContent = async () => {
   
    try {
      const rawContent = content;
      const cleanedContent_ImgRz   = cleanContentImageResize(rawContent);
      const cleanedContent_CodeRz  = cleanContentCodeResize(cleanedContent_ImgRz);
      const cleanedContent         = applyDefaultStyles(cleanedContent_CodeRz);
     
      const sendData = {
        heading: heading,
        normalContent: parseHTML(cleanedContent),
        htmlContent: cleanedContent,
        // imageDataURL: imageSrc ? imageSrc : defaultImg,
        imageDataURL: croppedCaImageURLRender ? croppedCaImageURLRender : defaultImg,
        imageWidth: ((imageSize.width) / 340) * 100,
        imageHeight: ((imageSize.width) / 340) * 100,
        date: localStorage.getItem('selectedDate'),
        // tag: 'hi',
        // tag: Tag_Options,
        tag: JSON.stringify(Tag_Options),
        createdByWho: localStorage.getItem('HTES_user_id'),
      };

      if(!heading){ customAlert("", "Enter Heading", "warning")
        return;
      }
      if(content.length==53 || content.length==0 ){ customAlert("", "Enter Content", "warning") // dont change 53 = initial div length ,if del key then 0
        return;
      }

      const response = await axios.post(`${baseURL}/createCurrentAffairs`, sendData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        customAlert("", "Current Affair Added", "success").then(() => {
          navigateTo('/dashboard');
        });
      } else {
        console.error("Error adding Current Affair");
      }
    } catch (error) {
      console.error("Network Error while adding content:", error);
    }
  };

 

    useEffect(() => {
      if (location.state) {
        const {
          editID,
          imageDataURL,
          imageWidth,
          imageHeight,
          editHeading,
          editHtmlContent,
          editNormalContent,
          editTag,
        } = location.state;
        let tagArray = [];

        try {
          if (editTag.startsWith('[') && editTag.endsWith(']')) {
            // editTag seems to be a JSON array string
            tagArray = JSON.parse(editTag);
          } else {
            // editTag seems to be a comma-separated list
            tagArray = editTag.split(',').map(tag => {
              const trimmedTag = tag.trim(); // Remove any extra whitespace
              return { value: trimmedTag, label: trimmedTag };
            });
          }
        } catch (error) {
          console.error("Error parsing tagArray:", error);
        }


        setImageSrc(imageDataURL || '');
        setCroppedCaImageURLRender(imageDataURL || '');
        setHeading(editHeading || '');
        // setTag_Options(editTag || '');
        setTag_Options(tagArray || '');
        setEditID(editID || '');
        setHtmlContent(editHtmlContent || '');
        setContent(editNormalContent || '');
      } 
    }, [location.state]);

  //-------------------------------------------------------------------------
  // Save Edited Content
  //-------------------------------------------------------------------------
  const saveEditContent = async () => {
    if(!heading){ customAlert("", "Enter Heading", "warning")
      return;
    }
    if(!content){ customAlert("", "Enter Content", "warning")
      return;
    }
  

    if (contentEditorRef.current) {
      const contentEditor = contentEditorRef.current;
      const editableDivs = contentEditor.querySelectorAll(".language-display, .code-viewer-content");
      editableDivs.forEach((div) => {
        div.setAttribute("contenteditable", "false");
      });
    }

    const rawContent = content;
    const cleanedContent_ImgRz   = cleanContentImageResize(rawContent);
    const cleanedContent_CodeRz  = cleanContentCodeResize(cleanedContent_ImgRz);
    const cleanedContent         = applyDefaultStyles(cleanedContent_CodeRz);

    try {
      if(heading==null){
        customAlert("",'Enter Heading', 'warning');
        return;
      }

      const sendData = {
        editID: editID,
        
        heading: heading,
        normalContent: parseHTML(cleanedContent),
        htmlContent: cleanedContent,
        // imageDataURL: imageSrc ? imageSrc : defaultImg,
        imageDataURL: croppedCaImageURLRender ? croppedCaImageURLRender : defaultImg,
        imageWidth: ((imageSize.width) / 340) * 100,
        imageHeight: ((imageSize.width) / 340) * 100,
        date: localStorage.getItem('selectedDate'),
        // tag: Tag_value,
        // tag: Tag_Options,
        tag: JSON.stringify(Tag_Options),
        ModifiedByWho: localStorage.getItem('HTES_user_id'),
      };

      const response = await axios.post(`${baseURL}/updateCurrentAffairs`, sendData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        console.log("Content Added success");
        customAlert("", "CA-Content Edited", "success");
          navigateTo('/dashboard');
          // navigateTo(`/${return_location}`);
      } else {
        console.error("Error adding content");
      }
    } catch (error) {
      console.error("Network Error while adding content:", error);
    }
  };

 
  //-------------------------------------------------------------------------
  // Insert Picture and maintain below 50KB
  //-------------------------------------------------------------------------
  const handlePictureInsert = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
  
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          canvas.width = image.width;
          canvas.height = image.height;
  
          let quality = 0.9;
          let dataUrl;
  
          do {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            dataUrl = canvas.toDataURL('image/jpeg', quality);
            quality -= 0.05;
          } while (dataUrl.length > 51200 && quality > 0);
  
          setImageSrc(dataUrl);
  // setCroppedCaImageURL(dataUrl)
          // Calculate rounded size maintaining aspect ratio and max dimension of 340
          const maxSize = 340;
          let width = image.width;
          let height = image.height;
  
          if (width > maxSize || height > maxSize) {
            if (width > height) {
              height = (maxSize / width) * height;
              width = maxSize;
            } else {
              width = (maxSize / height) * width;
              height = maxSize;
            }
          }
  
          const roundedSize = {
            width: Math.round(width),
            height: Math.round(height),
          };
  
          setImageSize(roundedSize);
          //'se', 'ne', 'sw', 'nw'
          setResizeHandles(['se', 'ne', 'sw', 'nw'])
        };
      };
      reader.readAsDataURL(imageFile);
    }
  };
 
  //-------------------------------------------------------------------------
  // Delete function to delete Image
  //-------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete') {
        setImageSrc(null);
        setCroppedCaImageURLRender(null);
        setResizeHandles([]);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //-------------------------------------------------------------------------
  // Image Resize Only
  //-------------------------------------------------------------------------
  const handleResize = (event, { size }) => {
    const roundedSize = {
      width: Math.round(size.width),
      height: Math.round(size.height),
    };
    setImageSize(roundedSize);
  };

  //-------------------------------------------------------------------------
  // Function to maintain max. img size
  //-------------------------------------------------------------------------
  const getMaxConstraints = () => {
    const parent = document.querySelector('.CAeditor_InnerImage');
    if (parent) {
      const { clientWidth, clientHeight } = parent;
      return [Math.min(340, clientWidth), Math.min(340, clientHeight)];
    }
    return [340, 340];
  };

  //-------------------------------------------------------------------------
  // Insert Image by Paste
  //-------------------------------------------------------------------------
  const [resizableBoxSelected, setResizableBoxSelected] = useState(false);
  const [caImageURL, setCaImageURL] = useState(false);
  const [croppedCaImageURL, setCroppedCaImageURL] = useState(false);
  const [croppedCaImageURLRender, setCroppedCaImageURLRender] = useState('');
  const [caPictureModalStatus, setcaPictureModalStatus] = useState(false);
  function toggleCaPictureModal() {
    setcaPictureModalStatus(!caPictureModalStatus);
    // setImgEditMode(imageEditMode + 1);
  }

  const handleOkCropper=()=>{
    setcaPictureModalStatus(false);
setCroppedCaImageURLRender(croppedCaImageURL);
setResizeHandles(['se', 'ne', 'sw', 'nw'])
  }
  const handlePasteOuterEditor = (event) => {
    if (resizableBoxSelected) {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        const reader = new FileReader();
        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = image.width;
            canvas.height = image.height;

            let quality = 0.9;
            let dataUrl;

            do {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
              dataUrl = canvas.toDataURL('image/jpeg', quality);
              quality -= 0.05;
            } while (dataUrl.length > 51200 && quality > 0);

            setImageSrc(dataUrl);
          };
        };
        reader.readAsDataURL(blob);
      }
    }
    }
  };

  const handleClick = (event) => {
    if (event.target.closest('.CA_Resizer')) {
      setResizableBoxSelected(true);
      // alert('true')
    } else {
      // alert('false')
      setResizableBoxSelected(false);
    }
  };

  useEffect(() => {
    const pasteListener = (event) => handlePasteOuterEditor(event);
    document.addEventListener("paste", pasteListener);
    document.addEventListener("click", handleClick);
    
    return () => {
      document.removeEventListener("paste", pasteListener);
      document.removeEventListener("click", handleClick);
    };
  }, [resizableBoxSelected]);

  return (
    <div>
      
      <div className="CA_editor_Main_container">
      {/*_________________________Image Container_________________________  */}
        <div className="CA_Editor_ImageContainer">
          <div className="CAeditor_InnerImage">
            <ResizableBox
              width={imageSize.width}
              height={imageSize.height}
              minConstraints={[50, 50]}
              maxConstraints={getMaxConstraints()}
              // resizeHandles={['se', 'ne', 'sw', 'nw']}
              resizeHandles={resizeHandles}
              onResize={handleResize}
              onResizeStart={() => setIsImgResizing(true)}
              onResizeStop={() => setIsImgResizing(false)}
              lockAspectRatio={true}
              className="CA_Resizer"
            >
              <img
                src={croppedCaImageURLRender }
                // alt=" "
                style={{
                  width: '100%',
                  height: '100%',
                  border: isImgResizing ? '5px solid greenyellow' : (isHovered ? '5px solid greenyellow' : 'none'),
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
            </ResizableBox>
          </div>
          Image Size: [{imageSize.width} x {imageSize.height}] px

          <div className="input_button_Box">
            <input
              type="file"
              // id="imgs_editorPicInsert"
              // onChange={handlePictureInsert}
              style={{ display: 'none' }}
              />
            <label
              // htmlFor="imgs_editorPicInsert"
              onClick={toggleCaPictureModal}
              className="insert_ImageButton"
              style={{ marginRight: '10px' }}
            >
              <span><i className="fas fa-pencil"></i>&nbsp;Insert Image</span>
            </label>
            
          </div>
        </div>

      {/*_________________________ Editor Container_________________________  */}
        <div className="CA_Editor_EditorContainer">
            <h3 className="CA_Editor_Header">Current Affairs Editor</h3>

          <Editor1
            isHeadingRequird={true}
            headingValue={heading}
            setHeadingValue={setHeading}
            height="50vh"
            content={content}
            setContent={setContent}
            // setOutputContent={setOtputContent}
            htmlContent={htmlContent}
            setHtmlContent={setHtmlContent}
            
            // for tag as text  
            is_tag_required={false}
            Tag_value={Tag_value}
            setTag_value={setTag_value}
            
            // for tag as Multi select
            is_tag_Option_required={true}
            Tag_Options={Tag_Options}
            setTag_Options={setTag_Options}
            placeholder='Enter the Tag'

            return_location="/dashboard"
            editID={editID}
            Button_Name={((editID)?'Update':'Save')}
            onSave={((editID)?saveEditContent:saveContent)}
            showActionButtons={true}
            />
        </div>

      </div>


      <Modal
        open={caPictureModalStatus}
        onClose={setcaPictureModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <div className="pmMuiProfilePicModalBox" >
             {/*_____________________ Modal Headings _____________________ */}
            <div >
              <h5 className="pmTabHeadings">Insert Picture</h5>
              <IconButton
                id="button-zoom"
                className="modal-close-iconButton-right-top-corner"
                onClick={toggleCaPictureModal}
              >
                <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
              </IconButton>
            </div>
          <br />
              
             {/*__________ Component call for Template using Props __________ */}
              <ImageCropperTemplate 
              cropperAspectRatio="fixed"    // free or fixed
              imageURL={caImageURL}
              setImageURL={setCaImageURL}
              croppedImgURL={croppedCaImageURL}
              setCroppedImgURL={setCroppedCaImageURL}
              isSaveButtonRequired={true}
              SaveButtonClick={()=>handleOkCropper()}
              // deleteSrcImage={deleteSrcImage} // add function/api to delete Img // write api
              // imageEditMode={imageEditMode}
              />

          </div>
    </Modal>





    </div>
  );
}

export default CA_Editor;

