import React, { useState, useEffect } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom"; // Import customAlert
import { baseURL } from "../../http"; // Import baseURL
import "./FromCompany.css";

const FromCompanyAddProject = ({
  initialFormData,
  openModal,
  setOpenModal,
  SetDataBaseUpdate,
  CloseActionDialogMenu,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    gst: "",
    pan_number: "",
    logo: null,
    official_mail_id: "",
    phone_number: "",
    mail_id: "",
    contact_person_name: "",
    designation: "",
    ...initialFormData, // Pre-populate form fields with initial data for editing
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Update form data when initialFormData changes (for editing)
    setFormData((prevData) => ({
      ...prevData,
      ...initialFormData,
    }));
  }, [initialFormData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    const phoneWithPlus = value.startsWith("+") ? value : `+${value}`;
    setFormData({
      ...formData,
      phone_number: phoneWithPlus, // Updated to phone_number
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate required fields
    const requiredFields = [
      "name",
      "address",
      "official_mail_id",
      "phone_number",
      "contact_person_name",
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      customAlert("Warning", "Please fill the required fields.", "warning");
      return; // Stop submission if there are empty fields
    }

    // Validate and format the phone number
    const phoneNumber = parsePhoneNumberFromString(formData.phone_number);
    if (!phoneNumber) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter a valid phone number.",
      }));
      return; // Stop submission if phone number is invalid
    }

    const formattedPhoneNumber = `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;
    const updatedFormData = { ...formData, phone_number: formattedPhoneNumber };

    try {
      const userId = localStorage.getItem("HTES_user_id");

      // Convert the logo file to Base64 if it exists
      let logoBase64 = null;
      if (formData.logo) {
        logoBase64 = await convertToBase64(formData.logo);
      }

      const submissionData = {
        ...updatedFormData,
        logo: logoBase64,
        created_by: userId,
        modified_by: userId,
      };

      // Send data to the backend for update
      const response = await axios.put(
        `${baseURL}/from-company-update/${initialFormData.id}`, // Assuming `id` is part of `initialFormData`
        submissionData
      );
      if (response.status === 200) {
        SetDataBaseUpdate(true); // Trigger data refresh
        customAlert("Success", "Data updated successfully!", "success");
        setFormData({
          name: "",
          address: "",
          gst: "",
          pan_number: "",
          logo: null,
          official_mail_id: "",
          phone_number: "",
          mail_id: "",
          contact_person_name: "",
          designation: "",
        });

        // Close the modal
        setOpenModal(false);

        if (CloseActionDialogMenu) {
          CloseActionDialogMenu();
        }
      } else {
        customAlert(
          "Error",
          "Something went wrong. Please try again.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating form:", error);
      customAlert("Error", "Something went wrong. Please try again.", "error");
    }
  };

  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <div className="from-company-enquiry-content">
        <IconButton
          className="from-company-enquiry-close-btn"
          onClick={() => setOpenModal(false)}
        >
          <CloseIcon className="from-company-enquiry-close-btnX2" />
        </IconButton>
        <div className="from-company-enquiry-header">
          {formData.id ? "Edit Our Company Details" : "Our Company Details"}
        </div>

        <form className="from-company-enquiry-form" onSubmit={handleFormSubmit}>
        <TextField
            margin="dense"
            label="Company Name"
            name="name"
            type="text"
            fullWidth
            required
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Address"
            name="address"
            type="text"
            fullWidth
            required
            value={formData.address}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="GST Number"
            name="gst"
            type="text"
            fullWidth
            value={formData.gst}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Pan Number"
            name="pan_number"
            type="text"
            fullWidth
            value={formData.pan_number}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Logo"
            name="logo"
            type="file"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setFormData({
                ...formData,
                logo: e.target.files[0],
              });
            }}
          />
          <TextField
            margin="dense"
            label="Official Mail ID"
            name="official_mail_id"
            type="email"
            fullWidth
            required
            value={formData.official_mail_id}
            onChange={handleChange}
          />
          {/* Phone input field */}
          <PhoneInput
            country={"in"}
            value={formData.phone_number} // Updated to phone_number
            onChange={handlePhoneChange}
            inputClass="phone-input"
            buttonClass={errors.phone ? "phone-button-error" : "phone-button"}
            dropdownClass="phone-input-dropdown"
            placeholder="Phone Number"
          />
          {errors.phone && (
            <div className="helper-text-phone-container">{errors.phone}</div>
          )}
          <TextField
            margin="dense"
            label="Mail ID"
            name="mail_id"
            type="email"
            fullWidth
            value={formData.mail_id}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Contact Person Name"
            name="contact_person_name"
            type="text"
            fullWidth
            required
            value={formData.contact_person_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Designation"
            name="designation"
            type="text"
            fullWidth
            value={formData.designation}
            onChange={handleChange}
          />

<div className="from-company-enquiry-actions">
            <Button
              type="submit"
              color="success"
              variant="contained"
              onClick={handleFormSubmit}
            >
              {formData.id ? (
                <><EditIcon className="from-company-buttoniconcommon" /> Update</>
              ) : (
                <><SaveIcon className="from-company-buttoniconcommon" /> Save</>
              )}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FromCompanyAddProject;
