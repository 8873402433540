import React, { useState, useEffect } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField } from "@mui/material";
import { Select, FormControl } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../http";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  getDeviceInfo,
  getCurrentDateTime,
  getLocationInfo,
} from "../Common/Utilities_Function";

function NewUserAdd({ setUpdate, dropdownOptions }) {
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("in");
  const [addUserModalStatus, setAddUserModalStatus] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    usertype: "",
    mobile: "",
    password: "",
  });
  const [addData, setAddData] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    usertype: "",
    status: "",
    last_Login_time: null,
    last_login_device_name: null,
    Longitude: null,
    Latitude: null,
    PinCode_Login: null,
    Current_Location: null,
  });

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.countryCode.toUpperCase());

    const phoneNumber = parsePhoneNumberFromString(
      value,
      country.countryCode.toUpperCase()
    );

    if (phoneNumber && phoneNumber.isValid()) {
      setAddData((prevData) => ({
        ...prevData,
        mobile: phoneNumber.number,
      }));
      setErrors((prev) => ({ ...prev, mobile: "" })); // Clear phone error if valid
    } else {
      setErrors((prev) => ({
        ...prev,
        mobile: "Phone number is not valid",
      }));
    }
  };

  useEffect(() => {
    if (addData.last_login_device_name == null) {
      addData.last_login_device_name = getDeviceInfo();
    }

    if (addData.last_Login_time == null) {
      addData.last_Login_time = getCurrentDateTime();
    }

    async function handleAddData() {
      if (addData.Longitude == null) {
        const location = await getLocationInfo();
        if (location != null) {
          setAddData((prevData) => ({
            ...prevData,
            Latitude: location.latitude,
            Longitude: location.longitude,
            PinCode_Login: location.pincode,
            Current_Location: location.locationName,
          }));
        }
      }
    }

    handleAddData();
  }, [addData]);

  const handleOpen = () => {
    setAddUserModalStatus(true);
  };

  const handleClose = () => {
    setAddUserModalStatus(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear errors when valid input is provided
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for the corresponding field
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!addData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!addData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    if (!addData.mobile) {
      newErrors.mobile = "Phone number is required";
      isValid = false;
    }

    if (!addData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    if (!addData.usertype) {
      newErrors.usertype = "User type is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const addUser = () => {
    if (!validateForm()) return;

    axios
      .post(baseURL + `/register`, [addData])
      .then((response) => {
        setUpdate(true);
        handleClose();
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.error || "Failed to add user";
        setErrors((prev) => ({ ...prev, api: errorMessage }));
      });
  };

  return (
    <>
      <div className="addEducationalhoverandcolor">
        <div onClick={handleOpen}>
          <AddBoxIcon
            className="addEducationaladdicon"
            color="primary"
            fontSize="large"
          />
          <br />
          <span>Add User Manually</span>
        </div>
      </div>

      <Modal
        open={addUserModalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="addnewusercontainer">
          <IconButton
            id="button-zoom"
            className="EducationalTableViewmodalcloseicon"
            onClick={handleClose}
          >
            <CloseIcon className="EducationalTableViewmodalcloseiconX" />
          </IconButton>
          <div className="Add-new-user-heading">Add New User </div>
<div className="grid-container-and-scroll-handle">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                variant="outlined"
                value={addData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={addData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                country={countryCode.toLowerCase()}
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Employee Phone Number"
                inputStyle={{
                  width: "100%",
                  height: "55px",
                  fontSize: "16px",
                  outline: errors.mobile ? "1px solid red" : "",
                }}
                buttonStyle={{
                  background: "white",
                  borderLeft: errors.mobile ? "2px solid red" : "",
                  borderTop: errors.mobile ? "2px solid red" : "",
                  borderBottom: errors.mobile ? "2px solid red" : "",
                  borderRight: "none",
                }}
                dropdownStyle={{
                  fontSize: "16px",
                }}
              />
              {errors.mobile && (
                <div
                  style={{ color: "red", fontSize: "12px", marginTop: "10px" }}
                >
                  {errors.mobile}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                variant="outlined"
                value={addData.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon className="visiablitycolor" />
                        ) : (
                          <VisibilityOffIcon className="visiablitycoloroff" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.usertype}>
                <InputLabel id="usertype-label">User Type</InputLabel>
                <Select
                  labelId="usertype-label"
                  id="usertype"
                  name="usertype"
                  value={addData.usertype}
                  label="User Type"
                  onChange={handleChange}
                  error={!!errors.usertype}
                >
                  {dropdownOptions && dropdownOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.usertype && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.usertype}
                </div>
              )}
            </Grid>
           
          </Grid>
          </div>
          <div className="addUserSubmitGrid">
              <Button
                variant="contained"
                id='button-zoom'
                color="success"
                onClick={addUser}
                startIcon={<SaveIcon className="buttonicons" />}
              >
                Save
              </Button>
            </div>
        </div>
      </Modal>
    </>
  );
}

export default NewUserAdd;
