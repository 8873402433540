import React, { useState } from "react";
import "../pdfpage.css"; // Import external CSS for styling

function LayoutsMain1({filter}) {
  const [footerText, setFooterText] = useState("Edit this footer...");

  return (
    <div className="pdf-layout-container">
      {/* Header */}
      <div className="pdf-header">
        <span className="page-number">Page 1</span>
      </div>

      {/* Content */}
      <div className="pdf-content">
        <p>
          {/* Add content here */}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
          imperdiet, nisi sed lacinia fermentum, justo risus egestas libero,
          id tincidunt eros neque at augue. Praesent vehicula eros at elit
          vehicula, ut pulvinar justo fermentum.
        </p>
        {/* Add more content if needed */}
        <p>Keep adding content to test overflow behavior.</p>
      </div>

      {/* Footer */}
      <div className="pdf-footer">
        <input
          type="text"
          value={footerText}
          onChange={(e) => setFooterText(e.target.value)}
          className="footer-input"
        />
      </div>
    </div>
  );
}

export default LayoutsMain1;
