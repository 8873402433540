import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import GoogleButton from "react-google-button";
import { baseURL } from "../../http";
import {
  getDeviceInfo,
  getCurrentDateTime,
  GoogleSignin,
  getLocationInfo
} from "./Utilities_Function";
import GoogleIcon from "@mui/icons-material/Google";
import Divider from "@mui/material/Divider";
import { customAlert } from "../SweetAlertCommon/Custom";


// SignIn component
const SignIn = () => {
  // State variables
  const [userType, setUserType] = useState(localStorage.getItem('userType')); // Store user type
  localStorage.setItem('userType',userType)
  const [trigger, setTrigger] = useState(false);
  const [LoginData, setLoginData] = useState({
    email: "",
    password: "",
    last_Login_time: '',
    last_login_device_name: '',
    Longitude: '',
    Latitude: '',
    PinCode_Login: "",
    Current_Location: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const storedUser= localStorage.getItem('HTES_user_id');

  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    
    if (storedUserType ) {
      setUserType(storedUserType);
    }
  }, [storedUser]);
  //console.log(LoginData)

  // Fetch user type when component mounts or trigger changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getLoginTable`);
        const userData = response.data.find(user => user.email === localStorage.getItem("HTES_user_id"));
        if (userData) {
          setUserType(userData.usertype);
          if (userData.usertype !== 'General') {
            window.location.href = "/dashBoard";
          } else {
            window.location.href = "/dashBoard";
          }
        }
      } catch (error) {
        console.error("Error fetching login data:", error);
      }
    };

    if (trigger) {
      fetchData();
      setTrigger(false);
    }
  }, [trigger]);
      
     
  

  // Google login handler
  const handleGoogleLogin = () => {
    GoogleSignin()
      .then((user) => {
        LoginData.email = user.email;
        console.log(user.email)
        loginHandle(baseURL + "/google_login", LoginData);
      })
      .catch((error) => {
        console.error("Sign-in error:", error);
      });
  };

  // Change handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    checkAutofill();
  };

  // Submit handler
  const handleSubmit = (event) => {
    event.preventDefault();
  
    const storedEmail = localStorage.getItem("HTES_user_id");
  
    // Clear local storage if a different user is logging in
    if (storedEmail && storedEmail !== LoginData.email) {
      localStorage.clear();
    }
  
    loginHandle(baseURL + "/loginuser", LoginData);
  };

  // Login handler function
  const loginHandle = (iUrl, iData) => {
    axios
      .post(iUrl, iData)
      .then((response) => {
        localStorage.setItem("HTES_user_id", response.data.user.email);
        localStorage.setItem("HTES_user_name", response.data.user.name);
        setTrigger(true)
       
       })
      .catch((error) => {
        console.log(error);
        if (error.response.status===400) {
          customAlert('',  "User Not Found,Please Register!","warning",);
        } else if (error.response.status===401) {
          customAlert('', "This user is blocked, please connect with the Administrator!",'warning');
        }else if (error.response.status===402) {
          customAlert('', "Email and Password Not Matched!",'warning');
        }
         else {
          customAlert('', "An error occurred while processing your request!","error",);
        }
      });
  };

  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Check autofill callback
  const checkAutofill = () => {
    if (LoginData.email.trim() !== "") {
      console.log("Email autofilled:", LoginData.email);
    }
  };


  if (!LoginData.last_login_device_name) {
    setLoginData((prevData) => ({
      ...prevData,
      last_login_device_name: getDeviceInfo(),
    }));
  }

  if (!LoginData.last_Login_time) {
    setLoginData((prevData) => ({
      ...prevData,
      last_Login_time: getCurrentDateTime(),
    }));
  }

  if (!LoginData.Longitude) {
    getLocationInfo()
      .then(location => {
        if (location && location.latitude && location.longitude) {
          setLoginData((prevData) => ({
            ...prevData,
            Latitude: location.latitude,
            Longitude: location.longitude,
            PinCode_Login: location.pincode,
            Current_Location: location.locationName,
          }));
  
          console.log(location.latitude);
          console.log(location.longitude);
          console.log(location.pincode);
          console.log(location.locationName);
        } else {
          // Trigger a custom alert if location data is invalid
          customAlert('',"Failed to retrieve location information. Please check your location settings.",'warning');
        }
      })
      .catch(error => {
        // Handle any other errors from the getLocationInfo call
        console.error("Error:", error);
        customAlert('',"An error occurred while fetching location data. Please try again.",'error');
      });
  }
  
  

  // Effect to update render count and fetch location information
  // useEffect(() => {
  //   var HTES_user_id = localStorage.getItem("HTES_user_id");

  //   if (HTES_user_id) {
  //     window.location.href = "/dashBoard";
  //     return true;
  //   }

  //   setRenderCount((prevCount) => prevCount + 1);
  //   document.title = "Sign In";


  // }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleGoogleLogin}
            startIcon={<GoogleIcon />}
          >
            Login With Google
          </Button>
          <br />
          <br />
          <Divider> or </Divider>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={handleChange}
            autoFocus
          />
          <FormControl variant="outlined" fullWidth required>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              label="Password"
              name="password"
              autoComplete="password"
              onChange={handleChange}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button type="submit"  onClick={handleSubmit}fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot_password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default SignIn;
