import React, { useState, useEffect } from "react";

const tabsData = [
  { id: 1, title: "User Management", content: "<user/>" },
];

function TeacherSideNav() {
  const [activeTab, setActiveTab] = useState(tabsData[0].id);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [showMobileTabs, setShowMobileTabs] = useState(false);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if (isMobile) {
      setShowMobileTabs(false);
    }
  };

  const handleToggleMobileTabs = () => {
    setShowMobileTabs(!showMobileTabs);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 600);
    if (window.innerWidth >= 600) {
      setShowMobileTabs(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`vertical-tabs-container`} style={{ zIndex: "-100" }}>
      {isMobile && (
        <span
          className={`mobile-tab-toggle ${showMobileTabs ? "active" : ""}`}
          onClick={handleToggleMobileTabs}
        >
          &#9776; {/* Display the hamburger icon */}
        </span>
      )}

      <div style={{ display: "flex" }}>
        <div
          className={`vertical-tabs ${isMobile ? "left-modal" : ""} ${showMobileTabs ? "show" : ""}`}
          style={{
            width: isMobile ? "60%" : "15%",
            height: "100%",
            marginTop: isMobile ? "60px" : "-7px",
          }}
        >
          {tabsData.map((tab) => (
            <div
              key={tab.id}
              className={`vertical-tab ${tab.id === activeTab ? "active" : ""}`}
              style={{ width: "auto" }}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>

      <div
        className="tab-content"
        style={{ width: isMobile ? "100%" : "85%", height: "100vh" }}
      >
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane ${tab.id === activeTab ? "active" : ""}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeacherSideNav;
