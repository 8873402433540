import React from 'react';
import './QABlog.css';

const LeftSidebar = () => {
    return (
        <div className="left-sidebar">
            <nav>
                <ul>
                    {/* Add your navigation links here */}
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/qa_blog_questions">Questions</a>
                    </li>
                    <li>
                        <a href="/tags">Tags</a>
                    </li>
                    <li>
                        <a href="/users">Users</a>
                    </li>
                    <li>
                        <a href="/companies">Companies</a>
                    </li>
                    {/* Add more links as needed */}
                </ul>
            </nav>
        </div>
    );
};

export default LeftSidebar;
