import React from "react";
import { Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function ExamDownloadTemplate({ anchorEl, setAnchorEl }) {
  //--------------------------------------------------------------------------------
  //handle menu close after export
  //--------------------------------------------------------------------------------
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //--------------------------------------------------------------------------------
  //Download the template
  //--------------------------------------------------------------------------------
  const handleDownload = async (format) => {
    try {
      // Define the column names
      const columnNames = [
        "top_category",
        "exam_category",
        "state_or_department",
        "exam_name",
        "authority_url",
        "overview",
        "syllabus",
        "eligibility",
        // "fees",
        "fees.general",
        "fees.sc",
        "fees.st",
        "fees.others",
      ];

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Create the first worksheet with column names
      const ws1 = XLSX.utils.aoa_to_sheet([columnNames]);
      XLSX.utils.book_append_sheet(wb, ws1, "Template");

      // Create example rows for the specified columns
      const exampleRow = [
        "Engineering", // top_category
        "JEE Mains", // exam_category
        "National", // state_or_department
        "Joint Entrance Examination Mains", // exam_name
        "https://jeemain.nta.nic.in/", // authority_url
        "National level entrance exam for engineering courses in India.", // overview
        "Mathematics, Physics, Chemistry.", // syllabus
        "Must have passed 12th standard with Physics, Chemistry, and Mathematics.", // eligibility
        // "", 
        650, // fees.general
        325, // fees.sc
        325, // fees.st
        500, // fees.others
      ];

      const exampleRow1 = [
        "Medical", // top_category
        "NEET", // exam_category
        "National", // state_or_department
        "National Eligibility cum Entrance Test", // exam_name
        "https://ntaneet.nic.in/", // authority_url
        "Entrance exam for medical courses in India.", // overview
        "Physics, Chemistry, Biology.", // syllabus
        "Must have passed 12th standard with Biology, Physics, and Chemistry.", // eligibility
        // "", 
        1500, // fees.general
        800, // fees.sc
        800, // fees.st
        1000, // fees.others
      ];

      const ws2 = XLSX.utils.aoa_to_sheet([
        columnNames,
        exampleRow,
        exampleRow1,
      ]);
      XLSX.utils.book_append_sheet(wb, ws2, "Example");

      // Convert workbook to binary string
      const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

      // Convert binary string to Blob
      const blob = new Blob([s2ab(wbBinary)], {
        type: "application/octet-stream",
      });

      // Determine the file extension based on the selected format
      const extension = format === "csv" ? "csv" : "xlsx";

      // Save Blob as file
      saveAs(blob, `Exam_Name_Upload_template.${extension}`);
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Convert string to ArrayBuffer
  //--------------------------------------------------------------------------------

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
      {/* ______________________Jsx for menu items________________________ */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ marginTop: "-50px" }}
      >
        <MenuItem onClick={() => handleDownload("csv")}>
          Download as CSV
        </MenuItem>
        <MenuItem onClick={() => handleDownload("excel")}>
          Download as Excel
        </MenuItem>
      </Menu>
      {/* ______________________End________________________ */}
    </>
  );
}

export default ExamDownloadTemplate;
