import React, { useState, useEffect } from "react";
import { Button, Tooltip } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";

function Dynamicimage() {
  const [selectedImage, setSelectedImage] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loadi

  useEffect(() => {
    if (images.length === 0) {
      fetchImages();
    }
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(baseURL + "/getimages");
      setImages(response.data.images);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  if (loading) {
    return <div style={{ margin: "15px" }}>Loading...</div>;
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      customAlert("", "Please select an image.", "warning");
      return;
    }

    // Check if there are images available
    if (images.length > 0) {
      // Delete the existing image first
      try {
        await axios.delete(baseURL + `/logos/${images[0].id}`);
        //customAlert('', 'Existing logo deleted successfully', 'success');
      } catch (error) {
        console.error("Error deleting existing logo:", error);
      }
    }

    // Proceed with the upload
    uploadLogo(selectedImage);
  };

  const uploadLogo = async (image) => {
    const formData = new FormData();
    formData.append("LogoPath", image);

    try {
      const response = await axios.post(baseURL + "/uploadlogos", formData);
      if (response) {
        customAlert(
          "",
          "Logo uploaded successfully,Please Refresh the Page to see the Effect",
          "success"
        );

        // Refresh the list of images after successful upload
        fetchImages();
      }
    } catch (error) {
      if (error.response.status === 400) {
        customAlert("", "Duplicate Logo not allowed", "error");
      } else {
        console.log("Error uploading image:", error);
      }
    }
  };

  const removeLastThreeChars = (str) => {
    return str.slice(0, -3);
  };

  const handleDelete = async (imageId) => {
    try {
      await axios.delete(baseURL + `/logos/${imageId}`);
      customAlert("", "Image deleted successfully", "success");
      // Refresh the list of images after successful delete
      fetchImages();
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <div className="UserSettingdynamicimage">
      <div style={{ display: "flex" }}>
        <div style={{ maxWidth: "250px" }}>
          <h6 className="headerFontandstyle">Upload Your Logo</h6>
          <div style={{ maxWidth: "230px", marginTop: "15px" }}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ marginBottom: "15px", maxWidth: "210px" }}
            />
            &nbsp;&nbsp;
          </div>
          <div>
            <Button
              variant="contained"
              id="button-zoom"
              color="success"
              onClick={handleUpload}
            >
              <UploadIcon className="buttonicons" /> Upload
            </Button>
          </div>
        </div>
      </div>

      <div className="UserSettingdynamicimage1">
        <div className="UserSettingdynamicimage2">
          {/* Display available images */}
          {images.map((image, index) => (
            <div key={index} className="UserSettingdynamicimage3">
              <Button
                onClick={() => handleDelete(image.id)}
                style={{ position: "absolute", top: "90px", right: "-21px" }}
              >
                <Tooltip title="Delete Logo" arrow>
                  <DeleteIcon id="button-zoom" style={{ color: "red" }} />
                </Tooltip>
              </Button>
              <img
                src={`${removeLastThreeChars(baseURL)}${image.LogoPath}`}
                alt={"Dynamic Logo"}
                className="UserSettingdynamicimage4"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dynamicimage;
