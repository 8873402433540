// import React from 'react'
// import HeaderLayout from './HeaderLayout';

// function HeaderInput() {

//     const pageTitle = "Vocabulary Master for All Government Exams Mock Test All Tests";
//   const totalTests = 258;
//   const buttonText = "Add This Test Series";

//   const lists = [
//     "39 Synonyms (Frequently Asked Words)",
//     "39 Antonyms (Mirror Words)",
//     "39 Idiom Meaning (Phrase Hunt)",
//     "39 One Word Substitution (Single Word Wonders)",
//     "39 Spelling (Word Weavers)",
//     "50 SSC PYPsPRO",
//     "13 Miscellaneous Questions",
//   ];

//   // Handle button click
//   const handleButtonClick = (index) => {
//     console.log(`Button ${index} clicked`);
//     // Add your logic here
//   };

//   // Handle add test series button click
//   const handleAddTestSeries = () => {
//     console.log("Add This Test Series button clicked");
//     // Add your logic here
//   };

//   return (
//     <div>
//       <HeaderLayout
//       pageTitle={pageTitle}
//       totalTests={totalTests}
//       buttonText={buttonText}
//       lists={lists}
//       handleButtonClick={handleButtonClick}
//       handleAddTestSeries={handleAddTestSeries}
//     />
//     </div>
//   )
// }

// export default HeaderInput



// import React from 'react';
// import HeaderLayout from './HeaderLayout';

// const HeaderInput = () => {
//     const testData = {
//         pageTitle: "Vocabulary Master for All Government Exams Mock Test All Tests",
//         totalTests: 258,
//         buttonText: "Add This Test Series",
//         lists: [
//           "39 Synonyms (Frequently Asked Words)",
//           "39 Antonyms (Mirror Words)",
//           "39 Idiom Meaning (Phrase Hunt)",
//           "39 One Word Substitution (Single Word Wonders)",
//           "39 Spelling (Word Weavers)",
//           "50 SSC PYPsPRO",
//           "13 Miscellaneous Questions",
//         ],
//         handleButtonClick: (index) => {
//           console.log(`Button ${index} clicked`);
//           // Add your logic here
//         },
//         handleAddTestSeries: () => {
//           console.log("Add This Test Series button clicked");
//           // Add your logic here
//         },
//     };

//     return (
//       <div>
//         <div>
//           <HeaderLayout testData={testData} />
//         </div>
//       </div>
//     )
// }

// export default HeaderInput;

import React from 'react';
import HeaderLayout from './HeaderLayout';
import TestSeriesMain from './TestSeriesMain';
import FAQPage from './FAQPage';
import FAQInput from './FAQInput';


const HeaderInput = () => {
  const testDatas = [
    {
      pageTitle: "Vocabulary Master for All Government Exams Mock Test All Tests",
      totalTests: 258,
      buttonText: "Add This Test Series",
      lists:[
        "39 Synonyms (Frequently Asked Words)",
        "39 Antonyms (Mirror Words)",
        "39 Idiom Meaning (Phrase Hunt)",
        "39 One Word Substitution (Single Word Wonders)",
        "39 Spelling (Word Weavers)",
        "50 SSC PYPsPRO",
        "13 Miscellaneous Questions",
      ],

      handleButtonClick: (index) => {
        console.log(`Button ${index} clicked`);
        // Add your logic here
      },

      handleAddTestSeries: () => {
        console.log("Add This Test Series button clicked");
        // Add your logic here
      },
    },
  ];

  return (
    <div>
      {testDatas.map((testData, index) => (
        <HeaderLayout key={index} testData={testData} />
      ))}
      <TestSeriesMain />
      <FAQInput />
    </div>
  );
}

export default HeaderInput;
