import React, { useEffect } from "react";

import TeacherHeader from "./TeacherHeader";
import TeacherSideNav from "./1TeacherSideNav";

function TeacherDashboard() {
  useEffect(() => {
    document.title = "Teacher-Dashboard";
  });

  return (
    <div>
      <TeacherHeader />
      <TeacherSideNav />
    </div>
  );
}

export default TeacherDashboard;
