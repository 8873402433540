import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ContentRender.css";
import { baseURL } from "../../../http";
import { FaArrowRight } from "react-icons/fa";

function ContentAdsSection() {
  const [adData, setAdData] = useState([]); // Initialize adData as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const response = await axios.get(`${baseURL}/get_advertisements`);
        setAdData(response.data); // Assuming response.data is an array of ads
      } catch (err) {
        setError("Error fetching advertisements");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAdvertisements();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <aside className="ads-section">
      <h2 className="ads-heading">ADVERTISEMENT</h2>
      {adData.map((ad, index) => (
        <div key={index} className="ad-item">
          <h5 className="ad-title">{ad.heading || "Ad Placeholder"}</h5>
          <a
            href={ad.link || "https://example.com"}
            target="_blank"
            rel="noopener noreferrer"
            className="image-container"
          >
            <img
              src={ad.image_url || "https://via.placeholder.com/150"}
              alt={ad.heading || "Ad Placeholder"}
              className="ads-image"
            />
            <FaArrowRight className="forward-icon" />
          </a>
          <p className="ads-content">
            {ad.content || "Your ads or promotional content here."}
          </p>
          <hr className="horizontal-line" />
        </div>
      ))}
    </aside>
  );
}

export default ContentAdsSection;