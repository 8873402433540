import React, { useEffect } from "react";
import StudentHeader from "./StudentHeader";
import StudentSideNav from "./1StudentSideNav";

function StudentDashboard() {
  useEffect(() => {
    document.title = "Student-Dashboard";
  });
  return (
    <div>
      <StudentHeader />
      <StudentSideNav />
    </div>
  );
}

export default StudentDashboard;
