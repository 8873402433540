import React, { useState, useEffect } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save"; // Import SaveIcon

function McqQuestionCard({
  questionData,
  showAnswers,
  isEditing, // Receive isEditing prop
  onDelete,
  onSave, // Renamed onSave prop
}) {
  const [editing, setEditing] = useState(isEditing); // Initialize editing state with isEditing prop
  const [editedQuestion, setEditedQuestion] = useState(questionData.question);
  const [editedOptions, setEditedOptions] = useState([...questionData.options]);

  //-------------------------------------------------------------------------
  // Update edited question when questionData or isEditing changes
  //-------------------------------------------------------------------------
  useEffect(() => {
    setEditing(isEditing); // Update editing state when isEditing changes
    setEditedQuestion(questionData.question);
    setEditedOptions([...questionData.options]);
  }, [questionData, isEditing]);

  //-------------------------------------------------------------------------
  // Handle option change
  //-------------------------------------------------------------------------
  const handleOptionChange = (index, value) => {
    const updatedOptions = [...editedOptions];
    updatedOptions[index] = value;
    setEditedOptions(updatedOptions);
  };

  //-------------------------------------------------------------------------
  // Handle Question chage
  //-------------------------------------------------------------------------
  const handleQuestionChange = (value) => {
    setEditedQuestion(value);
  };

  //-------------------------------------------------------------------------
  // Handle Question Edit
  //-------------------------------------------------------------------------
  const handleEdit = () => {
    setEditing(true);
  };

  //-------------------------------------------------------------------------
  // Handle Save the questions
  //-------------------------------------------------------------------------
  const saveQuestion = () => {
    // Renamed handleSave to saveQuestion
    const updatedQuestion = {
      ...questionData,
      question: editedQuestion,
      options: editedOptions,
    };
    onSave(updatedQuestion);
    setEditing(false);
  };

  //-------------------------------------------------------------------------
  // Handle Delete question
  //-------------------------------------------------------------------------
  const handleDelete = () => {
    onDelete(questionData);
  };

  return (
    <div className="questionCardContainer">
      <div className="questionRow">
        {editing ? (
          <textarea
            className="Mcqquestioncardtextarea"
            value={editedQuestion}
            onChange={(e) => handleQuestionChange(e.target.value)}
          />
        ) : (
          <Typography className="text-color-bold-in-mcq">
            {questionData.question}
          </Typography>
        )}
      </div>
      <div className="optionsRow">
        {editedOptions.map((option, index) => (
          <div key={index}>
            <div className="optionalignment">
              <span>{String.fromCharCode(65 + index)}</span>
              <span>&nbsp;&nbsp;</span>
              <span>
                {editing ? (
                  <textarea
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    className="optionsEdits"
                  />
                ) : (
                  <span
                    style={{
                      color:
                        showAnswers && option === questionData.answer
                          ? "green"
                          : "inherit",
                      fontWeight:
                        showAnswers && option === questionData.answer
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {option}
                  </span>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="saveanddeleteicon">
        {editing ? (
          <>
            <Tooltip title="Save" arrow>
              <IconButton
                id="button-zoom"
                aria-label="save"
                onClick={saveQuestion}
              >
                <SaveIcon color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <IconButton
                id="button-zoom"
                aria-label="delete"
                onClick={handleDelete}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Edit" arrow>
            <IconButton id="button-zoom" aria-label="edit" onClick={handleEdit}>
              <EditIcon className="mcq-generation-edit-icon-color" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default McqQuestionCard;
