import React from "react";
import { Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function DownloadTemplate({ anchorEl, setAnchorEl }) {
  //--------------------------------------------------------------------------------
  //close the modal
  //--------------------------------------------------------------------------------
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //--------------------------------------------------------------------------------
  //Download the template Excel
  //--------------------------------------------------------------------------------
  const handleDownload = async (format) => {
    try {
      // Define the column names
      const columnNames = ["Question", "Answer", "Category"];

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Create the first worksheet with column names
      const ws1 = XLSX.utils.aoa_to_sheet([columnNames]);
      XLSX.utils.book_append_sheet(wb, ws1, "Template");

      // Create example rows for the specified columns
      const exampleRow = ["Why TPA?", "Good To Learn", "General"];

      const ws2 = XLSX.utils.aoa_to_sheet([columnNames, exampleRow]);
      XLSX.utils.book_append_sheet(wb, ws2, "Example");

      // Convert workbook to binary string
      const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

      // Convert binary string to Blob
      const blob = new Blob([s2ab(wbBinary)], {
        type: "application/octet-stream",
      });

      // Determine the file extension based on the selected format
      const extension = format === "csv" ? "csv" : "xlsx";

      // Save Blob as file

      saveAs(blob, `Course_Name_Upload_template.${extension}`);
    } catch (error) {
      console.error("Error downloading template:", error);
    }
    handleMenuClose();
  };

  //--------------------------------------------------------------------------------
  // Convert string to ArrayBuffer
  //--------------------------------------------------------------------------------
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ marginTop: "-50px" }}
      >
        <MenuItem onClick={() => handleDownload("csv")}>
          Download as CSV
        </MenuItem>
        <MenuItem onClick={() => handleDownload("excel")}>
          Download as Excel
        </MenuItem>
      </Menu>
    </>
  );
}

export default DownloadTemplate;
