import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Button,
  Divider,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import "./Quiz.css";
import QuizTime from "./QuizTime";
import SendIcon from "@mui/icons-material/Send";
import QuizNav from "./QuizNav1";
import QuizQuestion from "./QuizQuestion1";
import QuizRightNav from "./QuizRightNav";
import { Drawer, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation } from "react-router-dom";
import { baseURL } from "../../http";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import { useNavigate } from "react-router-dom";
import DropDown from "../DropDownWithProps/DropDown";
import DynamicImageLogo from "../UserSettingAPI/DynamicImageLogo";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";

const QuizMain = ({
  reportModalOpen,
  setReportModalOpen,
  keypressCounter,
  setSelectedLanguage,
  selectedLanguage,
}) => {
  console.log("selectedLanguage", selectedLanguage);

  const email = localStorage.getItem("HTES_user_id");
  const location = useLocation();
  const testinfo = location.state.testinfo;
  const questionIds = JSON.parse(testinfo.QuestionIds);
  const totalQuestions = parseInt(testinfo.NoOfQuestions);
  const [closedNavStatus, setClosedNavStatus] = useState(0);
  const [AllQuestionData, setAllQuestionsData] = useState([]);
  const [timeLeft, setTimeLeft] = useState(testinfo.TestDuration * 60);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // State to track the current question index
  const [selectedOptions, setSelectedOptions] = useState(
    Array(totalQuestions).fill("")
  );
  const [markedForReview, setMarkedForReview] = useState(
    Array(totalQuestions).fill(false)
  );
  const [open, setOpen] = useState(false);
  const [isRightNavOpen, setIsRightNavOpen] = useState(true);
  const [testName, setTestName] = useState(testinfo.TestName); // State variable to store test name
  const [pausedTimes, setPausedTimes] = useState(
    Array(totalQuestions).fill({ minutes: 0, seconds: 0 })
  );
  const [loading, setLoading] = useState(true);

  const [translated, setTranslated] = useState([]);
  const [timerActive, setTimerActive] = useState(true);

  const [reportDetailsArray, setReportDetailsArray] = useState(
    Array.from({ length: totalQuestions }, () => ({ option: "", details: "" }))
  );
  const [viewedQuestions, setViewedQuestions] = useState(
    Array(totalQuestions).fill(false)
  );

  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch available images when the component mounts
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(baseURL + "/getimages");
      setImages(response.data.images);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }
  }, [timeLeft]);

  console.log("translated", translated);

  // Define a function for fetching data with retries
  useEffect(() => {
    const fetchData = async (retryCount = 10, retryInterval = 6000) => {
      let retries = 0;
      let success = false;

      while (!success && retries < retryCount) {
        try {
          const response = await axios.get(
            baseURL + "/getAllAiGeneratedQuestionsquiz",
            {
              params: {
                questionIds: questionIds, // Assuming questionIds is available in your component
              },
            }
          );
          const responseDataArray = response.data;

          const filteredDataArray = responseDataArray.map((questionData) => {
            const filteredData = {
              id: questionData.id,
              Questions: questionData.Questions,
              OptionA: questionData.OptionA,
              OptionB: questionData.OptionB,
              OptionC: questionData.OptionC,
              OptionD: questionData.OptionD,
            };
            return filteredData;
          });

          setAllQuestionsData(filteredDataArray);
          setTranslated(filteredDataArray);
          //console.log("filteredDataArray", filteredDataArray);
          //setLoading(false); // Set loading to false after fetching and processing data
          success = true; // Set success flag to true
        } catch (error) {
          console.log("Error fetching data:", error);
          retries++; // Increment retry count
          await new Promise((resolve) => setTimeout(resolve, retryInterval)); // Wait before retrying
        }
      }

      if (!success) {
        console.log("Max retries reached. Unable to fetch data.");
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once

  const translateText = async (text, targetLanguage) => {
    try {
      const response = await axios.post(baseURL + "/lang", {
        text,
        targetLanguage,
      });
      return response.data.translated_text;
    } catch (error) {
      console.error("Error translating text:", error);
      return null;
    }
  };

  const translateDummyQuestions = async () => {
    try {
      setLoading(true); // Set loading to true before starting translations
      const translatedQuestions = await Promise.all(
        AllQuestionData.map(async (question) => {
          const translatedQuestion = { ...question };
          translatedQuestion.Questions = await translateText(
            question?.Questions,
            selectedLanguage.code
          );
          translatedQuestion.OptionA = await translateText(
            question?.OptionA,
            selectedLanguage.code
          );
          translatedQuestion.OptionB = await translateText(
            question?.OptionB,
            selectedLanguage.code
          );
          translatedQuestion.OptionC = await translateText(
            question?.OptionC,
            selectedLanguage.code
          );
          translatedQuestion.OptionD = await translateText(
            question?.OptionD,
            selectedLanguage.code
          );

          // Update progress
          console.log("initialrendering");
          return translatedQuestion;
        })
      );
      setTranslated(translatedQuestions);
    } catch (error) {
      console.error("Error translating questions:", error);
    } finally {
      setLoading(false); // Set loading to false after translations are done
    }
  };

  // useEffect(() => {
  //   if (AllQuestionData.length > 0 ) {
  //     translateDummyQuestions();
  //   }
  // }, [AllQuestionData.length]);

  useEffect(() => {
    const handleDataAvailability = async () => {
      if (AllQuestionData.length > 0 && selectedLanguage.code !== "en") {
        translateDummyQuestions();
        console.log("Translation in progress...");
      } else if (AllQuestionData.length === 0) {
        setLoading(true);
        console.log("Waiting for data availability...");
        while (AllQuestionData.length === 0) {
          await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 1 second before checking again
        }
        setLoading(false);
      } else {
        setTranslated(AllQuestionData);
        setLoading(false);
        console.log("Data already available in English.");
      }
    };

    handleDataAvailability();
  }, [selectedLanguage, AllQuestionData]);

  useEffect(() => {
    // Update viewedQuestions based on pausedTimes and currentQuestionIndex
    setViewedQuestions((prevViewedQuestions) => {
      // Mark the clicked question as viewed
      const newViewedQuestions = [...prevViewedQuestions];
      newViewedQuestions[currentQuestionIndex] = true;
      return newViewedQuestions;
    });
  }, [currentQuestionIndex]);

  const [startTime, setStartTime] = useState("");

  useEffect(() => {
    const currentTime = new Date().toLocaleTimeString();
    setStartTime(currentTime);
  }, []);

  const [startDate, setStarDate] = useState("");
  useEffect(() => {
    const currentDate = new Date().toLocaleDateString();
    setStarDate(currentDate);
  }, []);

  const handleAvatarClick = (index) => {
    setCurrentQuestionIndex(index);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen1 = useMediaQuery(theme.breakpoints.down("sm"));

  //Drawer for right nav
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const toggleRightNav = () => {
    setIsRightNavOpen(!isRightNavOpen);
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  //To change the question by keeping the question for review
  const handleMarkForReview = () => {
    const newMarkedForReview = [...markedForReview];
    newMarkedForReview[currentQuestionIndex] = true;
    setMarkedForReview(newMarkedForReview);
  };

  // To save the selected  options
  const handleNext = () => {
    if (currentQuestionIndex === totalQuestions - 1) {
      alert("You have reached the last question!");
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleClearResponse = () => {
    const newSelectedOptions = [...selectedOptions];
    const newMarkedForReview = [...markedForReview];

    // Clear selected option for the current question
    newSelectedOptions[currentQuestionIndex] = "";
    setSelectedOptions(newSelectedOptions);

    // Remove question from review status
    newMarkedForReview[currentQuestionIndex] = false;
    setMarkedForReview(newMarkedForReview);
  };

  useEffect(() => {
    if (!isSmallScreen && open) {
      setOpen(false);
    }
  }, [isSmallScreen]);

  const history = useNavigate();

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // Fetch the answer array
      const response = await axios.get(
        baseURL + "/getAllAiGeneratedQuestionsquizanswer",
        {
          params: {
            questionIds: questionIds, // Assuming questionIds is available in your component
          },
        }
      );

      // Translate the answer array
      const answerarray = response.data;
      const translatedAnswerArray = await Promise.all(
        answerarray.map(async (answer) => {
          const translatedAnswer = await translateText(
            answer.Answer,
            selectedLanguage.code
          );
          return { ...answer, Answer: translatedAnswer };
        })
      );

      // Other operations
      const currentTime = new Date().toLocaleTimeString();
      const currentDate = new Date().toLocaleDateString();

      // Convert time strings to Date objects
      const startTime1 = new Date(`2000/01/01 ${startTime}`);
      const endTime1 = new Date(`2000/01/01 ${currentTime}`);

      // Calculate the duration in milliseconds
      const durationInMillis = endTime1 - startTime1;

      // Convert milliseconds to seconds
      const durationInSeconds = Math.floor(durationInMillis / 1000);

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = durationInSeconds % 60;

      // Format the result
      const TotalDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} minutes`;

      // Compare selected options with translated answers
      const resultArray = questionIds.map((questionId, index) => {
        const selectedOption = selectedOptions[index];
        const answer = translatedAnswerArray[index].Answer;
        return selectedOption === answer ? 1 : 0;
      });

      const isSubmitted = timeLeft === 0 ? false : true;

      const postData = {
        Testid: testinfo.id,
        TestName:testinfo.TestName,
        UserId: email,
        ExamStatus: [
          {
            SeletedOptions: selectedOptions,
            TestOuestionsIds: questionIds,
            TimeTakenForQuestions: pausedTimes,
            QuestionViewStatus: viewedQuestions,
            resultArray: resultArray,
            UserFeedback: reportDetailsArray,
            keypressCounter: keypressCounter,
          },
        ],
        Language: selectedLanguage.name,
        TestStarTime: startTime,
        TestStartDate: startDate,
        TestEndTime: currentTime,
        TestEndDate: currentDate,
        TotalDuration: TotalDuration,
        isSubmitted: isSubmitted,
      };

      await axios.post(baseURL + "/sendresult", postData);

      // Log and alert the results
      //console.log("postData", postData);
      customAlert("", "Test Result saved successfully", "success");

      if (document.fullscreenElement) {
        document.exitFullscreen();
      }

      // Route to dashboard
      history("/dashboard");
    } catch (error) {
      console.error("Error fetching data:", error);
      customAlert("", "Failed to save data", "error");
    } finally {
      setLoading(false); // Hide circular progress whether request is successful or not
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" size={28} />
          &nbsp;&nbsp;Loading... please wait
        </div>
      </div>
    );
  }

  return (
    <div className="background-colr-of-all-main-section">
      <div className="quiz-main-header-design">
        <div className="quiz-main-header-second-container">
          <div className="quizbashboard1-image-container-testname-container">
            <div className="quizdashboard1-image-conatiner">
              <DynamicImageLogo
                images={images}
                height={"45px"}
                width={"45px"}
              />
            </div>
            <div className="quizdashboard1-testname-alignment-and-color">
              <span>{testName}</span>
            </div>
          </div>

          <div className="quizbashboard1-timer-alignment-quiz-time">
            <QuizTime
              loadingTimeoff={loading}
              timeLeft={timeLeft}
              setTimeLeft={setTimeLeft}
              reportModalOpen={reportModalOpen}
            />
          </div>

          <div className="quizbashboard1-image-container-testname-container1">
            <div>
              <div>
                Language:
                <span className="quizdashboard1-testname-alignment-and-color">
                  {selectedLanguage.name}
                </span>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                id="button-zoom"
                color="success"
                onClick={handleSubmit}
              >
                <SendIcon className="buttonicons" />
                &nbsp; Submit
              </Button>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", height: "93vh" }}>
          <div
            style={{
              flex: isRightNavOpen ? "0 0 70%" : "1",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.4)",
              marginTop: "4px",
            }}
          >
            <Paper
              elevation={0}
              style={{
                height: isSmallScreen ? "auto" : "60vh",
              }}
            >
              <div>
                <QuizNav
                  questionIndex={currentQuestionIndex}
                  pausedTimes={pausedTimes}
                  loadingTimeoff={loading}
                  setPausedTimes={setPausedTimes}
                  totalQuestions={totalQuestions}
                  reportDetailsArray={reportDetailsArray}
                  setReportDetailsArray={setReportDetailsArray}
                  reportModalOpen={reportModalOpen}
                  setReportModalOpen={setReportModalOpen}
                  timerActive={timerActive}
                  setTimerActive={setTimerActive}
                />
              </div>
              {isSmallScreen && (
                <Tooltip title="Question Info" arrow>
                  <IconButton onClick={toggleDrawer}>
                    <MenuIcon style={{ color: "blue", top: 90 }} />
                  </IconButton>
                </Tooltip>
              )}

              {/* Drawer */}
              <Drawer anchor="right" open={open} onClose={toggleDrawer}>
                {/* Content inside the drawer */}
                <div style={{ width: 350 }}>
                  <IconButton
                    style={{ position: "absolute", top: 10, right: 10 }}
                    onClick={toggleDrawer}
                  >
                    <Close />
                  </IconButton>
                  {/* Right navigation */}
                  <QuizRightNav
                    selectedOptions={selectedOptions}
                    markedForReview={markedForReview}
                    currentQuestionIndex={currentQuestionIndex}
                    setSelectedOptions={setSelectedOptions}
                    handleAvatarClick={handleAvatarClick}
                    toggleRightNav={toggleRightNav}
                    isRightNavOpen={isRightNavOpen}
                    totalQuestions={totalQuestions}
                    closedNavStatus={closedNavStatus}
                    setClosedNavStatus={setClosedNavStatus}
                    viewedQuestions={viewedQuestions}
                    setViewedQuestions={setViewedQuestions}
                    email={email}
                  />
                </div>
              </Drawer>
              <Divider />
              <QuizQuestion
                questionIndex={currentQuestionIndex}
                markedForReview={markedForReview}
                selectedOptions={selectedOptions}
                questionIds={translated}
                setSelectedOptions={setSelectedOptions}
                toggleRightNav={toggleRightNav}
                isRightNavOpen={isRightNavOpen}
                reportModalOpen={reportModalOpen}
                setReportModalOpen={setReportModalOpen}
                reportDetailsArray={reportDetailsArray}
                setReportDetailsArray={setReportDetailsArray}
              />
            </Paper>

            {/* Navigation Buttons */}
            <div
              style={{
                display: "flex",
                gap: "7px",
                justifyContent: isSmallScreen ? "center" : "center",
                padding: "10px",
                width: "max-content",
                margin: "auto",
                borderTop: "1px solid rgb(216, 224, 240)",
              }}
            >
              <Button
                variant="contained"
                disabled={currentQuestionIndex === 0}
                onClick={handlePrevious}
                id="button-zoom"
                style={{
                  backgroundColor:
                    currentQuestionIndex === 0 ? "gray" : "green",
                  marginLeft: "10px",
                  color: currentQuestionIndex === 0 ? "black" : "white",
                }}
              >
                <Tooltip title="Previous" arrow>
                  <ArrowBackIcon className="buttonicons" />
                </Tooltip>
                {!isSmallScreen && (
                  <span
                    style={{
                      marginLeft: "5px",
                      opacity: currentQuestionIndex === 0 ? 0.5 : 1,
                    }}
                  >
                    Previous
                  </span>
                )}
              </Button>

              <Button
                variant="contained"
                onClick={handleMarkForReview}
                id="button-zoom"
                style={{
                  backgroundColor: "rgb(193, 100, 231)",
                  marginLeft: "5px",
                }}
              >
                <Tooltip title="Mark for Review" arrow>
                  <span>
                    <DoneAllIcon className="buttonicons" /> Mark for Review
                  </span>
                </Tooltip>
              </Button>

              <Button
                variant="contained"
                onClick={handleClearResponse}
                id="button-zoom"
                style={{
                  backgroundColor: "rgb(155, 39, 48)",
                  marginLeft: "5px",
                }}
              >
                <Tooltip title="Clear Answer" arrow>
                  <span>
                    <ClearIcon className="buttonicons" /> Clear Answer
                  </span>
                </Tooltip>
              </Button>

              <Button
                variant="contained"
                disabled={currentQuestionIndex === totalQuestions - 1}
                onClick={handleNext}
                id="button-zoom"
                style={{
                  backgroundColor:
                    currentQuestionIndex === totalQuestions - 1
                      ? "gray"
                      : "green",
                  marginLeft: "5px",
                  color:
                    currentQuestionIndex === totalQuestions - 1
                      ? "black"
                      : "white",
                }}
              >
                {!isSmallScreen && (
                  <span
                    style={{
                      marginRight: "5px",
                      opacity:
                        currentQuestionIndex === totalQuestions - 1 ? 0.5 : 1,
                    }}
                  >
                    Next
                  </span>
                )}
                <Tooltip title="Next" arrow>
                  <ArrowForwardIcon className="buttonicons" />
                </Tooltip>
              </Button>
            </div>
          </div>

          {/* Side nav to the right */}
          {!isSmallScreen && isRightNavOpen && (
            <div style={{ flex: "0 0 30%", position: "relative" }}>
              <Paper>
                <QuizRightNav
                  selectedOptions={selectedOptions}
                  markedForReview={markedForReview}
                  currentQuestionIndex={currentQuestionIndex}
                  setSelectedOptions={setSelectedOptions}
                  handleAvatarClick={handleAvatarClick}
                  toggleRightNav={toggleRightNav}
                  isRightNavOpen={isRightNavOpen}
                  totalQuestions={totalQuestions}
                  closedNavStatus={closedNavStatus}
                  setClosedNavStatus={setClosedNavStatus}
                  viewedQuestions={viewedQuestions}
                  setViewedQuestions={setViewedQuestions}
                  email={email}
                />
              </Paper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizMain;
