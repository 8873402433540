import React, { useState, useEffect, useRef } from "react";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
  FaSuperscript,
  FaSubscript,
} from "react-icons/fa";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CodeIcon from "@mui/icons-material/Code";
import AddLinkIcon from "@mui/icons-material/AddLink";
import AddPhotoAlternateSharpIcon from "@mui/icons-material/AddPhotoAlternateSharp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import { Tooltip } from "@material-ui/core";
import { SketchPicker } from "react-color";
import "./Editor.css";
import ButtonDropdown from "./ButtonDropDown";

const ToolBar = ({ windowWidth, 
  handleOpen, 
  handleImageInsert, 
  showHyperlink = true,
  showImageInsert = true,
  showCodeViewer = true,
  showButtonInsert = true, }) => {

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#000");
  const [selectionRange, setSelectionRange] = useState(null);
  const [currentProperty, setCurrentProperty] = useState(null);
  const colorPickerRef = useRef(null);

  const [isBoldActive, setIsBoldActive] = useState(false);
  const [isItalicActive, setIsItalicActive] = useState(false);
  const [isUnderlineActive, setIsUnderlineActive] = useState(false);
  const [isStrikethroughActive, setIsStrikethroughActive] = useState(false);
  const [isSubcriptActive, setIsSubscriptActive] = useState(false);
  const [isSupercriptActive, setIsSuperscriptActive] = useState(false);

  //-------------------------------------------------------------------------
  // Size of buttons and icons
  //-------------------------------------------------------------------------
  const getIconSize = () => {
    if (windowWidth < 400) return 10;
    if (windowWidth < 600) return 12;
    if (windowWidth < 800) return 15;
    return 20;
  };

  const getSmallIconSize = () => {
    if (windowWidth < 400) return 8;
    if (windowWidth < 600) return 10;
    if (windowWidth < 800) return 12;
    return 15;
  };

  const getButtonSize = () => {
    if (windowWidth < 400) return 20;
    if (windowWidth < 600) return 25;
    if (windowWidth < 800) return 28;
    return 30;
  };

  //-------------------------------------------------------------------------
  // selecting text
  //-------------------------------------------------------------------------

  const applyColorToSelection = (range, color, property) => {
    const containsOnlyTextNodes = Array.from(
      range.cloneContents().childNodes
    ).every((node) => node.nodeType === Node.TEXT_NODE);
    if (containsOnlyTextNodes) {
      const span = document.createElement("span");
      span.style[property] = color;
      range.surroundContents(span);
      range.collapse(true);
    } else {
      console.warn(
        "Selection contains non-Text nodes. Color cannot be applied."
      );
    }
  };

  //-------------------------------------------------------------------------
  //remove applied color
  //-------------------------------------------------------------------------

  const removeColorFromSelection = (range, property) => {
    const parentNode = range.startContainer.parentNode;
    if (parentNode && parentNode.style[property]) {
      parentNode.style[property] = "";
      if (!parentNode.style.color && !parentNode.style.backgroundColor) {
        const parent = parentNode.parentNode;
        while (parentNode.firstChild) {
          parent.insertBefore(parentNode.firstChild, parentNode);
        }
        parent.removeChild(parentNode);
      }
    }
  };

  //-------------------------------------------------------------------------
  //Apply color to selected
  //-------------------------------------------------------------------------

  const handleChangeColor = (newColor, property) => {
    setColor(newColor.hex);
    if (selectionRange) {
      applyColorToSelection(selectionRange, newColor.hex, property);
    }
    setShowColorPicker(false);
  };

  //-------------------------------------------------------------------------
  //Font color button handling
  //-------------------------------------------------------------------------

  const handleFontColorButtonClick = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setSelectionRange(range);
      const parentNode = range.startContainer.parentNode;
      if (parentNode && parentNode.style.color) {
        removeColorFromSelection(range, "color");
        setShowColorPicker(false);
      } 
      else {
        if (showColorPicker && currentProperty === "color") {
          setShowColorPicker(false);
        } else {
          setShowColorPicker(true);
          setCurrentProperty("color");
        }
      }
    }
  };

  //-------------------------------------------------------------------------
  //Highlighter button handling
  //-------------------------------------------------------------------------

  const handleBackgroundColorButtonClick = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setSelectionRange(range);
      const parentNode = range.startContainer.parentNode;
      if (parentNode && parentNode.style.backgroundColor) {
        removeColorFromSelection(range, "backgroundColor");
        setShowColorPicker(false);
      } else {
        if (showColorPicker && currentProperty === "backgroundColor") {
          setShowColorPicker(false);
        } else {
          setShowColorPicker(true);
          setCurrentProperty("backgroundColor");
        }
      }
    }
  };

  //-------------------------------------------------------------------------
  //InsertButtonClick_new
  //-------------------------------------------------------------------------
  const InsertButtonClick = (event) => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);

    const contentEditor = document.getElementById("edt");
    if (!contentEditor) return;

    const commonAncestor = range.commonAncestorContainer;
    if (!contentEditor.contains(commonAncestor)) {
      console.warn("Selection is not within the content editor.");
      return;
    }

    const ancestorElement =
      commonAncestor.nodeType === Node.ELEMENT_NODE
        ? commonAncestor
        : commonAncestor.parentElement;
    if (ancestorElement && ancestorElement.closest(".code-viewer-container")) {
      return;
    }

    const button = document.createElement("button");
    button.setAttribute("title", "Inserted Button");
    button.innerText = "Button";
    button.classList.add("dynamic-button");

    button.draggable = false;

    button.contentEditable = false;

    range.insertNode(button);

    let buttonLink = null;

    button.addEventListener("click", (event) => {
      if (buttonLink) {
        window.open(buttonLink, "_blank");
      } else {
        document.querySelectorAll(".dynamic-button.selected").forEach((el) => {
          el.classList.remove("selected");
        });

        button.classList.toggle("selected");
      }
    });

    document.body.addEventListener("click", (event) => {
      const isCodeViewerContainer = event.target.closest(".dynamic-button");
      if (!isCodeViewerContainer) {
        document.querySelectorAll(".dynamic-button.selected").forEach((el) => {
          el.classList.remove("selected");
        });
      }
    });

    button.addEventListener("contextmenu", (event) => {
      event.preventDefault();
      event.stopPropagation();

      const dialog = document.createElement("div");
      dialog.classList.add("dialog");

      // Option 1: Change button name
      const changeNameOption = document.createElement("div");
      changeNameOption.classList.add("dialog-item");
      changeNameOption.innerHTML =
        '<i class="fas fa-pencil-alt"></i> Change Name';
      changeNameOption.addEventListener("click", () => {
        let newName = prompt("Enter the new name for the button:");
        if (newName) {
          button.innerText = newName;
        }
        dialog.remove();
      });

      // Option 2: Add hyperlink
      const addLinkOption = document.createElement("div");
      addLinkOption.classList.add("dialog-item");
      addLinkOption.innerHTML = '<i class="fas fa-link"></i> Add Link';
      addLinkOption.addEventListener("click", () => {
        let url = prompt("Enter the Button URL:");
        if (url) {
          if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = "http://" + url;
          }

          buttonLink = url;
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          button.replaceWith(link);
          link.appendChild(button);
        }
        dialog.remove();
      });

      // Option 3: Delete button
      const deleteButtonOption = document.createElement("div");
      deleteButtonOption.classList.add("dialog-item");
      deleteButtonOption.innerHTML =
        '<i class="fas fa-trash-alt"></i> Delete Button';
      deleteButtonOption.addEventListener("click", () => {
        button.remove();
        dialog.remove();
      });

      dialog.appendChild(changeNameOption);
      dialog.appendChild(addLinkOption);
      dialog.appendChild(deleteButtonOption);

      //option 4: Navigate to link
      if (buttonLink) {
        const navigateToLinkOption = document.createElement("div");
        navigateToLinkOption.classList.add("dialog-item");
        navigateToLinkOption.innerHTML =
          '<i class="fas fa-external-link-alt"></i> Navigate to Link';
        navigateToLinkOption.addEventListener("click", () => {
          window.open(buttonLink, "_blank");
          dialog.remove();
        });

        //option 4: Edit link
        const editLinkOption = document.createElement("div");
        editLinkOption.classList.add("dialog-item");
        editLinkOption.innerHTML = '<i class="fas fa-edit"></i> Edit Link';
        editLinkOption.addEventListener("click", () => {
          let newUrl = prompt("Enter the new URL for the button:", buttonLink);
          if (newUrl) {
            if (
              !newUrl.startsWith("http://") &&
              !newUrl.startsWith("https://")
            ) {
              newUrl = "http://" + newUrl;
            }
            buttonLink = newUrl;
            const link = document.createElement("a");
            link.href = newUrl;
            link.target = "_blank";
            button.replaceWith(link);
            link.appendChild(button);
          }
          dialog.remove();
        });

        dialog.appendChild(navigateToLinkOption);
        dialog.appendChild(editLinkOption);
      }

      dialog.style.position = "absolute";
      dialog.style.left = `${event.pageX}px`;
      dialog.style.top = `${event.pageY}px`;

      document.body.appendChild(dialog);

      document.addEventListener("click", (event) => {
        if (!dialog.contains(event.target) && event.target !== button) {
          dialog.remove();
        }
      });
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Delete" && document.activeElement === button) {
        button.remove();
      }
    });
  };

  //-------------------------------------------------------------------------
  //handle Insert Link  in content Editor
  //-------------------------------------------------------------------------
  const insertLink = () => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) {
      alert("Please select the text you want to hyperlink.");
      return;
    }

    let url = prompt("Enter the URL:");
    if (url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }

      const range = selection.getRangeAt(0);
      const selectedText = range.extractContents();

      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.appendChild(selectedText);

      // Style the link with underline
      link.style.textDecoration = "underline";

      // Insert the link at the position of the selected text
      range.insertNode(link);

      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);

      link.addEventListener("click", (event) => {
        event.preventDefault();
        window.open(link.href, "_blank");
      });

      link.addEventListener("contextmenu", (event) => {
        event.preventDefault();
        showLinkContextMenu(event, link);
      });
    }
  };

  const showLinkContextMenu = (event, link) => {
    // Remove any existing context menu
    const existingMenu = document.querySelector(".link-context-menu");
    if (existingMenu) existingMenu.remove();

    // Create the context menu
    const menu = document.createElement("div");
    menu.classList.add("link-context-menu");
    menu.style.left = `${event.clientX}px`;
    menu.style.top = `${event.clientY}px`;

    // Option to navigate to the link
    const navigateOption = createMenuItem(
      "Navigate to Link",
      "fa-external-link-alt",
      "navigate-icon",
      () => {
        window.open(link.href, "_blank");
        menu.remove();
      }
    );

    // Option to edit the link
    const editLinkOption = createMenuItem(
      "Edit",
      "fa-edit",
      "edit-icon",
      () => {
        let newUrl = prompt("Enter the new URL for the link:", link.href);
        if (newUrl) {
          if (!newUrl.startsWith("http://") && !newUrl.startsWith("https://")) {
            newUrl = "http://" + newUrl;
          }
          link.href = newUrl;
        }
        menu.remove();
      }
    );

    // Option to delete the link
    const deleteLinkOption = createMenuItem(
      "Delete",
      "fa-trash-alt",
      "delete-icon",
      () => {
        const parent = link.parentNode;
        while (link.firstChild) {
          parent.insertBefore(link.firstChild, link);
        }
        link.remove();
        menu.remove();
      }
    );

    menu.appendChild(navigateOption);
    menu.appendChild(editLinkOption);
    menu.appendChild(deleteLinkOption);

    document.body.appendChild(menu);

    // Remove context menu on click outside
    document.addEventListener(
      "click",
      (e) => {
        if (!menu.contains(e.target)) {
          menu.remove();
        }
      },
      { once: true }
    );
  };

  const createMenuItem = (text, iconClass, iconColorClass, onClick) => {
    const item = document.createElement("div");
    item.classList.add("link-context-menu-item");
    item.addEventListener("click", onClick);

    const icon = document.createElement("i");
    icon.classList.add("fas", iconClass, iconColorClass);
    item.appendChild(icon);

    const label = document.createElement("span");
    label.textContent = text;
    item.appendChild(label);

    return item;
  };

  //-------------------------------------------------------------------------
  //Active states of button
  //-------------------------------------------------------------------------
  useEffect(() => {
    const updateButtonStates = () => {
      setIsBoldActive(document.queryCommandState("bold"));
      setIsItalicActive(document.queryCommandState("italic"));
      setIsUnderlineActive(document.queryCommandState("underline"));
      setIsStrikethroughActive(document.queryCommandState("strikethrough"));
      setIsSubscriptActive(document.queryCommandState("subscript"));
      setIsSuperscriptActive(document.queryCommandState("superscript"));
    };

    document.addEventListener("selectionchange", updateButtonStates);

    return () => {
      document.removeEventListener("selectionchange", updateButtonStates);
    };
  }, []);

  //-------------------------------------------------------------------------
  //Handle Bullet format
  //-------------------------------------------------------------------------
  const handleUnorderedListClick = () => {
    const contentEditor = document.getElementById("edt");
    contentEditor.focus();
    document.execCommand("insertUnorderedList");

    setTimeout(() => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        let node = range.startContainer;

        if (node.nodeType === Node.TEXT_NODE) {
          node = node.parentNode;
        }

        const ul = node.closest("ul");
        if (ul) {
          ul.classList.add("custom-bullets");
        }
      }
    }, 0);
  };

  // Close SketchPicker if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //-------------------------------------------------------------------------
  //Rendering buttons with tooltip
  //-------------------------------------------------------------------------
  const renderButtonWithTooltip = (title, onClick, icon, isActive) => (
    <Tooltip title={title} arrow>
      <button
        onClick={onClick}
        className={`ctrlActive ${isActive ? "active" : ""}`}
        style={{ width: getButtonSize(), height: getButtonSize() }}
      >
        {icon}
      </button>
    </Tooltip>
  );

  return (
    <div>
      <div className="buttonDiv">
        {renderButtonWithTooltip(
          "Bold",
          () => document.execCommand("bold", false, null),
          <FaBold style={{ fontSize: getSmallIconSize() }} />,
          isBoldActive
        )}
        {renderButtonWithTooltip(
          "Italic",
          () => document.execCommand("italic"),
          <FaItalic style={{ fontSize: getSmallIconSize() }} />,
          isItalicActive
        )}
        {renderButtonWithTooltip(
          "Underline",
          () => document.execCommand("underline"),
          <FaUnderline style={{ fontSize: getSmallIconSize() }} />,
          isUnderlineActive
        )}
        {renderButtonWithTooltip(
          "Strikethrough",
          () => document.execCommand("strikethrough"),
          <FaStrikethrough style={{ fontSize: getSmallIconSize() }} />,
          isStrikethroughActive
        )}
        {renderButtonWithTooltip(
          "Superscript",
          () => document.execCommand("superscript"),
          <FaSuperscript style={{ fontSize: getSmallIconSize() }} />,
          isSupercriptActive
        )}
        {renderButtonWithTooltip(
          "Subscript",
          () => document.execCommand("subscript"),
          <FaSubscript style={{ fontSize: getSmallIconSize() }} />,
          isSubcriptActive
        )}
        <ButtonDropdown
          windowWidth={windowWidth}
          handleUnorderedListClick={handleUnorderedListClick}
        />
        <div className="separator"></div>
        {showHyperlink &&
          renderButtonWithTooltip(
            "Hyperlink",
            insertLink,
            <AddLinkIcon style={{ fontSize: getIconSize() }} />
          )}
        {showImageInsert &&
          renderButtonWithTooltip(
            "Insert Image",
            handleImageInsert,
            <AddPhotoAlternateSharpIcon style={{ fontSize: getIconSize() }} />
          )}
        {showButtonInsert &&
          renderButtonWithTooltip(
            "Insert Button",
            InsertButtonClick,
            <AddBoxIcon style={{ fontSize: getIconSize() }} />
          )}
        {showCodeViewer &&
          renderButtonWithTooltip(
            "Insert Code Viewer",
            handleOpen,
            <CodeIcon style={{ fontSize: getIconSize() }} />
          )}
        {renderButtonWithTooltip(
          "Font Colour",
          handleFontColorButtonClick,
          <FormatColorTextIcon style={{ fontSize: getIconSize() }} />
        )}
        {renderButtonWithTooltip(
          "Background Colour",
          handleBackgroundColorButtonClick,
          <BorderColorIcon style={{ fontSize: getIconSize() }} />
        )}
        <div className="colorPicker" ref={colorPickerRef}>
          {showColorPicker && (
            <SketchPicker
              color={color}
              onChange={(newColor) =>
                handleChangeColor(newColor, currentProperty)
              }
              onChangeComplete={() => {
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(selectionRange);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ToolBar;
