import React, { useState, useEffect } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ContactEmergencyOutlinedIcon from "@mui/icons-material/ContactEmergencyOutlined";
import "./ResultDashboardPage.css";

function ResultTable({ filteredQuestions, ResultData }) {
  const [correctCount, setCorrectCount] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [notAnsweredCount, setNotAnsweredCount] = useState(0);

  const [questions, setQuestions] = useState([]);

  //--------------------------------------------------------------------------------
  // Rener the question and Result the data
  //--------------------------------------------------------------------------------
  useEffect(() => {
    // Parse the ExamStatus data
    const examStatus =
      typeof ResultData?.ExamStatus === "string"
        ? JSON.parse(ResultData.ExamStatus)
        : ResultData?.ExamStatus;

    if (filteredQuestions && examStatus && examStatus[0]) {
      const { resultArray, SeletedOptions, TimeTakenForQuestions } =
        examStatus[0];

      const formattedQuestions = filteredQuestions.map((question, index) => {
        const statusValue = resultArray[index];
        const seletedOption = SeletedOptions ? SeletedOptions[index] : null;
        const timeTaken = TimeTakenForQuestions[index];
        let status;
        let time = question.timeTaken || "--"; // Set time or "--" for unanswered questions
        let timeInSeconds = 0;

        let correctcount = 0;
        let Wrongcount = 0;
        let Notansweredcount = 0;
        if (timeTaken && typeof timeTaken === "object") {
          const { minutes, seconds } = timeTaken;
          timeInSeconds = minutes * 60 + seconds;
        }

        // Determine the question status based on SelectedOptions
        if (seletedOption === null || seletedOption === undefined) {
          status = "Unanswered";
          Notansweredcount += 1;
        } else if (statusValue === 1) {
          status = "Correct";
          correctcount += 1;
        } else if (statusValue === 0) {
          status = "Wrong";
          Wrongcount += 1;
        }

        return {
          id: index + 1,
          question: question.Questions,
          status: status,
          time: timeInSeconds,
          difficulty: question.DifficultyLevels,
        };
      });

      // Calculate the counts based on filtered questions
      const correct = formattedQuestions.filter(
        (q) => q.status === "Correct"
      ).length;
      const wrong = formattedQuestions.filter(
        (q) => q.status === "Wrong"
      ).length;
      const notAnswered = formattedQuestions.filter(
        (q) => q.status === "Unanswered"
      ).length;

      // Set the counts to state
      setCorrectCount(correct);
      setWrongCount(wrong);
      setNotAnsweredCount(notAnswered);

      // Set formatted questions
      setQuestions(formattedQuestions);
    }
  }, [filteredQuestions, ResultData?.ExamStatus]);

  return (
    <div className="result-table-container">
      {/* Header Section */}
      <div className="header-container">
        <div className="header-item">
          <div>
            <HelpOutlineIcon className="icon-height-and-color-alignmnet total-questions" />
          </div>
          &nbsp;
          <div className="text-and-items-render-fontsize">
            <span>Total Questions</span>
            <span className="text-and-items-render-fontsize-render-data">
              {filteredQuestions?.length || "NA"}
            </span>
          </div>
        </div>
        <div className="header-item">
          <div>
            <TimelapseIcon className="icon-height-and-color-alignmnet total-time " />
          </div>
          &nbsp;
          <div className="text-and-items-render-fontsize">
            <span>Total Time Taken</span>
            <span className="text-and-items-render-fontsize-render-data">
              {ResultData?.TotalTimeTaken?.replace("minutes", "").trim() ||
                "NA"}
            </span>
          </div>
        </div>
        <div className="header-item">
          <div>
            <ThumbUpOffAltIcon className="icon-height-and-color-alignmnet correct" />
          </div>
          &nbsp;
          <div className="text-and-items-render-fontsize">
            <span>Correct</span>
            <span className="text-and-items-render-fontsize-render-data">
              {correctCount}
            </span>
          </div>
        </div>
        <div className="header-item">
          <div>
            <DangerousIcon className="icon-height-and-color-alignmnet wrong" />
          </div>
          &nbsp;
          <div className="text-and-items-render-fontsize">
            <span>Wrong</span>
            <span className="text-and-items-render-fontsize-render-data">
              {wrongCount}
            </span>
          </div>
        </div>
        <div className="header-item">
          <div>
            <DashboardOutlinedIcon className="icon-height-and-color-alignmnet not-answered" />
          </div>
          &nbsp;
          <div className="text-and-items-render-fontsize">
            <span>Unanswered</span>
            <span className="text-and-items-render-fontsize-render-data">
              {notAnsweredCount}
            </span>
          </div>
        </div>
        <div className="header-item">
          <div>
            <ContactEmergencyOutlinedIcon className="icon-height-and-color-alignmnet total-score" />
          </div>
          &nbsp;
          <div className="text-and-items-render-fontsize">
            <span>Total Score</span>
            <span className="text-and-items-render-fontsize-render-data">
              {correctCount}
            </span>
          </div>
        </div>
      </div>
      {/* Table Section */}
      <div className="table-container">
        <table className="result-table">
          <thead>
            <tr>
              <th>Sl.No</th>
              <th>Question</th>
              <th>Status</th>
              <th>Time(sec)</th>
              <th>Difficulty</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((q) => (
              <tr key={q.id}>
                <td>{q.id}</td>
                <td>{q.question}</td>
                <td
                  className={
                    q.status === "Correct"
                      ? "status-correct"
                      : q.status === "Wrong"
                      ? "status-wrong"
                      : "status-unanswered"
                  }
                >
                  {q.status}
                </td>
                <td>{q.time}</td>
                <td
                  className={
                    q.difficulty === "Easy"
                      ? "status-easy"
                      : q.difficulty === "Medium"
                      ? "status-Medium"
                      : "status-hard"
                  }
                >
                  {q.difficulty}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ResultTable;
