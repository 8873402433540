import React, { useState } from "react";
import {
  Chip,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  Avatar,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";

function InstructorCardView({ SelectedTest, setSelectedTest }) {
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  //--------------------------------------------------------------------------------
  // Function to handle deleting a test from SelectedTest
  //--------------------------------------------------------------------------------
  const handleDeleteTest = (test) => {
    const updatedSelectedTest = SelectedTest.filter((item) => item !== test);
    setSelectedTest(updatedSelectedTest);
    setSnackbarMessage("Instructor removed successfully!");
    setOpenSnackbar(true); // Show snackbar after deletion
  };

  //--------------------------------------------------------------------------------
  // Handle drag and drop reorder
  //--------------------------------------------------------------------------------
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedTests = Array.from(SelectedTest); // Ensure SelectedTest is being reordered
    const [movedTest] = reorderedTests.splice(result.source.index, 1);
    reorderedTests.splice(result.destination.index, 0, movedTest);
    setSelectedTest(reorderedTests); // Update test order
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tests">
          {(provided) => (
            <div
              className="accordion-container-add-totest"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {SelectedTest &&
                SelectedTest.map((Test, index) => (
                  <Draggable
                    key={Test.id}
                    draggableId={String(Test.id)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="all-component-render-page-outer-container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="accordion-summary-of-the-question-display-instructor-card">
                          <Tooltip title={Test.instructorEmail} arrow>
                            <Avatar className="instructorcardview1-avatar-bakground-color">
                              {Test.instructorName.charAt(0).toUpperCase()}
                            </Avatar>
                          </Tooltip>
                          {Test.instructorName}
                        </div>
                        <div className="Testcardview1-general-info-alignemnt">
                          <div className="testcardview1-icon-and-text-alignment">
                            <WorkHistoryOutlinedIcon className="buttonicons-test-card-view1" />
                            &nbsp;Year of Experience:
                            <span className="Test-card-view1-text-color">
                              &nbsp; {Test.yearOfExperience}
                            </span>
                          </div>
                          <div className="testcardview1-icon-and-text-alignment">
                            <SupervisorAccountOutlinedIcon className="buttonicons-test-card-view1" />
                            &nbsp;Year of Teaching Experience:{" "}
                            <span className="Test-card-view1-text-color">
                              &nbsp;{Test.teachingExperience}
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="add-icon-and-right-icon-placement">
                            <Chip
                              sx={{ backgroundColor: "green" }}
                              className="difficulty-level-shown-color-font-test-card-view"
                              label={Test.designation}
                            />

                            {/* Delete Icon */}
                            <Tooltip title="Remove Instructor" arrow>
                              <IconButton
                                className="icon-button-to-disapay-add-button"
                                onClick={() => handleDeleteTest(Test)}
                              >
                                <RemoveCircleIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* ___________snackbar for small notification____________ */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}

export default InstructorCardView;
