import React, { useState, useEffect } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from '@mui/icons-material/Update';
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../http";
import Autocomplete from '@mui/material/Autocomplete';
import './modaltemplatepage.css';

function AddReceiptModalPage({ open, close, receiptFormData, setReceiptFormData,SetDataBaseUpdate }) {
  // State Handles
  const [errors, setErrors] = useState({
    studentName: false,
    email: false,
    courseCategory: false,
    paidDate: false,
    paidThrough: false,
    amount: false,
  });
  const [users, setUsers] = useState([]);
  const [courseOptions, setCourseOptions] = useState(['']);
  // Fetching the Students names and emails to dropdown
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${baseURL}/getLoginTable`);
        const filteredUsers = response.data
          .filter((user) => user.usertype == "student")
          .map((user) => ({ name: user.name, email: user.email }));
        setUsers(filteredUsers);
      } catch (error) {
        console.log("Error fetching login data:", error);
      }
    };

    fetchUser();

    const fetchTpaCourses = async () => {
    try{
      const response = await axios.get(`${baseURL}/getUniqueCourseNames`);
      const fetchedCourses = response.data.map(course => ({ label: course }));
      setCourseOptions(fetchedCourses);
    }  catch (error){
      console.error("Error fetching courses", error);
    }
    }
    fetchTpaCourses();
  }, [open]);

  // Handles the text field On-Change
  const handleTextFieldChange = (event, fieldName) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    setReceiptFormData({ ...receiptFormData, [fieldName]: value });
    setErrors({ ...errors, [fieldName]: false });
  };

  // Handles the change for both name and email fields
  const handleUserChange = (newValue, fieldName) => {
    if (newValue) {
      let selectedUser;
      if (fieldName === 'studentName') {
        selectedUser = users.find(user => user.name === newValue);
      } else if (fieldName === 'email') {
        selectedUser = users.find(user => user.email === newValue);
      }

      if (selectedUser) {
        setReceiptFormData({
          ...receiptFormData,
          studentName: selectedUser.name,
          email: selectedUser.email
        });
      }
    } else {
      setReceiptFormData({
        ...receiptFormData,
        studentName: '',
        email: ''
      });
    }
  };

  const emailOptions = users.map(user => ({ label: user.email }));
  const nameOptions = users.map(user => ({ label: user.name }));

  const paidThroughOptions = [
    { label: 'Online' },
    { label: 'Cash' },
  ];
  

  // Handle Save to API with Data-Validation
  const handleSave = async () => {
    const requiredFields = [
      "studentName",
      "email",
      "courseCategory",
      "paidDate",
      "paidThrough",
      "amount",
    ];

    let valid = true;
    const newErrors = { ...errors };

    for (const field of requiredFields) {
      if (!receiptFormData[field]) {
        newErrors[field] = true;
        valid = false;
      }
    }

    setErrors(newErrors);

    if (!valid) {
      customAlert("", "Please fill mandatory fields correctly.", "warning");
      return;
    }

    try {
      let response;
      if (receiptFormData.editItemID) {
        response = await axios.put(baseURL + "/Reciept_edit", receiptFormData);
      } else {
        response = await axios.post(baseURL + "/Reciept", receiptFormData);
      }
  
      if (response.status === 200) {
        if (response.data.status === 'exists') {
          customAlert("", `Receipt for student ${receiptFormData.studentName} in course ${receiptFormData.courseCategory} already exists.`, "warning");
        } else {
          if (receiptFormData.editItemID) {
            customAlert("", "Receipt Edited", "success");
          } else {
            customAlert("", "Receipt Added", "success");
          }
          SetDataBaseUpdate(true);
          close();
        }
      }
    } catch (error) {
      customAlert("", "Receipt Upload Failed", "error");
      console.error("Error uploading receipt:", error);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={close} className="Modal-screen-center-alignment">
        <div className="modal-container">
          <div className="modal-inside-content-container">
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={close}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>
            <div className="modal-header-and-switch-container">
              <h5 className="modal-header-name">{receiptFormData.editItemID ? 'Edit' : 'Add'} Receipt Data</h5>
            </div>
            <div className="modal-grid-textfield-dropdown-container">

              <Autocomplete
                disablePortal
                id="combo-box-email"
                options={emailOptions}
                sx={{ width: '100%' }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Email"
                    error={errors.email}
                  />
                }
                value={emailOptions.find(option => option.label === receiptFormData.email) || null}
                onChange={(event, newValue) => handleUserChange(newValue?.label || '', "email")}
              />

              <Autocomplete
                disablePortal
                id="combo-box-name"
                options={nameOptions}
                sx={{ width: '100%' }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Student Name"
                    error={errors.studentName}
                  />
                }
                value={nameOptions.find(option => option.label === receiptFormData.studentName) || null}
                onChange={(event, newValue) => handleUserChange(newValue?.label || '', "studentName")}
              />

              <Autocomplete
                disablePortal
                id="combo-box-course"
                options={courseOptions}
                sx={{ width: '100%' }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Course Category"
                    error={errors.courseCategory}
                  />
                }
                value={courseOptions.find(option => option.label === receiptFormData.courseCategory) || null}
                onChange={(event, newValue) => handleTextFieldChange(newValue?.label || '', "courseCategory")}
              />

              <TextField
                required
                label="Paid Date"
                type="date"
                fullWidth
                value={receiptFormData.paidDate}
                onChange={(event) => handleTextFieldChange(event, "paidDate")}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { style: { textTransform: 'uppercase' } }, // Ensure input value is also uppercase
                }}
                error={errors.paidDate}  // if true - red box
              />
              <Autocomplete
                disablePortal
                id="combo-box-paid-through"
                options={paidThroughOptions}
                sx={{ width: '100%' }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Paid Through"
                    error={errors.paidThrough}
                  />
                }
                value={paidThroughOptions.find(option => option.label === receiptFormData.paidThrough) || null}
                onChange={(event, newValue) => handleTextFieldChange(newValue?.label || '', "paidThrough")}
              />

              <TextField
                label="Amount"
                type="number"
                name="amount"
                value={receiptFormData.amount}
                onChange={(event) => handleTextFieldChange(event, "amount")}
                fullWidth
                required
                error={errors.amount}  // if true - red box
              />
            </div>
            <div className="Save-Button-Alignment-container">
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleSave}
              >
                {receiptFormData.editItemID ? <UpdateIcon className="buttonicons"/> :
                <SaveIcon className="buttonicons" /> }
                {receiptFormData.editItemID ? 'Update': 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddReceiptModalPage;
