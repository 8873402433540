import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import ClearIcon from "@mui/icons-material/Clear";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import axios from "axios";
import { baseURL } from "../../http";
import {
  IconButton,
  Radio,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  MenuItem,
  Select,
  InputLabel,
  Button,
} from "@mui/material";

function FilterModal({
  openFilter,
  closefilter,
  loginData,
  setFilteredData,
  setClearFilterColor,
}) {
  const [batchInfo, setBatchInfo] = useState([]);
  const [testInfo, setTestInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedUserTypes, setSelectedUserTypes] = useState({
    student: false,
    parent: false,
    teacher: false,
    admin: false,
    general: false,
  });

  const [blockedStatus, setBlockedStatus] = useState({
    blockedUsers: null,
    unblockedUsers: null,
  });

  const [searchType, setSearchType] = useState("Test");
  const [searchValue, setSearchValue] = useState("");
  const [selectedDateOption, setSelectedDateOption] = useState("");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  //_____________dropdown fro the Date of Joining________________//

  const dateOptions = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 15 days",
    "Last 30 days",
    "Custom Date",
  ];

  //_____________________________end________________________//

  //___________________________Fetch the BatchInfo and TestInfo______________//

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true); // Set loading to true while fetching data
  //       const batchResponse = await axios.get(baseURL + "/getbatchinfo");
  //       const testResponse = await axios.get(`${baseURL}/gettestgeninfo`);
  //       setBatchInfo(batchResponse.data);
  //       setTestInfo(testResponse.data);
  //       setLoading(false); // Set loading to false when data is fetched successfully
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       fetchData(); // Set loading to false in case of error
  //     }
  //   };

  //   if (openFilter && (batchInfo.lenght === 0 || testInfo.length === 0)) {
  //     fetchData();
  //   }
  // }, [openFilter]);

  //______________________________end_________________________________//

  //________________________Handle the custom Date and dropdown select_____________//

  const handleDateOptionChange = (event) => {
    setSelectedDateOption(event.target.value);
    if (event.target.value !== "Custom Date") {
      setCustomStartDate("");
      setCustomEndDate("");
    }
  };

  //______________________end______________________________//

  //________________________Handle the Test and batch select____________//

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchValue(""); // Reset search value when search type changes
  };
  //________________________________end________________________________//

  //___________________Search the Dropdown options_____________________//
  const handleSearchValueChange = (event, newValue) => {
    setSearchValue(newValue);
  };

  //________________________________end_______________________________//

  //_____________________________Handle the filter_____________________//

  const handleApplyFilter = () => {
    const now = new Date();
    let startDate = null;
    let endDate = null;
  
    switch (selectedDateOption) {
      case "Today":
        startDate = new Date(now);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(now);
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Yesterday":
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
        break;
      case "Last 7 days":
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0, 0);
        endDate = now;
        break;
      case "Last 15 days":
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 15);
        startDate.setHours(0, 0, 0, 0);
        endDate = now;
        break;
      case "Last 30 days":
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 30);
        startDate.setHours(0, 0, 0, 0);
        endDate = now;
        break;
      case "Custom Date":
        startDate = new Date(customStartDate);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(customEndDate);
        endDate.setHours(23, 59, 59, 999);
        break;
      default:
        startDate = null;
        endDate = null;
    }
  
    const filteredData = loginData.filter((user) => {
      const userType = user.usertype?.toLowerCase();
      const isBlocked = user.isBlocked === 1;
      const userCreatedDate = user.created_at ? new Date(user.created_at.slice(0, 10)) : null;
  
      const isDateInRange = userCreatedDate && startDate && endDate
        ? userCreatedDate >= startDate && userCreatedDate <= endDate
        : true;
  
      const isBlockedMatch = (blockedStatus.blockedUsers && isBlocked) ||
        (blockedStatus.unblockedUsers && !isBlocked) ||
        (blockedStatus.blockedUsers === null && blockedStatus.unblockedUsers === null);
  
      const isUserTypeMatch = selectedUserTypes[userType] || Object.values(selectedUserTypes).every((value) => !value);
  
      const isBatchMatch = () => {
        const selectedBatch = batchInfo.find((batch) => batch.BatchName === searchValue);
        if (!selectedBatch) return true;
  
        const enrolledStudents = JSON.parse(selectedBatch.StudentEnrolled || "[]");
        return enrolledStudents.includes(user.id);
      };
  
      const isTestMatch = () => {
        const selectedTest = searchValue;
        const batchWithTest = batchInfo.find((batch) => {
          const testsArray = JSON.parse(batch.Tests || "[]");
          return testsArray.includes(selectedTest);
        });
        if (!batchWithTest) return true;
  
        const enrolledStudents = JSON.parse(batchWithTest.StudentEnrolled || "[]");
        return enrolledStudents.includes(user.id);
      };
  
      if (searchType === "Batch") {
        return isBatchMatch() && isBlockedMatch && isUserTypeMatch && isDateInRange;
      }
  
      if (searchType === "Test") {
        return isTestMatch() && isBlockedMatch && isUserTypeMatch && isDateInRange;
      }
  
      return isBlockedMatch && isUserTypeMatch && isDateInRange;
    });
  
    setFilteredData(filteredData);
    setClearFilterColor(true);
    closefilter();
  };
  
  
  //_____________________________end_______________________________//

  //_________________________clear the filter____________________//

  const handleClearFilter = () => {
    setFilteredData(loginData);
    setClearFilterColor(false);
    setSelectedUserTypes({
      student: false,
      parent: false,
      teacher: false,
      admin: false,
      general: false,
    });
    setBlockedStatus({
      blockedUsers: null,
      unblockedUsers: null,
    });
    setSearchType("Test");
    setSearchValue("");
    setSelectedDateOption("");
    setCustomStartDate("");
    setCustomEndDate("");
  };
  //________________________end_______________________________//

  return (
    <Modal
      open={openFilter}
      onClose={closefilter}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="filtermodaloutercontainer"
    >
      <div className="filetermodaloutercontainer">
        {/* _______________Header and close icon section_______ */}
        <div className="filtermodalcontent">
          <div>
            <IconButton
              id="button-zoom"
              className="EducationalTableViewmodalcloseicon"
              onClick={closefilter}
            >
              <CloseIcon className="EducationalTableViewmodalcloseiconX" />
            </IconButton>
          </div>
          <div className="filterheaderdiv">
            <h5 className="filterheader"> Filter</h5>
          </div>
          {/* __________________________end_____________________ */}
          {/* _________________________loading container__________ */}
          <div>
            {loading && (
              <div className="loading-container">
                <p>Loading...</p>
              </div>
            )}
            {/* ________________________end__________________________ */}
            {!loading && (
              <div className="filterscrollcontainer">
                {/* ________________________usertype sections_____________ */}
                <div className="filterscrollcontainerusertype">
                  <h6 className="headerfontweight">User Type</h6>
                  {Object.entries(selectedUserTypes).map(([key, value]) => (
                    <div key={key}>
                      <Checkbox
                        checked={value}
                        onChange={(e) =>
                          setSelectedUserTypes({
                            ...selectedUserTypes,
                            [key]: e.target.checked,
                          })
                        }
                      />
                      <span className="fontcolor">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </span>
                      <br />
                    </div>
                  ))}
                </div>
                {/* ___________________________end______________________ */}
                <div className="filterscrollcontainerusertype">
                  {/* _________________Blocked status___________________ */}
                  <h6 className="headerfontweight">Blocked Status</h6>
                  {Object.entries(blockedStatus).map(([key, value]) => (
                    <div key={key}>
                      <Checkbox
                        checked={value}
                        onChange={(e) =>
                          setBlockedStatus({
                            ...blockedStatus,
                            [key]: e.target.checked ? true : null,
                          })
                        }
                      />
                      <span className="fontcolor">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </span>
                      <br />
                    </div>
                  ))}
                </div>
                {/* _____________________end______________________ */}
                {/* ___________________________Batch and test____________ */}
                {/* <div className="filterscrollcontainerusertype">
                  <FormControl component="fieldset">
                    <h6 className="headerfontweight">User In</h6>
                    <RadioGroup
                      row
                      value={searchType}
                      onChange={handleSearchTypeChange}
                    >
                      <FormControlLabel
                        value="Test"
                        control={<Radio />}
                        label="Test"
                      />
                      <FormControlLabel
                        value="Batch"
                        control={<Radio />}
                        label="Batch"
                      />
                    </RadioGroup>
                  </FormControl>
                  <br />
                  <div className="dropdownmargin">
                    {searchType === "Test"
                      ? testInfo && (
                          <Autocomplete
                            options={testInfo.map((test) => test.TestName)}
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`Search ${searchType}`}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        )
                      : batchInfo && (
                          <Autocomplete
                            options={batchInfo.map((batch) => batch.BatchName)}
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`Search ${searchType}`}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        )}
                  </div>
                </div> */}
                {/* _______________________end_________________________ */}
                {/* __________________________Date of Joining______________ */}
                <div className="filterscrollcontainerusertype">
                  <h6 className="headerfontweight">Date of Joining</h6>
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="date-option-label">
                      Select Date Option
                    </InputLabel>
                    <Select
                      labelId="date-option-label"
                      value={selectedDateOption}
                      onChange={handleDateOptionChange}
                      label="Select Date Option"
                    >
                      {dateOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedDateOption === "Custom Date" && (
                    <div className="date-picker-container">
                      <TextField
                        label="Start Date"
                        type="date"
                        value={customStartDate}
                        onChange={(e) => setCustomStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                      <TextField
                        label="End Date"
                        type="date"
                        value={customEndDate}
                        onChange={(e) => setCustomEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* ___________________________end____________________________ */}
            {/* ____________________________Button Section_________________ */}
            <div className="filtermodalbuttons">
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleClearFilter}
              >
                <ClearIcon className="buttonicons" />
                Clear Filter
              </Button>
              &nbsp;&nbsp;
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleApplyFilter}
              >
                <PanToolAltIcon className="buttonicons" />
                Apply Filter
              </Button>
            </div>
            {/* _______________________________end____________________ */}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FilterModal;
