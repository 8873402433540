import React, { useEffect } from 'react';
import { Modal, CircularProgress } from '@mui/material'; // Import CircularProgress
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { baseURL } from '../../../http';
import axios from 'axios';
import ProcessProgressCircle from '../../ProcessProgressCircle/ProcessProgressCircle';

function DownloadAllEmployeesInfo({ open, SetOpen }) {
    const [employeeData, setEmployeeData] = React.useState([]);
    const [loading, setLoading] = React.useState(false); // Loading state

    useEffect(() => {
        if (open) {
            fetchEmployeeData();
        }
    }, [open]);

    const fetchEmployeeData = async () => {
        try {
            const response = await axios.get(`${baseURL}/Employee_Data_fetch_all`);
            setEmployeeData(response.data);
            handleDownloadExcelAndFiles(response.data); // Pass data to download function
            SetOpen(false);
        } catch (error) {
            console.error('Error fetching employee data:', error.response ? error.response.data : error.message);
        }
    };

    const handleDownloadExcelAndFiles = async (data) => {
        setLoading(true); // Set loading to true when starting the download
        const zip = new JSZip();

        for (const editData of data) {
            // Prepare the data for the Excel file
            const excelData = [
                Object.keys(editData), // First row with keys
                Object.values(editData), // Second row with values
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(excelData); // Create a worksheet from the 2D array

            // Apply styles to the first column
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let row = range.s.r; row <= range.e.r; row++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: 0 }); // First column (index 0)
                if (!ws[cellAddress]) continue; // Skip if cell doesn't exist
                ws[cellAddress].s = {
                    fill: {
                        fgColor: { rgb: "0000FF" }, // Blue color
                    },
                    font: {
                        color: { rgb: "FFFFFF" }, // Optional: white text color for contrast
                    },
                };
            }

            XLSX.utils.book_append_sheet(wb, ws, "Employee Data");

            // Convert workbook to binary string
            const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
            const excelBlob = new Blob([s2ab(wbBinary)], { type: "application/octet-stream" });

            // Create a main folder for the employee
            const mainFolder = zip.folder(`${editData.EmployeeID}_${editData.PersonalMailId}`);

            // Add the Excel file to the main folder
            mainFolder.file(`${editData.EmployeeID}_${editData.PersonalMailId}.xlsx`, excelBlob);

            // Create a folder for documents inside the main folder
            const docFolder = mainFolder.folder("Documents");

            // Document fields
            const documentFields = [
                'AadhaarDocument',
                'Photo',
                'PanDocument',
                'PfAccountDocument',
                'TenthMarksCard',
                'PreUGMarksCard',
                'UGMarksCard',
                'CompletionCertificate',
                'Resume',
            ];

            // Download each specified document file and add to the zip
            for (const field of documentFields) {
                const value = editData[field];
                if (typeof value === "string" && value) {
                    const filename = value.split('/').pop(); // Extract file name from URL
                    const email = editData.PersonalMailId; // Use the actual email from editData
                    const apiUrl = `${baseURL}/download-document/${encodeURIComponent(email)}/${encodeURIComponent(filename)}`; // Ensure URL is encoded

                    try {
                        const response = await axios.get(apiUrl, { responseType: 'blob' });
                        // Add the file to the documents folder in the main folder
                        docFolder.file(filename, response.data);
                    } catch (error) {
                        console.error('Error downloading file:', error.response ? error.response.data : error.message);
                    }
                }
            }
        }

        // Generate the zip file
        zip.generateAsync({ type: "blob" }).then(function(content) {
            // Save the zip file
            saveAs(content, `Employee_Data.zip`);
            setLoading(false); // Set loading to false after download is complete
        });
    };

    // Function to convert string to array buffer
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    };

    return (
        <>
            
                
                      
                        {loading &&  
                            <ProcessProgressCircle RequiredProgressName= 'Downloading employee documents...' /> 
                        }
                    
                   
              
        </>
    );
}

export default DownloadAllEmployeesInfo;
