import React, { useState } from "react";
import {
  Chip,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  Avatar,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";

function InstructorCardView1({ SelectedTest, setSelectedTest, Testall }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //--------------------------------------------------------------------------------
  // Function to handle the selection of a  question
  //--------------------------------------------------------------------------------
  const handleAddQuestion = (question) => {
    if (SelectedTest.includes(question)) {
      // If the question is already selected, remove it from selectedQuestions
      setSelectedTest((prevSelected) =>
        prevSelected.filter((q) => q !== question)
      );
    } else {
      // If it's not selected, add it to the top of selectedQuestions and show Snackbar
      setSelectedTest((prevSelected) => [question, ...prevSelected]); // Add to the top
      setSnackbarOpen(true); // Open Snackbar
    }
  };

  //--------------------------------------------------------------------------------
  // Handle the "Done" action to move the question to the top
  //--------------------------------------------------------------------------------
  const handleMarkAsDone = (question) => {
    setSelectedTest((prevSelected) => {
      // Remove the question from the current position
      const updatedSelected = prevSelected.filter((q) => q !== question);
      // Add it back at the top
      return [question, ...updatedSelected];
    });
  };

  //--------------------------------------------------------------------------------
  // Handle closing the Snackbar
  //--------------------------------------------------------------------------------
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Sort rows to bring selected questions to the top
  // Sort rows to bring selected questions to the top
  // const sortedRows = Array.isArray(rows) && rows.length > 0
  //   ? [...rows].sort((a, b) => {
  //       const isASelected = selectedQuestions.includes(a);
  //       const isBSelected = selectedQuestions.includes(b);
  //       return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
  //     })
  //   : []; // Return an empty array if rows is empty or not an array

  return (
    <>
      <div>
        <div className="accordion-container-add-totest">
          {Testall &&
            Testall.map((Test, index) => (
              <div
                key={index}
                className="all-component-render-page-outer-container"
              >
                <div className="accordion-summary-of-the-question-display-instructor-card">
                  <Tooltip title={Test.instructorEmail} arrow>
                    <Avatar className="instructorcardview1-avatar-bakground-color">
                      {Test.instructorName.charAt(0).toUpperCase()}
                    </Avatar>
                  </Tooltip>
                  {Test.instructorName}
                </div>
                <div className="Testcardview1-general-info-alignemnt">
                  <div className="testcardview1-icon-and-text-alignment">
                    <WorkHistoryOutlinedIcon className="buttonicons-test-card-view1" />
                    &nbsp;Year of Experience:
                    <span className="Test-card-view1-text-color">
                      &nbsp; {Test.yearOfExperience}
                    </span>
                  </div>
                  <div className="testcardview1-icon-and-text-alignment">
                    <SupervisorAccountOutlinedIcon className="buttonicons-test-card-view1" />
                    &nbsp;Year of Teaching Experience:{" "}
                    <span className="Test-card-view1-text-color">
                      &nbsp;{Test.teachingExperience}
                    </span>
                  </div>
                </div>
                <div>
                  {/* Expanded details for each question */}
                  <div>
                    <div className="add-icon-and-right-icon-placement">
                      <Chip
                        className="difficulty-level-shown-color-font-test-card-view"
                        label={Test.designation}
                      />

                      {SelectedTest.includes(Test) ? (
                        <Tooltip title="Navigate the Instructor" arrow>
                          <IconButton
                            className="icon-button-to-disapay-done-button"
                            onClick={() => handleMarkAsDone(Test)} // Mark as done
                          >
                            <DoneAllIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add Instructor to Batch" arrow>
                          <IconButton
                            className="icon-button-to-disapay-add-button"
                            onClick={() => handleAddQuestion(Test)}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* _________________sancks bar for small notifiction______________ */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={500}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">
          Instructor added to Batch successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

export default InstructorCardView1;
