import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Tooltip } from "@mui/material";
import "../batchmanagement.css";
import axios from "axios";
import { baseURL } from "../../../http";
import ManageBatchStudent from "./ManageBatchStudent";

function ManageBatchStudentLandingPage({
  handleManageStudentViewClose,
  editData,
  SetDataBaseUpdate,
}) {
  const [data, setData] = useState([]);

  //--------------------------------------------------------------------------------
  // Api call to fetch the user data
  //--------------------------------------------------------------------------------
  const fetchTestGenInfoname = async () => {
    try {
      const response = await axios.get(`${baseURL}/getLoginTable`);

      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch test info, retrying...", error);
      fetchTestGenInfoname();
    }
  };

  useEffect(() => {
    fetchTestGenInfoname();
  }, []);

  return (
    <>
      <div className="createtestgeneralinfoemarionsmalldiv">
        <div className="edit-icon-alignemnt">
          <Tooltip title="Back to Home" arrow>
            <IconButton onClick={handleManageStudentViewClose}>
              <ArrowBackIcon className="edit-icon-color-and-fontsize-generalinfo" />
            </IconButton>
          </Tooltip>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            <span className="info-dispaly-font-color">
              Manage Students-{editData.BatchName}
            </span>
          </div>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            {/* <DownloadButton filteredTestData={selectedBatchTest} BatchName={editData.BatchName}/> */}
          </div>
        </div>
      </div>
      {/* ______________manage batch student component call___________ */}
      <ManageBatchStudent
        renderRows={data}
        editData={editData}
        handleManageStudentViewClose={handleManageStudentViewClose}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />
    </>
  );
}

export default ManageBatchStudentLandingPage;
