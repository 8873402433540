import React from "react";
import { IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function GeneralTable({ editData, openView, handleCloseView }) {
  return (
    <>
      {/* ______________view modal______________________ */}
      <Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="giveaccessmodaloutercontainer"
      >
        <div className="giveaccessmodaloutercontainer">
          <div className="filtermodalcontent">
            {/* ____________modal and close icon_______________ */}
            <div>
              <IconButton
                id="button-zoom"
                className="EducationalTableViewmodalcloseicon"
                onClick={handleCloseView}
              >
                <CloseIcon className="EducationalTableViewmodalcloseiconX" />
              </IconButton>
            </div>
            {/* _______________________end_______________ */}
            {/* _____________Header of the modal____________ */}

            <div className="EducationalTableViewmodalHeader2">
              View Educational Road Map
            </div>

            {/* ________________________end_____________________ */}
            {/* _________________________content and the arrow____________  */}
            <div className="view-items-container">
              <p className="items-font-and-color-content">
                <span className="items-font-and-color">Syllabus:</span>{" "}
                {editData && editData.Syllabus}
              </p>
              <ArrowDownwardIcon />
              <p className="items-font-and-color-content">
                <span className="items-font-and-color">Education:</span>{" "}
                {editData && editData.Education}
              </p>
              <ArrowDownwardIcon />
              <p className="items-font-and-color-content">
                <span className="items-font-and-color">
                  Branch/FieldOfStudy:
                </span>{" "}
                {editData && editData["Branch/FieldOfStudy"]}
              </p>
              <ArrowDownwardIcon />
              <p className="items-font-and-color-content">
                <span className="items-font-and-color">Class/Sem:</span>{" "}
                {editData && editData["Class/Sem"]}
              </p>
              <ArrowDownwardIcon />
              <p className="items-font-and-color-content">
                <span className="items-font-and-color">Subject:</span>{" "}
                {editData && editData.Subject}
              </p>
              <ArrowDownwardIcon />
              <p className="items-font-and-color-content">
                <span className="items-font-and-color">ChapterName:</span>{" "}
                {editData && editData.ChapterName}
              </p>
              <ArrowDownwardIcon />
              <p className="items-font-and-color-content">
                <span className="items-font-and-color">TopicName:</span>{" "}
                {editData && editData.TopicName}
              </p>
            </div>
            {/* ___________________________end_________________________ */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default GeneralTable;
