import navItems from "./DashboardAdmin/navBarItems";

export const isReadOnly = (componentName) => {
  // Get ReadOnlyTabs from localStorage
  const readOnlyTabsString = JSON.parse(localStorage.getItem('readOnlyTabs'));
  let ReadOnlyTabs = [];

  // Safely parse ReadOnlyTabs from localStorage
  if (readOnlyTabsString) {
    try {
      ReadOnlyTabs = JSON.parse(readOnlyTabsString) || [];
    } catch (e) {
      console.error("Error parsing readOnlyTabs from localStorage", e);
    }
  }

  // Ensure ReadOnlyTabs is always an array
  if (!Array.isArray(ReadOnlyTabs)) {
    ReadOnlyTabs = [];
  }

  // Find the component in navItems or its subItems
  const matchedTab = navItems.find((tab) => {
    // Match main component
    if (tab.content && tab.content.type === componentName.type) {
      return true;
    }

    // Match sub-items if they exist
    if (tab.subItems) {
      return tab.subItems.some((subItem) => subItem.content.type === componentName.type);
    }

    return false;
  });

  if (!matchedTab) {
    return false; // No match found, return false
  }

  // Extract the tabId and subItemId if available
  const tabId = matchedTab.id;
  const subItemId = matchedTab.subItems?.find((subItem) => subItem.content.type === componentName.type)?.id;

  // Check in ReadOnlyTabs if the tabId or subItemId exists
  const isMainIdReadOnly = ReadOnlyTabs.some((group) => group[0] === tabId);
  const isSubItemIdReadOnly = subItemId ? ReadOnlyTabs.some((group) => group.includes(subItemId)) : false;

  // Return true if either the main tab ID or the sub-item ID is read-only
  return isMainIdReadOnly || isSubItemIdReadOnly;
};

// Example usage
// const readaccessManage = isReadOnly(<EmployeeLandingPage />);
// console.log('readaccessManage', readaccessManage);
