import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, Stepper, Step, Grid } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { baseURL } from "../../http";
import './Content.css';


const ContentSidenav = ({ setSelectedEducRoadMapId, setSelectedTopic, activeStep, setActiveStep, isScrolledToBottom, selectedTopic }) => {
  //-------------------------------------------------------------------------
  // State handle
  //-------------------------------------------------------------------------
    const [hoveredTopic, setHoveredTopic] = useState("");
    const [topicLists, setTopicLists] = useState([]);
    const [selectedOption, setSelectedOption] = useState(selectedTopic); 
    const [selectedOptionColor, setSelectedOptionColor] = useState({});  

  //-------------------------------------------------------------------------
  // Use Effect to Fetch -Topic Names
  //-------------------------------------------------------------------------

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const response = await axios.get(baseURL + "/dropdownOptions");
                const optionsData = response.data;

                setTopicLists(optionsData);
                // console.log('topicLists=', optionsData);

                // Initialize selectedOptionColor state with default colors
                if (optionsData.length > 0) {
                    const initialColors = {};
                    optionsData.forEach((row) => {
                        initialColors[row["TopicName"]] = row["TopicName"] === selectedOption ? 'green' : 'grey';
                    });
                    setSelectedOptionColor(initialColors);
                    let click;
                    let activeTab;
                    if(localStorage.getItem('selectedTopic')){
                        click=localStorage.getItem('selectedTopic')
                        activeTab=localStorage.getItem('contentActiveStep')
                    }else{
                        click=optionsData[0].TopicName;
                        activeTab=(0)
                    }

                    handleTopicClick(click, activeTab, optionsData[0].id);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchDropdownOptions();
    }, []);

  //-------------------------------------------------------------------------
  // Content Side Nav topic Selection
  //-------------------------------------------------------------------------
    const handleTopicClick = (topic, index, edRoadMapId) => {
        const contentContainer = document.querySelector('.main-content-container'); // Adjust the selector to target the correct container
        if (contentContainer) {
            contentContainer.scrollTop = 0;
        }
        setSelectedTopic(topic); // Update selected topic.
        localStorage.setItem('selectedTopic',topic);
        localStorage.setItem('contentActiveStep',index);
        setActiveStep(index); // Update active step
        setSelectedOption(topic); // Update selected option
        setSelectedEducRoadMapId(edRoadMapId);
    };

    useEffect(() => {
        if (isScrolledToBottom) {
            setSelectedOptionColor((prevColors) => ({
                ...prevColors,
                [selectedOption]: 'green', 
            }));
        }
    }, [isScrolledToBottom]);



    return (
        <div className="side-nav-container" >
            <Grid container>
            {/* <h3>Select Topic</h3> */}
                <div style={{ display: 'flex' }}>
                    <div className="tabs" >
                        {/* {JSON.stringify(dropdownOptions)} */}
                        
                        <div className="tab1" style={{ height: "auto" }}>
                            <Stepper activeStep={activeStep} orientation="vertical" style={{ width: "200px", marginTop:'15px' }}>
                                {topicLists.map((topic, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem
                                            button
                                            onClick={() => handleTopicClick(topic.TopicName, index, topic.id)}
                                            style={{
                                                top: '-15px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingLeft: '10px',
                                                position: 'relative',
                                                backgroundColor: activeStep == index ? '#66bb6a' : (hoveredTopic == topic.TopicName ? '#e0e0e0' : 'transparent'),
                                            }}
                                            onMouseEnter={() => setHoveredTopic(topic.TopicName)}
                                            onMouseLeave={() => setHoveredTopic("")}
                                        >
                                            <CheckCircle
                                                style={{
                                                    marginRight: '10px',
                                                    color: selectedOptionColor[topic.TopicName] || 'transparent', // Apply color based on selectedOptionColor state
                                                    fontSize: '20px'
                                                }}
                                            />
                                            <ListItemText
                                                primary={topic.TopicName} // Render the TopicName property
                                                style={{
                                                    color: activeStep == index ? 'white' : 'black',
                                                }}
                                            />
                                            {index !== topicLists.length - 1 && ( // vertical stepper line only
                                                <div // vertical stepper line only 
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        left: '10%',
                                                        transform: 'translate(-50%, 120%)',
                                                        width: '2px',
                                                        height: 'calc(100% - 20px)',
                                                        backgroundColor: selectedOptionColor[topic.TopicName] || 'transparent', // Apply color based on selectedOptionColor state
                                                    }}
                                                />
                                            )}
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </Stepper>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    );
}

export default ContentSidenav;
