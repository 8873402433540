import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts";
import "./ResultDashboardPage.css";

function SubjectsBarGraphs({ ResultData, filteredQuestions, generalInfo }) {
  const [chartDimensions, setChartDimensions] = useState({
    width: 700,
    height: 300,
    fontSize: 12,
  });
  const [chartData, setChartData] = useState([]);

  // useEffect(() => {
  //     const updateChartDimensions = () => {
  //         const screenWidth = window.innerWidth;
  //         if (screenWidth <= 480) {
  //             setChartDimensions({ width: 320, height: 200, fontSize: 10 });
  //         } else if (screenWidth <= 768) {
  //             setChartDimensions({ width: 500, height: 250, fontSize: 10 });
  //         } else {
  //             setChartDimensions({ width: 700, height: 300, fontSize: 11 });
  //         }
  //     };

  //     updateChartDimensions();
  //     window.addEventListener("resize", updateChartDimensions);
  //     return () => window.removeEventListener("resize", updateChartDimensions);
  // }, []);

  //--------------------------------------------------------------------------------
  // based on the exam status and filterquestions render the states
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (!filteredQuestions || !ResultData?.ExamStatus) return;

    // Parse ExamStatus data
    const examStatus =
      typeof ResultData.ExamStatus === "string"
        ? JSON.parse(ResultData.ExamStatus)
        : ResultData.ExamStatus;

    if (examStatus && examStatus[0]) {
      const { resultArray = [], SeletedOptions = [] } = examStatus[0];

      const data = filteredQuestions.reduce((acc, question, index) => {
        const subject = question.Subject;

        if (!acc[subject]) {
          acc[subject] = {
            subject,
            correctAnswers: 0,
            wrongAnswers: 0,
            unanswered: 0,
          };
        }

        // Check if SelectedOptions exists and has a value at the current index
        if (
          SeletedOptions[index] === null ||
          SeletedOptions[index] === undefined
        ) {
          acc[subject].unanswered += 1;
        } else {
          // Check if resultArray has a value at the current index
          const result = resultArray[index];

          if (result === 0) {
            acc[subject].wrongAnswers += 1;
          } else if (result === 1) {
            acc[subject].correctAnswers += 1;
          }
        }

        return acc;
      }, {});

      setChartData(Object.values(data));
    }
  }, [ResultData, filteredQuestions]);

  //--------------------------------------------------------------------------------
  // Chat setting
  //--------------------------------------------------------------------------------
  const chartSetting = {
    yAxis: [{ label: "Marks" }],
    width: chartDimensions.width,
    height: chartDimensions.height,

    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-10px, 0)",
      },
      "& .MuiTypography-root": {
        fontSize: chartDimensions.fontSize,
      },
    },
  };

  const valueFormatter = (value) => `${value}`;

  return (
    <div className="chart-section">
      <div className="chart-container">
        {chartData.length > 0 ? (
          <BarChart
            dataset={chartData}
            xAxis={[{ scaleType: "band", dataKey: "subject" }]}
            series={[
              {
                dataKey: "correctAnswers",
                label: "Correct Answers",
                valueFormatter,
                color: "green",
              },
              {
                dataKey: "wrongAnswers",
                label: "Wrong Answers",
                valueFormatter,
                color: "red",
              },
              {
                dataKey: "unanswered",
                label: "Unanswered",
                valueFormatter,
                color: "rgb(217, 158, 69)",
              },
            ]}
            {...chartSetting}
          />
        ) : (
          <p>Loading data...</p> // Optional fallback when data is unavailable
        )}
      </div>
      <div className="chart-title">Subject vs Marks</div>
    </div>
  );
}

export default SubjectsBarGraphs;
