import React, { useState } from "react";
import {
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  Avatar,
  Chip,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function ExamCardView({ SelectedTest, setSelectedTest }) {
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  //--------------------------------------------------------------------------------
  // Function to handle deleting a test from SelectedTest
  //--------------------------------------------------------------------------------
  const handleDeleteTest = (test) => {
    const updatedSelectedTest = SelectedTest.filter((item) => item !== test);
    setSelectedTest(updatedSelectedTest);
    setSnackbarMessage("Exams removed successfully!");
    setOpenSnackbar(true); // Show snackbar after deletion
  };

  //--------------------------------------------------------------------------------
  // Handle drag and drop reorder
  //--------------------------------------------------------------------------------
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedTests = Array.from(SelectedTest); // Ensure SelectedTest is being reordered
    const [movedTest] = reorderedTests.splice(result.source.index, 1);
    reorderedTests.splice(result.destination.index, 0, movedTest);
    setSelectedTest(reorderedTests); // Update test order
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tests">
          {(provided) => (
            <div
              className="accordion-container-add-totest"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {SelectedTest &&
                SelectedTest.map((Test, index) => (
                  <Draggable
                    key={Test.id}
                    draggableId={String(Test.id)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="all-component-render-page-outer-container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="accordion-summary-of-the-question-display-instructor-card">
                          <Tooltip
                            title={`State/Department: ${Test.state_or_department}`}
                            arrow
                          >
                            <Avatar className="studentview1-avatar-bakground-color">
                              {Test.exam_name.charAt(0).toUpperCase()}
                            </Avatar>
                          </Tooltip>

                          <div className="username-and-phonenumber-alignmnet">
                            <div>{Test.exam_name}</div>
                            <div className="Testcardview1-general-info-alignemnt">
                              <div>
                                <span className="font-weight-reduce-and-alignment">
                                  Exam Category:
                                </span>
                                <span className="Test-card-view1-text-color">
                                  &nbsp;{Test.exam_category}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="add-icon-and-right-icon-placement">
                            <Chip
                              className="difficulty-level-shown-color-font-test-card-view"
                              label={Test.top_category}
                              style={{
                                backgroundColor:
                                  Test.top_category === "State"
                                    ? "green"
                                    : Test.top_category === "Central"
                                    ? "rgb(176, 74, 227)" // Assuming 'Central' category is blue
                                    : Test.top_category === "Department"
                                    ? "orange"
                                    : "gray", // Default color if no condition matches
                              }}
                            />

                            {/* Delete Icon */}
                            <Tooltip title="Remove Exams" arrow>
                              <IconButton
                                className="icon-button-to-disapay-add-button"
                                onClick={() => handleDeleteTest(Test)}
                              >
                                <RemoveCircleIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* _____________snackbar for notification_____________ */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}

export default ExamCardView;
