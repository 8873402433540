import React, { useState } from "react";
import { Modal, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { useNavigate, Link } from "react-router-dom"; // Import useHistory
import InstructionPage from "./InstructionPage";

function TestInstructionLink({ open1, handleClose, testinfo }) {
  const [checked, setChecked] = useState(false);
  const [agreed, setAgreed] = useState(false);
  //const [testdata, settestdata] = useState(testinfo);
  const navigate = useNavigate();


    //--------------------------------------------------------------------------------
  // Chcek box check and uncheek handle
  //--------------------------------------------------------------------------------
  const handleChange = (event) => {
    setChecked(event.target.checked);
    //setAgreed(true)
  };

      //--------------------------------------------------------------------------------
  // clikc to start the test
  //--------------------------------------------------------------------------------
  const handleClick = () => {
    setAgreed(true);
    navigate("/quiz", { state: { testinfo: testinfo } });
  };

  return (
    <>
      <div>
        <Modal
          open={open1}
          onClose={handleClose}
          className="testmanagementdatagrid3"
        >
          <div className="testmanagementdatagrid4">
            <IconButton
              id="button-zoom"
              className="addeducationalMapmodalcancel"
            >
              <CloseIcon
                className="addeducationalMapmodalcancelX"
                onClick={handleClose}
              />
            </IconButton>
            <div className="testinstructionlink">
              <h5 className="font-weight-b0ld-in-all-render" >Instructions for Student</h5>
            </div>
            <div className="testinstructionlink1">
              <h6 className="font-weight-b0ld-in-all-render">Important Instructions</h6>
              <ul >
                <li>Arrange for stable Internet connectivity.</li>
                <li>
                  Giving examination on Laptop or Desktop is highly recommended.
                </li>
                <li>
                  Make sure mobile/laptop is fully charged. Power bank for
                  mobile or UPS/Inverter for laptop/desktop should be arranged
                  for uninterrupted power supply
                </li>
                <li>
                  Once the exam starts, do not switch to any other window/tab.
                  On doing so, your attempt may be considered as malpractice and
                  your exam may get terminated.
                </li>
                <li>
                  Do Not Pickup/Receive the Call during the exam if you are
                  giving the exam on mobile. This also will be treated as
                  changing the window.
                </li>
              </ul>
              <InstructionPage/>
              </div>

            <div className="testinstructionlink2">
              <div className="testinstructionlink3">
               
                    <Checkbox checked={checked} onChange={handleChange} />
                
                <span >
                  I Agree to all the terms and conditions
                </span>
              </div>
              <div >
                <Button
                  variant="contained"
                  id="button-zoom"
                  color="success"
                  onClick={handleClick}
                  disabled={!checked} // Disable button when checkbox is not checked
                >
                  <BeenhereIcon className="buttonicons" />
                  &nbsp;Agree
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {agreed && (
        <Link to={{ pathname: "/quiz", state: { testinfo: testinfo } }}>
          Go to Quiz
        </Link>
      )}
    </>
  );
}

export default TestInstructionLink;
