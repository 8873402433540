import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Autocomplete } from "@mui/material";
import Editor1 from "../Editor/Editor1"; // Assuming you have the Editor1 component
import "./CreateExam.css";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ImageCropperTemplate2 from "../Templates/ImageCroper/ImageCropperTemplate2";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";

const CreateExam = ({ setExamDetails, examDetails }) => {
  const topCategories = ["State", "Central", "Department"];
  const states = ["State 1", "State 2", "State 3"];
  const departments = ["Department 1", "Department 2"];
  const examCategories = ["Category 1", "Category 2"];

  const [newCategory, setNewCategory] = useState("");
  const [newState, setNewState] = useState("");
  const [newDepartment, setNewDepartment] = useState("");

  const containerWidth = 145;
  const containerHeight = 145;
  const containerSize = Math.min(containerWidth, containerHeight);
  const fontSize = containerSize * 0.65;

  const [profilePictureModalStatus, setProfilePictureModalStatus] =
    useState(false);
  const [croppedLogoURL, setCroppedLogoURL] = useState(null); // Store cropped image data URL
  const [imageEditMode, setImgEditMode] = useState(0);

  // States to store content of editors
  const [overviewContent, setOverviewContent] = useState(
    examDetails.overview || ""
  );
  const [syllabusContent, setSyllabusContent] = useState(
    examDetails.syllabus || ""
  );
  const [eligibilityContent, setEligibilityContent] = useState(
    examDetails.eligibility || ""
  );

  const [feesContent, setFeesContent] = useState(() => {
    if (examDetails.fees) {
      const { general, sc, st, others } = examDetails.fees;
      return `
        General: ${general || ""}<br/>
        SC: ${sc || ""}<br/>
        ST: ${st || ""}<br/>
        Others: ${others || ""}<br/>
      `;
    }
    return `General:<br/><br/>SC:<br/><br/>ST:<br/><br/>Others:<br/><br/>`;
  });

  useEffect(() => {
    console.log("Exam Details:", examDetails); // Log examDetails whenever it changes
  }, [examDetails]);

  const handleTopCategoryChange = (event, value) => {
    setExamDetails({
      ...examDetails,
      top_category: value,
      state_or_department: "",
    });
  };

  const handleInputChange = (e) => {
    let value = e.target.value;

    // URL validation
    if (e.target.name === "authority_url") {
      if (!/^https?:\/\//i.test(value) && value.trim() !== "") {
        value = "http://" + value; // Prepend http://
      } else if (!/^https?:\/\//i.test(value)) {
        // Optional: Show an error message if the URL is not valid
        alert("Please enter a valid URL starting with http:// or https://");
        return;
      }
    }

    // Name validation (Example: Required)
    if (e.target.name === "exam_name" && value.trim() === "") {
      alert("Exam Name is required");
      return;
    }

    setExamDetails({
      ...examDetails,
      [e.target.name]: value,
    });
  };

  const handleFeesEditorContentChange = (content) => {
    // Extract fee data from the editor content using regex
    const generalFee = content.match(/General:\s*(\d+)/)?.[1] || "";
    const scFee = content.match(/SC:\s*(\d+)/)?.[1] || "";
    const stFee = content.match(/ST:\s*(\d+)/)?.[1] || "";
    const othersFee = content.match(/Others:\s*(\d+)/)?.[1] || "";

    // Update examDetails with the extracted numbers
    setExamDetails({
      ...examDetails,
      fees: {
        general: Number(generalFee),
        sc: Number(scFee),
        st: Number(stFee),
        others: Number(othersFee),
      },
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setExamDetails((prevDetails) => ({
        ...prevDetails,
        [event.target.name]: file,
      }));
    }
  };

  const handleOpenCropper = () => {
    setProfilePictureModalStatus(!profilePictureModalStatus);
    setImgEditMode(imageEditMode + 1);
  };

  // Handler for saving the cropped image from the cropper
  const handleSaveCroppedImage = () => {
    // Set the cropped image as the logo image in exam details
    setExamDetails({ ...examDetails, logo_image: croppedLogoURL });
    setProfilePictureModalStatus(false); // Close the modal
  };

  // Handle setting examDetails from editor content
  const handleEditorContentChange = (field, content) => {
    setExamDetails({
      ...examDetails,
      [field]: content,
    });
  };

  const documentFields = ["detailed_pdf"]; // You can add more fields as needed

  const renderDocumentFields = () =>
    documentFields.map((field, idx) => (
      <div className="text-field-status" key={idx}>
        <div className="slno-and-text-fileds">
          <div key={idx}>
            <label className="align-items-heading" htmlFor={field}>
              {`Upload ${field.replace(/([A-Z])/g, " $1").trim()}`}
            </label>
            
            {/* Hidden file input */}
            <input
              type="file"
              id={field}
              name={field}
              accept="application/pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
  
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => document.getElementById(field).click()}
              >
                Choose File
              </Button>
  
              <span>&nbsp;</span>
  
              {/* Display the file name if a new file is chosen */}
              {examDetails[field] instanceof File ? (
                <span>{examDetails[field].name}</span>
              ) : (
                // If a valid URL exists for the field, display the link to the file
                examDetails[field] ? (
                  <>
                    <a
                      href={examDetails[field]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Existing File
                    </a>
                    <span> (Or choose a new file)</span>
                  </>
                ) : (
                  <span>No file chosen</span>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="modal-content">
      {/* Step 1: Top Category Selection */}
      <div className="two-column-row">
        <div className="profile-row">
          <Autocomplete
            className="full-width"
            options={topCategories}
            value={examDetails.top_category || ""}
            onChange={handleTopCategoryChange}
            renderInput={(params) => (
              <TextField {...params} label="Top Category" />
            )}
          />
          <Autocomplete
            className="full-width"
            options={examCategories}
            value={examDetails.exam_category || ""}
            onChange={(event, value) =>
              setExamDetails({ ...examDetails, exam_category: value })
            }
            onInputChange={(event, value) => {
              setNewCategory(value); // Store the input value
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Exam Category"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {newCategory && !examCategories.includes(newCategory) ? (
                        <AddIcon
                          onClick={() => {
                            setExamDetails({
                              ...examDetails,
                              exam_category: newCategory,
                            });
                            examCategories.push(newCategory); // Add to options
                            setNewCategory(""); // Clear input
                          }}
                          className="Autocomplete-add-icon"
                        />
                      ) : null}
                      {params.InputProps.endAdornment}{" "}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="companyProfileGroup cpLogo">
          {/* Company Logo */}
          <div className="companyProfileImageContainer">
            <img
              src={examDetails.logo_image} // Display the uploaded logo image
              alt="Logo Image"
              className="ExamProfileImage"
              style={{
                fontSize: `${fontSize}px`,
                lineHeight: `${containerHeight}px`,
              }}
            />
            <Tooltip
              title={croppedLogoURL ? "Edit Logo" : "Add / Change Logo"}
              arrow
            >
              <label onClick={handleOpenCropper} className="AddExamLogo">
                {"Update Logo"}
              </label>
            </Tooltip>
          </div>
        </div>
      </div>

      {/* Step 3: Conditional State or Department Selection */}
      {examDetails.top_category === "State" && (
        <div className="form-row">
          <Autocomplete
            className="full-width"
            options={states}
            value={examDetails.state_or_department || ""}
            onChange={(event, value) =>
              setExamDetails({ ...examDetails, state_or_department: value })
            }
            onInputChange={(event, value) => {
              setNewState(value); // Store the input value
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select State"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {newState && !states.includes(newState) ? (
                        <AddIcon
                          onClick={() => {
                            setExamDetails({
                              ...examDetails,
                              state_or_department: newState,
                            });
                            states.push(newState); // Add to options
                            setNewState(""); // Clear input
                          }}
                          className="Autocomplete-add-icon"
                        />
                      ) : null}
                      {params.InputProps.endAdornment}{" "}
                      {/* Keep existing adornments */}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
      )}

      {examDetails.top_category === "Department" && (
        <div className="form-row">
          <Autocomplete
            className="full-width"
            options={departments}
            value={examDetails.state_or_department || ""}
            onChange={(event, value) =>
              setExamDetails({ ...examDetails, state_or_department: value })
            }
            onInputChange={(event, value) => {
              setNewDepartment(value); // Store the input value
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Department"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {newDepartment && !departments.includes(newDepartment) ? (
                        <AddIcon
                          onClick={() => {
                            setExamDetails({
                              ...examDetails,
                              state_or_department: newDepartment,
                            });
                            departments.push(newDepartment); // Add to options
                            setNewDepartment(""); // Clear input
                          }}
                          className="Autocomplete-add-icon"
                        />
                      ) : null}
                      {params.InputProps.endAdornment}{" "}
                      {/* Keep existing adornments */}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
      )}

      <div className="form-row">
        <TextField
          label="Exam Name"
          name="exam_name"
          value={examDetails.exam_name || ""}
          onChange={handleInputChange}
          className="text-field-container"
        />
      </div>
      <div className="two-column-row">
        <TextField
          label="Official Website URL"
          name="authority_url"
          value={examDetails.authority_url || ""}
          onChange={handleInputChange}
          className="text-field-container"
          helperText="Please include http:// or https://"
        />

        {/* PDF Upload */}
        {renderDocumentFields()}
      </div>

      <div className="form-row">
        <h2>Fees</h2>
        <Editor1
          isHeadingRequird={false}
          is_tag_required={false}
          isAutocomplete={false}
          desktopHeight="250px"
          return_location="/dashboard"
          editID="feesEditor"
          showHyperlink={true}
          showImageInsert={true}
          showCodeViewer={false}
          showButtonInsert={false}
          setContent={(content) => {
            setFeesContent(content); // Update state for editor UI
            handleFeesEditorContentChange(content); // Update examDetails
          }}
          content={feesContent}
        />
      </div>

      {/* Replace TextFields with Editors */}
      <div className="form-row">
        <h2>OverView</h2>
        <Editor1
          isHeadingRequird={false}
          is_tag_required={false}
          isAutocomplete={false}
          desktopHeight="250px"
          return_location="/dashboard"
          editID="overviewEditor"
          showHyperlink={true}
          showImageInsert={true}
          showCodeViewer={false}
          showButtonInsert={false}
          setContent={(content) => {
            setOverviewContent(content);
            handleEditorContentChange("overview", content);
          }}
          content={overviewContent}
        />
      </div>

      <div className="form-row">
        <h2>Syllabus</h2>
        <Editor1
          isHeadingRequird={false}
          is_tag_required={false}
          isAutocomplete={false}
          desktopHeight="250px"
          return_location="/dashboard"
          editID="syllabusEditor"
          showHyperlink={true}
          showImageInsert={true}
          showCodeViewer={false}
          showButtonInsert={false}
          setContent={(content) => {
            setSyllabusContent(content);
            handleEditorContentChange("syllabus", content);
          }}
          content={syllabusContent}
        />
      </div>

      <div className="form-row">
        <h2>Eligibility Criteria</h2>
        <Editor1
          isHeadingRequird={false}
          is_tag_required={false}
          isAutocomplete={false}
          desktopHeight="250px"
          return_location="/dashboard"
          editID="eligibilityEditor"
          showHyperlink={true}
          showImageInsert={true}
          showCodeViewer={false}
          showButtonInsert={false}
          setContent={(content) => {
            setEligibilityContent(content);
            handleEditorContentChange("eligibility", content);
          }}
          content={eligibilityContent}
        />
      </div>
      {profilePictureModalStatus && (
        <ImageCropperTemplate2
          open={profilePictureModalStatus}
          setOpen={setProfilePictureModalStatus}
          croppedImageDataUrl={croppedLogoURL}
          setCroppedImageDataUrl={setCroppedLogoURL}
          handleSave={handleSaveCroppedImage} // Save the cropped image
        />
      )}
    </div>
  );
};

export default CreateExam;
