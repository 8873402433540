import React from "react";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./instructor.css";

function InstructorCardTemplate({ instructorData = {} }) {
  //--------------------------------------------------------------------------------
  //convert string(json) into array
  //--------------------------------------------------------------------------------

  const companyLogos = JSON.parse(instructorData.companyLogos || "[]");
  const socialMediaLinks = JSON.parse(instructorData.socialMediaLinks || "[]");

  //--------------------------------------------------------------------------------
  //function for rendering the social media links
  //--------------------------------------------------------------------------------

  const getSocialMediaIcon = (platform) => {
    switch (platform) {
      case "linkedin":
        return <LinkedInIcon className="social-media-hover-background-color" />;
      case "instagram":
        return (
          <InstagramIcon className="social-media-hover-background-color" />
        );
      case "facebook":
        return <FacebookIcon className="social-media-hover-background-color" />;
      case "youtube":
        return <YouTubeIcon className="social-media-hover-background-color" />;
      default:
        return null;
    }
  };
  return (
    <div className="instructor-card-make-center-container">
      <div className="instructor-template-container">
        <div className="instructor-content-container">
          {/* ___________image section_____________ */}
          <div className="make-the-image-container-center">
            <div className="instructor-image-name-designation">
              <div className="instructor-image-container">
                {instructorData.profileImage && (
                  <img src={instructorData.profileImage} alt="instructor" />
                )}
              </div>
              <div className="instuctor-name">
                {instructorData.instructorName}
              </div>
              <div className="instuctor-Designation">
                {instructorData.designation}
              </div>
            </div>
          </div>
          {/* ___________end_____________ */}

          {/* __________Instructoe worke experience __________ */}

          <div className="instructor-right-content">
            <div className="instructor-experience">
              <div className="instructor-work-experience">
                <WorkHistoryOutlinedIcon className="experience-icon-alignment" />
                <div>
                  <div className="year-font-size-and-align">
                    {" "}
                    {instructorData.yearOfExperience}+ Years{" "}
                  </div>
                  <div className="work-experien-teaching-font-align">
                    Work Experience
                  </div>
                </div>
              </div>

              <div className="instructor-work-experience">
                <SupervisorAccountOutlinedIcon className="experience-icon-alignment" />
                <div>
                  <div className="year-font-size-and-align">
                    {instructorData.teachingExperience}+ Years{" "}
                  </div>
                  <div className="work-experien-teaching-font-align">
                    Teaching Experience
                  </div>
                </div>
              </div>

              {/* __________Social medi icons__________ */}
              <div className="social-media-contact">
                {socialMediaLinks
                  .filter((link) => link.link) // Filter out null or empty links
                  .map((link, index) => (
                    <a
                      key={index}
                      href={`https://${link.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getSocialMediaIcon(link.platform)}
                    </a>
                  ))}
              </div>
            </div>
            {/* ________________end____________ */}

            {/* _____________company logos_______________ */}
            <div className="instructor-logos">
              {companyLogos.map((logo, index) => (
                <div className="instructor-logos-inside-container" key={index}>
                  <img src={logo} alt={`Logo ${index}`} />
                </div>
              ))}
            </div>
            {/* _____________end______________ */}
            {/* _________________Instructor content______________ */}
            <div className="instructor-content">
              {instructorData.aboutInstructor}
            </div>
          </div>
        </div>
        <div className="instructor-content1">
          {instructorData.aboutInstructor}
        </div>
      </div>
      {/* _________________end______________ */}
    </div>
  );
}

export default InstructorCardTemplate;
