import React, { useState } from "react";
import { Chip, IconButton, Snackbar, Alert, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import "./CreateTest.css"; // Assuming you have your CSS file for styling
import SellIcon from "@mui/icons-material/Sell";

function CreateTestQuestionView({
  rows,
  loading,
  SetSeletedQuestion,
  selectedQuestions,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //--------------------------------------------------------------------------------
  // Function to handle the selection of a question
  //--------------------------------------------------------------------------------
  const handleAddQuestion = (question) => {
    if (selectedQuestions.includes(question)) {
      // If the question is already selected, remove it from selectedQuestions
      SetSeletedQuestion((prevSelected) =>
        prevSelected.filter((q) => q !== question)
      );
    } else {
      // If it's not selected, add it to the top of selectedQuestions and show Snackbar
      SetSeletedQuestion((prevSelected) => [question, ...prevSelected]); // Add to the top
      setSnackbarOpen(true); // Open Snackbar
    }
  };

  //--------------------------------------------------------------------------------
  // Handle the "Done" action to move the question to the top
  //--------------------------------------------------------------------------------
  const handleMarkAsDone = (question) => {
    SetSeletedQuestion((prevSelected) => {
      // Remove the question from the current position
      const updatedSelected = prevSelected.filter((q) => q !== question);
      // Add it back at the top
      return [question, ...updatedSelected];
    });
  };

  //--------------------------------------------------------------------------------
  // Handle closing the Snackbar
  //--------------------------------------------------------------------------------
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Sort rows to bring selected questions to the top
  // Sort rows to bring selected questions to the top
  // const sortedRows = Array.isArray(rows) && rows.length > 0
  //   ? [...rows].sort((a, b) => {
  //       const isASelected = selectedQuestions.includes(a);
  //       const isBSelected = selectedQuestions.includes(b);
  //       return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
  //     })
  //   : []; // Return an empty array if rows is empty or not an array

  return (
    <>
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="accordion-container-add-totest">
            {rows &&
              rows.map((question, index) => (
                <div
                  key={index}
                  className="all-component-render-page-outer-container"
                >
                  <div className="accordion-summary-of-the-question-display">
                    Q{index + 1}.&nbsp;
                    <div>{question.Questions}</div>
                  </div>

                  <div>
                    {/* Expanded details for each question */}
                    <div>
                      <div className="display-options-and-answer">
                        {!question.OptionA &&
                          !question.OptionB &&
                          !question.OptionC &&
                          !question.OptionD && (
                            <span className="color-change-for-the-answer">
                              <span>Answer:</span> {question.Answer}
                            </span>
                          )}

                        {question.OptionA && (
                          <div
                            className={`option-line ${
                              question.Answer === question.OptionA
                                ? "highlight-green"
                                : ""
                            }`}
                          >
                            <span className="color-dimming-for-the-options">
                              A.&nbsp;
                            </span>{" "}
                            <div>{question.OptionA}</div>
                          </div>
                        )}
                        {question.OptionB && (
                          <div
                            className={`option-line ${
                              question.Answer === question.OptionB
                                ? "highlight-green"
                                : ""
                            }`}
                          >
                            <span className="color-dimming-for-the-options">
                              B.&nbsp;
                            </span>{" "}
                            {question.OptionB}
                          </div>
                        )}
                        {question.OptionC && (
                          <div
                            className={`option-line ${
                              question.Answer === question.OptionC
                                ? "highlight-green"
                                : ""
                            }`}
                          >
                            <span className="color-dimming-for-the-options">
                              C.&nbsp;
                            </span>{" "}
                            {question.OptionC}
                          </div>
                        )}
                        {question.OptionD && (
                          <div
                            className={`option-line ${
                              question.Answer === question.OptionD
                                ? "highlight-green"
                                : ""
                            }`}
                          >
                            <span className="color-dimming-for-the-options">
                              D.&nbsp;
                            </span>{" "}
                            {question.OptionD}
                          </div>
                        )}

                        <div className="add-icon-and-right-icon-placement">
                          <Chip
                            className="difficulty-level-shown-color-font1"
                            label={question.DifficultyLevels}
                            style={{
                              backgroundColor:
                                question.DifficultyLevels === "Easy"
                                  ? "green"
                                  : question.DifficultyLevels === "Medium"
                                  ? "orange"
                                  : question.DifficultyLevels === "Hard"
                                  ? "red"
                                  : "default",
                              color: "white",
                            }}
                          />

                          {selectedQuestions.includes(question) ? (
                            <Tooltip title="Navigate the Question" arrow>
                              <IconButton
                                className="icon-button-to-disapay-done-button"
                                onClick={() => handleMarkAsDone(question)} // Mark as done
                              >
                                <DoneAllIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Add Question to Test" arrow>
                              <IconButton
                                className="icon-button-to-disapay-add-button"
                                onClick={() => handleAddQuestion(question)}
                              >
                                <Add />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                        <div className="question-tag-div">
                          <SellIcon className="button-icons-tags" />:
                          <span className="question-tag-div1">
                            {question.QuestionTag}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={500}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">Question added to test successfully!</Alert>
      </Snackbar>
    </>
  );
}

export default CreateTestQuestionView;
