import React, { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { baseURL } from "../../http";
import {
  TextField,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import { customAlert } from "../SweetAlertCommon/Custom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import "./questionBank.css";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function AddtoQuestionBank({ onClose, update1, editData, renderRows }) {
  //--------------------------------------------------------------------------------
  //For order the Educational Roadmap
  //--------------------------------------------------------------------------------
  const specificData = [
    "Syllabus",
    "Education",
    "Branch/FieldOfStudy",
    "Class/Sem",
    "Subject",
    "ChapterName",
    "TopicName",
  ];

  const [dropdownOptions, setDropdownOptions] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [combinedOptions, setCombinedOptions] = useState([]);

  const defaultOptions = [
    { label: "Math", value: "math" },
    { label: "Science", value: "science" },
    { label: "History", value: "history" },
    { label: "Geography", value: "geography" },
  ];

  //--------------------------------------------------------------------------------
  //Dropdown options for Eductaional dropdown
  //--------------------------------------------------------------------------------
  const fetchDropdownOptions = async () => {
    try {
      const optionsResponse = await axios.get(baseURL + "/dropdownOptions");
      const optionsData = optionsResponse.data;
      const uniqueOptions = {};
      specificData.forEach((field) => {
        uniqueOptions[field] = [
          ...new Set(optionsData.map((row) => row[field])),
        ];
      });
      setDropdownOptions(uniqueOptions);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  //--------------------------------------------------------------------------------
  //call the function of Dropdown options for Eductaional dropdown
  //--------------------------------------------------------------------------------

  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  //--------------------------------------------------------------------------------
  //call the function of Dropdown options for Eductaional dropdown
  //--------------------------------------------------------------------------------

  const handleInputChange = (newValue, fieldName) => {
    console.log(newValue, fieldName); // Log the new value and field name
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: newValue,
    }));
  };

  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    QuestionType: "MCQs", // Added QuestionType field
    Questions: "",
    OptionA: "",
    OptionB: "",
    OptionC: "",
    OptionD: "",
    Marks: 1,
    DifficultyLevels: "",
    Answer: "",
    Syllabus: "",
    Education: "",
    "Branch/FieldOfStudy": "",
    "Class/Sem": "",
    Subject: "",
    ChapterName: "",
    TopicName: "",
    Hint: "",
    UserFeedback: "",
    QuestionTag: "",
    LearningObjective: "",
    LanguageOfQuestion: "en",
    isAdminApproved: false,
  });

  const difficultyLevelsOptions = ["Easy", "Medium", "Hard"];
  const fields = [
    { label: "Hint", name: "Hint", placeholder: "Hint" },
    {
      label: "User Feedback",
      name: "UserFeedback",
      placeholder: "User Feedback",
    },
    {
      label: "Learning Objective",
      name: "LearningObjective",
      placeholder: "Learning Objective",
    },
  ];

  //--------------------------------------------------------------------------------
  //For edit the data (prefill the textfields)
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      // Identify which option (A, B, C, D) contains the correct answer
      let correctAnswer = "";
      if (editData.Answer === editData.OptionA) {
        correctAnswer = "OptionA";
      } else if (editData.Answer === editData.OptionB) {
        correctAnswer = "OptionB";
      } else if (editData.Answer === editData.OptionC) {
        correctAnswer = "OptionC";
      } else if (editData.Answer === editData.OptionD) {
        correctAnswer = "OptionD";
      }
      setFormData({
        QuestionType: editData.QuestionType || "",
        Questions: editData.Questions || "",
        OptionA: editData.OptionA || "",
        OptionB: editData.OptionB || "",
        OptionC: editData.OptionC || "",
        OptionD: editData.OptionD || "",
        Marks: parseInt(editData.Marks) || 1,
        DifficultyLevels: editData.DifficultyLevels || "",
        Answer: correctAnswer || "",
        Syllabus: editData.Syllabus || "",
        Education: editData.Education || "",
        "Branch/FieldOfStudy": editData["Branch/FieldOfStudy"] || "",
        "Class/Sem": editData["Class/Sem"] || "",
        Subject: editData.Subject || "",
        ChapterName: editData.ChapterName || "",
        TopicName: editData.TopicName || "",
        Hint: editData.Hint || "",
        UserFeedback: editData.UserFeedback || "",
        QuestionTag: editData.QuestionTag || "",
        LearningObjective: editData.LearningObjective || "",
        LanguageOfQuestion: editData.LanguageOfQuestion || "en",
        isAdminApproved:
          parseInt(editData.isAdminApproved) === 0 ? false : true,
      });
    }
  }, [editData]);

  //--------------------------------------------------------------------------------
  // Convert the QuestionTag string to the format required for selectedOption
  //--------------------------------------------------------------------------------
  const formatQuestionTagForSelect = (tagString) => {
    if (!tagString) return [];
    return tagString
      .split(",")
      .map((tag) => {
        const trimmedTag = tag.trim(); // Remove any extra spaces
        const option = defaultOptions.find(
          (opt) => opt.label.toLowerCase() === trimmedTag.toLowerCase()
        );
        return option
          ? { label: option.label, value: option.value }
          : { label: trimmedTag, value: trimmedTag }; // Allow adding custom tags
      })
      .filter(Boolean); // Filter out any null values
  };
  //--------------------------------------------------------------------------------
  // To call the Dropdown function
  //--------------------------------------------------------------------------------
  useEffect(() => {
    // Ensure editData is defined and is an object
    if (editData && typeof editData === "object") {
      // Set initial values for formData based on editData
      setFormData((prevData) => ({
        ...prevData,
        QuestionTag: editData.QuestionTag || "", // Keep this for the form state
        // Other fields can be set here if necessary...
      }));
      // Set the selected option for the multi-select based on the QuestionTag
      const formattedTags = formatQuestionTagForSelect(editData.QuestionTag);
      setSelectedOption(formattedTags);
    }
  }, [editData]);
  //--------------------------------------------------------------------------------
  // Tags rendering Function
  //--------------------------------------------------------------------------------
  const formatQuestionTags = (data) => {
    const allTags = new Set();
    // Check if data is an array and is not empty
    if (!Array.isArray(data)) return;
    data.forEach((item) => {
      // Check if item is defined and has a QuestionTag property
      if (item && item.QuestionTag) {
        const tags = item.QuestionTag.split(",").map((tag) => tag.trim());
        tags.forEach((tag) => allTags.add(tag));
      }
    });
    const formattedTags = Array.from(allTags).map((tag) => {
      const option = defaultOptions.find(
        (opt) => opt.label.toLowerCase() === tag.toLowerCase()
      );
      return option
        ? { label: option.label, value: option.value }
        : { label: tag, value: tag }; // Allow adding custom tags
    });
    setCombinedOptions([...defaultOptions, ...formattedTags]); // Combine with default options
  };
  //--------------------------------------------------------------------------------
  // QuestionTags Render form the all the columns
  //--------------------------------------------------------------------------------
  useEffect(() => {
    formatQuestionTags(renderRows);
  }, [renderRows]);
  //--------------------------------------------------------------------------------
  //Changes in the text fileds entries
  //--------------------------------------------------------------------------------
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  //--------------------------------------------------------------------------------
  //use in the Question card to save the quetsions/truefalse/fill in the blaks etc
  //--------------------------------------------------------------------------------
  const handleSubmit = () => {
    setEditMode(false); // Turn off edit mode after saving
  };
  //--------------------------------------------------------------------------------
  //Used to save the data in the backend(both edit and add)
  //--------------------------------------------------------------------------------
  const handleSubmit1 = async () => {
    // Check if mandatory fields are present
    if (!formData.QuestionType) {
      customAlert("", "Please select a Question Type", "warning");
      return;
    }
    if (!formData.Questions) {
      customAlert("", "Question field is mandatory", "warning");
      return;
    }
    if (!formData.Answer) {
      customAlert("", "Answer field is mandatory", "warning");
      return;
    }
    // Extract the content of the selected option for MCQs
    // Handle True/False options specifically
    if (formData.QuestionType === "True/False") {
      if (!formData.OptionA || !formData.OptionB) {
        customAlert(
          "",
          "Both Option A and Option B are required for True/False",
          "warning"
        );
        return;
      }
      // Ignore OptionC and OptionD for True/False
      formData.OptionC = "";
      formData.OptionD = "";
    }

    // Extract the content of the selected option for MCQs and True/False
    let selectedOptionContent;
    if (
      formData.QuestionType === "MCQs" ||
      formData.QuestionType === "True/False"
    ) {
      switch (formData.Answer) {
        case "OptionA":
          selectedOptionContent = formData.OptionA;
          break;
        case "OptionB":
          selectedOptionContent = formData.OptionB;
          break;
        case "OptionC":
          selectedOptionContent = formData.OptionC;
          break;
        case "OptionD":
          selectedOptionContent = formData.OptionD;
          break;
        default:
          selectedOptionContent = "";
      }
    }
    // Handle Marks formatting
    let marksData = formData.Marks;
    if (marksData && typeof marksData === "number") {
      marksData = `${marksData} Mark${marksData !== 1 ? "s" : ""}`;
    }
    // Direct answer handling for "Fill in the blanks" or "Question and Answer"
    if (
      formData.QuestionType === "Fill in the Blanks" ||
      formData.QuestionType === "Question and Answer"
    ) {
      selectedOptionContent = formData.Answer;
    }
    try {
      let response;
      // If editData is present, use PUT for updating the question
      if (editData) {
        response = await axios.put(
          `${baseURL}/updatethequestions/${editData.id}`,
          {
            ...formData,
            Answer: selectedOptionContent,
            Marks: marksData,
            lastModifiedPerson: localStorage.getItem("HTES_user_id"),
          }
        );
      } else {
        // Otherwise, POST for adding a new question
        response = await axios.post(`${baseURL}/templatequestiontodatabase`, [
          {
            ...formData,
            Answer: selectedOptionContent,
            Marks: marksData,
            lastModifiedPerson: localStorage.getItem("HTES_user_id"),
          },
        ]);
      }

      // Handle response status
      if (response.status === 200) {
        customAlert("", "Data saved successfully", "success");
        update1(true);
        onClose();
      } else if (response.status === 400) {
        customAlert("", "Duplicate data. Please add unique data", "warning");
      }
    } catch (error) {
      console.log("Error occurred while saving data:", error);
      customAlert("", "Something went wrong", "error");
    }
  };

  //--------------------------------------------------------------------------------
  //if Mcq or true/false ten option should be available
  //--------------------------------------------------------------------------------
  const renderAnswerDropdown = () => {
    if (
      formData.QuestionType === "MCQs" ||
      formData.QuestionType === "True/False"
    ) {
      // Extract options from formData
      const { OptionA, OptionB, OptionC, OptionD } = formData;

      // Map the options to maintain original names
      const options = [
        {
          value: "OptionA",
          label: "Option A",
          disabled: OptionA.trim() === "",
        },
        {
          value: "OptionB",
          label: "Option B",
          disabled: OptionB.trim() === "",
        },
        {
          value: "OptionC",
          label: "Option C",
          disabled: OptionC.trim() === "",
        },
        {
          value: "OptionD",
          label: "Option D",
          disabled: OptionD.trim() === "",
        },
      ];

      return (
        <FormControl fullWidth>
          <InputLabel id="answer-dropdown-label">Answer</InputLabel>
          <Select
            required
            labelId="answer-dropdown-label"
            id="answer-dropdown"
            value={formData.Answer}
            onChange={(e) => {
              const selectedValue = e.target.value;
              // Set the Answer field to the selected value
              setFormData({ ...formData, Answer: selectedValue });
            }}
            label="Answer"
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      // If QuestionType is not MCQs or True/False, return a disabled TextField for Answer
      return (
        <TextField
          fullWidth
          multiline
          label="Answer"
          name="Answer"
          value={formData.Answer}
          onChange={handleChange}
          required
          placeholder="Answer"
          disabled
        />
      );
    }
  };

  const handleTagChange = (selectedTags) => {
    // Update both selectedOption and formData
    setSelectedOption(selectedTags);

    // Update QuestionTag in formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      QuestionTag: selectedTags.map((tag) => tag.value).join(", "), // Join the selected tags as a comma-separated string
    }));
  };

  return (
    <div className="addtoquestiobankoutercontainer">
      <div className="addtoquestiobankoutercontainerchild">
        <div className="submitbuttonadtoquestionbank">
          <div>
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={onClose}
            >
              <ArrowBackIcon className="buttonicons" />
              &nbsp; Back
            </Button>
          </div>
          <div className="addtoquestiobankheader">
            {!editData ? "Add Question Manually" : "Edit Question Manually"}
          </div>
          <div>
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={handleSubmit1}
            >
              {!editData ? (
                <SendIcon className="buttonicons" />
              ) : (
                <UpdateIcon className="buttonicons" />
              )}
              &nbsp;{!editData ? "Save" : "Update"}
            </Button>
          </div>
        </div>
        {/* ________________selecte the Question type_____________ */}
        <div className="scrollcontaineraddtoquestionbank">
          <div className="topofthemcqsspaceaddtoquestionbank">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Select Question Type
              </InputLabel>
              <Select
                value={formData.QuestionType}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Question Type"
                onChange={(e) =>
                  setFormData({ ...formData, QuestionType: e.target.value })
                }
              >
                <MenuItem value="MCQs">MCQs</MenuItem>
                <MenuItem value="True/False">True/False</MenuItem>
                <MenuItem value="Question and Answer">
                  Question and Answer
                </MenuItem>
                <MenuItem value="Fill in the Blanks">
                  Fill in the Blanks
                </MenuItem>
              </Select>
            </FormControl>
            {/* _________________Question card_________________ */}
            {editMode ? (
              <div className="questionCardContainer1">
                <div className="cardeditmodeaddtoquestioncontainer">
                  <textarea
                    label="Questions"
                    name="Questions"
                    value={formData.Questions}
                    onChange={handleChange}
                    className="textareaaddtoquestionbank"
                    placeholder="Questions"
                  />
                </div>
                {(formData.QuestionType === "MCQs" ||
                  formData.QuestionType === "True/False") && (
                  <div className="cardeditmodeaddtoquestioncontainer">
                    <div className="opionsalignmentaddquestiontobank">A</div>
                    <textarea
                      label="Option A"
                      name="OptionA"
                      value={formData.OptionA}
                      onChange={handleChange}
                      className="optionwidthfulladdtoquestionbank"
                    />
                  </div>
                )}
                {(formData.QuestionType === "MCQs" ||
                  formData.QuestionType === "True/False") && (
                  <div className="cardeditmodeaddtoquestioncontainer">
                    <div className="opionsalignmentaddquestiontobank">B</div>
                    <textarea
                      label="Option B"
                      name="OptionB"
                      value={formData.OptionB}
                      onChange={handleChange}
                      className="optionwidthfulladdtoquestionbank"
                    />
                  </div>
                )}
                {formData.QuestionType === "MCQs" && (
                  <>
                    <div className="cardeditmodeaddtoquestioncontainer">
                      <div className="opionsalignmentaddquestiontobank">C</div>
                      <textarea
                        label="Option C"
                        name="OptionC"
                        value={formData.OptionC}
                        onChange={handleChange}
                        className="optionwidthfulladdtoquestionbank"
                      />
                    </div>
                    <div className="cardeditmodeaddtoquestioncontainer">
                      <div className="opionsalignmentaddquestiontobank">D</div>
                      <textarea
                        label="Option D"
                        name="OptionD"
                        value={formData.OptionD}
                        onChange={handleChange}
                        className="optionwidthfulladdtoquestionbank"
                      />
                    </div>
                  </>
                )}
                {(formData.QuestionType === "Fill in the Blanks" ||
                  formData.QuestionType === "Question and Answer") && (
                  <div className="cardeditmodeaddtoquestioncontainer">
                    <textarea
                      label="Answer"
                      name="Answer"
                      value={formData.Answer}
                      onChange={handleChange}
                      className="textareaaddtoquestionbank"
                      placeholder="Answer"
                    />
                  </div>
                )}
                <div className="saveiconalignaddtoquestionBank">
                  <Button
                    id="button-zoom"
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                  >
                    <SaveIcon className="buttonicons" />
                    &nbsp;Save
                  </Button>
                </div>
              </div>
            ) : (
              <div
                className="questionCardContainer1"
                style={{ marginTop: "13px" }}
              >
                <Typography
                  variant="body1"
                  className="questioncolorandalignmenthandling"
                >
                  {formData.Questions}
                </Typography>
                {(formData.QuestionType === "MCQs" ||
                  formData.QuestionType === "True/False") && (
                  <>
                    <Typography
                      variant="body1"
                      className={`optionspaddingaddtoquestionbank ${
                        formData.Answer === "OptionA" ? "selected-option" : ""
                      }`}
                    >
                      <span style={{ fontWeight: "bold" }}> A </span>{" "}
                      <span>{formData.OptionA}</span>
                    </Typography>
                    <Typography
                      variant="body1"
                      className={`optionspaddingaddtoquestionbank ${
                        formData.Answer === "OptionB" ? "selected-option" : ""
                      }`}
                    >
                      <span style={{ fontWeight: "bold" }}> B </span>{" "}
                      <span>{formData.OptionB}</span>
                    </Typography>
                  </>
                )}
                {formData.QuestionType === "MCQs" && (
                  <>
                    <Typography
                      variant="body1"
                      className={`optionspaddingaddtoquestionbank ${
                        formData.Answer === "OptionC" ? "selected-option" : ""
                      }`}
                    >
                      <span style={{ fontWeight: "bold" }}> C </span>{" "}
                      <span>{formData.OptionC}</span>
                    </Typography>
                    <Typography
                      variant="body1"
                      className={`optionspaddingaddtoquestionbank ${
                        formData.Answer === "OptionD" ? "selected-option" : ""
                      }`}
                    >
                      <span style={{ fontWeight: "bold" }}> D </span>{" "}
                      <span>{formData.OptionD}</span>
                    </Typography>
                  </>
                )}
                {(formData.QuestionType === "Fill in the Blanks" ||
                  formData.QuestionType === "Question and Answer") && (
                  <Typography
                    variant="body1"
                    className="answercolorandpaddingaddtoquestionbank"
                  >
                    Ans: {formData.Answer}
                  </Typography>
                )}
                <div className="editiconalignment">
                  {formData.Questions ? (
                    <Tooltip title="Edit Question" arrow>
                      <IconButton
                        id="button-zoom"
                        onClick={() => setEditMode(true)}
                      >
                        <EditIcon style={{ color: "blue" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add Question" arrow>
                      <IconButton
                        id="button-zoom"
                        onClick={() => setEditMode(true)}
                      >
                        <AddIcon color="success" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="rightsidecontaineraddtoquestionbank">
            {formData.QuestionType === "Question and Answer" && (
              <div className="hinttextfield">
                {/* Answer dropdown */}
                {renderAnswerDropdown()}
              </div>
            )}
            <div className="grid-for-the-fileds-to-rearrange">
              {formData.QuestionType !== "Question and Answer" && (
                <div>{renderAnswerDropdown()}</div>
              )}
              {/* ____________________Render the other tha this Textfields________ */}
              {Object.keys(formData).map((key, index) => {
                if (
                  ![
                    "Questions",
                    "OptionA",
                    "OptionB",
                    "OptionC",
                    "OptionD",
                    "QuestionType",
                    "Hint",
                    "Answer",
                    "Syllabus",
                    "Education",
                    "Branch/FieldOfStudy",
                    "Class/Sem",
                    "Subject",
                    "ChapterName",
                    "TopicName",
                    "isAdminApproved",
                    "UserFeedback",
                    "QuestionTag",
                    "LearningObjective",
                  ].includes(key)
                ) {
                  return (
                    <div key={index}>
                      {key === "DifficultyLevels" ? (
                        <TextField
                          fullWidth
                          select
                          label={key.replace(/_/g, " ")}
                          name={key}
                          value={formData[key]}
                          onChange={handleChange}
                          disabled={
                            formData["QuestionType"] === "Question and Answer"
                          }
                        >
                          {difficultyLevelsOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) : (
                        <TextField
                          fullWidth
                          label={
                            key === "Marks" ? "Marks" : key.replace(/_/g, " ")
                          }
                          name={key}
                          value={formData[key]}
                          onChange={(e) => {
                            // If the field is "Marks", ensure it's a number before updating the state
                            const value =
                              key === "Marks"
                                ? parseInt(e.target.value) || ""
                                : e.target.value;
                            setFormData({ ...formData, [key]: value });
                          }}
                          type={key === "Marks" ? "number" : "text"}
                          inputProps={{ min: 1 }} // Set minimum value as 1
                        />
                      )}
                    </div>
                  );
                }
              })}

              <FormControl fullWidth>
                <InputLabel id="isAdminApproved">Admin Approved</InputLabel>
                <Select
                  variant="outlined"
                  label="isAdminApproved"
                  value={formData.isAdminApproved ? "true" : "false"}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      isAdminApproved: e.target.value === "true",
                    })
                  }
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>

              {/* Render additional fields with labels containing numbers */}
              {[
                "Syllabus",
                "Education",
                "Branch/FieldOfStudy",
                "Class/Sem",
                "Subject",
                "ChapterName",
                "TopicName",
              ].map((field, index) => (
                <div key={index}>
                  {[
                    "Syllabus",
                    "Education",
                    "Branch/FieldOfStudy",
                    "Class/Sem",
                    "Subject",
                    "ChapterName",
                    "TopicName",
                  ].includes(field) && (
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={dropdownOptions[field] || []}
                      value={formData[field] || ""}
                      onChange={(e, newValue) =>
                        handleInputChange(newValue, field)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${index + 1}. ${field}`}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  )}
                  {![
                    "Syllabus",
                    "Education",
                    "Branch/FieldOfStudy",
                    "Class/Sem",
                    "Subject",
                    "ChapterName",
                    "TopicName",
                  ].includes(field) && (
                    <TextField
                      fullWidth
                      label={`${index + 1}. ${field}`}
                      name={field}
                      value={formData[field]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData({ ...formData, [field]: value });
                      }}
                      type="text"
                      inputProps={{ min: 1 }} // Set minimum value as 1
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="text-fileds-arrangement">
            {fields.map((field, index) => (
              <textarea
                key={index}
                multiline
                label={field.label}
                name={field.name}
                value={formData[field.name]}
                onChange={handleChange}
                placeholder={field.placeholder}
                className="optionwidthfulladdtoquestionbank"
              />
            ))}

            <CustomMultiSelect
              options={combinedOptions}
              selectedOption={selectedOption}
              setSelectedOption={handleTagChange}
              label="Question Tag"
              placeholder="Add Tag Here..."
            />
          </div>
        </div>
        {/* ____________Button section_______________ */}
      </div>
    </div>
  );
}
export default AddtoQuestionBank;
