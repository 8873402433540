import React, { useState, useEffect, useRef } from "react";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import "./instructor.css";
import { baseURL } from "../../http";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import axios from "axios";
import { Tooltip, Button } from "@mui/material";
import { customAlert } from "../SweetAlertCommon/Custom";
import AddIcon from "@mui/icons-material/Add";

function InstructorTemplateForAddEdit({
  editId,
  editData,
  onClose,
  SetDataBaseUpdate,
  handleActionClose,
}) {
  const [logindata, setLogindata] = useState([]);
  const [instructor, setInstructor] = useState({
    instructorEmail: "",
    instructorName: "",
    designation: "",
    aboutInstructor: "",
    yearOfExperience: "",
    teachingExperience: "",
    profileImage: "",
    companyLogos: [],
    socialMediaLinks: [
      { platform: "linkedin", link: "" },
      { platform: "facebook", link: "" },
      { platform: "instagram", link: "" },
      { platform: "youtube", link: "" },
    ],
  });
  const [isHovered, setIsHovered] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [editDesignation, setEditDesignation] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editWorkExperience, setEditWorkExperience] = useState(false);
  const [editTeachingExperience, setEditTeachingExperience] = useState(false);
  const [editSocialMedia, setEditSocialMedia] = useState(null);

  //--------------------------------------------------------------------------------
  //open and close the file select
  //--------------------------------------------------------------------------------
  const imageRef = useRef(null);
  const imageRef1 = useRef(null);

  //--------------------------------------------------------------------------------
  //Useffect while edit the data
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (editData) {
      const socialMediaLinksString = editData.socialMediaLinks;

      if (socialMediaLinksString) {
        try {
          let cleanedLinksString = socialMediaLinksString.trim();
          if (
            cleanedLinksString.startsWith('"') &&
            cleanedLinksString.endsWith('"')
          ) {
            cleanedLinksString = cleanedLinksString.slice(1, -1);
          }

          const parsedLinks = JSON.parse(
            cleanedLinksString.replace(/\\"/g, '"')
          );

          setInstructor({
            instructorEmail: editData.instructorEmail || "",
            instructorName: editData.instructorName || "",
            designation: editData.designation || "",
            yearOfExperience: editData.yearOfExperience || "",
            teachingExperience: editData.teachingExperience || "",
            socialMediaLinks: parsedLinks,
            aboutInstructor: editData.aboutInstructor || "",
            profileImage: editData.profileImage || null,
            companyLogos: editData.companyLogos
              ? JSON.parse(editData.companyLogos)
              : [],
            Created_By: editData.Created_By || "",
            Modified_By: editData.Modified_By || "",
          });
        } catch (error) {
          console.error("Error parsing social media links:", error);
        }
      }
    }
  }, [editData]);

  //--------------------------------------------------------------------------------
  //Update the data to the database
  //--------------------------------------------------------------------------------
  const handleUpdate = async () => {
    const currentDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const modifiedBy = localStorage.getItem("HTES_user_id");

    // Update the instructor object with the new fields
    const updatedInstructor = {
      ...instructor,
      Modified_date: currentDateTime,
      Modified_By: modifiedBy,
    };

    try {
      const response = await axios.put(
        `${baseURL}/instructor_edit/${editId}`,
        updatedInstructor
      );

      if (response.status === 200) {
        customAlert("", "Instructor Info Updated Successfully", "success");
        onClose();
        SetDataBaseUpdate(true);
        handleActionClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        customAlert("", "Instructor already exists", "warning");
      } else if (error.response && error.response.status === 402) {
        customAlert(
          "",
          "Please Add the Required Information:Name,Designation",
          "warning"
        );
      } else {
        customAlert(
          "",
          "Error updating instructor data. Please try again later.",
          "error"
        );
      }
    }
  };

  //--------------------------------------------------------------------------------
  //For click the images add 1st for profile second for company logo
  //--------------------------------------------------------------------------------

  const handleEditClick = () => {
    imageRef.current.click(); // Trigger input file selection
  };
  const handleEditClick1 = () => {
    imageRef1.current.click(); // Trigger input file selection
  };

  //--------------------------------------------------------------------------------
  //Handle the images change logos
  //--------------------------------------------------------------------------------
  const handleImageChangeLogos = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        })
      ).then((imageDataUrls) => {
        const updatedLogos = [
          ...instructor.companyLogos,
          ...imageDataUrls.filter((url) => !!url),
        ];
        setInstructor({ ...instructor, companyLogos: updatedLogos });
      });
    }
  };

  //--------------------------------------------------------------------------------
  //Delete the logos based on the hover
  //--------------------------------------------------------------------------------
  const handleDeleteClickLogos = (index) => {
    const updatedLogos = [...instructor.companyLogos];
    updatedLogos.splice(index, 1);
    setInstructor({ ...instructor, companyLogos: updatedLogos });
  };

  //--------------------------------------------------------------------------------
  //Images change handle function for the profile
  //--------------------------------------------------------------------------------

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageDataUrl = reader.result;
        setInstructor({ ...instructor, profileImage: imageDataUrl });
      };
      reader.readAsDataURL(file);
    }
  };

  //--------------------------------------------------------------------------------
  //delete the Profile images
  //--------------------------------------------------------------------------------
  const handleDeleteClick = () => {
    setInstructor({ ...instructor, profileImage: "" });
  };

  //--------------------------------------------------------------------------------
  //Handle the Email chnage and name select
  //--------------------------------------------------------------------------------
  const handleEmailChange = (event, newValue) => {
    const selectedInstructor = logindata.find(
      (user) => user.email === newValue
    );
    if (selectedInstructor) {
      setInstructor({
        ...instructor,
        instructorEmail: selectedInstructor.email,
        instructorName: selectedInstructor.name,
      });
    }
  };

  //--------------------------------------------------------------------------------
  //Handle the Designation change
  //--------------------------------------------------------------------------------

  const handleDesignationChange = (event) => {
    setInstructor({ ...instructor, designation: event.target.value });
  };

  //--------------------------------------------------------------------------------
  //Handle Work Experience chnage
  //--------------------------------------------------------------------------------

  const handleWorkExperienceChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setInstructor({ ...instructor, yearOfExperience: value });
    }
  };

  //--------------------------------------------------------------------------------
  //Handle Teaching experience change
  //--------------------------------------------------------------------------------
  const handleTeachingExperienceChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setInstructor({ ...instructor, teachingExperience: value });
    }
  };

  //--------------------------------------------------------------------------------
  //Handle Socila medi link changes
  //--------------------------------------------------------------------------------
  const handleSocialMediaChange = (event, platform) => {
    const updatedLinks = instructor.socialMediaLinks.map((link) =>
      link.platform === platform ? { ...link, link: event.target.value } : link
    );
    setInstructor({ ...instructor, socialMediaLinks: updatedLinks });
  };
  //--------------------------------------------------------------------------------
  //Fetch the teacher from the login table
  //--------------------------------------------------------------------------------

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/getLoginTable`);
      const teacherUsers = response.data.filter(
        (user) => user.usertype === "Teacher"
      );
      setLogindata(teacherUsers);
    } catch (error) {
      console.error("Error fetching teacher data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //--------------------------------------------------------------------------------
  //Handle the about instructor text field
  //--------------------------------------------------------------------------------

  const handleAboutInstructorChange = (event) => {
    setInstructor({ ...instructor, aboutInstructor: event.target.value });
  };

  //--------------------------------------------------------------------------------
  // save the data to the database
  //--------------------------------------------------------------------------------
  const handleSave = async () => {
    const currentDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const data = new FormData();
    data.append("instructorEmail", instructor.instructorEmail);
    data.append("instructorName", instructor.instructorName);
    data.append("designation", instructor.designation);
    data.append("yearOfExperience", instructor.yearOfExperience);
    data.append("teachingExperience", instructor.teachingExperience);
    data.append("aboutInstructor", instructor.aboutInstructor);
    data.append("Created_date", currentDateTime);
    data.append("Modified_date", currentDateTime);
    data.append("Created_By", localStorage.getItem("HTES_user_id"));
    data.append("Modified_By", localStorage.getItem("HTES_user_id"));
    data.append("profileImage", instructor.profileImage);
    data.append("companyLogos", instructor.companyLogos);
    const companyLogosString = JSON.stringify(instructor.companyLogos);
    data.append("companyLogos", companyLogosString);
    // Convert socialMediaLinks array of objects to a JSON string
    const socialMediaLinksString = JSON.stringify(instructor.socialMediaLinks);
    data.append("socialMediaLinks", socialMediaLinksString);

    try {
      const response = await axios.post(`${baseURL}/instructor`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        customAlert("", "Instructor Info Added Successfully", "success");
        onClose();
        SetDataBaseUpdate(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        customAlert("", "Instructor already exists", "warning");
      } else if (error.response && error.response.status === 402) {
        customAlert(
          "",
          "Please Add the Required Information:Name,Designation",
          "warning"
        );
      } else {
        customAlert(
          "",
          "Error updating instructor data. Please try again later.",
          "error"
        );
      }
    }
  };

  return (
    <div className="instructor-template-container-template1">
      <IconButton
        id="button-zoom"
        className="instructor-close-iconButton-right-top-corner"
        onClick={onClose}
      >
        <CloseIcon className="instructor-close-iconButton-right-top-corner-symbol" />
      </IconButton>
      {/* ______________________Modal header_______________________ */}
      <div className="instructor-header-and-switch-container">
        <div>{!editData ? "Add Instructor" : "Edit Instructor"}</div>
      </div>
      <div className="instructor-template-container-template1-scroll">
        {/* ___________Image Section_____________ */}
        <div className="make-the-image-container-center-template1">
          <div
            className="instructor-image-container-template1"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={instructor.profileImage}
              alt=""
              onClick={handleEditClick}
            />

            {isHovered && (
              <div className="image-edit-delete-buttons">
                <Tooltip
                  title={`${!instructor.profileImage ? "Add" : "Edit"}`}
                  arrow
                >
                  <IconButton onClick={handleEditClick}>
                    {!instructor.profileImage ? (
                      <AddIcon className="buttonicons-edit" />
                    ) : (
                      <EditIcon className="buttonicons-edit" />
                    )}
                  </IconButton>
                </Tooltip>
                <input
                  type="file"
                  accept="image/*"
                  ref={imageRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {instructor.profileImage && (
                  <Tooltip title="Delete" arrow>
                    <IconButton onClick={handleDeleteClick}>
                      <DeleteIcon className="buttonicons-delete" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
        {/* ___________Image Section end_____________ */}
        <div className="instructor-name-and-designation-container-template2">
          {!editDesignation && (
            <div>
              {editName ? (
                <Autocomplete
                  options={logindata.map((user) => user.email)}
                  renderInput={(params) => (
                    <TextField {...params} label="Instructor Email" fullWidth />
                  )}
                  value={instructor.instructorEmail}
                  onChange={handleEmailChange}
                  onBlur={() => setEditName(false)}
                />
              ) : (
                <div>
                  <div className="instructorname-template2">
                    {instructor.instructorName
                      ? instructor.instructorName
                      : "Instructor Name"}

                    <Tooltip
                      title={`${!instructor.instructorName ? "Add" : "Edit"}`}
                      arrow
                    >
                      <IconButton onClick={() => setEditName(true)}>
                        {!instructor.instructorName ? (
                          <AddIcon className="buttonicons-edit" />
                        ) : (
                          <EditIcon className="buttonicons-edit" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          )}
          {!editName && (
            <div className="instructordesignation-template2">
              {editDesignation ? (
                <TextField
                  fullWidth
                  value={instructor.designation}
                  onBlur={() => setEditDesignation(false)}
                  onChange={handleDesignationChange}
                />
              ) : (
                <div>
                  {instructor.designation
                    ? instructor.designation
                    : "Designation"}

                  <Tooltip
                    title={`${!instructor.designation ? "Add" : "Edit"}`}
                    arrow
                  >
                    <IconButton onClick={() => setEditDesignation(true)}>
                      {!instructor.designation ? (
                        <AddIcon className="buttonicons-edit" />
                      ) : (
                        <EditIcon className="buttonicons-edit" />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
        {/* ___________________end_________________________ */}

        {/* ___________________Year of experience and teaching experience_________________________ */}

        <div className="instructor-experience-work-teaching-container">
          {/* __________Instructor work experience __________ */}
          {!editTeachingExperience && (
            <>
              {editWorkExperience ? (
                <TextField
                  fullWidth
                  value={instructor.yearOfExperience}
                  onBlur={() => setEditWorkExperience(false)}
                  onChange={handleWorkExperienceChange}
                  type="number"
                  inputProps={{ min: 0 }}
                />
              ) : (
                <div className="instructor-work-experience">
                  <WorkHistoryOutlinedIcon className="experience-icon-alignment" />
                  <div>
                    <div className="year-font-size-and-align">
                      {instructor.yearOfExperience}+ Years{" "}
                      <Tooltip
                        title={`${
                          !instructor.yearOfExperience ? "Add" : "Edit"
                        }`}
                        arrow
                      >
                        <IconButton onClick={() => setEditWorkExperience(true)}>
                          {!instructor.yearOfExperience ? (
                            <AddIcon className="buttonicons-edit" />
                          ) : (
                            <EditIcon className="buttonicons-edit" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="work-experien-teaching-font-align">
                      Work Experience
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {/* __________Instructor teaching experience __________ */}
          {!editWorkExperience && (
            <>
              {editTeachingExperience ? (
                <TextField
                  fullWidth
                  value={instructor.teachingExperience}
                  onBlur={() => setEditTeachingExperience(false)}
                  onChange={handleTeachingExperienceChange}
                  type="number"
                  inputProps={{ min: 0 }}
                />
              ) : (
                <div className="instructor-work-experience">
                  <SupervisorAccountOutlinedIcon className="experience-icon-alignment" />
                  <div>
                    <div className="year-font-size-and-align">
                      {instructor.teachingExperience}+ Years{" "}
                      <Tooltip
                        title={`${
                          !instructor.teachingExperience ? "Add" : "Edit"
                        }`}
                        arrow
                      >
                        <IconButton
                          onClick={() => setEditTeachingExperience(true)}
                        >
                          {!instructor.teachingExperience ? (
                            <AddIcon className="buttonicons-edit" />
                          ) : (
                            <EditIcon className="buttonicons-edit" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="work-experien-teaching-font-align">
                      Teaching Experience
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {/* _____________social media logos __________ */}
        <div className="instructor-worked-company-social-link-container">
          {instructor.socialMediaLinks.map((link, index) => (
            <div key={index}>
              {editSocialMedia === link.platform ? (
                <TextField
                  focused
                  fullWidth
                  value={link.link}
                  onBlur={() => setEditSocialMedia(null)}
                  onChange={(event) =>
                    handleSocialMediaChange(event, link.platform)
                  }
                />
              ) : (
                <IconButton onClick={() => setEditSocialMedia(link.platform)}>
                  {link.platform === "linkedin" && (
                    <Tooltip title="click to add linkedin link" arrow>
                      <LinkedInIcon className="social-media-hover-background-color" />
                    </Tooltip>
                  )}

                  {link.platform === "instagram" && (
                    <Tooltip title="click to add instagram link" arrow>
                      <InstagramIcon className="social-media-hover-background-color" />
                    </Tooltip>
                  )}
                  {link.platform === "facebook" && (
                    <Tooltip title="click to add facebook link" arrow>
                      <FacebookIcon className="social-media-hover-background-color" />
                    </Tooltip>
                  )}
                  {link.platform === "youtube" && (
                    <Tooltip title="click to add youtube link" arrow>
                      <YouTubeIcon className="social-media-hover-background-color" />
                    </Tooltip>
                  )}
                </IconButton>
              )}
            </div>
          ))}
        </div>
        {/* ___________________________Logos-worked-company________________________ */}
        <div className="overflow-scroll-container">
          <div className="instructor-worked-company-logos">
            {instructor.companyLogos.length === 0 && (
              <h6 className="instructor-logos-heading">
                Add Worked Company Logos
              </h6>
            )}
            {instructor.companyLogos.map((logo, index) => (
              <div
                key={index}
                className="instructor-worked-company-logos-hold-container"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <img src={logo} alt={`Logo ${index}`} />
                {hoveredIndex === index && (
                  <div className="delete-logos-conatiner-image-induvitual">
                    <Tooltip title="Delete" arrow>
                      <IconButton onClick={() => handleDeleteClickLogos(index)}>
                        <DeleteIcon className="buttonicons-delete" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div>
            <Tooltip
              title={`${instructor.companyLogos.length === 0 ? "Add" : "Edit"}`}
              arrow
            >
              <IconButton onClick={handleEditClick1}>
                {instructor.companyLogos.length === 0 ? (
                  <AddIcon className="buttonicons-edit" />
                ) : (
                  <EditIcon className="buttonicons-edit" />
                )}
              </IconButton>
            </Tooltip>
            <input
              type="file"
              accept="image/*"
              ref={imageRef1}
              style={{ display: "none" }}
              onChange={handleImageChangeLogos}
              multiple // Allow multiple file selection
            />
          </div>
        </div>
        <div className="about-instructor-conatiner">
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            label="About Instructor"
            value={instructor.aboutInstructor}
            onChange={handleAboutInstructorChange}
          />
        </div>
      </div>
      {/* ______________________save_______________________ */}

      <div className="instructor-Save-Button-Alignment-container">
        {!editData ? (
          <Button
            id="button-zoom"
            variant="contained"
            color="success"
            onClick={handleSave}
          >
            <SaveIcon className="buttonicons" />
            Save
          </Button>
        ) : (
          <Button
            id="button-zoom"
            variant="contained"
            color="success"
            onClick={handleUpdate}
          >
            <UpdateIcon className="buttonicons" />
            Update
          </Button>
        )}
      </div>
      {/* ______________________end_______________________ */}
    </div>
  );
}

export default InstructorTemplateForAddEdit;
