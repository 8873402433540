import React from "react";

function InstructionPage() {
  return (
    <>
      <div className="font-weight-b0ld-in-all-render">General Instructions</div>

      <div className="instructionpage1">
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step1:</span>Click
            the 'START' Button to start the test.
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction1.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step2:</span>Check
            the Checkbox and Enable the 'AGREE' Button.
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction2.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step3:</span>Once
            'AGREE' Button is Enabled then Click to proced Further.
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction3.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step4:</span>Make
            sure once the Test started Do not exit fullScreen
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction3A.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step5:</span>
            General Information of the quiz page see all the tags{" "}
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction4.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step6:</span>User
            Have any issues send feedback to the admin.{" "}
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction5.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step6:</span>For
            'Succesfull' feedback you see the Alert like this.
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction6.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step7:</span>During
            the test Keypress is disabled,if you press any keys will reported
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction7.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step8:</span>Once
            Test completed,Then click on the Submit to save the Result
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction10.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step9:</span>Once
            Result saved successfully then you received alert like this
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction9.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step10:</span>
            During Test Exit the fullScreen Alert will Appear if you click
            cancle then Return to test{" "}
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction11.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
        <div>
          <div className="instructionpage2">
            <span className="font-weight-b0ld-in-all-render">Step11:</span>If
            you click 'OK' terminate the Test and come back to the Admin
            DashBoard
          </div>
          <div className="instructionpage3">
            <img
              src="/instruction/instruction12.png"
              alt="General instruction"
              className="image-height-and-width-control"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default InstructionPage;
