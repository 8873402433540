// import React, { useState } from "react";
// import { Grid, Paper, Container } from "@mui/material";
// import FormatBoldIcon from "@mui/icons-material/FormatBold";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
// import LanguageIcon from "@mui/icons-material/Language";
// import "./TestSeries.css";

// function CombinedLayout({
//   title,
//   totalQuestions,
//   buttons,
//   barNames,
//   questionDifficultyButton,
//   users,
//   questions,
//   marks,
//   time,
//   isFree,
// }) {
//   const [selectedBarName, setSelectedBarName] = useState(null);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedDifficultyButton, setSelectedDifficultyButton] = useState(
//     null
//   );

//   const thunderboltStyle = {
//     color: "yellow",
//   };

//   const iconSize = 11;
//   const paragraphStyle = {
//     fontSize: "13px",
//   };

//   const spaceStyle = {
//     marginRight: "30px",
//   };

//   const handleDifficultyButtonClick = (label) => {
//     console.log(`Selected difficulty: ${label}`);
//     setSelectedDifficultyButton(label);
//   };

//   return (
//     <div>
//       <Container>
//         <Paper>
//           <Grid container>
//             <div className="questionHeadingContainer">
//               <div className="questionHeading">
//                 <h4>
//                   <strong>
//                     {title} ({totalQuestions})
//                   </strong>
//                 </h4>
//               </div>

//               <div className="questionDifficultyButtonContainer">
//                 {questionDifficultyButton.map((button, index) => (
//                   <button
//                     key={index}
//                     className={`buttonsForQuestionDifficulty ${
//                       selectedDifficultyButton === button.label ? "selected" : ""
//                     }`}
//                     onClick={() => handleDifficultyButtonClick(button.label)}
//                   >
//                     {button.label}
//                   </button>
//                 ))}
//               </div>
//             </div>

//             <Grid item xs={3}></Grid>
//           </Grid>
//         </Paper>

//         <Paper className="questionCardPaper">
//           {isFree && (
//             <div className="freeContainer">
//               <div className="freeBox">FREE</div>
//             </div>
//           )}
//           <div className="cardContent">
//             <div className="titleAndUsersContainer">
//               <h6>
//                 <strong>{title}</strong>
//               </h6>
//               <div className="usersContainer">
//                 <p className="usersText">
//                   <span style={thunderboltStyle}>⚡</span> {users} Users
//                 </p>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   marginLeft: "auto",
//                 }}
//               >
//                 <div></div>
//                 {isFree ? (
//                   <button className="buttonStyle">Start Now</button>
//                 ) : (
//                   <button className="unlockButtonStyle">
//                     {" "}
//                     <span style={{ color: "deepskyblue" }}>🔒</span>Unlock Now
//                   </button>
//                 )}
//               </div>
//             </div>

//             <div className="singleLineData">
//               <p style={paragraphStyle}>
//                 <span className="questionMarkStyle">
//                   <HelpOutlineIcon
//                     style={{ fontSize: iconSize, color: "black" }}
//                   />
//                 </span>
//                 <span style={spaceStyle}>{questions}&nbsp;Questions</span>
//                 <span style={spaceStyle}>
//                   <FeedOutlinedIcon
//                     style={{ fontSize: 12, color: "black", marginRight: "3px" }}
//                   />
//                   {marks}&nbsp;Marks
//                 </span>
//                 <span style={spaceStyle}>
//                   <AccessTimeIcon
//                     style={{
//                       fontSize: iconSize,
//                       color: "black",
//                       marginRight: "3px",
//                     }}
//                   />
//                   {time}&nbsp;Mins
//                 </span>
//               </p>
//             </div>
//           </div>
//         </Paper>

//         <p style={{ color: "deepskyblue", fontSize: "13px" }}>
//           <LanguageIcon
//             style={{
//               fontSize: iconSize,
//               color: "rgba(9,177,236, 0.8)",
//               marginRight: "6px",
//               marginLeft: "20px",
//             }}
//           />
//           English
//         </p>
//       </Container>
//     </div>
//   );
// }

// export default CombinedLayout;

// import React, { useState } from "react";
// import { Grid, Paper, Container } from "@mui/material";
// import FormatBoldIcon from "@mui/icons-material/FormatBold";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
// import LanguageIcon from "@mui/icons-material/Language";
// import "./TestSeries.css";

// function CombinedLayout({
//   type,
//   title,
//   totalQuestions,
//   questionDifficultyButton,
//   users,
//   questions,
//   marks,
//   time,
//   isFree,
//   languages,

// }) {
//   const [selectedBarName, setSelectedBarName] = useState(null);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedDifficultyButton, setSelectedDifficultyButton] =
//     useState(null);
//   const [selectedDifficulty, setSelectedDifficulty] = useState("All");

//   const thunderboltStyle = {
//     color: "yellow",
//   };

//   const iconSize = 11;
//   const paragraphStyle = {
//     fontSize: "13px",
//   };

//   const spaceStyle = {
//     marginRight: "30px",
//   };

//   const handleDifficultyButtonClick = (label) => {
//     console.log(`Selected difficulty: ${label}`);
//     setSelectedDifficultyButton(label);
//   };

//   const questionData = [];

//   // Check if the data is available
//   if (!type) {
//     return null;
//   }

//   return (
//     <div>
//       <Container>
//         <Paper elevation={5} style={{ padding: "20px" }}>
//           <Grid container>
//             {type === "testHeading" && (
//               <div className="questionHeadingContainer">
//                 <div className="questionHeading">
//                   <h4>
//                     <strong>
//                       {title} ({totalQuestions})
//                     </strong>
//                   </h4>
//                 </div>

//                 <div className="questionDifficultyButtonContainer">
//                   {questionDifficultyButton.map((button, index) => (
//                     <button
//                       key={index}
//                       className={`buttonsForQuestionDifficulty ${
//                         selectedDifficultyButton === button.label
//                           ? "selected"
//                           : ""
//                       }`}
//                       onClick={() => handleDifficultyButtonClick(button.label)}>
//                       {button.label}
//                     </button>
//                   ))}
//                 </div>

//                 {questionData
//                   .filter((question) =>
//                     selectedDifficulty === "All"
//                       ? true // Show all questions if "All" is selected
//                       : question.difficulty === selectedDifficulty
//                   )
//                   .map((data, index) => (
//                     <questionCards key={index} {...data} />
//                   ))}
//               </div>
//             )}

//             {type === "questionCard" && (
//               <Paper className="questionCardPaper">
//                 {isFree && (
//                   <div className="freeContainer">
//                     <div className="freeBox">FREE</div>
//                   </div>
//                 )}
//                 <div className="cardContent">
//                   <div className="titleAndUsersContainer">
//                     <h6>
//                       <strong>{title}</strong>
//                     </h6>
//                     <div className="usersContainer">
//                       <p className="usersText">
//                         <span style={thunderboltStyle}>⚡</span> {users} Users
//                         &nbsp;
//                         {/* {languages && languages.length > 0 && (
//                         <span className="languagesContainer">
//                           {languages.map((language, index) => (
//                             <span key={index} className="languageText">
//                                 {index === 0 ? "" : ", "}
//                               <LanguageIcon
//                                 style={{
//                                   fontSize: iconSize,
//                                   color: "rgba(9,177,236, 0.8)",
//                                   marginRight: "6px",
//                                 }}
//                               />
//                               {language}
//                               </span>
//                           ))}
//                           </span>
//                           )} */}
//                         {languages && languages.length > 0 && (
//                           <span className="languagesContainer">
//                             <LanguageIcon
//                               style={{
//                                 fontSize: iconSize,
//                                 color: "rgba(9,177,236, 0.8)",
//                                 marginRight: "6px",
//                               }}
//                             />
//                             {languages.join(", ")}
//                           </span>
//                         )}
//                       </p>
//                     </div>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                         marginLeft: "auto",
//                       }}>
//                       <div></div>
//                       {isFree ? (
//                         <button className="buttonStyle">Start Now</button>
//                       ) : (
//                         <button className="unlockButtonStyle">
//                           {" "}
//                           <span style={{ color: "deepskyblue" }}>🔒</span>Unlock
//                           Now
//                         </button>
//                       )}
//                     </div>
//                   </div>

//                   <div className="singleLineData">
//                     <p style={paragraphStyle}>
//                       <span className="questionMarkStyle">
//                         <HelpOutlineIcon
//                           style={{ fontSize: iconSize, color: "black" }}
//                         />
//                       </span>
//                       <span style={spaceStyle}>{questions}&nbsp;Questions</span>
//                       <span style={spaceStyle}>
//                         <FeedOutlinedIcon
//                           style={{
//                             fontSize: 12,
//                             color: "black",
//                             marginRight: "3px",
//                           }}
//                         />
//                         {marks}&nbsp;Marks
//                       </span>
//                       <span style={spaceStyle}>
//                         <AccessTimeIcon
//                           style={{
//                             fontSize: iconSize,
//                             color: "black",
//                             marginRight: "3px",
//                           }}
//                         />
//                         {time}&nbsp;Mins
//                       </span>
//                     </p>
//                   </div>
//                 </div>
//               </Paper>
//             )}

//             {/* {type === "questionCard" && (
//               <p style={{ color: "deepskyblue", fontSize: "13px" }}>
//                 <LanguageIcon
//                   style={{
//                     fontSize: iconSize,
//                     color: "rgba(9,177,236, 0.8)",
//                     marginRight: "6px",
//                     marginLeft: "20px",
//                   }}
//                 />
//                 English
//               </p>
//             )} */}
//           </Grid>
//         </Paper>
//       </Container>
//     </div>
//   );
// }

// export default CombinedLayout;

// import React, { useState } from 'react';
// import { Grid, Paper, Container, Button } from '@mui/material';
// import FormatBoldIcon from '@mui/icons-material/FormatBold';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
// import LanguageIcon from '@mui/icons-material/Language';
// import './TestSeries.css';

// function CombinedLayout({
//   type,
//   title,
//   totalQuestions,
//   questionDifficultyButton,
//   users,
//   questions,
//   marks,
//   time,
//   isFree,
//   languages,
// }) {
//   const [selectedDifficulty, setSelectedDifficulty] = useState('All');

//   const thunderboltStyle = {
//     color: 'yellow',
//   };

//   const iconSize = 11;
//   const paragraphStyle = {
//     fontSize: '13px',
//   };

//   const spaceStyle = {
//     marginRight: '30px',
//   };

//   const handleDifficultyButtonClick = (label) => {
//     console.log(`Selected difficulty: ${label}`);
//     setSelectedDifficulty(label);
//   };

//   // const questionData = [
//   //   {
//   //     type: "questionCard",
//   //     title: "CT 1: Synonyms ('A to B')",
//   //     users: "65.2k",
//   //     questions: 10,
//   //     marks: 20,
//   //     time: 4,
//   //     isFree: true,
//   //     languages: ["English", "Hindi"],
//   //     difficulty: "Easy",
//   //   },
//   //   {
//   //     type: "questionCard",
//   //     title: "CT 2: Antonyms ('B to A')",
//   //     users: "72.8k",
//   //     questions: 10,
//   //     marks: 20,
//   //     time: 4,
//   //     isFree: true,
//   //     languages: ["English", "Hindi"],
//   //     difficulty: "Moderate",
//   //   },
//   //   {
//   //     type: "questionCard",
//   //     title: "CT 3: Idiom Meaning ('Phrase Hunt')",
//   //     users: "60.5k",
//   //     questions: 10,
//   //     marks: 20,
//   //     time: 4,
//   //     isFree: true,
//   //     languages: ["English", "Hindi"],
//   //     difficulty: "Difficult",
//   //   },
//   // ]; // This needs to be populated with your actual data

//   // Check if the data is available
//   if (!type) {
//     return null;
//   }

//   return (
//     <div>
//       <Container>
//         <Paper elevation={5} style={{ padding: '20px' }}>
//           <Grid container>
//             {type === 'testHeading' && (
//               <div className="questionHeadingContainer">
//                 <div className="questionHeading">
//                   <h4>
//                     <strong>
//                       {title} ({totalQuestions})
//                     </strong>
//                   </h4>
//                 </div>

//                 <div className="questionDifficultyButtonContainer">
//                   {questionDifficultyButton.map((button, index) => (
//                     <Button
//                       key={index}
//                       variant="contained"
//                       color="primary"
//                       onClick={() => handleDifficultyButtonClick(button.label)}
//                     >
//                       {button.label}
//                     </Button>
//                   ))}
//                 </div>

//                 {questions
//                   .filter((question) =>
//                     selectedDifficulty === 'All'
//                       ? true // Show all questions if "All" is selected
//                       : question.difficulty === selectedDifficulty
//                   )
//                   .map((data, index) => (
//                     <Paper key={index} className="questionCardPaper">
//                       {/* ... existing code ... */}
//                     </Paper>
//                   ))}
//                       {data.isFree && (
//                         <div className="freeContainer">
//                           <div className="freeBox">FREE</div>
//                         </div>
//                       )}
//                       <div className="cardContent">
//                         <div className="titleAndUsersContainer">
//                           <h6>
//                             <strong>{data.title}</strong>
//                           </h6>
//                           <div className="usersContainer">
//                             <p className="usersText">
//                               <span style={thunderboltStyle}>⚡</span> {data.users} Users&nbsp;
//                               {data.languages && data.languages.length > 0 && (
//                                 <span className="languagesContainer">
//                                   <LanguageIcon
//                                     style={{
//                                       fontSize: iconSize,
//                                       color: 'rgba(9,177,236, 0.8)',
//                                       marginRight: '6px',
//                                     }}
//                                   />
//                                   {data.languages.join(', ')}
//                                 </span>
//                               )}
//                             </p>
//                           </div>
//                           <div
//                             style={{
//                               display: 'flex',
//                               justifyContent: 'space-between',
//                               alignItems: 'center',
//                               marginLeft: 'auto',
//                             }}
//                           >
//                             <div></div>
//                             {data.isFree ? (
//                               <button className="buttonStyle">Start Now</button>
//                             ) : (
//                               <button className="unlockButtonStyle">
//                                 {' '}
//                                 <span style={{ color: 'deepskyblue' }}>🔒</span>Unlock Now
//                               </button>
//                             )}
//                           </div>
//                         </div>

//                         <div className="singleLineData">
//                           <p style={paragraphStyle}>
//                             <span className="questionMarkStyle">
//                               <HelpOutlineIcon style={{ fontSize: iconSize, color: 'black' }} />
//                             </span>
//                             <span style={spaceStyle}>{data.questions}&nbsp;Questions</span>
//                             <span style={spaceStyle}>
//                               <FeedOutlinedIcon
//                                 style={{
//                                   fontSize: 12,
//                                   color: 'black',
//                                   marginRight: '3px',
//                                 }}
//                               />
//                               {data.marks}&nbsp;Marks
//                             </span>
//                             <span style={spaceStyle}>
//                               <AccessTimeIcon
//                                 style={{
//                                   fontSize: iconSize,
//                                   color: 'black',
//                                   marginRight: '3px',
//                                 }}
//                               />
//                               {data.time}&nbsp;Mins
//                             </span>
//                           </p>
//                         </div>
//                       </div>
//                     </Paper>
//                   ))}
//               </div>
//             )}
//           </Grid>
//         </Paper>
//       </Container>
//     </div>
//   );
// }

// export default CombinedLayout;

import React, { useState } from "react";
import { Grid, Paper, Container, Button } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import "./TestSeries.css";

function CombinedLayout({
  type,
  title,
  totalQuestions,
  questionDifficultyButton,
  questions, // This prop will contain the array of questions
}) {
  const [selectedDifficulty, setSelectedDifficulty] = useState("All");

  const thunderboltStyle = {
    color: "yellow",
  };

  const iconSize = 11;
  const paragraphStyle = {
    fontSize: "13px",
  };

  const spaceStyle = {
    marginRight: "30px",
  };

  const handleDifficultyButtonClick = (label) => {
    console.log(`Selected difficulty: ${label}`);
    setSelectedDifficulty(label);
  };

  // Check if the data is available
  if (!type) {
    return null;
  }

  return (
    <div>
      <Container>
        {type === "testHeading" && (
          <Paper
            elevation={5}
            style={{ padding: "20px", backgroundColor: "rgb(246, 250, 252)" }}>
            <Grid container>
              {/* {type === 'testHeading' && ( */}
              <div className="questionHeadingContainer">
                <div className="questionHeading">
                  <h4>
                    <strong>
                      {title} ({totalQuestions})
                    </strong>
                  </h4>
                </div>

                <div
                  className="questionDifficultyButtonContainer"
                  style={{ marginTop: "20px", marginBottom: "20px" }}>
                  {questionDifficultyButton.map((button, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      color="primary"
                      onClick={() => handleDifficultyButtonClick(button.label)}
                      style={{
                        backgroundColor: "darkgreen",
                        color: "White",
                        marginRight: "2%",
                      }}>
                      {button.label}
                    </Button>
                  ))}
                </div>

                {questions
                  .filter((question) =>
                    selectedDifficulty === "All"
                      ? true // Show all questions if "All" is selected
                      : question.difficulty === selectedDifficulty
                  )
                  .map((data, index) => (
                    <Paper
                      elevation={5}
                      key={index}
                      className="questionCardPaper">
                      {data.isFree && (
                        <div className="freeContainer">
                          <div className="freeBox">FREE</div>
                        </div>
                      )}
                      <div className="cardContent">
                        <div className="titleAndUsersContainer">
                          <h6>
                            <strong>{data.title}</strong>
                          </h6>
                          <div className="usersContainer">
                            <p className="usersText">
                              <span style={thunderboltStyle}>⚡</span>{" "}
                              {data.users} Users&nbsp;
                              {data.languages && data.languages.length > 0 && (
                                <span className="languagesContainer">
                                  <LanguageIcon
                                    style={{
                                      fontSize: iconSize,
                                      color: "rgba(9,177,236, 0.8)",
                                      marginRight: "5px",
                                    }}
                                  />
                                  {data.languages.join(", ")}
                                </span>
                              )}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}>
                            <div></div>
                            {data.isFree ? (
                              <button className="buttonStyle">Start Now</button>
                            ) : (
                              <button className="unlockButtonStyle">
                                {' '}
                                <span style={{ color: 'deepskyblue' }}>🔒</span>Unlock Now
                              </button>
                            )}
                            {/* {data.isFree ? (
                              <div className="buttonContainer">
                                <button className="buttonStyle">
                                  Start Now
                                </button>
                              </div>
                            ) : (
                              <div className="buttonContainer">
                                <button className="unlockButtonStyle">
                                  {" "}
                                  <span style={{ color: "deepskyblue" }}>
                                    🔒
                                  </span>
                                  Unlock Now
                                </button>
                              </div>
                            )} */}
                          </div>
                        </div>

                        <div className="singleLineData">
                          <p style={paragraphStyle}>
                            <span className="questionMarkStyle">
                              <HelpOutlineIcon
                                style={{ fontSize: iconSize, color: "black" }}
                              />
                            </span>
                            <span style={spaceStyle}>
                              {data.questions}&nbsp;Questions
                            </span>
                            <span style={spaceStyle}>
                              <FeedOutlinedIcon
                                style={{
                                  fontSize: 12,
                                  color: "black",
                                  marginRight: "3px",
                                }}
                              />
                              {data.marks}&nbsp;Marks
                            </span>
                            <span style={spaceStyle}>
                              <AccessTimeIcon
                                style={{
                                  fontSize: iconSize,
                                  color: "black",
                                  marginRight: "3px",
                                }}
                              />
                              {data.time}&nbsp;Mins
                            </span>
                          </p>
                        </div>
                      </div>
                    </Paper>
                  ))}
              </div>
              {/* )} */}
            </Grid>
          </Paper>
        )}
      </Container>
    </div>
  );
}

export default CombinedLayout;
