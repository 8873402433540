import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function DownloadTemplate({ columns }) {
  const [anchorEl, setAnchorEl] = useState(null);

  //--------------------------------------------------------------------------------
  //open and close the menu(dropdown)
  //--------------------------------------------------------------------------------
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //--------------------------------------------------------------------------------
  //handle download the template
  //--------------------------------------------------------------------------------
  const handleDownload = async (format) => {
    try {
      // Filter out created_at, updated_at, and id columns
      const filteredColumns = columns.filter(
        (column) =>
          column.field !== "created_at" &&
          column.field !== "updated_at" &&
          column.field !== "id"
      );

      // Fetch data to get column names (filtered)
      const columnNames = filteredColumns.map((column) => column.field);

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Create the first worksheet with column names
      const ws1 = XLSX.utils.aoa_to_sheet([columnNames]);
      XLSX.utils.book_append_sheet(wb, ws1, "Template");

      // Example rows (you can adjust these as needed)
      const exampleRow = [
        "NCERT",
        "School",
        "School",
        "1st Std",
        "Social",
        "Major Developments in the World",
        "History",
      ];
      const exampleRow1 = [
        "VTU",
        "College",
        "BE",
        "1st Sem",
        "Engineering Subject",
        "Field Theory",
        "Basic of Field Theory",
      ];

      // Create the second worksheet with the example rows
      const ws2 = XLSX.utils.aoa_to_sheet([
        columnNames,
        exampleRow,
        exampleRow1,
      ]);
      XLSX.utils.book_append_sheet(wb, ws2, "Example");

      // Convert workbook to binary string
      const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

      // Convert binary string to Blob
      const blob = new Blob([s2ab(wbBinary)], {
        type: "application/octet-stream",
      });

      // Determine the file extension based on the selected format
      const extension = format === "csv" ? "csv" : "xlsx";

      // Save Blob as file
      saveAs(blob, `Education_RoadMap_Template.${extension}`);
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Convert string to ArrayBuffer
  //--------------------------------------------------------------------------------
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
      <div className="addEducationalhoverandcolor">
        {/* _____________Button section__________ */}
        <div onClick={handleMenuOpen}>
          <FileDownloadIcon
            className="addEducationaladdicon"
            fontSize="large"
            color="primary"
          />
          <br />
          <span>Download Educational RoadMap</span>
        </div>
      </div>
      {/* _________________dropdown section____________ */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ marginTop: "-50px" }}
      >
        <MenuItem onClick={() => handleDownload("csv")}>
          Download as CSV
        </MenuItem>
        <MenuItem onClick={() => handleDownload("excel")}>
          Download as Excel
        </MenuItem>
      </Menu>
    </>
  );
}

export default DownloadTemplate;
