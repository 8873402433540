import React, { useState } from "react";
import "./coursecreatetemplate.css";
import { Avatar, Button, IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import HeaderTab from "./HeaderTab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Description from "./Description";
import OverView from "./OverView";
import Content from "./Content";
import FAQSectionTab from "./FAQSectionTab";
import InstructorTab from "./InstructorTab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";

function CourseCreationPage({
  courseNamesDropdown,
  selectedCourse,
  tags,
  setCoursePage,
}) {
  const tabs = [
    "Header",
    "Overview",
    "Description",
    "Content",
    "Instructor",
    "FAQ",
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleBackClick = () => {
    setCoursePage(false);
  };

  const handleNextClick = () => {
    if (activeTabIndex < tabs.length - 1) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  };

  const handlePreviousClick = () => {
    if (activeTabIndex > 0) {
      setActiveTabIndex(activeTabIndex - 1);
    }
  };

  const initialCourseFields = {
    courseName: selectedCourse,
    courseTagLine: "",
    durationOfCourse: "",
    teachingMode: "",
    priceOfCourse: "",
    numberOfStudentEnrolled: "",
    googleRatings: "",
    courseImage: "",
    courseTags: [],
  };

  const initialEditState = {
    courseName: false,
    courseTagLine: false,
    durationOfCourse: false,
    teachingMode: false,
    priceOfCourse: false,
    numberOfStudentEnrolled: false,
    googleRatings: false,
    courseImage: false,
    courseTags: false,
  };

  const [isEditing, setIsEditing] = useState(initialEditState);
  const [courseFields, setCourseFields] = useState(initialCourseFields);
  const [whatYouLearn, setWhatYouLearn] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [whothisCourse, setwhothisCourse] = useState([]);
  const [description, setDescription] = useState([]);
  const [courseContent, setCourseContent] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [selectedFAQs, setSelectedFAQs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleSave = async () => {
    const formData = new FormData();

    // Append all the data to formData
    formData.append("courseName", courseFields.courseName);
    formData.append("courseTagLine", courseFields.courseTagLine);
    formData.append("durationOfCourse", courseFields.durationOfCourse);
    formData.append("teachingMode", courseFields.teachingMode);
    formData.append("priceOfCourse", courseFields.priceOfCourse);
    formData.append("numberOfStudentEnrolled", courseFields.numberOfStudentEnrolled);
    formData.append("googleRatings", courseFields.googleRatings);
    formData.append("courseImage", courseFields.courseImage);
    formData.append("courseTags", JSON.stringify(courseFields.courseTags));
    formData.append("whatYouLearn", JSON.stringify(whatYouLearn));
    formData.append("requirements", JSON.stringify(requirements));
    formData.append("whothisCourse", JSON.stringify(whothisCourse));
    formData.append("description", JSON.stringify(description));
    formData.append("courseContent", JSON.stringify(courseContent));
    formData.append("selectedInstructors", JSON.stringify(selectedInstructors));
    formData.append("selectedFAQs", JSON.stringify(selectedFAQs));

        // Log the formData to see its contents
        formData.forEach((value, key) => {
          console.log(`${key}:`, value);
        });
    try {
      const response = await axios.post('/your-backend-endpoint', formData);
      // Handle success response
      console.log("Course saved successfully:", response.data);
    } catch (error) {
      // Handle error response
      console.error("Error saving course:", error);
    }
  };
  

  const calculateCompletionPercentage = () => {
    const totalFields = 16; // Total number of fields to fill (adjust this based on actual fields)
    let filledFields = 0;
  
    // Check if courseFields properties are filled
    Object.values(courseFields).forEach(value => {
      if (value) filledFields++;
    });
  
    // Check other states
    if (whatYouLearn.length > 0) filledFields++;
    if (requirements.length > 0) filledFields++;
    if (whothisCourse.length > 0) filledFields++;
    if (description.length > 0) filledFields++;
    if (courseContent.length > 0) filledFields++;
    if (selectedInstructors.length > 0) filledFields++;
    if (selectedFAQs.length > 0) filledFields++;
  
    return Math.round((filledFields / totalFields) * 100);
  };
  

  const tabContents = {
    Header: (
      <HeaderTab
        courseNamesDropdown={courseNamesDropdown}
        selectedCourse={selectedCourse}
        tags={tags}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        courseFields={courseFields}
        setCourseFields={setCourseFields}
      />
    ),
    Overview: (
      <OverView
        selectedCourse={selectedCourse}
        setwhothisCourse={setwhothisCourse}
        whothisCourse={whothisCourse}
        whatYouLearn={whatYouLearn}
        setWhatYouLearn={setWhatYouLearn}
        requirements={requirements}
        setRequirements={setRequirements}
      />
    ),
    Description: (
      <Description
        selectedCourse={selectedCourse}
        description={description}
        setDescription={setDescription}
      />
    ),
    Content: (
      <Content
        selectedCourse={selectedCourse}
        courseContent={courseContent}
        setCourseContent={setCourseContent}
      />
    ),
    Instructor: (
      <InstructorTab
        selectedCourse={selectedCourse}
        selectedInstructors={selectedInstructors}
        setSelectedInstructors={setSelectedInstructors}
      />
    ),
    FAQ: (
      <FAQSectionTab
        selectedCourse={selectedCourse}
        selectedFAQs={selectedFAQs}
        setSelectedFAQs={setSelectedFAQs}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}

      />
    ),
  };

  return (
    <div className="course-creation-page-container">
      <div className="Course-creation-page-tabs-container">
        {tabs.map((tab, index) => (
          <div
            key={tab}
            className={`tab-container-design ${
              activeTabIndex === index ? "active-tab" : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className="course-create-tab-content">
        {tabContents[tabs[activeTabIndex]]}
      </div>
      <div className="course-create-button-section">
        <div>
          <Button
            variant="contained"
            color="success"
            id="button-zoom"
            onClick={handlePreviousClick}
            disabled={activeTabIndex === 0}
          >
            <ArrowBackIcon className="buttonicons"></ArrowBackIcon>Previous
          </Button>
          &nbsp; &nbsp;
          <Button variant="contained" color="success" id="button-zoom">
            <VisibilityIcon className="buttonicons"></VisibilityIcon>View
          </Button>
        </div>

        <Tooltip title={"percentage of Course Complete"} arrow>
          <Avatar className="create-course-avatar">{`${calculateCompletionPercentage()}%`}</Avatar>
        </Tooltip>
        <div>
          <Button variant="contained" color="success" id="button-zoom" onClick={handleSave}>
            <SaveIcon className="buttonicons"></SaveIcon>Save
          </Button>
          &nbsp; &nbsp;
          <Button
            variant="contained"
            color="success"
            id="button-zoom"
            onClick={handleNextClick}
            disabled={activeTabIndex === tabs.length - 1}
          >
            Next <ArrowForwardIcon className="buttonicons"></ArrowForwardIcon>
          </Button>
        </div>
      </div>

      <div className="backicon-course-creations">
        <Tooltip title="Back to Dashboard" arrow>
          <IconButton className="back-button-color" onClick={handleBackClick}>
            <ArrowBackIcon className="buttoniconsback" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default CourseCreationPage;
