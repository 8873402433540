import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Drawer from '@mui/material/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import HT from "../static/HT_Large_round.png";
import './QABlog.css';
import Question_Editor from './QABlog_editor';

//----------------------------------------------------------------
// QuestionDrawer Component
//----------------------------------------------------------------

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    width: '100%',
    padding: theme.spacing(2),
    bottom: 0,
  },
}));


function QuestionDrawer({ open, onClose, onSave }) {

  //----------------------------------------------------------------
  //State handle
  //----------------------------------------------------------------

  const [editorData, setEditorData] = useState({ normalContent: '', htmlContent: '', tags: '' });


  //----------------------------------------------------------------
  //Function for saving the data
  //----------------------------------------------------------------

  const handleSave = () => {
    onSave(editorData);
    setEditorData({ normalContent: '', htmlContent: '', tags: '' });
    onClose();
  };


  const handleEditorContentChange = (data) => {
    setEditorData(data);
  };


  return (
    // ______________Drawer_________________//

    <StyledDrawer anchor="bottom" open={open} onClose={onClose}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pt: 4,
        }}
      >

        {/* ______________Editor_________________ */}

        <Question_Editor
          is_tag_required={true}
          isHeadingRequird={false}
          height="20vh"
          onContentChange={handleEditorContentChange}
          onSave={handleSave}
          onChange={(content) => setEditorData((prevData) => ({ ...prevData, content }))}
          apiType="question"
        />
      </Box>
    </StyledDrawer>
  );
}


// ______________________Nav bar start______________________//

function Navbar({ onAskQuestion }) {

  //----------------------------------------------------------------
  //Editor open for ask question button
  //----------------------------------------------------------------

  const handleDrawerOpen = () => {
    onAskQuestion();
  };

  return (
    <nav className="navbar">

      {/*_____________Brand Logo_______________ */}

      <div>
        <a href="#" className="brand-link">
          <img src={HT} alt="Happy Technovation Logo" className="brand-logo" />
        </a>
      </div>

      {/* ________________Search bar_____________________ */}

      <div className="search-bar">
        <div className="search-icon-wrapper">
          <SearchIcon className="search-icon" />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: 'input-root',
            input: 'input-input',
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

      {/* _____________Navigation Links______________ */}

      <div>
        <ul className="nav-links">
          <li><a href="#" onClick={handleDrawerOpen}>Ask Question</a></li>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Login</a></li>
        </ul>
      </div>

      {/* ______________Mobile View____________________ */}
      <div className="Modile_icon">Name</div>
    </nav>
  );
}

export default Navbar;
export { QuestionDrawer };
