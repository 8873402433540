import React from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function UploadFromTemplate({ DBApi, close, update }) {
  const endpointString = DBApi.endpoint;

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the data is present in the first sheet (index 0)
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to JSON format
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Required columns
      const requiredColumns = [
        "subject",
        "chapter",
        "topic",
        "Sub_Topics",

      ];

      // Modify JSON data to include only the required columns and additional fields
      const modifiedData = jsonData.map((row) => {
        const modifiedRow = {};

        // Ensure required columns are included if present in the row
        requiredColumns.forEach((column) => {
          modifiedRow[column] = row[column] || ""; // Add default value if column is missing
        });

        // Add additional fields
        modifiedRow["Created_By"] = localStorage.getItem("HTES_user_id");
        modifiedRow["Created_date"] = new Date().toISOString();
        modifiedRow["Modified_By"] = localStorage.getItem("HTES_user_id");
        modifiedRow["Modified_date"] = new Date().toISOString();
        modifiedRow["UTC_Time"] = new Date().toISOString();

        return modifiedRow;
      });


      try {
        // Send the modified JSON data to the backend
        const response = await axios.post(baseURL + DBApi, modifiedData);
        console.log(response);

        // Check if the response status is OK (200)
        if (response.status === 200) {
          customAlert(
            "",
            "Data uploaded successfully and dropped the duplicate course",
            "success"
          );
          update(true);
          close();
        } else {
          customAlert(
            "",
            "Error uploading data. Please check the template and database connectivity",
            "error"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          customAlert(
            "",
            "Duplicate record found. Please check and try again.",
            "warning"
          );
        } else {
          customAlert(
            "",
            "Error uploading data. Please check the template and database connectivity",
            "error"
          );
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <input
        type="file"
        accept=".xlsx, .xls .csv"
        onChange={handleUpload}
        style={{ display: "none" }}
        id="upload-file"
      />
      <label htmlFor="upload-file">
        <div>
          <FileUploadIcon
            className="course-name-template-addboxixon-style"
            color="primary"
            fontSize="large"
          />
          <br />
          <span>
            Upload
            <br />
            Content(s)
          </span>
        </div>
      </label>
    </div>
  );
}

export default UploadFromTemplate;
