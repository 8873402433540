import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
// import "./commontemplate.css";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import CreateGroupName from "./CreateGroupName";
import ManageBatchStudentLandingPage from "./AddGroupStudents/ManageBatchStudentLandingPage";
import ManageBatchInstructorLandingPage from "./AddGroupInstructor/ManageBatchInstructorLandingPage";
import ModalBatchAddedView from "./BatchModalView/ModalBatchAddedView";

function CreateGroupLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [open, setOpen] = useState(false);
  const [editData, seteditData] = useState("");
  const [showStudents, setShowStudents] = useState(false);
  const [showInstructors, setShowInstructors] = useState(false);
  const [viewOpenBatchModal, setviewOpenBatchModal] = useState(false);
  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/CreateGroup_fetch_all`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Additional Functions if the custom menu added(custommenuitems)
  //--------------------------------------------------------------------------------

  const handleViewStudentAdd = (row, rowid) => {
    seteditData(row);
    setShowStudents(true);
  };

  const handleViewStudentAddClose = (row, rowid) => {
    seteditData("");
    setShowStudents(false);
  };

  const handleViewInstructorAdd = (row, rowid) => {
    seteditData(row);
    setShowInstructors(true);
  };

  const handleViewInstructorAddClose = (row, rowid) => {
    seteditData("");
    setShowInstructors(false);
  };

  const handleClone = (groupId) => {
    axios
      .post(`${baseURL}/CreateGroup_clone/${groupId.id}`)
      .then((response) => {
        if (response.status === 201) {
          console.log("Cloned Group:", response.data.clonedGroup);
          customAlert("", "Group cloned successfully", "success");
          SetDataBaseUpdate(true);
          // Optional: Update the local group list if needed
          // fetchGroups(); // A function to refresh the list of groups
        }
      })
      .catch((error) => {
        console.error("Error cloning group:", error);
        customAlert("", "Failed to clone the group", "error");
      });
  };

  const handleViewBatchModal = (row) => {
    seteditData(row);
    setviewOpenBatchModal(true);
  };

  const handleViewBatchModalClose = () => {
    seteditData("");
    setviewOpenBatchModal(false);
  };

  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View Added Batchs", //Name of the MenuItem
      onClick: handleViewBatchModal, //Handle the Function(for working )
      IconColor: "blue", //Color of the Icon
    },
    {
      icon: GroupAddIcon, //icon Name (import from the Mui)
      label: "Add Students", //Name of the MenuItem
      onClick: handleViewStudentAdd, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
    {
      icon: PersonAddAlt1Icon,
      label: "Add Instructors",
      onClick: handleViewInstructorAdd,
      IconColor: "blue",
    },
    {
      icon: ContentCopyIcon,
      label: "Clone",
      onClick: handleClone,
      IconColor: "green",
    },
  ];

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    seteditData(rowdata);
    setOpen(true);
  };

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setOpen(true);
  };

  const handleClose = () => {
    seteditData("");
    setOpen(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      {!showStudents && !showInstructors && (
        <TableHeaderTemplate
          columns={renderColumns} // Columns render with default column if empty
          rows={renderRows} // Rows render
          customMenuItems={customMenuItems} // Addition menu items
          handleEdit={handleEdit} //Handle the Edit function
          deleteApi="CreateGroup_delete" // Delete Api
          tableNameForExport="Create_Group" // Export the Data to excel/csv (If name requred for the Excel or Csv)
          is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
          handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
          Manage_button_name="Create Group" //Name of the Manage button(in center of the header)
          // switchColumnName="isPublished" // Swithc required in the Table(one of the column)
          // swithcUpdateApi="updatecourse"// Api to handle the Toggle of the swithc
          // dropdownColumnName="isPaid" // Yes or No Dropdown required in the Table(one of the column)
          // dropdownUpdateApi="updatecourse"// Api to handle the Dropdown of the (yes or no)
          SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
          DefaultColumnNames={["GroupName", "Created_by"]} // Add to show the columns in a default render
          CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
        />
      )}
      {/* _______________________End_____________________________ */}

      {/* _____________________Create group name modal_____________ */}
      <CreateGroupName
        handleClose={handleClose}
        open={open}
        editData={editData}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />

      {/* _______________Create Student view__________ */}
      {showStudents && (
        <ManageBatchStudentLandingPage
          handleManageStudentViewClose={handleViewStudentAddClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}

      {/* _______________Create Instructor view__________ */}
      {showInstructors && (
        <ManageBatchInstructorLandingPage
          handleManageInstructorClose={handleViewInstructorAddClose}
          editData={editData}
          SetDataBaseUpdate={SetDataBaseUpdate}
        />
      )}

      {/* ________________Batches modal view modal___________ */}
      <ModalBatchAddedView
        close={handleViewBatchModalClose}
        open={viewOpenBatchModal}
        editData={editData}
      />
    </div>
  );
}

export default CreateGroupLandingPage;
