import React, { useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, IconButton, LinearProgress, Tooltip } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./ResultDashboardPage.css";

function ResultDashboardPageSection1({
  handleResultViewClose,
  ResultData,
  generalInfo,
  filteredQuestions,
}) {
  const [unattemptedCount, setUnattemptedCount] = useState(0);
  const [attemptedCount, setAttemptedCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [difficultyCounts, setDifficultyCounts] = useState({
    Easy: 0,
    Medium: 0,
    Hard: 0,
  });
  //--------------------------------------------------------------------------------
  // calculate the level of progress
  //--------------------------------------------------------------------------------
  const calculateLevelProgress = (attempted) => {
    return (attempted / filteredQuestions.length) * 100;
  };

  //--------------------------------------------------------------------------------
  // render the data with all avaibale status
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (ResultData?.ExamStatus) {
      // Parse the ExamStatus if it's a JSON string
      const examStatus =
        typeof ResultData.ExamStatus === "string"
          ? JSON.parse(ResultData.ExamStatus)
          : ResultData.ExamStatus;

      const selectedOptions = examStatus[0]?.SeletedOptions || [];

      // Count the null values to get unattempted questions
      const unattempted = selectedOptions.reduce((count, option) => {
        return option === null ? count + 1 : count;
      }, 0);
      setUnattemptedCount(unattempted);

      // Calculate attempted questions as the total minus unattempted questions
      const attempted = selectedOptions.length - unattempted;
      setAttemptedCount(attempted);

      // Calculate progress
      const calculatedProgress = (attempted / selectedOptions.length) * 100;
      setProgress(calculatedProgress);
    }
  }, [ResultData]);

  //--------------------------------------------------------------------------------
  // Update state with counts for each difficulty level
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (filteredQuestions?.length) {
      // Filter questions by difficulty levels
      const easyQuestions = filteredQuestions.filter(
        (question) => question.DifficultyLevels === "Easy"
      );
      const mediumQuestions = filteredQuestions.filter(
        (question) => question.DifficultyLevels === "Medium"
      );
      const hardQuestions = filteredQuestions.filter(
        (question) => question.DifficultyLevels === "Hard"
      );

      setDifficultyCounts({
        Easy: easyQuestions.length,
        Medium: mediumQuestions.length,
        Hard: hardQuestions.length,
      });
    }
  }, [filteredQuestions]);

  const tooltipContent = `Attempted Questions: ${attemptedCount} <br />Total Questions: ${filteredQuestions?.length}`;

  return (
    <div className="result-dashboard-page-section1-header-container">
      <div className="result-dashboard-page-section1-header-info">
        <div>
          <Tooltip title="Back to Home" arrow>
            <IconButton
              className="arror-back-icon-color-test-result-page"
              onClick={handleResultViewClose}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <div className="result-dashboard-page-section1-header-title">
            {ResultData?.TestName || "NA"}
          </div>

          <div className="result-dashboard-page-section1-header-date">
            <CalendarMonthIcon className="result-dashboard-page-section1-header-exam-icons" />
            <span className="hide-the-text-when-width-changes">
              Test Taken Date :
            </span>{" "}
            {ResultData?.TestStartDate || "NA"}
          </div>

          <div className="result-dashboard-page-section1-header-duration">
            <AccessTimeIcon className="result-dashboard-page-section1-header-exam-icons" />
            <span className="hide-the-text-when-width-changes">
              Test Duration :
            </span>{" "}
            {generalInfo?.TestDuration} minutes
          </div>
        </div>
      </div>

      <div className="result-dashboard-page-section1-header-chart-levels">
        <div className="result-dashboard-page-section1-header-chart">
          <Tooltip
            title={
              <span dangerouslySetInnerHTML={{ __html: tooltipContent }} />
            }
            arrow
          >
            <div className="result-dashboard-page-section1-header-progress-circle">
              <div className="quetion-text-alignemnt-and-poistion">
                Questions
              </div>
              <CircularProgressbar
                value={progress}
                text={`${attemptedCount}/${filteredQuestions?.length}`}
                // styles={circularProgressStyles}
              />
            </div>
          </Tooltip>
        </div>

        <div className="result-dashboard-page-section1-header-levels">
          <div className="result-dashboard-page-section1-header-level">
            <div className="result-dashboard-easy-medium-ard-text-alignment-control">
              Easy
              <span className="result-dashboard-page-section1-header-attempted-info">
                {" "}
                ({difficultyCounts.Easy}/{filteredQuestions?.length})
              </span>
            </div>
            <LinearProgress
              variant="determinate"
              value={calculateLevelProgress(difficultyCounts.Easy)}
              className="result-dashboard-page-section1-header-progress-bar easy"
            />
          </div>
          <div className="result-dashboard-page-section1-header-level">
            <div className="result-dashboard-easy-medium-ard-text-alignment-control">
              Medium
              <span className="result-dashboard-page-section1-header-attempted-info">
                {" "}
                ({difficultyCounts.Medium}/{filteredQuestions?.length})
              </span>
            </div>
            <LinearProgress
              variant="determinate"
              value={calculateLevelProgress(difficultyCounts.Medium)}
              className="result-dashboard-page-section1-header-progress-bar medium"
            />
          </div>
          <div className="result-dashboard-page-section1-header-level">
            <div className="result-dashboard-easy-medium-ard-text-alignment-control">
              Hard
              <span className="result-dashboard-page-section1-header-attempted-info">
                {" "}
                ({difficultyCounts.Hard}/{filteredQuestions?.length})
              </span>
            </div>
            <LinearProgress
              variant="determinate"
              value={calculateLevelProgress(difficultyCounts.Hard)}
              className="result-dashboard-page-section1-header-progress-bar hard"
            />
          </div>
        </div>
      </div>

      <div className="result-dashboard-page-section1-header-username-container">
        <Tooltip title={ResultData?.UserId} arrow>
          <Avatar className="result-dashboard-page-section1-header-avatar">
            {ResultData?.UserId.charAt(0).toUpperCase()}
          </Avatar>
        </Tooltip>
        <div>{localStorage.getItem("HTES_user_name")}</div>
        {/* <div className="result-dashboard-page-section1-header-username">
          {userName}
        </div> */}
      </div>
    </div>
  );
}

export default ResultDashboardPageSection1;
