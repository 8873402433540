import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import ToCompoanyTemplate from "./ToCompanyTemplete";
import ToCompanyAddProject from "./ToCompanyAddProject";

function ToCompanyMain() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openEditMode, setOpenEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false);
  const [CloseActionDialog, setCloseActionDialog] = useState(false);

  // Fetch the data from the database initially
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/to-companies-index`);
        const data = response.data;
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
        const rows = data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  // Handle edit function
  const handleEdit = (rowData) => {
    setInitialFormData(rowData); // Set the form data for editing
    setOpenEditMode(true); // Open the modal for editing
  };

  // Handle close action
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  // Handle add more functionality
  const handleAddMoreFunctionality = () => {
    setOpenTemplateModal(true);
  };

  return (
    <div>
      <TableHeaderTemplate
        columns={renderColumns}
        rows={renderRows}
        handleEdit={handleEdit} // Pass the handleEdit function here
        deleteApi="to-company-destroy"
        tableNameForExport="to_company"
        is_Manage_Button_Required={true}
        handleManageButtonClick={handleAddMoreFunctionality}
        Manage_button_name="Manage Client Company"
        swithcUpdateApi="to-company-update"
        switchColumnName="is_approved"
        dropdownUpdateApi="to-company-update"
        SetDataBaseUpdate={SetDataBaseUpdate}
        DefaultColumnNames={["name", "address", "phone_number"]}
        CloseActionDialog={CloseActionDialog}
      />

      <ToCompoanyTemplate
        openTemplateModal={openTemplateModal}
        setOpenTemplateModal={setOpenTemplateModal}
        SetDataBaseUpdate={SetDataBaseUpdate}
        columns={renderColumns}
      />

      <ToCompanyAddProject
        initialFormData={initialFormData}
        openModal={openEditMode}
        setOpenModal={setOpenEditMode}
        SetDataBaseUpdate={SetDataBaseUpdate}
        CloseActionDialogMenu={handleActionClose}
      />
    </div>
  );
}

export default ToCompanyMain;
