import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Modal,
  IconButton,
  Button,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import { baseURL } from "../../http";
import "./faq.css";
import { customAlert } from "../SweetAlertCommon/Custom";

const FAQTemplate = ({
  openAddModal,
  handleModalClose,
  SetDataBaseUpdate,
  editData,
  handleActionClose,
}) => {
  //--------------------------------------------------------------------------------
  //state to handle the complete template
  //--------------------------------------------------------------------------------
  const [header, setHeader] = useState("");
  const [content, setContent] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState(["General"]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isApproved, setIsApproved] = useState(false);

  //--------------------------------------------------------------------------------
  //To render the edit data while open the edit modal
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      setHeader(editData.Question);
      setContent(editData.Answer);
      setSelectedOptions(
        typeof editData.Category === "string"
          ? JSON.parse(editData.Category)
          : editData.Category
      );
      setIsApproved(editData.IsApproved === "1" ? 1 : 0);
      setEditingIndex(editData.id); // Adjust with your actual ID field
    }
  }, [editData, openAddModal]);

  //--------------------------------------------------------------------------------
  // Fetch Dropdown Options on Component Mount
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const optionsResponse = await axios.get(`${baseURL}/getcourse`);
        const optionsData = optionsResponse.data;

        const uniqueOptions = new Set([
          "General",
          "Internship Portal",
          "Job Portal",
          "Corporate Training",
          "Teach on TPA",
        ]);
        ["Category", "SubCategory", "CourseName"].forEach((field) => {
          optionsData.forEach((row) => uniqueOptions.add(row[field]));
        });

        setDropdownOptions(Array.from(uniqueOptions));
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchDropdownOptions();
  }, []);

  //--------------------------------------------------------------------------------
  // Handle adding new option
  //--------------------------------------------------------------------------------

  const handleAddOption = (newOption) => {
    if (newOption && !dropdownOptions.includes(newOption)) {
      setDropdownOptions((prevOptions) => [...prevOptions, newOption]);
      setSelectedOptions((prevSelected) => [...prevSelected, newOption]);
    }
  };

  //--------------------------------------------------------------------------------
  // Handle "Select All" option
  //--------------------------------------------------------------------------------

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedOptions(dropdownOptions);
    } else {
      setSelectedOptions([]);
    }
  };

  //--------------------------------------------------------------------------------
  //Remove the data from last add
  //--------------------------------------------------------------------------------
  const resetFileds = () => {
    setHeader("");
    setContent("");
    setSelectedOptions([]);
  };

  //--------------------------------------------------------------------------------
  //Keep the initially empty in Add modal
  //--------------------------------------------------------------------------------

  useEffect(() => {
    resetFileds();
  }, []);

  //--------------------------------------------------------------------------------
  // Save the data to the DB (new data and updated data)
  //--------------------------------------------------------------------------------

  const handleSave = async () => {
    // Check if Question and Answer are filled
    if (!header || !content) {
      customAlert(
        "",
        "Please fill in the required fields: Question and Answer",
        "warning"
      );
      return;
    }

    const commonContentItem = {
      Question: header,
      Answer: content,
      Category: selectedOptions.length === 0 ? ["General"] : selectedOptions,
      Modified_by: localStorage.getItem("HTES_user_id"),
      UTC_Time: new Date().toISOString(),
      IsApproved: isApproved,
      Modified_date: new Date().toISOString(),
    };

    const contentItem = editData
      ? commonContentItem
      : {
          ...commonContentItem,
          Created_by: localStorage.getItem("HTES_user_id"),
          Created_date: new Date().toISOString(),
        };

    try {
      if (editData) {
        const response = await axios.put(
          `${baseURL}/FAQ_edit/${editingIndex}`,
          contentItem
        );
        if (response.status === 200) {
          customAlert("", "FAQ Updated Successfully", "success");
          SetDataBaseUpdate(true);
          handleActionClose();
        }
      } else {
        const response = await axios.post(`${baseURL}/FAQ`, [contentItem]);
        if (response.status === 200) {
          customAlert("", "New FAQ Inserted Successfully", "success");
          SetDataBaseUpdate(true);
        }
      }

      resetFileds();
      handleModalClose();
    } catch (error) {
      console.error("Error inserting/updating FAQ:", error);
      if (error.response && error.response.status === 400) {
        customAlert("", "Duplicate question found. Please check!", "warning");
      } else {
        customAlert(
          "",
          "Error inserting/updating FAQ. Please try again.",
          "error"
        );
      }
    }
  };

  //--------------------------------------------------------------------------------
  // Delete the Options
  //--------------------------------------------------------------------------------
  const handleOptionDelete = (optionToDelete) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((opt) => opt !== optionToDelete)
    );
  };

    //--------------------------------------------------------------------------------
  // Header field value change
  //--------------------------------------------------------------------------------
  const handleHeaderChange = (e) => {
    setHeader(e.target.value);
  };


      //--------------------------------------------------------------------------------
  // Delete the Options
  //--------------------------------------------------------------------------------
  const handleContentChange = (e) => {
    setContent(e.target.value);
  };
  return (
    <>
      <Modal
        open={openAddModal}
        onClose={handleModalClose}
        className="FAQ-screen-center-alignment-overview-template"
      >
        <div className="FAQ-container-overview-template">
          {/* ______________FAQ modal heade and close icon__________ */}
          <div className="FAQ-inside-content-container-overview-template">
            <IconButton
              id="button-zoom"
              className="FAQ-close-iconButton-right-top-corner-overview-template"
              onClick={handleModalClose}
            >
              <CloseIcon className="FAQ-close-iconButton-right-top-corner-symbol-overview-template" />
            </IconButton>
            <div className="FAQ-header-and-switch-container-overview-template">
              <h5 className="FAQ-header-name-overview-template">
                {editingIndex !== null ? "Edit FAQ" : "Add FAQ"}
              </h5>

              {/* ________________________end_____________________________ */}
            </div>
            {/* ________________________text fields(quetion and answer)________________ */}
            <div className="textfiled-dropdown-filed-chip-container">
              <div className="title-textfiled-conatiner">
                <TextField
                  fullWidth
                  placeholder="Question"
                  value={header}
                  onChange={handleHeaderChange}
                  className="question-text-alignment"
                />
              </div>

              <div className="title-textfiled-conatiner">
                <textarea
                  className="faq-textarea-overview-template"
                  placeholder="Answer"
                  value={content}
                  onChange={handleContentChange}
                />
              </div>
              {/* ________________________end______________________ */}
              {/* ____________________Auto complate text filed for add the categories________ */}
              <div className="title-textfiled-conatiner">
                <Autocomplete
                  disableClearable
                  multiple
                  options={dropdownOptions}
                  value={selectedOptions}
                  onChange={(event, newValue) => setSelectedOptions(newValue)}
                  disableCloseOnSelect
                  renderTags={() => null} // Disable default tags rendering
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select or Add Category"
                      variant="outlined"
                      fullWidth
                      onClick={(event) => event.target.focus()} // This ensures dropdown opens on click
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {params.inputProps.value && ( // Check if value is not in options
                              <Tooltip title="Add New Category" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleAddOption(params.inputProps.value)
                                  }
                                >
                                  <AddIcon className="FAQ-addicon-design-for-icon" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              {/* _________________________end_____________________ */}
              {/* _______________________check box for select allcategories________ */}
              <div className="check-box-conataine-with-select-all">
                <Checkbox
                  //   indeterminate={
                  //     selectedOptions.length > 0 &&
                  //     selectedOptions.length < dropdownOptions.length
                  //   }
                  checked={selectedOptions.length === dropdownOptions.length}
                  onChange={handleSelectAll}
                  className="FAQ-checkbox-alignment"
                />
                Select All Category
              </div>
              {/* _______________________end_________________ */}

              {/* _________________chip showing conatainer_____________ */}

              <div className="chips-container">
                {selectedOptions.map((option, index) => (
                  <Chip
                    key={index}
                    className="FAQ-chip-background-color"
                    variant="outlined"
                    label={option}
                    onDelete={() => handleOptionDelete(option)}
                  />
                ))}
              </div>
            </div>
            {/* _________________end_____________ */}
            {/* _______________________Save button ,edit botton section______________ */}
            <div className="FAQ-Save-Button-Alignment-container-overview-template">
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleSave}
              >
                {editingIndex !== null ? (
                  <UpdateIcon className="buttonicons" />
                ) : (
                  <SaveIcon className="buttonicons" />
                )}
                &nbsp;{editingIndex !== null ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </div>
        {/* _________________end__________________ */}
      </Modal>
    </>
  );
};

export default FAQTemplate;
