import React, { useEffect } from "react";
import ContentMain from "./ContentMain";


function ContentDashboard() {
  useEffect(() => {
    document.title = "Content-Dashboard";
  });
  return (
    <div className="CA_Dashboard" >
     <ContentMain/>
    </div>
  );
}

export default ContentDashboard;
