import React from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CourseTemplateMainPage from "./CourseTemplateMainPage";

function ManageCourseTemplate({
  openMangaeCourse,
  setOpenManageCourse,
  showCoursepage,
  setCoursePage,
  SetDataBaseUpdate,
  editData,
  setEditdata,
  isEdit,
}) {
  //--------------------------------------------------------------------------------
  //handle clik for the add course button
  //--------------------------------------------------------------------------------
  const handleClickAddCourse = () => {
    setCoursePage(true);
    handleModalClose();
  };

  //--------------------------------------------------------------------------------
  //Close the Template modal
  //--------------------------------------------------------------------------------
  const handleModalClose = () => {
    setOpenManageCourse(false);
    setEditdata("");
  };

  return (
    <div>
      <Modal
        open={openMangaeCourse}
        onClose={handleModalClose}
        className="Manage-course-template-modal"
      >
        {/* _________________________Header_______________________ */}
        <div className="Manage-course-template-modal-container">
          <div className="Manage-course-name-template-container-with-content">
            <IconButton
              id="button-zoom"
              className="Manage-course-name-template-close-button"
              onClick={handleModalClose}
            >
              <CloseIcon className="Manage-course-name-template-close-button-x" />
            </IconButton>
            <h5 className="Manage-course-name-template-header">
              Course Management
            </h5>
            {/* _________________________End_______________________ */}
            {/* _________________________Button,upload,download,add_______________________ */}
            <div className="Manage-course-name-template-add-course-icon">
              <div onClick={handleClickAddCourse}>
                <AddBoxIcon
                  className="Manage-course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />

                <div>
                  Add<br></br> Course
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ___________________________end_____________________________________ */}
      </Modal>

      {/* ________________call the main page for coure adding______________   */}
      {showCoursepage && (
        <CourseTemplateMainPage
          editData={editData}
          setCoursePage={setCoursePage}
          SetDataBaseUpdate={SetDataBaseUpdate}
          setEditdata={setEditdata}
          isEdit={isEdit}
        />
      )}
      {/* __________________________end______________________________________ */}
    </div>
  );
}

export default ManageCourseTemplate;
