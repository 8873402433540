import React, { useState, useEffect } from "react";
import ManageInstructor from "./ManageInstructor";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import { baseURL } from "../../http";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditInstructor from "./EditInstructor";
import ViewInstructor from "./ViewInstructor";

function InstructorLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [openmanageinstructor, setManageInstructor] = useState(false);
  const [openEdit, setOpneEdit] = useState(false);
  const [editData, setEditdata] = useState();
  const [editId, setediId] = useState();
  const [openInstrutorview, setOpenInstructorView] = useState(false);
  const [instructorData, setInstructorData] = useState();

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/Instructor_fetch_all`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  // Manage instructor open and close modal
  //--------------------------------------------------------------------------------

  const handleManageInstructorclose = () => {
    setManageInstructor(false);
  };

  //--------------------------------------------------------------------------------
  //Additional Functions if the custom menu added(custommenuitems)
  //--------------------------------------------------------------------------------

  const handleView = (row, rowid) => {
    setOpenInstructorView(true);
    setInstructorData(row);
  };

  const handleCloseInsructor = () => {
    setOpenInstructorView(false);
    handleActionClose();
  };

  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View", //Name of the MenuItem
      onClick: handleView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
  ];

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata, id) => {
    console.log("handle Edit functions", rowdata);
    setOpneEdit(true);
    setEditdata(rowdata);
    setediId(id);
  };

  const handlecloseedit = () => {
    setOpneEdit(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setManageInstructor(true);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render with default column if empty
        rows={renderRows} // Rows render
        customMenuItems={customMenuItems} // Addition menu items
        handleEdit={handleEdit} //Handle the Edit function
        deleteApi="Instructor_delete" // Delete Api
        tableNameForExport="Instructor" // Export the Data to excel/csv (If name requred for the Excel or Csv)
        is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
        handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
        Manage_button_name="Manage Instructor" //Name of the Manage button(in center of the header)
        SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
        DefaultColumnNames={[
          "instructorName",
          "designation",
          "yearOfExperience",
          "teachingExperience",
        ]} // Add to show the columns in a default render
        CloseActionDialog={CloseActionDialog} //Handle the action dialog close when the functionality complete(action menu container)
      />
      {/* _______________________End_____________________________ *


      {/* _________________Call the Manage Instructor____________ */}
      <ManageInstructor
        open={openmanageinstructor}
        close={handleManageInstructorclose}
        SetDataBaseUpdate={SetDataBaseUpdate}
        editData={editData}
      />
      {/* _________________END____________ */}

      {/* _______________________call the Edit Modal______________ */}
      <EditInstructor
        openAddInstructor={openEdit}
        closeAddInstructor={handlecloseedit}
        editData={editData}
        editId={editId}
        SetDataBaseUpdate={SetDataBaseUpdate}
        handleActionClose={handleActionClose}
      />

      {/* _______________________end______________ */}

      {/* ____________________ViewInstructor_______________ */}
      <ViewInstructor
        handleCloseInsructor={handleCloseInsructor}
        openInstrutorview={openInstrutorview}
        instructorData={instructorData}
      />
      {/* _______________________end______________ */}
    </>
  );
}

export default InstructorLandingPage;
