import React from "react";
import McqGeneration from "../McqsGeneration/McqGeneration";
import UserSettingAPI from "../UserSettingAPI/UserSettingAPI";
import ViewHistoryDataGrid from "../UserManagement/ViewHistoryDataGrid";
import ResultDashboard from "../ResultReviewDashboard/ResultDashboard";
import CA_Main from "../CurrentAffairs/CA_Main";
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ClassIcon from '@mui/icons-material/Class';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { IconButton } from "@mui/material";
import ContentDashboard from "../ContentPage/ContentDashboard";
import ReceiptMain from "../Receipt/ReceiptMain";
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import UserManagementHeader from "../UserManagement/UserManagementHeadercopy";
import CourseNamePage from "../TPACoursePage/CourseNamePage/CourseNamePage";
import FAQ_Main from "../FAQ/FAQ_Main";
import EnquiryMain from "../Enquiry/EnquiryMain";
import WebsiteTrackTPAMain from "../WebSiteTrackTPA/WebsiteTrackTPAMain";
import ProfileManagement from "../ProfileManagement/Pm_LandingPage";
import PortraitIcon from '@mui/icons-material/Portrait';
import AddCourseButtonLandingPage from "../TPACoursePage/CourseCreationTemplate/AddCourseLandingPage";
import InstructorLandingPage from "../InstructorManagement/InstructorLandingPage";
import FAQ_AccordionTemplate from "../FAQTemplate/FAQ_AccordionTemplate";
import Coursetemplate2LandingPage from "../TPACoursePage/CoursePageTemplate2.js/Coursetemplate2LandingPage";
import InstructorTemplateForAddEdit from "../InstructorManagement/InstructorTemplateForAddEdit";
import PlacementRecordMain from "../PlacementRecord/PlacementRecordMain";
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import JobPostMain from "../JobPortal/JobPost/JobPostMain";
import ApartmentIcon from '@mui/icons-material/Apartment';
import CompanyProfileMain from "../JobPortal/CompanyProfile/CompanyProfileMain";
import ReviewLandingPage from "../TPAReview/ReviewLandingPage";
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import AppliedJobNotification from "../AppliedJobsNotification/AppliedJobNotification";
import InterviewQuestionLandingPage from "../InterViewQuestion/InterviewQuestionLandingPage";
import EmployeeListLandingPage from "../Employee/EmployeeList/EmployeeListLandingPage";
import Blog from "../TPA Blog/Blog";
import EmployeeDetailsLandingPage from "../Employee/EmployesDetails/EmployeeDetailsLandingPage";
import RollAccessTabLandingPage from "../RollAccessTabandRoleCreate/RollAccessTabLandingPage";
import BusinessIcon from '@mui/icons-material/Business';
import ContactsIcon from '@mui/icons-material/Contacts';
import HtesContact from "../HtesPortfolio/HtesContact";
import HtesCareer from "../HtesPortfolio/HtesCareer";
import HtesReachOut from "../HtesPortfolio/HtesReachOut";
import MySalarySlip from "../EmployeeData/MySalarySlip";
import MyPersonalInfo from "../EmployeeData/MyPersonalInfo";
import MySalaryStructure from "../EmployeeData/MySalaryStructure";
import EmployeeLandingPage from "../EmployeeData/EmployeeLandingPage";
import HolidaysLandingPage from "../HolidayList/HolidaysLandingPage";
import EducationalRoadMapLandingPage from "../EducationalRoadMap/EducationalRoadMapLandingPage";
import QuestionBankLandingPage from "../QuestionBank/QuestionBankLandingPage";
import ContentNamePage from "../ContentManagement/ContentNamePage";
import ContentMain from "../ContentManagement/ContentRender/ContentMain";
import TPAExamMain from "../TPAExam/TPAExamMain";
import ExamPatternLandingPage from "../CreateExamPattern/ExamPatternLandingPage";
import CreateTestLandingPage from "../CreateTest/CreateTestLandingPage";
import TestSeriesManageMentLandingPage from "../TestSeriesMangaement/TestSeriesManageMentLandingPage";
import Advertisement from "../ContentManagement/Advertisement/Advertisement";
import AdvertisementModal from "../ContentManagement/Advertisement/AdvertisementModal";
import ResultDashboardPageMain from "../ResultDashboardPage/ResultDashboardPageMain";
import BatchManagementLandingPage from "../BatchManagement/BatchManagementLandingPage";
import ResultDashboardLandingPage from "../ResultDashboardPage/ResultDashboardLandingPage";
import CreateGroupLandingPage from "../CreateGroups/CreateGroupLandingPage";
import NotificationsLandingPage from "../UserSettingAPI/Notifications/NotificationsLandingPage";
import TPAPreviousQuestionMain from "../TPAExam/ExamPreviousQuestionPaper/TPAPreviousQuestionMain"
import FromCompanyMain from "../FromComapany/FromCompanyMain";
import ToCompanyMain from "../ToCompany/ToCompanyMian";
import ProjectDetailsMain from "../ProjectDetails/ProjectDetailsMain";
import TPAPayment from "../TPAPayment/TPAPayment"

const navItems = [
  { id: 1, title: "Dashboard", icon: <IconButton><DashboardOutlinedIcon className="iconinnavbar" /></IconButton>, content: <ViewHistoryDataGrid />},
  { 
    id: 2, 
    title: "Users", 
    icon: <IconButton><PersonOutlineOutlinedIcon   className="iconinnavbar"/></IconButton>,   
    subItems: [
      { id: 2.1, subtitle: "Roll Access ManageMent",icon: < GroupIcon />, content: <RollAccessTabLandingPage /> },
      { id: 2.2, subtitle: "User Management",icon: < GroupIcon />, content: <UserManagementHeader /> },
      { id: 2.3, subtitle: "Instructor Management",icon: < GroupIcon />, content: <InstructorLandingPage /> },
      { id: 2.4, subtitle: "Create Groups",icon: < GroupIcon />, content: <CreateGroupLandingPage /> },     
      { id: 2.5, subtitle: "Holidays Management",icon: < GroupIcon />, content: <HolidaysLandingPage /> },   
            
    ] 
  },
  { 
    id: 3, 
    title: "Batch", 
    icon: <IconButton><TableChartOutlinedIcon  className="iconinnavbar" /></IconButton>, 
    subItems: [
       {  id: 3.1, subtitle: "Batch Management",icon:<ClassIcon/>, content:  <BatchManagementLandingPage />  },      
    ] 
  },
  { 
    id: 4, 
    title: "Questions", 
    icon: <IconButton><HelpOutlineOutlinedIcon  className="iconinnavbar" /></IconButton>, 
    subItems: [
      {  id: 4.1, subtitle: "Educational Roadmap",icon: < CastForEducationIcon />, content: <EducationalRoadMapLandingPage/> },
      { id: 4.2, subtitle: "Question Bank",icon: < QuestionAnswerIcon />, content: <QuestionBankLandingPage/> },      
    ] 
  },
  { 
    id: 5, 
    title: "Test", 
    icon: <IconButton><ContentPasteOutlinedIcon  className="iconinnavbar" /></IconButton>, 
    subItems: [
      { id: 5.1, subtitle: "AI-Question-Generation",icon: < PsychologyAltIcon />, content: <McqGeneration />  },
      { id: 5.2, subtitle: "Create Test Pattern",icon: < EditNoteIcon />, content: <ExamPatternLandingPage/> },
      { id: 5.3, subtitle: "Test Management",icon: < AddBoxIcon /> ,content: <CreateTestLandingPage/>  },
      { id: 5.4, subtitle: "Test Series Management", icon: < AutoAwesomeMotionIcon /> ,content: <TestSeriesManageMentLandingPage/>},      
    ] 
  },

   
  { 
    id: 6, 
    title: "Test Result", 
    icon: <IconButton><BarChartOutlinedIcon   className="iconinnavbar"/></IconButton>, 
    subItems: [
      { id: 6.1, subtitle: "Result",icon:<ClassIcon/>, content:  <ResultDashboardLandingPage/> },
      // { id: 6.2, subtitle: "Quiz Result",icon:<ClassIcon/>, content:  <ResultDashboardPageMain /> },
    ] 
  },
  { 
    id: 7, 
    title: "Content", 
    icon:<IconButton> <ContentCopyOutlinedIcon  className="iconinnavbar" /></IconButton>, 
    subItems: [
      { id: 7.1, subtitle: "Current Affairs",icon:<ClassIcon/>, content:  <CA_Main /> },
      { id: 7.2, subtitle: "Educational Data", icon:<ClassIcon/>,content: <ContentMain/> },
      { id: 7.3, subtitle: "Education", icon:<ClassIcon/>, content: <ContentNamePage/>},
    ] 
  },
  { 
    id: 8, 
    title: "Setting", 
    icon: <IconButton><SettingsOutlinedIcon  className="iconinnavbar"/></IconButton>, 
    subItems: [
      { id: 8.1, subtitle: "User Preferences",icon: < SettingsIcon />, content: <UserSettingAPI /> },
      { id: 8.2, subtitle: "Notification",icon: < SettingsIcon />, content: <NotificationsLandingPage /> },

    ] 
  },
  { id: 16, title: "TPA", icon:<IconButton> <GolfCourseIcon className="iconinnavbar"/></IconButton>, subItems: [
    { id: 16.1, subtitle: "Enquiry Tables",icon: < SettingsIcon />, content: <EnquiryMain/> },
    { id: 16.2, subtitle: "TPA Course Names",icon: < SettingsIcon />, content:<CourseNamePage />  },
    { id: 16.3, subtitle: "Course Page Creation",icon: < SettingsIcon />, content:<Coursetemplate2LandingPage/> },
    { id: 16.4, subtitle: "TPA Website Track",icon: < SettingsIcon />, content:<WebsiteTrackTPAMain/>  },
    { id: 16.5, subtitle: "TPA Review Table",icon: < SettingsIcon />, content:<ReviewLandingPage/>  },
    { id: 16.6, subtitle: "TPA Interview Questions",icon: < SettingsIcon />, content:<InterviewQuestionLandingPage/>  },
    { id: 16.7, subtitle: "TPA Blog", icon: < SettingsIcon />, content: <Blog/>},
    { id: 16.8, subtitle: "TPA Exam", icon: < SettingsIcon />, content: <TPAExamMain/>},
    { id: 16.9, subtitle: "TPA Exam Previous Paper", icon: < SettingsIcon />, content: <TPAPreviousQuestionMain/>},
  ]},
  { id:  22, title: "HTES", icon:<IconButton> <BusinessIcon className="iconinnavbar"/></IconButton>, subItems: [
    { id:  22.1, subtitle: "Contact",icon: < ContactsIcon className="iconinnavbar" />, content:<HtesContact/>  },
    { id:  22.2, subtitle: "Career",icon: <  WorkIcon />, content: <HtesCareer/> },
    { id:  22.3, subtitle: "Reach Out",icon: <  WorkIcon />, content: <HtesReachOut/> },

  ]},
  { id:  10, title: "Job Portal", icon:<IconButton> <WorkOutlineOutlinedIcon className="iconinnavbar"/></IconButton>, subItems: [
    { id:  10.1, subtitle: "Company Profile",icon: < ApartmentIcon className="iconinnavbar" />, content:<CompanyProfileMain/>  },
    { id:  10.2, subtitle: "Post Job",icon: <  WorkIcon />, content: <JobPostMain/> },
    { id: 10.3, subtitle: "Applied Job", content: <AppliedJobNotification/>},

  ]},
  { id: 11, title: "News", icon: <IconButton><ArticleOutlinedIcon className="iconinnavbar" /></IconButton>, content:<FAQ_AccordionTemplate /> },
  { id: 12, title: "Fees", icon: <IconButton><MonetizationOnOutlinedIcon className="iconinnavbar" /></IconButton>, content: <ReceiptMain /> },
  { id: 13, title: "Certificate", icon:<IconButton> <WorkspacePremiumOutlinedIcon className="iconinnavbar" /></IconButton>, content: <ViewHistoryDataGrid /> },
  { id: 14, title: "Advertisement", icon: <IconButton><FeaturedVideoOutlinedIcon className="iconinnavbar" /></IconButton>, content: <AdvertisementModal/>},
  { id: 15, title: "Announcement", icon:<IconButton> <CampaignOutlinedIcon className="iconinnavbar" /></IconButton>, content: <ViewHistoryDataGrid /> },
  
  { id: 20, title: "Employee-Admin", icon: <IconButton><BadgeOutlinedIcon className="iconinnavbar" /></IconButton>, 
    subItems: [
      { id: 20.1, subtitle: "EmployeeList",icon: < SettingsIcon />, content:<EmployeeListLandingPage/>  },
      { id: 20.2, subtitle: "EmployeeData",icon: < SettingsIcon />, content:<EmployeeDetailsLandingPage/>  },
      { id: 20.3, subtitle: "Certificate",icon: < SettingsIcon />, content:<ViewHistoryDataGrid />  },

    ] 
  },
  { id: 21, title: "Employee", icon: <IconButton><BadgeOutlinedIcon className="iconinnavbar" /></IconButton>, 
    subItems: [
      { id: 21.1, subtitle: "Employee Salary Slip",icon: < SettingsIcon />, content:<EmployeeLandingPage/>  },
      { id: 21.3, subtitle: "Employee Salary Structure",icon: < SettingsIcon />, content:<MySalaryStructure/>  },
      { id: 21.2, subtitle: "Employee Personal Info",icon: < SettingsIcon />, content:<MyPersonalInfo/>  },

    ] 
  },

  { 
    id: 23, 
    title: "Project Management", 
    icon: <IconButton><AccountTreeIcon className="iconinnavbar"/></IconButton>,   
    subItems: [
      { id: 23.1, subtitle: "From Company",icon: < GroupIcon />, content: <FromCompanyMain /> },
      { id: 23.2, subtitle: "To Comapny",icon: < GroupIcon /> , content: <ToCompanyMain />},
      { id: 23.3, subtitle: "Project Details",icon: < GroupIcon />, content: <ProjectDetailsMain />},
      { id: 23.4, subtitle: "Generate Invoice",icon: < GroupIcon /> },      
    ] 
  },
  
  { id: 17, title: "Profile", icon:<IconButton> <PortraitIcon className="iconinnavbar"/></IconButton>, content: <ProfileManagement/> },
  { id: 18, title: "Feedbacks", icon:<IconButton> <SmsOutlinedIcon className="iconinnavbar"/></IconButton>, content: 'FeedBack' },
  { id: 19, title: "FAQ", icon:<IconButton> <LiveHelpOutlinedIcon className="iconinnavbar"/></IconButton>, content: <FAQ_Main/> },


  // { id: 24, title: "Payments", icon:<IconButton> <LiveHelpOutlinedIcon className="iconinnavbar"/></IconButton>, content: <TPAPayment/> },

  { 
    id: 24, 
    title: "Payments", 
    icon: <IconButton><AccountTreeIcon className="iconinnavbar"/></IconButton>,   
    subItems: [
      { id: 24.1, subtitle: "Course Payment",icon: < GroupIcon />, content: <TPAPayment /> },  
    ] 
  },
];

export default navItems;  