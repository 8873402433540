import React, { useState, useEffect } from "react";
import {
  Button,
  Autocomplete,
  Modal,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../http";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Generalinformationsmalldiv from "./Generalinformationsmalldiv";
import AllComponetRenderPage from "./AllComponetRenderPage";

function AddGeneralInfo({
  Open,
  handleClose,
  dataGridVisible,
  setdataGridVisible,
  editData,
  setEditData,
  SetDataBaseUpdate,
  editTestData,
  setEditTestData,
}) {
  const [testNames, setTestNames] = useState([]);
  const [testNameQuantities, setTestNameQuantities] = useState([]);
  const [resetForm, setResetForm] = useState(false);
  const [patternData, setPatternData] = useState([]);
  const [selectedPatten, setSelectedPattern] = useState();

  console.log("rest", resetForm);
  const [testData, setTestData] = useState({
    testName: "",
    testDuration: "",
    examPattern: "",
    noOfQuestions: "",
    category: "",
  });

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data based on the Editdata(filll the form)
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editTestData) {
      setTestData({
        testName: editTestData.TestName || "",
        testDuration: editTestData.TestDuration || "",
        examPattern: editTestData.ExamPattern || "",
        noOfQuestions: editTestData.NoOfQuestions || "",
        category: editTestData.Category || "",
      });
    } else if (editData) {
      setTestData({
        testName: editData.testName || "",
        testDuration: editData.testDuration || "",
        examPattern: editData.examPattern || "",
        noOfQuestions: editData.noOfQuestions || "",
        category: editData.category || "",
      });
    } else {
      // Reset the form for new entries
      setTestData({
        testName: "",
        testDuration: "",
        examPattern: "",
        noOfQuestions: "",
        category: "",
      });
    }
  }, [editTestData, editData, resetForm]);

  //--------------------------------------------------------------------------------
  //UseEffect To render the All the Pattern from the backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTestNamesAndTotalQuestions = async () => {
      try {
        const [patternResponse] = await Promise.all([
          axios.get(baseURL + "/getpattern"),
        ]);
        const patterns = patternResponse.data;
        setPatternData(patterns);
        const testNameQuantities = {};
        patterns.forEach((pattern) => {
          const testName = pattern.PatternName;
          const quantity = pattern.TotalQuestions || 0; // Ensure TotalQuestions isn't undefined
          testNameQuantities[testName] =
            (testNameQuantities[testName] || "") + quantity;
        });
        const testNameQuantitiesArray = Object.keys(testNameQuantities).map(
          (testName) => ({
            testName,
            quantity: testNameQuantities[testName],
          })
        );
        setTestNames([
          "General",
          ...testNameQuantitiesArray.map((item) => item.testName),
        ]);
        setTestNameQuantities(testNameQuantitiesArray);
      } catch (error) {
        console.log("Error fetching test names and total questions:", error);
      }
    };

    fetchTestNamesAndTotalQuestions();
  }, []);

  //--------------------------------------------------------------------------------
  //Handle the entry in the Text fields
  //--------------------------------------------------------------------------------
  const handleTextFieldChange = (event, fieldName) => {
    const value = event.target.value;
    setTestData({ ...testData, [fieldName]: value });
  };

  //--------------------------------------------------------------------------------
  //Update the Fileds
  //--------------------------------------------------------------------------------
  const handleUpdateTest = () => {
    const requiredFields = [
      "testName",
      "testDuration",
      "noOfQuestions",
      "examPattern",
      "category",
    ];
    for (const field of requiredFields) {
      if (!testData[field]) {
        customAlert("", `Please fill the ${field} field.`, "warning");
        return;
      }
    }
    setEditData(testData);
    handleClose();
  };

  //--------------------------------------------------------------------------------
  //Create the test
  //--------------------------------------------------------------------------------
  const handleCreateTest = () => {
    setdataGridVisible(true);
    // handleUpdateTest()
    handleClose();
  };

  //--------------------------------------------------------------------------------
  //Make sure the Pattern shoulb render based on the Pattername
  //--------------------------------------------------------------------------------
  useEffect(() => {
    // This effect runs whenever testData.examPattern changes
    if (testData.examPattern !== "General") {
      // Find the corresponding pattern in patternData
      const selectedPattern = patternData.find(
        (pattern) => pattern.PatternName === testData.examPattern
      );
      if (selectedPattern) {
        setSelectedPattern(selectedPattern); // Update the selected pattern state
        localStorage.setItem(
          "selectedPattern_Test",
          JSON.stringify(selectedPattern)
        );
      }
    } else {
      setSelectedPattern(null); // If it's "General", clear the selected pattern
    }
  }, [testData, patternData]);

  //--------------------------------------------------------------------------------
  //Save and Update the test data
  //--------------------------------------------------------------------------------
  const handleSave = (selectedQuestions) => {
    const requestData = {
      ...testData,
      noOfQuestions: parseInt(testData.noOfQuestions),
      QuestionIds: selectedQuestions.map((question) => question.id),
    };
    // Check if the number of selected questions matches the specified number of questions
    if (requestData.noOfQuestions !== requestData.QuestionIds.length) {
      customAlert(
        "",
        "The number of selected questions does not match the specified number of questions",
        "warning"
      );
    } else {
      // If editTestData is present, it's an update operation, else it's a create operation
      const apiMethod = editTestData ? "put" : "post";
      const apiUrl = editTestData
        ? `${baseURL}/updateTest/${editTestData.id}` // API for updating test
        : `${baseURL}/createtest`; // API for creating new test

      axios[apiMethod](apiUrl, requestData)
        .then((response) => {
          customAlert(
            "",
            editTestData
              ? "Test updated successfully"
              : "Test created successfully",
            "success"
          );
          SetDataBaseUpdate(true);
          setdataGridVisible(false);
          setTestData({
            testName: "",
            testDuration: "",
            examPattern: "",
            noOfQuestions: "",
            category: "",
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            customAlert(
              "",
              "Duplicate test names are not allowed. Please choose a different test name.",
              "warning"
            );
          } else {
            customAlert(
              "",
              `Error while ${
                editTestData ? "updating" : "creating"
              } test. Please check the database connectivity.`,
              "error"
            );
          }
        });
    }
  };

  return (
    <>
      <Modal
        open={Open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="createtesteditgeneralinforamation"
      >
        <div className="createtesteditgeneralinforamation1">
          <IconButton
            id="button-zoom"
            onClick={handleClose}
            className="addeducationalMapmodalcancel"
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>
          {/* __________Header part___________________ */}
          <h5 className="add-general-info-heading">
            {editData ? "Edit" : "Add"} General Information
          </h5>
          {/* ______________Text filed____________________ */}
          <TextField
            label="Test Name"
            type="text"
            fullWidth
            value={testData.testName}
            onChange={(event) => handleTextFieldChange(event, "testName")}
          />
          <div className="createtesteditgeneralinforamation2">
            <FormControl fullWidth>
              <Autocomplete
                value={testData.examPattern}
                onChange={(event, value) => {
                  const selectedTest = testNameQuantities.find(
                    (test) => test.testName === value
                  );
                  setTestData({
                    ...testData,
                    examPattern: value,
                    noOfQuestions: selectedTest ? selectedTest.quantity : "",
                  });
                }}
                options={testNames}
                renderInput={(params) => (
                  <TextField required {...params} label="Exam Pattern" />
                )}
                clearOnEscape={true}
              />
            </FormControl>

            <TextField
              required
              type="number"
              label="No. of Questions"
              fullWidth
              value={testData.noOfQuestions}
              onChange={(event) =>
                handleTextFieldChange(event, "noOfQuestions")
              }
              inputProps={{
                min: 3,
              }}
              disabled={testData.examPattern !== "General"}
            />

            <TextField
              label="Duration"
              type="number"
              fullWidth
              value={testData.testDuration}
              onChange={(event) => handleTextFieldChange(event, "testDuration")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Minutes</InputAdornment>
                ),
              }}
              inputProps={{
                min: 5,
              }}
            />

            <TextField
              label="Test Category"
              fullWidth
              value={testData.category}
              onChange={(event) => handleTextFieldChange(event, "category")}
            />
          </div>
          {/* _____________Button section_____________ */}
          <div className="createtesteditgeneralinforamation3">
            {editData ? (
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleUpdateTest}
              >
                <UpdateIcon className="buttonicons" />
                &nbsp;Update
              </Button>
            ) : (
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleCreateTest}
              >
                <KeyboardDoubleArrowRightIcon className="buttonicons" />
                &nbsp;Next
              </Button>
            )}
          </div>
        </div>
      </Modal>

      {/* ________________Small general info div___________ */}
      {dataGridVisible && (
        <Generalinformationsmalldiv
          testData={testData}
          setTestData={setTestData}
          setdataGridVisible={setdataGridVisible}
          setResetForm={setResetForm}
          setEditTestData={setEditTestData}
          setSelectedPattern={setSelectedPattern}
        />
      )}
      {/* _______________AllComponent render part(View question) */}
      {dataGridVisible && (
        <AllComponetRenderPage
          selectedPatten={selectedPatten}
          setdataGridVisible={setdataGridVisible}
          handleSave={handleSave}
          SetDataBaseUpdate={SetDataBaseUpdate}
          testData={testData}
          editTestData={editTestData}
        />
      )}
    </>
  );
}

export default AddGeneralInfo;
