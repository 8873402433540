import React, { useEffect, useState } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { customAlert } from "../../SweetAlertCommon/Custom";
import EditorComponent from "../../Editor2/Editor2AddEdit";

function EditorModal({
  openEditor,
  closeEditor,
  addOrUpdateQuestion,
  selectedQuestion,
  setSelectedQuestion,
}) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [htmlContent, setHtmlContent] = useState("");

  //-------------------------------------------------------------------------
  // Pre-fill fields if a question is selected for editing
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (selectedQuestion) {
      setQuestion(selectedQuestion.Question || "");
      setAnswer(selectedQuestion.Answer || "");
      setHtmlContent(selectedQuestion.Answer || ""); // Set initial HTML content if available
    } else {
      // Clear fields when no question is selected
      setQuestion("");
      setAnswer("");
      setHtmlContent(""); // Clear HTML content when no question is selected
    }
  }, [selectedQuestion]);

  //-------------------------------------------------------------------------
  // Clear fields when modal is closed
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (!openEditor) {
      setQuestion("");
      setAnswer("");
      setHtmlContent("");
    }
  }, [openEditor]);

  //-------------------------------------------------------------------------
  // Handle save function
  //-------------------------------------------------------------------------
  const handleSave = () => {
    if (!question) {
      customAlert("", "Please fill the Question", "warning");
      return;
    }

    const updatedQuestion = {
      id: selectedQuestion ? selectedQuestion.id : new Date().getTime(), // Generate new ID if adding
      Question: question,
      Answer: answer,
      setHtmlContent: answer,
      // Add any other necessary fields here
    };

    console.log("updatedQuestion", updatedQuestion, typeof updatedQuestion.id);
    addOrUpdateQuestion(updatedQuestion);
    setSelectedQuestion(null);
    closeEditor(); // Close the modal after saving
  };

  return (
    <Modal
      open={openEditor}
      onClose={closeEditor}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="interview-question-add-geninfo-modal"
    >
      <div className="interview-question-add-question-modal-container3">
        <div>
          <IconButton
            id="button-zoom"
            className="addeducationalMapmodalcancel"
            onClick={closeEditor}
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>
        </div>
        {/* ______________editor component___________ */}
        <EditorComponent
          handleSave={handleSave}
          content={answer}
          setContent={setAnswer}
          question={question}
          setQuestion={setQuestion}
          editContent={htmlContent}
          setHtmlContent={setHtmlContent}
        />
        {/* __________________end______________ */}
      </div>
    </Modal>
  );
}

export default EditorModal;
