import React, { useState } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadTemplate from "./DownloadTemplate";
import UploadFromTemplate from "./UploadFromTemplate";
import AddBoxIcon from "@mui/icons-material/AddBox";

function QuestionBankTemplates({
  openModal,
  handleModalClose,
  columns,
  update1,
  setopenEditModal,
}) {
  //--------------------------------------------------------------------------------
  //open the add question modal
  //--------------------------------------------------------------------------------
  const openAddModal = () => {
    setopenEditModal(true);
    handleModalClose();
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="question-bank-modal-title"
      aria-describedby="question-bank-modal-description"
      className="educationalroadmapmodelcenteralignment"
    >
      <div className="modelcontainereducationroadmap">
        <IconButton
          className="EducationalTableViewmodalcloseicon"
          onClick={handleModalClose}
          id="button-zoom"
        >
          <CloseIcon className="EducationalTableViewmodalcloseiconX" />
        </IconButton>
        <h5 className="EducationalTableViewmodalHeader">
          Question Bank Management
        </h5>
        <div className="educationalroadmapinsidecontentgridalignment">
          <div className="addEducationalhoverandcolor">
            <div onClick={openAddModal}>
              <AddBoxIcon
                className="addEducationaladdicon"
                color="primary"
                fontSize="large"
              />
              <br />
              <span className="hovereffect">Add Manually Questions</span>
            </div>
            {/* __________________Add question manually moadal____________ */}
            {/* <AddtoQuestionBank
              isOpen={open}
              onClose={closeAddModal}
              update1={update1}
              renderRows={renderRows}
            /> */}
          </div>

          {/* __________________Download the template____________ */}
          <DownloadTemplate columns={columns} />

          {/* __________________Upload the template____________ */}
          <UploadFromTemplate
            DBApi="/templatequestiontodatabase"
            close={handleModalClose}
            update1={update1}
          />
        </div>
      </div>
    </Modal>
  );
}

export default QuestionBankTemplates;
