import React, { useEffect, useState } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { keySkillOptions } from "../../ProfileManagement/PmCommonFunction";
import "./interviewquestion.css";
import InterViewQuestionAddQuestionModal from "./InterViewQuestionAddQuestionModal";
import CustomMultiSelect from "../../Templates/MultiSelect/CustomMultiSelect";

function InterviewQuestionGenaralInfoAddModal({
  open,
  handleCloseModal,
  initialFormData,
  SetDataBaseUpdate,
}) {
  //-------------------------------------------------------------------------
  // State to manage form data
  //-------------------------------------------------------------------------
  const [open1, setOpen1] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [errors, setErrors] = useState({
    Designation: "",
  });

  //-------------------------------------------------------------------------
  // Handle click the next to open the editor
  //-------------------------------------------------------------------------
  const handleNextClick = () => {
    // Validate form data
    if (!formData.Designation) {
      setErrors({ ...errors, Designation: "Designation is required" });
    } else {
      setErrors({ ...errors, Designation: "" });
      setOpen1(true);
      handleSkillChange();
    }
  };
  //-------------------------------------------------------------------------
  // close the modal
  //-------------------------------------------------------------------------
  const handleCloseModal1 = () => {
    setOpen1(false);
  };
  const [formData, setFormData] = useState({
    Company: "",
    InterviewDate: "",
    Skills: [],
    Comment: "",
    Designation: "",
    Question: "",
    Answer: "",
  });

  //-------------------------------------------------------------------------
  // useEffect to handel the Initial data render
  //-------------------------------------------------------------------------
  useEffect(() => {
    setFormData({
      Company: initialFormData?.Company || "",
      InterviewDate: initialFormData?.InterviewDate || "",
      Comment: initialFormData?.Comment || "",
      Designation: initialFormData?.Designation || "",
      Question: initialFormData?.Question || "",
      Answer: initialFormData?.Answer || "",
    });
    setSelectedOption(
      initialFormData?.Skills && typeof initialFormData.Skills === "string"
        ? JSON.parse(initialFormData.Skills).map((skill) => ({
            value: skill,
            label: skill,
          }))
        : []
    );
  }, [initialFormData]);

  //-------------------------------------------------------------------------
  // Handle input changes
  //-------------------------------------------------------------------------
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear error message for the current field on change
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  //-------------------------------------------------------------------------
  // Update skills in formData
  //-------------------------------------------------------------------------
  const handleSkillChange = () => {
    setFormData({
      ...formData,
      Skills: selectedOption.map((option) => option.value), // Extract only the values from the selected options
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="interview-question-add-geninfo-modal"
    >
      <div className="interview-question-add-geninfo-modal1">
        <div className="interview-question-add-geninfo-modal-container">
          <div>
            <IconButton
              id="button-zoom"
              className="addeducationalMapmodalcancel"
              onClick={handleCloseModal}
            >
              <CloseIcon className="addeducationalMapmodalcancelX" />
            </IconButton>
          </div>
          <div className="enquiry-table-header-interview-question-add-modal-gen-info">
            Add Interview Information
          </div>
          <div className="over-flow-control-container">
            {/* _________________text filed ___________________ */}
            <div className="add-gen-info-grid-container">
              <TextField
                required
                label="Designation"
                name="Designation"
                value={formData.Designation}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={!!errors.Designation} // Show error if there is an error message
                helperText={errors.Designation} // Show error message below the TextField
              />
              <TextField
                label="Company"
                name="Company"
                value={formData.Company}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />

              <CustomMultiSelect
                options={keySkillOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                placeholder="Add Key Skills"
                label="Skills"
              />

              <TextField
                label="Interview Date"
                type="date"
                name="InterviewDate"
                value={formData.InterviewDate}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true, // This ensures that the label stays above the input field
                }}
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
              />
            </div>

            <div>
              <TextField
                label="Comment"
                name="Comment"
                value={formData.Comment}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
                multiline
                rows={2.5}
              />
            </div>
          </div>
          <div className="next-button-alignment-container">
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={handleNextClick}
            >
              <ArrowForwardIcon className="buttonicons" />
              &nbsp; Next
            </Button>
          </div>
          {/* ______________Add question modal________________ */}
          <InterViewQuestionAddQuestionModal
            open1={open1}
            handleCloseModal1={handleCloseModal1}
            formData={formData}
            setFormData={setFormData}
            handleCloseModal={handleCloseModal}
            setSelectedOption={setSelectedOption}
            initialFormData={initialFormData}
            SetDataBaseUpdate={SetDataBaseUpdate}
          />
        </div>
      </div>
    </Modal>
  );
}

export default InterviewQuestionGenaralInfoAddModal;
