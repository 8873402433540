import React, { useEffect, useState } from "react";
import PersonalInfo from "./PersonalInfo";
import HRForm from "./HRForm";
import SalaryStructure from "./SalaryStructure";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./hrfillableform.css";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function HRandSalaryStructureLandibgPage() {
  const [row, setRow] = useState(null);
  const [rowId, setRowId] = useState(null);
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    PersonalMailId: '',
    CompanyMailId: "",
    Designation: "",
    DateOfJoining: "",
    OfferLetterStatus: "",
    EmployeeType: "",
    EmployeeSalary: "",
    Department:'',
  });

  const [salaryDetails, setSalaryDetails] = useState({
    CTC: 0,
    BasicPercent: 50,
    HRApercent: 40,
    PFEmployerPercent: 12,
    PFEmployeePercent: 12,
    ProfessionalTax: 2400,
    Tax: 0,

    BasicMonthy:0,
    HRAMonthly:0,
    OtherAllowanceMonthly:0,
    PFEmployeeMontly:0,
    ProfessionalTaxManothly:0,
    TaxMonthly:0,
  });

  useEffect(() => {
    // Retrieve the data from localStorage
    const storedRow = localStorage.getItem("hrFormRow");
    const storedRowId = localStorage.getItem("hrFormRowId");

    // Parse the data and set it to state
    if (storedRow && storedRowId) {
      const parsedRow = JSON.parse(storedRow);
      setRow(parsedRow);
      setRowId(storedRowId);

      // Initialize formValues with row data
      setFormValues({
        PersonalMailId: parsedRow.PersonalMailId || '',
        CompanyMailId: parsedRow.CompanyMailId || '',
        Designation: parsedRow.Designation || '',
        DateOfJoining: parsedRow.DateOfJoining || '',
        OfferLetterStatus: parsedRow.OfferLetterStatus || '',
        EmployeeType: parsedRow.EmployeeType || '',
        Department: parsedRow.Department || '',
        EmployeeSalary: parsedRow.EmployeeSalary ? JSON.parse(parsedRow.EmployeeSalary) : '',
      });

      // Initialize salaryDetails from EmployeeSalary if available
      if (parsedRow.EmployeeSalary) {
        setSalaryDetails(JSON.parse(parsedRow.EmployeeSalary));
      }
    }
  }, []);

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      EmployeeSalary: JSON.stringify(salaryDetails), // Store salaryDetails as JSON string
    }));
  }, [salaryDetails]);

  const handleSubmit = async () => {
    try {
      // Add 'Modified_by' and 'Modified_date' to the formValues
      const modifiedDate = new Date().toISOString(); // Get the current date and time in ISO format
      const modifiedBy = localStorage.getItem('HTES_user_id'); // Replace this with your logic to get the current user

      const updatedFormValues = {
        ...formValues,
         // Ensure EmployeeSalary is a JSON string
        Modified_by: modifiedBy,
        Modified_date: modifiedDate,
      };

      // Send POST request to the backend
      const response = await axios.post(baseURL + '/employee/update', updatedFormValues);

      customAlert('', 'HR Info and Salary Structure Updated Successfully','success');
      navigate('/dashboard')
    } catch (error) {
      // Handle error response
      console.error("Error submitting data:", error.response?.data || error.message);
      customAlert('',"Error submitting data, please try again.",'error');
    }
  };

  const handleBacktoHome=()=>{
    navigate('/dashboard')
  }

  return (
    <div className="Hr-form-container">
      <div className="Hr-form-content-container">
        <div className="Hr-form-Header">HR-Form and Employee Basic Info</div>
        <div className="all-section-render-container-scroll">
          <PersonalInfo EditData={row} />
          <HRForm formValues={formValues} setFormValues={setFormValues} />
          <SalaryStructure salaryDetails={salaryDetails} setSalaryDetails={setSalaryDetails} />
        </div>
        <div className="hr-salary-structure-landing-page-button">
          <Button variant="contained" color="success" id="button-zoom" onClick={handleBacktoHome}>
            <ArrowBackIcon className="buttonicons" />
            &nbsp;Back
          </Button>
          &nbsp;
          <Button variant="contained" color="success" id="button-zoom" onClick={handleSubmit}>
            <SendIcon className="buttonicons" />
            &nbsp;Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HRandSalaryStructureLandibgPage;
