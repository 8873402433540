import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import ProcessProgressCircle from "../../ProcessProgressCircle/ProcessProgressCircle";

function SendEmailForDocumentRejection({
  editData,
  sendRequest,
  handleModalClose,
  setEditData,
}) {
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    id:'',
  });

  // State to track validation errors
  const [errors, setErrors] = useState({
    email: false,
    subject: false,
    message: false,
  });

  // State to track loading status for progress circle
  const [loading, setLoading] = useState(false);

  const handleInputChange = (field, value) => {
    setEmailData((prev) => ({ ...prev, [field]: value }));

    // Remove error for the field if user starts typing
    if (value.trim() !== "") {
      setErrors((prev) => ({ ...prev, [field]: false }));
    }
  };

  useEffect(() => {
    if (editData) {
      setEmailData({
        id:editData.id,
        name: editData.EmployeeName || "",
        email: editData.PersonalMailId || "",
        subject: "Employee Document Verification Failed",
        message:
          "We request you to correct the rejected fields and documents, and kindly resubmit them for verification.",
      });
    }
  }, [editData]);

  const handleSendEmail = async () => {
    // Basic validation
    const { email, subject, message } = emailData;
    let hasError = false;

    if (!email.trim()) {
      setErrors((prev) => ({ ...prev, email: true }));
      hasError = true;
    }

    if (!subject.trim()) {
      setErrors((prev) => ({ ...prev, subject: true }));
      hasError = true;
    }

    if (!message.trim()) {
      setErrors((prev) => ({ ...prev, message: true }));
      hasError = true;
    }

    if (hasError) return; // Stop if validation fails

    // Set loading state to true while sending email
    setLoading(true);

    try {
      const response = await axios.post(
        `${baseURL}/sendEmailForRejectionDocuments`,
        emailData
      );

      if (response.status === 200) {
        customAlert("", " Request mail sent successfully!", "success");
        // setEmailData({ email: "", subject: "", message: "", name: "" }); // Reset form after sending

        handleModalClose();
      }
    } catch (error) {
      customAlert("", "Failed to send email. Please try again.", "error");
    } finally {
      // Set loading state to false once request is complete
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Modal for sending email */}
      <Modal open={sendRequest} onClose={handleModalClose}>
        <div className="mail-container">
          <div className="mail-modal-heading-alignment">
            <h5>Send Email Request</h5>
          </div>

          <IconButton
            id="button-zoom"
            className="employeemodalcancel"
            onClick={handleModalClose}
          >
            <CloseIcon className="employeemodalcancelX" />
          </IconButton>

          {/* Display Progress Circle if loading is true */}
          {loading && (
            <ProcessProgressCircle RequiredProgressName="Sending email..." />
          )}

          <TextField
            label="Recipient Email"
            fullWidth
            margin="normal"
            value={emailData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            error={errors.email}
            helperText={errors.email ? "Email is required" : ""}
            disabled={loading} // Disable field during loading
          />
          <TextField
            label="Subject"
            fullWidth
            margin="normal"
            value={emailData.subject}
            onChange={(e) => handleInputChange("subject", e.target.value)}
            error={errors.subject}
            helperText={errors.subject ? "Subject is required" : ""}
            disabled={loading} // Disable field during loading
          />
          <TextField
            label="Message"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={emailData.message}
            onChange={(e) => handleInputChange("message", e.target.value)}
            error={errors.message}
            helperText={errors.message ? "Message is required" : ""}
            disabled={loading} // Disable field during loading
          />

          <div className="send-email-container-alignment">
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleSendEmail}
              disabled={loading} // Disable button during loading
            >
              <SendIcon className="buttonicons" />
              &nbsp;Send Email
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SendEmailForDocumentRejection;
