import React, { useState, useEffect, useMemo, useCallback } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import "../UserSettingAPI/UserSetting.css";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import SaveIcon from '@mui/icons-material/Save';
import Dynamicimage from "./dynamicimage";

function UserSettingAPI() {
  const [inputText, setInputText] = useState(""); 
  const [showApiAlert, setShowApiAlert] = useState(false);
  const [apiKeyAvailable, setApiKeyAvailable] = useState(true); // Track API key availability
  const email = localStorage.getItem("HTES_user_id");

  const handleAlert = useCallback(() => {
    setShowApiAlert(true);
  }, []);

  const memoizedCustomAlert = useMemo(() => {
    return customAlert;
  }, []);

  
  useEffect(() => {    
    fetchApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  

  const fetchApiKey = () => {
    axios
      .get(baseURL + "/getUserApi", {
        params: {
          Login_Id: email,
        },
      })
      .then((response) => {
        const apiKey = response.data.API_Key;
        if (apiKey) {
          setInputText(apiKey);
          setApiKeyAvailable(true);
        } else {
          setInputText("");
          setApiKeyAvailable(false);
          handleAlert(); 
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    setShowApiAlert(false);
  };

  const handleButtonClick = () => {
    if (!inputText) {
      setShowApiAlert(true);
    } else {
      if (apiKeyAvailable) {
        updateApiKey();
      } else {
        createApiKey();
      }
    }
  };

  const updateApiKey = () => {
    axios
      .put(baseURL + "/updateUserApi", {
        Login_Id: email,
        API_Key: inputText,
      })
      .then((response) => {
        memoizedCustomAlert('', response.data.message, 'success');
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const createApiKey = () => {
    axios
      .post(baseURL + "/userApi", {
        Login_Id: email,
        API_Key: inputText,
      })
      .then((response) => {
        memoizedCustomAlert('', response.data.message, 'success');
        setApiKeyAvailable(true); // Set API key as available after creation
        
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleApiError = (error) => {
    if (!apiKeyAvailable) {
      // Show the alert only when the API key is not available
      // customAlert('', "No API key available. Please add one.", 'warning');
    } else if (error.response) {
      if (error.response.status === 400 || error.response.status === 401) {
      //  console.log('', error.response.data.message, 'error');
      } else {
        // console.log('', "An error occurred. Please try again later.", 'warning');
      }
    } else {
      // console.log('', "An error occurred. Please try again later.", 'warning');
    }
  };

  return (
    <>
      <div className="UserApishadowdivmaster">
        <h6 className="headerFontandstyle">Empower Your Experience</h6>
        <div className="Userinformationdiv">
          <input
            type="text"
            className="textarea"
            placeholder="Enter the Gemini API..."
            value={inputText}
            onChange={handleInputChange}
          />
          <div  onClick={handleButtonClick} className="buttonContainer">
            <Button
            id='button-zoom'
              variant="contained"
              color="success"
             
            >
              
              <SaveIcon className="buttonicons" />Save
            </Button>
          </div>
        </div>
        {showApiAlert && (
          <span className="apiAlert">* Please enter the API key.</span>          
        )}
        
      </div>

      <Dynamicimage/>
    </>
  );
}

export default UserSettingAPI;
