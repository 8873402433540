import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import EducationalRoadmapTemplate from "./EducationalRoadmapTemplate";
import AddEducationalMap from "./AddEducationalMap";
import GeneralTable from "./GeneralTable";
import { isReadOnly } from "../CheckReadOnlyStatus";

function EducationalRoadMapLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [manageOpen, setManageOpen] = useState(false);
  const [editData, setEditdata] = useState("");
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/dropdownOptions`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Additional Functions if the custom menu added(custommenuitems)
  //--------------------------------------------------------------------------------

  const handleView = (row, rowid) => {
    setEditdata(row);
    setOpenView(true);

    console.log("data", row, rowid);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View", //Name of the MenuItem
      onClick: handleView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
  ];

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    setEditdata(rowdata);
    setOpen(true);
    console.log("handle Edit functions", rowdata);
  };

  const handleClose1 = () => {
    setEditdata("");
    setOpen(false);
    handleClose();
  };

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setManageOpen(true);
  };

  const handleClose = () => {
    setManageOpen(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render with default column if empty
        rows={renderRows} // Rows render
        customMenuItems={customMenuItems} // Addition menu items
        handleEdit={handleEdit} //Handle the Edit function
        deleteApi="deleteEdRoadMap" // Delete Api
        tableNameForExport="Education-RoadMap" // Export the Data to excel/csv (If name requred for the Excel or Csv)
        is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
        handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
        Manage_button_name="Manage Table" //Name of the Manage button(in center of the header)
        SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
        DefaultColumnNames={[
          "Education",
          "Syllabus",
          "ChapterName",
          "TopicName",
        ]} // Add to show the columns in a default render
        CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
      />
      {/* _______________________End_____________________________ */}

      {/* _________________Manage educational roadmap add/upload________ */}
      <EducationalRoadmapTemplate
        SetDataBaseUpdate={SetDataBaseUpdate}
        manageOpen={manageOpen}
        handleClose={handleClose}
        renderColumns={renderColumns}
      />

      {/* ___________Educational RoadMap Edit_______________ */}
      <AddEducationalMap
        editData={editData}
        open={open}
        handleClose1={handleClose1}
        update={SetDataBaseUpdate}
      />

      {/* ___________________Educational roadmap View_______________ */}
      <GeneralTable
        editData={editData}
        openView={openView}
        handleCloseView={handleCloseView}
      />
    </div>
  );
}

export default EducationalRoadMapLandingPage;
