import React, { useEffect, useRef, useState } from "react";
import "./editorlanding.css";
import { Update } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AddPhotoAlternateSharpIcon from "@mui/icons-material/AddPhotoAlternateSharp";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import CodeIcon from "@mui/icons-material/Code";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { Button, TextField, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { ProgramLanguage } from "./languages";
import Autocomplete from "@mui/material/Autocomplete";

function EditorComponent({
  handleSave,
  content,
  setContent,
  question,
  setQuestion,
  editContent,
}) {
  const editorRef = useRef(null);
  const [showCodeSnippetModal, setShowCodeSnippetModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("JavaScript");
  const [codeText, setCodeText] = useState("");
  const [editingSnippetId, setEditingSnippetId] = useState(null);

  //-------------------------------------------------------------------------
  //Edit back the content
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (editContent) {
      editorRef.current.innerHTML = editContent;
      console.log("editContent", editContent);

      setContent(editContent);
    }
  }, [editContent]);

  //-------------------------------------------------------------------------
  //Code snippet insert
  //-------------------------------------------------------------------------

  const handleCodeSnippetInsert = () => {
    const snippetId = editingSnippetId || `snippet-${Date.now()}`;

    // Construct the code snippet HTML
    const codeSnippet = `
      <div id="${snippetId}" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); border-radius:5px; border: 1px solid rgb(216, 224, 240); display:inline-block; width:90%; margin-top:5px; margin-bottom:5px; height:auto;" contenteditable="false">
        <div style="display: flex; justify-content: space-between; align-items: center; padding:10px; background:#fafafa; border-radius: 5px;">
          <div style='font-weight:bold;color:black;'>${selectedLanguage}</div>
          <button data-snippet-id="${snippetId}" class="edit-button-editor2">Edit</button>
        </div>
        <pre style="text-align:left; font-family:'Roboto',san-sarif; background: #ffffff; padding: 1px; margin:1px; margin-left: 20px; line-height:26px; white-space: pre-wrap; word-break: break-word; color:black;">${codeText
          .split("\n")
          .map(
            (line, index) =>
              `<span>${index + 1}.</span> <span>${line.trimStart()}</span>`
          )
          .join("\n")}</pre>
        <div hidden data-code="${encodeURIComponent(
          codeText
        )}" data-lang="${selectedLanguage}"></div>
      </div>
    `;

    console.log("Inserting HTML:", codeSnippet);

    if (editingSnippetId) {
      const existingSnippet = document.getElementById(editingSnippetId);
      console.log("existingSnippet", existingSnippet);
      if (existingSnippet) {
        // Replace the existing snippet with the updated content
        existingSnippet.outerHTML = codeSnippet;
        // Ensure the content in the editor is updated
        setContent(editorRef.current.innerHTML); // Reactively update the content state
      } else {
        console.error("Snippet element not found for ID:", editingSnippetId);
      }
    } else {
      insertHtmlAtCaret(codeSnippet); // If no editing snippet, insert new one
    }

    // Update the content in the editor after snippet insertion
    console.log("Updated content:", editorRef.current.innerHTML);

    // Close the modal (if applicable)
    closeCodeSnippetModal();
  };

  //-------------------------------------------------------------------------
  //Inser the image or code snippet at the cursor point
  //-------------------------------------------------------------------------
  const insertHtmlAtCaret = (html) => {
    const editor = editorRef.current;
    editor.focus();
    const selection = window.getSelection();

    if (selection.rangeCount > 0 && editor.contains(selection.anchorNode)) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      const div = document.createElement("div");
      div.innerHTML = html;
      const frag = document.createDocumentFragment();

      let node, lastNode;
      while ((node = div.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      if (lastNode) {
        range.setStartAfter(lastNode);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    } else {
      editor.innerHTML += html;
    }

    handleContentChange();
  };

  //-------------------------------------------------------------------------
  //click the cursor to active the editor
  //-------------------------------------------------------------------------
  const handleEditorClick = (event) => {
    if (event.target.classList.contains("edit-button-editor2")) {
      const snippetId = event.target.getAttribute("data-snippet-id");
      toggleEditMode(snippetId);
    }
  };

  //-------------------------------------------------------------------------
  //Toggle the edit and save mode
  //-------------------------------------------------------------------------

  const toggleEditMode = (snippetId) => {
    const snippetElement = document.getElementById(snippetId);
    if (snippetElement) {
      const codeDataElement = snippetElement.querySelector("div[data-code]");

      const existingCode = decodeURIComponent(
        codeDataElement.getAttribute("data-code")
      );
      const existingLanguage = codeDataElement.getAttribute("data-lang");

      setCodeText(existingCode);
      setSelectedLanguage(existingLanguage);
      setEditingSnippetId(snippetId);
      setShowCodeSnippetModal(true);
    } else {
      console.error("Snippet element not found for ID:", snippetId);
    }
  };

  //-------------------------------------------------------------------------
  //Content change
  //-------------------------------------------------------------------------
  const handleContentChange = () => {
    setContent(editorRef.current.innerHTML);
  };

  //-------------------------------------------------------------------------
  //Tool bar button click
  //-------------------------------------------------------------------------
  const handleCommand = (command) => {
    editorRef.current.focus();
    document.execCommand(command, false, null);
  };

  //-------------------------------------------------------------------------
  //Opne the code snippet
  //-------------------------------------------------------------------------

  const openCodeSnippetModal = () => {
    setCodeText("");
    setSelectedLanguage("JavaScript");
    setEditingSnippetId(null);
    setShowCodeSnippetModal(true);
  };

  //-------------------------------------------------------------------------
  //Close the code snippet
  //-------------------------------------------------------------------------
  const closeCodeSnippetModal = () => {
    setShowCodeSnippetModal(false);
    setEditingSnippetId(null);
  };

  //-------------------------------------------------------------------------
  //Image insert
  //-------------------------------------------------------------------------
  const handleImageInsert = () => {
    editorRef.current.focus();
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";

    fileInput.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const imgElement = `<div contenteditable="false" style="display:inline-block; position:relative; resize:both; max-width:800px; max-height:800px; overflow:auto; width:90%;">
            <img src="${event.target.result}" alt="Image" style="max-width: 90%; max-height: 90%; display:block; margin-top:5px; margin-bottom:5px;" />
          </div>`;
          insertHtmlAtCaret(imgElement);
        };
        reader.readAsDataURL(file);
      }
    };

    fileInput.click();
  };

  return (
    <div className="editor-container">
      <div className="toolbar-editor2">
        <div className="toolbar1">
          {/* __________Tool bar icosn buttons_______________ */}
          <Tooltip title="Bold" arrow>
            <button onClick={() => handleCommand("bold")}>
              <FormatBoldIcon className="buttoniconcommon-toolbar" />
            </button>
          </Tooltip>

          <Tooltip title="Italic" arrow>
            <button onClick={() => handleCommand("italic")}>
              <FormatItalicIcon className="buttoniconcommon-toolbar" />
            </button>
          </Tooltip>
          <Tooltip title="Underline" arrow>
            <button onClick={() => handleCommand("underline")}>
              <FormatUnderlinedIcon className="buttoniconcommon-toolbar" />
            </button>
          </Tooltip>
          <Tooltip title="InsertOrderedList" arrow>
            <button onClick={() => handleCommand("insertOrderedList")}>
              <FormatListNumberedIcon className="buttoniconcommon-toolbar" />
            </button>
          </Tooltip>
          <Tooltip title="InsertUnorderedList" arrow>
            <button onClick={() => handleCommand("insertUnorderedList")}>
              <FormatListBulletedIcon className="buttoniconcommon-toolbar" />
            </button>
          </Tooltip>
          <Tooltip title="Insert Image" arrow>
            <button onClick={handleImageInsert}>
              <AddPhotoAlternateSharpIcon className="buttoniconcommon-toolbar" />
            </button>
          </Tooltip>
          <Tooltip title="Insert Code" arrow>
            <button onClick={openCodeSnippetModal}>
              <CodeIcon className="buttoniconcommon-toolbar" />
            </button>
          </Tooltip>
        </div>
        {/* ______________________content save button______________ */}
        <div>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSave(content)}
            id="button-zoom"
          >
            <SaveIcon className="buttonicons" />
            &nbsp;Save
          </Button>
        </div>
      </div>
      {/* ___________________question and answer___________ */}
      <div className="header-text-field-container">
        <TextField
          label="Question"
          name="Question"
          fullWidth
          required
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </div>
      <div
        className="editable-area"
        contentEditable="true"
        ref={editorRef}
        onInput={handleContentChange}
        onClick={handleEditorClick}
        data-placeholder="Answer"
        suppressContentEditableWarning={true}
      ></div>
      {/* _________________code snippet modal ________________ */}
      <Modal
        open={showCodeSnippetModal}
        onClose={closeCodeSnippetModal}
        className="code-viewer-modal"
      >
        <div className="code-viewer-dialog-editor2">
          <IconButton
            id="button-zoom"
            className="enquiry-form-addeducationalMapmodalcancel"
            onClick={closeCodeSnippetModal}
          >
            <CloseIcon className="enquiry-form-addeducationalMapmodalcancelX" />
          </IconButton>
          <div className="code-snippet-insert-heading">
            {editingSnippetId ? "Edit Code " : "Insert Code "}
          </div>
          <div className="code-viewer-content-render">
            <div className="code-viewer-content-render-grid-container">
              <div>
                <Autocomplete
                  options={ProgramLanguage}
                  getOptionLabel={(option) => option.title}
                  value={
                    ProgramLanguage.find(
                      (lang) => lang.value === selectedLanguage
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    setSelectedLanguage(newValue ? newValue.value : "")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Language"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div>
                <TextField
                  name="Write Code Here.."
                  label="Write Code Here.."
                  variant="outlined"
                  value={codeText}
                  onChange={(e) => setCodeText(e.target.value)}
                  rows={10}
                  fullWidth
                  multiline
                  className="writecodehere"
                />
              </div>
            </div>
          </div>
          <div className="code-viewer-snippet-button-alignment">
            <Button
              variant="contained"
              id="button-zoom"
              color="success"
              onClick={handleCodeSnippetInsert}
            >
              {editingSnippetId ? (
                <Update className="buttonicons" />
              ) : (
                <SaveIcon className="buttonicons" />
              )}
              {editingSnippetId ? "Update" : "Save"}
            </Button>
          </div>
        </div>
        {/* _______________________________end_____________________ */}
      </Modal>
    </div>
  );
}

export default EditorComponent;
