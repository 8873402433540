import React, { useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableHeaderTemplate from "../../GeneralTemplateComponent/TableHeaderTemplate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import WorkIcon from "@mui/icons-material/Work";
import { baseURL } from "../../../http";
// import AddJobPostPage from "./AddJobPostPage";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { customAlert } from "../../SweetAlertCommon/Custom";
import "../JobPortal.css";
// import './CompanyProfile.css';
import AddCompanyProfilePage from "./AddCompanyProfilePage";

function CompanyProfileMain() {
  const [searchTerm, setSearchTerm] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call

  const [companyProfileData, setCompanyProfileData] = useState({
    id: "",
    CompanyName: "",
    CompanyLogo: "",
    HeadQuaterLocation: [],
    CompanyTypes: [],
    IndustryType: [],
    FoundedYear: "",
    Website: "",
    Address: "",
    Overview: "",
    Pincode: "",
    SocialMediaLinks: "",

    OverviewHtmlContent:'',
    OverviewNormalContent:'',

    Phone: "+91",
    City: "",
    State: "",
    CompanyEmail: "",

    Created_By: "",
    Created_date: "",
    Modified_By: "",
    Modified_date: "",
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [openEdit, setOpneEdit] = useState(false);
  const [editData, setEditdata] = useState();
  const [editId, setEditId] = useState();
  const [view, setView] = useState("renderView"); // render renderView or addEditView
  const [invalidFields, setInvalidFields] = useState({});

  const [cpNormalContent, setcpNormalContent] = useState(""); // render renderView or addEditView
  const [cpHTMLContent, setcpHTMLContent] = useState(""); // render renderView or addEditView
  const [descriptionAlert, setDescriptionAlert] = useState(false); // render renderView or addEditView

  //____________Fetch data when the component mounts_________________//
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/companyProflie_fetch_all`);
        const data = response.data;

        // Extract column names from the data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);

        // Set the login data and filtered data
        setCompanyProfileData(data);
        // setSelectedOption={(value)=>setCompanyProfileData({
        //   ...companyProfileData,
        //   JobLocations: value,
        // })}
        //  const JobLocations= JSON.parse(data.JobLocations)

        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata, id, mode) => {
    console.log("handle Edit functions", rowdata);
    // console.log("handle Edit functions");

    setCompanyProfileData({
      id: rowdata.id,
      CompanyID: rowdata.CompanyID,
      CompanyName: rowdata.CompanyName,
      CompanyLogo: rowdata.CompanyLogo,
      HeadQuaterLocation: JSON.parse(rowdata.HeadQuaterLocation),
      BranchLocations: rowdata.BranchLocations,
      CompanyTypes: JSON.parse(rowdata.CompanyTypes),
      // IndustryType: rowdata.IndustrialTypes,
      IndustryType: JSON.parse(rowdata.IndustrialTypes),
      
      FoundedYear: rowdata.FoundedYear,
      Website: rowdata.Website,
      Address: rowdata.Address,
      CompanyEmail: rowdata.CompanyEmail,
      // Overview: rowdata.Overview,
      
      Phone: rowdata.Phone,
      Pincode: rowdata.Pincode,
      SocialMediaLinks: rowdata.SocialMediaLinks,
      Phone: rowdata.Phone,
      City: rowdata.City,
      State: rowdata.State,
    });

   
    setcpNormalContent(rowdata.OverviewNormalContent);
    setcpHTMLContent(rowdata.OverviewHtmlContent);
    setView("addEditView");
    // setMode('addEditMode')
    // console.log(JSON.stringify(rowdata.Phone));
  };

  //--------------------------------------------------------------------------------
  //Handle View function on Table
  //--------------------------------------------------------------------------------
  const handleView = (rowdata, id, mode) => {};

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setView("addEditView");
    setCompanyProfileData({
      id: 0,
      CompanyID: "",
      CompanyName: "",
      CompanyLogo: "",
      HeadQuaterLocation: [],
      CompanyTypes: [],
      IndustryType: [],
      FoundedYear: "",
      Website: "",
      Address: "",
      Overview: "",
      Phone: "",
      Pincode: "",
      SocialMediaLinks: "",
      Phone: "+91",
      City: "",
      State: "",
      CompanyEmail: "",
      OverviewNormalContent:'',
      OverviewHtmlContent:''
    });
  };

  //--------------------------------------------------------------------------------
  //  API to Save Data
  //--------------------------------------------------------------------------------
  const handleSave = async () => {
    const user = localStorage.getItem("HTES_user_id");
    const requiredFields = [
      "CompanyName",
      "HeadQuaterLocation",
      "CompanyTypes",
      "IndustryType",
      "FoundedYear",
      "Website",
      "Address",
      "Phone",
      "Pincode",
      "State",
      "City",
      "CompanyEmail",
    ];
  
    const parseHTML = (htmlString) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;
      return tempDiv.innerText;
    };
  
    const sendData = {
      id: companyProfileData.id ? companyProfileData.id : 0,
      CompanyName: companyProfileData.CompanyName,
      CompanyLogo: companyProfileData.CompanyLogo,
      HeadQuaterLocation: companyProfileData.HeadQuaterLocation || [],
      CompanyTypes: companyProfileData.CompanyTypes || [],
      IndustryType: companyProfileData.IndustryType || [],
      FoundedYear: companyProfileData.FoundedYear,
      Website: companyProfileData.Website,
      Address: companyProfileData.Address,
      OverviewNormalContent: parseHTML(cpNormalContent),
      OverviewHtmlContent: cpNormalContent,
      Phone: companyProfileData.Phone,
      Pincode: companyProfileData.Pincode,
      SocialMediaLinks: companyProfileData.SocialMediaLinks,
      CompanyRating: companyProfileData.CompanyRating,
      CompanyEmail: companyProfileData.CompanyEmail,
      City: companyProfileData.City,
      State: companyProfileData.State,
    };
  
    if (companyProfileData.id > 0) {
      sendData.Modified_By = user;
    } else {
      sendData.Created_By = user;
    }
  
    // Validate required fields
    const newInvalidFields = {};
    const invalidFieldNames = [];
  
    for (const field of requiredFields) {
      if (!sendData[field] || (Array.isArray(sendData[field]) && sendData[field].length === 0)) {
        newInvalidFields[field] = true;
        invalidFieldNames.push(field);
      }
    }
  
    // Phone number validation
    if (sendData['Phone']) {
      const isValidPhone = sendData['Phone'] !== null && sendData['Phone'].trim().length >= 4;
      if (isValidPhone) {
        // Valid phone number
        // alert('Phone number is valid');
      } else {
        // Invalid phone number
        newInvalidFields['Phone'] = true;
        invalidFieldNames.push('Phone');
        // alert('Phone number is invalid');
      }
    }
  
    if (Object.keys(newInvalidFields).length > 0) {
      setInvalidFields(newInvalidFields);
      // alert(JSON.stringify(invalidFieldNames));
      return;
    }
    setInvalidFields({});
  
    if (parseHTML(cpNormalContent) === "") {
      customAlert("", `Enter the Overview`, "warning");
      setDescriptionAlert(true);
      return;
    } else {
      setDescriptionAlert(false);
    }
  
    if (!sendData['CompanyLogo']) {
      customAlert("", `Add Company Logo`, "warning");
      return;
    }
  
    let alertMessage = companyProfileData.id > 0 ? `Edit` : "Add";
    try {
      let api =
        companyProfileData.id > 0
          ? `/companyProflie_edit/${companyProfileData.id}`
          : "/companyProflie_insert";
      let httpMethod = companyProfileData.id > 0 ? "put" : "post";
      let response = await axios[httpMethod](`${baseURL}${api}`, sendData);
  
      if (response.status === 200) {
        customAlert("", `Company Profile ${alertMessage}ed`, "success");
        SetDataBaseUpdate(true);
        setView("renderView");
      } else {
        customAlert("", `API Error`, "warning");
      }
    } catch (error) {
      customAlert(
        "",
        `Server Down - While ${alertMessage}ing Company Profile`,
        "error"
      );
    }
  };
  

  return (
    <>
      {/*_______________________________ Render view _______________________________*/}
      {view == "renderView" ? (
        <TableHeaderTemplate
          columns={renderColumns} // Columns render with default column if empty
          rows={renderRows} // Rows render
          // customMenuItems={customMenuItems} // Addition menu items
          handleEdit={handleEdit} //Handle the Edit function
          deleteApi="companyProflie_delete" // Delete Api
          tableNameForExport="Post Job" // Export the Data to excel/csv (If name requred for the Excel or Csv)
          is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
          ManageButtonIcon={ApartmentIcon}
          handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
          Manage_button_name="Company Profile" //Name of the Manage button(in center of the header)
          SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
          DefaultColumnNames={[
            "id",
            "CompanyName",
            "IndustrialTypes",
            "Website",
          ]}
          // CloseActionDialog={CloseActionDialog} //Handle the action dialog close when the functionality complete(action menu container)
          // switchColumnName='isApproved'  // switch toggle
          // swithcUpdateApi='jobPost_approve'        // api name -  .put(`${baseURL}/${swithcUpdateApi}/${row.id}`
        />
      ) : (
        <div className="companyProflie_editor_page_Parent">
          {/*_______________________________ Editor view _______________________________*/}

          {/*_______________________________ Editor Header _______________________________*/}
          <div className="companyProflie_editor_page_child">
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              className="addJobEditorBackBtn"
              onClick={() => setView("renderView")}
            >
              <ArrowBackIosIcon />

              <span className="edt_saveBtn">Back</span>
            </Button>

            <div className="AddPageHeader">
              <ApartmentIcon className="common-icon-designs" />
              <span className="common-filter-and-sort-responsive-manage headerbtn">
                &nbsp;
                {companyProfileData.id > 0
                  ? "Edit Company Profile"
                  : "Add Company Profile"}
              </span>
            </div>

            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleSave}
              className="addJobEditorSaveBtn"
            >
              <SaveIcon />
              <span className="edt_saveBtn">
              {companyProfileData.id > 0 ? "Update" : "Save"}
                </span>
            </Button>
          </div>

          <hr className="companyProfileHeaderSeparator" />

          {/* ______________________________ Editor Area ________________________________ */}

          <div className="companyProflie_Editor_Section">
            <AddCompanyProfilePage
              companyProfileData={companyProfileData}
              setCompanyProfileData={setCompanyProfileData}
              SetDataBaseUpdate={SetDataBaseUpdate}
              invalidFields={invalidFields}
              setInvalidFields={setInvalidFields}
              cpNormalContent={cpNormalContent}
              setcpNormalContent={setcpNormalContent}
              cpHTMLContent={cpHTMLContent}
              setcpHTMLContent={setcpHTMLContent}
            />
          </div>
        </div>
      )}

      {/*_______________________________ Add view _______________________________*/}
    </>
  );
}

export default CompanyProfileMain;
