import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Tooltip } from "@mui/material";
// import "../batchmanagement.css";
import axios from "axios";
import { baseURL } from "../../../http";
import ManageBatchInstructor from "./ManageBatchInstructor";

function ManageBatchInstructorLandingPage({
  handleManageInstructorClose,
  editData,
  SetDataBaseUpdate,
}) {
  const [data, setData] = useState([]);

  //--------------------------------------------------------------------------------
  //Call the api to fetch the instructor
  //--------------------------------------------------------------------------------
  const fetchTestGenInfoname = async () => {
    try {
      const response = await axios.get(`${baseURL}/Instructor_fetch_all`);
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch test info, retrying...", error);
      fetchTestGenInfoname();
    }
  };

  useEffect(() => {
    fetchTestGenInfoname();
  }, []);

  return (
    <>
      <div className="createtestgeneralinfoemarionsmalldiv">
        <div className="edit-icon-alignemnt">
          <Tooltip title="Back to Home" arrow>
            <IconButton onClick={handleManageInstructorClose}>
              <ArrowBackIcon className="edit-icon-color-and-fontsize-generalinfo" />
            </IconButton>
          </Tooltip>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            <span className="info-dispaly-font-color">
              Manage Instructor-{editData.BatchName}
            </span>
          </div>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            {/* <DownloadButton filteredTestData={selectedBatchTest} BatchName={editData.BatchName}/> */}
          </div>
        </div>
      </div>

      {/* __________Manage instructor Component call___________ */}
      <ManageBatchInstructor
        renderRows={data}
        editData={editData}
        handleManageInstructorClose={handleManageInstructorClose}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />
    </>
  );
}

export default ManageBatchInstructorLandingPage;
