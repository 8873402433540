import React, { useState, useEffect } from "react";
import { Avatar, IconButton, Button, Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Update } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import FilterButton from "../../GeneralTemplateComponent/FilterButton";
import SaveIcon from "@mui/icons-material/Save";
import { customAlert } from "../../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../../http";
import InstructorCardView1 from "./InstructorCardView1";
import InstructorCardView from "./InstructorCardView";
import {
  getCurrentDateTime,
  getUtcTime,
} from "../../Common/Utilities_Function";

function ManageBatchInstructor({
  handleManageInstructorClose,
  editData,
  renderRows,
  SetDataBaseUpdate,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [allData, setAllData] = useState([]);
  const [SelectedTest, setSelectedTest] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  //--------------------------------------------------------------------------------
  // Pre fill the data when render
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData && editData.GroupName && allData.length > 0) {
      try {
        // Attempt to parse the Instructor field safely
        const parsedTests = JSON.parse(editData.InstructorIds);

        // Ensure parsedTests is an array before proceeding
        if (Array.isArray(parsedTests)) {
          // Filter allData based on matching TestName values
          const selectedTests = allData.filter((test) =>
            parsedTests.includes(test.instructorEmail)
          );
          setSelectedTest(selectedTests);
        } else {
          console.error("Parsed Instructor data is not an array:", parsedTests);
        }
      } catch (error) {
        // Handle JSON parsing errors
        console.error("Error parsing Instructor data:", error);
      }
    }
  }, [editData?.BatchName, editData, allData]);

  //--------------------------------------------------------------------------------
  // Filter function based on the search term
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const filteredData = allData.filter((question) => {
      // Convert the object to an array of its values and check if any value includes the search term
      return Object.values(question).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setSortedData(filteredData);
  }, [searchTerm, allData]);

  //--------------------------------------------------------------------------------
  // Function to handle search input change
  //--------------------------------------------------------------------------------
  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Update the search term
  };

  //--------------------------------------------------------------------------------
  // sort the data columns for filter
  //--------------------------------------------------------------------------------
  useEffect(() => {
    setSortedData(renderRows);
    setAllData(renderRows);

    if (renderRows.length > 0) {
      const columnKeys = Object.keys(renderRows[0]).map((key) => ({
        field: key,
        headerName: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize headers
      }));
      setColumns(columnKeys);
    }
  }, [renderRows]);

  //--------------------------------------------------------------------------------
  //Toggle side bar Question view
  //--------------------------------------------------------------------------------
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  //--------------------------------------------------------------------------------
  //Api call to update the data
  //--------------------------------------------------------------------------------
  const handleClickTestsAdded = () => {
    // Extract only the 'TestName' properties from the SelectedTest array
    const testNames = SelectedTest.map((test) => test.instructorEmail);

    if (testNames.length === 0) {
      customAlert("", "Please select Instructors", "warning");
    } else {
      axios
        .put(`${baseURL}/CreateGroup_edit/${editData.id}`, {
          GroupName: editData.GroupName, // Replace with your actual group name
          StudentIds: JSON.parse(editData.StudentIds),
          InstructorIds: testNames, // Replace with actual instructor IDs
          UTC_Time: getUtcTime(),
          Modified_By: localStorage.getItem("HTES_user_id"), // Replace with the name of the modifier
          Modified_date: getCurrentDateTime(),
        })
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            customAlert("", "Instructors Enrolled Successfully", "success");
            SetDataBaseUpdate(true);
            handleManageInstructorClose();
          } else {
            customAlert("", "No Instructors Added", "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          customAlert("", "Server Down", "error");
        });
    }
  };

  return (
    <div className="main-container-all-component-render">
      {/* Left container, dynamically adjusts between 70% and 100% width */}

      <div className={`left-container ${isOpen ? "expanded" : "full-width"}`}>
        <div className="Avatar-and-Button-conatiner-in-question-view-container">
          {!isOpen && (
            <Tooltip title="Total selected Instructors" arrow>
              <Avatar className="avatar-alignemnt-in-add-to-test">
                {SelectedTest?.length}
              </Avatar>
            </Tooltip>
          )}
        </div>
        <div className="Avatar-and-Button-conatiner-in-question-view-container1">
          <Button
            variant="contained"
            color="success"
            id="button-zoom"
            onClick={handleClickTestsAdded}
          >
            {editData ? (
              <Update className="buttonicons" />
            ) : (
              <SaveIcon className="buttonicons" />
            )}
            &nbsp;{editData ? "Update" : "Save Test"}
          </Button>
        </div>
        <div
          className={`selected-question-view-container ${
            isOpen ? "expanded" : "full-width"
          }`}
        >
          {/* Check if selectedQuestions is empty */}
          {SelectedTest.length === 0 ? (
            <div className="no-questions-message">
              Please add the Instructor.
            </div>
          ) : (
            <InstructorCardView
              SelectedTest={SelectedTest}
              setSelectedTest={setSelectedTest}
              Testall={SelectedTest}
            />
          )}
        </div>
      </div>

      {/* Right 30% container, can slide in/out */}
      {isOpen && (
        <div className={`right-container ${isOpen ? "open" : "closed"}`}>
          <Tooltip title="Close the Instructor Data" arrow>
            <IconButton className="toggle-button" onClick={toggleSidebar}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Tooltip>
          <div className="container-for-filter-search-all-component-render">
            <div className="common-page-search-container-all-component-render">
              <span className="common-page-search-icon-all-component-render">
                <SearchIcon />
              </span>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search..."
                className="search-input-all-component-render"
              />
            </div>
            <div>
              <Tooltip title="Total Instructor" arrow>
                <Avatar className="avatar-alignemnt-in-add-to-test">
                  {allData?.length}
                </Avatar>
              </Tooltip>
            </div>
            <div>
              <FilterButton
                columns={columns}
                rows={allData}
                setSortedData={setSortedData}
              />
            </div>
          </div>
          <div className="sidebar-content">
            <InstructorCardView1
              SelectedTest={SelectedTest}
              setSelectedTest={setSelectedTest}
              Testall={sortedData}
            />
          </div>
        </div>
      )}

      {!isOpen && (
        <span>
          <Tooltip title="Open the Instructor Data" arrow>
            <IconButton
              className="toggle-button closed"
              onClick={toggleSidebar}
            >
              <ArrowBackIosIcon className="close-the-container-arrow-alignment" />
            </IconButton>
          </Tooltip>
        </span>
      )}
    </div>
  );
}

export default ManageBatchInstructor;
