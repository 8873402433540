import React, { useEffect, useState, useRef } from "react";
import { TextField, MenuItem, Button, Select } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
import { baseURL } from "../../../http";
import "./employeedetails.css";
import { customAlert } from "../../SweetAlertCommon/Custom";
import {
  getCurrentDateTime,
  getUtcTime,
} from "../../Common/Utilities_Function";
import UpdateIcon from "@mui/icons-material/Update";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import SendEmailForDocumentRejection from "./SendEmailForDocumentRejection";
import Radio from "@mui/material/Radio";
import Avatar from "@mui/material/Avatar";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function EmployeePhase1InfoDocumentView() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    EmployeeName: "",
    DateOfBirth: "",
    Gender: "",
    AdhaarNumber: "",
    PersonalMailId: "",
    PhoneNumber: "",
    TenthMarksCard: "",
    PreUGMarksCard: "",
    UGMarksCard: "",
    CompletionCertificate: "",
    AadhaarDocument: "",
    Photo: "",
    Created_by: "",
    Created_date: getCurrentDateTime(),
    Modified_by: "",
    Modified_date: getCurrentDateTime(),
    UTC_Time: getUtcTime(),
    PanDocument: "",
    BankAccountDocument: "",
    PfAccountDocument: "",
    Resume: "",
    MedicalInsuranceNumber: "",
    BloodGroup: "",
    DocumentStatus: {
      EmployeeName: { Status: "", Comment: "" },
      DateOfBirth: { Status: "", Comment: "" },
      Gender: { Status: "", Comment: "" },
      PhoneNumber: { Status: "", Comment: "" },
      AdhaarNumber: { Status: "", Comment: "" },
      PersonalMailId: { Status: "", Comment: "" },
      TenthMarksCard: { Status: "", Comment: "" },
      PreUGMarksCard: { Status: "", Comment: "" },
      UGMarksCard: { Status: "", Comment: "" },
      CompletionCertificate: { Status: "", Comment: "" },
      AadhaarDocument: { Status: "", Comment: "" },
      PanDocument: { Status: "", Comment: "" },
      BankAccountDocument: { Status: "", Comment: "" },
      PfAccountDocument: { Status: "", Comment: "" },
      Resume: { Status: "", Comment: "" },
      PanNumber: { Status: "", Comment: "" },
      BankAccountNumber: { Status: "", Comment: "" },
      PfAccountNumber: { Status: "", Comment: "" },
      IfscCode: { Status: "", Comment: "" },
      Photo: { Status: "", Comment: "" },
      BloodGroup:{ Status: "", Comment: "" },
      // other fields as needed
    },
    PanNumber: "",
    BankAccountNumber: "",
    PfAccountNumber: "",
    IfscCode: "",
  });
  const employeeEditId = localStorage.getItem("Employee_Edit_Id");

  const [approveAll, setApproveAll] = useState(true);
  const [rejectAll, setRejectAll] = useState(true);
  const [oldStatus, setOldStatus] = useState(false); // Store previous state

  const countStatusesForRadioCheck = (documentStatus) => {
    let approvedCount = 0;
    let rejectedCount = 0;

    Object.values(documentStatus).forEach(({ Status }) => {
      if (Status === "Approved") {
        approvedCount += 1;
      } else if (Status === "Rejected") {
        rejectedCount += 1;
      }
    });

    return { approvedCount, rejectedCount };
  };

  useEffect(() => {
    const { approvedCount, rejectedCount } = countStatusesForRadioCheck(
      formData.DocumentStatus
    );

    // Update button states based on counts
    if (approvedCount === 21) {
      setApproveAll(false);
      setRejectAll(true); // Ensure Reject All is unchecked
    } else if (rejectedCount === 21) {
      setApproveAll(true); // Ensure Approve All is unchecked
      setRejectAll(false);
    } else {
      setApproveAll(true);
      setRejectAll(true);
    }
  }, [formData.DocumentStatus]); // Dependency array

  // Fetch employee data when component mounts
  useEffect(() => {
    if (employeeEditId || oldStatus === true) {
      axios
        .get(baseURL + `/Employees_fetch/${employeeEditId}`)
        .then((response) => {
          // Assuming the data comes in a similar structure
          const fetchedData = response.data;
          setData(fetchedData);
          console.log("fetchedData", fetchedData);

          // Parse DocumentStatus if it's a string
          let documentStatus = fetchedData.DocumentStatus;

          if (typeof documentStatus === "string") {
            try {
              documentStatus = JSON.parse(documentStatus); // Convert to JSON object
            } catch (error) {
              console.error("Error parsing DocumentStatus:", error);
              documentStatus = {}; // Default to an empty object if parsing fails
            }
          }

          // Map the fetched data to the formData state
          setFormData({
            EmployeeName: fetchedData.EmployeeName || "",
            DateOfBirth: fetchedData.DateOfBirth || "",
            Gender: fetchedData.Gender || "",
            AdhaarNumber: fetchedData.AdhaarNumber || "",
            PersonalMailId: fetchedData.PersonalMailId || "",
            PhoneNumber: fetchedData.PhoneNumber || "",
            TenthMarksCard: fetchedData.TenthMarksCard || "",
            PreUGMarksCard: fetchedData.PreUGMarksCard || "",
            UGMarksCard: fetchedData.UGMarksCard || "",
            CompletionCertificate: fetchedData.CompletionCertificate || "",
            AadhaarDocument: fetchedData.AadhaarDocument || "",
            PanDocument: fetchedData.PanDocument || "",
            BankAccountDocument: fetchedData.BankAccountDocument || "",
            PfAccountDocument: fetchedData.PfAccountDocument || "",
            Resume: fetchedData.Resume || "",
            DocumentStatus: documentStatus, // Use parsed document status
            PanNumber: fetchedData.PanNumber || "",
            BankAccountNumber: fetchedData.BankAccountNumber || "",
            PfAccountNumber: fetchedData.PfAccountNumber || "",
            IfscCode: fetchedData.IfscCode || "",
            Photo: fetchedData.Photo || "",
            BloodGroup: fetchedData.BloodGroup || "",
          });

          const formattedPhoneNumber = `+${fetchedData.PhoneNumber}`;
          setPhone(formattedPhoneNumber);
          setOldStatus(false);
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
        });
    }
  }, [employeeEditId, oldStatus]);

  const handleApproveAll = () => {
    if (approveAll) {
      // Uncheck 'Approve All' and revert to previous status
      setFormData((prevData) => ({ ...prevData, DocumentStatus: oldStatus }));
      setApproveAll(false);
      setRejectAll(true);
      updateDocumentStatus("Approved");
    } else {
      setOldStatus(true); // Save current state
      setApproveAll(true);
      setRejectAll(true);
    }
  };

  

  const handleRejectAll = () => {
    if (rejectAll) {
      // Uncheck 'Reject All' and revert to previous status
      setFormData((prevData) => ({ ...prevData, DocumentStatus: oldStatus }));
      setRejectAll(false);
      setApproveAll(true);
      updateDocumentStatus("Rejected");
    } else {
      setOldStatus(true); // Save current state
      setRejectAll(true);
      setApproveAll(true);
    }
  };

  const updateDocumentStatus = (status) => {
    const updatedStatus = { ...formData.DocumentStatus };
    Object.keys(updatedStatus).forEach((key) => {
      // Check if the corresponding field is not empty
      if (formData[key] && formData[key].trim() !== "") {
        // Update status for filled fields
        updatedStatus[key].Status = status;
      } else {
        // Keep status as 'Pending' for empty fields
        updatedStatus[key].Status = "Pending";
      }
    });
    setFormData({ ...formData, DocumentStatus: updatedStatus });
  };
  

  console.log(formData);
  const [errors, setErrors] = useState({});
  const [errors1, setErrors1] = useState({});
  const [countryCode, setCountryCode] = useState("in");
  const textFields1 = ["PanNumber"];
  const textFields2 = ["IfscCode"];
  const textFields = ["PersonalMailId"];
  const textFields3 = ["EmployeeName"];
  const numberFields = ["AdhaarNumber"];
  const numberFields1 = ["BankAccountNumber"];
  const numberFields2 = ["PfAccountNumber"];
  const dateFields = ["DateOfBirth"];
  const dropdownFields = ["Gender"];
  const dropdownFields1 = ["BloodGroup"];
  const documentFields = [
    "Photo",
    "AadhaarDocument",
    "Resume",
    "TenthMarksCard",
    "PreUGMarksCard",
    "UGMarksCard",
    "CompletionCertificate",
    "PanDocument",
    "BankAccountDocument",
    "PfAccountDocument",
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Allowed file types and size limit (in bytes)
    const allowedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const maxSize = 2048 * 1024; // 2048 KB in bytes

    // Check if the event is for a file input
    if (files && files.length > 0) {
      const file = files[0];

      // Check file size
      if (file.size > maxSize) {
        customAlert(
          "",
          "File size exceeds the maximum limit of 2048 KB.",
          "warning"
        );
        return;
      }

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        customAlert(
          "",
          "Invalid file type. Only doc, docx, jpg, jpeg, png, and pdf files are allowed.",
          "warning"
        );
        return;
      }

      // Update formData with the selected file and change the status to "Pending"
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          [name]: {
            Status: "Pending", // Set the status to "Pending" when a file is selected
            Comment: prevData.DocumentStatus[name]?.Comment || "",
          },
        },
      }));
    } else {
      // Handle regular input fields (non-file inputs)
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          [name]: {
            Status: "Pending", // Set the status to "Pending" when a file is selected
            Comment: prevData.DocumentStatus[name]?.Comment || "",
          },
        },
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const tempErrors = {};

    if (!formData.EmployeeName) {
      newErrors.EmployeeName = "Employee Name is required";
    }

    if (!formData.AdhaarNumber || formData.AdhaarNumber.length !== 12) {
      newErrors.AdhaarNumber = "Aadhaar number must be 12 digits";
    }

    const phoneStr = String(phone).trim();
    const phoneNumber = parsePhoneNumberFromString(phoneStr, countryCode);
    if (phoneNumber) {
      if (!phoneNumber.isValid()) {
        tempErrors.PhoneNumber = "Phone number is not valid";
      }
    } else {
      tempErrors.PhoneNumber = "Phone number is required";
    }

    if (!formData.DateOfBirth) {
      newErrors.DateOfBirth = "Date of Birth is required";
    }

    if (!formData.Gender) {
      newErrors.Gender = "Gender is required";
    }

    if (
      !formData.PersonalMailId ||
      !/\S+@\S+\.\S+/.test(formData.PersonalMailId)
    ) {
      newErrors.PersonalMailId = "Valid email is required";
    }

    setErrors(newErrors);
    setErrors1(tempErrors);

    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(tempErrors).length === 0
    );
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.countryCode.toUpperCase());

    // Parse and validate the phone number
    const phoneNumber = parsePhoneNumberFromString(
      value,
      country.countryCode.toUpperCase()
    );

    if (phoneNumber && phoneNumber.isValid()) {
      // Update formData with a valid phone number and set status to "Pending"
      setFormData((prevData) => ({
        ...prevData,
        PhoneNumber: value,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          PhoneNumber: {
            Status: "Pending", // Set status to "Pending" for the phone number
            Comment: prevData.DocumentStatus?.PhoneNumber?.Comment || "",
          },
        },
      }));

      // Clear any phone number errors
      setErrors1((prev) => ({ ...prev, PhoneNumber: "" }));
    } else {
      // Set error if the phone number is not valid
      setErrors1((prev) => ({
        ...prev,
        PhoneNumber: "Phone number is not valid",
      }));
    }
  };

  const checkRequiredFields = () => {
    const requiredFields = [
      "EmployeeName",
      "DateOfBirth",
      "Gender",
      "AdhaarNumber",
      "PersonalMailId",
      "PhoneNumber",
      "TenthMarksCard",
      "PreUGMarksCard",
      "UGMarksCard",
    ];

    const missingFields = requiredFields.filter(
      (field) => !formData[field] || formData[field] === ""
    );

    if (missingFields.length > 0) {
      return `Missing fields: ${missingFields.join(", ")}`;
    }
    return null;
  };

  const handlCloseMailForm = () => {
    setOpen(false);
  };

  const handlOpenMailForm = () => {
    setOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (!validateForm()) return;

    const missingFieldsError = checkRequiredFields();
    if (missingFieldsError) {
      customAlert("", missingFieldsError, "warning");
      return;
    }

    // Check if any documents are rejected
    const anyRejected = Object.values(formData.DocumentStatus).some(
      (doc) => doc.Status === "Rejected"
    );

    // Check if all documents are approved
    const allApproved = Object.values(formData.DocumentStatus).every(
      (doc) => doc.Status === "Approved"
    );

    // Determine the status to submit
    const status = anyRejected
      ? "rejected"
      : allApproved
      ? "approved"
      : "pending";

    const payload = {
      ...formData,
      Created_by: formData.PersonalMailId,
      Modified_by: formData.PersonalMailId,
      Created_date: getCurrentDateTime(),
      Modified_date: getCurrentDateTime(),
      UTC_Time: getUtcTime(),
      DocumentStatus: JSON.stringify(formData.DocumentStatus),
      Status: status, // Include the status in the payload
    };

    try {
      const response = await axios.post(baseURL + "/Employee_Data", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (anyRejected) {
        handlOpenMailForm(); // Open the mail form if any document status is 'rejected'
      } else {
        customAlert(
          "",
          "Employee Basic Data Status Updated Successfully",
          "success"
        );
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error submitting form:", error.response.data);
    }
  };

  const renderTextFields = () =>
    textFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            //disabled={!formData[field] || formData[field].trim() === ""} // Disable dropdown if field is empty
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));
  

  const renderTextFields1 = () =>
    textFields1.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>

        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>

        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            //disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));

  const renderTextFields2 = () =>
    textFields2.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            //disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));
  const renderTextFields3 = () =>
    textFields3.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            //disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));

  const renderNumberFields = () =>
    numberFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
            type="number"
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            // disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""}  value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem  value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));

  const renderNumberFields1 = () =>
    numberFields1.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
            type="number"
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            // disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));

  const renderNumberFields2 = () =>
    numberFields2.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
            type="number"
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            //disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));

  const renderDateFields = () =>
    dateFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            required
            type="date"
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors[field]}
            helperText={errors[field]}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            // disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));

  const renderDropdownFields = () =>
    dropdownFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            select
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </TextField>
        </div>
        <div className="status1">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment1">
          {/* Dropdown for Status in the Status Column */}
          <Select
            className="select-fields-width-container"
            value={formData.DocumentStatus[field]?.Status || "Pending"}
            onChange={(e) => {
              const newStatus = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Comment, only update Status
                    Status: newStatus,
                    Comment: prevData.DocumentStatus[field]?.Comment || "",
                  },
                },
              }));
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            // disabled={!formData[field] || formData[field].trim() === ""}
            style={{
              color:
                formData.DocumentStatus[field]?.Status === "Approved"
                  ? "green"
                  : formData.DocumentStatus[field]?.Status === "Rejected"
                  ? "red"
                  : "black",
            }}
          >
            <MenuItem value="Pending" style={{ color: "black" }}>
              Pending
            </MenuItem>
            <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
              Approved
            </MenuItem>
            <MenuItem value="Rejected" style={{ color: "red" }}>
              Rejected
            </MenuItem>
          </Select>
        </div>
      </div>
    ));


    const renderDropdownFields1 = () =>
      dropdownFields1.map((field, idx) => (
        <div className="text-field-status-comment" key={idx}>
          <div className="slno-and-text-fileds">
            <TextField
              key={idx}
              select
              label={field.replace(/([A-Z])/g, " $1").trim()}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors[field]}
              helperText={errors[field]}
            >
             <MenuItem value="A+ (A Positive)">A+ (A Positive)</MenuItem>
              <MenuItem value="A- (A Negative)">A- (A Negative)</MenuItem>
              <MenuItem value="B+ (B Positive)">B+ (B Positive)</MenuItem>
              <MenuItem value="B- (B Negative)">B- (B Negative)</MenuItem>
              <MenuItem value="AB+ (AB Positive)">AB+ (AB Positive)</MenuItem>
              <MenuItem value="AB- (AB Negative)">AB- (AB Negative)</MenuItem>
              <MenuItem value="O+ (O Positive)">O+ (O Positive)</MenuItem>
              <MenuItem value="AB- (AB Negative)">O- (O Negative)</MenuItem>
            </TextField>
          </div>
          <div className="status1">
            <TextField
              name={`comment-${field}`}
              value={formData.DocumentStatus[field]?.Comment || ""}
              onChange={(e) => {
                const newComment = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  DocumentStatus: {
                    ...prevData.DocumentStatus,
                    [field]: {
                      // Retain the existing Status, only update Comment
                      Status: prevData.DocumentStatus[field]?.Status || "Pending",
                      Comment: newComment,
                    },
                  },
                }));
              }}
              fullWidth
              multiline
              rows={1}
              variant="outlined"
              placeholder="Add your comment"
            />
          </div>
          <div className="comment1">
            {/* Dropdown for Status in the Status Column */}
            <Select
              className="select-fields-width-container"
              value={formData.DocumentStatus[field]?.Status || "Pending"}
              onChange={(e) => {
                const newStatus = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  DocumentStatus: {
                    ...prevData.DocumentStatus,
                    [field]: {
                      // Retain the existing Comment, only update Status
                      Status: newStatus,
                      Comment: prevData.DocumentStatus[field]?.Comment || "",
                    },
                  },
                }));
              }}
              fullWidth
              variant="outlined"
              displayEmpty
              // disabled={!formData[field] || formData[field].trim() === ""}
              style={{
                color:
                  formData.DocumentStatus[field]?.Status === "Approved"
                    ? "green"
                    : formData.DocumentStatus[field]?.Status === "Rejected"
                    ? "red"
                    : "black",
              }}
            >
              <MenuItem value="Pending" style={{ color: "black" }}>
                Pending
              </MenuItem>
              <MenuItem disabled={!formData[field] || formData[field].trim() === ""} value="Approved" style={{ color: "green" }}>
                Approved
              </MenuItem>
              <MenuItem value="Rejected" style={{ color: "red" }}>
                Rejected
              </MenuItem>
            </Select>
          </div>
        </div>
      ));

    

  const adjustedBaseURL = baseURL.slice(0, -4);

  const handleDeleteFile = async (field, dbColumnName) => {
    const filePath = formData[field]; // Get the file path (e.g., 'EmployeeDocuments/manohara.k@happytechnovation.com/TenthMarksCard_1726216565.docx')
    const userEmail = formData.PersonalMailId; // Assuming you have the user email stored in formData

    try {
      // Make API request to delete the file and update the DB column to null
      const response = await axios.delete(
        baseURL + "/Employee_Data_delete_file",
        {
          params: {
            path: filePath,
            dbColumnName: dbColumnName, // Send the database column name
            email: userEmail, // Send the user email to identify the record
          },
        }
      );

      if (response.status === 200) {
        // File successfully deleted, update formData and DocumentStatus to reflect this
        setFormData((prevData) => ({
          ...prevData,
          [field]: "", // Clear the file path from formData
          DocumentStatus: {
            ...prevData.DocumentStatus,
            [field]: {
              Status: "", // Clear the status for this document
              Comment: "", // Clear the comment for this document
            },
          },
        }));

        customAlert(
          "",
          "File deleted and database updated successfully",
          "success"
        );
      } else {
        customAlert("", "File deletion failed", "warning");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      customAlert("", "Error occurred while deleting the file", "error");
    }
  };

  const renderDocumentFields = () =>
    documentFields.map((field, idx) => (
      <div className="text-field-status-comment1" key={idx}>
        <div className="slno-and-text-fileds">
          <div key={idx}>
            <label className="align-items-heading" htmlFor={field}>
              {`Upload ${field.replace(/([A-Z])/g, " $1").trim()}`}
            </label>

            <input
              type="file"
              id={field}
              name={field}
              onChange={handleChange}
              style={{ display: "none" }}
            />

            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => document.getElementById(field).click()}
              >
                Choose File
              </Button>

              <span>
                &nbsp;
                {formData[field]?.name ? (
                  formData[field].name
                ) : formData[field] && typeof formData[field] === "string" ? (
                  <>
                    {/* Display link if document exists */}
                    <a
                      href={`${adjustedBaseURL}/uploads/${formData[field]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{formData[field].split("/").pop()}</span>
                    </a>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteFile(field, field)}
                      aria-label="delete"
                    >
                      <DeleteIcon className="buttonicons" color="error" />
                    </IconButton>
                  </>
                ) : (
                  "No file chosen"
                )}
              </span>

              {/* Hidden input field to send document path if editing */}
              {formData[field] && typeof formData[field] === "string" && (
                <input
                  type="hidden"
                  name={`${field}_existing`}
                  value={formData[field]}
                />
              )}
            </div>
          </div>
        </div>

        {/* Comment Section */}
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>

        {/* Status Text */}
        <div className="comment">
          {/* Dropdown for Status in the Status Column */}
          <Select
  className="select-fields-width-container"
  value={formData.DocumentStatus[field]?.Status || "Pending"}
  onChange={(e) => {
    const newStatus = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      DocumentStatus: {
        ...prevData.DocumentStatus,
        [field]: {
          // Retain the existing Comment, only update Status
          Status: newStatus,
          Comment: prevData.DocumentStatus[field]?.Comment || "",
        },
      },
    }));
  }}
  fullWidth
  variant="outlined"
  displayEmpty
  style={{
    color:
      formData.DocumentStatus[field]?.Status === "Approved"
        ? "green"
        : formData.DocumentStatus[field]?.Status === "Rejected"
        ? "red"
        : "black",
  }}
>
  <MenuItem value="Pending" style={{ color: "black" }}>
    Pending
  </MenuItem>
  <MenuItem
    value="Approved"
    disabled={
      !formData[field] ||
      (typeof formData[field] === "string" && formData[field].trim() === "")
    }
    style={{ color: "green" }}
  >
    Approved
  </MenuItem>
  <MenuItem value="Rejected" style={{ color: "red" }}>
    Rejected
  </MenuItem>
</Select>

        </div>
      </div>
    ));

  const countStatuses = (documentStatus) => {
    let approvedCount = 0;
    let rejectedCount = 0;

    Object.values(documentStatus).forEach(({ Status }) => {
      if (Status === "Approved") {
        approvedCount += 1;
      } else if (Status === "Rejected") {
        rejectedCount += 1;
      }
    });

    return { approvedCount, rejectedCount };
  };

  const statusCounts = countStatuses(formData.DocumentStatus);

  const countFilledFields = (formData) => {
    // Define the fields you want to track
    const fields = [
      "EmployeeName",
      "DateOfBirth",
      "Gender",
      "AdhaarNumber",
      "PersonalMailId",
      "PhoneNumber",
      "TenthMarksCard",
      "PreUGMarksCard",
      "UGMarksCard",
      "CompletionCertificate",
      "AadhaarDocument",
      "PanDocument",
      "BankAccountDocument",
      "PfAccountDocument",
      "Resume",
      "PanNumber",
      "BankAccountNumber",
      "PfAccountNumber",
      "IfscCode",
      'BloodGroup',
      'Photo'
    ];

    let filledCount = 0;
    let totalCount = fields.length;

    fields.forEach((field) => {
      if (formData[field]) {
        filledCount += 1;
      }
    });

    return `${filledCount}/${totalCount}`;
  };

  const FilledCounts = countFilledFields(formData);


  const isPhoneNumberValid = Boolean(phone); // Check if phone is not empty
const isPhoneNumberFieldEmpty = !formData.DocumentStatus.PhoneNumber?.Comment?.trim(); // Check if the phone number comment is empty

const shouldDisable = !isPhoneNumberValid || isPhoneNumberFieldEmpty;

  return (
    <div className="phse1-employee-info-background-color">
      <div className="employee_phase1_container">
        <div className="employee_phase1_heading_view">
          <div>Basic Information View</div>
          <div className="checkbox-alignment-for-reject-and-approve">
            <div>
             
              <Radio
                className="buttonicons"
                checked={!approveAll}
                onChange={handleApproveAll}
                color="success"
              />
              <span className="text-alignment-and-font-size-approved">
                Approve All
              </span>
            </div>
            <div>
              <Radio
                className="buttonicons"
                checked={!rejectAll}
                onChange={handleRejectAll}
                color="error"
              />
             
              <span className="text-alignment-and-font-size-rejected">
                Reject All
              </span>
            </div>
          </div>
        </div>

        <div className="employee_phase1_main_container">
          <div className="employee_phase1_main_grid_container1">
            <div className="text-field-status-comment2">
              <div className="slno-and-text-fileds">Basic Data Fields</div>
              <div className="status">Comment</div>
              <div className="comment">
                Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </div>

          <div className="employee_phase1_main_grid_container">
            {renderTextFields()}
            <div className="text-field-status-comment1">
              <div className="slno-and-text-fileds">
                <div className={errors1.PhoneNumber ? "phone-input-error" : ""}>
                  <PhoneInput                
                    country={countryCode.toLowerCase()}
                    value={phone}
                    onChange={handlePhoneChange}
                    inputStyle={{
                      width: "100%",
                      height: "55px",
                      fontSize: "16px",
                      outline: errors1.PhoneNumber ? "1px solid red" : "",
                    }}
                    buttonStyle={{
                      background: "white",
                      borderLeft: errors1.PhoneNumber ? "2px solid red" : "",
                      borderTop: errors1.PhoneNumber ? "2px solid red" : "",
                      borderBottom: errors1.PhoneNumber ? "2px solid red" : "",
                      borderRight: "none",
                    }}
                    dropdownStyle={{
                      fontSize: "16px",
                    }}
                    placeholder="Employee Phone Number"
                  />
                  {errors1.PhoneNumber && (
                    <div className="helper-text-phone-container">
                      {errors1.PhoneNumber}
                    </div>
                  )}
                </div>
              </div>

              <div className="status">
                <TextField
                  name="comment-PhoneNumber"
                  value={formData.DocumentStatus.PhoneNumber?.Comment || ""}
                  onChange={(e) => {
                    const newComment = e.target.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      DocumentStatus: {
                        ...prevData.DocumentStatus,
                        PhoneNumber: {
                          // Retain the existing Status, only update Comment
                          Status:
                            prevData.DocumentStatus.PhoneNumber?.Status ||
                            "Pending",
                          Comment: newComment,
                        },
                      },
                    }));
                  }}
                  fullWidth
                  multiline
                  rows={1}
                  variant="outlined"
                  placeholder="Add your comment"
                />
              </div>
              <div className="comment">
                {/* Dropdown for Status in the Status Column */}
                <Select
                  className="select-fields-width-container"
                  value={formData.DocumentStatus["PhoneNumber"]?.Status || "Pending"}
                  onChange={(e) => {
                    const newStatus = e.target.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      DocumentStatus: {
                        ...prevData.DocumentStatus,
                        PhoneNumber: {
                          // Retain the existing Comment, only update Status
                          Status: newStatus,
                          Comment:
                            prevData.DocumentStatus["PhoneNumber"]?.Comment ||
                            "",
                        },
                      },
                    }));
                  }}
                  fullWidth
                  variant="outlined"
                 
                 
                  style={{
                    color:
                      formData.DocumentStatus["PhoneNumber"]?.Status ===
                      "Approved"
                        ? "green"
                        : formData.DocumentStatus["PhoneNumber"]?.Status ===
                          "Rejected"
                        ? "red"
                        : "black",
                  }}
                >
                  <MenuItem value="Pending" style={{ color: "black" }}>
                    Pending
                  </MenuItem>
                  <MenuItem value="Approved" style={{ color: "green" }}>
                    Approved
                  </MenuItem>
                  <MenuItem value="Rejected" style={{ color: "red" }}>
                    Rejected
                  </MenuItem>
                </Select>
              </div>
            </div>
            {renderTextFields3()}
            {renderDateFields()}
            {renderDropdownFields()}
            {renderDropdownFields1()}
            {renderNumberFields()}
            {renderTextFields1()}
            {renderNumberFields1()}
            {renderTextFields2()}
            {renderNumberFields2()}
            {renderDocumentFields()}
          </div>
        </div>
        <div className="form-submit-button-of-employee-phase1">
          <div className="form-data-status-display">
            Filled Fields:
            <Avatar className="filled-fields-fontsize">{FilledCounts}</Avatar>
          </div>

          <div>
            <Button
              type="submit"
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={(e) => handleSubmit(e)} // Pass 'approved' and event
            >
              <CheckCircleOutlineIcon className="buttonicons" />
              &nbsp; Finalize
            </Button>
          </div>
          <div className="form-data-status-display1">
            {/* Status:
            <Avatar className="filled-fields-fontsize">
              {" "}
              <span dangerouslySetInnerHTML={{ __html: statusCounts }} />
            </Avatar> */}
            <div className="avatar-and-Text-alignment">
              Approved:&nbsp;{" "}
              <Avatar className="filled-fields-fontsize-approved">
                {statusCounts.approvedCount}
              </Avatar>
            </div>
            <div className="avatar-and-Text-alignment1">
              &nbsp;Rejected:&nbsp;{" "}
              <Avatar className="filled-fields-fontsize-rejected">
                {statusCounts.rejectedCount}
              </Avatar>
            </div>
          </div>
        </div>
        <SendEmailForDocumentRejection
          editData={data}
          sendRequest={open}
          handleModalClose={handlCloseMailForm}
          setEditData={setData}
        />
      </div>
    </div>
  );
}

export default EmployeePhase1InfoDocumentView;
