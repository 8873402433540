import React, { useState, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import McqQuestionGenerator from "./McqQuestionGenerator";
import axios from "axios";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { baseURL } from "../../http";
import "../McqsGeneration/McqGeneration.css";
import { franc } from "franc";
// import ISO6391 from "iso-639-1";
import { customAlert } from "../SweetAlertCommon/Custom";
import SendIcon from "@mui/icons-material/Send";
import { Stack } from "@mui/system";

function McqGeneration() {
  const email = localStorage.getItem("HTES_user_id");
  const questionGenerateCategories = [
    "MCQs",
    "True/False",
    "Fill in the Blanks",
    "Question and Answer",
  ];

  const [questionType, setQuestionType] = useState(null);
  const [difficultyLevel, setDifficultyLevel] = useState(null);
  const [noOfQuestions, setNoOfQuestions] = useState("4");
  const [fontSize, setFontSize] = useState(15);
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [apiKey, setApiKey] = useState("");
  const [aiResponse, setAiResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [marks, setMarks] = useState("1 Mark"); // State for marks, used for question type "Question and Answer"

  //-------------------------------------------------------------------------
  // Used to call the api
  //-------------------------------------------------------------------------
  useEffect(() => {
    axios
      .get(baseURL + "/getUserApi", {
        params: {
          Login_Id: email,
        },
      })
      .then((response) => {
        if (response.data.API_Key) {
          setApiKey(response.data.API_Key);
        } else {
          console.log("API Key Not Available");
        }
      })
      .catch((error) => {
        // Remove the console.error statement
        // console.error("API not found");
      });
  }, []);

  //-------------------------------------------------------------------------
  // Language code based detect the language
  //-------------------------------------------------------------------------
  useEffect(() => {
    const languageCode = franc(text);

    setDetectedLanguage(languageCode);
  }, [text]);

  //-------------------------------------------------------------------------
  // update the word count
  //-------------------------------------------------------------------------
  const updateWordCount = (text) => {
    const words = text.trim().split(/\s+/);
    setWordCount(words.length);
  };

  //-------------------------------------------------------------------------
  // Handle text change
  //-------------------------------------------------------------------------
  const handleTextChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    updateWordCount(newText);
  };

  //-------------------------------------------------------------------------
  // Generate the question using the Gemini
  //-------------------------------------------------------------------------
  const generateQuestions = async (prompt) => {
    setLoading(true);
    const genAI = new GoogleGenerativeAI(apiKey);
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });

    let responseText = "";

    try {
      const result = await model.generateContent(prompt);
      const response = await result.response;
      responseText = response.text();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    return responseText;
  };

  //-------------------------------------------------------------------------
  // Handle click to generate the Question
  //-------------------------------------------------------------------------
  const handleClick = async () => {
    if (!questionType || !difficultyLevel || !text) {
      customAlert(
        "",
        "Some required data is missing. Please check all fields.",
        "warning"
      );
      return;
    }
    if (!apiKey) {
      customAlert(
        "",
        "API key not found. Please enter the API key in the user Setting API Page",
        "warning"
      );

      return;
    }

    if ((questionType && difficultyLevel && text) || marks) {
      let prompt = "";
      switch (questionType) {
        case "MCQs":
          prompt = `Generate multiple-choice questions based on the given text: "${text}".The questions should be at ${difficultyLevel} difficulty level and there should be ${noOfQuestions} questions generated. Each question should have four options and one correct answer. Ensure that the questions are relevant to the text and cover key concepts. Format of the input should be as follows:[{"id": 1,"question": "","options": ["Option 1", "Option 2", "Option 3", "Option 4"],"answer": "Correct Option"}]`;
          break;
        case "True/False":
          prompt = `Generate true/false questions based on the given text only: "${text}". The questions should be at ${difficultyLevel} difficulty level and there should be ${noOfQuestions} questions generated. Each question should have two options: "True" and "False", with one correct answer. Ensure that the questions are relevant to the text and cover key concepts. Format of the input should be as follows: [{"id": 1,"question": "","options": ["True", "False"],"answer": "Correct Option"}]`;
          break;
        case "Fill in the Blanks":
          prompt = `Generate fill in the blanks questions based on the given text only: "${text}". The questions should be at ${difficultyLevel} difficulty level and there should be ${noOfQuestions} Fill in the Blanks questions generated. Ensure that the questions are relevant to the text and cover key concepts. Format of the input should be as follows: [{"id": 1,"question": "","answer": "Correct Answer"}]`;
          break;
        case "Question and Answer":
          prompt = `Generate questions and answers based on the given text only: "${text}". The questions should be worth ${marks} marks each, and there should be ${noOfQuestions} questions and answers generated. Ensure that the questions are relevant to the text and cover key concepts. Please provide the questions and answers in the following format: [{"id": 1, "question": "Your question here?", "answer": "Correct Answer"}] Remember to consider the content when formulating the questions and answers.`;
          break;
        default:
          break;
      }

      let responseText = "";
      do {
        responseText = await generateQuestions(prompt);
      } while (!isValidJSON(responseText));

      setAiResponse(responseText);
    } else {
      customAlert("", "Please select the input field properly", "warning");
    }
  };

  const isValidJSON = (text) => {
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className="mcqgeneration">
      <div className="textareaContainer">
        <div className="inputContainer">
          <div className="inputContainerstyle">
            <h6 className="TextareaFontsize">Font Size:</h6>
            <input
              type="number"
              className="fontSizeInput"
              placeholder="Font size"
              min="1"
              value={fontSize}
              onChange={(e) => setFontSize(e.target.value)}
            />
          </div>
          <span className="wordNameContainer">
            DetectedLanguage: {detectedLanguage}
          </span>
          <div className="wordNameContainer">Word Count: {wordCount}</div>
        </div>
        <textarea
          type="text"
          className="textArea"
          placeholder="Enter Your Text Here..."
          style={{ fontSize: `${fontSize}px` }}
          value={text}
          onChange={handleTextChange}
        ></textarea>

        <div className="questiontypeselection">
          <Autocomplete
            limitTags={2}
            id="question-type-autocomplete"
            options={questionGenerateCategories}
            getOptionLabel={(option) => option}
            value={questionType}
            onChange={(event, value) => setQuestionType(value)}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ className: "textfieldlabel" }}
                {...params}
                label="Question Type"
              />
            )}
          />
        </div>
        <Stack />
        <div className="questionanddifficultylevelbutton">
          {questionType !== "Question and Answer" && (
            <Autocomplete
              limitTags={2}
              id="difficulty-level-autocomplete"
              options={["Easy", "Medium", "Hard"]}
              getOptionLabel={(option) => option}
              value={difficultyLevel}
              onChange={(event, value) => setDifficultyLevel(value)}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{ className: "textfieldlabel" }}
                  {...params}
                  label="Difficulty Level"
                />
              )}
            />
          )}
          {/* Marks input - Conditionally render based on question type */}
          {questionType === "Question and Answer" && (
            <Autocomplete
              limitTags={2}
              id="marks-autocomplete"
              options={[
                "1 Mark",
                "2 Marks",
                "3 Marks",
                "4 Marks",
                "5 Marks",
                "10 Marks",
                "15 Marks",
                "20 Marks",
              ]}
              getOptionLabel={(option) => option}
              value={marks}
              onChange={(event, value) => setMarks(value)}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{ className: "textfieldlabel" }}
                  {...params}
                  label="Marks"
                />
              )}
            />
          )}
          <TextField
            InputLabelProps={{ className: "textfieldlabel" }}
            type="number"
            value={noOfQuestions}
            onChange={(e) => setNoOfQuestions(e.target.value)}
            inputProps={{ min: "1" }}
            label="No. Question Count"
          />
        </div>
        <div className="buttonSubmit">
          {loading ? (
            <div style={{ margin: "auto" }}>
              <CircularProgress size={20} sx={{ color: "white" }} />
              <span className="loadinganimation">Loading...</span>
            </div>
          ) : (
            <Button
              id="button-zoom1"
              variant="contained"
              color="success"
              onClick={handleClick}
            >
              <SendIcon className="buttonicons" />
              &nbsp;submit
            </Button>
          )}
        </div>
      </div>
      <div>
        <McqQuestionGenerator
          aiResponse={aiResponse}
          questionType={questionType}
          Difficulty={difficultyLevel}
          Marks={marks}
        />
      </div>
    </div>
  );
}

export default McqGeneration;
