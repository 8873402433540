import React, { useState, useEffect } from "react";
import Editor1 from "../../Editor/Editor1";
import { TextField, Autocomplete, Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {
  companyNameOptions,
  designationOptions,
  educationRequiredOptions,
  employmentTypesOptions,
  industrialTypesOptions,
  locationsPreferred,
  requiredKeySkillsOptions,
} from "../Common";
import CustomMultiSelect from "../../Templates/MultiSelect/CustomMultiSelect";
import "../JobPortal.css";

function AddJobPostPage({
  jobPostData,
  setJobPostData,
  invalidFields,
  setInvalidFields,
  numericError,
  setNumericError,
  roles_Responsibilities_NormalContent,
  setRoles_Responsibilities_NormalContent,
  roles_Responsibilities_HtmlContent,
  setRoles_Responsibilities_HtmlContent,
  JobDescription_NormalContent,
  setJob_Description_NormalContent,
  JobDescription_HtmlContent,
  setJob_Description_HtmlContent,
  perks_NormalContent,
  setPerks_NormalContent,
  perks_HtmlContent,
  setPerks_HtmlContent,
}) {
  const [minOpeningError, setMinOpeningError] = useState(false);
  const [maxOpeningError, setMaxOpeningError] = useState(false);
  const [minExperienceError, setMinExperienceError] = useState(false);
  const [maxExperienceError, setMaxExperienceError] = useState(false);
  const [minSalaryError, setMinSalaryError] = useState(false);
  const [maxSalaryError, setMaxSalaryError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  useEffect(() => {
    if (
      minOpeningError ||
      maxOpeningError ||
      minExperienceError ||
      maxExperienceError ||
      minSalaryError ||
      maxSalaryError ||
      startDateError ||
      endDateError
    ) {
      setNumericError(true);
    } else {
      setNumericError(false);
    }
  }, [
    minOpeningError,
    maxOpeningError,
    minExperienceError,
    maxExperienceError,
    minSalaryError,
    maxSalaryError,
    startDateError,
    endDateError,
  ]);

  const handleJobPostDataChange = (field, newValue) => {
    // Convert newValue to Date object if it's a date field
    const newDateValue = new Date(newValue);
    const today = new Date();
    const formattedToday = new Date(today.setHours(0, 0, 0, 0)); // Normalize today's date

    setJobPostData((prevData) => {
      const updatedData = { ...prevData, [field]: newValue };
      const startDate = new Date(updatedData.ApplicationStartDate);
      const endDate = new Date(updatedData.ApplicationEndDate);

      // Handle ApplicationStartDate changes
      if (field === "ApplicationStartDate") {
        if (newDateValue < formattedToday) {
          setStartDateError("Start Date cannot be in the past");
        } else if (newDateValue > endDate) {
          setStartDateError("Start Date cannot be after End Date");
        } else {
          setStartDateError(false);
        }
        setEndDateError(false); // Reset end date error in any case
      }

      // Handle ApplicationEndDate changes
      if (field === "ApplicationEndDate") {
        if (newDateValue < startDate) {
          setEndDateError("End Date cannot be before Start Date");
        } else {
          setEndDateError(false);
        }
        if (startDate > today && startDate < endDate) {
          setStartDateError(false);
        }
        if (endDate < today || endDate < startDate) {
          setEndDateError(true);
        }
      }

      // Handle DesignationSalaryMin changes
      if (field === "DesignationSalaryMin") {
        const minSalary = parseInt(newValue, 10);
        const maxSalary = parseInt(updatedData.DesignationSalaryMax, 10);

        if (isNaN(minSalary) || minSalary < 0) {
          setMinSalaryError("Min Salary should be a non-negative number");
        } else if (!isNaN(maxSalary) && minSalary > maxSalary) {
          setMinSalaryError("Min Salary should be less than Max Salary");
          setMaxSalaryError(false);
        } else {
          setMinSalaryError(false);
          setMaxSalaryError(false);
        }
      }

      // Handle DesignationSalaryMax changes
      if (field === "DesignationSalaryMax") {
        const maxSalary = parseInt(newValue, 10);
        const minSalary = parseInt(updatedData.DesignationSalaryMin, 10);

        if (isNaN(maxSalary) || maxSalary < 0) {
          setMaxSalaryError("Max Salary should be a non-negative number");
        } else if (!isNaN(minSalary) && maxSalary < minSalary) {
          setMaxSalaryError("Max Salary should be greater than Min Salary");
          setMinSalaryError(false);
        } else {
          setMinSalaryError(false);
          setMaxSalaryError(false);
        }
      }

      // Handle RequiredMinExperience changes
      if (field === "RequiredMinExperience") {
        const minExp = parseInt(newValue, 10);
        const maxExp = parseInt(updatedData.RequiredMaxExperience, 10);

        if (isNaN(minExp) || minExp < 0) {
          setMinExperienceError(
            "Min Experience should be a non-negative number"
          );
        } else if (!isNaN(maxExp) && minExp > maxExp) {
          setMinExperienceError(
            "Min Experience should be less than or equal to Max Experience"
          );
          setMaxExperienceError(false);
        } else {
          setMinExperienceError(false);
          setMaxExperienceError(false);
        }
      }

      // Handle RequiredMaxExperience changes
      if (field === "RequiredMaxExperience") {
        const maxExp = parseInt(newValue, 10);
        const minExp = parseInt(updatedData.RequiredMinExperience, 10);

        if (isNaN(maxExp) || maxExp < 0) {
          setMaxExperienceError(
            "Max Experience should be a non-negative number"
          );
        } else if (!isNaN(minExp) && maxExp < minExp) {
          setMaxExperienceError(
            "Max Experience should be greater than or equal to Min Experience"
          );
          setMinExperienceError(false);
        } else {
          setMinExperienceError(false);
          setMaxExperienceError(false);
        }
      }

      // Handle MinimumOpenings changes
      if (field === "MinimumOpenings") {
        const minOpen = parseInt(newValue, 10);
        const maxOpen = parseInt(updatedData.MaximumOpenings, 10);

        if (isNaN(minOpen) || minOpen < 0) {
          setMinOpeningError("Min. Opening should be a non-negative number");
        } else if (!isNaN(maxOpen) && minOpen > maxOpen) {
          setMinOpeningError(
            "Min. Opening should be less than or equal to Max Openings"
          );
          setMaxOpeningError(false);
        } else {
          setMinOpeningError(false);
          setMaxOpeningError(false);
        }
      }

      // Handle MaximumOpenings changes
      if (field === "MaximumOpenings") {
        const maxOpen = parseInt(newValue, 10);
        const minOpen = parseInt(updatedData.MinimumOpenings, 10);

        if (isNaN(maxOpen) || maxOpen < 0) {
          setMaxOpeningError("Max. Opening should be a non-negative number");
        } else if (!isNaN(minOpen) && maxOpen < minOpen) {
          setMaxOpeningError(
            "Max. Opening should be greater than or equal to Min Openings"
          );
          setMinOpeningError(false);
        } else {
          setMinOpeningError(false);
          setMaxOpeningError(false);
        }
      }

      // For other fields, just update the jobPostData
      if (
        [
          "RequiredKeyskills",
          "JobLocations",
          "RequiredEducationUG",
          "RequiredEducationPG",
          "JobRole",
          "RoleCategory",
          "EmploymentType",
          "IndustryType",
          "Department",
          "CompanyName",
          "Designation",
        ].includes(field)
      ) {
        return updatedData;
      }

      return updatedData;
    });
  };

  return (
    <div className="CompanyProfilePageOuterContainer">
      {/* ____________________ Designation & Company Name _______________________ */}
      <div className="companyProfileContainer  ">
        {/* Designation */}
        <div className="companyProflieGroup">
          <div className="item1">
            <Autocomplete
              fullWidth
              options={designationOptions}
              // getOptionLabel={(option) => option}
              value={jobPostData.Designation || ""}
              onChange={(e, newValue) =>
                handleJobPostDataChange("Designation", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                  fullWidth
                  value={jobPostData.Designation || ""}
                  onChange={(e) =>
                    handleJobPostDataChange("Designation", e.target.value)
                  }
                  InputProps={{
                    ...params.InputProps,
                    className: "input_Custom_Style",
                  }}
                  error={invalidFields.Designation}
                  helperText={invalidFields.Designation ? "* mandatory" : ""}
                />
              )}
            />
          </div>
        </div>

        {/* Company Name */}
        <div className="companyProflieGroup">
          <div className="item1">
            <Autocomplete
              fullWidth
              options={companyNameOptions}
              getOptionLabel={(option) => option}
              value={jobPostData.CompanyName || ""}
              onChange={(e, newValue) =>
                handleJobPostDataChange("CompanyName", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company Name"
                  fullWidth
                  value={jobPostData.CompanyName || ""}
                  onChange={(e) =>
                    handleJobPostDataChange("CompanyName", e.target.value)
                  }
                  InputProps={{
                    ...params.InputProps,
                    className: "input_Custom_Style",
                  }}
                  error={invalidFields.CompanyName}
                  helperText={invalidFields.CompanyName ? "* mandatory" : ""}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* ____________________ Application Date Start and end ___________________ */}
      <div className="companyProfileContainer  ">
        {/* Application Date - Start */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              type="date"
              fullWidth
              label="Application StartDate  "
              value={jobPostData.ApplicationStartDate || ""}
              onChange={
                (e, value) =>
                  handleJobPostDataChange(
                    "ApplicationStartDate",
                    e.target.value
                  )
                // alert(e.target.value)
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  className: "input_custom_Date",
                  min: new Date().toISOString().split("T")[0],
                  max: jobPostData.ApplicationEndDate,
                },
                className: "input_Custom_Style",
              }}
              error={startDateError || invalidFields.ApplicationStartDate}
              helperText={
                invalidFields.ApplicationStartDate
                  ? "* mandatory"
                  : startDateError
                  ? "Should be less than End date & Greater than today"
                  : ""
              }
            />
          </div>
        </div>

        {/* Application Date - End */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              type="date"
              fullWidth
              label="Application End Date  "
              value={jobPostData.ApplicationEndDate || ""}
              onChange={(e, value) =>
                handleJobPostDataChange("ApplicationEndDate", e.target.value)
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  className: "input_custom_Date",
                  min: jobPostData.ApplicationStartDate,
                },
                className: "input_Custom_Style",
              }}
              error={endDateError || invalidFields.ApplicationEndDate}
              // helperText={invalidFields.ApplicationEndDate ? "* mandatory" : ""}
              helperText={
                invalidFields.ApplicationEndDate
                  ? "* mandatory"
                  : endDateError
                  ? "Should be greater than Start date"
                  : ""
              }
            />
          </div>
        </div>
      </div>
      {/* ____________________ Openings Min & Max ___________________ */}
      <div className="companyProfileContainer">
        {/* Min -Openings */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              label="Min Openings"
              fullWidth
              type="number"
              value={jobPostData.MinimumOpenings || ""}
              onChange={(e) => {
                const value = e.target.value;
                // Only allow whole numbers (integers)
                if (/^\d*$/.test(value)) {
                handleJobPostDataChange("MinimumOpenings", e.target.value)
                }
              }}
              InputProps={{
                className: "input_Custom_Style",
              
              }}
              error={
                !!minOpeningError ||
                (invalidFields.MinimumOpenings && !jobPostData.MinimumOpenings)
              }
              helperText={
                minOpeningError
                  ? minOpeningError
                  : invalidFields.MinimumOpenings
                  ? "* mandatory"
                  : ""
              }
            />
          </div>
        </div>

        {/* Max -Openings */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              label="Max Openings"
              fullWidth
              type="number"
              value={jobPostData.MaximumOpenings || ""}
              onChange={(e) => {
                const value = e.target.value;
                // Only allow whole numbers (integers)
                if (/^\d*$/.test(value)) {
                  handleJobPostDataChange("MaximumOpenings", value);
                }
              }}
              InputProps={{
                className: "input_Custom_Style",
               
              }}
              error={
                !!maxOpeningError ||
                (invalidFields.MaximumOpenings && !jobPostData.MaximumOpenings)
              }
              helperText={
                maxOpeningError
                  ? maxOpeningError
                  : invalidFields.MaximumOpenings
                  ? "* mandatory"
                  : ""
              }
            />
          </div>
        </div>
      </div>

      {/* ____________________ EmploymentType & IndustryType  (auto-dd) ______ */}
      <div className="companyProfileContainer  ">
        {/* EmploymentType */}
        <div className="companyProflieGroup">
          <div className="item1">
            <CustomMultiSelect
              placeholder="Type / Select Employment type"
              chipClassName="jobPostLocationtag"
              chipDeleteClassName="jobPostLocationDeleteX"
              inputPropStyleClass="input_Custom_Style"
              isMandatoryError={invalidFields.EmploymentType}
              helperText="* mandatory"
              options={employmentTypesOptions}
              selectedOption={jobPostData.EmploymentType || ""}
              setSelectedOption={(value) =>
                setJobPostData({
                  ...jobPostData,
                  EmploymentType: value,
                })
              }
            />
          </div>
        </div>

        {/* IndustryType */}
        <div className="companyProflieGroup">
          <div className="item1">
            <CustomMultiSelect
              placeholder="Type / Select Industry type"
              chipClassName="jobPostLocationtag"
              chipDeleteClassName="jobPostLocationDeleteX"
              inputPropStyleClass="input_Custom_Style"
              isMandatoryError={invalidFields.IndustryType}
              helperText="* mandatory"
              options={industrialTypesOptions}
              selectedOption={jobPostData.IndustryType || ""}
              setSelectedOption={(value) =>
                setJobPostData({
                  ...jobPostData,
                  IndustryType: value,
                })
              }
            />
          </div>
        </div>
      </div>

      {/* ____________________ DesignationSalary - Min and Max  ______________ */}
      <div className="companyProfileContainer">
        {/* DesignationSalary Min */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              fullWidth
              type="number"
              value={jobPostData.DesignationSalaryMin || ""}
              onChange={(e) =>
                handleJobPostDataChange("DesignationSalaryMin", e.target.value)
              }
              label="Min Salary"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">LPA</InputAdornment>
                ),
                inputProps: {
                  min: 0, // Set minimum value
                  max: 35, // Set maximum value
                },
                className: "input_Custom_Style",
              }}
              error={
                !!minSalaryError ||
                (invalidFields.DesignationSalaryMin &&
                  !jobPostData.DesignationSalaryMin)
              }
              helperText={
                minSalaryError
                  ? minSalaryError
                  : invalidFields.DesignationSalaryMin &&
                    !jobPostData.DesignationSalaryMin
                  ? "* mandatory"
                  : ""
              }
            />
          </div>
        </div>

        {/* DesignationSalary Max */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              fullWidth
              type="number"
              value={jobPostData.DesignationSalaryMax || ""}
              onChange={(e) =>
                handleJobPostDataChange("DesignationSalaryMax", e.target.value)
              }
              label="Max Salary"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">LPA</InputAdornment>
                ),
                inputProps: {
                  min: 0, // Set minimum value
                  max: 35, // Set maximum value
                },
                className: "input_Custom_Style",
              }}
              error={
                !!maxSalaryError ||
                (invalidFields.DesignationSalaryMax &&
                  !jobPostData.DesignationSalaryMax)
              }
              helperText={
                maxSalaryError
                  ? maxSalaryError
                  : (invalidFields.DesignationSalaryMax ? "* mandatory" : "")
                  ? "* mandatory"
                  : ""
              }
            />
          </div>
        </div>
      </div>

      {/* ____________________ Experience_Req - Min and Max  ______________ */}
      <div className="companyProfileContainer">
        {/* Experience_Req Min */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              fullWidth
              type="number"
              value={jobPostData.RequiredMinExperience || ""}
              onChange={(e) =>
                handleJobPostDataChange("RequiredMinExperience", e.target.value)
              }
              label="Min Experience"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Yr(s)</InputAdornment>
                ),
                className: "input_Custom_Style",
              }}
              error={
                !!minExperienceError ||
                (invalidFields.RequiredMinExperience &&
                  !jobPostData.RequiredMinExperience)
              }
              helperText={
                minExperienceError ||
                (invalidFields.RequiredMinExperience ? "* mandatory" : "")
              }
            />
          </div>
        </div>

        {/* Experience_Req Max */}
        <div className="companyProflieGroup">
          <div className="item1">
            <TextField
              fullWidth
              type="number"
              value={jobPostData.RequiredMaxExperience || ""}
              onChange={(e) =>
                handleJobPostDataChange("RequiredMaxExperience", e.target.value)
              }
              label="Max Experience"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Yr(s)</InputAdornment>
                ),
                className: "input_Custom_Style",
              }}
              error={
                !!maxExperienceError ||
                (invalidFields.RequiredMaxExperience &&
                  !jobPostData.RequiredMaxExperience)
              }
              helperText={
                maxExperienceError
                  ? maxExperienceError
                  : invalidFields.RequiredMaxExperience &&
                    !jobPostData.RequiredMaxExperience
                  ? "* mandatory"
                  : ""
              }
            />
          </div>
        </div>
      </div>

      {/* ____________________ Education Required UG & PG _________________ */}
      <div className="cpLogo">
        <CustomMultiSelect
          placeholder="Type to Select Education Required"
          chipClassName="jobPostLocationtag"
          chipDeleteClassName="jobPostLocationDeleteX"
          inputPropStyleClass="input_Custom_Style"
          // isMandatoryError={true}
          isMandatoryError={invalidFields.RequiredEducation}
          helperText="* mandatory"
          options={educationRequiredOptions}
          selectedOption={jobPostData.RequiredEducation || ""}
          setSelectedOption={(value) =>
            setJobPostData({
              ...jobPostData,
              RequiredEducation: value,
            })
          }
        />
        {/* ksld-{invalidFields.RequiredEducation} */}
      </div>

      {/* ____________________ Job Location _______________________ */}
      <div className="cpLogo">
        <CustomMultiSelect
          placeholder="Type to Select Job Location(s)"
          chipClassName="jobPostLocationtag"
          chipDeleteClassName="jobPostLocationDeleteX"
          inputPropStyleClass="input_Custom_Style"
          // isMandatoryError={true}
          isMandatoryError={invalidFields.JobLocations}
          helperText="* mandatory"
          options={locationsPreferred}
          selectedOption={jobPostData.JobLocations || ""}
          setSelectedOption={(value) =>
            setJobPostData({
              ...jobPostData,
              JobLocations: value,
            })
          }
        />
      </div>

      {/* ____________________ Key Skills _______________________ */}
      <div className="cpLogo">
        <CustomMultiSelect
          placeholder="Type to Select Key Skills"
          chipClassName="jobPostLocationtag"
          chipDeleteClassName="jobPostLocationDeleteX"
          options={requiredKeySkillsOptions}
          isMandatoryError={invalidFields.RequiredKeyskills}
          helperText="* mandatory"
          // inputPropStyleClass="input_Custom_Style"
          selectedOption={jobPostData.RequiredKeyskills || ""}
          setSelectedOption={(value) =>
            setJobPostData({
              ...jobPostData,
              RequiredKeyskills: value,
            })
          }
        />
      </div>

      {/* _______________ Roles & Responsibilities ~ Editor ______________ */}
      <h2 className="job_heading">Roles and Responsibilities</h2>
      <Editor1
        isHeadingRequird={false}
        desktopHeight="250px"
        mobileHeight="250px"
        content={roles_Responsibilities_NormalContent}
        setContent={setRoles_Responsibilities_NormalContent}
        output_content={roles_Responsibilities_HtmlContent}
        setOutputContent={setRoles_Responsibilities_HtmlContent}
        // setHtmlContent={setRoles_Responsibilities_HtmlContent}
        contentPlacholder={"Roles and Responsibilities . . ."}
        is_Editor_HeaderRequired={true}
        // editor_HeaderName={"Roles and Responsibilities "}
        // for tag as text
        is_tag_required={false}
        is_tag_Option_required={false}
        return_location="/dashboard"
        editID={"editID"}
        Button_Name={null} //default button
      />
      {/* {roles_Responsibilities_HtmlContent} */}

      {/* _______________ Job Description ~ Editor ______________ */}
      <h2 className="job_heading">Job Description</h2>
      <Editor1
        isHeadingRequird={false}
        desktopHeight="250px"
        mobileHeight="250px"
        content={JobDescription_NormalContent}
        setContent={setJob_Description_NormalContent}
        output_content={JobDescription_HtmlContent}
        setOutputContent={setJob_Description_HtmlContent}
        // htmlContentProp={JobDescription_HtmlContent}
        contentPlacholder={"Job Descriptions . . ."}
        contentUniqueID={"jobpost12"}
        is_Editor_HeaderRequired={true}
        // editor_HeaderName={"Job Description "}
        // for tag as text
        is_tag_required={false}
        is_tag_Option_required={false}
        return_location="/dashboard"
        editID={"editID"}
        Button_Name={null} //default button
      />
      {/* {JobDescription_NormalContent} */}

      {/* _______________ Perks ~ Editor ______________ */}
      <h2 className="job_heading">Perks</h2>
      <Editor1
        isHeadingRequird={false}
        desktopHeight="250px"
        mobileHeight="250px"
        content={perks_NormalContent}
        setContent={setPerks_NormalContent}
        output_content={perks_HtmlContent}
        setOutputContent={setPerks_HtmlContent}
        // htmlContentProp={perks_HtmlContent}
        contentPlacholder={"Perks . . ."}
        contentUniqueID={"jobPerks12"}
        is_Editor_HeaderRequired={true}
        // editor_HeaderName={"Perks "}
        // for tag as text
        is_tag_required={false}
        is_tag_Option_required={false}
        return_location="/dashboard"
        editID={"editID"}
        Button_Name={null} //default button
      />
    </div>
  );
}

export default AddJobPostPage;
