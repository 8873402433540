import React, { useEffect, useState } from "react";
import AdminSideNav from "./1AdminSideNav";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import PageLoading from "../PageLoading/PageLoading";
import './Dashboard.css'

function AdminDashboard() {
  const [isLoading, setIsLoading] = useState(true);

  //--------------------------------------------------------------------------------
  //Fetch the usertable data and validate the usertype,blocked or unblocked
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const checkUserLogin = async () => {
      const HTES_user_email = localStorage.getItem("HTES_user_id");
      try {
        const response = await axios.get(`${baseURL}/getLoginTable`);
        const userExists = response.data.some(
          (user) => user.email === HTES_user_email
        );
        const userblocked = response.data.find(
          (user) => user.email === HTES_user_email
        );

        if (userExists === false) {
          window.location.href = "/login";
        } else if (userblocked.isBlocked === 1) {
          customAlert(
            "",
            "User Blocked please contact the Admistration",
            "warning"
          );
          setTimeout(() => {
            window.location.href = "/login";
          }, 4000);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error checking user:", error);
        window.location.href = "/login";
      }
    };

    checkUserLogin();
  }, []);

  //--------------------------------------------------------------------------------
  //Render loading indicator until authentication check is complete
  //--------------------------------------------------------------------------------
  if (isLoading) {
    return (
      <div className="adminDashboardloadingcontainer">
        <PageLoading image="HTES_LOGO.png" />
      </div>
    );
  }

  return (
    <>
      <AdminSideNav />
    </>
  );
}

export default AdminDashboard;
