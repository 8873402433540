import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  IconButton,
  Tooltip,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddIcon from "@mui/icons-material/Add";
import "./coursecreatetemplate.css";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";

const FAQSectionTab = ({selectedCategories,setSelectedCategories,selectedFAQs, setSelectedFAQs,title }) => {
  const [expanded, setExpanded] = useState([]); // State to track expanded items
  const [categories, setCategories] = useState([]);
  
  const [allFAQs, setAllFAQs] = useState([]);
  // const [selectedFAQs, setSelectedFAQs] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const autocompleteRef = useRef(null);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  console.log(categories.length, selectedCategories.length);

  useEffect(() => {
    // Fetch all FAQ data
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/FAQ_fetch_all`);
        const data = response.data.filter(
          (item) => parseInt(item.IsApproved) === 1
        );
        setAllFAQs(data);

        // Extract unique categories
        const uniqueCategories = [
          ...new Set(data.flatMap((item) => JSON.parse(item.Category))),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    };

    fetchData();
  }, []);

  // Toggle expand/collapse
  const handleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      if (newExpanded.includes(index)) {
        newExpanded.splice(newExpanded.indexOf(index), 1);
      } else {
        newExpanded.push(index);
      }
      return newExpanded;
    });
  };

  // Handle category selection
  const handleCategorySelect = (event, newValue) => {
    if (newValue && !selectedCategories.includes(newValue)) {
      setSelectedCategories([...selectedCategories, newValue]);
      setIsAdding(false); // Hide autocomplete
    } else {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== newValue)
      );
    }
  };

  // Remove selected category chip
  const handleRemoveCategory = (category) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
  };

  // Render all FAQs for selected categories
  useEffect(() => {
    const selectedFAQs = allFAQs.filter((faq) => {
      const categories = JSON.parse(faq.Category);
      return selectedCategories.some((cat) => categories.includes(cat));
    });
    setSelectedFAQs(selectedFAQs);
  }, [selectedCategories, allFAQs]);

  const toggleAutocomplete = () => {
    if (categories.length !== selectedCategories.length) {
      setIsAdding(!isAdding);
      setAutocompleteValue("");
    } else {
      customAlert("", "No caterogies available", "warning");
    }
  };



  return (
    <>
      {/* Heading and Buttons */}
      <div className="Header-tab-course-name">
        <div className="instructortab-flex-container">
          <div className="autocomplete-container-width">
            <div className="overview-header-of-the-container-color">
              {selectedCategories.length === 0 && "Add FAQ"}
            </div>

            {isAdding ? (
              <Autocomplete
                options={categories.filter(
                  (cat) => !selectedCategories.includes(cat)
                )}
                value={autocompleteValue || ""}
                onChange={(event, newValue) => {
                  setAutocompleteValue(newValue);
                  handleCategorySelect(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select or Search Category"
                    variant="outlined"
                    fullWidth
                    ref={autocompleteRef}
                  />
                )}
              />
            ) : (
              selectedCategories.map((category, index) => (
                <Chip
                  key={index}
                  className="chip-background-color"
                  label={category}
                  onDelete={() => handleRemoveCategory(category)}
                  style={{ margin: "5px" }}
                />
              ))
            )}
          </div>
          {/* Render selected categories as chips */}

          <div>
            {!isAdding && (
              <Tooltip title="Add Category" arrow>
                <IconButton onClick={toggleAutocomplete}>
                  <AddIcon className="buttoncolorEdit" variant="contained" />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {/* Render Autocomplete or Add button based on isAdding state */}
        </div>
      </div>

      {/* Render selected FAQs for all selected categories */}
      {selectedFAQs.map((selectedFAQ, faqIndex) => (
        <div className={`FAQ-header-content-holds-container ${expanded.includes(faqIndex) ? 'active' : ''}`} key={faqIndex}>

          <div
            className="FAQ-content-header-title"
            onClick={() => handleExpand(faqIndex)}
          >
            <div className="FAQ-content-dropdown-and-header">
            <h6 className="FAQ-content-header">{selectedFAQ.Question}</h6>
              <IconButton>
                {expanded.includes(faqIndex) ? (
                  <ExpandLessIcon className="expand-icon-color" />
                ) : (
                  <ExpandMoreIcon className="expand-icon-color" />
                )}
              </IconButton>
              
            </div>
          </div>
          {expanded.includes(faqIndex) && (
            <div className="FAQ-content-points-descriptions">
              <ul>
                {selectedFAQ.Answer.split("\n").map((point, pointIndex) => (
                  <li key={pointIndex}>{point}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default FAQSectionTab;
