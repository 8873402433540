import React, { useState, useEffect } from 'react'
import { baseURL } from "../../http";
import axios from "axios";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from 'react-router-dom';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

function HtesCareer() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchCareerData = async () => {
      try {
        const response = await axios.get(`${baseURL}/careers_getall`);
        const data = response.data;

        // Assuming data contains blog fields
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));

        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Use index as unique ID for table row
          ...row,
        }));
        setRenderRows(rows);
        setDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchCareerData();
  }, [dataBaseUpdate]);

  const handleEdit = (rowdata) => {
    // setEditData(rowdata);
    // setOpenEdit(true);
    console.log("handle Edit functions", rowdata);
  };

  const handleView = (row) => {
    // Construct the full URL to view the resume
    const resumeViewUrl = `${baseURL}/career_view/${row.id}`;
    
    // Open the resume in a new tab
    window.open(resumeViewUrl, '_blank');
  };

const handleDownload = (row) => {
  const resumeDownloadUrl = `${baseURL}/career_download/${row.id}`;
  window.location.href = resumeDownloadUrl;
};

  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View", //Name of the MenuItem
      onClick: handleView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },

    {
      icon: DownloadForOfflineIcon, //icon Name (import from the Mui)
      label: "download", //Name of the MenuItem
      onClick: handleDownload, //Handle the Function(for working ),
      IconColor: "green", //Color of the Icon
    },
  ];

  return (
    <div>
      <TableHeaderTemplate
        columns={renderColumns} //Columns render
        rows={renderRows} //rows render
        handleEdit={handleEdit}
        customMenuItems={customMenuItems}
        deleteApi="careers_delete" //Delete Api
        tableNameForExport='Careers'//optionl
        is_Manage_Button_Required={true}//Mange button view if addition Items add
        Manage_button_name='Careers'
        SetDataBaseUpdate={setDataBaseUpdate}
        DefaultColumnNames={["Name", "Email", "Phone", "Resume","Services", "Message"]} // Add to show the columns in a default render
      />
    </div>
  )
}

export default HtesCareer;
