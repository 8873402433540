import React, { useState, useEffect } from "react";
import ResultCard from "./ResultCard";
import ResultSideNav from "./ResultSideNav";
import { Avatar, IconButton, Modal, Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

function ResultLandingPage({ filteredQuestions, ResultData }) {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //--------------------------------------------------------------------------------
  //Handle avatar click
  //--------------------------------------------------------------------------------
  const handleAvatarClick = (index) => {
    setSelectedQuestionIndex(index);
  };

  //--------------------------------------------------------------------------------
  //Useeffect to Resize based on the mobile view
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth <= 800;
      setIsMobile(mobileView);
      setIsOpen(!mobileView); // Close the sidebar if in mobile view
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //--------------------------------------------------------------------------------
  //Toggle side bar Question view
  //--------------------------------------------------------------------------------
  const toggleSidebar = () => {
    setIsOpen(false);
  };

  const toggleSidebarclose = () => {
    setIsOpen(true);
  };
  return (
    <>
      <div className="main-container-all-component-render-result-card">
        {/* Left container, dynamically adjusts between 70% and 100% width */}

        <div
          className={`left-container-result-card ${
            isOpen ? "expanded" : "full-width"
          }`}
        >
          <div className="Avatar-and-Button-conatiner-in-question-view-container-result-card"></div>

          <div
            className={`selected-question-view-container-result-card ${
              isOpen ? "expanded" : "full-width"
            }`}
          >
            <ResultCard
              filteredQuestions={filteredQuestions}
              ResultData={ResultData}
              selectedQuestionIndex={selectedQuestionIndex}
            />
          </div>
        </div>

        {/* Right 30% container, can slide in/out */}
        {!isMobile && isOpen && (
          <div
            className={`right-container-result-card ${
              isOpen ? "open" : "closed"
            }`}
          >
            <Tooltip title="Close the Exams Data" arrow>
              <IconButton
                className="toggle-button-result-card"
                onClick={toggleSidebar}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Tooltip>
            <div className="container-for-filter-search-all-component-render">
              <ResultSideNav
                setSelectedQuestionIndex={setSelectedQuestionIndex}
                onAvatarClick={handleAvatarClick}
                filteredQuestions={filteredQuestions}
                ResultData={ResultData}
                selectedQuestionIndex={selectedQuestionIndex}
              />
            </div>
          </div>
        )}

        {!isMobile && !isOpen && (
          <span>
            <Tooltip title="Open the Exams Data" arrow>
              <IconButton
                className="toggle-button closed"
                onClick={toggleSidebarclose}
              >
                <ArrowBackIosIcon className="close-the-container-arrow-alignment" />
              </IconButton>
            </Tooltip>
          </span>
        )}

        {isMobile && (
          <Tooltip
            title={isModalOpen ? "Close Exams Data" : "Open Exams Data"}
            arrow
          >
            <IconButton
              className="toggle-button-mobile-view"
              onClick={() => setIsModalOpen(true)}
            >
              <ArrowBackIosIcon className="close-the-container-arrow-alignment" />
            </IconButton>
          </Tooltip>
        )}

        {/* Modal for mobile view */}
        {isMobile && (
          <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div className="modal-container-result-card">
              <Tooltip title="Close the Exams Data" arrow>
                <IconButton
                  className="toggle-button-modal"
                  onClick={() => setIsModalOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <ResultSideNav
                setSelectedQuestionIndex={setSelectedQuestionIndex}
                onAvatarClick={handleAvatarClick}
                filteredQuestions={filteredQuestions}
                ResultData={ResultData}
                selectedQuestionIndex={selectedQuestionIndex}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default ResultLandingPage;
