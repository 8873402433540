// import React from 'react';
// import { Paper, Container, Typography } from '@mui/material';
// import "./TestSeries.css";
// import Grid from '@mui/system/Unstable_Grid/Grid';

// function FAQPage({ FAQData }) {

//   if (!FAQData || FAQData.length === 0) {
//     return <div>No FAQ data available</div>;
//   }
//   return (

//     <Grid className="faqContainer">
//       <Paper elevation={3} className="faqOuterPaper">
//       {FAQData.map((faq, index) => (
//         <Paper key={index} elevation={3} className="vertical-paper">
//           {Object.entries(faq).map(([key, value]) => (
//             <React.Fragment key={key}>
//               <Typography variant="h6">{key}</Typography>
//               <Typography>{value}</Typography>
//             </React.Fragment>
//           ))}
//         </Paper>
//       ))}
//       </Paper>
//     </Grid>
//   )
// }

// export default FAQPage

// import React, { useState } from 'react';
// import { Paper, Container, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import "./TestSeries.css";
// import Grid from '@mui/system/Unstable_Grid/Grid';

// function FAQPage({ FAQData }) {
//   const [expanded, setExpanded] = useState(null);

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : null);
//   };

//   if (!FAQData || FAQData.length === 0) {
//     return <div>No FAQ data available</div>;
//   }

//   return (
//     <Container className="center-container">
//       <Paper elevation={3} className="faqOuterPaper">
//         <Grid container spacing={2}>
//           <Grid item xs={6}>
//             {/* Left side: Paper element */}
//             <Paper elevation={3} className="vertical-paper">
//               <Typography variant="h6">Questions</Typography>
//               {FAQData.map((faq, index) => (
//                 <Typography key={index} onClick={() => handleAccordionChange(index)} className="question">
//                   {faq.title}
//                 </Typography>
//               ))}
//             </Paper>
//           </Grid>
//           <Grid item xs={6}>
//             {/* Right side: Accordion with answers */}
//             <Accordion expanded={expanded !== null} className="accordion">
//               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
//                 <Typography className="heading">Answer</Typography>
//               </AccordionSummary>
//               <AccordionDetails>
//                 <Typography>
//                   {expanded !== null && FAQData[expanded]?.content}
//                 </Typography>
//               </AccordionDetails>
//             </Accordion>
//           </Grid>
//         </Grid>
//       </Paper>
//     </Container>
//   );
// }

// export default FAQPage;

// import React, { useState } from 'react';
// import { Paper, Container, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import "./TestSeries.css";
// import Grid from '@mui/system/Unstable_Grid/Grid';

// function FAQPage({ FAQData }) {
//   const [expanded, setExpanded] = useState(null);

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : null);
//   };

//   if (!FAQData || FAQData.length === 0) {
//     return <div>No FAQ data available</div>;
//   }

//   return (
//     <Container className="center-container">
//       <Paper elevation={3} className="faqOuterPaper">
//         <Grid container spacing={2}>
//           <Grid item xs={6}>
//             {/* Left side: Paper element with questions */}
//             <Paper elevation={3} className="vertical-paper">
//               <Typography variant="h6">Questions</Typography>
//               {FAQData.map((faq, index) => (
//                 <Typography key={index} onClick={() => handleAccordionChange(index)} className="question">
//                   {faq.title}
//                 </Typography>
//               ))}
//             </Paper>
//           </Grid>
//           <Grid item xs={6}>
//             {/* Right side: Accordion with answers */}
//             <Accordion expanded={expanded !== null} className="accordion">
//               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
//                 <Typography className="heading">Answer</Typography>
//               </AccordionSummary>
//               <AccordionDetails>
//                 <Typography>
//                   {expanded !== null && FAQData[expanded]?.content}
//                 </Typography>
//               </AccordionDetails>
//             </Accordion>
//           </Grid>
//         </Grid>
//       </Paper>
//     </Container>
//   );
// }

// export default FAQPage;

import React, { useState } from "react";
import {
  Paper,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./TestSeries.css";
import Grid from "@mui/system/Unstable_Grid/Grid";

function FAQPage({ FAQData }) {
  const [expanded, setExpanded] = useState(null);

  const handleAccordionChange = (index) => () => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    // <Container className="faqContainer" >
    //   {FAQData.map((faq, index) => (
    //     <Paper key={index} elevation={3} className="faqOuterPaper">
    //       <Grid container spacing={2}>
    //         <Grid item xs={6}>
    //           {/* Left side: Paper element with questions */}
    //           <Paper elevation={3} className="vertical-paper" onClick={handleAccordionChange(index)}>
    //             <Typography variant="h6">{faq.title}</Typography>
    //             <Typography>{faq.content}</Typography>
    //           </Paper>
    //         </Grid>
    //       <Grid item xs={6}>
    //         {/* Right side: Accordion with answers */}
    //         <Accordion expanded={expanded !== null} className="accordion">
    //           <AccordionSummary
    //             expandIcon={<ExpandMoreIcon />}
    //             aria-controls="panel1bh-content"
    //             id="panel1bh-header"
    //           >
    //             <Typography className="heading">Answer</Typography>
    //           </AccordionSummary>
    //           <AccordionDetails>
    //             <Typography>
    //               {expanded !== null && FAQData[expanded]?.content}
    //             </Typography>
    //           </AccordionDetails>
    //         </Accordion>
    //       </Grid>
    //     </Grid>
    //   </Paper>
    //   ))}
    // </Container>
    <Container className="faqContainer" >
  <Paper elevation={3} className="faqOuterPaper">
  <Grid container spacing={2} justifyContent="center">
      {FAQData.map((faq, index) => (
        <Grid item xs={3} key={index}>
          {/* Left side: Paper element with questions */}
          <Paper elevation={5} className="vertical-paper" onClick={handleAccordionChange(index)}>
            <Typography variant="h6">{faq.title}</Typography>
            <Typography>{faq.content}</Typography>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={9}>
        {/* Right side: Accordion with answers */}
        <Accordion expanded={expanded !== null} className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="heading">Answer</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {expanded !== null && FAQData[expanded]?.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  </Paper>
</Container>

  );
}

export default FAQPage;
