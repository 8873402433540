import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow,  MDBRadio } from "mdb-react-ui-kit";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import { TextField, Button, Autocomplete } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";
import { locationsPreferred } from "./PmCommonFunction";



function Stscroll11CareerProfileModal({ togglingModal, fetchData, callPercentage, buttonName }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [currentIndustry, setCurrentIndustry] = useState("");
  const [department, setDepartment] = useState("");
  const [roleCategory, setRoleCategory] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [desiredJobType, setDesiredJobType] = useState("");
  const [desiredEmploymentType, setDesiredEmploymentType] = useState("");
  const [preferredShift, setPreferredShift] = useState("");
  const [preferredWorkLocation, setPreferredWorkLocation] = useState([]);
  const [invalidFields, setInvalidFields] = useState({});


  //--------------------------------------------------------------------------------
  // Dropdown Options
  //--------------------------------------------------------------------------------

  const industryOptions = [
    "Information Technology (IT)",
    "Business Process Outsourcing (BPO)",
    "Metal",
    "Healthcare",
    "Finance",
    "Education",
    "Automotive",
    "Construction",
    "Retail",
    "Telecommunications",
    "Hospitality",
    "Manufacturing",
    "Energy",
    "Pharmaceutical",
    "Aerospace",
    // Add more options as needed
  ];
  const departmentOptions = [
    "Information Technology (IT)",
    "Business Process Outsourcing (BPO)",
    "Metal",
    "Healthcare",
    "Finance",
    "Education",
    "Automotive",
    "Construction",
    "Retail",
    "Telecommunications",
    "Hospitality",
    "Manufacturing",
    "Energy",
    "Pharmaceutical",
    "Aerospace",
    // Add more options as needed
  ];
  const roleCategoryOptions = [
    "Information Technology (IT)",
    "Business Process Outsourcing (BPO)",
    "Metal",
    "Healthcare",
    "Finance",
    "Education",
    "Automotive",
    "Construction",
    "Retail",
    "Telecommunications",
    "Hospitality",
    "Manufacturing",
    "Energy",
    "Pharmaceutical",
    "Aerospace",
    // Add more options as needed
  ];

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${baseURL}/getCareerProfile/${userEmail}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCurrentIndustry(data.currentIndustry || "");
          setDepartment(data.department || "");
          setRoleCategory(data.roleCategory || "");
          setJobRole(data.jobRole || "");
          
          setDesiredJobType(data.desiredJobType || "");
          setDesiredEmploymentType(data.desiredEmploymentType || "");
          setPreferredShift(data.preferredShift || "");
          // setPreferredWorkLocation(data.preferredWorkLocation || "");

            // Parse preferredWorkLocation if it's a JSON string
        const parsedPreferredWorkLocation = data.preferredWorkLocation
        ? JSON.parse(data.preferredWorkLocation)
        : [];

      setPreferredWorkLocation(parsedPreferredWorkLocation);

        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, []);

  //--------------------------------------------------------------------------------
  // Sending the Data TO API
  //--------------------------------------------------------------------------------
  async function saveProfileData() {
    try {
      const sendData = {
        email: userEmail, // going

        currentIndustry: currentIndustry,
        department     : department,
        roleCategory   : roleCategory,
        jobRole        : jobRole,
        desiredJobType : desiredJobType,
        desiredEmploymentType: desiredEmploymentType,
        preferredShift: preferredShift,
        preferredWorkLocation:preferredWorkLocation,
      };

      // Define required fields for validation
      const requiredFields = [
        'currentIndustry',
        'department',
        'roleCategory',
        'jobRole',
        'desiredJobType',
        'desiredEmploymentType',
      ];
    
      // Validate required fields
      const newInvalidFields = {};
      for (const field of requiredFields) {
        if (!sendData[field]) {
          newInvalidFields[field] = true;
        }
      }
      
      if (Object.keys(newInvalidFields).length > 0) {
        setInvalidFields(newInvalidFields);
        return; 
      }
      setInvalidFields({});

      const response = await fetch(`${baseURL}/updateCareerProfile`, {
        method: "POST",
        body: JSON.stringify(sendData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        console.log(" Career Profile updated success");
        customAlert("", "Item Updated", "success").then(() => {
          togglingModal();
          fetchData();
          callPercentage();
        });
      } else {
        console.error("Error updating Career Profile:", response.statusText);
      }
    } catch (error) {
      console.error("Error while enter savi button:", error);
    }
  }

  return (
    <div>
      <div className="pmModalContainer">
        <h5 className="pmTabHeadings">Career Profile</h5> <br />
        {/* ___________________________________ Tab Heading _______________________________ */}
        {/* ___________________________________  Career Profile ____________________________ */}
        <div >
          <p className="pmModParaText">
            It is the first thing recruiters notice in your profile. Write
            concisely what makes you unique and the right person for the job you
            are looking for.
          </p>
          <br />

          {/* ___________________________________  Current Industry ____________________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel">Current Industry</label>
            <Autocomplete
              fullWidth
              options={industryOptions}
              getOptionLabel={(option) => option}
              value={currentIndustry}
              onChange={(event, newValue) => setCurrentIndustry(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={currentIndustry || ""}
                  onChange={(e) => setCurrentIndustry(e.target.value)}
                  error={invalidFields.currentIndustry}
                  helperText={invalidFields.currentIndustry ? "* mandatory" : ""}
                  
                />
              )}
            />
          </MDBRow>
          {/* ___________________________________  Department ____________________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel">Department</label>
            <Autocomplete
              fullWidth
              options={departmentOptions}
              getOptionLabel={(option) => option}
              value={department}
              onChange={(event, newValue) => setDepartment(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={department || ""}
                  onChange={(e) => setDepartment(e.target.value)}
                  error={invalidFields.department}
                  helperText={invalidFields.department ? "* mandatory" : ""}
                  
                />
              )}
            />
          </MDBRow>

          {/* ___________________________________  Role Category ____________________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel">Role Category</label>
            <Autocomplete
              fullWidth
              options={roleCategoryOptions}
              getOptionLabel={(option) => option}
              value={roleCategory}
              onChange={(event, newValue) => setRoleCategory(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={roleCategory || ""}
                  onChange={(e) => setRoleCategory(e.target.value)}
                  error={invalidFields.roleCategory}
                  helperText={invalidFields.roleCategory ? "* mandatory" : ""}
                />
              )}
            />
          </MDBRow>
          {/* ___________________________________  Job Role ____________________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel">Job Role</label>
            <Autocomplete
              fullWidth
              options={roleCategoryOptions}
              getOptionLabel={(option) => option}
              value={jobRole}
              onChange={(event, newValue) => setJobRole(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={jobRole || ""}
                  onChange={(e) => setJobRole(e.target.value)}
                  error={invalidFields.jobRole}
                  helperText={invalidFields.jobRole ? "* mandatory" : ""}
                 />
              )}
            />
          </MDBRow>

          {/* ___________________________________  Desired Job Type (Radio) ____________________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel">Desired Job Type</label>
            <MDBCol className="pmChkBoxes">
              <Checkbox
                checked={desiredJobType=='Permanent'}
                onChange={(e) => setDesiredJobType('Permanent')}
                inputProps={{ "aria-label": "controlled" }}
              /> 
              <span>Permanent</span>
            </MDBCol>
            <MDBCol className="pmChkBoxes">
              <Checkbox
                checked={desiredJobType=='Contractual'}
                onChange={(e) => setDesiredJobType('Contractual')}
                inputProps={{ "aria-label": "controlled" }}
              /> 
              <span>Contractual</span>
            </MDBCol>
          </MDBRow>

          {/* ___________________________________  Desired Emp Type (all select check) ____________________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel">Desired Employment Type</label>
            <MDBCol className="pmChkBoxes">
              <Checkbox
                checked={desiredEmploymentType=='FullTime'}
                onChange={(e) => setDesiredEmploymentType('FullTime')}
                inputProps={{ "aria-label": "controlled" }}
              /> 
              <span>Full-Time</span>
            </MDBCol>
            <MDBCol className="pmChkBoxes">
              <Checkbox
                checked={desiredEmploymentType=='PartTime'}
                onChange={(e) => setDesiredEmploymentType('PartTime')}
                inputProps={{ "aria-label": "controlled" }}
              /> 
              <span>Part-Time</span>
            </MDBCol>
          </MDBRow>

          {/* ___________________________________  Desired Preferred shift (Radio) _____________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel">Desired Preferred shift</label>
            
            <MDBCol  className="pmRadioModal pmChkBoxes">
             <MDBRadio
              name="cc" id="pref_Shift1"  value="Day" label="Day" inline  
              checked={preferredShift== "Day"}
              onChange={() => setPreferredShift('Day')}
            />
            </MDBCol>

            <MDBCol className="pmChkBoxes">
            <MDBRadio 
              name="cc"
              id="pref_Shift2"
              value="Night"
              label="Night"
              inline
              checked={preferredShift== "Night"}
              onChange={() => setPreferredShift('Night')}
            />
            </MDBCol>

            <MDBCol>
            <MDBRadio 
              name="cc"
              id="pref_Shift3"
              value="Flexible"
              label="Flexible"
              inline
              checked={preferredShift== "Flexible"}
              onChange={() => setPreferredShift('Flexible')}
            />
            </MDBCol>
          </MDBRow>

          {/* ___________________________________  Preferred Location __________________________ */}
          <MDBRow className="pmmodElement">
            <label className="pmLabel"> Preferred Location</label>
            <MDBCol>

            

                 {/*  ____________________  Template call  _______________ */}
                <CustomMultiSelect
                options={locationsPreferred}
                selectedOption={preferredWorkLocation}
                setSelectedOption={setPreferredWorkLocation}
                chipClassName="jobPostLocationtag"
                chipDeleteClassName="jobPostLocationDeleteX"
                placeholder="Type to Select Location(s)"
                />
            </MDBCol>
          </MDBRow> 

        </div>
      </div>

      {/* _____________________________     Save  &  Cancel   _______________________ */}
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={saveProfileData}
        >
          <SaveIcon className="buttonicons" />
          &nbsp;{buttonName}
        </Button>
      </div>
    </div>
  );
}

export default Stscroll11CareerProfileModal;
