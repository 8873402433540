import React, { useState } from 'react';
import { Box, CardContent, Avatar, IconButton, Menu, MenuItem, Paper, Tooltip, Drawer, Button, TextField, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Question_Editor from './QABlog_editor';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';

export function ReplyCard({

    //____________Required props__________________//

    reply,
    onEdit,
    onDelete,
    hasLiked,
    hasDisliked,
    handleLike,
    handleDislike,
    likes,
    dislikes
}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        onEdit(reply);
        handleMenuClose();
    };

    const handleDelete = () => {
        onDelete(reply);
        handleMenuClose();
    };

    // Create a method to safely render HTML content
    const createMarkup = (html) => {
        return { __html: html };
    };

    return (
        <Paper elevation={3} className='reply-box'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <CardContent>
                <Box className="avatar-container">
                    <Avatar className="avatar-margin">{reply.user.charAt(0)}</Avatar>
                    <Box>
                        <Typography className="reply-user" variant="body1">{reply.user}</Typography>
                    </Box>


                    {/*__________3-dots for editing and deleting the comment card_____________*/}

                    <IconButton onClick={handleMenuOpen} style={{ marginLeft: 'auto' }} className='more-vert-icon'>
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                width: '12ch',
                            },
                        }}
                    >
                        <MenuItem onClick={handleEdit} className="menu-item">
                            <EditIcon className="menu-item-icon-edit" />
                            Edit
                        </MenuItem>
                        <MenuItem onClick={handleDelete} className="menu-item">
                            <DeleteIcon className="menu-item-icon-delete" />
                            Delete
                        </MenuItem>
                    </Menu>
                </Box>
                <hr className="Question_FooterLine" />


                {/* ____________________Like and dislike icon with comment______________________ */}

                <Typography variant="subtitle1" className="content-typography" dangerouslySetInnerHTML={createMarkup(reply.normalContent)} />
                <Box className="like-dislike-container">
                    <Box className="like-dislike">
                        <IconButton color={hasLiked ? 'primary' : 'default'} onClick={handleLike}>
                            <ThumbUpIcon />
                        </IconButton>
                        <Typography variant="body2">{likes}</Typography>
                    </Box>
                    <Box className="like-dislike">
                        <IconButton color={hasDisliked ? 'error' : 'default'} onClick={handleDislike}>
                            <ThumbDownIcon />
                        </IconButton>
                        <Typography variant="body2">{dislikes}</Typography>
                    </Box>
                    {/* {isHovered && (
                        <Tooltip title="Share" arrow>
                            <Box className="share-icon">
                                <IconButton>
                                    <ShareIcon />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    )} */}
                </Box>
            </CardContent>
        </Paper>
    );
}

// ____________________Editor________________________//

export function ReplyDrawer({

    //____________Required props__________________//

    isOpen,
    onClose,
    onSubmit,
    replyText,
    setReplyText,
    editMode,
    questionId,
    replyId
}) {

    const handleContentChange = ({ heading, content, tags }) => {
        setReplyText(content);
    };

    const handleSave = () => {
        onSubmit();
    };

    return (
        <Drawer anchor='bottom' open={isOpen} onClose={onClose}>
            <Box className='reply-drawer-container'>
                <Question_Editor
                    isHeadingRequird={false}
                    is_tag_required={false}
                    onContentChange={handleContentChange}
                    onSave={handleSave}
                    apiType="reply"
                    editMode={editMode}
                    questionId={questionId}
                    replyId={replyId}
                    content={replyText}
                />
            </Box>
        </Drawer>
    );
}