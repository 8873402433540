import React  from "react";
import { MDBCol, MDBRow, MDBRadio } from "mdb-react-ui-kit";
import { TextField, Button, IconButton, Autocomplete  } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { universityOptions,
   educationSpecializationOptions,
   educationCourseOptions,
   educationOptions
   } from "./PmCommonFunction";
import UpdateIcon from '@mui/icons-material/Update';

function Stscroll6EducationAddModal({ togglingModal, fetchEducList ,
  selectedEducation,
  setSelectedEducation,
  University,
  setUniversity,
  selectedCourse,
  setSelectedCourse,
  selectedOptionSpecialization,
  setSelectedOptionSpecialization,
  courseType,
  setCourseType,
  selectedCourseStartYear,
  setSelectedCourseStartYear,
  selectedCourseEndYear,
  setSelectedCourseEndYear,
  editItemID,
  handleItemDelete,
save,
mode,
invalidFields,


}) {

  return (
    <div className="profileModal">
        {/* ___________________________________ Tab Heading _______________________________ */}
      <h5 className="pmTabHeadings">{mode} Education</h5>
      <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={togglingModal}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>

          {(mode=='Edit') &&
            <Tooltip title="Delete">
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() => handleItemDelete(editItemID)}
                  style={{position:'absolute', top:20, right:30}}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            }

<div className="pmModalContainer">
  
      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" className="pmLabel">Education</label>
        <br></br>
    {/* ___________________________________ Select Education ____________________________ */}
        <MDBCol>
  
                <Autocomplete  // correct
              fullWidth
              options={educationOptions}
              getOptionLabel={(option) => option}
              value={selectedEducation}
              onChange={(event, newValue) => setSelectedEducation(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={University || ""}
                  onChange={(e) => setSelectedEducation(e.target.value)}
                  error={invalidFields.education}
                  helperText={invalidFields.education ? "* mandatory" : ""}
                />
              )}
            />
        </MDBCol>
      </MDBRow>
      <br></br>
    {/* ___________________________________ Type University ____________________________ */}
      <MDBRow>
        <label htmlFor="exampleFormControlSelect2" className="pmLabel">University/Institute</label>
        <br></br>
        <MDBCol>
           <Autocomplete  // correct
              fullWidth
              options={universityOptions}
              getOptionLabel={(option) => option}
              value={University}
              onChange={(event, newValue) => setUniversity(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={University || ""}
                  onChange={(e) => setUniversity(e.target.value)}
                  error={invalidFields.university}
                  helperText={invalidFields.university ? "* mandatory" : ""}
                />
              )}
            />

        </MDBCol>
      </MDBRow>

      <br></br>
    {/* ___________________________________ Select Course ____________________________ */}
      <MDBRow>
        <label className="pmLabel">Course</label>
        {/* <br></br> */}
          <MDBCol>
                  <Autocomplete
              fullWidth
              options={educationCourseOptions}
              getOptionLabel={(option) => option}
              value={selectedCourse}
              onChange={(event, newValue) => setSelectedCourse(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={selectedCourse || ""}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  error={invalidFields.course}
                  helperText={invalidFields.course ? "* mandatory" : ""}
                />
              )}
            />
        </MDBCol>
      </MDBRow>
      <br></br>
    {/* ___________________________________ Select Specialization ____________________________ */}
      <MDBRow>
        <label  className="pmLabel">Specialization </label>
        <br></br>
          <MDBCol>
          
                  <Autocomplete
                fullWidth
                options={educationSpecializationOptions}
                getOptionLabel={(option) => option}
                value={selectedOptionSpecialization}
                onChange={(event, newValue) => setSelectedOptionSpecialization(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    value={selectedOptionSpecialization || ""}
                    onChange={(e) => setSelectedOptionSpecialization(e.target.value)}
                    error={invalidFields.specialization}
                    helperText={invalidFields.specialization ? "* mandatory" : ""}
                />
              )}
            />
        </MDBCol>

        
      </MDBRow>
      <br></br>
    {/* ___________________________________ Radio  Course Type ____________________________ */}
      <MDBRow>
        {/* <label className="pmLabel">Course Type</label> */}
        <label className={`pmLabel ${invalidFields.courseType ? 'error_Pmlabel' : ''}`}>Course Type</label>
        <br></br>

        <MDBCol>
          <MDBRadio
            name="courseType"
            id="inlineRadio1"
            value="Full-Time"
            label="Full-Time"
            inline
            checked={courseType === "Full-Time"}
            onChange={()=> setCourseType("Full-Time")}
          />
          <MDBRadio
            name="courseType"
            id="inlineRadio2"
            value="Part-Time"
            label="Part-Time"
            inline
            checked={courseType === "Part-Time"}
            onChange={()=> setCourseType("Part-Time")}
          />
          <MDBRadio
            name="courseType"
            id="inlineRadio3"
            value="Correspondance/Distance"
            label="Correspondance/Distance"
            inline
            checked={courseType === "Correspondance/Distance"}
            onChange={()=> setCourseType("Correspondance/Distance")}
          />
        </MDBCol>
      </MDBRow>
      <br></br>
    {/* ___________________________________ Select Course Duration  ______________________ */}
      <MDBRow>
        <label className="pmLabel">Course Duration</label>
        <br></br>
        <MDBRow>
          <MDBCol>
              <label className="pmLabel">Start</label>
              <TextField
              required
              type="date"
              fullWidth
              value={selectedCourseStartYear}
              onChange={(e) =>setSelectedCourseStartYear(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { 
                  style: { textTransform: "uppercase" },
                  max: new Date().toISOString().split('T')[0]
                 }, // Ensure the start date cannot be after the end date
              }}
              error={invalidFields.courseStart}
                helperText={invalidFields.courseStart ? "* mandatory" : ""}
            />
          </MDBCol>
          <MDBCol>
           
                <label className="pmLabel">End</label>
              <TextField
              required
              type="date"
              fullWidth
              value={selectedCourseEndYear}
              onChange={(e) =>setSelectedCourseEndYear(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { 
                  style: { textTransform: "uppercase" },
                  min: selectedCourseStartYear }, // Ensure the start date cannot be after the end date
              }}
              error={invalidFields.courseEnd}
                helperText={invalidFields.courseEnd ? "* mandatory" : ""}
            />
          </MDBCol>
        </MDBRow>
        <br></br>
      </MDBRow>
      <br></br>
</div>
    {/* ___________________________________ Save & Cancel buttons  ______________________ */}
     
     
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={save}
        >
          {mode=='Edit' ? (<><UpdateIcon className="buttonicons" /> Update</>
          ) : (
              <><SaveIcon className="buttonicons" /> Save</>
          )}
        </Button>
      </div>
    </div>
  );
}

export default Stscroll6EducationAddModal;
