import React, { useState, useEffect } from "react";
import McqQuestionCard from "./McqQuestionCard";
import FillintheblanksandQAcard from "./FillintheblanksandQAcard";
import axios from "axios";
import { baseURL } from "../../http";
import QuestionBankModel from "./QuestionBankModel";
import { customAlert } from "../SweetAlertCommon/Custom";
import CircularProgress from "@mui/material/LinearProgress";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import DropDown from "../DropDownWithProps/DropDown";

function McqQuestionGenerator({ aiResponse, questionType, Difficulty, Marks }) {
  // Initialize state for dummy questions
  const [dummyQuestions, setDummyQuestions] = useState([]);
  const [GenerateText, setGenerateText] = useState([]);
  const [loading, setLoading] = useState(false);
  //console.log(dummyQuestions)
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "English",
    code: "en",
  });

  //-------------------------------------------------------------------------
  // Translate the Tex of the Questions
  //-------------------------------------------------------------------------
  const translateText = async (text, targetLanguage) => {
    try {
      const response = await axios.post(baseURL + "/lang", {
        text,
        targetLanguage,
      });
      return response.data.translated_text;
    } catch (error) {
      console.error("Error translating text:", error);
      return null;
    }
  };

  //-------------------------------------------------------------------------
  // Function to translate dummy questions to selected language
  //-------------------------------------------------------------------------

  const translateDummyQuestions = async () => {
    try {
      setLoading(true); // Set loading to true before starting translations
      const translatedQuestions = await Promise.all(
        GenerateText.map(async (question) => {
          const translatedQuestion = { ...question };
          translatedQuestion.question = await translateText(
            question.question,
            selectedLanguage.code
          );
          translatedQuestion.options = await Promise.all(
            question.options.map((option) =>
              translateText(option, selectedLanguage.code)
            )
          );
          translatedQuestion.answer = await translateText(
            question.answer,
            selectedLanguage.code
          ); // Translate answer as well

          // Update progress
          return translatedQuestion;
        })
      );
      setDummyQuestions(translatedQuestions);
    } catch (error) {
      console.error("Error translating questions:", error);
    } finally {
      setLoading(false); // Set loading to false after translations are done
    }
  };

  //-------------------------------------------------------------------------
  // useeffect to check the AiResponse
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (aiResponse) {
      const parsedResponse = JSON.parse(aiResponse);
      setDummyQuestions(parsedResponse);
      setGenerateText(parsedResponse);
    }
  }, [aiResponse]);

  //-------------------------------------------------------------------------
  // used to translate tht question based on the selected question changes
  //-------------------------------------------------------------------------
  useEffect(() => {
    translateDummyQuestions();
  }, [selectedLanguage]); // Re-translate when selected language changes

  //-------------------------------------------------------------------------
  // Define a function to handle exporting the data to the database
  //-------------------------------------------------------------------------

  const handleExportData = async (formData) => {
    try {
      // Construct the payload to send to the backend
      const payload = {
        questions: dummyQuestions,
        data: formData,
        questionType: questionType,
        Difficulty_Level: Difficulty,
        LanguageOfQuestion: selectedLanguage.name,
        Marks: Marks,
      };
      console.log(payload);

      // Send the payload to the backend endpoint
      const response = await axios.post(
        baseURL + "/questiontodatabase",
        payload
      );

      // Handle successful response from the backend
      customAlert("", "Data Saved in the Database Successfully", "success");

      // If needed, provide feedback to the user or update the UI
    } catch (error) {
      // Handle error
      console.error("Error saving data:", error);
      customAlert(
        "",
        "Duplicate Questions place check Generate the new question and send to Database",
        "warning"
      );
    }
  };

  //-------------------------------------------------------------------------
  // Clear dummyQuestions state when questionType changes
  //-------------------------------------------------------------------------

  useEffect(() => {
    setDummyQuestions([]);
  }, [questionType]);

  //-------------------------------------------------------------------------
  // Parse the AI response and update dummyQuestions state
  //-------------------------------------------------------------------------

  useEffect(() => {
    if (aiResponse !== undefined && aiResponse.trim() !== "") {
      try {
        const parsedResponse = JSON.parse(aiResponse);
        parsedResponse.forEach((question) => {
          question.options = Array.isArray(question.options)
            ? question.options
            : [];
        });
        setDummyQuestions(parsedResponse);
      } catch (error) {
        console.error("Error parsing AI response:", error);
      }
    }
  }, [aiResponse]);

  const [showAnswers, setShowAnswers] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  //-------------------------------------------------------------------------
  //Check box for show answer
  //-------------------------------------------------------------------------
  const toggleShowAnswers = () => {
    setShowAnswers(!showAnswers);
  };

  //-------------------------------------------------------------------------
  //Delete the question
  //-------------------------------------------------------------------------
  const handleDelete = (questionToDelete) => {
    setDummyQuestions(
      dummyQuestions.filter((question) => question.id !== questionToDelete.id)
    );
  };

  //-------------------------------------------------------------------------
  //save the question
  //-------------------------------------------------------------------------
  const handleSave = (updatedQuestion) => {
    updatedQuestion.options = Array.isArray(updatedQuestion.options)
      ? updatedQuestion.options
      : [];

    const updatedQuestions = dummyQuestions.map((question) =>
      question.id === updatedQuestion.id ? updatedQuestion : question
    );
    setDummyQuestions(updatedQuestions);

    console.log(`updated: ${dummyQuestions}`);
    setIsEditing(false);
  };

  return (
    <div className="McqsquestionGeneratortopbar">
      <div className="McqsquestionGeneratorshowanswerandtotalquestions">
        <div
          className="McqsquestionGeneratorshowcheckBoxandshowanswer"
          style={{ color: showAnswers ? "green" : "#777" }}
        >
          <label>
            <input
              type="checkbox"
              checked={showAnswers}
              onChange={toggleShowAnswers}
              className="checkboxalignment"
            />
            <span>&nbsp;Show Answer</span>
          </label>
        </div>
        <div className="totalquestions">
          Total Questions: {dummyQuestions.length}
        </div>
        <div className="transationandicon">
          <GTranslateIcon style={{ height: "35px" }} />
          &nbsp;
          {/* <ComboBox selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} /> */}
          <DropDown
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
          &nbsp;
        </div>
        <div className="exportbutton">
          <QuestionBankModel
            handleExportData={handleExportData}
            questions={dummyQuestions}
          />
        </div>
      </div>
      <hr></hr>

      {loading && <CircularProgress />}
      {dummyQuestions.length === 0 ? (
        <div>Please generate the questions</div>
      ) : (
        <div className="mcqGeneratorcontainer">
          {dummyQuestions.map((question) => {
            if (questionType === "MCQs" || questionType === "True/False") {
              return (
                <McqQuestionCard
                  key={question.id}
                  questionData={question}
                  showAnswers={showAnswers}
                  isEditing={isEditing}
                  onDelete={handleDelete}
                  onSave={handleSave}
                />
              );
            } else if (
              questionType === "Fill in the Blanks" ||
              questionType === "Question and Answer"
            ) {
              return (
                <FillintheblanksandQAcard
                  key={question.id}
                  questionData={question}
                  isEditing={isEditing}
                  onDelete={handleDelete}
                  onSave={handleSave}
                />
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default McqQuestionGenerator;
