import React, { useState, useEffect } from "react";

import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { IconButton} from "@mui/material";
import { baseURL } from "../../http";
import Stscroll7ITSkillsAddModal from "./PmTab7ITSkillsAddModal";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { customAlert } from "../SweetAlertCommon/Custom";

function Stscroll7ITSkills({callPercentage}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [itSkillsList, setITSkillsList] = useState([]); // from DB
  const [modalStatus, setModalStatus] = useState(false);
  const [currentYear, setCurrentYear] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  const [itData, setITData] = useState({
    id: "",
    skills: "",
    version: "",
    lastUsed: "",
    itExpYear: "",
    itExpMonth: "",
  });

  //--------------------------------------------------------------------------------
  //  Modal toggles
  //--------------------------------------------------------------------------------
  const toggleModal = (ID) => {
    setModalStatus(true);
    if (ID != 0) {
      const selectedItem = itSkillsList.find((item) => item.id === ID);
      setITData({
        id: ID,
        skills: selectedItem.skills || "",
        version: selectedItem.version || "",
        lastUsed: selectedItem.lastUsed || "",
        itExpYear: selectedItem.itExpYear || "",
        itExpMonth: selectedItem.itExpMonth || "",
      });
    } else {
      setITData({
        id: 0,
        skills: "",
        version: "",
        lastUsed: "",
        itExpYear: "",
        itExpMonth: "",
      });
    }
  };

  //--------------------------------------------------------------------------------
  // Fetching the Student All IT Skills LIst
  //--------------------------------------------------------------------------------
  const fetchITSkillsList = () => {
    const requestBody = {
      email: userEmail,
    };


    fetch(`${baseURL}/getITSkills`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setITSkillsList(data || []);
      })
      .catch((error) => {
        console.error("Error fetching IT skills:", error);
      });
  };

  useEffect(() => {
    fetchITSkillsList();
  }, []);

  //--------------------------------------------------------------------------------
  // API - Delete IT skill Item
  //--------------------------------------------------------------------------------
  const handleItemDelete = (itemId) => {
    const updatedITSkillList = itSkillsList.filter(
      (item) => item.id !== itemId
    );
    setITSkillsList(updatedITSkillList);

    fetch(`${baseURL}/deleteITSkills/${itemId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          customAlert("", "Item Deleted", "success")
          setModalStatus(false);
          fetchITSkillsList();
          callPercentage();
        } else {
          console.error("Failed to delete item");
        }
      })
      .catch((error) => {
        console.error("Item deleting user:", error);
      });
  };



  //--------------------------------------------------------------------------------
  // Function - Get Current Year
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // Update the date every second
    setCurrentYear(currentDate.getFullYear());
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  return (
    <div>
      <MDBRow>
        <MDBCol>
          <h5 className="pmTabHeadings"> IT Skills </h5>
        </MDBCol>

        <MDBCol >
          <h5
            onClick={() => toggleModal(0)}
           className="pmAddEmpBtn"
          >
            Add IT Skills
          </h5>
        </MDBCol>
      </MDBRow>

      {/* ___________________________________ Data Render _______________________________ */}
    <div className="itTableContainer">
      {/* ___________________________________ Tab Heading _______________________________ */}

      <MDBTable>
        {/* ___________________________________ IT Table Heading ___________________________ */}
        <MDBTableHead className="bg-gradient">
          <tr>
            <th className="itTableHeader"  > Skills</th>
            <th className="itTableHeader"  > Version</th>
            <th className="itTableHeader"  > Last Used</th>
            <th className="itTableHeader"  > Experience</th>
            <th className="itTableHeader"  > Action</th>
          </tr>
        </MDBTableHead>
        {/* ___________________________________ IT Table Render Map ________________________ */}
        <MDBTableBody className="itTableBody">
          {itSkillsList.map((table) => (
            <tr key={table.id}>
              <td className="itTableContent">{table.skills}</td>
              <td className="itTableContent">{table.version}</td>
              <td className="itTableContent">{table.lastUsed}</td>
              <td className="itTableContent">
              {table.itExpYear} {table.itExpYear > 1 ? 'Years' : 'Year'} &nbsp;
  {table.itExpMonth} {table.itExpMonth > 1 ? 'Months' : 'Month'}
              </td>
              <td className="itTableContent">
                <div>
                  <Tooltip title="Edit" arrow>
                    <IconButton onClick={() => toggleModal(table.id)}>
                      <EditIcon className="pmEditIcon" />
                    </IconButton>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>


      {/* ___________________________________ Add_Modal / Edit Modal code starts____________________________ */}

      <Modal
        open={modalStatus}
        onClose={setModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          
          {/* _____________________________ Heading    _______________________ */}
        <div className="pmModHeader2">
        <h5 className="pmTabHeadings">IT Skills</h5> <br />
        
        {(itData.id !=0)&&
        <Tooltip title="Delete">
            <IconButton variant="contained" color="error" 
            onClick={()=>handleItemDelete(itData.id)}
            >
              <DeleteIcon  />
            </IconButton>
          </Tooltip> 
                  }
        </div>

          <IconButton
            id="button-zoom"
            className="modal-close-iconButton-right-top-corner"
            onClick={() => setModalStatus(false)}
          >
            <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
          </IconButton>


          <Stscroll7ITSkillsAddModal
            togglingModal={toggleModal}
            itData={itData}
            fetchData={fetchITSkillsList}
            setITData={setITData}
            handleItemDelete={handleItemDelete}
            setModalStatus={setModalStatus}
            callPercentage={callPercentage}
          />
        </div>
      </Modal>
      {/* ___________________________________ Add_Modal / Edit Modal code Ends____________________________ */}
    </div>
  );
}

export default Stscroll7ITSkills;
