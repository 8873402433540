import React from 'react'
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from '@mui/icons-material/Update';
import { TextField, Grid, Button, IconButton, Autocomplete } from "@mui/material";



function EmployeeDataEdit({open,handleClose,editData,SetDataBaseUpdate}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="employeedataeditmodal">
        <IconButton
          id="button-zoom"
          className="employeemodalcancel"
          onClick={handleClose}
        >
          <CloseIcon className="employeemodalcancelX" />
        </IconButton>
        
       

        </div>
        </Modal>
  )
}

export default EmployeeDataEdit