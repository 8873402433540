import React, { useState, useEffect } from 'react';
import HT from "../static/HT_Large_round.png";
import { baseURL } from '../../http';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

function DynamicImageLogo({ height, width }) {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(images?.length > 0 ? images[0] : HT);

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (images?.length > 0) {
      setCurrentImage(images[0]);
      changeFavicon(`${removeLastThreeChars(baseURL)}${images[0].LogoPath}`);
    }
  }, [images]);

  const fetchImages = async () => {
    try {
      const response = await axios.get(baseURL + '/getimages');
      setImages(response.data.images);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const removeLastThreeChars = (str) => {
    return str.slice(0, -3);
  };

  const changeFavicon = (iconPath) => {
    const favicon = document.querySelector("link[rel='icon']");
    favicon.href = iconPath;
  };

  return (
    <div>
      {isLoading && <CircularProgress color='success' size={22}/>}
      {!isLoading && (
        <div style={{ maxHeight: height, maxWidth: width }}>
          {typeof currentImage === 'object' && currentImage.LogoPath ? (
            <img src={`${removeLastThreeChars(baseURL)}${currentImage.LogoPath}`} alt="HT Logo" style={{ maxHeight: '100%', maxWidth: '100%',objectFit:'cover' }} />
          ) : (
            <img src={currentImage} alt="HT Logo" style={{ maxHeight: '100%', maxWidth: '100%' ,objectFit:'fill'}}  />
          )}
        </div>
      )}
    </div>
  );
}

export default DynamicImageLogo;
