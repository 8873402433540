import React, { useState, useEffect } from "react";
import { TextField, Modal, Button, MenuItem, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./holidays.css";
import SaveIcon from "@mui/icons-material/Save";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../http";
import UpdateIcon from "@mui/icons-material/Update";
import { getCurrentDateTime, getUtcTime } from "../Common/Utilities_Function";

const months = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const nationalHolidays = [
  { value: "newYear", label: "New Year's Day" },
  { value: "independenceDay", label: "Independence Day" },
  { value: "republicDay", label: "Republic Day" },
  { value: "diwali", label: "Diwali" },
  { value: "eid", label: "Eid" },
  { value: "christmas", label: "Christmas" },
];

function AddEditHolidays({ open, handleClose, editData, SetDataBaseUpdate }) {
  const [formData, setFormData] = useState({
    Year: "",
    Month: "",
  });

  const [selectedOption, setSelectedOption] = useState([]);
  const [errors, setErrors] = useState({
    Year: false,
    Events: false,
  });

  //--------------------------------------------------------------------------------
  //used to empty the all the Data when modal close
  //--------------------------------------------------------------------------------
  useEffect(() => {
    setFormData({
      Year: "",
      Month: "",
    });
    setSelectedOption([]);
  }, [handleClose]);

  //--------------------------------------------------------------------------------
  // Handle input changes
  //--------------------------------------------------------------------------------

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  //--------------------------------------------------------------------------------
  //Pre-fill the data when i open the modal for edit
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      setFormData({
        Year: editData.Year || "",
        Month: editData.Month || "",
      });
      // Parse and transform Events to display labels
      const events = JSON.parse(editData.Events || "[]");
      const transformedOptions = events.map((event) => ({
        value: event,
        label: event, // Assuming the JSON string contains ["label1", "label2"]
      }));
      setSelectedOption(transformedOptions);
    }
  }, [editData]);

  //--------------------------------------------------------------------------------
  // Validation logic
  //--------------------------------------------------------------------------------

  const validateForm = () => {
    const yearValid =
      formData.Year.length === 4 && /^\d{4}$/.test(formData.Year);
    const eventsValid = selectedOption.length > 0;

    setErrors({
      Year: !yearValid,
      Events: !eventsValid,
    });

    return yearValid && eventsValid;
  };

  //--------------------------------------------------------------------------------
  // Handle form submission
  //--------------------------------------------------------------------------------
  const handleSubmit = async () => {
    if (validateForm()) {
      const holidayData = {
        Year: formData.Year,
        Month: formData.Month,
        Events: selectedOption.map((option) => option.label), // This is an array; convert it before sending
        NoOfHolidays: selectedOption.length, // Calculate the number of holidays
        Created_by: localStorage.getItem("HTES_user_id"), // Example, replace with actual user
        Modified_by: localStorage.getItem("HTES_user_id"), // Example, replace with actual user
        UTC_Time: getUtcTime(), // Get current UTC time
        Created_date: getCurrentDateTime(), // Creation date
        Modified_date: getCurrentDateTime(), // Modification date
      };

      try {
        let response;
        if (editData && editData.id) {
          // If editData exists, use PUT for update
          response = await axios.put(
            baseURL + `/Employees_Holidays_update/${editData.id}`,
            {
              ...holidayData,
              Events: JSON.stringify(holidayData.Events), // Convert Events array to JSON string
            }
          );
        } else {
          // Otherwise, create a new entry with POST
          response = await axios.post(baseURL + "/Employee_Holidays", {
            ...holidayData,
            Events: JSON.stringify(holidayData.Events), // Convert Events array to JSON string
          });
        }

        if (response.status === 200) {
          customAlert("", "Employee Holidays saved successfully", "success");
          setFormData({
            Year: "",
            Month: "",
          });
          setSelectedOption([]);
          SetDataBaseUpdate(true);
          handleClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          customAlert(
            "",
            "A holiday record for this Month and Year already exists.",
            "warning"
          );
        } else {
          customAlert(
            "",
            "Failed to save holidays. Please try again.",
            "error"
          );
        }
      }
    } else {
      if (!selectedOption.length) {
        customAlert("", "Please select at least one event.", "warning");
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="holiday-add-edit-modal-conatainer">
        <IconButton
          id="button-zoom"
          className="course-name-template-close-button"
          onClick={handleClose}
        >
          <CloseIcon className="course-name-template-close-button-x" />
        </IconButton>
        <div className="Holiday-modal-heading">
          {editData && editData.id ? "Update Holidays" : "Add Holidays"}
        </div>
        <div className="holidays-modal-text-fields-grid-container">
          <div>
            <TextField
              label="Year"
              type="number"
              value={formData.Year}
              onChange={(e) => handleChange("Year", e.target.value)}
              fullWidth
              inputProps={{
                maxLength: 4,
              }}
              error={errors.Year}
              helperText={
                errors.Year ? "Please enter a valid 4-digit year" : ""
              }
              placeholder="Enter 4-digit year"
            />
          </div>
          <div>
            <TextField
              select
              label="Month"
              value={formData.Month}
              onChange={(e) => handleChange("Month", e.target.value)}
              fullWidth
            >
              {months.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div>
            <CustomMultiSelect
              options={nationalHolidays}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              label=""
              placeholder="Add The Events"
              error={errors.Events}
              helperText={
                errors.Events ? "Please select at least one event." : ""
              }
            />
          </div>
        </div>

        <div className="addHolidays-button-alignment">
          <Button
            onClick={handleSubmit}
            id="button-zoom"
            variant="contained"
            color="success"
          >
            {editData && editData.id ? (
              <UpdateIcon className="buttonicons" />
            ) : (
              <SaveIcon className="buttonicons" />
            )}
            {editData && editData.id ? <>&nbsp;Update</> : <>&nbsp;Save</>}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AddEditHolidays;
