import React, { useEffect } from "react";
import SASideNav from "./1SASideNav";
import SAHeader from "./SAHeader";

function SuperAdminDashboard() {
  useEffect(() => {
    document.title = "SuperAdmin-Dashboard";
  });
  return (
    <div>
      <SAHeader />
      <SASideNav />
    </div>
  );
}

export default SuperAdminDashboard;
