import React, { useState } from "react";
import { Edit } from "@mui/icons-material";
import ClassIcon from "@mui/icons-material/Class";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import DomainIcon from "@mui/icons-material/Domain";
import CategoryIcon from "@mui/icons-material/Category";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { IconButton, Tooltip } from "@mui/material";
import AddGeneralInfo from "./AddGeneralInfo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Generalinformationsmalldiv({
  testData,
  setTestData,
  setdataGridVisible,
  setResetForm,
  setEditTestData,
  setSelectedPattern,
}) {
  const [open, setOpen] = useState(false);

  //--------------------------------------------------------------------------------
  // Update the local state with the edited data
  //--------------------------------------------------------------------------------

  const handleUpdateTestData = (updatedData) => {
    setTestData(updatedData);
  };

  //--------------------------------------------------------------------------------
  // Back To Home
  //--------------------------------------------------------------------------------
  const handleClickBackArrow = () => {
    // setTestData(null)
    setEditTestData();
    setResetForm((prev) => !prev);
    setSelectedPattern();
    setdataGridVisible(false);
  };

  //--------------------------------------------------------------------------------
  //Open and close the edit the enaral inforamtion
  //--------------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="createtestgeneralinfoemarionsmalldiv">
      <div className="edit-icon-alignemnt">
        <Tooltip title="Back to Home" arrow>
          <IconButton onClick={handleClickBackArrow}>
            <ArrowBackIcon className="edit-icon-color-and-fontsize-generalinfo" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="createtestgeneralinfoemarionsmalldiv1">
        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            <ClassIcon className="general-info-small-div-icon-size-alignment" />
            TestName:&nbsp;
            <span className="info-dispaly-font-color">
              {testData?.testName}
            </span>
          </div>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            <TimelapseIcon className="general-info-small-div-icon-size-alignment" />
            Duration:&nbsp;
            <span className="info-dispaly-font-color">
              {testData?.testDuration}Mins
            </span>
          </div>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            <DomainIcon className="general-info-small-div-icon-size-alignment" />
            ExamPattern:&nbsp;
            <span className="info-dispaly-font-color">
              {testData?.examPattern}
            </span>
          </div>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            <CategoryIcon className="general-info-small-div-icon-size-alignment" />
            Category:&nbsp;
            <span className="info-dispaly-font-color">
              {testData?.category}
            </span>
          </div>
        </div>

        <div className="fontsizeandweight">
          <div className="icon-and-name-alignment-generalinfo">
            <QuestionMarkIcon className="general-info-small-div-icon-size-alignment" />
            No.OfQuestions:&nbsp;
            <span className="info-dispaly-font-color">
              {testData?.noOfQuestions}
            </span>
          </div>
        </div>
      </div>
      <div className="edit-icon-alignemnt">
        <Tooltip title="Edit General Info." arrow>
          <IconButton onClick={handleOpen}>
            <Edit className="edit-icon-color-and-fontsize-generalinfo" />
          </IconButton>
        </Tooltip>
      </div>

      {/* ___________Edit general info_______________ */}
      <AddGeneralInfo
        Open={open}
        handleClose={handleClose}
        editData={testData}
        setEditData={handleUpdateTestData}
      />
    </div>
  );
}

export default Generalinformationsmalldiv;
