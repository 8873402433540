import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import axios from "axios";
import { baseURL } from "../../http";
import CA_SideNav_CalenderRender from './CA_SideNav_CalenderRender';
import { useNavigate } from 'react-router-dom';
import { customAlert } from '../SweetAlertCommon/Custom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; // Import html2canvas library
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CardViewerTemplate from './CardViewerTemplate';

function CA_Viewer({ selectedDate, onDateSelect, searchTerm }) {
  //-------------------------------------------------------------------------
  //State Handles
  //-------------------------------------------------------------------------
  const [renderContent, setRenderContent] = useState(['Loading']);
  const [filteredContent, setFilteredContent] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentContentId, setCurrentContentId] = useState(null);
  const [editedDateId, setEditedDateId] = useState(null);
  const [editedDate, setEditedDate] = useState('');

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (event, newOpen) => () => {
    setDrawerOpen(true);
  };

  //-------------------------------------------------------------------------
  // Drawer render for mobile View
  //-------------------------------------------------------------------------
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" className='CA_Drawer'>    {/**onClick={()=>setDrawerOpen(false)} */}
      <h4 onClick={()=>setDrawerOpen(false)}
     className='CA_Drawer_Header'
      >Select Date </h4>
      <br/>
      <CA_SideNav_CalenderRender onDateSelect={onDateSelect}  />
      <h4 className='CA_Drawer_finish'  ></h4>
    </Box>
  );

 //-------------------------------------------------------------------------
  //Fetching Data from DB
  //-------------------------------------------------------------------------
  useEffect(() => { fetchData();  }, [selectedDate]);

  const fetchData = async () => {
    // alert(selectedDate)
    try {
    const[year, month, day]=selectedDate.split('-');
    console.log('selected Date='+selectedDate+'-('+year+'-'+month+'-'+day+')')

      const response = await axios.get(`${baseURL}/getCurrentAffairs`);

      // const filteredContent = response.data.filter((content) => content.date === selectedDate);

      let filteredContent;

      if ((year!='yy') && (month!='mm') && (day!='dd')) {
        filteredContent = response.data.filter(content => content.date == selectedDate);
      } 
      else if (year && (month!='mm') && (day=='dd')) {
        filteredContent = response.data.filter(content => content.date.startsWith(`${year}-${month}-`));
      } 
      
      else if (year && (month=='mm')&& (day=='dd')) {
        // Format: YYYY-MM-DD
        filteredContent = response.data.filter(content => content.date.startsWith(`${year}-`));
      } else {
        filteredContent = [];
      }
      
      setRenderContent(filteredContent);

    } catch (error) {
      console.error('Error fetching Contents data:', error);
    }
    setDrawerOpen(false);
  };

  //-------------------------------------------------------------------------
  // Filter content based on searchTerm
  //-------------------------------------------------------------------------
  useEffect(() => {
    try {
      const filtered = renderContent.filter(content => {
        const headingMatch = content.heading && content.heading.toLowerCase().includes(searchTerm.toLowerCase());
        const normalContentMatch = content.normalContent && content.normalContent.toLowerCase().includes(searchTerm.toLowerCase());
        return headingMatch || normalContentMatch;
      });
      setFilteredContent(filtered);
    } catch (error) {
      setFilteredContent(renderContent);
      console.error('Error filtering content:', error);
    }
  }, [searchTerm, renderContent]);
  
  
  //-------------------------------------------------------------------------
  // API to delete Item
  //-------------------------------------------------------------------------
  const handleDelete = (index, id) => {
    const updatedContents = [...renderContent];
    updatedContents.splice(index, 1);
    setRenderContent(updatedContents);
    
    fetch(`${baseURL}/deleteCurrentAffairs/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.ok) {
        fetchData();
        customAlert("",'Content Deleted', "success");
      } else {
        console.error("Failed to delete item");
        alert('falied-id='+id)
      }
    })
    .catch((error) => {
      console.error("Item deleting user:", error);
    });
  }
  //-------------------------------------------------------------------------
  // Handle 3 dots to Edit and Delete Options open and close list
  //-------------------------------------------------------------------------
  const handle3DotClick = (event, contentId) => {
    setAnchorEl(event.currentTarget);
    setCurrentContentId(contentId);
  };

  const handle3DotClose = () => {
    setAnchorEl(null);
  };


  const navigateTo = useNavigate();
  const handleEditClick = (
    editID, 
    imageDataURL, imageWidth, imageHeight, 
    editHeading, editHtmlContent, editNormalContent,
    editTag, editLanguage
  ) => {
    navigateTo('/CA_Editor', {
      state: {
        editID,
        imageDataURL,
        imageWidth,
        imageHeight,

        editHeading,
        editHtmlContent,
        editNormalContent,
        editTag,

        editLanguage,
        // editedDateId,
        // editedDate
      }
    });
    // console.log(editID,editTag,editHtmlContent);
  };
  const handleDateEditClick = (contentId, date) => {
    setEditedDateId(contentId);
    setEditedDate(date);
  };

  //-------------------------------------------------------------------------
  //Date Change Save function at Card
  //-------------------------------------------------------------------------
  const handleDateSaveClick = (contentId) => {
    const updatedContents = renderContent.map(content => {
      if (content.id === contentId) {
        return { ...content, date: editedDate };
      }
      return content;
    });
    setRenderContent(updatedContents);

    try {
      const sendData = {
        editID:editedDateId,
        date: editedDate,
      };
      const response =  axios.post(`${baseURL}/updateCurrentAffairsDate`, sendData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        fetchData();
        customAlert("", "Date Changed", "success");
      } 
      else {  console.error("Error Changing Date"); }
        
    } 
    catch (error) {
      console.error("Network Error while Changing Date:", error);
    }

    setEditedDateId(null);
  };

  const handleDateChange = (e) => {
    setEditedDate(e.target.value);
    fetchData();
  };


  
  return (
    <>
      <div className='CA_Content_Viewer'>
        <div className='hamMenu'>
            <h3>
              <span className="hamburger-menu" onClick={toggleDrawer(true)}>
                &nbsp;  <i class="fas fa-bars" ></i> </span>
            </h3>
          
        </div>
{/* <h4>{searchTerm}</h4> */}
        {selectedDate && (
          <>
            {/* _________________________Card Template Start + with Prop components_______ */}
            <div className='CA_RenderCards'>
              {/* {renderContent.map((content, index) => ( */}
               {filteredContent.map((content, index) => (
                <CardViewerTemplate
                  content={content}
                  editedDateId={editedDateId}
                  editedDate={editedDate}
                  id={content.id}
                  index={index}
                  handleDateChange={handleDateChange}
                  handleDateSaveClick={handleDateSaveClick}
                  handleDateEditClick={handleDateEditClick}
                  handle3DotClick={handle3DotClick}
                  handle3DotClose={handle3DotClose}
                  anchorEl={anchorEl}
                  currentContentId={currentContentId}
                  handleDelete={handleDelete}
                  handleEditClick={handleEditClick}
                  showActionButtons={true}                //  Makes true for Admin users   
                  />
              ))}
            </div>
            {/* _________________________Card Template End  ______________________________*/}
          </>
        )}
        <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          {DrawerList}
        </Drawer>
      </div>
    </>
  );
}

export default CA_Viewer;

