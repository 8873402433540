import React, {useState} from 'react'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";

function ContentViewerTemplate({content,index, handleEditClick,handleMoveUp,handleMoveDown,
                            handleDelete,renderContent,topicSelectedFromSideNav, 
                            showActionButtons='false'}) {

          const [anchorEl, setAnchorEl] = useState(null);
          const[currentContentId, setCurrentContentId]=useState(null);
          const handle3DotClose = () => {
            setAnchorEl(null);
          };
          const handle3DotClick = (event, contentId) => {
            setAnchorEl(event.currentTarget);
            setCurrentContentId(contentId);
          };
  return ( 
    <>
       <div className='renderContainer'>
                <h3 className='inner_content'>{content.heading}</h3> 

                {showActionButtons &&
                <>
                <div>
                   <MoreVertIcon className="CA_batchmoreiconcolor dots3" 
                   id="button-zoom" onClick={(event) => handle3DotClick(event, content.id)}
                   />
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && currentContentId === content.id)}
                        onClose={handle3DotClose}
                      >
                        <MenuItem onClick={() => handleEditClick(
                          content.id,   content.heading, content.htmlContent, content.normalContent,
                          topicSelectedFromSideNav)}>
                            <EditIcon className="buttoniconsContent " id="batchcardediticon" />
                          <span className="menuitemscolor Content_MenuItems">
                           Edit</span>
                        </MenuItem>

                        <MenuItem onClick={() => handleDelete(index, content.id)} >
                            <DeleteIcon   
                            className='icon-tr '  disabled={index === 0}/>
                            <span className="menuitemscolor CA_MenuItems">
                           Delete</span>
                        </MenuItem>

                        <MenuItem onClick={() => handleMoveUp(content.position)} >
                            <ArrowDropUpIcon   
                             disabled={index === 0}/>
                            <span className="menuitemscolor Content_MenuItems">
                            Move Up</span>
                        </MenuItem>

                        <MenuItem onClick={() => handleMoveDown(content.position)} >
                            <ArrowDropDownIcon   
                             disabled={index === 0}/>
                            <span className="menuitemscolor Content_MenuItems">
                            Move Down</span>
                        </MenuItem>
                        </Menu>
                  
              
                </div>
               
                </>
            }

              </div>
              <div dangerouslySetInnerHTML={{ __html: content.htmlContent }} className='inner_content'/>
              <br/>
              <div className='CA_RenderCard_Footer'>
                    <div className='' style={{width:'100%'}}>
                        <h6 style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                        <div>
                            <span className='CA_RC_footerKey'>Created By <span className='colan1'>:</span></span>
                            <span className='CA_RC_footerValue'>{content.createdByWho || 'NA'}</span>
                        </div>
                        <div>
                            <span className='CA_RC_footerKey'>Modified By <span className='colan2'>:</span></span>
                            <span className='CA_RC_footerValue'>{content.ModifiedByWho || 'NA'}</span>
                        </div>
                        <div>
                            <span className='CA_RC_footerKey'>Last Modified <span className='colan3'>:</span></span>
                            <span className='CA_RC_footerValue'>
                            {content.updated_at ? content.updated_at.slice(0, 10) : 'NA'}
                            </span>
                        </div>
                        </h6>
                    </div>
                    {/* <div className='CA_RC_right'>
                        <h6 className=''>
                        <span className='CA_RC_footerKey'>Tag:</span>
                        <span className='CA_RC_footerValue'>{content.tag || 'NA'}</span>
                        </h6>
                    </div> */}
                </div>
              <hr/>
             
    </>
  )
}

export default ContentViewerTemplate
