import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../../http";
import "./TPAPreviousQuestion.css";
import {
  TextField,
  MenuItem,
  Autocomplete,
  IconButton,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

function AddQuestionPaperModal({ showModal, setShowModal, initialFormData,setInitialFormData }) {
  const [examNames, setExamNames] = useState([]);
  const [formData, setFormData] = useState({
    tpaexam_id: "",
    question_paper_year: "",
    access_type: "Free",
    question_paper_name: "",
    number_of_questions: "",
    question_paper_marks: "",
    exam_duration: "",
    available_languages: [], // Initial empty array
    question_paper_pdf: null,
    created_by: "",
    amount: "",
  });
  const [languageInput, setLanguageInput] = useState("");
  const [fileName, setFileName] = useState("");

  // Determine the mode (Add/Edit) based on the presence of initialFormData
  const isEditMode = initialFormData !== null && initialFormData !== undefined;
  console.log(isEditMode)
  useEffect(() => {
    console.log("examnames",typeof examNames)
    if (isEditMode && initialFormData && examNames.length > 0) {
      // Match the exam based on `tpaexam_id`
      const matchedExam = examNames.find(
        (exam) => exam.id === String(initialFormData.tpaexam_id) // Convert to string for comparison
      );
      

  
      console.log("Initial Data:", initialFormData);
      console.log("Exam Names:", examNames);
      console.log("Matched Exam:", matchedExam);
  
      setFormData({
        ...initialFormData,
        tpaexam_id: matchedExam ? matchedExam.id : "",
        exam_name: matchedExam ? matchedExam.exam_name : "",
        available_languages: Array.isArray(initialFormData.available_languages)
          ? initialFormData.available_languages
          : JSON.parse(initialFormData.available_languages || "[]"),
      });
  
      setFileName(initialFormData.question_paper_pdf || "");
    }
  }, [initialFormData, examNames, isEditMode]);
  
  useEffect(() => {
    const fetchExamNames = async () => {
      try {
        const response = await axios.get(`${baseURL}/getExamNameWithID`);
        const transformedData = Object.entries(response.data).map(
          ([key, value]) => ({
            id: key,
            exam_name: value,
          })
        );
        console.log("Transformed Exam Names:", transformedData);
        setExamNames(transformedData);
      } catch (error) {
        console.error("Error fetching exam names:", error);
      }
    };

    fetchExamNames();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          question_paper_pdf: reader.result,
        });
      };
      reader.readAsDataURL(file);
      setFileName(file.name);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const createdBy = localStorage.getItem("HTES_user_id");
    if (!createdBy) {
      alert("User not logged in.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      created_by: createdBy,
    };

    // Check if it's edit mode and call the appropriate API
    if (isEditMode) {
      // Perform update request
      axios
        .put(
          `${baseURL}/previous-question-papers-update/${initialFormData.id}`,
          dataToSubmit
        )
        .then((response) => {
          console.log("Updated successfully:", response.data);
          setShowModal(false);
        })
        .catch((error) => {
          console.log("Error updating data:", error);
        });
    } else {
      // Perform add request
      axios
        .post(`${baseURL}/previous-question-papers-store`, dataToSubmit)
        .then((response) => {
          console.log("Form data submitted:", response.data);
          setShowModal(false);
        })
        .catch((error) => {
          console.log("Error submitting form:", error);
        });
    }
  };

  const handleCloseModal = () => {
    setInitialFormData()
    setShowModal(false); // Close the modal
    setFormData({
      tpaexam_id: "",
      question_paper_year: "",
      access_type: "Free",
      question_paper_name: "",
      number_of_questions: "",
      question_paper_marks: "",
      exam_duration: "",
      available_languages: [],
      question_paper_pdf: null,
      created_by: "",
      amount: "",
    }); // Reset form data

    // Reset any other states if required (languageInput, fileName, etc.)
    setLanguageInput("");
    setFileName("");
  };

  if (!showModal) return null;

  const handleAddLanguage = () => {
    const newLanguage = languageInput.trim();
    if (newLanguage && !formData.available_languages.includes(newLanguage)) {
      setFormData({
        ...formData,
        available_languages: [...formData.available_languages, newLanguage],
      });
      setLanguageInput("");
    }
  };

  const handleLanguagesChange = (event, newLanguages) => {
    setFormData({ ...formData, available_languages: newLanguages });
  };

  return (
    <div className="previouspaper-modal">
      <div className="previouspaper-modal-content">
        <div className="previouspaper-modal-heading-container">
          <h2 className="previouspaper-modal-heading">
            {initialFormData
              ? "Edit Previous Question Paper"
              : "Add Previous Year Question Paper"}
          </h2>
          <hr className="previouspaper_separator" />
        </div>

        <div className="prev-close-icon-container">
          <IconButton
            className="add-update-previous-year-close-button"
            onClick={handleCloseModal} // Call the handleCloseModal to reset data and close
          >
            <CloseIcon className="add-update-previous-year-close-button-x" />
          </IconButton>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Form Fields */}
          <div className="prev-exam-name-container">
            <Autocomplete
              value={
                examNames.find((exam) => exam.id === formData.tpaexam_id) ||
                null
              }
              onChange={(event, newValue) => {
                console.log("New Value:", newValue); // Log the selected value
                setFormData({
                  ...formData,
                  tpaexam_id: newValue ? newValue.id : "",
                  exam_name: newValue ? newValue.exam_name : "",
                });
              }}
              options={examNames || []} // Ensure no crash if examNames is empty
              getOptionLabel={(option) => option.exam_name || ""}
              renderInput={(params) => (
                <TextField {...params} label="Exam Name" fullWidth />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />

            <TextField
              label="Question Paper Year"
              type="number"
              name="question_paper_year"
              value={formData.question_paper_year}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Access Type"
              select
              name="access_type"
              value={formData.access_type}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Free">Free</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
            </TextField>

            {formData.access_type === "Paid" && (
              <TextField
                label="Amount"
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            )}

            <div className="question-name-questions-container">
              <TextField
                label="Question Paper Name"
                name="question_paper_name"
                value={formData.question_paper_name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Number of Questions"
                type="number"
                name="number_of_questions"
                value={formData.number_of_questions}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </div>

            <div className="marks-duration-container">
              <TextField
                label="Total Marks"
                type="number"
                name="question_paper_marks"
                value={formData.question_paper_marks}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <div className="exam-duration-container">
                <TextField
                  label="Exam Duration"
                  type="number"
                  name="exam_duration"
                  value={formData.exam_duration}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <span style={{ marginLeft: "8px" }}>Minutes</span>
                    ),
                  }}
                />
              </div>
            </div>

            <div>
              <Autocomplete
                multiple
                id="available-languages"
                options={[]}
                value={
                  Array.isArray(formData.available_languages)
                    ? formData.available_languages
                    : []
                } // Fallback to array
                onChange={handleLanguagesChange}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Available Languages"
                    margin="normal"
                    value={languageInput || ""}
                    onChange={(e) => setLanguageInput(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <IconButton
                            onClick={handleAddLanguage}
                            color="primary"
                          >
                            <AddIcon />
                          </IconButton>
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                filterOptions={(options, state) => {
                  return options.filter(
                    (option) => !formData.available_languages.includes(option)
                  );
                }}
              />
            </div>

            {/* File Upload Section */}
            <div className="previouspaper-text-field-status">
              <div className="previouspaper-slno-and-text-fileds">
                {/* <label
                  htmlFor="question_paper_pdf"
                  className="previouspaper-slno-and-text-fileds"
                >
                  Upload Question Paper PDF:
                </label> */}

                <input
                  type="file"
                  id="question_paper_pdf"
                  name="question_paper_pdf"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    document.getElementById("question_paper_pdf").click()
                  }
                  className="previouspaper-choose-file-button"
                >
                  Upload Question Paper
                </Button>

                {fileName ? (
                  <span>{fileName}</span>
                ) : (
                  <span>No file choosen</span>
                )}
              </div>
            </div>
          </div>

          <div className="previouspaper-submit-container">
            <button type="submit" className="previouspaper-submit">
              <AddIcon />
              {initialFormData ? "Update Question Paper" : "Add Question Paper"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddQuestionPaperModal;
