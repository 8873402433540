import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse, { domToReact } from "html-react-parser";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./coursetemplate2.css";

const CourseTemplate2Description = ({
  isChecked,
  title,
  content,
  setContent,
}) => {
  const [open, setOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(true);
  const [text, setText] = useState("");

  //--------------------------------------------------------------------------------
  // Handle open and close the Description textarea (text editor)
  //--------------------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
    setText(content.join("\n"));
  };

  const handleClose = () => {
    setOpen(false);
    setText("");
  };

  //--------------------------------------------------------------------------------
  // Handle the text change in the text editor
  //--------------------------------------------------------------------------------

  const handleTextChange = (value) => {
    setText(value);
  };

  //--------------------------------------------------------------------------------
  // Save the text to the form of array in the states
  //--------------------------------------------------------------------------------

  const handleSave = () => {
    setContent([text]); // Save content as an array containing HTML string
    handleClose();
  };
  //--------------------------------------------------------------------------------
  // Delete the complete section data
  //--------------------------------------------------------------------------------

  const handleDelete = () => {
    setContent([]);
  };
  //--------------------------------------------------------------------------------
  // Function to render the content based on the html text
  //--------------------------------------------------------------------------------

  const renderContent = (content) => {
    return content.map((point, index) => {
      const parsedContent = parse(point, {
        replace: (domNode) => {
          if (
            domNode.name === "li" &&
            domNode.parent &&
            (domNode.parent.name === "ul" || domNode.parent.name === "ol")
          ) {
            if (domNode.parent.name === "ul") {
              return (
                <li key={index}>
                  <FiberManualRecordIcon className="bulletpoints" />
                  {domToReact(domNode.children)}
                </li>
              );
            } else if (domNode.parent.name === "ol") {
              return <li key={index}>{domToReact(domNode.children)}</li>;
            }
          }
        },
      });
      return <div key={index}>{parsedContent}</div>;
    });
  };

  //--------------------------------------------------------------------------------
  // Expan and Hide the conatiner(content container)
  //--------------------------------------------------------------------------------
  const handleExpand = () => {
    setIsExpand((prev) => !prev);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            isExpand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpand}
            >
              <h6 className="coursetemplete2-title">{title}</h6>
              <IconButton>
                {isExpand ? (
                  <ExpandLessIcon className="course-template-expand-icon-color" />
                ) : (
                  <ExpandMoreIcon className="expand-icon-color" />
                )}
              </IconButton>
            </div>
            {/* ______________________________All icons with conatent render container_____________ */}
            <div>
              {open ? (
                <Tooltip title="Save" arrow>
                  <IconButton onClick={handleSave}>
                    <SaveIcon color="success" />
                  </IconButton>
                </Tooltip>
              ) : isChecked && content.length === 0 ? (
                <Tooltip title="Add" arrow>
                  <IconButton onClick={() => setOpen(true)}>
                    <AddIcon className="courseTemplate2-edit-icon-color" />
                  </IconButton>
                </Tooltip>
              ) : (
                isChecked && (
                  <Tooltip title="Edit" arrow>
                    <IconButton onClick={handleOpen}>
                      <EditIcon className="courseTemplate2-edit-icon-color" />
                    </IconButton>
                  </Tooltip>
                )
              )}
              {isChecked && (
                <Tooltip title="Delete" arrow>
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>

          {/* ______________________________Description textarea_____________ */}
          {open && (
            <div className="coursetemplate2-description-textare-coantainer">
              <ReactQuill
                className="cousetemplate2-description-textarea"
                value={text}
                onChange={handleTextChange}
                placeholder="Description Start From Here...."
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
          )}

          {/* ______________________________end_____________________________ */}
          {/* _________________Render the content__________________ */}
          {isExpand && (
            <div className="coursetemplate2-content-render-conatiner">
              <div className="coursetemplate2-checkicon-and-points">
                <div>{renderContent(content)}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* ______________________________end_____________________________ */}
    </>
  );
};

export default CourseTemplate2Description;
