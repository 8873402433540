import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, TextField, Button } from "@mui/material";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";
import SendIcon from "@mui/icons-material/Send";
import ProcessProgressCircle from "../../ProcessProgressCircle/ProcessProgressCircle";

function SendEmailForm({
  editData,
  sendRequest,
  handleModalClose,
  setEditData,
}) {
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  //--------------------------------------------------------------------------------
  // State to track validation errors
  //--------------------------------------------------------------------------------

  const [errors, setErrors] = useState({
    email: false,
    subject: false,
    message: false,
  });

  //--------------------------------------------------------------------------------
  // Remove error for the field if user starts typing and add the data
  //--------------------------------------------------------------------------------
  const handleInputChange = (field, value) => {
    setEmailData((prev) => ({ ...prev, [field]: value }));

    if (value.trim() !== "") {
      setErrors((prev) => ({ ...prev, [field]: false }));
    }
  };

  //--------------------------------------------------------------------------------
  //Fill the Email form Deatails Default
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      setEmailData({
        name: editData.EmployeeName || "",
        email: editData.EmployeeEmail || "",
        subject: "Request From HTES To Fill Phase1 Information",
        message: "Request you to submit Phase1 information between this date.",
      });
    }
  }, [editData]);

  //--------------------------------------------------------------------------------
  //Send mail api calling and data send to the backend
  //--------------------------------------------------------------------------------
  const handleSendEmail = async () => {
    // Basic validation
    setLoading(true);
    const { email, subject, message } = emailData;
    let hasError = false;

    if (!email.trim()) {
      setErrors((prev) => ({ ...prev, email: true }));
      hasError = true;
    }

    if (!subject.trim()) {
      setErrors((prev) => ({ ...prev, subject: true }));
      hasError = true;
    }

    if (!message.trim()) {
      setErrors((prev) => ({ ...prev, message: true }));
      hasError = true;
    }

    if (hasError) {
      setLoading(false); // Stop loading if validation fails
      return; // Stop if validation fails
    }

    try {
      const response = await axios.post(
        `${baseURL}/sendEnquiryMail`,
        emailData
      );

      if (response.status === 200) {
        customAlert(
          "",
          "Employee Info Request mail sent successfully!",
          "success"
        );
        setEmailData({ email: "", subject: "", message: "", name: "" }); // Reset form after sending
        handleModalClose();
        setEditData("");
      }
    } catch (error) {
      customAlert("", "Failed to send email. Please try again.", "error");
    } finally {
      setLoading(false); // Stop loading once the email is sent or fails
    }
  };

  return (
    <div>
      {/* ______________Loader___________________ */}
      {loading && <ProcessProgressCircle RequiredProgressName="Loading..." />}
      {/* ___________________form data and fileds____________ */}
      {!loading && (
        <Modal open={sendRequest} onClose={handleModalClose}>
          <div className="mail-container">
            <div className="mail-modal-heading-alignment">
              <h5>Send Email Request</h5>
            </div>

            {/* Conditionally render the loading circle */}

            <>
              <TextField
                label="Recipient Email"
                fullWidth
                margin="normal"
                value={emailData.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                error={errors.email}
                helperText={errors.email ? "Email is required" : ""}
              />
              <TextField
                label="Subject"
                fullWidth
                margin="normal"
                value={emailData.subject}
                onChange={(e) => handleInputChange("subject", e.target.value)}
                error={errors.subject}
                helperText={errors.subject ? "Subject is required" : ""}
              />
              <TextField
                label="Message"
                multiline
                rows={4}
                fullWidth
                margin="normal"
                value={emailData.message}
                onChange={(e) => handleInputChange("message", e.target.value)}
                error={errors.message}
                helperText={errors.message ? "Message is required" : ""}
              />
              {/* ______________Button section_____________ */}
              <div className="send-email-container-alignment">
                <Button
                  variant="contained"
                  color="success"
                  id="button-zoom"
                  onClick={handleSendEmail}
                  disabled={loading} // Disable button while loading
                >
                  <SendIcon className="buttonicons" />
                  &nbsp;Send Email
                </Button>
              </div>
            </>
            {/* ______________________end__________________ */}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default SendEmailForm;
