import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  Checkbox,
  Chip,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import "./batchmanagement.css";
import { CheckBox } from "@mui/icons-material";

function BatchInfoModal({ open, close, setUpdate, editData }) {
  const [batchInfo, setBatchInfo] = useState({
    AddGroup: "",
    BatchName: "",
    StartDate: "",
    EndDate: "",
    BatchInformation: "",
    FreeOrPaid: "Free",
    Price: "",
    IsResultShown: "",
    Authentication: [], // Updated to hold multiple selections
  });
  const [error, setError] = useState("");
  const [groupOptions, setGroupOptions] = useState([{ GroupName: "General" }]);

  //-------------------------------------------------------------------------
  // Fetch all groups from the API on mount
  //-------------------------------------------------------------------------

  useEffect(() => {
    axios
      .get(`${baseURL}/CreateGroup_fetch_all`)
      .then((response) => {
        if (response.status === 200) {
          // setGroupOptions(response.data || []);
          setGroupOptions([{ GroupName: "General" }, ...response.data] || []);
        }
      })
      .catch((error) => {
        console.error("Error fetching groups:", error);
      });
  }, []);

  //-------------------------------------------------------------------------
  // Add more options as needed
  //-------------------------------------------------------------------------
  const securityOptions = [
    { label: "Face Detection" },
    { label: "Disable Full Screen Exits" },
    { label: "Screen Recording Prevention" },
  ];

  //-------------------------------------------------------------------------
  // Reset price when Free is selected
  //-------------------------------------------------------------------------
  useEffect(() => {
    if (batchInfo.FreeOrPaid === "Free") {
      setBatchInfo((prev) => ({
        ...prev,
        Price: 0,
      }));
    }
  }, [batchInfo.FreeOrPaid]);

  //-------------------------------------------------------------------------
  // Set initial content for editing
  //-------------------------------------------------------------------------
  useEffect(() => {
    setBatchInfo({
      AddGroup: editData.AddGroup,
      BatchName: editData.BatchName,
      StartDate: editData.StartDate,
      EndDate: editData.EndDate,
      BatchInformation: editData.BatchInformation,
      FreeOrPaid: editData.FreeOrPaid || "",
      IsResultShown: editData.IsResultShown ? "True" : "False" || "",
      Authentication:
        editData.Authentication && typeof editData.Authentication === "string"
          ? (() => {
              try {
                const parsed = JSON.parse(editData.Authentication);
                return Array.isArray(parsed)
                  ? parsed.map((skill) => ({ value: skill, label: skill }))
                  : [];
              } catch (e) {
                // console.error("Failed to parse Authentication:", e);
                return [];
              }
            })()
          : [],
    });
  }, [editData]);

  //-------------------------------------------------------------------------
  // Hndle change the content
  //-------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBatchInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (
      name === "StartDate" &&
      batchInfo.EndDate &&
      value > batchInfo.EndDate
    ) {
      setError("*End date should be greater than the start date");
    } else if (
      name === "EndDate" &&
      batchInfo.StartDate &&
      value < batchInfo.StartDate
    ) {
      setError("*End date should be greater than the start date");
    } else {
      setError("");
    }
  };

  //-------------------------------------------------------------------------
  // Dropdown selction of the security concern
  //-------------------------------------------------------------------------
  const handleSecurityChange = (event, newValue) => {
    setBatchInfo((prevState) => ({
      ...prevState,
      Authentication: newValue,
    }));
  };

  //-------------------------------------------------------------------------
  // Save and update the data
  //-------------------------------------------------------------------------
  const handleSave = () => {
    // Check if required fields are not empty
    if (!batchInfo.BatchName) {
      customAlert("", "Please enter the Batch Name", "warning");
      return;
    }

    if (!batchInfo.StartDate) {
      customAlert("", "Please select the Start Date", "warning");
      return;
    }

    if (!batchInfo.EndDate) {
      customAlert("", "Please select the End Date", "warning");
      return;
    }

    const isEditMode = !!editData;

    // Map the Authentication array to strings before sending to backend
    const formattedBatchInfo = {
      ...batchInfo,
      Authentication: batchInfo.Authentication.map((option) => option.label),
    };

    const url = isEditMode
      ? `${baseURL}/updatebatchinfo/${editData.id}`
      : `${baseURL}/batchinfo`;
    const method = isEditMode ? axios.put : axios.post;

    method(url, formattedBatchInfo)
      .then((response) => {
        if (response.status === 200) {
          customAlert(
            "",
            editData
              ? "Batch Updated Successfully"
              : "Batch Saved Successfully",
            "success"
          );
          close();
          setBatchInfo("");
          setUpdate(true);
        } else {
          console.error("Unexpected status code:", response.status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          customAlert("", "Batch Name Already Exists", "warning");
        } else {
          console.error("Error saving batch:", error);
        }
      });
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="createteslayoutmodel3"
    >
      <div className="addstudentoutercontainer">
        <div className="batchinfomodal1">
          <IconButton
            id="button-zoom"
            onClick={close}
            className="addeducationalMapmodalcancel"
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>

          <h5 className="EducationalTableViewmodalHeader">
            {editData ? "Edit" : "Add"} Batch Information
          </h5>
          <div className="batch-information-scroll-container">
            <TextField
              fullWidth
              name="BatchName"
              label="Batch Name"
              value={batchInfo.BatchName}
              onChange={handleInputChange}
              required
            />

            <div className="gridmargingap">
              <Autocomplete
                options={groupOptions}
                getOptionLabel={(option) => option.GroupName}
                onChange={(event, newValue) => {
                  setBatchInfo((prevState) => ({
                    ...prevState,
                    AddGroup: newValue ? newValue.GroupName : "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Add Group" />
                )}
                value={
                  groupOptions.find(
                    (option) => option.GroupName === batchInfo.AddGroup
                  ) || null
                }
                fullWidth
              />
            </div>
            <div className="batchinfomaodelgrid">
              <TextField
                name="StartDate"
                label="Start Date"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: today,
                  style: { textTransform: "uppercase" },
                }}
                value={batchInfo.StartDate}
                onChange={handleInputChange}
              />
              <TextField
                name="EndDate"
                label="End Date"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: today,
                  style: { textTransform: "uppercase" },
                }}
                error={!!error}
                helperText={error}
                value={batchInfo.EndDate}
                onChange={handleInputChange}
              />
            </div>

            <div className="batchinfomaodelgrid">
              <FormControl fullWidth>
                <InputLabel>Free or Paid</InputLabel>
                <Select
                  label="Free or Paid"
                  name="FreeOrPaid"
                  value={batchInfo.FreeOrPaid || "Free"}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Free">Free</MenuItem>
                  <MenuItem value="Paid">Paid</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Is Result Shown</InputLabel>
                <Select
                  label="Is Result Shown"
                  name="IsResultShown"
                  value={batchInfo.IsResultShown}
                  onChange={handleInputChange}
                >
                  <MenuItem value="True">True</MenuItem>
                  <MenuItem value="False">False</MenuItem>
                </Select>
              </FormControl>
            </div>

            {batchInfo.FreeOrPaid === "Paid" && (
              <TextField
                fullWidth
                label="Price"
                name="Price"
                type="number"
                value={batchInfo.Price}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                margin="normal"
              />
            )}

            <div className="gridmargingap">
              <Autocomplete
                multiple
                fullWidth
                options={securityOptions}
                getOptionLabel={(option) => option.label}
                value={batchInfo.Authentication}
                onChange={handleSecurityChange}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                } // Ensure unique selection
                disableCloseOnSelect // Keep dropdown open on selection
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Security Concern"
                    placeholder="Select options"
                  />
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      label={option.label}
                      {...getTagProps({ index })}
                      deleteIcon={
                        <Tooltip title="Remove" placement="top" arrow>
                          <CloseIcon className="chip-background-color-and-alignment-delete" />
                        </Tooltip>
                      }
                      classes={{
                        root: "chip-background-color-and-alignment",
                        deleteIcon:
                          "chip-background-color-and-alignment-delete",
                      }}
                    />
                  ))
                }
              />
            </div>
            <div className="gridmargingap">
              <TextField
                multiline
                rows={2}
                fullWidth
                label="Batch Information"
                name="BatchInformation"
                value={batchInfo.BatchInformation}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="createteslayoutmodel-new">
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={handleSave}
            >
              {editData ? (
                <UpdateIcon className="buttonicons" />
              ) : (
                <SaveIcon className="buttonicons" />
              )}
              &nbsp;{editData ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default BatchInfoModal;
