import React, { useEffect } from "react";
import "./ReceiptDownloadPage.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import logoImage from "../static/Transparent2.png";
import html2pdf from "html2pdf.js";
import { renderToStaticMarkup } from "react-dom/server";
import { baseURL } from "../../http";
import axios from "axios";


function ReceiptDownloadPage({ downloadData }) {

  useEffect(() => {
    handleGeneratePDF();
  }, [downloadData]); 

  const handleGeneratePDF = () => {
    const receiptContent = (
      <div id={`receipt-${downloadData.id}`} className="receiptPaperContainer">
        <Paper elevation={3} className="receiptPaper actual-receipt">
          <img src={logoImage} alt="Logo" className="receiptLogo" />
            {/* ____________________________ Header ___________________________ */}
          <div className="receiptHeader">
            <div className="receiptHeaderBorder">RECEIPT</div>
          </div>

            {/* ____________________________ ID, Date _________________________ */}
          <div className="receiptHeaderIDandDate">
            <Typography
              variant="body1"
              component="span"
              className="rc_head_IdDate"
            >
              Receipt ID: <strong>{downloadData.receiptid || "N/A"}</strong>
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className="rc_head_IdDate"
            >
              Date: <strong>{downloadData.paiddate || "N/A"}</strong>
            </Typography>
          </div>

            {/* ____________________________ Address, PH, Email  ______________ */}
          <div className="addressBox">
            <div className="addressBoxHeading">Talent Pool Academy</div>
            <div className="adressContent">
              <p>
                Address: No.115A, 16th Main Road, Vijaya Nagar, Bangalore,
                Karnataka - 560040
              </p>
            </div>
            <div className="addressBoxPhoneNoAndEmailId">
              <Typography variant="body1" component="span" className="rc_Phone">
                Ph No: <strong>7483868010</strong>
              </Typography>
              <Typography variant="body1" component="span" className="rc_Phone">
                Email ID: <strong>talentpoolacademy09@gmail.com</strong>
              </Typography>
            </div>
          </div>

            {/* ____________________________ Payment Summary __________________ */}
          <div className="paymentSummary">
            {/* _______________ Received _______________ */}
            <p className="rc_sum_txt">
              <span className="textContainer">Received with thanks from</span>
              <span className="underlineContainer">
                <strong className="underline">
                  {downloadData.studentname || "the student"}
                </strong>
              </span>
            </p>

            {/* _______________ Amount __________________ */}
            <p className="rc_sum_txt">
              <span className="textContainer">Rupees</span>
              <span className="underlineContainer">
                <strong className="underline">₹ {downloadData.amount}</strong>
              </span>
              <span className="textContainer">Only </span>
            </p>

            {/* _______ Paid Thro & Course Catergory ____ */}
            <p className="rc_sum_txt">
              <span className="textContainer"> towards</span>
              <span className="underlineContainer">
                <strong className="underline">
                  {downloadData.paidthrough || "N/A"}
                </strong>
              </span>
              <span className="textContainer">Course by</span>
              <span className="underlineContainer">
                <strong className="underline">
                  {downloadData.courseCategory || "N/A"}
                </strong>
              </span>
              <span className="textContainer">in full.</span>
            </p>
          </div>

            {/* _______________ Acknowlegement __________ */}
          <div className="acknowledgmentBox">
            <p style={{ fontSize: "12px" }}>
              This is a computer-generated e-Acknowledgment & does not require
              any signature.
            </p>
          </div>
        </Paper>
      </div>
    );

    const content = renderToStaticMarkup(receiptContent);

    html2pdf()
      .set({
        margin: 1,
        filename: "Receipt.pdf",
        image: { type: "jpeg", quality: 3.5 },
        html2canvas: { scale: 4, logging: false },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .from(content)
      .save()
      .then(() => {
        updateReceiptDownloadCount();
      });

  };

  // This Below function will call only if PDF is downloaded
  const updateReceiptDownloadCount=()=>{
    // alert('reci-count')
    axios.put(`${baseURL}/Receipt_DownloadCount/${downloadData.id}`, null, {
      headers: {
          'Accept': 'application/json',
      }
  })
  .then((response) => {
      console.log('Response status:', response.status); 
      if (response.status === 200) {
          console.log('updated - Download Count'); 
          
        } else {
        console.log('API Error - Download Count'); 
          
      }
  })
  .catch(() => {console.log('Server Down - Download Count');  });

  }

  return null;
}

export default ReceiptDownloadPage;
