import React, { useState, useEffect } from "react";
import "./coursetemplate2.css";
import Template2Header from "./Template2Header";
import Coursetemplate2Tabs from "./Coursetemplate2Tabs";
import axios from "axios";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";

function CourseTemplateMainPage({
  isEdit,
  editData,
  setCoursePage,
  SetDataBaseUpdate,
}) {
  const [selectedCourse, setSelectedCourse] = useState("Select Course Name");
  const [image, setImage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [tags, setTags] = useState([]);
  const [courseDetails, setCourseDetails] = useState({
    duration: "1 Hours",
    teachingMode: "online",
    price: "10000",
    studentsEnrolled: "99",
    rating: "1",
  });

  const [courseTags, setCourseTags] = useState([]);
  const [objectivecontent, setObjectiveContent] = useState([]);
  const [skillscontent, setSkillsContent] = useState([]);
  const [requirementscontent, setRequirementsContent] = useState([]);
  const [learnersecontent, setLearnersContent] = useState([]);
  const [description, setDescription] = useState([]);
  const [courseContent, setCourseContent] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [selectedFAQs, setSelectedFAQs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [files, setFiles] = useState([]);

  //--------------------------------------------------------------------------------
  // When edit the data prefilled to the template fillled
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData && isEdit === true) {
      setSelectedCourse(editData.CourseName);
      setImage(editData.Image);
      setCourseDetails({
        duration: editData.Duration,
        teachingMode: editData.TeachingMode,
        price: editData.CoursePrice,
        studentsEnrolled: editData.StudentsEnrolled,
        rating: editData.Rating,
      });
      setCourseTags(JSON.parse(editData.CourseTags));
      setSelectedCategories(JSON.parse(editData.FAQsSelectedCategories));
      setObjectiveContent(JSON.parse(editData.Objective));
      setSkillsContent(JSON.parse(editData.Skills));
      setRequirementsContent(JSON.parse(editData.Requirements));
      setLearnersContent(JSON.parse(editData.learnedFromCourse));
      setDescription(JSON.parse(editData.Description));
      setCourseContent(JSON.parse(editData.CourseContent));

      //____________________________Handle files_____________________________//
      if (editData.UploadBrochure) {
        try {
          const parsedFiles = JSON.parse(editData.UploadBrochure);
          setFiles(parsedFiles);
        } catch (error) {
          console.error("Error parsing files data:", error);
          setFiles([]);
        }
      } else {
        setFiles([]);
      }

      //____________________________Fetch instructor data_____________________________//

      const fetchInstructors = async () => {
        try {
          // Parse the JSON string into an array of instructor IDs
          const instructorIds = JSON.parse(editData.Instructors);

          // Fetch data for each instructor using Promise.all
          const instructorsData = await Promise.all(
            instructorIds.map(async (id) => {
              const response = await axios.get(
                `${baseURL}/Instructor_fetch/${id}`
              );
              return response.data;
            })
          );

          // Update selected instructors state with the fetched data
          setSelectedInstructors(instructorsData);
          console.log("Fetched instructor data:", instructorsData);
        } catch (error) {
          console.error("Error fetching instructor data:", error);
        }
      };

      //___________________fetch the faqs based on the categories and id__________//
      const fetchFAQs = async () => {
        try {
          // Parse the JSON string into an array of instructor IDs
          const FAQsIds = JSON.parse(editData.FAQs);

          // Fetch data for each instructor using Promise.all
          const FAQsData = await Promise.all(
            FAQsIds.map(async (id) => {
              const response = await axios.get(`${baseURL}/FAQ_fetch/${id}`);
              return response.data;
            })
          );

          // Update selected instructors state with the fetched data
          setSelectedFAQs(FAQsData);
          console.log("Fetched instructor data:", FAQsData);
        } catch (error) {
          console.error("Error fetching instructor data:", error);
        }
      };

      fetchInstructors();
      fetchFAQs();
    }
  }, [editData, isEdit]);

  //--------------------------------------------------------------------------------
  // Calculate the %of the course page complete
  //--------------------------------------------------------------------------------

  const calculateCompletionPercentage = () => {
    const totalFields = 17; // Adjust this number based on the actual total fields you want to consider
    let filledFields = 0;
  
    // Count filled fields in courseDetails
    Object.values(courseDetails || {}).forEach((value) => {
      if (value) filledFields++;
    });
  
    // Count additional fields
    if ((selectedFAQs || []).length > 0) filledFields++;
    if ((selectedInstructors || []).length > 0) filledFields++;
    if ((courseContent || []).length > 0) filledFields++;
    if ((description || []).length > 0) filledFields++;
    if ((learnersecontent || []).length > 0) filledFields++;
    if ((requirementscontent || []).length > 0) filledFields++;
    if ((skillscontent || []).length > 0) filledFields++;
    if ((objectivecontent || []).length > 0) filledFields++;
    if ((courseTags || []).length > 0) filledFields++;
    if ((files || []).length > 0) filledFields++;
    if ((selectedCategories || []).length > 0) filledFields++;
    if (image) filledFields++; // Check if image is not an empty string
  
    return Math.round((filledFields / totalFields) * 100);
  };

  useEffect(() => {
    calculateCompletionPercentage();
  }, [courseDetails, image, selectedFAQs, 
    selectedInstructors, courseContent, description, 
    learnersecontent, requirementscontent, skillscontent, 
    objectivecontent, courseTags, files, selectedCategories]);
  


  //--------------------------------------------------------------------------------
  // create a new course api call
  //--------------------------------------------------------------------------------

  const handleSave = async () => {
    if (selectedCourse === 'Select Course Name') {
      customAlert("", "Please select a course name", "warning");
      return;
    }
    const formData = new FormData();
    formData.append("CourseName", selectedCourse);
    formData.append("Image", image);
    formData.append("Duration", courseDetails.duration);
    formData.append("TeachingMode", courseDetails.teachingMode);
    formData.append("CoursePrice", courseDetails.price);
    formData.append("StudentsEnrolled", courseDetails.studentsEnrolled);
    formData.append("Rating", courseDetails.rating);
    formData.append("CourseTags", JSON.stringify(courseTags));
    formData.append("Objective", JSON.stringify(objectivecontent));
    formData.append("Skills", JSON.stringify(skillscontent));
    formData.append("Requirements", JSON.stringify(requirementscontent));
    formData.append("learnedFromCourse", JSON.stringify(learnersecontent));
    formData.append("Description", JSON.stringify(description));
    formData.append("CourseContent", JSON.stringify(courseContent));
    formData.append("UploadBrochure", JSON.stringify(files));
    formData.append(
      "FAQsSelectedCategories",
      JSON.stringify(selectedCategories)
    );
    const instructorIds = selectedInstructors.map(
      (instructor) => instructor.id
    );
    const faqIds = selectedFAQs.map((faq) => faq.id);
    formData.append("Instructors", JSON.stringify(instructorIds));
    formData.append("FAQs", JSON.stringify(faqIds));
    formData.append("%CourseComplete", calculateCompletionPercentage());
    formData.append("Created_By", localStorage.getItem("HTES_user_id"));
    formData.append("Created_date", new Date().toISOString());
    formData.append("Modified_By", localStorage.getItem("HTES_user_id"));
    formData.append("Modified_date", new Date().toISOString());

    try {
      // For create (POST request)
      const response = await axios.post(`${baseURL}/coursepage`, formData);
      customAlert("", "Course page created successfully", "success");
      SetDataBaseUpdate(true);
      setCoursePage(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        customAlert("", "Course name already exists", "warning");
      } else {
        customAlert(
          "",
          "An error occurred while saving the course page",
          "error"
        );
      }
    }
  };

  //--------------------------------------------------------------------------------
  // update  course api call
  //--------------------------------------------------------------------------------

  const handleUpdate = async () => {
    if (selectedCourse === 'Select Course Name') {
      customAlert("", "Please select a course name", "warning");
      return;
    }
    try {
      const response = await axios.put(
        `${baseURL}/coursepage_edit/${editData.id}`,
        {
          CourseName: selectedCourse,
          Image: image,
          Duration: courseDetails.duration,
          TeachingMode: courseDetails.teachingMode,
          CoursePrice: courseDetails.price,
          StudentsEnrolled: courseDetails.studentsEnrolled,
          Rating: courseDetails.rating,
          CourseTags: JSON.stringify(courseTags),
          Objective: JSON.stringify(objectivecontent),
          Skills: JSON.stringify(skillscontent),
          Requirements: JSON.stringify(requirementscontent),
          learnedFromCourse: JSON.stringify(learnersecontent),
          Description: JSON.stringify(description),
          CourseContent: JSON.stringify(courseContent),
          UploadBrochure: JSON.stringify(files),
          FAQsSelectedCategories: JSON.stringify(selectedCategories),
          Instructors: JSON.stringify(
            selectedInstructors.map((instructor) => instructor.id)
          ),
          FAQs: JSON.stringify(selectedFAQs.map((faq) => faq.id)),
          "%CourseComplete": calculateCompletionPercentage(),
          Modified_By: localStorage.getItem("HTES_user_id"),
          Modified_date: new Date().toISOString(),
        }
      );

      if (response) {
        customAlert("", "Course page updated successfully", "success");
        setCoursePage(false);
        SetDataBaseUpdate(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        customAlert("", "Course name already exists", "warning");
      } else {
        customAlert(
          "",
          "An error occurred while saving the course page",
          "error"
        );
      }
    }
  };

  return (
    <div className="CourseTemplate2-container">
      {/* ________________________Header fnction call_________________ */}
      <Template2Header
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        image={image}
        setImage={setImage}
        courseDetails={courseDetails}
        setCourseDetails={setCourseDetails}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        tags={tags}
        setTags={setTags}
        calculateCompletionPercentage={calculateCompletionPercentage}
        handleSave={handleSave}
        handleUpdae={handleUpdate}
        editData={editData}
        setCoursePage={setCoursePage}
      />
      {/* ____________________________end__________________ */}
      {/* _________________________tabs component call__________________ */}

      <Coursetemplate2Tabs
        isChecked={isChecked}
        objectivecontent={objectivecontent}
        setObjectiveContent={setObjectiveContent}
        skillscontent={skillscontent}
        setSkillsContent={setSkillsContent}
        requirementscontent={requirementscontent}
        setRequirementsContent={setRequirementsContent}
        learnersecontent={learnersecontent}
        setLearnersContent={setLearnersContent}
        description={description}
        setDescription={setDescription}
        courseContent={courseContent}
        setCourseContent={setCourseContent}
        selectedInstructors={selectedInstructors}
        setSelectedInstructors={setSelectedInstructors}
        selectedFAQs={selectedFAQs}
        setSelectedFAQs={setSelectedFAQs}
        courseTags={courseTags}
        setCourseTags={setCourseTags}
        tags={tags}
        files={files}
        setFiles={setFiles}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      {/* _________________________end__________________ */}
    </div>
  );
}

export default CourseTemplateMainPage;
