import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import "./myemployeedata.css";
import { baseURL } from "../../http";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";

function MySalaryStructure() {
  const [salaryDetails, setSalaryDetails] = useState({
    CTC: 0,
    BasicPercent: 50,
    HRApercent: 40,
    PFEmployerPercent: 12,
    PFEmployeePercent: 12,
    ProfessionalTax: 2400,
    Tax: 0,
  }); // Initialize with null
  const [loading, setLoading] = useState(true); // Initialize loading state

  useEffect(() => {
    const fetchSalaryStructure = async () => {
      setLoading(true);
      try {
        const userEmail = localStorage.getItem('HTES_user_id');

        // First API call to get employee ID by email
        const employeeResponse = await axios.post(`${baseURL}/get-employee-id-by-email`, { email: userEmail });
        const employeeId = employeeResponse.data.employee_id;

        // Second API call to fetch salary structure using employee ID
        const salaryResponse = await axios.get(`${baseURL}/Employees_fetch/${employeeId}`);
        const parsedSalaryDetails = JSON.parse(salaryResponse.data.EmployeeSalary);
        setSalaryDetails(parsedSalaryDetails); // Assuming the response contains the salary structure
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // Ensure loading is set to false after the fetch is complete
      }
    };

    fetchSalaryStructure();
  }, []);

  if (loading) {
    return <ProcessProgressCircle />; // Show loading spinner
  }
  
  

  // Calculations with Math.floor to round down without crossing the CTC
  const basicYearly = Math.round((salaryDetails.CTC * salaryDetails.BasicPercent) / 100);
  const hraYearly = Math.floor((basicYearly * salaryDetails.HRApercent) / 100);
  const pfEmployerYearly = Math.floor((basicYearly * salaryDetails.PFEmployerPercent) / 100);
  const pfEmployeeYearly = Math.floor((basicYearly * salaryDetails.PFEmployeePercent) / 100);
  const otherAllowance = Math.floor(salaryDetails.CTC - (basicYearly + hraYearly + pfEmployerYearly));
  const grossYearly = salaryDetails.CTC;
  const totalDeductionYearly = Math.floor(pfEmployeeYearly + salaryDetails.ProfessionalTax + salaryDetails.Tax);
  const totalYearly = grossYearly - totalDeductionYearly;

  return (
    <div className="salary-structure-table-container">
      <div className="salary-structure-table-heading2">
        My Salary: &nbsp;{ salaryDetails?.CTC } LPA
      </div>

      <TableContainer className="salary-structure-table">
        <Table aria-label="salary structure table">
          <TableHead>
            <TableRow className="salary-structure-row-background-color">
              <TableCell className="salary-structure-header">Particulars</TableCell>
              <TableCell className="salary-structure-header">%</TableCell>
              <TableCell className="salary-structure-header">Yearly</TableCell>
              <TableCell className="salary-structure-header">Monthly</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="salary-structure-row-fontsize">Basic</TableCell>
              <TableCell className="salary-structure-row-fontsize">{salaryDetails.BasicPercent}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{basicYearly}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{Math.floor(basicYearly / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-row-fontsize">HRA</TableCell>
              <TableCell className="salary-structure-row-fontsize">{salaryDetails.HRApercent}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{hraYearly}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{Math.floor(hraYearly / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-row-fontsize">PF Employer</TableCell>
              <TableCell className="salary-structure-row-fontsize">{salaryDetails.PFEmployerPercent}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{pfEmployerYearly}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{Math.floor(pfEmployerYearly / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-row-fontsize">Other Allowance</TableCell>
              <TableCell className="salary-structure-row-fontsize"></TableCell>
              <TableCell className="salary-structure-row-fontsize">{otherAllowance}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{Math.floor(otherAllowance / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-header">Gross Salary</TableCell>
              <TableCell></TableCell>
              <TableCell className="salary-structure-header">{grossYearly}</TableCell>
              <TableCell className="salary-structure-header">{Math.floor(grossYearly / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-header1">Deduction Part:</TableCell>
              <TableCell className="salary-structure-header1"></TableCell>
              <TableCell className="salary-structure-header1"></TableCell>
              <TableCell className="salary-structure-header1"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-row-fontsize">PF Employee</TableCell>
              <TableCell className="salary-structure-row-fontsize">{salaryDetails.PFEmployeePercent}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{pfEmployeeYearly}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{Math.floor(pfEmployeeYearly / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-row-fontsize">Professional Tax</TableCell>
              <TableCell></TableCell>
              <TableCell>{salaryDetails.ProfessionalTax}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{Math.floor(salaryDetails.ProfessionalTax / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-row-fontsize">Tax</TableCell>
              <TableCell></TableCell>
              <TableCell className="salary-structure-row-fontsize">{salaryDetails.Tax}</TableCell>
              <TableCell className="salary-structure-row-fontsize">{Math.floor(salaryDetails.Tax / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-header">Total Deduction</TableCell>
              <TableCell></TableCell>
              <TableCell className="salary-structure-header">{totalDeductionYearly}</TableCell>
              <TableCell className="salary-structure-header">{Math.floor(totalDeductionYearly / 12)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="salary-structure-header">Total Salary</TableCell>
              <TableCell></TableCell>
              <TableCell className="salary-structure-header">{totalYearly}</TableCell>
              <TableCell className="salary-structure-header">{Math.floor(totalYearly / 12)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default MySalaryStructure;
