import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close";
import {  IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { baseURL } from "../../http";
import {
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import Stscroll11CareerProfileModal from "./PmTab11CareerProfileModal";

function Stscroll11CareerProfile({callPercentage}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem('HTES_user_id') || '';
  const [modalStatus, setModalStatus] = useState(false);

  const [currentIndustry, setCurrentIndustry] = useState("");
  const [department, setDepartment] = useState("");
  const [roleCategory, setRoleCategory] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [desiredJobType, setDesiredJobType] = useState("");
  const [desiredEmploymentType, setDesiredEmploymentType] = useState("");
  const [preferredShift, setPreferredShift] = useState("");
  const [preferredWorkLocation, setPreferredWorkLocation] = useState([]);
  const [buttonName, setButtonName] = useState("Save");
  
//--------------------------------------------------------------------------------
//  Modal toggles
//--------------------------------------------------------------------------------
function toggleModal() {
    setModalStatus(!modalStatus);
  }

//--------------------------------------------------------------------------------
// Fetching the Data from API
//--------------------------------------------------------------------------------
useEffect(() => {
    fetchData();

  }, []);

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
    async function fetchData() {
      try {
        const response = await fetch(`${baseURL}/getCareerProfile/${userEmail}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCurrentIndustry(data.currentIndustry || "");
          setDepartment(data.department || "");
          setRoleCategory(data.roleCategory || "");
          setJobRole(data.jobRole || "");
          
          setDesiredJobType(data.desiredJobType || "");
          setDesiredEmploymentType(data.desiredEmploymentType || "");
          setPreferredShift(data.preferredShift || "");
          // setPreferredWorkLocation(data.preferredWorkLocation || "");
          setPreferredWorkLocation(
            data.preferredWorkLocation
              ? JSON.parse(data.preferredWorkLocation)
              : []
          );

          // setA(data.preferredWorkLocation);
          // setB(JSON.parse(data.preferredWorkLocation))
          if(data.currentIndustry==null){ setButtonName('Save') } else { setButtonName('Update')}

        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }

      
    }
  return (
    <div>
        {/* ___________________________________ Tab Heading _______________________________ */}
      <h5 className="pmTabHeadings">
        Career Profile{" "}
        <Tooltip title="Edit" arrow>
                <IconButton onClick={toggleModal}>
                  <EditIcon className="pmEditIcon" />
                </IconButton>
        </Tooltip>
      </h5>


        {/* ___________________________________ Profile  render __________________________ */}
      <div>

    


          <MDBRow>
        {/* ___________________________________ Current Industry _______________________________ */}
            <MDBCol>
              <label  >Current Industry</label>
              <br></br>
              <h6 className="pmDataLabel">
                {currentIndustry || ""} 
                
              </h6>
            </MDBCol>
            <br></br>
            <br></br>

        {/* ___________________________________ Department    _______________________________ */}
            <MDBCol>
              <label >Department</label>
              <br></br>
              <h6 className="pmDataLabel">{department || ""}</h6>
            </MDBCol>
          </MDBRow>
          <br />

          <MDBRow>
        {/* ___________________________________ Role Category    _______________________________ */}
            <MDBCol>
              <label  >Role Category</label>
              <br />
              <h6 className="pmDataLabel" >
              {roleCategory}
              </h6>
            </MDBCol>
            <br />

        {/* ___________________________________ Job Role _____________________________ */}
            <MDBCol>
            <label  >
            Job Role
              </label>
              <br></br>
              <h6 className="pmDataLabel">{jobRole || ""}</h6>
            </MDBCol>
          </MDBRow>
          <br />

          <MDBRow>
        {/* _______________________________________ Desired Job type  _________________________________ */}
            <MDBCol>
              <label  >Desired Job type</label>
              <br></br>
              <h6 className="pmDataLabel">{desiredJobType || ""}</h6>
            </MDBCol>

        {/* _______________________________________ Desired Employment type  _________________________________ */}
            <MDBCol>
              <label  >Desired Employment type</label>
              <br></br>
              <h6 className="pmDataLabel">{desiredEmploymentType || ""}</h6>
            </MDBCol>
          </MDBRow>
          <br />
       
          <MDBRow>
        {/* _______________________________________ Preferred Shift  _________________________________ */}
            <MDBCol>
              <label  >Preferred Shift</label>
              <br></br>
              <h6 className="pmDataLabel">{preferredShift || ""}</h6>
            </MDBCol>

        {/* _______________________________________ Preferred Work Location  _________________________________ */}
            <MDBCol>
              <label  >Preferred Work Location</label>
              <br></br>
              <h6 className="pmDataLabel">
                {/*  DB = [{"value":"Raipur","label":"Raipur"}] check in response with parsed */}
                {Array.isArray(preferredWorkLocation) && preferredWorkLocation.length > 0
                ? preferredWorkLocation.map(location => location.label).join(", ")
                : ""}
                </h6>
            </MDBCol>
          </MDBRow>
          <br />
        </div>

        {/* ___________________________________ Add Modal code starts   ____________________ */}
        <Modal
        open={modalStatus}
        onClose={setModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <div className="pmMuiModalBox">
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={toggleModal}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>

          {/* _________________________________    Component Call  ___________________________ */}
            <Stscroll11CareerProfileModal 
            togglingModal={toggleModal} 
            fetchData={fetchData} 
            buttonName={buttonName}
            callPercentage={callPercentage}
            />
            </div>
        </Modal>
    {/* ___________________________________ Add_Modal code Ends   ____________________________ */}
    </div>
  );
}

export default Stscroll11CareerProfile;
