import React, { useState } from "react";
import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import { Delete, Edit, MoreVert } from "@mui/icons-material";
import SellIcon from "@mui/icons-material/Sell";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function CreateTestQuestionView1({
  rows,
  SetSeletedQuestion,
  selectedQuestions,
  onEdit,
  loading,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreMenuIndex, setMoreMenuIndex] = useState(null); // Track which question's menu is open
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  //--------------------------------------------------------------------------------
  // More icon click to open the Dropdown
  //--------------------------------------------------------------------------------
  const handleMoreClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMoreMenuIndex(index); // Keep track of which question's menu is open
  };

  //--------------------------------------------------------------------------------
  // More icon click to close the Dropdown
  //--------------------------------------------------------------------------------
  const handleMoreClose = () => {
    setAnchorEl(null);
    setMoreMenuIndex(null); // Reset when menu is closed
  };

  //--------------------------------------------------------------------------------
  // Handke delete the question
  //--------------------------------------------------------------------------------
  const handleDelete = (question) => {
    const updatedQuestions = selectedQuestions.filter((q) => q !== question);
    SetSeletedQuestion(updatedQuestions); // Update the selectedQuestions state
    handleMoreClose(); // Close the menu after deleting
    setSnackbarMessage("Question deleted successfully!"); // Set the snackbar message
    setOpenSnackbar(true); // Open the snackbar
  };

  //--------------------------------------------------------------------------------
  // close the snackbar
  //--------------------------------------------------------------------------------
  const handleSnackbarClose = () => {
    setOpenSnackbar(false); // Close the snackbar after action
  };

  //--------------------------------------------------------------------------------
  // handle edit the question
  //--------------------------------------------------------------------------------
  const handleEditQuestion = (question) => {
    onEdit(question);
  };
  //--------------------------------------------------------------------------------
  // drag and drop the question card
  //--------------------------------------------------------------------------------

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedQuestions = Array.from(rows);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);
    SetSeletedQuestion(reorderedQuestions); // Update the question order
  };

  return (
    <>
      {loading ? (
        <ProcessProgressCircle />
      ) : (
        <div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="questions">
              {(provided) => (
                <div
                  className="accordion-container-add-totest"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {rows &&
                    rows.map((question, index) => (
                      <Draggable
                        key={index}
                        draggableId={String(index)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="all-component-render-page-outer-container"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="accordion-summary-of-the-question-display">
                              Q{index + 1}.&nbsp;
                              <div className="question-container-view1">
                                {question.Questions}
                              </div>
                              <Tooltip title="More" arrow>
                                <IconButton
                                  onClick={(e) => handleMoreClick(e, index)}
                                  className="icon-button-to-disapay-add-button1"
                                >
                                  <MoreVert />
                                </IconButton>
                              </Tooltip>
                              {/* Show Edit/Delete Menu for the corresponding question */}
                              {moreMenuIndex === index && (
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleMoreClose}
                                >
                                  <MenuItem
                                    className="create-test-menuitem-edit"
                                    onClick={() => handleEditQuestion(question)}
                                  >
                                    <Edit className="buttonicons" />
                                    &nbsp;Edit
                                  </MenuItem>
                                  <MenuItem
                                    className="create-test-menuitem-delete"
                                    onClick={() => handleDelete(question)}
                                  >
                                    <RemoveCircleIcon className="buttonicons" />
                                    &nbsp;Remove
                                  </MenuItem>
                                </Menu>
                              )}
                            </div>

                            <div>
                              {/* Expanded details for each question */}
                              <div>
                                <div className="display-options-and-answer">
                                  {!question.OptionA &&
                                    !question.OptionB &&
                                    !question.OptionC &&
                                    !question.OptionD && (
                                      <span className="color-change-for-the-answer">
                                        <span>Answer:</span> {question.Answer}
                                      </span>
                                    )}

                                  {question.OptionA && (
                                    <div
                                      className={`option-line ${
                                        question.Answer === question.OptionA
                                          ? "highlight-green"
                                          : ""
                                      }`}
                                    >
                                      <span className="color-dimming-for-the-options">
                                        A.&nbsp;
                                      </span>{" "}
                                      {question.OptionA}
                                    </div>
                                  )}
                                  {question.OptionB && (
                                    <div
                                      className={`option-line ${
                                        question.Answer === question.OptionB
                                          ? "highlight-green"
                                          : ""
                                      }`}
                                    >
                                      <span className="color-dimming-for-the-options">
                                        B.&nbsp;
                                      </span>{" "}
                                      {question.OptionB}
                                    </div>
                                  )}
                                  {question.OptionC && (
                                    <div
                                      className={`option-line ${
                                        question.Answer === question.OptionC
                                          ? "highlight-green"
                                          : ""
                                      }`}
                                    >
                                      <span className="color-dimming-for-the-options">
                                        C.&nbsp;
                                      </span>{" "}
                                      {question.OptionC}
                                    </div>
                                  )}
                                  {question.OptionD && (
                                    <div
                                      className={`option-line ${
                                        question.Answer === question.OptionD
                                          ? "highlight-green"
                                          : ""
                                      }`}
                                    >
                                      <span className="color-dimming-for-the-options">
                                        D.&nbsp;
                                      </span>{" "}
                                      {question.OptionD}
                                    </div>
                                  )}

                                  <div className="add-icon-and-right-icon-placement">
                                    <Tooltip title="Remove Question" arrow>
                                      <IconButton
                                        onClick={() => handleDelete(question)}
                                        className="icon-button-to-disapay-add-button3"
                                      >
                                        <RemoveCircleIcon className="buttonicons" />
                                      </IconButton>
                                    </Tooltip>
                                    <Chip
                                      className="difficulty-level-shown-color-font"
                                      label={question.DifficultyLevels}
                                      style={{
                                        backgroundColor:
                                          question.DifficultyLevels === "Easy"
                                            ? "green"
                                            : question.DifficultyLevels ===
                                              "Medium"
                                            ? "orange"
                                            : question.DifficultyLevels ===
                                              "Hard"
                                            ? "red"
                                            : "default",
                                        color: "white", // Set text color to white for contrast
                                      }}
                                    />
                                    {/* More Icon to open Edit/Delete options */}
                                    <div className="question-tag-div">
                                      <SellIcon className="button-icons-tags" />
                                      :
                                      <span className="question-tag-div1">
                                        {question.QuestionTag}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* Snackbar for success message */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={500}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="error">{snackbarMessage}</Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
}

export default CreateTestQuestionView1;
