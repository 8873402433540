import {
  TextField,
  Modal,
  IconButton,
  Button,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./rollaccesstabandrolecreate.css";
import { customAlert } from "../SweetAlertCommon/Custom";


  function AddUserRoll({ open, close, setRole, setOpen1, renderRows,inputValue,setInputValue ,selectedRole,setSelectedRole}) {


  //--------------------------------------------------------------------------------
  // User type options for the dropdown
  //--------------------------------------------------------------------------------

  const usertypeOptions = [
    { label: "Admin", value: "Admin" },
    { label: "Student", value: "Student" },
    { label: "Parent", value: "Parent" },
    { label: "Teacher", value: "Teacher" },
    { label: "General", value: "General" },
    { label: "Employee", value: "Employee" },
  ];
  
   //--------------------------------------------------------------------------------
  // Merge usertypeOptions with roles from renderRows
  //--------------------------------------------------------------------------------

  const mergedOptions = [
    ...usertypeOptions,
    ...renderRows.map(row => ({ label: row.UserRole, value: row.UserRole }))
  ];

  // Remove duplicates based on the `label` property
const uniqueOptions = mergedOptions.filter((option, index, self) => 
  index === self.findIndex(o => o.label === option.label)
);
  //--------------------------------------------------------------------------------
  // Utility function to convert text to Title Case
  //--------------------------------------------------------------------------------

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  //--------------------------------------------------------------------------------
  // Handle changes when selecting or typing a role
  //--------------------------------------------------------------------------------

  const handleChange = (event, value) => {
    const roleValue = value ? toTitleCase(value.label || value) : "";
    setSelectedRole(value);
    setRole(roleValue);
  };

  //--------------------------------------------------------------------------------
  // Handle input changes when typing into the field
  //--------------------------------------------------------------------------------

  const handleInputChange = (event, newInputValue) => {
    const titleCasedValue = toTitleCase(newInputValue);
    setInputValue(titleCasedValue); // Set the typed input as Title Case
    setRole(titleCasedValue);
  };

  //--------------------------------------------------------------------------------
  // Check if the role already exists in renderRows and proceed accordingly
  //--------------------------------------------------------------------------------

  const handleNext = () => {
    const roleToCheck = selectedRole ? toTitleCase(selectedRole.label) : inputValue;
    
    // Check if the role already exists in renderRows
    const roleExists = renderRows.some(row => row.UserRole === roleToCheck);
    
    if (roleExists) {
      customAlert("", "User role already created. Please add a new role.", "warning");
    } else if (roleToCheck) {
      setOpen1(true);
    } else {
      customAlert("", "Please add the role.", "warning");
    }
  };

  return (
    <Modal open={open} onClose={close}>
      <div className="addusermodal-roll-create-container">
        <IconButton
          id="button-zoom"
          className="EducationalTableViewmodalcloseicon"
          onClick={close}
        >
          <CloseIcon className="EducationalTableViewmodalcloseiconX" />
        </IconButton>

        {/* _______________header_______________ */}
        <div className="create-user-role-header">
          <h4>Create User Role</h4>
        </div>

        {/* _______________________Autocomplete for Role Selection___________________ */}
        <div>
          <Autocomplete
            options={uniqueOptions}
            getOptionLabel={(option) => option.label}
            value={selectedRole} // This binds the selected option
            inputValue={inputValue} // This binds the typed input
            onChange={handleChange} // Handles selection from dropdown
            onInputChange={handleInputChange} // Handles manual typing
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label="Select or Add New User Role"
              />
            )}
            freeSolo // Allows typing in custom roles
          />
        </div>

        {/* ______________________button section__________________ */}
        <div className="button-allignemnt-user-roll">
          <Button
            variant="contained"
            color="success"
            id="button-zoom"
            onClick={handleNext}
          >
            <ArrowForwardIcon className="buttonicons" />
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AddUserRoll;
