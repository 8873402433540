import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import Select from "react-select";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import a from "./../static/Transparent2.png";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function QASpage() {
  const location = useLocation();
  const examId = location.state && location.state.examId;
  const subject = location.state && location.state.subject;

  const [quesNo, setQuesNo] = useState(1);
  const [selectedOptionLanguage, setSelectedOptionLanguage] = useState(1);
  const [second, setSecond] = useState(30);
  const [minute, setMinute] = useState(2);

  const [answers, setAnswers] = useState({
    1: "",
    answered1: "Yes",
    2: "",
    answered2: "Yes",
    3: "",
    answered3: "",
    4: "",
    answered4: "",
  });
  const [score, setScore] = useState(0);
  const [all, setAll] = useState("");

  const navigate = useNavigate();

  const languageOptions = [
    { value: "React", label: "React" },
    { value: "PHP", label: "PHP" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "CSS", label: "CSS" },
    { value: "HTML", label: "HTML" },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (second !== 0) {
        setSecond(second - 1);
      }
      if (second === 0) {
        if (minute !== 0) {
          setMinute(minute - 1);
          setSecond(59);
        } // Reset seconds to 59 when minutes decrease
        else {
          if (minute === 0 && second === 0) {
            alert("Time-Up");
            navigate("/admin");
          }
        } // If both minutes and seconds are 0, show alert
      }
    }, 1000);

    // Cleanup the timer when the component unmounts or when the dependencies change
    return () => clearTimeout(timer);
  }, [minute, second ,navigate]);

  const qasData = [
    {
      id: 1,
      question: "What is React?",
      opt1: "React",
      opt2: "opt1.2",
      opt3: "opt1.3",
      opt4: "opt1.4",
      examtimetableID: 1,
      answer: "React",
      markForReview: true,
    },

    {
      id: 2,
      question: "What is CSS?",
      opt1: "CSS",
      opt2: "opt1.2",
      opt3: "opt1.3",
      opt4: "opt1.4",
      examtimetableID: 2,
      answer: "CSS",
      markForReview: true,
    },

    {
      id: 3,
      question: "What is MUI?",
      opt1: "opt1.1",
      opt2: "opt1.2",
      opt3: "MUI",
      opt4: "opt1.4",
      examtimetableID: 2,
      answer: "MUI",
      markForReview: true,
    },

    {
      id: 4,
      question: "What is ant?",
      opt1: "ant",
      opt2: "opt1.2",
      opt3: "opt1.3",
      opt4: "opt1.4",
      examtimetableID: 2,
      answer: "ant",
      markForReview: true,
    },
  ];
  // const qasData = qasDatafull.filter((item) => item.examtimetableID == (examId));

  const currentQuestion = qasData.find((item) => item.id === quesNo);

  function handleAnswer(currentQuestionID, selectedCurrentQuestionOption) {
    const updatedAnswers = {
      ...answers,
      [currentQuestionID]: selectedCurrentQuestionOption,
      ["answered" + currentQuestionID]: "Yes",
    }; // !!!! important
    setAnswers(updatedAnswers);

    setAll(JSON.stringify(answers));
  }

  function prevQues() {
    const index = qasData.findIndex((item) => item.id === quesNo);
    if (index > 0) {
      setQuesNo(qasData[index - 1].id);
    }
  }

  function nextQues() {
    const index = qasData.findIndex((item) => item.id === quesNo);
    if (index < qasData.length - 1) {
      setQuesNo(qasData[index + 1].id);
    }
  }

  function totalScore() {
    let total = 0;
    qasData.forEach((question) => {
      if (question.answer === answers[question.id]) {
        total += 1;
      }
    });

    console.log("Total Score:", total);

    setScore(total);
  }

  // const customStyles = {
  //   control: (provided) => ({ ...provided, backgroundColor: '#e0b7eb', border:'3px solid white'}), // Change 'your_desired_color' to the desired background color
  //   singleValue: (provided) => ({...provided, color: 'black', }),// Change 'your_text_color' to the desired text color
  //   clearIndicator: (provided) => ({...provided, color: 'black',}), // Change 'your_remove_color' to the desired remove color
  //   dropdownIndicator: (provided) => ({...provided, color: 'black',}), // Change 'your_dropdown_arrow_color' to the desired color

  // };

  return (
    <>
      <MDBContainer className="qasContainer">
        <MDBRow className="qasRow1">
          <MDBCol className="qasRow11" style={{ maxWidth: "200px" }}>
            <img src={a} alt="question tag" width={100} height={100} />
          </MDBCol>
          <MDBCol className="qasRow12">
            <h6>Exam Information</h6>
          </MDBCol>

          <MDBCol className="qasRow13">
            <MDBCol>
              <h6>Select Language</h6>
            </MDBCol>

            <MDBCol style={{ minWidth: "200px" }}>
              <Select
                value={selectedOptionLanguage}
                options={languageOptions}
                onInputChange={(inputValue) => inputValue} //correct
                onChange={(selectedOptions) =>
                  setSelectedOptionLanguage(selectedOptions)
                }
                isClearable={true} // x to remove at right
                isSearchable={false} // select is default searchable
                placeholder="Select Language"
                style={{ maxWidth: "100px" }}
                // styles={customStyles}  // Adjust the width as needed
              />
            </MDBCol>
          </MDBCol>
          <MDBCol
            className="qasRow14"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="clockDiv">
              {" "}
              {/** clockDiv */}
              <h6>
                <span className="mins">{minute} min </span>
                <span className="minsec">:</span>
                <span className="sec">{second} sec</span>
              </h6>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="qasRow2" style={{ width: "100%" }}>
          <MDBCol className="qasRow2Col1">
            <MDBRow>
              {/* <h3>Question-Answer Area </h3>  */}
              <h3>
                ID={examId}, {subject}{" "}
              </h3>
              <MDBRow className="qasRow2Col11">
                <div>
                  <h4>
                    {currentQuestion.id}
                    {")."} {currentQuestion.question}
                  </h4>
                  <ul className="qasRow21">
                    <input
                      type="radio"
                      id="GreenA"
                      name="a"
                      value={currentQuestion.opt1}
                      checked={
                        answers[currentQuestion.id] === currentQuestion.opt1
                      }
                      onChange={() =>
                        handleAnswer(currentQuestion.id, currentQuestion.opt1)
                      }
                    />
                    <label
                      htmlFor="GreenA"
                      className={
                        answers[currentQuestion.id] === currentQuestion.opt1
                          ? "radioSelectColor"
                          : "radioSelectColornone"
                      }
                    >
                      {currentQuestion.opt1}
                    </label>
                    <br />

                    <input
                      type="radio"
                      id="GreenB"
                      name="b"
                      value={currentQuestion.opt2}
                      checked={
                        answers[currentQuestion.id] === currentQuestion.opt2
                      }
                      onChange={() =>
                        handleAnswer(currentQuestion.id, currentQuestion.opt2)
                      }
                    />
                    <label
                      htmlFor="GreenB"
                      className={
                        answers[currentQuestion.id] === currentQuestion.opt2
                          ? "radioSelectColor"
                          : "radioSelectColornone"
                      }
                    >
                      {currentQuestion.opt2}
                    </label>
                    <br />

                    <input
                      type="radio"
                      id="GreenC"
                      name="c"
                      value={currentQuestion.opt3}
                      checked={
                        answers[currentQuestion.id] === currentQuestion.opt3
                      }
                      onChange={() =>
                        handleAnswer(currentQuestion.id, currentQuestion.opt3)
                      }
                    />
                    <label
                      htmlFor="GreenC"
                      className={
                        answers[currentQuestion.id] === currentQuestion.opt3
                          ? "radioSelectColor"
                          : "radioSelectColornone"
                      }
                    >
                      {currentQuestion.opt3}
                    </label>
                    <br />

                    <input
                      type="radio"
                      id="GreenD"
                      name="d"
                      value={currentQuestion.opt4}
                      checked={
                        answers[currentQuestion.id] === currentQuestion.opt4
                      }
                      onChange={() =>
                        handleAnswer(currentQuestion.id, currentQuestion.opt4)
                      }
                    />
                    <label
                      htmlFor="GreenD"
                      // style={{ backgroundColor: answers[currentQuestion.id] === currentQuestion.opt4 ? 'yellow' : 'none' }}
                      className={
                        answers[currentQuestion.id] === currentQuestion.opt4
                          ? "radioSelectColor"
                          : "radioSelectColornone"
                      }
                    >
                      {currentQuestion.opt4}
                    </label>
                    <br />
                  </ul>
                </div>
              </MDBRow>
            </MDBRow>

            <MDBRow className="qasFooter">
              <MDBCol className="prebtn">
                <button onClick={prevQues} className="preNxtbttn">
                  <i className="fas fa-arrow-left"></i> Prev
                </button>
              </MDBCol>
              <MDBCol style={{ textAlign: "center" }}>
                {currentQuestion.markForReview ? (
                  <button
                    onClick={() => alert("unMarked")}
                    className="preNxtbttn"
                  >
                    unMark for Review
                  </button>
                ) : (
                  <button
                    outline
                    onClick={() => alert("Marked")}
                    className="preNxtbttn"
                  >
                    Mark for Review
                  </button>
                )}
              </MDBCol>
              <MDBCol className="nexbtn">
                <button onClick={nextQues} className="preNxtbttn">
                  Next <i className="fas fa-arrow-right"></i>
                </button>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol className="qasRow2Col2 ">
            <MDBRow style={{ textAlign: "center" }}>
              <MDBCol>
                <span style={{ fontSize: "12px" }}>
                  Marked <button className="btnmarked">#</button>
                </span>
              </MDBCol>

              <MDBCol>
                <span style={{ fontSize: "12px" }}>
                  Un-Marked <button className="btnUnmarked">#</button>
                </span>
              </MDBCol>

              <MDBCol>
                <span style={{ fontSize: "12px" }}>
                  Answered <button className="bta btnanswered">#</button>
                </span>
              </MDBCol>
              <hr style={{ color: "blue" }} />
            </MDBRow>{" "}
            {/** btnmarked btnUnmarked  btnanswered*/}
            {qasData.map((qasList, index) => (
              <span key={qasList.id} style={{ margin: "5px" }}>
                {qasList.markForReview &&
                answers["answered" + qasList.id] === "Yes" ? (
                  <button
                    onClick={() => setQuesNo(qasList.id)}
                    className="btnmarked btnanswered"
                  >
                    <span className="">{qasList.id}</span>
                  </button>
                ) : qasList.markForReview ? (
                  <button
                    onClick={() => setQuesNo(qasList.id)}
                    className="btnmarked "
                  >
                    <span>{qasList.id}</span>
                  </button>
                ) : (
                  <button
                    onClick={() => setQuesNo(qasList.id)}
                    className="btnUnmarked"
                  >
                    <span>{qasList.id}</span>
                  </button>
                )}
              </span>
            ))}
            <hr style={{ color: "blue" }} />
          </MDBCol>
        </MDBRow>

        <MDBBtn onClick={totalScore}>Calculte</MDBBtn>
        <h3>Score={score}</h3>
        {all}
      </MDBContainer>
    </>
  );
}
