import React, { useState, useEffect } from "react";
import { IconButton, Tooltip, Checkbox, FormControlLabel } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import DropDown from "../../DropDownWithProps/DropDown";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import "./pdfpage.css";
import { baseURL } from "../../../http";
import axios from "axios";
import DynamicImageLogo from "../../UserSettingAPI/DynamicImageLogo";
import PDF_layout_and_renderpage from "./PDF_layout_and_renderpage";

function PDF_Landing_Page({ editTestData, handleManageQuestionPaperclose }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [includeAnswer, setIncludeAnswer] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [allQuestions, setAllQuestions] = useState([]); // Ensure this is an empty array by default
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch all questions from the backend when the component mounts
    fetchAllQuestions();

    // Fetch available images when the component mounts
    fetchImages();
  }, []);

  const fetchAllQuestions = async () => {
    try {
      const response = await axios.get(baseURL + "/getAllAiGeneratedQuestions");
      setAllQuestions(response.data || []); // Ensure it's always an array
    } catch (error) {
      console.error("Error fetching all questions:", error);
    }
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get(baseURL + "/getimages");
      setImages(response.data.images || []); // Ensure it's always an array
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    // Check if editTestData and QuestionIds are available
    if (editTestData && editTestData.QuestionIds && Array.isArray(allQuestions)) {
      const questionIds = JSON.parse(editTestData.QuestionIds); // Parse the JSON string to get the array of question IDs
      const filtered = allQuestions.filter(question =>
        questionIds.includes(question.id) // Filter questions based on the IDs in the JSON array
      );
      setFilteredQuestions(filtered);
    }
  }, [editTestData, allQuestions]);

  const instructionOptions = ["Instruction1", "Instruction2", "Instruction3"]; // Replace with your options

  return (
    <>
      <div className="createtestgeneralinfoemarionsmalldiv">
        {/* Back Icon */}
        <div className="edit-icon-alignemnt">
          <Tooltip title="Back to Home" arrow>
            <IconButton onClick={handleManageQuestionPaperclose}>
              <ArrowBackIcon className="edit-icon-color-and-fontsize-generalinfo" />
            </IconButton>
          </Tooltip>
        </div>

        <div>
          <DynamicImageLogo
            images={images}
            height={"40px"}
            width={"40px"}
          />
        </div>

        {/* Language Dropdown */}
        <div className="language-dropdown">
          <GTranslateIcon />
          <DropDown
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
          />
        </div>

        {/* Instruction Dropdown */}
        <div className="instruction-dropdown">
          <select
            id="instruction-dropdown"
            value={instruction}
            onChange={(e) => setInstruction(e.target.value)}
            className="dropdown"
          >
            <option value="" disabled>
              select Instruction
            </option>
            {instructionOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {/* Checkbox for "Answer with Question" */}
        <div className="answer-checkbox">
          <FormControlLabel
            control={
              <Checkbox
                checked={includeAnswer}
                onChange={(e) => setIncludeAnswer(e.target.checked)}
              />
            }
            label="Show Answer"
          />
        </div>

        {/* Download Icon */}
        <div className="download-icon-alignemnt">
          <Tooltip title="Download PDF" arrow>
            <IconButton onClick={() => console.log("Download initiated")}>
              <DownloadIcon className="download-icon-color-and-fontsize" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <PDF_layout_and_renderpage editTestData={editTestData} filteredQuestions={filteredQuestions} />
    </>
  );
}

export default PDF_Landing_Page;
