import React, { useState, useEffect } from "react";
import { ExpandMore } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { baseURL } from "../../../http";

function ContentSideNav({ chapters, setSelectedTopic, setSelectedSubTopic, setSelectedSubTopicId, openTopics,
  toggleTopic, setOpenTopics }) {

  const [chapterData, setChapterData] = useState([]);

  useEffect(() => {
    setChapterData(chapters);
  }, [chapters]);


  //----------------------------------------------------------------
  //Function to update order in backend
  //----------------------------------------------------------------
  const updateOrderInBackend = async (order) => {
    try {
      await axios.put(`${baseURL}/update-order`, { order });
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  //----------------------------------------------------------------
  //Function to drag and drop based on chapter, topic and sub topic
  //----------------------------------------------------------------
  const onDragEnd = (result) => {
    const { source, destination, type } = result;
    if (!destination) return;

    if (type === "CHAPTER") {
      const newChapters = [...chapterData];
      const [movedChapter] = newChapters.splice(source.index, 1);
      newChapters.splice(destination.index, 0, movedChapter);
      setChapterData(newChapters);

      const order = newChapters.map((chapter, index) => ({
        id: chapter.id || index,
        type: "chapter",
        order: index + 1,
      }));
      updateOrderInBackend(order);
    } else if (type === "TOPIC") {
      const chapterIndex = parseInt(source.droppableId.split("-")[1], 10);
      const chapter = chapterData[chapterIndex];
      if (!chapter) return;

      const newTopics = Array.from(chapter.topics);
      const [movedTopic] = newTopics.splice(source.index, 1);
      newTopics.splice(destination.index, 0, movedTopic);

      const newChapterData = Array.from(chapterData);
      newChapterData[chapterIndex] = { ...chapter, topics: newTopics };
      setChapterData(newChapterData);

      const order = newTopics.map((topic, index) => ({
        id: topic.id,
        type: 'topic',
        order: index + 1,
      }));
      updateOrderInBackend(order);
    } else if (type === "SUBTOPIC") {
      const [chapterIndex, topicIndex] = source.droppableId.split("-").slice(1).map(Number);
      const chapter = chapterData[chapterIndex];
      const topic = chapter?.topics[topicIndex];
      if (!topic) return;

      const newSubtopics = Array.from(topic.subtopics);
      const [movedSubtopic] = newSubtopics.splice(source.index, 1);
      newSubtopics.splice(destination.index, 0, movedSubtopic);

      const newChapterData = Array.from(chapterData);
      newChapterData[chapterIndex].topics[topicIndex].subtopics = newSubtopics;
      setChapterData(newChapterData);

      const order = newSubtopics.map((subtopic, index) => ({
        id: subtopic.id,
        type: 'sub_topic',
        order: index + 1,
      }));
      updateOrderInBackend(order);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="side-nav">
        <Droppable droppableId="chapters" type="CHAPTER">
          {(provided) => (
            <ul ref={provided.innerRef} {...provided.droppableProps}>
              {chapterData.map((chapter, chapterIndex) => (
                <Draggable key={chapter.id} draggableId={`chapter-${chapter.id}`} index={chapterIndex}>
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        backgroundColor: snapshot.isDragging ? "lightblue" : "transparent", // Only background color change
                        ...provided.draggableProps.style,
                      }}
                    >
                      <div className="chapter-name">{chapter.name}</div>

                      <Droppable droppableId={`chapter-${chapterIndex}`} type="TOPIC">
                        {(provided) => (
                          <ul ref={provided.innerRef} {...provided.droppableProps}>
                            {chapter.topics.map((topic, topicIndex) => (
                              <Draggable key={topic.id} draggableId={`topic-${topic.id}`} index={topicIndex}>
                                {(provided, snapshot) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      backgroundColor: snapshot.isDragging ? "lightgreen" : "transparent",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <div className="topic-container">
                                      <button
                                        className="topic-button"
                                        onClick={() => {
                                          if (topic.subtopics.length > 0) {
                                            // If there are subtopics, expand them immediately
                                            toggleTopic(topic.id);  // Expand subtopics
                                          }
                                          setSelectedTopic(topic.name);  // Set the selected topic
                                          setSelectedSubTopic(null);  // Reset subtopic
                                        }}
                                      >
                                        {topic.name}
                                      </button>

                                      {topic.subtopics.length > 0 && (
                                        <ExpandMore className={`expand-icon ${openTopics[topic.id] ? "open" : ""}`} />
                                      )}
                                    </div>

                                    {openTopics[topic.id] && (
                                      <Droppable droppableId={`topic-${chapterIndex}-${topicIndex}`} type="SUBTOPIC">
                                        {(provided) => (
                                          <ul ref={provided.innerRef} {...provided.droppableProps} className="subtopics-list">
                                            {topic.subtopics.map((subtopic, subtopicIndex) => (
                                              <Draggable key={subtopic.id} draggableId={`subtopic-${subtopic.id}`} index={subtopicIndex}>
                                                {(provided, snapshot) => (
                                                  <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      backgroundColor: snapshot.isDragging ? "lightcoral" : "transparent",
                                                      ...provided.draggableProps.style,
                                                    }}
                                                  >
                                                    <button
                                                      className="subtopic-button"
                                                      onClick={() => {
                                                        setSelectedTopic(topic.name);
                                                        setSelectedSubTopic(subtopic.name);
                                                        setSelectedSubTopicId(subtopic.id);
                                                      }}
                                                    >
                                                      {subtopic.name}
                                                    </button>
                                                  </li>
                                                )}
                                              </Draggable>
                                            ))}
                                            {provided.placeholder}
                                          </ul>
                                        )}
                                      </Droppable>
                                    )}
                                  </li>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default ContentSideNav;
