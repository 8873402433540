import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, IconButton } from "@mui/material";
import "./instructor.css";
import InstructorCardTemplate from "./InstructorCardTemplate";

function ViewInstructor({
  handleCloseInsructor,
  openInstrutorview,
  instructorData,
}) {
  return (
    <Modal
      open={openInstrutorview}
      onClose={handleCloseInsructor}
      className="viewinstructor-screen-center-alignment"
    >
      <div className="viewinstructor-container">
        <div className="viewinstructor-inside-content-container">
          <IconButton
            id="button-zoom"
            className="viewinstructor-close-iconButton-right-top-corner"
            onClick={handleCloseInsructor}
          >
            <CloseIcon className="viewinstructor-close-iconButton-right-top-corner-symbol" />
          </IconButton>

          <div className="viewinstructor-scroll-container">
            <div className="view-instructor-inside-container">
              <InstructorCardTemplate instructorData={instructorData} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ViewInstructor;
