// import React from 'react'
// import "../../../App.css"
// import TestHeadingandQuestionMenu from './TestHeadingandQuestionMenu'
// import { Grid, Paper } from '@mui/material'

// // function TestSeriesMain() {
// //     const title = "English test";
// //   const buttons = ["Mock Tests", "Prev. Years Paper Pro"];
// //   const barNames = ["Bar Name 1", "Bar Name 2", "Bar Name 3"];

//   const testData = [
//     {
//       title: "English Test 1",
//       buttons: ["Mock Tests 1", "Prev. Years Paper Pro 1"],
//       barNames: ["Bar Name 1", "Bar Name 2", "Bar Name 3"],
//     },
//     {
//       title: "English Test 2",
//       buttons: ["Mock Tests 2", "Prev. Years Paper Pro 2"],
//       barNames: ["Bar Name 4", "Bar Name 5", "Bar Name 6"],
//     },
//     // Add more objects for additional instances
//   ];

//   return (
//     // <div>
//     //    <TestHeadingandQuestionMenu
//     //     title={title}
//     //     buttons={buttons}
//     //     barNames={barNames}
//     //   />
//     // </div>
//     <div>
//       {testData.map((data, index) => (
//         <TestHeadingandQuestionMenu
//           key={index}
//           title={data.title}
//           buttons={data.buttons}
//           barNames={data.barNames}
//         />
//       ))}
//       {/* Add other components or content for the TestSeriesMain */}
//     </div>
//   )
// }

// export default TestSeriesMain

// import React, { useState } from "react";
// import "../../../App.css";
// import TestHeadingandQuestionMenu from "./TestHeadingandQuestionMenu";

// const testData = [
//   {
//     title: "Vocabulary Master for All Government Exams Mock Test All Tests",
//     totalQuestions: "259",
//     buttons: [
//       { label: "Mock Tests", className: "buttonsInQuestions button1" },
//       {
//         label: (
//           <>
//             Prev. Years Paper <span className="goldBox">PRO</span>
//           </>
//         ),
//         className: "buttonsInQuestions button2",
//       },

      
//     ],
//     barNames: [
//       "Synonyms (Frequently Asked Words)(39)",
//       "Antonyms (Mirror Words)(39)",
//       "Idiom Meaning (Phrase Hunt)(39)",
//       "One Word Substitution (Single Word Wonders)(39)",
//       "Spelling (Word Weavers)(39)",
//       "Miscellaneous Questions(13)",
//     ],
//   },

// ];

// function TestSeriesMain() {
// //   const [selectedBarName, setSelectedBarName] = useState(null);
// //   const [currentIndex, setCurrentIndex] = useState(0);

// //   const handleBarNameClick = (name) => {
// //     // Implement the logic you want when a bar name is clicked
// //     console.log(`Clicked on ${name}`);
// //     setSelectedBarName(name);
// //   };

// //   const handlePrevClick = () => {
// //     // Move to the previous item
// //     setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
// //   };

// //   const handleNextClick = () => {
// //     // Move to the next item
// //     setCurrentIndex((prevIndex) =>
// //       Math.min(testData.length - 1, prevIndex + 1)
// //     );
// //   };

//   return (
//     <div>
//       {testData.map((data, index) => (
//         // <TestHeadingandQuestionMenu
//         //   key={index}
//         //   title={data.title}
//         //   totalQuestions={data.totalQuestions}
//         //   buttons={data.buttons}
//         //   barNames={data.barNames}
//         //   selectedBarName={selectedBarName}
//         //   handleBarNameClick={handleBarNameClick}
//         //   handlePrevClick={handlePrevClick}
//         //   handleNextClick={handleNextClick}
//         // />
//         <TestHeadingandQuestionMenu
//         key={index}
//         {...data}
//       />
//       ))}
//       {/* Add other components or content for the TestSeriesMain */}
//     </div>
//   );
// }

// export default TestSeriesMain;


// import React, { useState } from "react";
// import "../../../App.css";
// import TestHeadingandQuestionMenu from "./TestHeadingandQuestionMenu";
// import QuestionCards from "./QuestionCards";
// import { Grid, Paper } from "@mui/material";

// const testData = [
//   {
//     title: "Vocabulary Master for All Government Exams Mock Test All Tests",
//     totalQuestions: "259",
//     buttons: [
//       { label: "Mock Tests", className: "buttonsInQuestions button1" },
//       {
//         label: (
//           <>
//             Prev. Years Paper <span className="goldBox">PRO</span>
//           </>
//         ),
//         className: "buttonsInQuestions button2",
//       },
//     ],

//     barNames: [
//       "Synonyms (Frequently Asked Words)(39)",
//       "Antonyms (Mirror Words)(39)",
//       "Idiom Meaning (Phrase Hunt)(39)",
//       "One Word Substitution (Single Word Wonders)(39)",
//       "Spelling (Word Weavers)(39)",
//       "Miscellaneous Questions(13)",
//     ],
//     questionDifficultyButton:[
//         {label:"All"},
//         {label:"Easy"},
//         {label:"Moderate"},
//         {label:"Difficult"},
//     ],
//   },

// ];

// const questionData = [
//   {
    
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree:"True",
//   },

//   {
    
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//   },
 
//   {
    
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//   },
// ];

// function TestSeriesMain() {
//     return (
//       <Grid>
//         <div>
//           {testData.map((data, index) => (
          
//             <TestHeadingandQuestionMenu
//             key={index}
//             {...data}
//           />
//           ))}
          
//         </div>
//         <div>
//           {questionData.map((data,index)=>(
//             <QuestionCards key={index}
//             {...data} />
//           ))}
//         </div>
//         </Grid>
//       );
//     }
//     export default TestSeriesMain;

// import React from "react";
// import "../../../App.css";
// import CombinedLayout from "./CombinedLayout"; // Replace with the actual file path

// const combinedData = [
//   {
//     type: "testHeading",
//     title: "Vocabulary Master for All Government Exams Mock Test All Tests",
//     totalQuestions: "259",
//     buttons: [
//       { label: "Mock Tests", className: "buttonsInQuestions button1" },
//       {
//         label: (
//           <>
//             Prev. Years Paper <span className="goldBox">PRO</span>
//           </>
//         ),
//         className: "buttonsInQuestions button2",
//       },
//     ],

//     barNames: [
//       "Synonyms (Frequently Asked Words)(39)",
//       "Antonyms (Mirror Words)(39)",
//       "Idiom Meaning (Phrase Hunt)(39)",
//       "One Word Substitution (Single Word Wonders)(39)",
//       "Spelling (Word Weavers)(39)",
//       "Miscellaneous Questions(13)",
//     ],
//     questionDifficultyButton: [
//       { label: "All" },
//       { label: "Easy" },
//       { label: "Moderate" },
//       { label: "Difficult" },
//     ],
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: "True",
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//   },
// ];

// function TestSeriesMain() {
//   return (
//     <div>
//       {combinedData.map((data, index) => (
//         <CombinedLayout key={index} {...data} />
//       ))}
//     </div>
//   );
// }

// export default TestSeriesMain;

import React, {useState} from "react";
import "../../../App.css";
import CombinedLayout from "./CombinedLayout";

// const testData = [
//   {
//     type: "testHeading",
//     title: "Vocabulary Master for All Government Exams Mock Test All Tests",
//     totalQuestions: "259",
//     barNames: [
//       "Synonyms (Frequently Asked Words)(39)",
//       "Antonyms (Mirror Words)(39)",
//       "Idiom Meaning (Phrase Hunt)(39)",
//       "One Word Substitution (Single Word Wonders)(39)",
//       "Spelling (Word Weavers)(39)",
//       "Miscellaneous Questions(13)",
//     ],
//     questionDifficultyButton: [
//       { label: "All" },
//       { label: "Easy" },
//       { label: "Moderate" },
//       { label: "Difficult" },
//     ],
//   },
// ];




// const questionData = [
//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: true,
//     languages: ["English", "Hindi"],
//     difficulty: "Easy",
//   },
//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: false,
//     languages: ["English", "Hindi"],
//     difficulty: "Easy",
//   },
//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: true,
//     languages: ["English", "Hindi"],
//     difficulty: "Easy",
//   },
//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: false,
//     languages: ["English", "Hindi"],
//     difficulty: "Moderate",
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: true,
//     languages: ["Tamil", "Hindi"],
//     difficulty: "Moderate",
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: false,
//     languages: [ "Hindi"],
//     difficulty: "Moderate",
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: true,
//     languages: [ "Hindi"],
//     difficulty: "Difficult",
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: false,
//     languages: ["English", "Hindi"],
//     difficulty: "Difficult",
//   },

//   {
//     type: "questionCard",
//     title: "CT 1 : Synonyms ('A to B')",
//     users: "65.2k",
//     questions: "10",
//     marks: "20",
//     time: "4",
//     isFree: true,
//     languages: ["English", "Hindi"],
//     difficulty: "Difficult",
//   },
// ];

function TestSeriesMain() {

  const [testData, setTestData] = useState([
    {
      type: 'testHeading',
      title: 'Vocabulary Master for All Government Exams Mock Test All Tests',
      totalQuestions: '259',
      questionDifficultyButton: [
        { label: 'All' },
        { label: 'Easy' },
        { label: 'Moderate' },
        { label: 'Difficult' },
      ],
    },
  ]);

  const [questionData, setquestionData] = useState([
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: true,
        languages: ["English", "Hindi"],
        difficulty: "Easy",
      },
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: false,
        languages: ["English", "Hindi"],
        difficulty: "Easy",
      },
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: true,
        languages: ["English", "Hindi"],
        difficulty: "Easy",
      },
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: false,
        languages: ["English", "Hindi"],
        difficulty: "Moderate",
      },
    
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: true,
        languages: ["Tamil", "Hindi"],
        difficulty: "Moderate",
      },
    
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: false,
        languages: [ "Hindi"],
        difficulty: "Moderate",
      },
    
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: true,
        languages: [ "Hindi"],
        difficulty: "Difficult",
      },
    
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: false,
        languages: ["English", "Hindi"],
        difficulty: "Difficult",
      },
    
      {
        type: "questionCard",
        title: "CT 1 : Synonyms ('A to B')",
        users: "65.2k",
        questions: "10",
        marks: "20",
        time: "4",
        isFree: true,
        languages: ["English", "Hindi"],
        difficulty: "Difficult",
      },
    ]);


  return (
    <div>
      {testData.map((data, index) => (
        <CombinedLayout key={index} {...data} questions={questionData} />
      ))}
      {questionData.map((data, index) => (
        <CombinedLayout key={index} {...data} />
      ))}
    </div>
  );
}

export default TestSeriesMain;
