import React, { useState, useEffect } from "react";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import { customAlert } from "../SweetAlertCommon/Custom";
import CreateExamModal from "./CreateExamModal";
import CreateExam from "./CreateExam";
import "./CreateExam.css";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { baseURL } from "../../http";
import axios from "axios";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import VisibilityIcon from "@mui/icons-material/Visibility";

function TPAExamMain() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);
  const [editData, setEditData] = useState(null);
  const LoginEmail = localStorage.getItem("HTES_user_id");
  const [openManageExam, setManageExam] = useState(false);
  const [showCreateExam, setShowCreateExam] = useState(false);
  const [examDetails, setExamDetails] = useState({
    logo_image: null, 
    logo_image_name: "",
  });

  // Fetch exams from the database on component mount
  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axios.get(`${baseURL}/TPA_exams_getAll`);
        const data = response.data;

        // Set columns based on the keys of the first object in the array
        const columnNames = Object.keys(data[0] || {}).map((key) => {
          if (key === "fees") return "fees"; // Custom handling for fees
          return key;
        });
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));

        setRenderColumns(columns);

        // Format rows data and flatten fees
        const rows = data.map((row, index) => ({
          id: index + 1, // Use index as unique ID for table row
          ...row,
          fees: `General: ${row.fees.general}, SC: ${row.fees.sc}, ST: ${row.fees.st}, Others: ${row.fees.others}`, // Flatten the fees object
        }));
        setRenderRows(rows);
      } catch (error) {
        customAlert(
          "Error",
          "Failed to fetch exams. Please try again.",
          "error"
        );
        console.error(error);
      }
    };

    fetchExams();
  }, [dataBaseUpdate]);

  const handleAddMoreFunctionality = () => {
    if (LoginEmail) {
      setManageExam(true); // Open the modal
    } else {
      customAlert("", "Please Login To Add Your Exam", "warning");
    }
  };

  const handleManageExamClose = () => {
    setManageExam(false);
    setShowCreateExam(false);
    resetForm();
  };

  const handleAddExamClick = () => {
    setShowCreateExam(true);
    setManageExam(false);
  };

  const checkDuplicateExam = async () => {
    try {
      // Fetch all exams with the same exam_category
      const response = await axios.get(`${baseURL}/TPA_exams_getAll`, {
        params: {
          exam_category: examDetails.exam_category, // Send the current exam_category
        },
      });
  
      const matchingExams = response.data; // This contains all exams from the same category
  
      // Check if any exam in the same category has the same exam_name
      const isDuplicate = matchingExams.some(
        (exam) =>
          exam.exam_name.toLowerCase() === examDetails.exam_name.toLowerCase() &&
          exam.id !== examDetails.id // Exclude the current exam in case of edit
      );
  
      if (isDuplicate) {
        // Show SweetAlert if a duplicate is found
        customAlert("Duplicate Exam", "This exam already exists!", "warning");
        return true; // If duplicate is found, return true
      }
  
      return false; // If no duplicate, return false
    } catch (error) {
      console.error("Error checking duplicate exam:", error);
      customAlert("Error", "Failed to check duplicate exam.", "error");
      return true; // Assume duplicate to prevent further submission in case of error
    }
  };
  

  const handleSubmit = async () => {
    
    // const isDuplicate = await checkDuplicateExam();
    // if (isDuplicate) return; 

    try {
      const formData = new FormData();
      formData.append("exam_name", examDetails.exam_name);
      formData.append("exam_category", examDetails.exam_category);
      formData.append("overview", examDetails.overview);
      formData.append("syllabus", examDetails.syllabus);
      formData.append("eligibility", examDetails.eligibility);
      formData.append("fees[general]", examDetails.fees.general);
      formData.append("fees[sc]", examDetails.fees.sc);
      formData.append("fees[st]", examDetails.fees.st);
      formData.append("fees[others]", examDetails.fees.others);
      formData.append("top_category", examDetails.top_category);
      formData.append("state_or_department", examDetails.state_or_department);
      formData.append("authority_url", examDetails.authority_url);
      formData.append("created_by", LoginEmail);
      formData.append("created_date", new Date().toISOString());
      formData.append("is_approved", false);

      if (examDetails.logo_image) {
        formData.append("logo_image", examDetails.logo_image);
      }
      if (examDetails.detailed_pdf) {
        formData.append("detailed_pdf", examDetails.detailed_pdf);
      }

      // POST request to create a new exam
      const response = await axios.post(
        `${baseURL}/TPA_exams_create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        customAlert("", "Exam Created Successfully!", "success");
        setDataBaseUpdate(true);
        resetForm(); // Reset the form after submission
        handleManageExamClose();
      }
    } catch (error) {
      customAlert(
        "Error",
        "Failed to create a new exam. Please try again.",
        "error"
      );
      console.error(error);
    }
  };

  const handleUpdate = async () => {

    try {
      const updatedExamDetails = {
        exam_name: examDetails.exam_name,
        exam_category: examDetails.exam_category,
        overview: examDetails.overview,
        syllabus: examDetails.syllabus,
        eligibility: examDetails.eligibility,
        fees: {
          general: examDetails.fees.general,
          sc: examDetails.fees.sc,
          st: examDetails.fees.st,
          others: examDetails.fees.others,
        },
        top_category: examDetails.top_category,
        state_or_department: examDetails.state_or_department,
        authority_url: examDetails.authority_url,
        created_by: LoginEmail,
        created_date: new Date().toISOString(),
        is_approved: false,
      };

      // Include files only if they exist
      if (examDetails.logo_image) {
        updatedExamDetails.logo_image = examDetails.logo_image;
      }
      
      if (examDetails.detailed_pdf instanceof File) {
        updatedExamDetails.detailed_pdf = examDetails.detailed_pdf; // Include only if it's a file object
      } else if (typeof examDetails.detailed_pdf === "string") {
        updatedExamDetails.detailed_pdf = examDetails.detailed_pdf; // Include if it's a valid URL
      }

      // Make the PUT request to update the exam
      const response = await axios.put(
        `${baseURL}/TPA_exams_update/${examDetails.id}`,
        updatedExamDetails
      );

      if (response.status === 200) {
        customAlert("", "Exam Updated Successfully!", "success");
        setDataBaseUpdate(true);
        resetForm(); // Reset the form after submission
        handleManageExamClose();
      }
    } catch (error) {
      customAlert(
        "Error",
        "Failed to update the exam. Please try again.",
        "error"
      );
      console.error(error);
    }
  };

  const resetForm = () => {
    setExamDetails({
      top_category: "",
      exam_category: "",
      state_or_department: "",
      exam_name: "",
      authority_url: "",
      overview: "",
      syllabus: "",
      eligibility: "",
      fees: {
        general: "",
        sc: "",
        st: "",
        others: "",
      },
    });
  };

  const handlePDFView = (row) => {
    // Construct the full URL to view the resume
    const resumeViewUrl = `${baseURL}/TPA_exams_viewPDF/${row.id}`;

    // Open the resume in a new tab
    window.open(resumeViewUrl, "_blank");
  };

  const handleImageView = (row) => {
    // Construct the full URL to view the resume
    const resumeViewUrl = `${baseURL}/TPA_exams_viewImage/${row.id}`;

    // Open the resume in a new tab
    window.open(resumeViewUrl, "_blank");
  };

  const handlePDFDownload = (row) => {
    const resumeDownloadUrl = `${baseURL}/TPA_exams_downloadPDF/${row.id}`;
    window.location.href = resumeDownloadUrl;
  };

  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View PDF", //Name of the MenuItem
      onClick: handlePDFView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },

    {
      icon: DownloadForOfflineIcon, //icon Name (import from the Mui)
      label: "download PDF", //Name of the MenuItem
      onClick: handlePDFDownload, //Handle the Function(for working ),
      IconColor: "green", //Color of the Icon
    },
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View Image", //Name of the MenuItem
      onClick: handleImageView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
  ];

  const handleEdit = (row) => {
    // Parse fees string into an object
    const feesString = row.fees || "";
    const feesArray = feesString.split(", ");
    
    // Initialize an empty object for the fees
    const fees = {};
  
    // Loop through the feesArray and extract the values
    feesArray.forEach((fee) => {
      const [key, value] = fee.split(": ");
      const formattedKey = key.toLowerCase(); // Convert "General", "SC" to lowercase
      fees[formattedKey] = parseInt(value, 10) || ""; // Set fee value or empty if undefined
    });
  
    setExamDetails({
      id: row.id,
      top_category: row.top_category,
      exam_category: row.exam_category,
      state_or_department: row.state_or_department,
      exam_name: row.exam_name,
      authority_url: row.authority_url,
      overview: row.overview,
      syllabus: row.syllabus,
      eligibility: row.eligibility,
      fees: {
        general: fees.general || "",
        sc: fees.sc || "",
        st: fees.st || "",
        others: fees.others || "",
      },
      logo_image: row.logo_image,
      detailed_pdf: row.detailed_pdf || null,
    });
  
    setShowCreateExam(true); 
  };

  return (
    <div>
      {!showCreateExam ? (
        <>
          <TableHeaderTemplate
            columns={renderColumns}
            rows={renderRows}
            handleEdit={handleEdit}
            is_Manage_Button_Required={true}
            handleManageButtonClick={handleAddMoreFunctionality}
            customMenuItems={customMenuItems}
            deleteApi="TPA_exams_delete"
            Manage_button_name="Add Exam"
            SetDataBaseUpdate={setDataBaseUpdate}
            switchColumnName="is_approved"
            swithcUpdateApi="TPA_exams_updateIsApproved"
            DefaultColumnNames={[
              "top_category",
              "exam_category",
              "state_or_department",
              "exam_name",
              "logo_image",
              "detailed_pdf",
              "authority_url",
              "overview",
              "syllabus",
              "eligibility",
              "fees",
              "created_by",
              "modified_by",
              "created_date",
              "modified_date",
              "is_approved",
            ]}
          />
          <CreateExamModal
            open={openManageExam}
            close={handleManageExamClose}
            handleAddExamClick={handleAddExamClick}
          />
        </>
      ) : (
        <div className="create-exam-container">
          <div className="exam-header-buttons">
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleManageExamClose}
            >
              <ArrowBackIosIcon />
              <span className="edt_saveBtn">Back</span>
            </Button>
            <h4>{examDetails.id ? "Edit Exam" : "Create Exam"}</h4>

            {/* Conditionally render Save or Update button */}
            {examDetails.id ? (
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleUpdate} // This will trigger update for the existing exam
              >
                <SaveIcon />
                <span className="edt_saveBtn">Update</span>
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleSubmit} // This will trigger save for a new exam
              >
                <SaveIcon />
                <span className="edt_saveBtn">Save</span>
              </Button>
            )}
            <hr />
          </div>
          <CreateExam
            setExamDetails={setExamDetails}
            examDetails={examDetails}
          />
        </div>
      )}
    </div>
  );
}

export default TPAExamMain;
