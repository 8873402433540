import React from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function UploadFromTemplate({ DBApi, close, update }) {
  //--------------------------------------------------------------------------------
  // Array of valid months
  //--------------------------------------------------------------------------------

  const validMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //--------------------------------------------------------------------------------
  // Upload the excel or from the csv
  //--------------------------------------------------------------------------------
  const handleUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the data is present in the first sheet (index 0)
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to JSON format
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Required columns
      const requiredColumns = ["Year", "Month", "Events"];

      // Maintain a set for unique Year-Month combinations to avoid duplicates
      const uniqueCombinations = new Set();

      // Modify JSON data to include only the required columns and additional fields
      const modifiedData = jsonData.reduce((acc, row) => {
        const modifiedRow = {};

        // Add required columns with values from the uploaded data
        requiredColumns.forEach((header) => {
          modifiedRow[header] = row[header] || ""; // Set value from uploaded data or empty string if missing
        });

        // Ensure Year and Month are available
        if (!modifiedRow["Year"] || !modifiedRow["Month"]) {
          return acc; // Skip rows missing Year or Month
        }

        // Convert the Month to Title Case
        modifiedRow["Month"] = toTitleCase(modifiedRow["Month"]);

        // Validate Month
        if (!validMonths.includes(modifiedRow["Month"])) {
          // customAlert("", `Invalid Month: ${modifiedRow["Month"]}. Must be one of: ${validMonths.join(', ')}`, "warning");
          return acc; // Skip if the Month is not valid
        }

        // Skip duplicates based on Year-Month combination
        const yearMonthKey = `${modifiedRow["Year"]}-${modifiedRow["Month"]}`;
        if (uniqueCombinations.has(yearMonthKey)) {
          return acc; // Skip if the combination already exists
        }
        uniqueCombinations.add(yearMonthKey);

        // Convert Events field from comma-separated string to array of objects
        const events = modifiedRow["Events"];
        if (events) {
          modifiedRow["Events"] = events.split(",").map((event) => {
            const trimmedEvent = event.trim();
            return { value: trimmedEvent.toLowerCase(), label: trimmedEvent };
          });
        } else {
          modifiedRow["Events"] = []; // Handle case where Events field is empty
        }

        // Calculate and add NoOfHolidays field
        modifiedRow["NoOfHolidays"] = modifiedRow["Events"].length;

        // Add additional fields
        modifiedRow["Created_by"] = localStorage.getItem("HTES_user_id");
        modifiedRow["Created_date"] = new Date().toISOString();
        modifiedRow["Modified_by"] = localStorage.getItem("HTES_user_id");
        modifiedRow["Modified_date"] = new Date().toISOString();
        modifiedRow["UTC_Time"] = new Date().toISOString();

        acc.push(modifiedRow);
        return acc;
      }, []);

      if (modifiedData.length === 0) {
        customAlert(
          "",
          "No valid data to upload. Please check the file.",
          "warning"
        );
        return;
      }

      try {
        // Send the modified JSON data to the backend
        const response = await axios.post(baseURL + DBApi, modifiedData);
        console.log(response);

        // Check if the response status is OK (200)
        if (response.status === 200) {
          customAlert("", "Holidays uploaded successfully", "success");
          update(true);
          close();
        } else {
          customAlert(
            "",
            "Error uploading data. Please check the template and database connectivity",
            "error"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          customAlert(
            "",
            "Duplicate record found. Please check and try again.",
            "warning"
          );
        } else {
          customAlert(
            "",
            "Error uploading data. Please check the template and database connectivity",
            "error"
          );
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };

  //--------------------------------------------------------------------------------
  // Utility function to convert a string to Title Case
  //--------------------------------------------------------------------------------

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div>
      <input
        type="file"
        accept=".xlsx, .xls , .csv"
        onChange={handleUpload}
        style={{ display: "none" }}
        id="upload-file"
      />
      <label htmlFor="upload-file">
        <div>
          <FileUploadIcon
            className="course-name-template-addboxixon-style"
            color="primary"
            fontSize="large"
          />
          <br />
          <span>
            Upload
            <br />
            Holidays
          </span>
        </div>
      </label>
    </div>
  );
}

export default UploadFromTemplate;
