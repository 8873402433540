import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Modal, IconButton, Tooltip, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./coursecreatetemplate.css";

const ContentTemplate = ({ selectedCourse,courseContent, setCourseContent, title }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState();
  const [header, setHeader] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [expanded, setExpanded] = useState([]); // State to track expanded items
  

  // Handle open and close the modal (text editor)
  const handleOpen = () => {
    setOpen(true);
    setText("• ");
    setHeader("");
    setEditingIndex(null); // Reset editing index
  };

  const handleClose = () => {
    setOpen(false);
    setText("");
    setHeader("");
    setEditingIndex(null); // Reset editing index
  };

  // Handle the text change in the text area
  const handleTextChange = (e) => {
    const value = e.target.value;
    setText(value);
  };

  // Handle key down events for text area (supporting bullet points)
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const { selectionStart, selectionEnd, value } = e.target;
      const beforeCaret = value.slice(0, selectionStart);
      const afterCaret = value.slice(selectionEnd);
      setText(`${beforeCaret}\n• ${afterCaret}`);
      setTimeout(() => {
        e.target.selectionStart = selectionStart + 3;
        e.target.selectionEnd = selectionStart + 3;
      }, 0);
    }
  };

  // Save the Text as an object with title and content
  const handleSave = () => {
    const points = text
      .split("\n")
      .filter((point) => point.trim() !== "")
      .map((point) => point.replace(/^•\s*/, ""));

    const newContent = [...courseContent];
    const contentItem = { header, points };

    if (editingIndex !== null) {
      newContent[editingIndex] = contentItem;
    } else {
      newContent.push(contentItem);
    }

    setCourseContent(newContent);
    handleClose();
  };

  // Edit existing content
  const handleEdit = (index) => {
    setEditingIndex(index);
    setHeader(courseContent[index].header);
    const existingPoints = courseContent[index].points.map((point) => `• ${point}`).join("\n");
    setText(existingPoints);
    setOpen(true);
  };

  // Delete a section by index
  const handleDelete = (index) => {
    const newContent = courseContent.filter((_, i) => i !== index);
    setCourseContent(newContent);
  };

  // Toggle expand/collapse
  const handleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      if (newExpanded.includes(index)) {
        newExpanded.splice(newExpanded.indexOf(index), 1);
      } else {
        newExpanded.push(index);
      }
      return newExpanded;
    });
  };

  return (
    <>
      {/* Heading and Buttons */}
      <div className="Header-tab-course-name">
        <div className="overviewtemplate-course-name-and-icon-align">
          <h6 className="overview-header-of-the-container-color">{title}</h6>
          <div className="overviewtemplate-icons-alignment">
            <Tooltip title="Add" arrow>
              <IconButton onClick={handleOpen}>
                <AddIcon className="buttoncolorEdit" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div >
        {courseContent.map((item, index) => (
          <div key={index} className="header-content-holds-container">
            <div className="content-header-title" onClick={() => handleExpand(index)}>
              <div className="content-dropdown-and-header" >
              <IconButton >
                {expanded.includes(index) ? <ExpandLessIcon className="expand-icon-color" /> : <ArrowForwardIosIcon className="expand-icon-color-arrow" />}
              </IconButton>
              <h6 className="content-header">{item.header}</h6>
              </div>
              <div>
                <Tooltip title='Edit' arrow>
                <IconButton onClick={() => handleEdit(index)}>
                  <EditIcon className="buttoncolorEdit" />
                </IconButton>
                </Tooltip>
                <Tooltip title='Delete' arrow>
                <IconButton onClick={() => handleDelete(index)}>
                  <DeleteIcon className="buttoncolorEdit" color="error" />
                </IconButton>
                </Tooltip>
              </div>
            </div>
            {expanded.includes(index) && (
              <div className="content-points-descriptions">
                <ul>
                  {item.points.map((point, pointIndex) => (
                    <div className="align-the-dropdown-of-conetent">
                     <div> <FiberManualRecordIcon className="buttetpoints" /></div>&nbsp;
                      <li key={pointIndex}> {point}</li>
                      </div>
                    
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        className="Modal-screen-center-alignment-overview-template"
      >
        <div className="modal-container-overview-template">
          <div className="modal-inside-content-container-overview-template">
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner-overview-template"
              onClick={handleClose}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol-overview-template" />
            </IconButton>
            <div className="modal-header-and-switch-container-overview-template">
              <h5 className="modal-header-name-overview-template">
                {title}-{selectedCourse}
              </h5>
            </div>
            <div className="title-textfiled-conatiner">
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Content Header"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>
            <div className="course-content-template-textarea">
              <textarea
                className="textarea-course-content-template"
                value={text}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter content here..."
              />
            </div>
            <div className="Save-Button-Alignment-container-overview-template">
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleSave}
              >
                <SaveIcon className="buttonicons" />
                {editingIndex !== null ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContentTemplate;
