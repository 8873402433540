import React from "react";
import {
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { TextField, Button, Autocomplete } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from '@mui/material/InputAdornment';
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";

function StudentBasicDetailModal({ 
  togglingModal,
  pfname,         setPfname,
  mobile,         setMobile,
  expYear,        setExpYear,
  expMonth,       setExpmonth,
  noticePeriod,   setNoticePeriod, 
  location,        setLocation,
  expSalary,      setExpSalary,
  fetchUserData,
}) {
  
  
  //--------------------------------------------------------------------------------
  // State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem('HTES_user_id') || '';
  
//--------------------------------------------------------------------------------
//saveProfileData
//--------------------------------------------------------------------------------

  async function saveProfileData() {
    try {
      const formData = {
        email: userEmail, 
        name: pfname, 
        expY: expYear ? expYear : null, 
        expM: expMonth ? expMonth : null, 
        phone: mobile, 
        loc: location, 
        notice: noticePeriod ? noticePeriod : null, 
        curSal: expSalary, 
      };

      const response = await fetch(`${baseURL}/updateProfile`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        customAlert("","Profile Data Updated","success")
        .then(() => {
          fetchUserData();
          togglingModal();
        });
      } else {
        console.error("Error updating profile1:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating profile2:", error);
    }
  }

//--------------------------------------------------------------------------------
// Dropdown Options
//--------------------------------------------------------------------------------
  const noticePeriodOptions = [
    { value: "15 Days or less", label: "15 Days or less" },
    { value: "1 Month", label: "1 Month" },
    { value: "2 Months", label: "2 Months" },
    { value: "3 Months", label: "3 Months" },
  ];

  return (
    < >

      <div className="pmModalContainer">
        <MDBRow>
        {/* _______________________ Name Input _____________________ */}
          <MDBCol className="pmBasFields"
          
          >
            <label className="pmLabel">Name</label>
            <div className="formProfile">

             <TextField
                fullWidth
                value={pfname || ""}
              onChange={(e) => setPfname(e.target.value)}
              />
              </div>
              <br></br>
          </MDBCol>
        {/* _______________________ Mobile Input ___________________ */}
          <MDBCol >
            <label className="pmLabel">Mobile</label>
            <TextField
              fullWidth
              type="tel"  // Using 'tel' to better indicate phone number input
              value={mobile || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || (/^\d{0,10}$/.test(value))) {
                  setMobile(value);
                }
              }}
              inputProps={{ maxLength: 10 }}
            />

             
            <br></br>
            <br></br>
          </MDBCol>
        </MDBRow> 
        {/* _______________________ Total Experience _______________ */}
        <div className="">
          <label className="pmLabel">Total Experience</label>
          <br></br>
          
          <MDBRow>
            <MDBCol className="pmBasFields">
          <label className="pmLabel">Years</label>
             
                <TextField
                    fullWidth
                    value={expYear || ''}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setExpYear(inputValue);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      
                      },
                      endAdornment: <InputAdornment position="end">Year(s)</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    />
            <br></br>
            </MDBCol> 
            <MDBCol>
            <label
            className="pmLabel"
          >
            Months
          </label>
          
             <TextField
                    fullWidth
                    value={expMonth || ''}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && (inputValue) <= 12) {
                        setExpmonth(inputValue);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      
                      },
                      endAdornment: <InputAdornment position="end">Month(s)</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    />
            </MDBCol>
          </MDBRow>
          <br></br>
        </div>

        <MDBRow>
        {/* _______________________ Location ________________________ */}
          <MDBCol  className="pmBasFields">
            <label className="pmLabel">Location</label>
            <div className="formProfile">
              
              <TextField
                fullWidth
                value={location || ""}
                onChange={(e) => setLocation(e.target.value)} 
              />
            </div>
            <br></br>
          </MDBCol>

        {/* _______________________ Notice Period ___________________ */}
          <MDBCol>
            <label className="pmLabel">Notice Period</label>
            <Autocomplete
                disablePortal
                id="exp-year-autocomplete"
                options={noticePeriodOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) =>  <TextField  {...params} />
                 } 
                value={noticePeriodOptions.find(option => option.label === noticePeriod) || null}
                onChange={(event, newValue) => setNoticePeriod(newValue?.label || '')}
              
              />
       
            <br></br>
          </MDBCol>
        </MDBRow>
        {/* _______________________ Current Salary __________________ */}
        <MDBRow>
          <MDBCol className="pmBasFields">
            <label className="pmLabel">Current Salary</label>
            <div className="formProfile">
             
               <TextField
                    fullWidth
                    value={expSalary || ''}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setExpSalary(inputValue);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      },
                      endAdornment: <InputAdornment position="end">Salary</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    />
              
            </div>
            <br></br>
          </MDBCol>
          <MDBCol></MDBCol>

        {/* _______________________ Save and Cancel ___________________ */}
        </MDBRow>


      
       
      </div>
        {/* _______________________       Save        _______________ */}
        <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={saveProfileData}
        >
          <SaveIcon className="buttonicons" />
          Save
        </Button>
      </div>
     </>
  );
}

export default StudentBasicDetailModal;
