import React, { useState,  useRef } from "react";
import "./NewScrollspy.css";
import PmTab2Resume from "./PmTab2Resume";
import PmTab3ResumeHeadline from "./PmTab3ResumeHeadline";
import PmTab4KeySkills from "./PmTab4KeySkills";
import Stscroll9ProfileSummary from "./PmTab9ProfileSummary";
import PmTab5Employment from "./PmTab5Employment";
import PmTab6Education from "./PmTab6Education";
import PmTab7ITSkills from "./PmTab7ITSkills";
import PmTab8Projects from "./PmTab8Projects";
import PmTab11CareerProfile from "./PmTab11CareerProfile";
import PmTab12PersonalDetails from "./PmTab12PersonalDetails";

const PmScrollspy = ({ callPercentage }) => {
  const sections = [
    {
      id: "sec-1",
      title: " Resume",
      content: <PmTab2Resume callPercentage={callPercentage} />,
    },
    {
      id: "sec-2",
      title: " Resume Headline",
      content: <PmTab3ResumeHeadline callPercentage={callPercentage} />,
    },
    {
      id: "sec-3",
      title: " Keyskills",
      content: <PmTab4KeySkills callPercentage={callPercentage} />,
    },
    {
      id: "sec-4",
      title: " Employment",
      content: <PmTab5Employment callPercentage={callPercentage} />,
    },
    {
      id: "sec-5",
      title: " Education",
      content: <PmTab6Education callPercentage={callPercentage} />,
    },
    {
      id: "sec-6",
      title: " IT Skills",
      content: <PmTab7ITSkills callPercentage={callPercentage} />,
    },
    {
      id: "sec-7",
      title: " Projects",
      content: <PmTab8Projects callPercentage={callPercentage} />,
    },
    {
      id: "sec-8",
      title: " Profile Summary",
      content: <Stscroll9ProfileSummary callPercentage={callPercentage} />,
    },
    {
      id: "sec-9",
      title: " Career Profile",
      content: <PmTab11CareerProfile callPercentage={callPercentage} />,
    },
    {
      id: "sec-10",
      title: " Personal details",
      content: <PmTab12PersonalDetails callPercentage={callPercentage} />,
    },
  ];
  //---------------------------------------------------------------------
  //  State Handles
  //---------------------------------------------------------------------

  const [activeTab, setActiveTab] = useState("sec-1");

  //---------------------------------------------------------------------
  //  Handle Active Tab
  //---------------------------------------------------------------------
  const sectionRefs = useRef([]);

  const handleTabClick = (tabId, index) => {
    setActiveTab(tabId);
    // Scroll the content section into view
    // sectionRefs.current[index]?.scrollIntoView({ behavior: "smooth" });

    if (index == 0 || index == sections.length - 1) {
      sectionRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
    } else {
    //   Approach 1   // (-1) show previous index
    // sectionRefs.current[index - 1]?.scrollIntoView({ behavior: "smooth" });
    
    //   Approach 2  // 
    const previousElement = sectionRefs.current[index - 1];
    const contentContainer = document.querySelector(".scrollspyContentSectionRight");

    if (previousElement && contentContainer) {
      // Scroll the previous element into view
      previousElement.scrollIntoView({ behavior: "smooth" });

      // Calculate the new scroll position for the content container
      const rect = previousElement.getBoundingClientRect();
      const containerRect = contentContainer.getBoundingClientRect();
      const offset = 50; // The amount of the offset from the top

      // Calculate the new scroll position
      const newScrollTop = contentContainer.scrollTop + (rect.top - containerRect.top + offset);

      // Use a setTimeout to ensure the initial scrollIntoView completes before adjusting
      setTimeout(() => {
        contentContainer.scrollTo({
          top: newScrollTop,
          behavior: 'smooth'
        });
      }, 300); // Adjust the delay if needed
    }
  }



};

  return (
    <div className="mainContainerNewScroll">
      {/*  ____________________________________ Left Side Scroll Tabs _________________________________ */}
      <nav className="scrollspyTabLeft">
        <ul className="scrUL">
          {sections.map((section, index) => (
            <li key={section.id} className="scrollspyTabItem">
              <span onClick={() => handleTabClick(section.id, index)}>
                <a
                  className={
                    activeTab === section.id
                      ? "profile_Scroll_Active"
                      : "profile_Scroll"
                  }
                >
                  {section.title}
                </a>
              </span>
            </li>
          ))}
        </ul>
      </nav>

      {/*  ____________________________________ Right Side Scroll contents ____________________________  */}
      <div className="scrollspyContentSectionRight">
        {sections.map((section, index) => (
          <section
            id={section.id}
            key={section.id}
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <div
              className={
                section.id === activeTab
                  ? "profileScrollTabsNew pmActiveContent"
                  : "profileScrollTabsNew"
              }
            >
              {section.content}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default PmScrollspy;
