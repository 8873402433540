import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import './ViewCareer.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ViewCareer() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [resumeData, setResumeData] = useState({ resumeName: '', resumeUrl: '' });

    useEffect(() => {
        // Retrieve resume details from localStorage
        const storedResumeData = localStorage.getItem('resumeData');
        if (storedResumeData) {
            setResumeData(JSON.parse(storedResumeData));
        }
    }, []);

    const handleBack = () => {
        // Clear resume details from localStorage when navigating back
        localStorage.removeItem('resumeData');
        navigate(-1);  // Go back to the previous page
    };

    return (
        <div className="career-container">
            <Card className="career-card">
                <div className="career-header">
                    <Button onClick={handleBack} variant="contained" className="career-back-button">
                        <ArrowBackIcon /> 
                        <span className="saveBtn">Back</span>
                    </Button>
                    <h2 className="career-title">{resumeData.resumeName || "Resume"}</h2>
                </div>
                <div className="career-content">
                    {resumeData.resumeUrl ? (
                        <div>
                            <p>Your resume is ready to view or download:</p>
                            <a 
                                href={resumeData.resumeUrl}  // Link to the resume URL
                                download={resumeData.resumeName}  // Force download with the resume name
                                target="_blank"  // Open in a new tab
                                rel="noopener noreferrer"
                                className="resume-link"
                            >
                                Click here to view or download your resume
                            </a>
                        </div>
                    ) : (
                        <p>No resume available</p>
                    )}
                </div>
            </Card>
        </div>
    );
}

export default ViewCareer;
