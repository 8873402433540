import React from "react";
import QASpage from "./Components/QASPage/QASpage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminDashboard from "./Components/DashboardAdmin/1AdminDashboard";
import ParentDashboard from "./Components/DashboardParent/1ParentDashboard";
import SuperAdminDashboard from "./Components/DashboardSuperAdmin/1SADashboard";
import PoDashboard from "./Components/DashboardProductOwner/1PoDashboard";
import StudentDashboard from "./Components/DashboardStudent/1StudentDashboard";
import TeacherDashboard from "./Components/DashboardTeacher/1TeacherDashboard";
import SignIn from "./Components/Common/SignIn";
import SignUp from "./Components/Common/SignUP";
import SignOut from "./Components/Common/SignOut";
import Home from "./Components/PublicPages/Home";
import ForgotPassword from "./Components/Common/ForgotPassword";
import HeaderInput from "./Components/PublicPages/TestSeries/HeaderInput";
import { TestSideNav } from "./Components/TestPage/TestSideNav";
// import QuizDashboard from "./Components/QuizPage/QuizDashboard1";
import ContentDashboard from "./Components/ContentPage/ContentDashboard";
import HomeLandingPage from "./Components/PublicPages/HomeLandingPage";
import QABlog_landPage from "./Components/QABlog/QABlog_landPage";
import QuestionDetail from "./Components/QABlog/QABlog_QuestionDetails";
import CA_Editor from "./Components/CurrentAffairs/CA_Editor";
import ContentEditor from "./Components/ContentPage/ContentEditor";
import Editor1 from "./Components/Editor/Editor1";
import PageLoading from "./Components/PageLoading/PageLoading";
import CourseCreationPage from "./Components/TPACoursePage/CourseCreationTemplate/CourseCreationPage";
import NewScrollspy from "./Components/ProfileManagement/NewScrollspy";
import SampleCode from "./Components/Templates/ImageCroper/SampleCode";
import JobPostMain from "./Components/JobPortal/JobPost/JobPostMain";
import EmployeePhase1Info from "./Components/Employee/EmployesInfoSendForm/EmployeePhase1Info";
import EmployeePhase1InfoEdit from "./Components/Employee/EmployesDetails/EmployeePhase1InfoEdit";
import EmployeePhase1InfoDocumentView from "./Components/Employee/EmployesDetails/EmployeePhase1InfoDocumentView";
import EmployeePhase2InfoRejectDocuments from "./Components/Employee/EmployesInfoSendForm/EmployeePhase2InfoRejectDocuments";
import HRandSalaryStructureLandibgPage from "./Components/Employee/HRFillableForm/HRandSalaryStructureLandibgPage";
import ViewCareer from "./Components/HtesPortfolio/ViewCareer";
import ContentMain from "./Components/ContentManagement/ContentRender/ContentMain";
import TPAExamMain from "./Components/TPAExam/TPAExamMain";
import AdvertisementModal from "./Components/ContentManagement/Advertisement/AdvertisementModal";
import QuizMainLandingPage from "./Components/QuizPage/QuizMainLandingPage";
import QuizDashboard from "./Components/QuizPage/QuizDashboard1";




function Main() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<HomeLandingPage />} />
          <Route path="/dashBoard" element={<AdminDashboard />} />
          <Route path="/productOwner" element={<PoDashboard />} />
          <Route path="/headerInput" element={<HeaderInput />} />
          <Route path="/superadmin" element={<SuperAdminDashboard />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/student" element={<StudentDashboard />} />
          <Route path="/parent" element={<ParentDashboard />} />
          <Route path="/teacher" element={<TeacherDashboard />} />
          <Route path="/examQasPage" element={<QASpage />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/test" element={<TestSideNav />} />
          {/* <Route path="/quiz" element={<QuizDashboard />} /> */}
          <Route path="/test" element={<TestSideNav />} />
          {/* <Route path="/quiz" element={<QuizDashboard />} /> */}
          <Route path="/content" element={<ContentDashboard />} />
          <Route path="/Content_Editor" element={<ContentEditor/>} />
          <Route path="/CA_Editor" element={<CA_Editor/>} />
          <Route path="/scroll" element={<PageLoading/>} />
          <Route path="/qa_blog_questions" element={<QABlog_landPage/>} />
          <Route path="/qa_blog_questions/:id" element={<QuestionDetail />} />
          <Route path="/editor" element={<Editor1/>} />
          <Route path="/nsc" element={<NewScrollspy/>} />
          <Route path="/dashBoard/create-course" element={<CourseCreationPage/>} />
          <Route path="/imageCroper2" element={<SampleCode/>} />
          <Route path="/jpost" element={<JobPostMain/>} />
          <Route path="/Employee_Phase1_Info" element={<EmployeePhase1Info/>} />
          <Route path="/Employee_Phase1_Info_edit" element={<EmployeePhase1InfoEdit/>} />
          <Route path="/Employee_Phase1_Info_Document_View" element={<EmployeePhase1InfoDocumentView/>} />
          <Route path="/Employee_Phase1_Document_Rejected_Form" element={<EmployeePhase2InfoRejectDocuments/>} />
          <Route path="/Hr_Form_and_Salary_Structure" element={<HRandSalaryStructureLandibgPage/>} />
          {/* <Route path="/My_Salary_Structure" element={<MySalaryStructure/>} /> */}
          <Route path="/view-resume/:id" element={<ViewCareer />} />
          <Route path="/contentMain" element={<ContentMain/>}/>
          <Route path='/quizLandingPage' element={<QuizMainLandingPage/>}/>
          {/* <Route path="/tpa_exam" element={<TPAExamMain/>} /> */}
          <Route path="/Ads" element={<AdvertisementModal/>}/>
          <Route path='/quiz' element={<QuizDashboard/>}/>
          </Routes>
      </Router>
    </div>
  );
}

export default Main;
