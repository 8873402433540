import React, { useState, useEffect } from "react";
import TableHeaderTemplate from "../../GeneralTemplateComponent/TableHeaderTemplate";
import { customAlert } from "../../SweetAlertCommon/Custom";
import AddQuestionPaperModal from "./AddQuestionPaperModal";
import axios from "axios";
import { baseURL } from "../../../http";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import VisibilityIcon from "@mui/icons-material/Visibility";

function TPAPreviousQuestionMain() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);
  const [openmanagePaper, setManagePaper] = useState(false);
  const LoginEmail = localStorage.getItem("HTES_user_id");
  const [openEditMode, setOpenEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState();
  const [CloseActionDialog, setCloseActionDialog] = useState(false);//Need to close the Action menu dropdown(once fuctionlaity triggered)

  const handleAddMoreFunctionality = () => {
    if (LoginEmail) {
      console.log("openmodel")
      setOpenEditMode(true); // Open the modal
    } else {
      customAlert("", "Please Login To Add Your Blog", "warning");
    }
  };

  // const handleEdit = (rowData) => {
  //   setEditData(rowData); 
  //   if (LoginEmail) {
  //     setOpenEdit(true); 
  //   } else {
  //     customAlert("", "Please Login To Edit Your Blog", "warning");
  //   }
  // };

// Handle edit function (triggered from table action icon)
const handleEdit = (rowData) => { 
  console.log("Edit button clicked for content:", rowData); 
  setInitialFormData(rowData); // Set the initial form data to the selected row's data
  setOpenEditMode(true); // Open the edit modal
  
};


  useEffect(() => {
    const fetchQuestionPapers = async () => {
      try {
        const response = await axios.get(`${baseURL}/previous-question-papers-show-all`);
        const data = response.data;

        // Extract column names from data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Unique ID for table row
          ...row,
        }));
        setRenderRows(rows);
        setDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching question papers data:", error);
        customAlert("Error", "Failed to load question papers data", "error");
      }
    };

    fetchQuestionPapers();
  }, [dataBaseUpdate]);

  const handlePDFView = (row) => {
    // Construct the full URL to view the resume
    const resumeViewUrl = `${baseURL}/question-papers-view/${row.id}`;

    // Open the resume in a new tab
    window.open(resumeViewUrl, "_blank");
  };

  const handlePDFDownload = (row) => {
    const resumeDownloadUrl = `${baseURL}/question-papers-download/${row.id}`;
    window.location.href = resumeDownloadUrl;
  };
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };
  const customMenuItems = [
    {
      icon: VisibilityIcon, //icon Name (import from the Mui)
      label: "View PDF", //Name of the MenuItem
      onClick: handlePDFView, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },

    {
      icon: DownloadForOfflineIcon, //icon Name (import from the Mui)
      label: "download PDF", //Name of the MenuItem
      onClick: handlePDFDownload, //Handle the Function(for working ),
      IconColor: "green", //Color of the Icon
    },
  ];

  return (
    <div>
      <TableHeaderTemplate
        columns={renderColumns}
        rows={renderRows}
        handleEdit={handleEdit}
        is_Manage_Button_Required={true}
        handleManageButtonClick={handleAddMoreFunctionality}
        customMenuItems={customMenuItems}
        deleteApi="previous-question-papers-delete"
        Manage_button_name="Previous Papers"
        SetDataBaseUpdate={setDataBaseUpdate}
        switchColumnName="IsApproved"
        swithcUpdateApi="previous-question-papers-isapprove"
        DefaultColumnNames={['question_paper_name', 'question_paper_year', 'access_type']} 
        CloseActionDialog={handleActionClose}
      />

      
        <AddQuestionPaperModal
          showModal={openEditMode }
          setShowModal={setOpenEditMode}
          initialFormData={initialFormData}
          setInitialFormData={setInitialFormData}
        />
      
    </div>
  );
}

export default TPAPreviousQuestionMain;
