
import React from 'react';
import { Paper, Typography, Badge, Divider, Button, Tooltip, Grid } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import './TestCard.css'; // Import CSS file

const TestCard = (props) => {
  const { logo, badgeText, heading, subtitles, language, listItems, buttonText } = props;

  const renderLanguages = () => {
    const languagesArray = language.split(',');

    if (languagesArray.length > 2) {
      const hiddenLanguages = languagesArray.slice(2).join(', ');
      return (
        <Tooltip title={hiddenLanguages} enterTouchDelay={0} arrow>
          <Typography variant="subtitle1" style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {languagesArray.slice(0, 2).join(', ')} +{languagesArray.length - 2} More
          </Typography>
        </Tooltip>
      );
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Paper elevation={5} className="card-container" style={{ marginBottom: '10px',border: '3px solid #e0e0e0'}}>
        <div className="logo-and-badge-container">
          {logo && (
            <div className="logo-container">
              <img src={logo} alt="Logo" style={{ width: '50px' }} />
            </div>
          )}
          {badgeText && (
            <div className="badge-container">
              <Badge
                badgeContent={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BoltIcon fontSize="small" style={{ marginRight: '4px', color: 'yellow' }} />
                    <Typography variant="body2">{badgeText}</Typography>
                  </div>
                }
                color="primary"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className="custom-badge"
              />
            </div>
          )}
        </div>
        <div className="content-container">
          <Typography variant="body1" gutterBottom>{heading}</Typography>
          {subtitles.map((subtitle, index) => (
            <Typography key={index} variant="body2" className={subtitle.includes("Free Tests") ? "greenSubtitle" : ""}>{subtitle}</Typography>
          ))}
          <Divider style={{ margin: '10px 0' }} />
          <div className={`test-lang`}>
            <Typography variant="subtitle1"><img src="https://tse4.mm.bing.net/th?id=OIP.3a9y1GWtyftjIR_1_z69VgHaEc&pid=Api&P=0&h=180" alt="Language Image" style={{ display: 'flex', marginRight: '5px', width: '30px', height: '20px', marginLeft: '-30px' }} />
              {renderLanguages()}</Typography></div>
          <Divider style={{ margin: '10px 0' }} />
          <ul>
            {listItems.map((item, index) => (
              <li key={index} className={index === listItems.length - 1 ? 'last-item' : 'item-list'}>{item}</li>
            ))}
          </ul>
          {/* <Button variant="contained" color="primary">{buttonText}</Button> */}
          <a href="/next-page" target="_blank" >
            <Button className="button" variant="contained">{buttonText}</Button>
          </a>
        </div>
      </Paper>
    </Grid>
  );
};

export default TestCard;
