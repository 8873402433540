import React, { useState } from "react";
import { Modal, IconButton, Tooltip, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./coursecreatetemplate.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import parse, { domToReact } from "html-react-parser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const DescriptionTemplate = ({ selectedCourse,setContent, title, content }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [isExpand,setIsExpand]=useState(false)

  // Handle open and close the modal (text editor)
  const handleOpen = () => {
    setOpen(true);
    setText(content.join("\n"));
  };

  const handleClose = () => {
    setOpen(false);
    setText("");
  };

  // Handle the text change in the text editor
  const handleTextChange = (value) => {
    setText(value);
  };

  // Save the text to the form of array in the states
  const handleSave = () => {
    setContent([text]); // Save content as an array containing HTML string
    handleClose();
  };

  // Delete the complete section data
  const handleDelete = () => {
    setContent([]);
  };

  // Function to render the content
  const renderContent = () => {
    return content.map((point, index) => {
      const parsedContent = parse(point, {
        replace: (domNode) => {
          if (domNode.name === "li") {
            return (
              <li key={index}>
                <FiberManualRecordIcon className="buttetpoints" />
                {domToReact(domNode.children)}
              </li>
            );
          }
        },
      });
      return <div key={index}>{parsedContent}</div>;
    });
  };

       //--------------------------------------------------------------------------------
    // Expan and Hide the grid div
    //--------------------------------------------------------------------------------
    const handleExpand=()=>{
      setIsExpand((prev)=>!prev)
    }

  return (
    <>
      {/* Heading and Buttons */}
      <div className="Header-tab-course-name">
        <div className="overviewtemplate-course-name-and-icon-align" onClick={handleExpand}>
        <div className="toggleicon-expand-more-less">
        <IconButton className="expand-hide-button-alignment">
                  {isExpand ? (
                    <ExpandLessIcon className="expand-icon-color" />
                  ) : (
                    <ExpandMoreIcon className="expand-icon-color" />
                  )}
                </IconButton>
          <h6 className="overview-header-of-the-container-color">{title}</h6>
          </div>
          <div className="overviewtemplate-icons-alignment">
            {content.length === 0 ? (
              <Tooltip title="Add" arrow>
                <IconButton onClick={() => setOpen(true)}>
                  <AddIcon className="buttoncolorEdit" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Edit" arrow>
                <IconButton onClick={handleOpen}>
                  <EditIcon className="buttoncolorEdit" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete" arrow>
              <IconButton onClick={handleDelete}>
                <DeleteIcon className="buttoncolorEdit" color="error" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {isExpand &&  (
        <div className="description-container">{renderContent()}</div>)}
      </div>
      {/* Content Editor */}
      <Modal
        open={open}
        onClose={handleClose}
        className="Modal-screen-center-alignment-overview-template"
      >
        <div className="modal-container-overview-template">
          <div className="modal-inside-content-container-overview-template">
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner-overview-template"
              onClick={handleClose}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol-overview-template" />
            </IconButton>
            <div className="modal-header-and-switch-container-description-template">
              <h5 className="modal-header-name-description-template">
              {title}-{selectedCourse}
              </h5>
              <h5 className="modal-header-name-description-template2">
              <Tooltip title={selectedCourse} arrow>{title}</Tooltip>
              </h5>
            </div>
            <div className="description-template-textarea">
              <ReactQuill
                className="textarea-description-template"
                value={text}
                onChange={handleTextChange}
                placeholder="Description Start From Here...."
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],                    
                  ],
                }}
                formats={["bold", "italic", "list", "bullet"]}
              />
            </div>
            <div className="description-Save-Button-Alignment-container-overview-template">
              <Button
                id="button-zoom"
                variant="contained"
                color="success"
                onClick={handleSave}
              >
                {content.length === 0 ? (
                  <SaveIcon className="buttonicons" />
                ) : (
                  <UpdateIcon className="buttonicons" />
                )}
                {content.length === 0 ? "Save" : "Update"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DescriptionTemplate;
