import React, { useState, useEffect } from "react";
import { baseURL } from "../../../http";
import TableHeaderTemplate from "../../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { customAlert } from "../../SweetAlertCommon/Custom";
import ManageCourseTemplate from "./ManageCourseTemplate";

function Coursetemplate2LandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set to true when functionality completed and reset to false once API call is made
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown (once functionality triggered)
  const [openMangaeCourse, setOpenManageCourse] = useState(false);
  const [open, setOpen] = useState(false);
  const [showCoursepage, setCoursePage] = useState(false);
  const [editData, setEditData] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  //--------------------------------------------------------------------------------
  // open and close the modal
  //--------------------------------------------------------------------------------
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //--------------------------------------------------------------------------------
  // UseEffect to render the data from the backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/coursepage_fetch_all`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  // Additional Functions if the custom menu is added (customMenuItems)
  //--------------------------------------------------------------------------------

  const handleClone = (row) => {
    const clonedRow = {
      ...row,
      CourseName: `${row.CourseName}_Copy`, // Add "_Copy" to the course name or any other unique identifier
    };
    saveClonedRow(clonedRow);
  };

  //--------------------------------------------------------------------------------
  // Save the cloned row
  //--------------------------------------------------------------------------------
  const saveClonedRow = async (clonedRow) => {
    const formData = new FormData();
    Object.keys(clonedRow).forEach((key) => {
      formData.append(key, clonedRow[key]);
    });

    try {
      await axios.post(`${baseURL}/coursepage`, formData);
      customAlert("", "Course cloned successfully", "success");
      SetDataBaseUpdate(true); // Trigger table data re-fetch
    } catch (error) {
      console.error("Error cloning course:", error);
      customAlert("", "Error cloning course", "error");
    }
  };

  //--------------------------------------------------------------------------------
  // Add the additional custom menus format (in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: ContentCopyIcon, // Icon name (import from MUI)
      label: "Clone", // Name of the MenuItem
      onClick: handleClone, // Handle the function (for working)
    },
  ];

  //--------------------------------------------------------------------------------
  // Handle edit function in edit (Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    setEditData(rowdata);
    setCoursePage(true);
    setIsEdit(true);
  };

  //--------------------------------------------------------------------------------
  // Handle manage button function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setOpenManageCourse(true);
  };

  //--------------------------------------------------------------------------------
  // Handle action dialog (menu) close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      {!showCoursepage && (
        <TableHeaderTemplate
          columns={renderColumns} // Columns render with default column if empty
          rows={renderRows} // Rows render
          customMenuItems={customMenuItems} // Additional menu items
          handleEdit={handleEdit} // Handle the edit function
          deleteApi="coursepage_delete" // Delete API
          tableNameForExport="Course_page" // Export the data to Excel/CSV (if name required for the Excel or CSV)
          is_Manage_Button_Required={true} // Manage button view if additional items add (in center of the header)
          handleManageButtonClick={handleAddMoreFunctionality} // If optional data required (in center of the header click function)
          Manage_button_name="Create Course" // Name of the manage button (in center of the header)
          SetDataBaseUpdate={SetDataBaseUpdate} // Make the table update when delete
          DefaultColumnNames={[
            "CourseName",
            "%CourseComplete",
           
          ]} // Add to show the columns in a default render
          CloseActionDialog={CloseActionDialog} // Handle the action dialog close when the functionality completes (action menu container)
        />
      )}
      {/* _______________________End_____________________________ */}

      {/*___________________Call the Manage Course Template_______*/}
      <ManageCourseTemplate
        openMangaeCourse={openMangaeCourse}
        setOpenManageCourse={setOpenManageCourse}
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        showCoursepage={showCoursepage}
        setCoursePage={setCoursePage}
        editData={editData}
        SetDataBaseUpdate={SetDataBaseUpdate}
        setEditdata={setEditData}
        isEdit={isEdit}
      />
    </div>
  );
}

export default Coursetemplate2LandingPage;
