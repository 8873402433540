import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import { MDBRow, MDBCol, MDBRadio } from "mdb-react-ui-kit";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import UpdateIcon from "@mui/icons-material/Update";
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";

function PmTab5EmploymentEditModal({
  editData,
  setEditData,
  selectedOptionsKeySkills,
  keySkillOptions,
  setSelectedOptionsKeySkills,
  saveEditedItem,
  handleItemDelete,
  invalidFields,
}) {

//--------------------------------------------------------------------------------
//  State Handles
//--------------------------------------------------------------------------------
  const [displayYear, setDisplayYear] = useState(null);
  const [displayMonth, setDisplayMonth] = useState(null);

//--------------------------------------------------------------------------------
//  Calculate the years and month duration (Automatically from datepicker)
//--------------------------------------------------------------------------------
  useEffect(() => {
    handleCalcDuration(editData.fulltimeWorkFrom, editData.fulltimeWorkTill);
  }, []);

  const handleCalcDuration = (startDate, endDate) => {
    if (!startDate || !endDate) return;

    let start;
    let end;

    if (editData.curEmp === "Yes") {
      start = new Date(startDate);
      end = new Date(); // Set end date to today
    } else {
      start = new Date(startDate);
      end = new Date(endDate);
    }
    // alert('start'+start+'  end='+end)
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    // Adjust the years and months if the end month is earlier than the start month
    if (months < 0) {
      years--;
      months += 12;
    }

    console.log("start =", start);
    console.log("end =", end);
    console.log("years =", years);
    console.log("months =", months);

    setEditData((prevData) => ({
      ...prevData,
      fullExpYears: years,
      fullExpMonths: months,
    }));

    setDisplayMonth(months);
    setDisplayYear(years);
  };


  return (
    <div className="profileModal">
      {/* ___________________________________  Heading       _______________________________ */}
      <div className="pmModHeader2">
        <h3 className="pmTabHeadings">Edit Employment </h3>
        <Tooltip title="Delete">
          <IconButton
            variant="contained"
            color="error"
            onClick={() => handleItemDelete(editData.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div className="pmModalContainer">
        {/* ___________________________________  Current Employment_______________________________ */}
        <MDBRow>
          <label className={`pmLabel ${invalidFields.curEmp ? 'error_Pmlabel' : ''}`}>Is this your current employment ?</label>
          <MDBCol className="pmRadioModal">
            <MDBRadio
              name="CurEmployment"
              id="inlineRadio1"
              value="Yes"
              label="Yes"
              inline
              checked={editData.curEmp === "Yes"}
              onChange={() => 
                {
                  const currentDate = new Date().toISOString().slice(0, 10);
                  setEditData({ ...editData, curEmp: "Yes" })
                  handleCalcDuration(editData.fulltimeWorkFrom, currentDate)
                }
                }
            />
            <MDBRadio
              name="CurEmployment"
              id="inlineRadio2"
              value="No"
              label="No"
              inline
              checked={editData.curEmp === "No"}
              onChange={() => setEditData({ ...editData, curEmp: "No" })}
            />
          </MDBCol>
        </MDBRow>
        <br></br>

        {/* ___________________________________   Employment Type _______________________________ */}
        <MDBRow>
          <label className={`pmLabel ${invalidFields.empType ? 'error_Pmlabel' : ''}`}>Employment type</label>
          <MDBCol className="pmRadioModal">
            <MDBRadio
              name="empType"
              id="inlineRadio3"
              value="Full-Time"
              label="Full-Time"
              inline
              checked={editData.empType === "Full-Time"}
              onChange={() =>
                setEditData({ ...editData, empType: "Full-Time" })
              }
            />
            <MDBRadio
              name="empType"
              id="inlineRadio4"
              value="Internship"
              label="Internship"
              inline
              checked={editData.empType === "Internship"}
              onChange={() =>
                setEditData({ ...editData, empType: "Internship" })
              }
            />
          </MDBCol>
        </MDBRow>
        <br></br>

        {/* ___________________________________   Employment Experience __________________________ */}
        {editData.empType === "Full-Time" 
        // && editData.curEmp === "Yes" 
        && (
          <div className="">
            <label className="pmLabel">Total Experience</label>

            <MDBRow>
                {/*______________ Years ______________ */}
              <MDBCol>
                <label className="pmLabel">Years</label>
                <TextField
                  disabled
                  fullWidth
                  // value={editData.fullExpYears || ''}
                  value={displayYear}
                  // onChange={(e) => {
                  //   const inputValue = e.target.value;
                  //   if (/^\d*$/.test(inputValue)) {
                  //     setEditData({ ...editData, fullExpYears: e.target.value });
                  //   }
                  // }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    endAdornment: (
                      <InputAdornment position="end">Year(s)</InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                />
              </MDBCol>
                {/*______________ Months ______________ */}
              <MDBCol>
                <label className="pmLabel">Months</label>
                <TextField
                  disabled
                  fullWidth
                  value={displayMonth}
                  // value={editData.fullExpMonths || ''}
                  // onChange={(e) => {
                  //   const inputValue = e.target.value;
                  //   if (/^\d*$/.test(inputValue) && (inputValue === '' || (parseInt(inputValue, 10) >= 0 && parseInt(inputValue, 10) <= 12))) {
                  //     setSelectedFullexpMonths(inputValue);
                  //   }
                  // }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "left" }, // Align text to the right
                    },
                    endAdornment: (
                      <InputAdornment position="end">Month(s)</InputAdornment>
                    ),
                    inputComponent: "input",
                  }}
                />
              </MDBCol>
            </MDBRow>
            <br></br>
          </div>
        )}

        {/* ___________________________________   Current Company _______________________________ */}
        <MDBRow>
          <MDBCol style={{ width: "800px" }}>
            {editData.curEmp === "Yes" ? (
              <label className="pmLabel">Current Company Name</label>
            ) : (
              <label className="pmLabel">Previous Company Name</label>
            )}
            <div className="formProfile">
              <TextField
                fullWidth
                value={editData.currentCompanyname || ""}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    currentCompanyname: e.target.value,
                  })
                }
                error={invalidFields.currentCompanyname}
                helperText={invalidFields.currentCompanyname ? "* mandatory" : ""}
             
              />
            </div>
            <br></br>
          </MDBCol>
        </MDBRow>

        {/* ___________________________________   Designation___________________________________ */}
        <MDBRow>
          <MDBCol style={{ width: "800px" }}>
            {editData.curEmp === "Yes" ? (
              <label className="pmLabel">Current Designation</label>
            ) : (
              <label className="pmLabel">Previous Designation</label>
            )}
            <div className="formProfile">
              <TextField
                fullWidth
                value={editData.currentDesignation || ""}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    currentDesignation: e.target.value,
                  })
                }
                error={invalidFields.currentDesignation}
                helperText={invalidFields.currentDesignation ? "* mandatory" : ""}
         />
            </div>
            <br></br>
          </MDBCol>
        </MDBRow>

        {editData.empType === "Full-Time" ? (
          <>
            {/*______________ Joining year -Full time ______________ */}
            <div className="">
              <label className="pmLabel">Full-Time</label>
              <br></br>
              <MDBRow>
            {/*______________ From ______________ */}
                <MDBCol>
                  <label className="pmLabel">
                    From
                    <span style={{ fontSize: "9px" }}>
                      {editData.curEmp == "Yes" ? " (Current Employment)" : ""}
                    </span>
                  </label>
                  <TextField
                    required
                    type="date"
                    fullWidth
                    value={editData.fulltimeWorkFrom}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        fulltimeWorkFrom: e.target.value,
                      });
                      handleCalcDuration(
                        e.target.value,
                        editData.fulltimeWorkTill
                      );
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { 
                        style: { textTransform: "uppercase" },
                            max: editData.curEmp === 'Yes'
                              ? new Date().toISOString().split('T')[0]
                              :  editData.curEmp === 'No'? 
                              editData.fulltimeWorkTill:''
                              }, // Ensure the start date cannot be after the end date
                    }}
                    error={invalidFields.fulltimeWorkFrom}
                    helperText={invalidFields.fulltimeWorkFrom ? "* mandatory" : ""}
                  />
                </MDBCol>
            {/*______________ TIll ______________ */}
            {editData.curEmp == 'No' &&(

                <MDBCol>
                  <label className="pmLabel">Till</label>
                  <TextField
                    required
                    type="date"
                    fullWidth
                    value={editData.fulltimeWorkTill} // YYYY-MM-DD - format required on database
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        fulltimeWorkTill: e.target.value,
                      });
                      handleCalcDuration(
                        editData.fulltimeWorkFrom,
                        e.target.value
                      );
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { 
                        style: { textTransform: "uppercase" },
                        min: editData.fulltimeWorkFrom,
                        max: new Date().toISOString().split('T')[0],
                       }, // Ensure the start date cannot be after the end date
                    }}
                  />
                </MDBCol>
            )}
              </MDBRow>
              <br></br>
            </div>

            {/*__________________ Current Salary -input __________________ */}
            <MDBRow>
              <MDBCol>
                <label className="pmLabel">Current Salary</label>
                <div className="formProfile">
                  <TextField
                    fullWidth
                    value={editData.curSal || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setEditData({ ...editData, curSal: inputValue });
                      }
                    }}
                  />
                </div>
                <br></br>
              </MDBCol>
            </MDBRow>

            {/*_____________ Skills used -multi select input _____________ */}
            <MDBRow>
              <MDBCol>
                <label className="pmLabel">Skills Used</label>
                <CustomMultiSelect
                  options={keySkillOptions} // send dropdown options
                  selectedOption={selectedOptionsKeySkills} // for value prop
                  setSelectedOption={setSelectedOptionsKeySkills} // for onChange prop
                  chipClassName="jobPostLocationtag"
                  chipDeleteClassName="jobPostLocationDeleteX"
                  placeholder="Type to Select KeySkill(s)"
                />
                <br></br>
              </MDBCol>
            </MDBRow>

            {/*__________________ Job Profile - input __________________ */}
            <MDBRow>
              <MDBCol>
                <label className="pmLabel">Job profile</label>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={editData.jobProfile || ""}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      jobProfile: e.target.value,
                    })
                  }
                />
              </MDBCol>
            </MDBRow>
            <br></br>
          </>
        ) : (
          <>
            {/*_____________ Location (internship) - input _____________ */}
            <MDBRow>
              <MDBCol>
                <div>
                  <label className="pmLabel">Location</label>
                  <TextField
                    fullWidth
                    multiline
                    value={editData.internshipLocation || ""}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        internshipLocation: e.target.value,
                      })
                    }
                  />
                </div>

                <br></br>
              </MDBCol>
            </MDBRow>

            {/*_____________ Department (internship) - input _____________ */}
            <MDBRow>
              <MDBCol style={{ width: "800px" }}>
                <label className="pmLabel">Department</label>
                <TextField
                  fullWidth
                  multiline
                  value={editData.internshipDepartment || ""}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      internshipDepartment: e.target.value,
                    })
                  }
                />
                <br></br>
              </MDBCol>
            </MDBRow>

            {/*_____________ WorkingFrom (internship) From & TIll - dropdownt ______ */}
            <div>
              <br></br>
              <MDBRow>
                <MDBCol>
                  <label className="pmLabel">
                    Working from{" "}
                    <span style={{ fontSize: "9px" }}>(Internship)</span>
                  </label>
                  <TextField
                    required
                    type="date"
                    fullWidth
                    // value={'2024-07-08'}   // YYYY-MM-DD - format required on database
                    value={editData.internshipWorkFrom}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        internshipWorkFrom: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { 
                        style: { textTransform: "uppercase" },
                        max: editData.curEmp === 'Yes'
                        ? new Date().toISOString().split('T')[0]
                        :  editData.curEmp === 'No'? 
                        editData.internshipWorkFrom:''
                        
                    },
                  }}
                  />
                </MDBCol>
                {editData.curEmp == 'No' &&(
                <MDBCol>
                  <label className="pmLabel">
                    Working Till{" "}
                    <span style={{ fontSize: "9px" }}>(Internship)</span>
                  </label>
                  <TextField
                    required
                    type="date"
                    fullWidth
                    // value={'2024-07-08'}   // YYYY-MM-DD - format required on database
                    value={editData.internshipWorkTill}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        internshipWorkTill: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { style: { textTransform: "uppercase" },
                      min: editData.internshipWorkFrom,
                      max: new Date().toISOString().split('T')[0],
                    }, 
                    }}
                  />
                </MDBCol>
                )}
              </MDBRow>
            </div>
            <br></br>

            {/*_______________________ Internship - Stipend __________________________ */}
            <MDBRow>
              <MDBCol>
                <label className="pmLabel">Stipend</label>
                <div className="formProfile">
                  <TextField
                    fullWidth
                    value={editData.monthlyStipend || ""}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setEditData({
                          ...editData,
                          monthlyStipend: inputValue,
                        });
                      }
                    }}
                  />
                </div>
                <br></br>
              </MDBCol>
            </MDBRow>
          </>
        )}
        <br></br>
      </div>

      {/* ________________________________ Save  _________________________________ */}
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={saveEditedItem}
        >
          <UpdateIcon className="buttonicons" />
          Update
        </Button>
      </div>
    </div>
  );
}

export default PmTab5EmploymentEditModal;
