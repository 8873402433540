import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import Editor1 from "../Editor/Editor1";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import "./TPABlog.css";

function BlogModal({ open, closeAddBlog, setDataBaseUpdate, editData }) {

  console.log('Props inside BlogModal:', { editData }); 
  // State for input and tags
  const [inputValue, setInputValue] = useState("");
  const [showAddIcon, setShowAddIcon] = useState(false);
  // const [headingValue, setHeadingValue] = useState(editData?.heading || ""); 
  // const [content, setContent] = useState(editData?.content || "");
  const [localHeading, setHeadingValue] = useState("")
  const [content, setContent] = useState("");
  const [selectedTag, setSelectedTag] = useState([]);
  const [PublisheDate, setPublisheDate] = useState(
    editData?.Created_date || new Date().toISOString().split("T")[0]
  );
  const [isApproved, setIsApproved] = useState(editData?.IsApproved || false);
  const allTags = [
    { title: "React" },
    { title: "JavaScript" },
    { title: "CSS" },
    { title: "HTML" },
    // Add more options as needed
  ];

  // Filtered options to exclude already selected tags
  const availableTags = allTags.filter(
    (tag) => !selectedTag.some((selected) => selected.title === tag.title)
  );

  useEffect(() => {
    if (editData) {
      setHeadingValue(editData.heading || "");
      setContent(editData.Content || ""); 
      const categoriesArray = JSON.parse(editData.Category || "[]");
      setSelectedTag(categoriesArray.map((tag) => ({ title: tag })) || []);
      setPublisheDate(editData.Created_date || new Date().toISOString().split("T")[0]);
      setIsApproved(editData.IsApproved === 1);
    }
  }, [editData]);

  // Handle input change for autocomplete
  const handleInputChange_tag = (newInputValue) => {
    setInputValue(newInputValue);
    const isNewOption = !availableTags.some(
      (option) => option.title === newInputValue
    );
    setShowAddIcon(isNewOption && newInputValue !== "");
  };

  // Add new item when the Add button is clicked
  const handleAddNewItem = () => {
    if (
      inputValue &&
      !selectedTag.some((option) => option.title === inputValue)
    ) {
      const newOption = { title: inputValue };
      setSelectedTag([...selectedTag, newOption]);
    }
    setInputValue("");
    setShowAddIcon(false);
  };

  // Handle removing a tag from selected tags
  const handleRemoveTag = (tagToRemove) => {
    setSelectedTag(
      selectedTag.filter((tag) => tag.title !== tagToRemove.title)
    );
  };

  const handleSave = async () => {
    try {
      const LoginEmail = localStorage.getItem("HTES_user_name");

      // Fetching the current content from Editor1
      const contentEditor = document.getElementById("edt");
      const fullContent = contentEditor.innerHTML; // Grab the full content from editor
      const updatedContent = await processImagesInContent(fullContent); // Process any images
      const normalContent = extractTextWithImages(contentEditor); // Extract text content

      const blogPayload = {
        heading: localHeading,
        content: updatedContent,  // This will store the full content from the editor
        normalContent,  // Extracted text content
        category: selectedTag.map((tag) => tag.title),
        Created_date: PublisheDate,
        IsApproved: isApproved,
        Created_by: LoginEmail,     	
        Modified_by: LoginEmail,
      };

      if (editData) {
        await axios.put(`${baseURL}/blogs_edit/${editData.id}`, blogPayload);
        customAlert("", "Blog post updated successfully", "success");
      } else {
        await axios.post(`${baseURL}/blogs`, blogPayload);
        customAlert("", "Blog post saved successfully", "success");
      }

      closeAddBlog(); // Close the modal after saving
    } catch (error) {
      customAlert("", "There was an error saving the blog post", "warning");
    }
  };

  // Helper function to strip HTML while keeping image URLs
  const extractTextWithImages = (contentEditor) => {
    const clonedNode = contentEditor.cloneNode(true); // Clone the content editor DOM to manipulate safely
    const images = clonedNode.querySelectorAll("img");

    // Replace images with their URLs
    images.forEach((img) => {
      const imgUrl = img.src;
      const imgTextNode = document.createTextNode(`[Image: ${imgUrl}]`); // Create text node with the image URL
      img.replaceWith(imgTextNode); // Replace the img tag with the image URL as text
    });

    // Strip all remaining HTML tags
    return clonedNode.textContent || clonedNode.innerText || "";
  };

  // Helper function to convert images to base64 in the content
  const processImagesInContent = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const images = doc.querySelectorAll("img");

    // Convert images to base64
    for (const img of images) {
      if (img.src.startsWith("data:")) {
        // Already base64, no need to convert
        continue;
      }
      const base64Image = await convertImageToBase64(img.src);
      img.src = base64Image;
    }

    return doc.body.innerHTML;
  };

  // Convert image URL to base64
  const convertImageToBase64 = (imageUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = reject;
      img.src = imageUrl;
    });
  };


  return (
    <Modal open={open} onClose={closeAddBlog} aria-labelledby="blog-modal">
      <div className="modal-box">
        <div>
          <IconButton
            id="button-zoom"
            className="enquiry-form-addeducationalMapmodalcancel"
            onClick={closeAddBlog}
          >
            <CloseIcon className="enquiry-form-addeducationalMapmodalcancelX" />
          </IconButton>
        </div>

        <div className="modal-header-row">
          <h2 className="modal-title">
            {editData ? "Edit Blog" : "Add a New Blog"}
          </h2>
        </div>

        <Editor1
          isHeadingRequird={true}
          is_tag_required={true}
          isAutocomplete={true}
          desktopHeight="250px"
          return_location="/dashboard"
          editID={editData?.id || "editID"}
          showHyperlink={true}
          showImageInsert={true}
          showCodeViewer={true}
          showButtonInsert={false}
          setContent={setContent}
          label="Select Category"
          header_label="Heading"
          inputValue={inputValue}
          setInputValue={setInputValue}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          showAddIcon={showAddIcon}
          handleInputChange_tag={handleInputChange_tag}
          handleAddNewItem={handleAddNewItem}
          tagOptions={availableTags}
          handleRemoveTag={handleRemoveTag}
          setHeadingValue={setHeadingValue}
          content={content}
          headingValue={localHeading}
        />

        <div className="modal-actions">
          <Button variant="contained" className="BlogSave" onClick={handleSave}>
            <SaveIcon className="buttoniconcommon" />
            {editData ? "Update Blog" : "Save Blog"}{" "}
            {/* Change button label based on editing or creating */}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default BlogModal;
