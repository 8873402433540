import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function DownloadTemplate({ columns }) {
  const [anchorEl, setAnchorEl] = useState(null);
  //--------------------------------------------------------------------------------
  //Menu open and close
  //--------------------------------------------------------------------------------
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //--------------------------------------------------------------------------------
  //Handle download the Template
  //--------------------------------------------------------------------------------
  const handleDownload = async (format) => {
    try {
      const filteredColumns = columns.filter(
        (column) =>
          column.field !== "created_at" &&
          column.field !== "updated_at" &&
          column.field !== "id" &&
          column.field !== "isAdminApproved" &&
          column.field !== "lastModifiedPerson"
      );
      // Fetch data to get column names
      // This assumes you have already fetched the column names and stored them in the 'columns' prop
      const columnNames = filteredColumns.map((column) => column.field);

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Create the first worksheet with column names
      const ws1 = XLSX.utils.aoa_to_sheet([columnNames]);
      XLSX.utils.book_append_sheet(wb, ws1, "Template");

      // Create the second worksheet with an example row
      const exampleRow = [
        "MCQs",
        "What is NOT included in the Rpi Pi RFID Starter Kit?",
        "Soldering Iron",
        "GPIO Board",
        "Code (Python & C)",
        "Soldering Iron",
        "Tutorial CD Manual",
        "Easy",
        "VTU",
        "Degree",
        "MECH",
        "1st sem",
        "science",
        "FIELD THEORY",
        "Introductions",
        "User defined",
        "en",
        "1 Mark",
        "User defined",
        "asked in RRB,asked in SSD",
        "Lear about the importence of the filed theroy",
      ];
      const exampleRow1 = [
        "True/False",
        "Qublai Khan was a cruel ruler.",
        "FALSE",
        "TRUE",
        "FALSE",
        "-",
        "-",
        "Medium",
        "VTU",
        "Degree",
        "MECH",
        "1st sem",
        "science",
        "FIELD THEORY",
        "Introductions",
        "User defined",
        "en",
        "1 Mark",
        "user defined",
        "asked in RRB,asked in SSD",
        "Lear about the importence of the filed theroy",
      ];
      const exampleRow2 = [
        "Question and Answering",
        "What is the purpose of the freeSolo prop?",
        "Allows free-form input in an Autocomplete component",
        "-",
        "-",
        "-",
        "-",
        "-",
        "VTU",
        "Degree",
        "MECH",
        "1st sem",
        "science",
        "FIELD THEORY",
        "Introductions",
        "User defined",
        "en",
        "1 Mark",
        "user defined",
        "asked in RRB,asked in SSD",
        "Lear about the importence of the filed theroy",
      ];
      const exampleRow3 = [
        "Fill In the Blanks",
        "A century refers to a period of __________ years.",
        "	100",
        "-",
        "-",
        "-",
        "-",
        "Hard",
        "VTU",
        "Degree",
        "MECH",
        "1st sem",
        "science",
        "FIELD THEORY",
        "Introductions",
        "User defined",
        "en",
        "1 Mark",
        "user defined",
        "asked in RRB,asked in SSD",
        "Lear about the importence of the filed theroy",
      ];

      const ws2 = XLSX.utils.aoa_to_sheet([
        columnNames,
        exampleRow,
        exampleRow1,
        exampleRow2,
        exampleRow3,
      ]);
      //const ws2 = XLSX.utils.aoa_to_sheet([exampleRow]);
      XLSX.utils.book_append_sheet(wb, ws2, "Example");

      // Convert workbook to binary string
      const wbBinary = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

      // Convert binary string to Blob
      const blob = new Blob([s2ab(wbBinary)], {
        type: "application/octet-stream",
      });

      // Determine the file extension based on the selected format
      const extension = format === "csv" ? "csv" : "xlsx";

      // Save Blob as file
      saveAs(blob, `Question_Bank_Template.${extension}`);
    } catch (error) {
      console.error("Error downloading template:", error);
    }
  };

  // Convert string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
      <div className="addEducationalhoverandcolor">
        <div onClick={handleMenuOpen}>
          <FileDownloadIcon
            className="addEducationaladdicon"
            fontSize="large"
            color="primary"
          />
          <br></br>
          <span>Dowload Template</span>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ marginTop: "-50px" }}
      >
        <MenuItem onClick={() => handleDownload("csv")}>
          Download as CSV
        </MenuItem>
        <MenuItem onClick={() => handleDownload("excel")}>
          Download as Excel
        </MenuItem>
      </Menu>
    </>
  );
}

export default DownloadTemplate;
