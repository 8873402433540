import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { customAlert } from "../../SweetAlertCommon/Custom";
import "./coursetemplate2.css";

const CourseTemplate2OverView = ({ isChecked, title, content, setContent }) => {
  const [open, setOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(true);
  const [text, setText] = useState("• ");

  //--------------------------------------------------------------------------------
  // Handle open text editor
  //--------------------------------------------------------------------------------

  const handleOpen = () => {
    setOpen(true);
    setText(content.map((point) => `• ${point}`).join("\n"));
  };

  //--------------------------------------------------------------------------------
  // Handle the text change in the text area
  //--------------------------------------------------------------------------------

  const handleTextChange = (e) => {
    const value = e.target.value;
    setText(value);
  };

  //--------------------------------------------------------------------------------
  // Call the function for add the Bullet in front of the each point
  //--------------------------------------------------------------------------------

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const { selectionStart, selectionEnd, value } = e.target;
      const beforeCaret = value.slice(0, selectionStart);
      const afterCaret = value.slice(selectionEnd);
      setText(`${beforeCaret}\n• ${afterCaret}`);
      setTimeout(() => {
        e.target.selectionStart = selectionStart + 3;
        e.target.selectionEnd = selectionStart + 3;
      }, 0);
    }
  };

  //--------------------------------------------------------------------------------
  // Save the Text to the form of array in the states
  //--------------------------------------------------------------------------------

  const handleSave = () => {
    // Split the text into points, trim each one, and filter out empty ones
    const points = text
      .split("\n")
      .map((point) => point.trim())
      .filter((point) => point !== "•")
      .map((point) => point.replace(/^•\s*/, ""));
  
  
  
    // Set the content with the non-empty points
    setContent(points);
    setOpen(false);
  };
  

  //--------------------------------------------------------------------------------
  // Delete the Complete section data
  //--------------------------------------------------------------------------------

  const handleDelete = () => {
    setContent([]);
    setText("• ")
  };

  //--------------------------------------------------------------------------------
  // Expand and Hide the grid div
  //--------------------------------------------------------------------------------

  const handleExpand = () => {
    setIsExpand((prev) => !prev);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            isExpand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpand}
            >
              <h6 className="coursetemplete2-title">{title}</h6>
              {/* ____________________All icons and expan and collaps_____________ */}
              <IconButton>
                {isExpand ? (
                  <ExpandLessIcon className="course-template-expand-icon-color" />
                ) : (
                  <ExpandMoreIcon className="expand-icon-color" />
                )}
              </IconButton>
            </div>

            <div>
              {open ? (
                <Tooltip title="Save" arrow>
                  <IconButton onClick={handleSave}>
                    <SaveIcon color="success" />
                  </IconButton>
                </Tooltip>
              ) : isChecked && content.length === 0 ? (
                <Tooltip title="Add" arrow>
                  <IconButton onClick={() => setOpen(true)}>
                    <AddIcon className="courseTemplate2-edit-icon-color" />
                  </IconButton>
                </Tooltip>
              ) : (
                isChecked && (
                  <Tooltip title="Edit" arrow>
                    <IconButton onClick={handleOpen}>
                      <EditIcon className="courseTemplate2-edit-icon-color" />
                    </IconButton>
                  </Tooltip>
                )
              )}
              {isChecked && (
                <Tooltip title="Delete" arrow>
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          {/* ____________________Contnet render area_____________ */}
          {isExpand && !open && (
            <div className="coursetemplate2-content-render-conatiner">
              {content.map((point, index) => (
                <div
                  className="coursetemplate2-checkicon-and-points"
                  key={index}
                >
                  <div>
                    <CheckIcon className="buttonicons" />{" "}
                  </div>
                  &nbsp;
                  <div>{point}</div>
                </div>
              ))}
            </div>
          )}
          {/* ____________________Text area for the conatnet enter_____________ */}
          {open && (
            <div className="coursetemplate2-textare-coantainer">
              <textarea
                className="cousetemplate2-textarea"
                value={text}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter content here..."
              />
            </div>
          )}
           {/* _______________________________end___________________________ */}
        </div>
      </div>
    </>
  );
};

export default CourseTemplate2OverView;
