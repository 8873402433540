import React, { useState, useEffect } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { customAlert } from "../SweetAlertCommon/Custom"; // Importing customAlert
import axios from "axios";
import { baseURL } from "../../http"; // Importing baseURL
import "./ToCompanyPage.css";

const ToCompanyAddProject = ({
  initialFormData,
  openModal,
  setOpenModal,
  SetDataBaseUpdate,
  CloseActionDialogMenu,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    gst: "",
    pan_number: "",
    phone_number: "",
    vendor_code: "",
    contact_person_name: "",
    designation: "",
    official_mail_id: "",
    ...initialFormData, // Populate form data with initial data for edit mode
  });

  const [errors, setErrors] = useState({});

  // Update the form data when initialFormData changes
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      ...initialFormData,
    }));
  }, [initialFormData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    const phoneWithPlus = value.startsWith("+") ? value : `+${value}`;
    setFormData({
      ...formData,
      phone_number: phoneWithPlus, // Updated to phone_number
    });
  };

  // Validate the phone number
  const validatePhoneNumber = (phone) => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    if (!phoneNumber || !phoneNumber.isValid()) {
      return "Please enter a valid phone number";
    }
    return "";
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate all required fields
    const requiredFields = [
      "name",
      "address",
      "official_mail_id",
      "phone_number",
      "contact_person_name",
    ];

    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.replace('_', ' ')} is required`;
      }
    });

    // Validate phone number
    const phoneError = validatePhoneNumber(formData.phone_number);
    if (phoneError) {
      newErrors.phone = phoneError;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Set errors state to display error messages
      customAlert("Warning", "Please fill the required fields correctly.", "warning");
      return;
    }

    try {
      // Add created_by and modified_by from localStorage
      const userId = localStorage.getItem("HTES_user_id");

      if (!userId) {
        customAlert("Error", "User not authenticated. Please log in again.", "error");
        return;
      }

      const dataToSubmit = {
        ...formData,
        created_by: userId,
        modified_by: userId,
      };

      let response;
      if (formData.id) {
        // Edit existing record (PUT request)
        response = await axios.put(`${baseURL}/to-company-update/${formData.id}`, dataToSubmit);
      } else {
        // Add new record (POST request)
        response = await axios.post(`${baseURL}/to-company-store`, dataToSubmit);
      }

      if (response.status === 200 || response.status === 201) {
        SetDataBaseUpdate(true); // Triggers a re-fetch of the data
        customAlert("Success", formData.id ? "Data updated successfully!" : "Form submitted successfully!", "success");

        // Reset form data after successful submission
        setFormData({
          name: "",
          address: "",
          gst: "",
          pan_number: "",
          phone_number: "",
          vendor_code: "",
          contact_person_name: "",
          designation: "",
          official_mail_id: "",
        });

        // Close the modal
        setOpenModal(false);

        // Close modal if the function is available
        if (CloseActionDialogMenu) {
          CloseActionDialogMenu();
        }
      } else {
        customAlert("Error", "Something went wrong. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      customAlert("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <div className="to-company-enquiry-content">
        <IconButton
          className="to-company-enquiry-close-btn"
          onClick={() => setOpenModal(false)}
        >
          <CloseIcon className="to-company-enquiry-close-btnX2" />
        </IconButton>
        
        {/* Dynamic modal header text based on editing or adding */}
        <div className="to-company-enquiry-header">
          {formData.id ? "Edit Client Company Details" : "Client Company Details"}
        </div>

        <form className="to-company-enquiry-form" onSubmit={handleFormSubmit}>
          <TextField
            margin="dense"
            label="Company Name"
            name="name"
            type="text"
            fullWidth
            required
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Address"
            name="address"
            type="text"
            fullWidth
            required
            value={formData.address}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="GST"
            name="gst"
            type="text"
            fullWidth
            value={formData.gst}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="PAN Number"
            name="pan_number"
            type="text"
            fullWidth
            value={formData.pan_number}
            onChange={handleChange}
          />
          {/* Phone input field with error handling */}
          <PhoneInput
            country={"in"}
            value={formData.phone_number}
            onChange={handlePhoneChange}
            inputClass="phone-input"
            buttonClass={errors.phone ? "phone-button-error" : "phone-button"}
            dropdownClass="phone-input-dropdown"
            placeholder="Phone Number"
          />
          {errors.phone && (
            <div className="helper-text-phone-container">{errors.phone}</div>
          )}

          <TextField
            margin="dense"
            label="Vendor Code"
            name="vendor_code"
            type="text"
            fullWidth
            value={formData.vendor_code}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Contact Person Name"
            name="contact_person_name"
            type="text"
            fullWidth
            required
            value={formData.contact_person_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Designation"
            name="designation"
            type="text"
            fullWidth
            value={formData.designation}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Official Mail ID"
            name="official_mail_id"
            type="email"
            fullWidth
            required
            value={formData.official_mail_id}
            onChange={handleChange}
          />
          <div className="to-company-enquiry-actions">
            {/* Dynamic button text based on editing or adding */}
            <Button
              type="submit"
              color="success"
              variant="contained"
            >
              {formData.id ? (
                <><EditIcon className="to-company-buttoniconcommon" /> Update</>
              ) : (
                <><SaveIcon className="to-company-buttoniconcommon" /> Save</>
              )}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ToCompanyAddProject;
