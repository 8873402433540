import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { baseURL } from "../../http";
import axios from "axios";
import { customAlert } from "../SweetAlertCommon/Custom";
import Modal from "@mui/material/Modal";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';

function PmTab3ResumeHeadline({callPercentage}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [resHeadline, setresHeadline] = useState("");
  const [resHeadlineEdit, setresHeadlineEdit] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [modalStatus, setModalStatus] = useState(false);

  function toggleModal() {
    setModalStatus(!modalStatus);
  }

  //--------------------------------------------------------------------------------
  // Fetching the Resume Headline Datas
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchResHeadline();
  }, []);

  const fetchResHeadline = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/getprofile`,
        { email: userEmail },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        setresHeadline(response.data.resumeHeadline || "");
        setresHeadlineEdit(response.data.resumeHeadline || "");
        if(!response.data.resumeHeadline){setButtonName('Save')}
        else{ setButtonName('Update');}
      } else {
        console.error("Error fetching ResHead");
      }
    } catch (error) {
      console.error("Error fetching ResHead:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Save Resume Data API
  //--------------------------------------------------------------------------------
  const saveProfileData = async () => {
    try {
      const sendData = {
        email: userEmail,
        resumeHeadline: resHeadlineEdit,
      };

      const response = await axios.post(
        `${baseURL}/updateResumeHeadline`,
        sendData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Resume Headline updated success");
        customAlert("", "Item Updated", "success").then(() => {
          setModalStatus(false);
          setresHeadline(resHeadlineEdit);
          fetchResHeadline();
          callPercentage();
        });
      } else {
        console.error("Error updating Resume Headline");
      }
    } catch (error) {
      console.error("Error while saving data:", error);
    }
  };

  return (
    <div>
      {/* ___________________________________  Tab Render  _______________________________ */}

      <h5 className="pmTabHeadings">
        Resume Headline{" "}
        <Tooltip title="Edit" arrow>
                <IconButton onClick={toggleModal}>
                  <EditIcon className="pmEditIcon" />
                </IconButton>
        </Tooltip>
      </h5>
      <p>{resHeadline || ""}</p>

      {/* ___________________________________ Resume Headline Modal _______________________________ */}

      <Modal
        open={modalStatus}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox">
          <div>
            <h5 className="pmTabHeadings">Resume Headline</h5>
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={toggleModal}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>
          </div>

          <br />

          <p className="pmModParaText">
            It is the first thing recruiters notice in your profile. Write
            concisely what makes you unique and the right person for the job you
            are looking for.
          </p>
          <br />

          <div className="formProfile">
            <TextField
              id="outlined-multiline-static"
              label="Resume Headline"
              multiline
              fullWidth
              rows={4}
              value={resHeadlineEdit || ""}
              onChange={(e) => setresHeadlineEdit(e.target.value)}
            />
            <br />
          </div>

          <div className="createteslayoutmodel6">
            <Button id="button-zoom" variant="contained" color="success" onClick={saveProfileData}>
              <SaveIcon className="buttonicons"  />
              &nbsp;{buttonName}
            </Button>
          </div>
        </div>
      </Modal>
      {/* ___________________________________ Resume Headline Modal  Ends__________________________ */}
    </div>
  );
}

export default PmTab3ResumeHeadline;
