import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddEditInstructor from "./AddEditInstructor";

function ManageInstructor({ open, close, SetDataBaseUpdate }) {
  const [addinstructor, setAddInstrructor] = useState(false);
  //--------------------------------------------------------------------------------
  // open and close add/edit instructor
  //--------------------------------------------------------------------------------
  const handleAddInstructorOpen = () => {
    setAddInstrructor(true);
    close();
  };
  const handleAddInstructorclose = () => {
    setAddInstrructor(false);
  };
  return (
    <>
      <Modal open={open} onClose={close} className="course-template-modal">
        {/* _________________________Header_______________________ */}
        <div className="course-template-modal-container">
          <div className="course-name-template-container-with-content">
            <IconButton
              id="button-zoom"
              className="course-name-template-close-button"
              onClick={close}
            >
              <CloseIcon className="course-name-template-close-button-x" />
            </IconButton>
            <h5 className="course-name-template-header">
              Instructor Management
            </h5>
            {/* _________________________End_______________________ */}
            {/* _________________________Button,upload,download,add_______________________ */}
            <div className="course-name-template-add-course-icon">
              <div onClick={handleAddInstructorOpen}>
                <AddBoxIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />

                <div>
                  Add<br></br>Instructor
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* ______________call the Add instructor modal_________ */}
      <AddEditInstructor
        open={open}
        openAddInstructor={addinstructor}
        closeAddInstructor={handleAddInstructorclose}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />
      {/* ______________end_________ */}
    </>
  );
}

export default ManageInstructor;
