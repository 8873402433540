import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Editor1 from "../Editor/Editor1";
import { customAlert } from "../SweetAlertCommon/Custom";
import { baseURL } from "../../http";

import './QABlog_editor.css';

function Question_Editor({ isHeadingRequird, is_tag_required, onContentChange = () => { }, onSave, onChange = () => { }, apiType, editMode, questionId, replyId, content: initialContent = "" }) {

  //-------------------------------------------------------------------------
  //State Handles
  //-------------------------------------------------------------------------

  const [heading, setHeading] = useState("");
  const [content, setContent] = useState(initialContent);
  const [Tag_value, setTag_value] = useState("");
  const [user, setUser] = useState("John");

  const navigateTo = useNavigate();

  //-------------------------------------------------------------------------
  // Clean Content Resize
  //-------------------------------------------------------------------------

  const cleanContentImageResize = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const imgContainers = tempDiv.querySelectorAll(".image-container");
    imgContainers.forEach((container) => {
      container.style.resize = null;
    });
    return tempDiv.innerHTML;
  };

  const cleanContentCodeResize = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const codeContainers = tempDiv.querySelectorAll(".code-viewer-container");
    codeContainers.forEach((container) => {
      container.style.resize = null;
    });
    return tempDiv.innerHTML;
  };

  //-------------------------------------------------------------------------
  // Parse HTML content to Normal Text
  //-------------------------------------------------------------------------

  const parseHTML = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.innerText;
  };

  useEffect(() => {
    if (localStorage.getItem("CA_Edit_ID")) {
      setHeading(localStorage.getItem("CA_Edit_Heading"));
      setContent(localStorage.getItem("CA_Edit_Content"));
      setTag_value(localStorage.getItem("CA_Edit_Tag"));
    }
  }, []);

  useEffect(() => { onChange(content); },
    [content, onChange]);

  useEffect(() => {
    onContentChange({ heading, content, tags: Tag_value });
  }, [heading, content, Tag_value, onContentChange]);

  //----------------------------------------------------------------
  //Save the content and update
  //----------------------------------------------------------------

  const handleSave = async () => {
    const rawContent = content;
    const cleanedContent_ImgRz = cleanContentImageResize(rawContent);
    const cleanedContent = cleanContentCodeResize(cleanedContent_ImgRz);

    const sendData = {
      heading: heading,
      normalContent: parseHTML(cleanedContent),
      htmlContent: cleanedContent,
      tags: Tag_value.split(','), // Ensure tags is an array
      user: user, // Consistently set user to "John"
    };

    let apiUrl = `${baseURL}/questionblog`;

    if (apiType === 'reply') {
      if (!questionId) {
        customAlert("Error", "Question ID is missing.", "error");
        return;
      }
      if (editMode && replyId) {
        apiUrl = `${baseURL}/questions/${questionId}/replies/${replyId}`;
        try {
          const response = await axios.put(apiUrl, sendData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          if (response.status === 200 || response.status === 201) {
            customAlert("", "Reply Updated Successfully", "success").then(() => {
              onSave(response.data);
            });
          } else {
            customAlert("Error", "Failed to update reply. Please try again.", "error");
          }
        } catch (error) {
          customAlert("Error", "Network Error. Please try again later.", "error");
        }
        return;
      } else {
        apiUrl = `${baseURL}/questions/${questionId}/replies`;
      }
    }

    try {
      const response = await axios.post(apiUrl, sendData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200 || response.status === 201) {
        customAlert("", apiType === 'reply' ? "Reply Added Successfully" : "Question Added Successfully", "success").then(() => {
          onSave(response.data);
        });
      } else {
        customAlert("Error", "Failed to add content. Please try again.", "error");
      }
    } catch (error) {
      customAlert("Error", "Network Error. Please try again later.", "error");
    }
  };

  return (
    <div>
      <div className="Question_Main_container">

        {/*_________________________ Editor Container_________________________  */}

        <div className="Question_EditorContainer">
          <Editor1
            isHeadingRequird={isHeadingRequird}
            heading={heading}
            setHeading={setHeading}
            height="30vh"
            setOtputContent={setContent}
            setContent={setContent}
            is_tag_required={is_tag_required}
            Tag_value={Tag_value}
            setTag_value={setTag_value}
            Buttun_Name={editMode ? 'Update' : 'Save'}
            onSave={handleSave}
            content={content}
          />
        </div>
      </div>
    </div>
  );
}

export default Question_Editor;
