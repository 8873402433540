import React, { useState, useEffect } from "react";
import { Avatar, IconButton, Button, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Update } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import FilterButton from "../GeneralTemplateComponent/FilterButton";
import SaveIcon from "@mui/icons-material/Save";
import { Edit } from "@mui/icons-material";
import axios from "axios";
import { baseURL } from "../../http";
import TestCardView from "./TestCardView";
import TestCardView1 from "./TestCardView1";
import { getUtcTime, getCurrentDateTime } from "../Common/Utilities_Function";
import { customAlert } from "../SweetAlertCommon/Custom";

function TestAddandManage({
  formData,
  setDatatableview,
  setopenCreateTestModal,
  editTestData,
  SetDataBaseUpdate,
  seteditTestData,
  setTestSeriesName,
  setFormData
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [TestNames, setTestNames] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [SelectedTest, setSelectedTest] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // console.log("sortedData", sortedData);

  useEffect(() => {
    if (editTestData && editTestData.TestIds && TestNames.length > 0) {
      const selectedTests = TestNames.filter(
        (test) => editTestData.TestIds.includes(test.TestName) // Match test IDs with editTestData.TestIds
      );
      setSelectedTest(selectedTests);
    }
  }, [editTestData?.TestSeriesName, editTestData, TestNames]);

  //--------------------------------------------------------------------------------
  // Filter function based on the search term
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const filteredData = TestNames.filter((question) => {
      // Convert the object to an array of its values and check if any value includes the search term
      return Object.values(question).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setSortedData(filteredData);
  }, [searchTerm, TestNames]);

  //--------------------------------------------------------------------------------
  // Function to handle search input change
  //--------------------------------------------------------------------------------
  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Update the search term
  };
  //--------------------------------------------------------------------------------
  //Toggle side bar Question view
  //--------------------------------------------------------------------------------
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleHidetheTable = () => {
    setFormData({
      TestSeriesName: '',
      TestsIds: []  // Placeholder for TestsIds, which you may manage later
    })
    seteditTestData()
    setTestSeriesName('')
    setDatatableview(false);

  };

  const handleeditclick = () => {
    setopenCreateTestModal(true);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/gettestgeninfo`);
      const data = response.data;
      // Set the data in the states
      setTestNames(data);
      setSortedData(data);

      // Dynamically create columns from the data
      if (data.length > 0) {
        const columnKeys = Object.keys(data[0]).map((key) => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize headers
        }));
        setColumns(columnKeys);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error if needed
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  console.log('SelectedTest',SelectedTest)
  const handleSaveTest = async () => {
    try {
      // Prepare the payload data
      const payload = {
        TestSeriesName: formData.TestSeriesName, // Assuming you have this in your formData
        TestIds: SelectedTest.map((test) => test.TestName), // Assuming you map selected tests by their IDs
        Created_by: localStorage.getItem("HTES_user_id"), // Replace with actual user if needed
        Modified_by: localStorage.getItem("HTES_user_id"),
        UTC_Time: getUtcTime(),
        Modified_date: getCurrentDateTime(),
        Created_date: getCurrentDateTime(), // You may want to change this for updates
      };

      if (editTestData) {
        // If editing an existing test series, send a PUT request
        await axios.put(
          `${baseURL}/TestManagement_edit/${editTestData.id}`,
          payload
        );
        customAlert("", "Test series updated successfully", "success");
        SetDataBaseUpdate(true);
        setTestSeriesName('')
        seteditTestData()
        setFormData({
          TestSeriesName: '',
          TestsIds: []  // Placeholder for TestsIds, which you may manage later
        })
        setDatatableview(false);

      } else {
        // If adding a new test series, send a POST request
        await axios.post(`${baseURL}/TestManagement`, payload);
        customAlert("", "Test series created successfully", "success");
        SetDataBaseUpdate(true);
        setTestSeriesName('')
        seteditTestData()
        setFormData({
          TestSeriesName: '',
          TestsIds: []  // Placeholder for TestsIds, which you may manage later
        })
        setDatatableview(false);
        
      }

      // Optionally, you can reset the form, refresh the list, etc.
    } catch (error) {
      console.error("Error saving test series:", error);
      alert("Failed to save the test series. Please try again.");
    }
  };

  return (
    <>
      <div className="createtestgeneralinfoemarionsmalldiv">
        <div className="edit-icon-alignemnt">
          <Tooltip title="Back to Home" arrow>
            <IconButton onClick={handleHidetheTable}>
              <ArrowBackIcon className="edit-icon-color-and-fontsize-generalinfo" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="test-series-name-font-alignment">
          <span className="test-series-name-font-alignemnt-heading">
            Test Series Name:
          </span>{" "}
          {formData.TestSeriesName}
        </div>
        <div>
          <Tooltip title="Edit Test Series Name" arrow>
            <IconButton onClick={handleeditclick}>
              <Edit className="test-series-manage-edit-icon-alignment" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="main-container-all-component-render">
        {/* Left container, dynamically adjusts between 70% and 100% width */}

        <div className={`left-container ${isOpen ? "expanded" : "full-width"}`}>
          <div className="Avatar-and-Button-conatiner-in-question-view-container">
            {!isOpen && (
              <Tooltip title="Total selected Question" arrow>
                <Avatar className="avatar-alignemnt-in-add-to-test">
                  {SelectedTest?.length}
                </Avatar>
              </Tooltip>
            )}
          </div>
          <div className="Avatar-and-Button-conatiner-in-question-view-container1">
            <Button
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleSaveTest}
            >
              {editTestData ? (
                <Update className="buttonicons" />
              ) : (
                <SaveIcon className="buttonicons" />
              )}
              &nbsp;{editTestData ? "Update Test" : "Save Test"}
            </Button>
          </div>
          <div
            className={`selected-question-view-container ${
              isOpen ? "expanded" : "full-width"
            }`}
          >
            {/* Check if selectedQuestions is empty */}
            {SelectedTest.length === 0 ? (
              <div className="no-questions-message">
                Please add the test questions.
              </div>
            ) : (
              <TestCardView
                SelectedTest={SelectedTest}
                setSelectedTest={setSelectedTest}
                Testall={SelectedTest}
              />
            )}
          </div>
        </div>

        {/* Right 30% container, can slide in/out */}
        {isOpen && (
          <div className={`right-container ${isOpen ? "open" : "closed"}`}>
            <Tooltip title="Close the Test Bank" arrow>
              <IconButton className="toggle-button" onClick={toggleSidebar}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Tooltip>
            <div className="container-for-filter-search-all-component-render">
              <div className="common-page-search-container-all-component-render">
                <span className="common-page-search-icon-all-component-render">
                  <SearchIcon />
                </span>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search..."
                  className="search-input-all-component-render"
                />
              </div>
              <div>
                <Tooltip title="Total Tests" arrow>
                  <Avatar className="avatar-alignemnt-in-add-to-test">
                    {TestNames?.length}
                  </Avatar>
                </Tooltip>
              </div>
              <div>
                <FilterButton
                  columns={columns}
                  rows={TestNames}
                  setSortedData={setSortedData}
                />
              </div>
            </div>
            <div className="sidebar-content">
              <TestCardView1
                SelectedTest={SelectedTest}
                setSelectedTest={setSelectedTest}
                Testall={sortedData}
              />
            </div>
          </div>
        )}

        {!isOpen && (
          <span>
            <Tooltip title="Open the Test Bank" arrow>
              <IconButton
                className="toggle-button closed"
                onClick={toggleSidebar}
              >
                <ArrowBackIosIcon className="close-the-container-arrow-alignment" />
              </IconButton>
            </Tooltip>
          </span>
        )}
      </div>
    </>
  );
}

export default TestAddandManage;
