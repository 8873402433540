import React, { useState, useEffect, useRef } from "react";
import "./dropdown.css";

function DropDown({ setSelectedLanguage, selectedLanguage }) {
  const [isActive, setIsActive] = useState(false);
  //const [selectedLanguage, setSelectedLanguage] = useState();
  const [searchValue, setSearchValue] = useState("");

  //--------------------------------------------------------------------------------
  //Choose the language in th dropdown
  //--------------------------------------------------------------------------------
  const handleOptionClick = (option) => {
    setSelectedLanguage(option);
    setIsActive(false);
  };

  //--------------------------------------------------------------------------------
  //Search the language
  //--------------------------------------------------------------------------------
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  //--------------------------------------------------------------------------------
  //alll the langgaes options
  //--------------------------------------------------------------------------------
  const options = [
    { name: "Assamese", code: "as", currency: "INR", cname: "Assamese" },
    { name: "Bengali", code: "bn", currency: "INR", cname: "Bengali" },
    // { name: 'Bodo', code: 'br', currency: 'INR', cname: 'Bodo' },
    { name: "Dogri", code: "doi", currency: "INR", cname: "Dogri" },
    { name: "English", code: "en", currency: "INR", cname: "English" },
    { name: "Gujarati", code: "gu", currency: "INR", cname: "Gujarati" },
    { name: "Hindi", code: "hi", currency: "INR", cname: "Hindi" },
    { name: "Kannada", code: "kn", currency: "INR", cname: "Kannada" },
    // { name: 'Kashmiri', code: 'ks', currency: 'INR', cname: 'Kashmiri' },
    // { name: 'Konkani', code: 'kok', currency: 'INR', cname: 'Konkani' },
    { name: "Maithili", code: "mai", currency: "INR", cname: "Maithili" },
    { name: "Malayalam", code: "ml", currency: "INR", cname: "Malayalam" },
    // { name: 'Manipuri', code: 'mni', currency: 'INR', cname: 'Manipuri' },
    { name: "Marathi", code: "mr", currency: "INR", cname: "Marathi" },
    { name: "Nepali", code: "ne", currency: "NPR", cname: "Nepali" },
    { name: "Odia", code: "or", currency: "INR", cname: "Odia" },
    { name: "Punjabi", code: "pa", currency: "INR", cname: "Punjabi" },
    { name: "Sanskrit", code: "sa", currency: "INR", cname: "Sanskrit" },
    // { name: 'Santali', code: 'sat', currency: 'INR', cname: 'Santali' },
    { name: "Sindhi", code: "sd", currency: "INR", cname: "Sindhi" },
    { name: "Tamil", code: "ta", currency: "INR", cname: "Tamil" },
    { name: "Telugu", code: "te", currency: "INR", cname: "Telugu" },
    { name: "Urdu", code: "ur", currency: "PKR", cname: "Urdu" },
  ];

  //--------------------------------------------------------------------------------
  //fiter the questions
  //--------------------------------------------------------------------------------
  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  //--------------------------------------------------------------------------------
  //Ref container
  //--------------------------------------------------------------------------------
  const dropdownRef = useRef(null);
  const closeDropdown = () => {
    setIsActive(false);
  };

  //--------------------------------------------------------------------------------
  //close the Dropdown
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSelect = () => {
    setIsActive(!isActive);
  };

  //--------------------------------------------------------------------------------
  //Stop pagination
  //--------------------------------------------------------------------------------
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div ref={dropdownRef} className={`select-box ${isActive ? "active" : ""}`}>
      <div className="select-option" onClick={toggleSelect}>
        <input
          type="text"
          placeholder="Language"
          readOnly
          value={selectedLanguage.name}
          onClick={toggleSelect} // Toggle dropdown when input is clicked
        />
      </div>
      <div className="content">
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            onClick={handleClick} // Prevent closing dropdown when input is clicked
          />
        </div>
        <ul className="options">
          {filteredOptions.length === 0 ? (
            <li>No Language available</li>
          ) : (
            filteredOptions.map((option) => (
              <li key={option.code} onClick={() => handleOptionClick(option)}>
                {option.name}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}

export default DropDown;
