import React, { useState, useEffect } from "react";
import { IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save"; // Import SaveIcon

function FillintheblanksandQAcard({
  questionData,
  isEditing,
  onDelete,
  onSave,
}) {
  const [editing, setEditing] = useState(isEditing);
  const [editedQuestion, setEditedQuestion] = useState(questionData.question);
  const [editedAnswer, setEditedAnswer] = useState(questionData.answer);

  //-------------------------------------------------------------------------
  // Update edited question and answer when isEditing changes
  //-------------------------------------------------------------------------

  useEffect(() => {
    setEditedQuestion(questionData.question);
    setEditedAnswer(questionData.answer);
    setEditing(isEditing);
  }, [questionData, isEditing]);

  //-------------------------------------------------------------------------
  // Question change handle function
  //-------------------------------------------------------------------------
  const handleQuestionChange = (value) => {
    setEditedQuestion(value);
  };

  //-------------------------------------------------------------------------
  // Answer change handle function
  //-------------------------------------------------------------------------
  const handleAnswerChange = (value) => {
    setEditedAnswer(value);
  };

  //-------------------------------------------------------------------------
  //Mak esure the fileds to ediatable
  //-------------------------------------------------------------------------
  const handleEdit = () => {
    setEditing(true);
  };

  //-------------------------------------------------------------------------
  // Create updated question object
  //-------------------------------------------------------------------------
  const handleSave = () => {
    const updatedQuestion = {
      ...questionData,
      question: editedQuestion,
      answer: editedAnswer,
    };

    // Call onSave function passed from the parent component
    onSave(updatedQuestion);

    // Exit editing mode
    setEditing(false);
  };

  //-------------------------------------------------------------------------
  // Delete the QUestion function
  //-------------------------------------------------------------------------
  const handleDelete = () => {
    onDelete(questionData); // Notify the parent component to delete this question
  };

  return (
    <div className="questionCardContainer">
      <div className="questionRow">
        {editing ? (
          <textarea
            className="Mcqquestioncardtextarea"
            value={editedQuestion}
            onChange={(e) => handleQuestionChange(e.target.value)}
          />
        ) : (
          <Typography className="text-color-bold-in-mcq">
            {questionData.question}
          </Typography>
        )}
      </div>
      <div className="answerRow">
        {editing ? (
          <textarea
            className="Mcqquestioncardtextarea"
            value={editedAnswer}
            onChange={(e) => handleAnswerChange(e.target.value)}
          />
        ) : (
          <Typography className="text-answer-color-in-question-generation">
            <span className="text-color-bold-in-mcq">Answer: </span>
            {editedAnswer}
          </Typography>
        )}
      </div>
      <div className="saveanddeleteicon">
        {editing ? (
          <>
            <IconButton id="button-zoom" aria-label="save" onClick={handleSave}>
              <SaveIcon color="success" />
            </IconButton>
            <IconButton
              id="button-zoom"
              aria-label="delete"
              onClick={handleDelete}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </>
        ) : (
          <IconButton id="button-zoom" aria-label="edit" onClick={handleEdit}>
            <EditIcon className="mcq-generation-edit-icon-color" />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default FillintheblanksandQAcard;
