import React, { useState, useEffect } from 'react';
import { Grid, Button} from '@mui/material';
import ContentSidenav from './ContentSidenav';
import AdminContentViewer from './AdminContentViewer';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircle';
import Drawer from '@mui/material/Drawer';
import './Content.css';
import { useNavigate } from 'react-router-dom';

const ContentMain = () => {

  //-------------------------------------------------------------------------
  // State handle
  //-------------------------------------------------------------------------
  const [selectedTopic, setSelectedTopic] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 900);
  const [activeStep, setActiveStep] = useState(0);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [scrollPositions, setScrollPositions] = useState({});
  const [showImage, setShowImage] = useState(false);
  const [selectedEducRoadMapId, setSelectedEducRoadMapId] = useState("");//  +++


  //-------------------------------------------------------------------------
  // For resizing the screen(for small screens)
  //-------------------------------------------------------------------------

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  //-------------------------------------------------------------------------
  // _Handling the scroll event for the content part
  //-------------------------------------------------------------------------

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      //Position for the scroll icon
      setScrollPositions(prevPositions => ({
        ...prevPositions,
        [selectedTopic]: scrolledToBottom,
      }));

      setShowBackToTop(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [selectedTopic]);

  //-------------------------------------------------------------------------
  // Function to handle the content to the beginning
  //-------------------------------------------------------------------------
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  //-------------------------------------------------------------------------
  // Buttons Function to navigate to next and previous topic
  //-------------------------------------------------------------------------

  const handlePrevious = () => {
    setActiveStep(prevStep => Math.max(prevStep - 1, 0));
  };

  const handleNext = () => {
    setActiveStep(prevStep => prevStep + 1);
  };


  //-------------------------------------------------------------------------
  // Function to Select topic
  //-------------------------------------------------------------------------
  const handleTopicSelect = (topic) => {
    console.log('handleltop sel @ContentMain-'+topic)
    setSelectedTopic(topic);
    setShowImage(true);
  };
  const handleRoadMapIDSelect = (topic) => {    //++++
  setSelectedEducRoadMapId(topic);
  };

  //-------------------------------------------------------------------------
  // Function and navigation to Add Content
  //-------------------------------------------------------------------------

  const navigateTo = useNavigate();
  function handlAddContent() {
    const activeTab = localStorage.getItem('activeTab');
    const HTES_user_id = localStorage.getItem('HTES_user_id');
    const activeSubItem = localStorage.getItem('activeSubItem');

  // Clear localStorage
  localStorage.clear();

  // Restore the specified items
  if (activeTab !== null) localStorage.setItem('activeTab', activeTab);
  if (HTES_user_id !== null) localStorage.setItem('HTES_user_id', HTES_user_id);
  if (activeSubItem !== null) localStorage.setItem('activeSubItem', activeSubItem);
  // if (activeSubItem !== null) localStorage.setItem('activeSubItem', activeSubItem);
  // localStorage.setItem('selectedContentTopic',selectedTopic);
    navigateTo('/Content_Editor', { state: { selectedTopic, selectedEducRoadMapId } });
    // alert(selectedTopic)
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (event, newOpen) => () => {
    setDrawerOpen(true);
    // event.preventDefault();
  };

  
  return (
    <Grid container className="content-main-container">

      {/* ________________Import side nav file___________________________ */}

      {!isSmallScreen && (
        <Grid item xs={12} md={2}>
          <br></br>
          <ContentSidenav 
            setSelectedTopic={handleTopicSelect}
            setSelectedEducRoadMapId={handleRoadMapIDSelect}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            selectedTopic={selectedTopic}
            isScrolledToBottom={scrollPositions[selectedTopic]}
          />
        </Grid>
      )}

      {/* _____________________________ Content Area ______________________ */}

      <Grid item xs={12} md={showImage ? 8 : 9}>

        <div className="main-content">

        {/* ________________________ Content Render Area ________________ */}
           <Grid container direction="column">
             {/* _____ Topic Name & Add Content Button ______ */}
              <Grid item>
                <div className='contentTopicHeader' >

                  <div style={{width:'50%', display:'flex'}}>
                  <span className="hamburger-menu" onClick={toggleDrawer(true)}>
                   &nbsp;  <i class="fas fa-bars" ></i> </span>
                    <h3 className='selectedTopic2'>{selectedTopic}</h3>

                  </div>

                  <div className='renderTopicHeader' >
                    <Button variant="contained" color="success" 
                    onClick={handlAddContent}>
                      <AddCircleOutlineIcon/>&nbsp;Add Content</Button>
                  </div>
                    
                </div>
               </Grid>

             {/* ___________ Prev & next above ____________ */}
            <Grid item>
              <div className="button-container2">
                <Button
                  variant="contained"
                  color="success"
                  onClick={handlePrevious}
                  className={`previous-button ${isSmallScreen ? 'left-margin' : ''}`}
                >
                  <ArrowBackIosIcon />
                  Previous
                </Button>
              
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleNext}
                  className={`next-button ${isSmallScreen ? 'right-margin' : ''}`}
                >
                  Next
                  <ArrowForwardIosIcon />
                </Button>
              </div>
            </Grid>

            {/* ____________  Content Render  _____________ */}
            <Grid item xs={12} direction='column'>
              <div className="additional-content" style={{ marginBottom: '15px' }}>
                {selectedTopic && <AdminContentViewer topicSelectedFromSideNav={selectedTopic} />}
              </div>
            </Grid>

              {/* ___________ Prev & next above ____________ */}
              <Grid item>
                <div className="button-container2">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handlePrevious}
                    className={`previous-button ${isSmallScreen ? 'left-margin' : ''}`}
                  >
                    <ArrowBackIosIcon />
                    Previous
                  </Button>
                 
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleNext}
                    className={`next-button ${isSmallScreen ? 'right-margin' : ''}`}
                  >
                    Next
                    <ArrowForwardIosIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>


          </div>
        
        {/* ____________________________ Back to Top ____________________ */}
          <div className="back-to-top-icon" onClick={handleBackToTop}>
            <ArrowUpwardIcon fontSize="large" />
          </div>

      </Grid>



        {/* ____________________________ Drawer Menu ____________________ */}
      <Drawer anchor="left" open={drawerOpen} onClose={()=>setDrawerOpen(false)}>
        {/* {DrawerList} */}

        <Box sx={{ width: 230 }} role="presentation" 
          className='CA_Drawer'
          onClick={()=>setDrawerOpen(false)} >  
            
           <div >
            {/* <h4 className='' style={{paddingBottom:'60px'}}>Select Topic </h4> */}
            
            <ContentSidenav 
                  setSelectedTopic={handleTopicSelect}
                  setSelectedEducRoadMapId={handleRoadMapIDSelect}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  selectedTopic={selectedTopic}
                  isScrolledToBottom={scrollPositions[selectedTopic]}
                />

           </div>
            
          </Box>

      </Drawer>
    </Grid >
  );
}

export default ContentMain;