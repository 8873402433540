import React, { useState, useEffect } from "react";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart, registerables } from "chart.js";
import "./ResultDashboardPage.css";

//--------------------------------------------------------------------------------
// Register Chart.js components
//--------------------------------------------------------------------------------
Chart.register(...registerables);
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function ResultDashboard2({ ResultData }) {
  const [pieData, setPieData] = useState({
    correct: 0,
    incorrect: 0,
    unanswered: 0,
  });

  const [timeData, setTimeData] = useState({
    correctTime: 20,
    incorrectTime: 30,
  });

  //--------------------------------------------------------------------------------
  // Render the all the data with Resultdata
  //--------------------------------------------------------------------------------
  useEffect(() => {
    // Parse ExamStatus if it's a string
    const examStatus =
      typeof ResultData?.ExamStatus === "string"
        ? JSON.parse(ResultData?.ExamStatus)
        : ResultData?.ExamStatus;

    if (examStatus && examStatus[0]) {
      const selectedOptions = examStatus[0]?.SeletedOptions || [];
      const resultArray = examStatus[0]?.resultArray || [];
      const timeTakenForQuestions = examStatus[0]?.TimeTakenForQuestions || [];

      let correctCount = 0;
      let incorrectCount = 0;
      let unansweredCount = 0;
      let totalCorrectTime = 0;
      let totalIncorrectTime = 0;

      // Iterate over resultArray and selectedOptions to calculate counts and time
      resultArray.forEach((result, index) => {
        const selectedOption = selectedOptions[index];
        const time = timeTakenForQuestions[index];

        // Convert each time entry to total minutes
        const timeInMinutes = (time?.minutes || 0) + (time?.seconds || 0) / 60;

        if (selectedOption === null) {
          // Unanswered question
          unansweredCount++;
        } else if (result === 1) {
          // Correct answer
          correctCount++;
          totalCorrectTime += timeInMinutes;
        } else if (result === 0) {
          // Incorrect answer
          incorrectCount++;
          totalIncorrectTime += timeInMinutes;
        }
      });

      // Update state with calculated values
      setPieData({
        correct: correctCount,
        incorrect: incorrectCount,
        unanswered: unansweredCount,
      });

      setTimeData({
        correctTime: totalCorrectTime.toFixed(2),
        incorrectTime: totalIncorrectTime.toFixed(2),
      });
    }
  }, [ResultData]);

  //--------------------------------------------------------------------------------
  // Pai cahrt proparties
  //--------------------------------------------------------------------------------
  const pieChartData = {
    labels: ["Correct", "Incorrect", "Unanswered"],
    datasets: [
      {
        data: [pieData.correct, pieData.incorrect, pieData.unanswered],
        backgroundColor: ["#28a745", "#dc3545", "#ffc107"],
      },
    ],
  };

  //--------------------------------------------------------------------------------
  // Pai chart controll
  //--------------------------------------------------------------------------------
  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
          boxWidth: 12,
          padding: 20,
          width: 200,
          height: 200,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw || 0;
            const total = tooltipItem.dataset.data.reduce(
              (acc, val) => acc + val,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2) + "%";
            return `${label}: ${value} (${percentage})`;
          },
        },
      },
      datalabels: {
        color: "#fff",
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce(
            (acc, val) => acc + val,
            0
          );
          const percentage = Math.round((value / total) * 100);
          return `${percentage}%`;
        },
        anchor: "center",
        align: "center",
        font: {
          weight: "bold",
          size: 16,
        },
      },
    },
  };

  //--------------------------------------------------------------------------------
  // Pai chart Proparties
  //--------------------------------------------------------------------------------
  const barOptions = {
    responsive: true,
    layout: {
      padding: {
        left: 10,
      },
    },
    plugins: {
      datalabels: {
        color: "#ffffff",
        anchor: "end",
        align: "start",
        formatter: (value) => `${value} min`,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        position: "left",
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Time Spent (min)",
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
        },
        categoryPercentage: 0.5,
        barPercentage: 0.5,
      },
    },
  };

  //--------------------------------------------------------------------------------
  // Pai chart Options
  //--------------------------------------------------------------------------------
  const barData = {
    labels: ["Correct", "Incorrect"],
    datasets: [
      {
        data: [timeData.correctTime, timeData.incorrectTime],
        backgroundColor: ["green", "red"],
        barThickness: 100,
      },
    ],
  };

  return (
    <div className="performace-container">
      <div className="performace-chartContainer">
        <div className="pie-chart-center-alignemnt">
          <div className="performace-headingpieChart">
            Answers Result Status
          </div>
          <div className="chart-wrapper-pie">
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        </div>

        <div className="performace-barChartComponent">
          <div className="performace-headingbarChart">
            Time Spent on Questions
          </div>

          <div className="chart-wrapper-bar">
            <Bar data={barData} options={barOptions} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultDashboard2;
