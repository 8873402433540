import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {
  TextField,
  Grid,
  Button,
  IconButton,
  Autocomplete,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import UpdateIcon from "@mui/icons-material/Update";
import {
  getCurrentDateTime,
  getUtcTime,
} from "../../Common/Utilities_Function";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import "./employee.css";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";

function EmployeeListAddEditModal({
  openAddModal,
  handleModalClose,
  SetDataBaseUpdate,
  editData, // Prop to handle edit functionality
}) {
  const [loginData, setLoginData] = useState([]);
  const [formData, setFormData] = useState({
    EmployeeName: "",
    EmployeeEmail: "",
    EmployeePhoneNumber: "",
    EmployeeStatus: "WIP",
    Created_by: localStorage.getItem("HTES_user_id"),
    Created_date: getCurrentDateTime(),
    Modified_by: localStorage.getItem("HTES_user_id"),
    Modified_date: getCurrentDateTime(),
    UTC_Time: getUtcTime(),
  });
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("in"); // Default country code
  const [errors, setErrors] = useState({});
  const [emailOptions, setEmailOptions] = useState([]);

  //--------------------------------------------------------------------------------
  // Fetch login data excluding admin
  //--------------------------------------------------------------------------------

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/getLoginTable`);
      const filteredData = response.data.filter(
        (user) => user.usertype !== "Admin"
      );
      setLoginData(filteredData);
      setEmailOptions(filteredData.map((user) => user.email));
    } catch (error) {
      console.error("Error fetching login data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //--------------------------------------------------------------------------------
  // Populate form fields if editData is provided
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (editData) {
      setFormData({
        EmployeeName: editData.EmployeeName || "",
        EmployeeEmail: editData.EmployeeEmail || "",
        EmployeePhoneNumber: editData.EmployeePhoneNumber || "",
        EmployeeStatus: editData.EmployeeStatus || "WIP",
        Created_by: editData.Created_by || localStorage.getItem("HTES_user_id"),
        Created_date: editData.Created_date || getCurrentDateTime(),
        Modified_by: localStorage.getItem("HTES_user_id"),
        Modified_date: getCurrentDateTime(),
        UTC_Time: getUtcTime(),
      });
      setPhone(`+${editData.EmployeePhoneNumber}` || "");
      setCountryCode(
        parsePhoneNumberFromString(editData.EmployeePhoneNumber)?.country ||
          "IN"
      );
    } else {
      // Reset form when there is no editData
      setFormData({
        EmployeeName: "",
        EmployeeEmail: "",
        EmployeePhoneNumber: "",
        EmployeeStatus: "WIP",
        Created_by: localStorage.getItem("HTES_user_id"),
        Created_date: getCurrentDateTime(),
        Modified_by: localStorage.getItem("HTES_user_id"),
        Modified_date: getCurrentDateTime(),
        UTC_Time: getUtcTime(),
      });
      setPhone("");
    }
  }, [editData, openAddModal]);

  //--------------------------------------------------------------------------------
  // Handle input change
  //--------------------------------------------------------------------------------

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  };

  //--------------------------------------------------------------------------------
  // Handle phone number change
  //--------------------------------------------------------------------------------

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.countryCode.toUpperCase());

    // Parse and validate the phone number
    const phoneNumber = parsePhoneNumberFromString(
      value,
      country.countryCode.toUpperCase()
    );
    setErrors((prev) => ({ ...prev, EmployeePhoneNumber: "" }));
  };

  //--------------------------------------------------------------------------------
  // Handle email selection from Autocomplete
  //--------------------------------------------------------------------------------

  const handleEmailChange = (event, value) => {
    setFormData((prev) => ({ ...prev, EmployeeEmail: value || "" }));
    if (value) {
      const selectedUser = loginData.find((user) => user.email === value);
      if (selectedUser) {
        setPhone("91" + selectedUser.mobile || ""); // Update phone state
        setCountryCode("IN"); // Assume India if mobile number is not valid
        setFormData((prev) => ({
          ...prev,
          EmployeeName: selectedUser.name || "",
          EmployeePhoneNumber: selectedUser.mobile || "",
        }));
      }
    }
  };

  //--------------------------------------------------------------------------------
  // Validate the form
  //--------------------------------------------------------------------------------

  const validate = () => {
    let tempErrors = {};
    tempErrors.EmployeeName = formData.EmployeeName
      ? ""
      : "Employee Name is required";
    tempErrors.EmployeeEmail = formData.EmployeeEmail
      ? /\S+@\S+\.\S+/.test(formData.EmployeeEmail)
        ? ""
        : "Email is not valid"
      : "Employee Email is required";

    // Ensure phone is a string and format it correctly
    const phoneStr = String(phone).trim();
    const phoneNumber = parsePhoneNumberFromString(phoneStr, countryCode);
    if (phoneNumber) {
      tempErrors.EmployeePhoneNumber = phoneNumber.isValid()
        ? ""
        : "Phone number is not valid";
    } else {
      tempErrors.EmployeePhoneNumber = "Employee Phone Number is not valid";
    }

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  //--------------------------------------------------------------------------------
  // Handle form submission
  //--------------------------------------------------------------------------------

  const handleSave = async () => {
    if (validate()) {
      const formattedPhone = phone.replace("+", "");

      const updatedFormData = {
        ...formData,
        EmployeePhoneNumber: formattedPhone,
        EmployeeStatus: "WIP",
        Created_by: localStorage.getItem("HTES_user_id"),
        Created_date: getCurrentDateTime(),
        Modified_by: localStorage.getItem("HTES_user_id"),
        Modified_date: getCurrentDateTime(),
        UTC_Time: getUtcTime(),
      };

      try {
        let response;

        if (editData) {
          // Update existing employee data
          response = await axios.put(
            `${baseURL}/Employees_edit/${editData.id}`,
            updatedFormData
          );
        } else {
          // Add new employee
          response = await axios.post(`${baseURL}/Employees`, [
            updatedFormData,
          ]);
        }

        // Check response status
        if (response.status === 200) {
          const successMessage = editData
            ? "Employee Info Updated Successfully"
            : "New Employee Added Successfully";
          customAlert("", successMessage, "success");

          // Reset form data after successful submission
          setFormData({
            EmployeeName: "",
            EmployeeEmail: "",
            EmployeePhoneNumber: "",
          });
          setPhone("");
          setErrors({});
          SetDataBaseUpdate(true);
          handleModalClose();
        } else if (response.status === 400) {
          customAlert("", "Employee already available", "warning");
        }
      } catch (error) {
        console.error("Error saving employee data:", error);

        if (error.response) {
          // If there's a specific error response from the server
          if (error.response.status === 400) {
            customAlert("", "Employee already available", "warning");
          } else {
            customAlert(
              "",
              "An error occurred while saving employee data",
              "error"
            );
          }
        } else {
          // Handle any other errors (network issues, etc.)
          customAlert("", "An unexpected error occurred", "error");
        }
      }
    }
  };

  return (
    // _____________________Modal___________________//
    <Modal open={openAddModal} onClose={handleModalClose}>
      <div className="employeemodal">
        <IconButton
          id="button-zoom"
          className="employeemodalcancel"
          onClick={handleModalClose}
        >
          <CloseIcon className="employeemodalcancelX" />
        </IconButton>
        <div className="employeemodalHeader">
          {editData ? "Edit Employee" : "Add New Employee"}
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {/* ____________________Text fileds______________ */}
            <Autocomplete
              freeSolo
              options={emailOptions}
              value={formData.EmployeeEmail}
              onChange={handleEmailChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employee Email"
                  error={!!errors.EmployeeEmail}
                  helperText={errors.EmployeeEmail}
                  onChange={(e) =>
                    handleInputChange("EmployeeEmail", e.target.value)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Employee Name"
              value={formData.EmployeeName}
              onChange={(e) =>
                handleInputChange("EmployeeName", e.target.value)
              }
              error={!!errors.EmployeeName}
              helperText={errors.EmployeeName}
            />
          </Grid>
          {/* _____________________End_________________________ */}
          <Grid item xs={12}>
            {/* _________________phone section_____________ */}
            <div
              className={errors.EmployeePhoneNumber ? "phone-input-error" : ""}
            >
              <PhoneInput
                country={countryCode.toLowerCase()}
                value={phone}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: "100%",
                  height: "55px",
                  fontSize: "16px",
                  outline: errors.EmployeePhoneNumber ? "1px solid red" : "",
                }}
                buttonStyle={{
                  background: "white",
                  borderLeft: errors.EmployeePhoneNumber ? "2px solid red" : "",
                  borderTop: errors.EmployeePhoneNumber ? "2px solid red" : "",
                  borderBottom: errors.EmployeePhoneNumber
                    ? "2px solid red"
                    : "",
                  borderRight: "none",
                }}
                dropdownStyle={{
                  fontSize: "16px",
                }}
                placeholder="Employee Phone Number"
              />
              {errors.EmployeePhoneNumber && (
                <div className="helper-text-phone-container">
                  {errors.EmployeePhoneNumber}
                </div>
              )}
            </div>
          </Grid>
          {/* _____________Button section________________ */}
          <Grid item xs={12} className="modal-footer">
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={handleSave}
              startIcon={
                editData ? (
                  <UpdateIcon className="buttonicons" />
                ) : (
                  <SaveIcon className="buttonicons" />
                )
              }
            >
              {editData ? "update" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export default EmployeeListAddEditModal;
