import React, { useState, useEffect } from "react";
import Editor1 from "../../Editor/Editor1";
import { Modal, Button, IconButton, Menu, MenuItem } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL } from "../../../http";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { customAlert } from "../../SweetAlertCommon/Custom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './ContentRender.css';

function ContentMainSection({ sections, updateSections, selectedSubTopic, selectedSubTopicId, onNext, onPrevious }) {

  //----------------------------------------------------------------
  //State Handle
  //----------------------------------------------------------------
  const [showEditor, setShowEditor] = useState(false);
  const [content, setContent] = useState("");
  const [localHeading, setHeadingValue] = useState("");
  const [currentTopic, setCurrentTopic] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItemId, setMenuItemId] = useState(null);
  const [post, setPost] = useState([]);
  const [currentSubSection, setCurrentSubSection] = useState(null);
  const [userType, setUserType] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [sectionContent, setSectionContent] = useState({});

  //----------------------------------------------------------------
  //useEffect to store User Type
  //----------------------------------------------------------------
  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    setUserType(storedUserType);
  }, []);

  //----------------------------------------------------------------
  // Fetch content for the selected section
  //----------------------------------------------------------------
  const fetchContentBySection = async (sectionId) => {
    try {
      const response = await axios.get(`${baseURL}/getaddedcontent/section/${sectionId}`);
      if (response.status === 200) {
        setSectionContent((prevContent) => ({
          ...prevContent,
          [sectionId]: response.data || [],
        }));
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  useEffect(() => {
    const sectionId = selectedSubTopicId || (sections.length > 0 ? sections[0].id : null);
    if (sectionId) {
      fetchContentBySection(sectionId);
    }
  }, [sections, selectedSubTopicId]);

  //----------------------------------------------------------------
  // Fetch content for the first section on mount
  //----------------------------------------------------------------
  useEffect(() => {
    if (sections.length > 0) {
      const firstSectionId = sections[0].id;
      fetchContentBySection(firstSectionId);
    }
  }, [sections]);

  // Disable image resizing and remove unwanted content after rendering
  useEffect(() => {
    if (post.length > 0) {
      // Disable image resizing
      const containers = document.querySelectorAll('.editor-image-container');
      containers.forEach((container) => {
        container.style.resize = 'none';
        container.style.overflow = 'hidden'; // Hide any overflow
      });

      // Hide the edit and copy buttons
      const editButtons = document.querySelectorAll('.edit-button');
      const copyButtons = document.querySelectorAll('.copy-button');
      editButtons.forEach(button => button.style.display = 'none');
      copyButtons.forEach(button => button.style.display = 'none');

      // Remove unwanted text from the content
      const subSectionContentElements = document.querySelectorAll('.sub-section-content');
      subSectionContentElements.forEach(contentElement => {
        // Check for text nodes with unwanted characters
        const textNodes = Array.from(contentElement.childNodes);
        textNodes.forEach((node) => {
          if (node.nodeType === Node.TEXT_NODE && node.nodeValue.includes('â€‹')) {
            node.parentNode.removeChild(node); // Remove unwanted text nodes
          }
        });

        // Additional cleanup using regex
        contentElement.innerHTML = contentElement.innerHTML.replace(/â€‹/g, '');
      });
    }
  }, [post]);

  //----------------------------------------------------------------
  //Function to Add content
  //----------------------------------------------------------------
  const handleAddContent = (section) => {
    const id = selectedSubTopicId || section.id;
    if (selectedSubTopicId) {
      console.log("Adding content for sub-topic with ID:", id);
    } else {
      console.log("Adding content for topic with ID:", id);
    }
    setCurrentId(id);
    setCurrentTopic(section);
    setShowEditor(true);
  };

  const handleClose = () => {
    setShowEditor(false);
    setContent("");
    setHeadingValue("");
    setIsEditing(false); // Reset editing state
    setAnchorEl(null);
  };

  //----------------------------------------------------------------
  // Save or update content for a specific section
  //----------------------------------------------------------------
  const handleSave = async () => {
    try {
      const sectionId = selectedSubTopicId || currentId;
      if (!sectionId) {
        throw new Error("Section ID is missing. Please select a valid section.");
      }

      const contentEditor = document.getElementById("edt");
      const fullContent = contentEditor.innerHTML;
      const updatedContent = await processImagesInContent(fullContent);
      const normalContent = extractTextWithImages(contentEditor);

      const payload = {
        heading: localHeading,
        content: updatedContent,
        normalContent,
        content_section_id: sectionId,
      };

      let response;
      if (isEditing) {
        response = await axios.put(`${baseURL}/updateaddcontent/${currentSubSection.id}`, payload);
      } else {
        response = await axios.post(`${baseURL}/education_addcontent`, payload);
      }

      if (response.status === (isEditing ? 200 : 201)) {
        const updatedSection = response.data.data;
        updateSections((prevSections) => {
          return prevSections.map((section) => {
            if (section.id === currentId) {
              return {
                ...section,
                sections: section.sections
                  ? isEditing
                    ? section.sections.map((sub) => (sub.id === currentSubSection.id ? updatedSection : sub))
                    : [...(section.sections || []), updatedSection]
                  : [updatedSection],
              };
            }
            return section;
          });
        });

        handleClose();
        customAlert("", `Content ${isEditing ? 'Updated' : 'Added'} Successfully`, "success");
      }
    } catch (error) {
      console.error("Error saving content:", error);
      customAlert("", "There was an error saving the content", "warning");
    }
  };


  // Helper function to process images to base64
  const processImagesInContent = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const images = doc.querySelectorAll("img");

    for (const img of images) {
      if (!img.src.startsWith("data:")) {
        const base64Image = await convertImageToBase64(img.src);
        img.src = base64Image;
      }
    }
    return doc.body.innerHTML;
  };

  // Helper function to extract text content while preserving image URLs
  const extractTextWithImages = (contentEditor) => {
    const clonedNode = contentEditor.cloneNode(true);
    const images = clonedNode.querySelectorAll("img");

    images.forEach((img) => {
      const imgTextNode = document.createTextNode(`[Image: ${img.src}]`);
      img.replaceWith(imgTextNode);
    });

    return clonedNode.textContent || clonedNode.innerText || "";
  };

  // Helper to convert image URL to base64
  const convertImageToBase64 = (imageUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };
      img.onerror = reject;
      img.src = imageUrl;
    });
  };

  //----------------------------------------------------------------
  //Function to Edit the content
  //----------------------------------------------------------------
  const handleEdit = (subSection) => {
    if (subSection) {
      setIsEditing(true);
      setCurrentSubSection(subSection);
      setHeadingValue(subSection.heading);
      setContent(subSection.content);
      setCurrentId(subSection.content_section_id);
      setShowEditor(true);
      setAnchorEl(null);
    }
  };

  //----------------------------------------------------------------
  // Handle Delete action
  //----------------------------------------------------------------
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseURL}/deleteaddcontent/${id}`);
      if (response.status === 200) {
        updateSections((prevSections) => {
          return prevSections.map(section => {
            if (section.id === currentId) {
              return {
                ...section,
                sections: section.sections.filter(subSection => subSection.id !== id)
              };
            }
            return section;
          });
        });
        customAlert("", "Content deleted Successfully", "success");
      }
    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  //----------------------------------------------------------------
  //Function for opening edit and delete using more vert icon
  //----------------------------------------------------------------
  const handleMoreVertClick = (event, subSectionId) => {
    setAnchorEl(event.currentTarget);
    setMenuItemId(subSectionId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuItemId(null);
  };

  //----------------------------------------------------------------
  // Drag and drop handler
  //----------------------------------------------------------------
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(sectionContent[currentId]);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSectionContent((prevContent) => ({
      ...prevContent,
      [currentId]: items,
    }));
  };

  const sectionsByTopic = sections.reduce((acc, section) => {
    if (!acc[section.topic]) {
      acc[section.topic] = [];
    }
    acc[section.topic].push(section);
    return acc;
  }, {});

  return (
    <div className="content_render_section">

      {/* _______________Content render section__________________ */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {Object.keys(sectionsByTopic).map((topic, topicIndex) => (
          <Droppable key={topicIndex} droppableId={topic}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="topic-section">

                {/* _____________Previous next and add content button_______________ */}
                <div className="topic-header">
                  <Button variant="contained" className="content-previous-button" onClick={onPrevious}>
                    <ArrowBackIosIcon /> Previous
                  </Button>
                  {userType === "Admin" && (
                    <button className="add-content-button" onClick={() => handleAddContent({ id: sectionsByTopic[topic][0]?.id, topic })}>
                      <AddIcon /> Add Content
                    </button>
                  )}
                  <Button variant="contained" className="content-next-button" onClick={onNext}>
                    Next <ArrowForwardIosIcon />
                  </Button>
                </div>

                {/* ________________Selected topic or sub-topic with content_______________________ */}

                <h3>{selectedSubTopic || topic}</h3>
                <hr className="horizontal-line" />
                {sectionContent[selectedSubTopicId || sectionsByTopic[topic][0]?.id]?.length > 0 ? (
                  sectionContent[selectedSubTopicId || sectionsByTopic[topic][0].id].map((subSection, index) => (
                    <Draggable key={subSection.id} draggableId={subSection.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={`sub-section ${snapshot.isDragging ? 'dragging' : ''}`}>
                          <h4 className="sub-section-heading"
                            style={{
                              backgroundColor: snapshot.isDragging ? 'lightblue' : 'initial',
                              color: snapshot.isDragging ? 'white' : 'initial',
                              border: 'none', // Ensure no borders
                            }}>
                            {subSection.heading}
                            {userType === "Admin" && (
                              <>
                                <IconButton aria-label="more" onClick={(e) => handleMoreVertClick(e, subSection.id)} className="more-vert-button">
                                  <MoreVertIcon />
                                </IconButton>
                                {menuItemId === subSection.id && (
                                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                    <MenuItem onClick={() => handleEdit(subSection)}>
                                      <EditIcon className="content-edit-icon" /> Edit
                                    </MenuItem>
                                    <MenuItem onClick={() => handleDelete(subSection.id)}>
                                      <DeleteIcon className="content-delete-icon" /> Delete
                                    </MenuItem>
                                  </Menu>
                                )}
                              </>
                            )}
                          </h4>
                          <p dangerouslySetInnerHTML={{ __html: subSection.content }} className="sub-section-content" />
                          <hr className="horizontal-line" />
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p>No content available.</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>

      {/*______________________Modal for Editor_________________________*/}
      <Modal open={showEditor} onClose={handleClose} aria-labelledby="editor-modal-title" aria-describedby="editor-modal-description" className="editor-modal">
        <div className="modal-content">
          <div>
            <IconButton id="button-zoom" className="enquiry-form-addeducationalMapmodalcancel" onClick={handleClose}>
              <CloseIcon className="enquiry-form-addeducationalMapmodalcancelX" />
            </IconButton>
          </div>
          <Editor1
            isHeadingRequird={true}
            content={content}
            setHeading={setHeadingValue}
            setContent={setContent}
            header_label="Heading"
            is_Editor_HeaderRequired={true}
            is_tag_required={false}
            is_tag_Option_required={false}
            return_location="/dashboard"
            editID={"editID"}
            Button_Name={null}
            setHeadingValue={setHeadingValue}
            headingValue={localHeading}
            desktopHeight={"300px"}
            mobileHeight={"200px"}
          />
          <div className="modal-actions">
            <Button variant="contained" className="BlogSave" onClick={handleSave}>
              <SaveIcon className="buttoniconcommon" />
              {isEditing ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ContentMainSection;