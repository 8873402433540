import React from 'react';
import ContentTemplate from './ContentTemplate';

function Content({selectedCourse,courseContent,setCourseContent}) {
  // const [courseContent, setCourseContent] = useState([]);
  console.log(courseContent)

  return (
    <div>
      <ContentTemplate
      selectedCourse={selectedCourse}
        title="Course Content"
        courseContent={courseContent}
        setCourseContent={setCourseContent}
      />
    </div>
  );
}

export default Content;
