import React, { useEffect } from "react";
import { MDBRadio, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { TextField, Button, Autocomplete } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";
import { languageOptions } from "./PmCommonFunction";
import { customAlert } from "../SweetAlertCommon/Custom";

function PmTab13PersonalDetailModal({
  gender,
  setGender,
  category,
  setCategory,
  maritalStatus,
  setMaritalStatus,
  moreInfo,
  setMoreInfo,
  dateOfBirth,
  setDateOfBirth,
  differentlyAbled,
  setDifferentlyAbled,
  careerBreak,
  setCareerBreak,
  address,
  setAddress,
  hometown,
  setHometown,
  pincode,
  setPincode,
  addPersDetail,
  languageCount,
  setLanguageCount,
  languageArray,
  setLanguageArray,
}) {


  //--------------------------------------------------------------------------------
  //  Function - handleLanguageNameChange
  //--------------------------------------------------------------------------------
  
  const handleLanguageNameChange = (index, field, value) => {
    const lowerCaseValue = value?.toLowerCase();
  
    if (lowerCaseValue && languageArray.some(language => language.language?.toLowerCase() === lowerCaseValue)) {
      customAlert("", "Already Exists", "warning");
      
    } else {
      const updatedLanguages = [...languageArray];
      updatedLanguages[index][field] = value;
      setLanguageArray(updatedLanguages);
    }
  };

  
  //--------------------------------------------------------------------------------
  //  Function - handleLanguageRadioChange
  //--------------------------------------------------------------------------------
  
  const handleLanguageRadioChange = (index, field, value) => {
   
      const updatedLanguages = [...languageArray];
      updatedLanguages[index][field] = value;
      setLanguageArray(updatedLanguages);
    
  };

  //--------------------------------------------------------------------------------
  //  Function - handleDeleteLanguage
  //--------------------------------------------------------------------------------
  
  const handleDeleteLanguage = (index) => {
    const updatedLanguages = languageArray.filter((item, idx) => idx !== index);
    setLanguageArray(updatedLanguages);
    setLanguageCount(languageCount - 1);
  };

  //--------------------------------------------------------------------------------
  //  useEffect - Initial Addition
  //--------------------------------------------------------------------------------
  
  useEffect(() => {
    handleAddLanguage();
  }, []);
  const handleAddLanguage = () => {
    if (languageCount < 5) {
      // Find the next available ID in the range of 1 to 5
      const existingIds = languageArray.map(language => language.id);
      let newId = 1;
      while (existingIds.includes(newId) && newId <= 5) {
        newId++;
      }
  
      if (newId <= 5) {
        const newLanguage = {
          id: newId,
          language: "",
          read: false,
          write: false,
          speak: false,
        };
  
        setLanguageArray([...languageArray, newLanguage]);
        setLanguageCount(languageCount + 1);
      }
    }
  };
  
  const selectedLanguages = languageArray.map(lang => lang.language);

  return (
    <div lassName="profileModal">
    <h5 className="pmTabHeadings">Personal Details</h5>
    <div className="pmModalContainer">
        
        <>
          {/*_______________________________ Gender _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Gender</label>
            <br />
            <MDBCol>
              <button
                onClick={() => setGender("Male")}
                className={
                  gender === "Male"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Male
              </button>
              <button
                onClick={() => setGender("Female")}
                className={
                  gender === "Female"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Female
              </button>
              <button
                onClick={() => setGender("Transgender")}
                className={
                  gender === "Transgender"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Transgender
              </button>
            </MDBCol>
          </MDBRow>
          <br />
          {/*_______________________________ More information _______________________________*/}
          <MDBRow>
            <label className="pmLabel">More information</label>
            <br />
            <MDBCol>
              <button
                onClick={() => setMoreInfo("Single parent +")}
                className={
                  moreInfo === "Single parent +"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Single parent +
              </button>
              <button
                onClick={() => setMoreInfo("Working mother +")}
                className={
                  moreInfo === "Working mother +"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Working mother +
              </button>
              <button
                onClick={() => setMoreInfo("Served in military +")}
                className={
                  moreInfo === "Served in military +"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Served in military +
              </button>
              <button
                onClick={() => setMoreInfo("Retired (60+)")}
                className={
                  moreInfo === "Retired (60+)"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Retired (60+)
              </button>
              <button
                onClick={() => setMoreInfo("LGBTQ+")}
                className={
                  moreInfo === "LGBTQ+"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                LGBTQ+
              </button>
            </MDBCol>
          </MDBRow>
          <br />
          {/*_______________________________ Marital Status _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Marital Status</label>
            <br />
            <MDBCol>
              <button
                onClick={() => setMaritalStatus("Single/unmarried")}
                className={
                  maritalStatus === "Single/unmarried"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Single/unmarried
              </button>
              <button
                onClick={() => setMaritalStatus("Married")}
                className={
                  maritalStatus === "Married"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Married
              </button>
              <button
                onClick={() => setMaritalStatus("Widowed")}
                className={
                  maritalStatus === "Widowed"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Widowed
              </button>
              <button
                onClick={() => setMaritalStatus("Divorced")}
                className={
                  maritalStatus === "Divorced"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Divorced
              </button>
              <button
                onClick={() => setMaritalStatus("Separated")}
                className={
                  maritalStatus === "Separated"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Separated
              </button>
              <button
                onClick={() => setMaritalStatus("Other")}
                className={
                  maritalStatus === "Other"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Other
              </button>
            </MDBCol>
          </MDBRow>
          <br />
          {/*_______________________________ Date of Birth _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Date of Birth</label>
            <br />
            <MDBRow>
              <MDBCol>
                <TextField
                  required
                  type="date"
                  fullWidth
                  value={dateOfBirth}
                  onChange={(event) => setDateOfBirth(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { style: { textTransform: "uppercase" } },
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBRow>
          <br />
          {/*_______________________________ Category _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Category</label>
            <br />
            <MDBCol>
              <button
                onClick={() => setCategory("General")}
                className={
                  category === "General"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                General
              </button>
              <button
                onClick={() => setCategory("Scheduled Caste (SC)")}
                className={
                  category === "Scheduled Caste (SC)"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Scheduled Caste (SC)
              </button>
              <button
                onClick={() => setCategory("Scheduled Tribe (ST)")}
                className={
                  category === "Scheduled Tribe (ST)"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Scheduled Tribe (ST)
              </button>
              <button
                onClick={() => setCategory("OBC - Creamy")}
                className={
                  category === "OBC - Creamy"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                OBC - Creamy
              </button>
              <button
                onClick={() => setCategory("OBC - Non Creamy")}
                className={
                  category === "OBC - Non Creamy"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                OBC - Non Creamy
              </button>
              <button
                onClick={() => setCategory("Other")}
                className={
                  category === "Other"
                    ? "pmPersDetailBtn pmSelectedbtn"
                    : " pmPersDetailBtn "
                }
              >
                Other
              </button>
            </MDBCol>
          </MDBRow>
          <br />
          {/*_______________________________ Differently Abled? _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Differently Abled?</label>
            <br />
            <MDBCol>
              <MDBRadio
                name="diffAbled"
                id="inlineRadio1"
                value="Yes"
                label="Yes"
                inline
                checked={differentlyAbled === "Yes"}
                onChange={() => setDifferentlyAbled("Yes")}
              />
              <MDBRadio
                name="diffAbled"
                id="inlineRadio2"
                value="No"
                label="No"
                inline
                checked={differentlyAbled === "No"}
                onChange={() => setDifferentlyAbled("No")}
              />
            </MDBCol>
          </MDBRow>
          <br />
          {/*_______________________________ Career break? _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Have you taken a career break?</label>
            <br />
            <MDBCol>
              <MDBRadio
                name="careerBreak"
                id="inlineRadio3"
                value="Yes"
                label="Yes"
                inline
                checked={careerBreak === "Yes"}
                onChange={() => setCareerBreak("Yes")}
              />
              <MDBRadio
                name="careerBreak"
                id="inlineRadio4"
                value="No"
                label="No"
                inline
                checked={careerBreak === "No"}
                onChange={() => setCareerBreak("No")}
              />
            </MDBCol>
          </MDBRow>
          <br />
          {/*_______________________________ Permanent Address _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Permanent Address</label>
            <br />
            <div className="formProfile">
              <TextField
                fullWidth
                multiline
                // rows={6}
                minRows={3}
                maxRows={8}
                value={address || ""}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <br />
          </MDBRow>
          <br />
          {/*_______________________________ Hometown _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Hometown</label>
            <br />
            <div className="formProfile">
              <TextField
                fullWidth
                value={hometown || ""}
                onChange={(e) => setHometown(e.target.value)}
              />
            </div>
            <br />
          </MDBRow>
          <br />
          {/*_______________________________ Pincode _______________________________*/}
          <MDBRow>
            <label className="pmLabel">Pincode</label>
            <br />
            <div className="formProfile">
              <TextField
                fullWidth
                value={pincode || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (/^\d{0,6}$/.test(value))) {
                    setPincode(value);
                  }
                }}
              />
            </div>
            <br />
          </MDBRow>
          <br />
          </>



          {/*___________________________________ Language Inputs ___________________________________*/}
          {languageArray.map((language, index) => (
          <MDBRow key={index} className="PmPersonalLang">
            <label className="pmLabel">Language </label>
            <MDBCol>
            <Autocomplete
              fullWidth
              options={languageOptions.filter(option => 
                !selectedLanguages.some(lang => lang && lang.toLowerCase() === option.toLowerCase())
              )}
              getOptionLabel={(option) => option}
              value={language.language}
              onChange={(e, newValue) => handleLanguageNameChange(index, "language", newValue)}
              isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  value={language.language || ""}
                  onChange={(e) => handleLanguageNameChange(index, "language", e.target.value)}
                />
              )}
            />
            </MDBCol>
            <MDBCol>
              <Checkbox
                checked={language.read}
                onChange={(e) => handleLanguageRadioChange(index, "read", e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span>Read</span>
            </MDBCol>
            <MDBCol>
              <Checkbox
                checked={language.write}
                onChange={(e) => handleLanguageRadioChange(index, "write", e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span>Write</span>
            </MDBCol>
            <MDBCol>
              <Checkbox
                checked={language.speak}
                onChange={(e) => handleLanguageRadioChange(index, "speak", e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span>Speak</span>
            </MDBCol>
            <MDBCol>
              <span
                onClick={() => handleDeleteLanguage(index)}
                className="PmPersonal_DeleteLang"
              >
                Delete
              </span>
            </MDBCol>
          </MDBRow>
        ))}
        {/* Add More Language Button */}
        <MDBRow>
          {languageArray.length < 4 && (
            <span onClick={handleAddLanguage} className="PmPersonalLangAdd">
              Add Language
            </span>
          )}
          <br />
          <br />
        </MDBRow>
      </div>
        {/* _______________________________    Save & Cancel Buttons   _______________________________ */}
        <div className="createteslayoutmodel6">
          <Button
            id="button-zoom"
            variant="contained"
            color="success"
            onClick={addPersDetail}
          >
            <SaveIcon className="buttonicons" />
            Save
          </Button>
        </div>
    </div>
  );
}

export default PmTab13PersonalDetailModal;