import React, { useEffect, useState, useRef } from "react";
import ResultDashboardPageSection1 from "./ResultDashboardPageSection1";
import ResultDashboard2 from "./ResultDashboardPageSection2";
import ResultDashboardPageSection3 from "./ResultDashboardPageSection3";
import ResultTable from "./ResultTable";
import axios from "axios";
import { baseURL } from "../../http";
import ProcessProgressCircle from "../ProcessProgressCircle/ProcessProgressCircle";
import ResultLandingPage from "./ResultCardView/ResultLandingPage";
import ResultCardViewHeading from "./ResultCardView/ResultCardViewHeading";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { IconButton, Tooltip } from "@mui/material";

function ResultDashboardPageMain({ handleResultViewClose, ResultData }) {
  const [generalInfo, setGeneralInfo] = useState(null);
  const [allAiQuestions, setAllAiQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const contentRef = useRef();

  //--------------------------------------------------------------------------------
  // Fetch general info when ResultData.TestId is available
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchGeneralInfo = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/gettestgeninfo_specific/${ResultData.TestId}`
        );
        setGeneralInfo(response.data);
      } catch (error) {
        console.error("Error fetching general info:", error);
      }
    };

    if (ResultData?.TestId) {
      fetchGeneralInfo();
    }
  }, [ResultData?.TestId]);

  //--------------------------------------------------------------------------------
  // Fetch all AI-generated questions when generalInfo becomes available
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchAiGeneratedQuestions = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/getAllAiGeneratedQuestions`
        );
        setAllAiQuestions(response.data);
      } catch (error) {
        console.error("Error fetching AI-generated questions:", error);
      }
    };

    if (generalInfo) {
      fetchAiGeneratedQuestions();
    }
  }, [generalInfo]);

  //--------------------------------------------------------------------------------
  // Filter questions based on QuestionIds in generalInfo
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (generalInfo && allAiQuestions.length > 0) {
      const filtered = allAiQuestions.filter((question) =>
        generalInfo.QuestionIds.includes(question.id)
      );
      setFilteredQuestions(filtered);
    }
  }, [generalInfo, allAiQuestions]);

  //--------------------------------------------------------------------------------
  // Handle download the pdf
  //--------------------------------------------------------------------------------
  const handleDownloadPDF = async () => {
    const content = contentRef.current;

    // Temporarily set a white background for the content
    content.style.backgroundColor = "#ffffff";

    // Render the entire content as a canvas using html2canvas
    const originalCanvas = await html2canvas(content, {
      scale: 2.1,
      backgroundColor: "#ffffff",
      useCORS: true,
      windowWidth: 1200,
    });

    // Revert background color if it was changed temporarily
    content.style.backgroundColor = "";

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const marginTop = 10; // Top margin for header
    const marginBottom = 15; // Bottom margin for footer
    const imgWidth = pdfWidth - 20; // Width of the image (with margins)

    let offsetY = 0;
    const pageHeightLeft = pdfHeight - marginTop - marginBottom; // Adjust for top and bottom margins

    // Loop through the content and split across pages
    while (offsetY < originalCanvas.height) {
      // Add header
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 255);
      // const headerWidth = pdf.getTextWidth(headerText);
      // pdf.text(headerText, (pdf.internal.pageSize.width - headerWidth) / 2, 15); // Center the header

      // Create a new canvas for the page
      const pageCanvas = document.createElement("canvas");
      pageCanvas.width = originalCanvas.width;
      pageCanvas.height = pageHeightLeft * (originalCanvas.width / imgWidth);

      const pageCtx = pageCanvas.getContext("2d");

      // Fill page canvas with a white background before drawing content
      pageCtx.fillStyle = "#ffffff";
      pageCtx.fillRect(0, 0, pageCanvas.width, pageCanvas.height);

      // Draw the current section of the original canvas onto the page canvas
      pageCtx.drawImage(
        originalCanvas,
        0,
        offsetY,
        originalCanvas.width,
        pageCanvas.height,
        0,
        0,
        pageCanvas.width,
        pageCanvas.height
      );

      const pageData = pageCanvas.toDataURL("image/jpeg", 0.85);
      pdf.addImage(pageData, "JPEG", 10, marginTop, imgWidth, pageHeightLeft);

      // Add footer
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0); // Reset text color to black for footer
      const footerTextWithNumber = `Page ${pdf.internal.getNumberOfPages()}`;
      const footerWidth = pdf.getTextWidth(footerTextWithNumber);
      pdf.text(
        footerTextWithNumber,
        (pdf.internal.pageSize.width - footerWidth) / 2,
        pdfHeight - marginBottom + 5
      ); // Center footer text

      offsetY += pageCanvas.height;

      // Add a new page if there's more content
      if (offsetY < originalCanvas.height) {
        pdf.addPage();
      }
    }

    // Save the PDF with the desired name
    const userId = ResultData.UserId.split("@")[0]; // Get part before "@"
    pdf.save(`Test_Result_${ResultData.TestName}_${userId}.pdf`);
  };

  return (
    <>
      <div className="Download-icon-alignment-and-keep-the-corner">
        <Tooltip title="Download the result" arrow>
          <IconButton
            className="icon-button-with-backgroundcolor"
            onClick={handleDownloadPDF}
          >
            <DownloadForOfflineIcon />
          </IconButton>
        </Tooltip>
      </div>
      {filteredQuestions.length === 0 ? (
        <ProcessProgressCircle />
      ) : (
        <>
          <div ref={contentRef}>
            <ResultDashboardPageSection1
              handleResultViewClose={handleResultViewClose}
              ResultData={ResultData}
              generalInfo={generalInfo}
              filteredQuestions={filteredQuestions}
            />
            <ResultDashboard2 ResultData={ResultData} />
            <ResultDashboardPageSection3
              ResultData={ResultData}
              filteredQuestions={filteredQuestions}
              generalInfo={generalInfo}
            />
            <ResultTable
              filteredQuestions={filteredQuestions}
              ResultData={ResultData}
            />
          </div>
          <ResultCardViewHeading
            ResultData={ResultData}
            filteredQuestions={filteredQuestions}
          />
          <ResultLandingPage
            filteredQuestions={filteredQuestions}
            ResultData={ResultData}
          />
        </>
      )}
    </>
  );
}

export default ResultDashboardPageMain;
