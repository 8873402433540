import React, { useState } from "react";
import {
  Grid,
  Paper,
  Input,
  InputLabel,
  Box,
  FormControl,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMobile } from "@fortawesome/free-solid-svg-icons";

function SignUp() {
  const [userCount, setUserCount] = useState(100);
  const handleSignupAndTakeTests = () => {
    // Handle signup and take tests logic here
    setUserCount((prevCount) => prevCount + 1);
  };

  return (
    <div>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          className="new-paper-element"
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <h5 style={{ textAlign: "center", marginBottom: "30px" }}>
            {" "}
            Sign up To Test Your Exam Knowledge Now!{" "}
          </h5>

          <Box display="flex" flexDirection="column" alignItems="center">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ccc",
                padding: "20px",
              }}>
              {/* <FontAwesomeIcon icon={faMobile} className="mobile-icon" />
                    <TextField label="Enter your mobile number" variant="outlined" /> */}

              <Grid alignItems="center">
                <Grid item xs={12}>
                  {/* <TextField
                  label="Enter your mobile number"
                  variant="outlined"
                  InputProps={{
                  startAdornment: (
                  <FontAwesomeIcon icon={faMobile} className="mobile-icon" style={{ marginRight: '10px' }} />
                  ),
                }}
                /> */}

                  {/* <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="mobile-number" shrink={true}>
                            {lastUpdateDate ? "Enter your mobile number" : ""}
                          </InputLabel>
                          <Input
                            id="mobile-number"
                            startAdornment={
                              <FontAwesomeIcon
                                icon={faMobile}
                                className="mobile-icon"
                                style={{ marginRight: "8px" }}
                              />
                            }
                          />
                        </FormControl> */}

                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="mobile-number" shrink={true}>
                      Enter your mobile number
                    </InputLabel>
                    <Input
                      id="mobile-number"
                      startAdornment={
                        <FontAwesomeIcon
                          icon={faMobile}
                          className="mobile-icon"
                          style={{ marginRight: "8px" }}
                        />
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "10px",
                      backgroundColor: "green",
                    }}
                    onClick={handleSignupAndTakeTests}>
                    Signup & Take Free Tests
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Box>
          <Grid item xs={12}>
            <p style={{ marginTop: "10px", fontSize:"12px" }}>
              <strong>{userCount.toLocaleString()}</strong> Enrolled this test
              series
            </p>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}

export default SignUp;
