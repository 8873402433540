import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
import { baseURL } from "../../http";
import DynamicImageLogo from "../UserSettingAPI/DynamicImageLogo";
import FlipIcon from "@mui/icons-material/Flip";
import Tooltip from "@mui/material/Tooltip";
import "./Dashboard.css";

function AdminHeader({
  userType,
  handleTabClick,
  filteredNavItems,
  toggleIconsOnly,
  showIconsOnly,
  heading,
  handleSubItemClick,
  activeSubItem,
  activeTab,
}) {
  const email = localStorage.getItem("HTES_user_id");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  //--------------------------------------------------------------------------------
  //Call the function whne images is empty
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (images.length === 0) {
      fetchImages();
    }
  }, []);

  //--------------------------------------------------------------------------------
  //Fetch the database images for logo
  //--------------------------------------------------------------------------------

  const fetchImages = async () => {
    try {
      const response = await axios.get(baseURL + "/getimages");
      setImages(response.data.images);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  //--------------------------------------------------------------------------------
  //Open the mobile navbar
  //--------------------------------------------------------------------------------
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //--------------------------------------------------------------------------------
  //open and close the heder menu
  //--------------------------------------------------------------------------------
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //--------------------------------------------------------------------------------
  //Logout the User in the Menudropdown tab
  //--------------------------------------------------------------------------------

  const handleLogout = () => {
    navigate("/signout");
  };

  //--------------------------------------------------------------------------------
  //Mobile navbar drawer
  //--------------------------------------------------------------------------------
  const drawer = (
    <>
      <div className="drawerHome">
        <HomeIcon className="Homeicon" />
        <span className="mobile-bar-usertype">{userType}</span>
      </div>
      <div
        className="navigation-wrapper-mobile"
      >
        <div className="tab-navigation" ref={dropdownRef}>
          {filteredNavItems.map((tab) => (
            <div
              key={tab.id}
              className={`hovercontainer ${
                tab.id === activeTab ? "active" : ""
              }`}
            >
              {tab.subItems && tab.subItems.length > 0 ? (
                <div className="align-icon-and-text-center">
                  <span className="fontcolorandsize">
                    
                      <>
                        {tab.icon}
                        {tab.title}
                      </>
                    
                    
                  </span>
                </div>
              ) : (
                <div
                  className="align-icon-and-text-center"
                  onClick={() => handleTabClick(tab.id, tab.title)}
                >
                  <span className="fontcolorandsize">
                    
                      <>
                        {tab.icon}
                        {tab.title}
                      </>
                   
                  </span>
                </div>
              )}
              {tab.subItems && tab.subItems.length > 0 && (
                <div
                  className='open-container'
                >
                  {tab.subItems.map((subItem) => (
                    <div
                      className="subitemsblock"
                      key={subItem.id}
                      onClick={() =>
                        handleSubItemClick(tab.id, subItem.id, subItem.subtitle)
                      }
                    >
                      <div
                        className={`subitemsblocknamesmobileview ${
                          subItem.id === activeSubItem ? "active" : ""
                        }`}
                      >
                        <span>{subItem.subtitle}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <Box>
      <AppBar component="nav">
        <Toolbar className="navBar-toolitems-container">
          {/*___________________ logo,menuicon,open and close icon,And usertyepe____________________ */}
          <Box>
            <Box className="mobile-header-alignment">
              <Box sx={{ mr: 2, display: { sm: "none" } }}>
                <DynamicImageLogo
                  images={images}
                  height={"30px"}
                  width={"30px"}
                />
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <div className="sidenavopenandcloseandlogocontainer">
              <div>
                <Tooltip
                  onClick={toggleIconsOnly}
                  title={showIconsOnly ? "Open SideNav" : "Close SideNav"}
                  arrow
                >
                  <FlipIcon className="flipiconalign" />
                </Tooltip>
              </div>
              <div>
                <DynamicImageLogo
                  images={images}
                  height={"28px"}
                  width={"28px"}
                />
              </div>
              <div>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: "16px",
                    flexGrow: 1,
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  {userType}
                </Typography>
              </div>
            </div>
          </Box>
          {/*______________________ end____________________________________ */}
          {/*______________________________ Tab heading____________________ */}
          {heading}
          {/* ______________________________end______________________________ */}
          {/*__________________________________ Menu items and Avatar___________________________ */}
          <Box>
            <Stack direction="row" spacing={2}>
              <div>
                <Button onClick={handleMenuOpen}>
                  <Avatar
                    alt={email ? email.charAt(0).toUpperCase() : ""}
                    className="Avatarprofifename"
                    src="/static/images/avatar/1.jpg"
                  />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem>{email}</MenuItem>
                  <MenuItem onClick={() => navigate("/profile")}>
                    Profile Management
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/settings")}>
                    Settings
                  </MenuItem>
                  <MenuItem
                    className="myaccountlistitems"
                    onClick={handleLogout}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      {/*__________________________________ end___________________________ */}
      <nav>
        {/*_____________ open the mobile navigation bar__________________ */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          {drawer}
        </Drawer>
        {/*_________________________________end___________________________ */}
      </nav>
    </Box>
  );
}

export default AdminHeader;
