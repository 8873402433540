import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../http';
import { customAlert } from '../SweetAlertCommon/Custom';
import { Link, useNavigate } from 'react-router-dom';
import './Content.css'
import ContentViewerTemplate from './ContentViewerTemplate';

function AdminContentViewer({ topicSelectedFromSideNav }) {
  //-------------------------------------------------------------------------
  // State Handles
  //-------------------------------------------------------------------------
  const [renderContent, setRenderContent] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [draggingOverElement, setDraggingOverElement] = useState(null);
  const [catchingElementEducationRoadMapID, setCatchingElementEducationRoadMapID] = useState('');

  //-------------------------------------------------------------------------
  // Fetching the content w.r.to selected Topic
  //-------------------------------------------------------------------------
  useEffect(() => {    fetchData(); }, [topicSelectedFromSideNav]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/getContentTable`);
      const filteredContent = response.data.filter(
        (content) => content.topic === topicSelectedFromSideNav
      );
      const sortedRenderContents = [...filteredContent].sort((a, b) => a.position - b.position);
      setRenderContent(sortedRenderContents);

    } catch (error) {
      console.error('Error fetching Contents data:', error);
    }
  };

//-------------------------------------------------------------------------
// Handle Edit - Navigate to new page
//-------------------------------------------------------------------------

const navigateTo = useNavigate();
const handleEditClick = (editID, editHeading, editHtmlContent, editNormalContent ) => {
  
  navigateTo('/Content_Editor', {
    state: {
      editID,
      editHeading,
      editHtmlContent, 
      editNormalContent
    }
  });
};

//-------------------------------------------------------------------------
// Function to Move Content UP
//-------------------------------------------------------------------------
const handleMoveUp = (sortOrder) => {
  fetch(`${baseURL}/moveContentUpTopicWise/${sortOrder}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
          fetchData();
        customAlert("",'Content  Moved up', "info");
      } else {
          customAlert("",'No Previous item to shift', "warning");
      }
    })
    .catch((error) => {
      console.error("Item move-server error:", error);
    });
  };

//-------------------------------------------------------------------------
// Function to Move Content Down
//-------------------------------------------------------------------------
const handleMoveDown = (sortOrder) => {
  fetch(`${baseURL}/moveContentDownTopicWise/${sortOrder}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
          fetchData();
        customAlert("",'Content Moved Down', "info");
      } else {
          customAlert("",'No Next item to shift', "warning");
      }
    })
    .catch((error) => {
      console.error("Item move-server error:", error);
    });
};

//-------------------------------------------------------------------------
// Function to delete content item
//-------------------------------------------------------------------------

const handleDelete = (index, id) => {
  const updatedContents = [...renderContent];
  updatedContents.splice(index, 1);
  setRenderContent(updatedContents);
  
  fetch(`${baseURL}/deleteContent/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        fetchData();
        customAlert("",'Content Deleted', "error");
      } else {
        console.error("Failed to delete item");
        alert('falied-id='+id)
      }
    })
    .catch((error) => {
      console.error("Item deleting user:", error);
    });

};


//-------------------------------------------------------------------------
// Function to Move Content by Dragging
//-------------------------------------------------------------------------
  const handleDragStart = (event, id, educationalRoadMapID) => {
    event.dataTransfer.setData('text/plain', id);
    // console.log('catch_Elment--'+id)
    setDragging(true);
    setCatchingElementEducationRoadMapID(educationalRoadMapID);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    const targetElement = event.target;
    setDraggingOverElement(targetElement);

     
  };

  const handleDragLeave = () => {
    setDraggingOverElement(null);

  };

  const handleDrop = (event) => {
    event.preventDefault();
    const id = event.dataTransfer.getData('text/plain');
    const sortableList = document.getElementById("sortable");
    
    // Get the element after which the dragged item will be dropped
    const afterElement = getDragAfterElement(sortableList, event.clientY);
    
    // Determine the previous and next elements' IDs
    let previousElementId = null;
    // Get the previous element's ID
    if (afterElement && afterElement.previousElementSibling) {
        previousElementId = parseInt(afterElement.previousElementSibling.getAttribute("data-id"));
    }
    
    
    let nextElementId = null;
    // Get the next element's ID
    if (afterElement) {
        nextElementId = parseInt(afterElement.getAttribute("data-id"));
    }
    
    console.log('Dragged Element ID:->', id);
    // console.log('Previous Element ID:->', previousElementId);
    console.log('Next Element ID:->', nextElementId);
    setDraggingOverElement(null);

    setDragging(false);

    const requestData = {
      catchingElementID: parseInt(id),
      droppedBeforeToElementID: nextElementId,
      educationalRoadMapID:parseInt(catchingElementEducationRoadMapID)
  };

  axios.post(`${baseURL}/moveContentUpDownMouse`, requestData, {
      headers: {
          "Content-Type": "application/json"
      }
  })
  .then((response) => {
      if (response.status === 200) {
        customAlert("", 'Content Moved', "info");
        // fetchData();
      } else {
          customAlert("", 'Unable to shift', "warning");
      }
  })
  .catch((error) => { 
      console.error("Item move-server error:", error);
  });

/***___API end____ */

  };

  const getDragAfterElement = (container, y) => {
    const draggableElements = [
      ...container.querySelectorAll("li:not(.dragging)"),
    ];

    return draggableElements.reduce(
      (closest, child) => {
        const box = child.getBoundingClientRect();
        const offset = y - box.top - box.height / 2;
        if (offset < 0 && offset > closest.offset) {
          return {
            offset: offset,
            element: child,
          };
        } else {
          return closest;
        }
      },
      { 
        offset: Number.NEGATIVE_INFINITY,
      }
    ).element;
  };
  
  useEffect(() => {
    const sortableList = document.getElementById("sortable");
    let draggedItem = null;

    const getDragAfterElement = (container, y) => {
      const draggableElements = [
        ...container.querySelectorAll("li:not(.dragging)"),
      ];

      return draggableElements.reduce(
        (closest, child) => {
          const box = child.getBoundingClientRect();
          const offset = y - box.top - box.height / 2;
          if (offset < 0 && offset > closest.offset) {
            return {
              offset: offset,
              element: child,
            };
          } else {
            return closest;
          }
        },
        {
          offset: Number.NEGATIVE_INFINITY,
        }
      ).element;
    };

    const handleDragStart = (e) => {
      draggedItem = e.target;
      draggedItem.classList.add("dragging"); // Add a class to the dragged item
      setTimeout(() => {
        e.target.style.display = "none";
      }, 0);
    };

    const handleDragEnd = (e) => {
      setTimeout(() => {
        e.target.style.display = "";
        draggedItem.classList.remove("dragging"); // Remove the dragging class
        draggedItem = null;
      }, 0);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      const afterElement = getDragAfterElement(sortableList, e.clientY);
      const currentElement = document.querySelector(".dragging");
      if (afterElement == null) {
        sortableList.appendChild(draggedItem);
      } else {
        const rect = afterElement.getBoundingClientRect();
        const offset = e.clientY - rect.top;
        if (offset > rect.height / 2) {
          sortableList.insertBefore(draggedItem, afterElement.nextElementSibling);
        } else {
          sortableList.insertBefore(draggedItem, afterElement);
        }
      }
    };

    sortableList.addEventListener("dragstart", handleDragStart);
    sortableList.addEventListener("dragend", handleDragEnd);
    sortableList.addEventListener("dragover", handleDragOver);

    return () => {
      sortableList.removeEventListener("dragstart", handleDragStart);
      sortableList.removeEventListener("dragend", handleDragEnd);
      sortableList.removeEventListener("dragover", handleDragOver);
    };
  }, []);

//-------------------------------------------------------------------------
// Dragg Function End
//-------------------------------------------------------------------------



  return (
  
    <div className="sortable-list">
      <ul id="sortable">
        {/* Mapping function for render content */}
        {renderContent.map((content, index) => (
            <li 
              key={content.position}  
              draggable="true"
              style={{ position: 'relative' }}
              className={`sortable-div 
              ${dragging && draggingOverElement && 
                draggingOverElement.classList.contains("sortable-div-hover") ? 
              "sortable-div-hover li" : "li"}`}

              data-id={content.id} // Make sure data-id is set here
              onDrop={handleDrop} 
              onDragOver={handleDragOver} 
              onDragLeave={handleDragLeave}
              onDragStart={(event) => handleDragStart(event, content.id, content.educationalRoadMapID)}
              onDragEnd={handleDragEnd}
            >
              <ContentViewerTemplate
                content={content}
                index={index}
                handleEditClick={handleEditClick}
                handleMoveUp={handleMoveUp}
                handleMoveDown={handleMoveDown}
                handleDelete={handleDelete}
                renderContent={renderContent}
                topicSelectedFromSideNav={topicSelectedFromSideNav}
                showActionButtons={true}
              />
            </li>
        ))}
      </ul>
    </div>

    
  );
}

export default AdminContentViewer;
