import React from "react";
import InstructorTemplateForAddEdit from "./InstructorTemplateForAddEdit";
import { Modal } from "@mui/material";
import "./instructor.css";

function EditInstructor({
  openAddInstructor,
  closeAddInstructor,
  editData,
  editId,
  SetDataBaseUpdate,
  handleActionClose,
}) {
  return (
    <Modal
      open={openAddInstructor}
      onClose={closeAddInstructor}
      className="instructor-screen-center-alignment"
    >
      <div className="instructor-container">
        <InstructorTemplateForAddEdit
          SetDataBaseUpdate={SetDataBaseUpdate}
          handleActionClose={handleActionClose}
          editData={editData}
          onClose={closeAddInstructor}
          editId={editId}
        />
      </div>
    </Modal>
  );
}

export default EditInstructor;
