import React from 'react'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from '@mui/icons-material/Check';
import { MDBInput } from 'mdb-react-ui-kit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

function CardViewerTemplate(
        {  content, editedDateId, editedDate, id,index,
        handleDateChange, handleDateSaveClick, handleDateEditClick, handle3DotClick, 
      anchorEl,currentContentId,handle3DotClose,handleDelete,handleEditClick,
        showActionButtons='false'
    }) {



      function formatLabels(input) {
        try {
          // Check if the input is defined and not an empty string
          if (input) {
            // Parse the JSON string into an array of objects
            const dataArray = JSON.parse(input);
            // Extract the 'label' values from each object and join them into a comma-separated string
            const labels = dataArray.map(item => item.label).join(', ');
            return labels;
          }
        } catch (error) {
          console.error('Invalid JSON input:', error);
        }
        return 'NA'; // Return 'NA' if input is undefined or invalid
      }

      const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
        };
  return (
    <>
              
                <div className='CA_RenderCard_Container' key={content.position}>

                  <div className='flexRow '>
                    {/* _________________________Content Header ______________________________ */}
                    <div className='CA_RenderCard_Header'>
                      <h5 className='CA_HeaderText'>{content.heading}</h5>
                    </div>
                    {/* _________________________3 dot Options________________________________ */}
                    <div className='CA_card3dots skipPDF'>
                    {showActionButtons &&
                      <MoreVertIcon className="CA_batchmoreiconcolor dots3" id="button-zoom" onClick={(event) => handle3DotClick(event, content.id)} />}
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && currentContentId === content.id)}
                        onClose={handle3DotClose}
                      >
                        <MenuItem onClick={() => handleEditClick(content.id, content.imageDataURL, content.imageWidth, content.imageHeight,
                          content.heading, content.htmlContent, content.normalContent, content.tag, content.language)}>
                            <EditIcon className="buttonicons " id="batchcardediticon" />
                          <span className="menuitemscolor CA_MenuItems">
                           Edit</span>
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(index, content.id)}>
                          <DeleteIcon className="buttonicons" id="batchcarddeleteicon" />
                         <span className="menuitemscolor CA_MenuItems">Delete</span>
                        </MenuItem>

                        <MenuItem >
                        {editedDateId === content.id ? 
                        (
                          <div className='flexRow'  >
                              <CheckIcon className='CA_Card_DateOK' onClick={() => handleDateSaveClick(content.id)} />

                              <span className='CA_MenuItems'>
                              <MDBInput type="date" value={editedDate} onChange={handleDateChange} />
                              </span>
                          </div>
                        ) : (
                          <div>
                             {showActionButtons &&
                             
                            <EditCalendarIcon className="buttonicons" id="batchcardediticon"  onClick={() => handleDateEditClick(content.id, content.date)}/>
                         }
                          
                            <span className='CA_MenuItems' 
                            onClick={() => handleDateEditClick(content.id, content.date)}>
                              {formatDate(content.date)}
                              </span>
                          </div>
                        )}
                        </MenuItem>

                      </Menu>
                    </div>
                  </div>
                  <hr  className='CA_cardContentSeparator'/>

                    {/* _________________________Image container Render______________________________ */}
                  <div className='CA_RC_InnerContainer' >
                    <div className='CA_RenderCard_Image'>
                      <img
                        src={content.imageDataURL || ""}
                        alt={'Render Image'}
                        style={{ width: `${content.imageWidth}%` }}
                      />
                    </div>
                    {/* _________________________Main Content Render__________________________________ */}
                    <div className='CA_RenderContent' >
                      <div 
                      className='CA_Html_InnerDiv'
                      style={{color:'black', fontSize:'24px'}}
                      dangerouslySetInnerHTML={{ __html: content.htmlContent }} 
                      />
                    </div>
                  </div>
                  <hr className='CA_cardContentSeparator'/>
                    {/* _________________________Footer tags__________________________________________ */}
                    <div className='CA_RenderCard_Footer'>
                        <div className='caFooterItems'>
                          <span className='CA_RC_footerKey'>Created By :</span>
                          <span className='CA_RC_footerValue'>{content.createdByWho || 'NA'}</span>
                        </div>
                        <div className='caFooterItems skipPDF'>
                          <span className='CA_RC_footerKey'>Modified By :</span>
                          <span className='CA_RC_footerValue'>{content.ModifiedByWho || 'NA'}</span>
                        </div>
                        <div className='caFooterItems skipPDF'>
                          <span className='CA_RC_footerKey'>Last Modified :</span>
                          <span className='CA_RC_footerValue'>
                            {content.updated_at ? content.updated_at.slice(0, 10) : 'NA'}
                          </span>
                        </div>
                        <div className='caFooterItems'>
                          <span className='CA_RC_footerKey'>Tag:</span>
                          {/* <span className='CA_RC_footerValue'>{content.tag || 'NA'}</span> */}
                          <span className='CA_RC_footerValue'>
                           

                          {formatLabels(content.tag)}
                            </span>
                        </div>
                    </div>

                    {/* _________________________Footer tags End__________________________________________ */}
                </div>

                <hr className='CA_cardSeparator'/>
             
    </>
            
  )
}

export default CardViewerTemplate

