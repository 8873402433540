import React, { useState, useEffect } from "react";
import { Chip, IconButton, Tooltip } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./coursetemplate2.css";
import { customAlert } from "../../SweetAlertCommon/Custom";

function UploadBrochure({ isChecked, files, setFiles }) {
  const [expand, setExpand] = useState(true);

  //--------------------------------------------------------------------------------
  // Ensure files is always initialized as an array
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (!Array.isArray(files)) {
      setFiles([]);
    }
  }, [files, setFiles]);

  //--------------------------------------------------------------------------------
  // Handle the file change while uploading the files
  //--------------------------------------------------------------------------------
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    selectedFiles.forEach((file) => {
      const MAX_FILE_SIZE_LIMIT = 500 * 1024;
      if (file.size > MAX_FILE_SIZE_LIMIT) {
        customAlert(
          "",
          `File ${file.name} exceeds the maximum size limit please select 200kb files`,
          "warning"
        );
        return; // Skip processing large files or implement a different handling strategy
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const fileDataURL = e.target.result;
        if (!files.some((f) => f.name === file.name)) {
          setFiles((prevFiles) => [
            ...prevFiles,
            { name: file.name, data: fileDataURL },
          ]);
        } else {
          customAlert("", "Duplicate files are not allowed", "warning");
        }
      };
      reader.readAsDataURL(file);
    });
  };

  //--------------------------------------------------------------------------------
  // Delete the files
  //--------------------------------------------------------------------------------
  const handleFileDelete = (fileToDelete) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  //--------------------------------------------------------------------------------
  // Expand and collaps
  //--------------------------------------------------------------------------------
  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  return (
    <div className="coursetemplate2-general-first-three-tabs">
      {/* _________________________Upload brochure expand and collaps icons_______________________ */}
      <div
        className={`coursetemplate2-upload-brochure ${expand ? "active" : ""}`}
      >
        <div className="coursetemplate2-button-header-alignment">
          <input
            accept="application/pdf"
            style={{ display: "none" }}
            id="brochure-upload"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          {/* _________________________Upload brochure Header_______________________ */}
          <h6 className="coursetemplete2-title">Upload Brochure</h6>
          <div>
            <IconButton onClick={handleExpand}>
              {expand ? (
                <ExpandLessIcon className="course-template-expand-icon-color" />
              ) : (
                <ExpandMoreIcon className="expand-icon-color" />
              )}
            </IconButton>
            {/* _________________________Upload icon and repective area_______________________ */}
            <label htmlFor="brochure-upload">
              {isChecked && (
                <Tooltip title="Upload-brochure" arrow>
                  <IconButton
                    variant="contained"
                    color="success"
                    component="span"
                  >
                    <UploadFileIcon />
                  </IconButton>
                </Tooltip>
              )}
            </label>
          </div>
        </div>
        {/* _________________________Render the Files_______________________ */}
        {expand && (
          <div className="coursetemplate2-selected-files">
            {files.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => handleFileDelete(file)}
                className="coursetemplate2-chip-background-color"
              />
            ))}
          </div>
        )}
        {/* _________________________end_______________________ */}
      </div>
    </div>
  );
}

export default UploadBrochure;
