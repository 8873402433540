import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { baseURL } from "../../http";
import { TextField, Autocomplete, IconButton } from "@mui/material";
import { customAlert } from "../SweetAlertCommon/Custom";

function QuestionBankModel({ handleExportData, questions }) {
  const specificData = [
    "Syllabus",
    "Education",
    "Branch/FieldOfStudy",
    "Class/Sem",
    "Subject",
    "ChapterName",
    "TopicName",
    "UserFeedback",
    "QuestionTag",
    "LearningObjective",
  ];

  //-------------------------------------------------------------------------
  // Initialize formData with all fields in specificData
  //-------------------------------------------------------------------------

  const initialFormData = specificData.reduce((acc, field) => {
    acc[field] = "";
    return acc;
  }, {});

  const [formData, setFormData] = useState(initialFormData);
  const [open, setOpen] = useState(false);

  const [dropdownOptions, setDropdownOptions] = useState({});

  //-------------------------------------------------------------------------
  // Dropdown options for the educational roadmap
  //-------------------------------------------------------------------------
  const fetchDropdownOptions = async () => {
    try {
      const optionsResponse = await axios.get(baseURL + "/dropdownOptions");
      const optionsData = optionsResponse.data;

      const uniqueOptions = {};
      specificData.forEach((field) => {
        uniqueOptions[field] = [
          ...new Set(optionsData.map((row) => row[field])),
        ];
      });

      setDropdownOptions(uniqueOptions);
      //console.log (setDropdownOptions);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  //-------------------------------------------------------------------------
  // open and close the Modal
  //-------------------------------------------------------------------------
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //-------------------------------------------------------------------------
  // Handle input change
  //-------------------------------------------------------------------------
  const handleInputChange = (newValue, fieldName) => {
    //console.log(newValue, fieldName); // Log the new value and field name
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: newValue,
    }));
  };

  //-------------------------------------------------------------------------
  // clikc to export the Data to Backend
  //-------------------------------------------------------------------------
  const handleExportClick = () => {
    // Check if questions array is defined and not empty
    if (questions.length === 0) {
      customAlert("", "Please generate questions first", "warning");
      return;
    }

    handleExportData(formData).then(() => {
      setFormData(initialFormData);
      handleClose();
    });
  };

  return (
    <div>
      <Tooltip title="Save to DataBase" arrow>
        <CloudUploadIcon
          id="button-zoom"
          color="success"
          variant="contained"
          fontSize="medium"
          className="cloudhover"
          onClick={handleOpen}
        />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="moodalsizecolorclass">
          <IconButton
            id="button-zoom"
            onClick={handleClose}
            className="addeducationalMapmodalcancel"
          >
            <CloseIcon className="addeducationalMapmodalcancelX" />
          </IconButton>

          <h5 className="EducationalTableViewmodalHeader">
            Add Questions to QuestionBank
          </h5>

          <Box className="container-div-alignemnt-gerid-container">
            {specificData.map((item, index) => (
              <React.Fragment key={index}>
                {["UserFeedback", "LearningObjective", "QuestionTag"].includes(
                  item
                ) ? (
                  <TextField
                    key={index}
                    label={item}
                    value={formData[item]} // Ensure this value is correctly set
                    onChange={(e) => handleInputChange(e.target.value, item)}
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    key={index}
                    freeSolo
                    disableClearable
                    options={dropdownOptions[item] || []}
                    value={formData[item] || ""}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, item)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={index + 1 + ". " + item} // Predefined numbers before labels
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>

          <Box className='button-alignment-in-question-modal' >
            <Button
              id="button-zoom"
              variant="contained"
              color="success"
              onClick={handleExportClick}
            >
              <SaveIcon className="buttonicons" />
              &nbsp;Save
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* <ExamPatternModal dropdownOptions={dropdownOptions} /> */}
      {/* <ExamPatternEditModal specificData={specificData} dropdownOptions={dropdownOptions} /> */}
    </div>
  );
}

export default QuestionBankModel;
