import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  TextField,
  Grid,
  IconButton,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getCurrentDateTime,
  getUtcTime,
} from "../../Common/Utilities_Function";
import { baseURL } from "../../../http";
import "./assignedformmodal.css";
import axios from "axios";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { customAlert } from "../../SweetAlertCommon/Custom";

const AssignedFormModal = ({
  open,
  handleActionClose,
  SetDataBaseUpdate,
  handleClose,
  initialFormData,
}) => {
  const [formData, setFormData] = useState({
    Name: initialFormData?.Name || "",
    Email: initialFormData?.Email || "",
    Phone: initialFormData?.Phone || "",
    EnquiryType: initialFormData?.EnquiryType || "General",
    Message: initialFormData?.Message || "",
    Latitude: initialFormData?.Latitude || "",
    Longitude: initialFormData?.Longitude || "",
    UTC_Time: initialFormData?.UTC_Time || getUtcTime(),
    StartDate: "",
    EndDate: "",
    Comment: "",
    Status: "Wip",
    Assign_To: initialFormData?.Assign_To || "",
  });

  const [loginData, setLoginData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  //--------------------------------------------------------------------------------
  //Set or prefill the data
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (initialFormData) {
      setFormData({
        ...formData,
        ...initialFormData,
      });

      // Find and set the selected user from loginData based on initialFormData.Assign_To
      if (loginData.length > 0) {
        const user = loginData.find(
          (user) => user.email === initialFormData.Assign_To
        );
        setSelectedUser(user);
      }
    }
  }, [initialFormData, open, loginData]);

  //--------------------------------------------------------------------------------
  //call the api when open the modal
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchData();
  }, [open]);

  //--------------------------------------------------------------------------------
  //Api call function
  //--------------------------------------------------------------------------------
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/getLoginTable`);
      const employeeData = response.data.filter(
        (user) => user.usertype === "Employee"
      );
      setLoginData(employeeData);

      // Set the selected user after fetching the loginData
      if (initialFormData?.Assign_To) {
        const user = employeeData.find(
          (user) => user.email === initialFormData.Assign_To
        );
        setSelectedUser(user);
      }
    } catch (error) {
      console.error("Error fetching login data:", error);
    }
  };

  //--------------------------------------------------------------------------------
  //Handel the fileds based on the user entry
  //--------------------------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //--------------------------------------------------------------------------------
  //Submit used to save the data to backend
  //--------------------------------------------------------------------------------
  const handleSubmit = async () => {
    const updatedFormData = {
      ...formData,
      UTC_Time: getUtcTime(),
      Status: "WIP",
      Modified_by: localStorage.getItem("HTES_user_id"),
      Modified_date: getCurrentDateTime(),
    };

    try {
      await axios.put(
        baseURL + `/enquiry_update/${initialFormData.id}`,
        updatedFormData
      );
      customAlert("", "Enquiry Assigned Successfully", "success");
      handleClose();
      handleActionClose();
      SetDataBaseUpdate();
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  //--------------------------------------------------------------------------------
  //get the todat days some required format
  //--------------------------------------------------------------------------------
  const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="enquiry-form-container-assign"
    >
      <div className="enquiry-form-container-assign">
        <div className="enquiry-form-content-container-assign">
          <IconButton
            id="button-zoom"
            className="addeducationalMapmodalcancel-assign"
            onClick={handleClose}
          >
            <CloseIcon className="addeducationalMapmodalcancelX-assign" />
          </IconButton>
          <div className="enquiry-table-header-assign">Enquiry Assign Form</div>
          <div className="assign-enquiry-flex-container-assign">
            <div className="asssign-enquiry-form-basic-info-assign">
              <div className="assign-name-and-content-assign">
                <div>
                  <strong>Name:</strong> {formData.Name}
                </div>
              </div>
              <div className="assign-name-and-content-assign">
                <strong>Email:</strong>
                <div> {formData.Email}</div>
              </div>
              <div className="assign-name-and-content-assign">
                <strong>Phone:</strong>
                <div> {formData.Phone}</div>
              </div>
              <div className="assign-name-and-content-assign">
                <strong>Enquiry Type:</strong>
                <div> {formData.EnquiryType}</div>
              </div>
              <div className="assign-name-and-content-assign">
                <strong>Message:</strong>
                <div> {formData.Message}</div>
              </div>
            </div>
            <Grid
              container
              spacing={2}
              className="additional-info-container-assign"
            >
              <Grid item xs={12}>
                <Autocomplete
                  options={loginData}
                  getOptionLabel={(option) => option.email || ""}
                  value={selectedUser}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assign to"
                      name="Assign_To"
                      onChange={handleChange}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      Assign_To: newValue?.email || "",
                    });
                    setSelectedUser(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Start Date"
                  name="StartDate"
                  type="date"
                  value={formData.StartDate}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: getTodayDate(),
                    style: { textTransform: "uppercase" },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="End Date"
                  name="EndDate"
                  type="date"
                  value={formData.EndDate}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: getTodayDate(),
                    style: { textTransform: "uppercase" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  name="Comment"
                  value={formData.Comment}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </div>
          <div className="enqury-table-button-alignment-assign">
            <Button variant="contained" onClick={handleSubmit} color="success">
              <AssignmentIcon className="buttonicons" />
              Assign
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssignedFormModal;
