import React, { useState, useEffect } from "react";
import Stscroll4KeySkillsModal from "./PmTab4KeySkillsModal";
import Modal from '@mui/material/Modal';
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios"; 
import { baseURL } from "../../http";

function PmTab4KeySkills({callPercentage}) {
//--------------------------------------------------------------------------------
//  State Handles
//--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [keyskills, setKeyskills] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);

  function toggleModal(e) {
    setModalStatus(!modalStatus);
  }
//--------------------------------------------------------------------------------
// Fetching the Key Skill LIst
//--------------------------------------------------------------------------------

  useEffect(() => {
    fetchStudentSkillsList();
  }, []);

//--------------------------------------------------------------------------------
// Save Data API
//--------------------------------------------------------------------------------
  const fetchStudentSkillsList = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/getStudentKeySkills`,
        { email: userEmail },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        setKeyskills(response.data || []);
        console.log(response.data)
      } else {
        console.error("Error fetching Keyskills by student");
      }
    } catch (error) {
      console.error("Error fetching Keyskills by student:", error);
    }
  };

  return (
    <div >
        {/* ___________________________________ Tab Heading _______________________________ */}
      <h5 className="pmTabHeadings">
        Keyskills{" "}
        {/* <i onClick={toggleModal} className="fas fa-pencil pmEditIcon"></i> */}
        <Tooltip title="Edit" arrow>
                <IconButton onClick={toggleModal}>
                  <EditIcon className="pmEditIcon" />
                </IconButton>
        </Tooltip>
      </h5>

        {/* ___________________________________ Key skill Render _______________________________ */}
      {Array.isArray(keyskills) ? (
        keyskills.map((skill) => (
          <span key={skill}>
            <button className="pmBtnkskills1">{skill}</button>
          </span>
        ))
      ) : (
        <p>No key skills found.</p>
      )}

      {/* ___________________________________ Key Skill Modal ___________________________________ */}

      <Modal  open={modalStatus}  onClose={toggleModal}
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
      >
         <div className='pmMuiModalBox'>

              

              {/* _______ Key skill Modal - Component ___ */}
              <Stscroll4KeySkillsModal
              togglingModal={toggleModal}
              fetchStudentKeySkills={fetchStudentSkillsList}
              callPercentage={callPercentage}
            />
          </div>
      </Modal>
      {/* ___________________________________ Key Skill Modal Ends________________________________ */}
    </div>
  );
}

export default PmTab4KeySkills;
