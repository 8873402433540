import React, { useState, useEffect } from "react";
import { Grid, Avatar,Tooltip, } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; // Import html2canvas library
import { useNavigate } from "react-router-dom";
// import CA_Render from "./CA_Render";import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import NewspaperIcon from '@mui/icons-material/Newspaper';

import './Tree.css';
import CA_Viewer from './CA_Viewer';
import CA_SideNav_CalenderRender from './CA_SideNav_CalenderRender';

function CA_Main() {

  //-------------------------------------------------------------------------
  //State Handles
  //-------------------------------------------------------------------------
  const [selectedDate, setSelectedDate] = useState(null);
  const [fetchingDate, setFetchingDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  


  
  //-------------------------------------------------------------------------
  // Navigate to Editor for selected content
  //-------------------------------------------------------------------------
  const navigateTo = useNavigate();
  const handleOpen = () => {
    navigateTo('/CA_Editor');
      };

   //--------------------------------------------------------------------------------
// Helper function to format date
//--------------------------------------------------------------------------------
const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  };



  //-------------------------------------------------------------------------
  // Select the date for the Content Display
  //-------------------------------------------------------------------------
  const handleDateSelect = (day, month, year) => {

    // console.log('at main----'+day+'-'+month+'-'+year)//ok
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const monthIndex = monthNames.indexOf(month) + 1;
let formattedMonth;
    if((monthIndex.toString().padStart(2, '0'))=='00'){
      formattedMonth='mm';
    }
    else{
      formattedMonth=monthIndex.toString().padStart(2, '0');
    }
    // const formattedMonth = monthIndex.toString().padStart(2, '0');
    
    const formattedDay = day.toString().padStart(2, '0');

    const selectedDate = `${year}-${formattedMonth}-${formattedDay}`;
    setSelectedDate(selectedDate);
    // alert('al='+selectedDate)
    // console.log('al='+selectedDate)

  
    localStorage.setItem('selectedDate', selectedDate);
  };

  //-------------------------------------------------------------------------
  // Download Pdf 
  //-------------------------------------------------------------------------
const downloadPDF=()=>{
  const capture = document.querySelector('.CA_RenderCards');
  const scaleFactor = 3; // Increase the scale factor for better clarity

  // Select elements with class 'skipPDF'
  const skipElements = capture.querySelectorAll('.skipPDF');
  
  // Hide elements with class 'skipPDF'
  skipElements.forEach(element => {
    element.style.display = 'none'; // Hide elements
  });

  html2canvas(capture, { scale: scaleFactor }).then((canvas) => {
    const imgData = canvas.toDataURL('image/jpeg', 0.8); // Compress the image with quality factor 0.8
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
 
    const margin = 10; // 10mm margin
    const contentWidth = pdfWidth - 2 * margin;
    const contentHeight = (imgProps.height * contentWidth) / imgProps.width;

    let position = 0;

  // Add the first page with margin
  pdf.addImage(imgData, 'JPEG', margin, margin + position, contentWidth, contentHeight);
  position -= pageHeight - 2 * margin;

  // Add additional pages if the content exceeds one page
  while (position > -contentHeight) {
    pdf.addPage();
    pdf.addImage(imgData, 'JPEG', margin, margin + position, contentWidth, contentHeight);
    position -= pageHeight - 2 * margin;
  }

    pdf.save('currentAffairs.pdf');

    // Restore original display of skipPDF elements after saving PDF
    skipElements.forEach(element => {
      element.style.display = ''; // Restore original display
    });
  });
}
  return (
    <>

    <div className="usemanagementheadercopy">
            {/* _____________________Search container_________ */}
            <div style={{paddingRight:'70px'}} className="search-container">
              <span className="common-page-search-icon">
                <SearchIcon />
              </span>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                // onKeyUp={handleSearch}
                placeholder="Search..."
                className="search-input"
              />
            </div>
     
           
            {/* _____________________ Content Header (Center) _________ */}
              <div
                className="common-border-template"
                onClick={handleOpen}
              >
                <NewspaperIcon className="common-icon-designs" />
                <span className="common-filter-and-sort-responsive-manage">
                  &nbsp;{'Current Affairs'}
                </span>
                <MoreVertIcon className="common-icon-designs" />
              </div>

            {/* _________________________Download To PDF Start______________________________ */}
            <div className="common-table-header-avatar">
              <button className="usermanagementheadercopyfilter" 
                onClick={downloadPDF}>  
                <FileDownloadIcon fontSize="small"  color="primary" style={{marginRight:'10px'}}/>
                Download PDF
                </button>
              &nbsp;
              {/* ________________________________end________________________________ */}
            
            </div>
    </div>
  
  {/* _________________________Render Container______________________________ */}

    <div >
      <Grid container className="" >
          <Grid item xs={12} >
            <div className="CA_Nav_with_Content"  >

              <div className='calenderContainer' >
                {/* _________________________Calender  Container______________________________ */}
                    <ul  id="myUL" className={`CalenderList `}>
                      <CA_SideNav_CalenderRender onDateSelect={handleDateSelect}
                      />
                    </ul>

                {/* _________________________Data render belongs to Calender__________________ */}
                    {selectedDate ? 
                      <CA_Viewer selectedDate={selectedDate} fetchingDate={fetchingDate} 
                      downloadPDF={downloadPDF}
                      onDateSelect={handleDateSelect}
                      searchTerm={searchTerm}
                      />
                      : <CA_Viewer />}
                </div>

            </div>
          </Grid>
      </Grid >
    </div>

    </>
  )
}

export default CA_Main
