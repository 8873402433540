import React, { useState,useEffect } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TestAddandManage from "./TestAddandManage";
import "./testcard.css";
import UpdateIcon from '@mui/icons-material/Update';

function TestSeriesCreate({seteditTestData,editTestData,SetDataBaseUpdate, open, close, datatableview, setDatatableview ,setopenCreateTestModal}) {
  // State for the Test Series Name
  const [testSeriesName, setTestSeriesName] = useState('');
  const [formData, setFormData] = useState({
    TestSeriesName: '',
    TestsIds: []  // Placeholder for TestsIds, which you may manage later
  });

  useEffect(() => {
    if (editTestData) {
      // Prefill the form with existing data
      setTestSeriesName(editTestData.TestSeriesName);
      setFormData({
        TestSeriesName: editTestData.TestSeriesName,
        TestsIds: editTestData.TestsIds || [] // Default to empty array if not available
      });
    }
  }, [editTestData]);

  // Handle text field change
  const handleTextFieldChange = (event) => {
    setTestSeriesName(event.target.value); // Update test series name from input
  };

  // Handle 'Next' button click
  const handleNext = () => {
    // Update formData with the TestSeriesName and any other required fields
    setFormData({
      ...formData,
      TestSeriesName: testSeriesName
    });
    
    // Proceed to the next step or component
    setDatatableview(true);
    close(); // Close the modal
  };

  console.log('formData',formData)
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        className="Modal-screen-center-alignment-create-test-series"
      >
        <div className="modal-container--create-test-series">
          <div className="modal-inside-content-container-create-test-series">
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner-create-test-series"
              onClick={close}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol-create-test-series" />
            </IconButton>
            <div className="create-test-series-heading">{formData.TestSeriesName ? 'Update':'Create'} Test Series</div>
            <div className="modal-grid-textfield-dropdown-container-create-test-series">
              <TextField
                required
                label="Test Series Name"
                variant="outlined"
                fullWidth
                value={testSeriesName} // Link the state with the text field
                onChange={handleTextFieldChange} // Handle changes
              />
            </div>
            <div className="next-button-alignment-create-test-series">
              <Button onClick={handleNext} id="button-zoom" variant="contained" color="success">
              {formData.TestSeriesName ? <UpdateIcon className="buttonicons"/> : <ArrowForwardIcon className="buttonicons" />}
                &nbsp;{formData.TestSeriesName ? 'Update' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Conditionally render the next component */}
      {datatableview && <TestAddandManage setFormData={setFormData} setTestSeriesName={setTestSeriesName} seteditTestData={seteditTestData} editTestData={editTestData} formData={formData} setDatatableview={setDatatableview} setopenCreateTestModal={setopenCreateTestModal} SetDataBaseUpdate={SetDataBaseUpdate}/>}
    </>
  );
}

export default TestSeriesCreate;
