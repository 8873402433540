import React, { useState, useEffect } from "react";
import { TextField, Modal, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import SaveIcon from "@mui/icons-material/Save";
import "./creategroup.css";
import { customAlert } from "../SweetAlertCommon/Custom";
import axios from "axios";
import { baseURL } from "../../http";
import { getUtcTime, getCurrentDateTime } from "../Common/Utilities_Function";

function CreateGroupName({ editData, open, handleClose, SetDataBaseUpdate }) {
  const [GroupName, setGroupName] = useState("");


  //--------------------------------------------------------------------------------
  // Effect to set the pattern name if editData is provided
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      setGroupName(editData.GroupName || ""); // Assume editData has a property named patternName
    }
  }, [editData]);
  //--------------------------------------------------------------------------------
  // Function to handle save
  //--------------------------------------------------------------------------------
  const handleSaveOrUpdate = async () => {
    try {
      // Get user ID from local storage for Created_By and Modified_By
      const userId = localStorage.getItem("HTES_user_id");

      // Prepare payload with all required fields
      const payload = {
        GroupName: GroupName,
        // StudentIds: JSON.stringify([]) ,
        // InstructorIds: JSON.stringify([]),
        UTC_Time: getUtcTime(),
        Created_By: userId,
        Created_date: getCurrentDateTime(),
        Modified_By: userId, // Only include if editing
        Modified_date: getCurrentDateTime(), // Only include if editing
      };

      let response;
      if (editData.id) {
        // If groupId exists, perform update
        response = await axios.put(
          `${baseURL}/CreateGroup_edit/${editData.id}`,
          payload
        );
        customAlert("", "Group Name Updated Successfully", "success");

        console.log("Group updated:", response.data);
      } else {
        // If no groupId, perform create
        response = await axios.post(`${baseURL}/CreateGroup`, payload);
        customAlert("", "New group created Successfully", "success");
      }
      SetDataBaseUpdate(true);
      setGroupName("");
      handleClose(); // Close the modal after saving or updating
    } catch (error) {
      if (error.response && error.response.status === 400) {
        customAlert("", "Group name already exists", "warning"); // Show error if group name already exists
      } else {
        console.error("Error saving or updating group:", error);
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className="create-groups-modelcontainer"
      >
        <div className="create-groups-modal-inside-container">
          {/* _________Header____________ */}
          <div className="create-heading-name-container">
            <h5 className="exam-pattern-header-name">
              {editData ? "Update" : "Add"} Create Group Name
            </h5>

            <IconButton
              id="button-zoom"
              onClick={handleClose}
              className="addeducationalMapmodalcancel"
            >
              <CloseIcon className="addeducationalMapmodalcancelX" />
            </IconButton>
          </div>
          {/* _____________Textfield_________________ */}
          <div className="">
            <TextField
              id="outlined-basic"
              label="Create Group Name"
              variant="outlined"
              fullWidth
              value={GroupName} // Bind the TextField value to local state
              onChange={(e) => setGroupName(e.target.value)} // Update local state on change
            />
          </div>

          {/* ___________________Button sections______________ */}
          <div className="buttoncontainerexampattern">
            {editData ? ( // Conditional rendering based on editData
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleSaveOrUpdate}
              >
                <UpdateIcon className="buttonicons" />
                &nbsp;Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleSaveOrUpdate}
              >
                <SaveIcon className="buttonicons" />
                &nbsp;Save
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateGroupName;
