import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Select from "react-select";
import { baseURL } from "./../../http";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";

const Swal = require("sweetalert2");
const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const UserList = () => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    fetchStudentList();
  }, []);

  const fetchStudentList = () => {
    fetch(`${baseURL}/alllist`)
      .then((response) => response.json())
      .then((data) => {
        const userListWithBlocked = data.map((user) => ({
          ...user,
          blocked: false,
        }));
        setUserList(userListWithBlocked);
      })
      .catch((error) => {
        console.error("Error fetching student list:", error);
      });
  };

  const handleUserTypeChange = (userId, newUserType) => {
    const updatedUserList = userList.map((user) => {
      if (user.id === userId) {
        return { ...user, usertype: newUserType };
      }
      return user;
    });

    setUserList(updatedUserList);

    fetch(`${baseURL}/updateusers/${userId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ usertype: newUserType }),
    })
      .then((response) => {
        if (response.ok) {
          alert("User type updated successfully");
        } else {
          alert("Failed to update User type");
        }
      })
      .catch((error) => {
        alert("Server Error..to update user Type...!");
      });
  };

  const handleUserBlockToggle = (userId) => {
    const updatedUserList = userList.map((user) => {
      if (user.id === userId) {
        return { ...user, blocked: !user.blocked };
      }
      return user;
    });
    setUserList(updatedUserList);

    const userToUpdate = updatedUserList.find((user) => user.id === userId);

    fetch(`${baseURL}/updateBlockedStatus/${userId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ blocked: !userToUpdate.blocked }),
    })
      .then((response) => {
        if (response.ok) {
          alert("User Blocked..!");
        } else {
          alert("Failed to Block..!");
        }
      })
      .catch((error) => {
        alert("Server Error..!");
      });
  };

  const handleUserDelete = (userId) => {
    const updatedUserList = userList.filter((user) => user.id !== userId);
    setUserList(updatedUserList);

    fetch(`${baseURL}/deleteusers/${userId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          alert("User Deleted..!");
        } else {
          alert("Failed to delete user");
        }
      })
      .catch((error) => {
        alert("Server - Error deleting user");
      });
  };

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <h2>User Lists</h2>
          </Grid>

          <Grid item xs={6}>
            <Button variant="outlined" onClick={""}>
              Add User
            </Button>
          </Grid>
        </Grid>
      </Box>

      <MDBTable>
        <MDBTableHead className="bg-gradient">
          <tr style={{ textAlign: "center" }}>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">Roles-(User Type)</th>
            <th scope="col">
              Action<br></br>
              Block | Delete
            </th>
          </tr>
        </MDBTableHead>
        <MDBTableBody style={{ textAlign: "center" }}>
          {userList.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.mobile}</td>

              <td>
                <Select
                  value={{
                    label: capitalizeFirstLetter(user.usertype),
                    value: user.usertype,
                  }}
                  options={[
                    { label: "Admin", value: "admin" },
                    { label: "Student", value: "student" },
                    { label: "Parent", value: "parent" },
                    { label: "Normal User", value: "normalUser" },
                  ]}
                  onInputChange={(inputValue) => inputValue}
                  onChange={(selectedOptions) =>
                    handleUserTypeChange(user.id, selectedOptions.value)
                  }
                  placeholder={user.usertype}
                  style={{ marginLeft: "-20px" }}
                />
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id={`flexSwitchCheckDefault-${user.id}`}
                      checked={user.blocked}
                      onChange={() => handleUserBlockToggle(user.id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexSwitchCheckDefault-${user.id}`}
                    >
                      {" "}
                      Block{" "}
                    </label>
                    &nbsp;|&nbsp;
                    <button
                      className="red-trash-icon text-align-center"
                      onClick={() => handleUserDelete(user.id)}
                    >
                      <i className="fas fa-trash-can"></i>&nbsp;Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

export default UserList;
