import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from "@mui/icons-material/Save";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";

function Stscroll9ProfileSummary({callPercentage}) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem('HTES_user_id') || '';
  const [profileSummary, setProfileSummary] = useState("");
  const [profSummEdit, setProfSummEdit] = useState("");
  const [modalStatus, setModalStatus] = useState(false);

  //--------------------------------------------------------------------------------
  //  Modal toggles
  //--------------------------------------------------------------------------------
  function toggleModal() {
    setModalStatus(!modalStatus);
  }

  //--------------------------------------------------------------------------------
  // Fetching the Data from API
  //--------------------------------------------------------------------------------
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const requestBody = { email: userEmail };
    fetch(`${baseURL}/getprofile`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProfileSummary(data.profileSummary || "");
        setProfSummEdit(data.profileSummary || "");
      })
      .catch((error) => {
        console.error("Error fetching Profile Summary:", error);
      });
  };

  //--------------------------------------------------------------------------------
  // Save Data API
  //--------------------------------------------------------------------------------
  const saveProfileData = async () => {
    try {
      const sendData = {
        email: userEmail,
        profileSummary: profSummEdit,
      };

      const response = await fetch(`${baseURL}/updateProfileSummary`, {
        method: "POST",
        body: JSON.stringify(sendData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        customAlert("","Item Updated","success")
        .then(() => {
          setModalStatus(false);
          setProfileSummary(profSummEdit);
          callPercentage();
        });
      } else {
        console.error("Error updating Resume Headline:", response.statusText);
      }
    } catch (error) {
      console.error("Error while saving button:", error);
    }
  };

  //--------------------------------------------------------------------------------
  // Function to convert new line characters to <br /> tags
  //--------------------------------------------------------------------------------
  const formatProfileSummary = (text) => {
    return text.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ));
  };

  return (
    <>
    <div>
        {/* ___________________________________ Tab Heading _______________________________ */}
      <h5 className="pmTabHeadings">
        Profile Summary{" "}
        <Tooltip title="Edit" arrow>
          <IconButton onClick={toggleModal}>
            <EditIcon className="pmEditIcon" />
          </IconButton>
        </Tooltip>
      </h5>

        {/* ___________________________________ Profile summary render ____________________ */}
      <p>{formatProfileSummary(profileSummary || "")}</p>

        {/* ___________________________________ Add Modal code starts   ____________________ */}
        <Modal
        open={modalStatus}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pmMuiModalBox2">
          <div>
            {/* __________________     Heading      ____________ */}
            <h5 className="pmTabHeadings">Profile Summary</h5>
            <IconButton
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={toggleModal}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>
          </div>
          <br />

          <p className="pmModParaText">
            It is the first thing recruiters notice in your profile. Write
            concisely what makes you unique and the right person for the job you
            are looking for.
          </p>
          <br />

          {/* __________________     Edit Summary - TextField ____________ */}
          <div className="formProfile">
            <TextField
              id="outlined-multiline-static"
              label="Profile Summary"
              multiline
              fullWidth
              minRows={3}
              maxRows={8}
              value={profSummEdit || ""}
              onChange={(e) => setProfSummEdit(e.target.value)}
              className="pmSummaryTextBox"
            />
            <br />
          </div>

          {/* __________________          Save      ____________ */}
          <div className="createteslayoutmodel6">
            <Button id="button-zoom" variant="contained" color="success"
            onClick={saveProfileData}>
              <SaveIcon className="buttonicons" />
              &nbsp;Save
            </Button>
          </div>
        </div>
        </Modal>
        {/* ___________________________________ Add_Modal code Ends   ____________________________ */}
    </div>
    </>
  );
}

export default Stscroll9ProfileSummary;
