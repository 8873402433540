import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../../http";
import "./ContentRender.css";
import ContentTopNav from "./ContentTopNav";
import ContentSideNav from "./ContentSideNav";
import ContentMainSection from "./ContentMainSection";
import ContentAdsSection from "./ContentAdsSection";
import Drawer from '@mui/material/Drawer';
import ScrollProgress from './../../ScrollProgress/ScrollProgress';
import BackToTop from './../../BacktoTop/BackToTop';

function ContentMain() {

  //-------------------------------------------------------------------------
  //State Handle
  //-------------------------------------------------------------------------
  const [subjects, setSubjects] = useState([]);
  const [chaptersBySubject, setChaptersBySubject] = useState({});
  const [sections, setSections] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [selectedSubTopicId, setSelectedSubTopicId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);
  const [currentChapterIdx, setCurrentChapterIdx] = useState(0);
  const [currentTopicIdx, setCurrentTopicIdx] = useState(0);
  const [openTopics, setOpenTopics] = useState({});

  //----------------------------------------------------------------
  // Function for displaying the selected topic
  //----------------------------------------------------------------
  const toggleTopic = (topicId) => {
    setOpenTopics((prev) => ({
      ...prev,
      [topicId]: !prev[topicId],
    }));
  };

  //----------------------------------------------------------------
  //Function to fetch the content using api
  //----------------------------------------------------------------
  useEffect(() => {
    const fetchContentData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getcontent`);
        const data = response.data;
        const subjects = [...new Set(data.map(item => item.subject))];
        const chaptersBySubject = {};

        data.forEach(item => {
          if (!chaptersBySubject[item.subject]) {
            chaptersBySubject[item.subject] = [];
          }

          let chapter = chaptersBySubject[item.subject].find(ch => ch.name === item.chapter);
          if (!chapter) {
            chapter = {
              id: item.id,
              name: item.chapter,
              topics: [],
              chapterOrder: item.chapter_order || 0,
            };
            chaptersBySubject[item.subject].push(chapter);
          }

          // Sorting topics within each chapter
          if (item.parent_id === null) {
            let topic = chapter.topics.find(t => t.name === item.topic);
            if (!topic) {
              topic = {
                id: item.id,
                name: item.topic,
                subtopics: [],
                topicOrder: item.topic_order || 0,
              };
              chapter.topics.push(topic);
            }
          } else {
            const parentTopic = chapter.topics.find(t => t.id === item.parent_id);
            if (parentTopic) {
              const subtopic = {
                id: item.id,
                name: item.sub_topic,
                subTopicOrder: item.sub_topic_order || 0,
              };
              parentTopic.subtopics.push(subtopic);
            }
          }
        });

        // Sort chapters, topics, and subtopics
        Object.values(chaptersBySubject).forEach(chapters => {
          chapters.sort((a, b) => a.chapterOrder - b.chapterOrder); // Sort chapters
          chapters.forEach(chapter => {
            chapter.topics.sort((a, b) => a.topicOrder - b.topicOrder); // Sort topics
            chapter.topics.forEach(topic => {
              topic.subtopics.sort((a, b) => a.subTopicOrder - b.subTopicOrder); // Sort subtopics
            });
          });
        });

        setSubjects(subjects);
        setChaptersBySubject(chaptersBySubject);
        setSections(data);
      } catch (error) {
        console.error("Error fetching content data:", error);
      }
    };

    fetchContentData();
  }, []);

  //----------------------------------------------------------------
  // Update isMobile state when screen resizes
  //----------------------------------------------------------------
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 950);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuClick = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  //----------------------------------------------------------------
  //Function to select the topic and sub topic
  //----------------------------------------------------------------
  const handleTopicSelection = (topic) => {
    setSelectedTopic(topic);
    if (topic.subtopics && topic.subtopics.length > 0) {
      // Immediately expand subtopics
      toggleTopic(topic.id);
    } else if (isMobile) {
      // Close the drawer if there are no subtopics
      setDrawerOpen(false);
    }
  };

  const handleSubTopicSelection = (subTopic, subTopicId) => {
    setSelectedSubTopic(subTopic);
    setSelectedSubTopicId(subTopicId);
  };

  //----------------------------------------------------------------
  //Function for Next topic or sub topic selection
  //----------------------------------------------------------------
  const handleNext = () => {
    const localChapters = chaptersBySubject[selectedSubject] || [];
    const currentChapter = localChapters[currentChapterIdx];
    const topics = currentChapter?.topics || [];
    const currentTopic = topics[currentTopicIdx];

    // If there are subtopics and we're not on the last subtopic, move to the next subtopic
    if (currentTopic.subtopics && currentTopic.subtopics.length > 0) {
      const currentSubTopicIdx = currentTopic.subtopics.findIndex(sub => sub.id === selectedSubTopicId);
      if (currentSubTopicIdx < currentTopic.subtopics.length - 1) {
        const nextSubTopic = currentTopic.subtopics[currentSubTopicIdx + 1];
        setSelectedSubTopic(nextSubTopic.name);
        setSelectedSubTopicId(nextSubTopic.id);
        return;
      }
    }
    // If no more subtopics, move to the next topic
    if (currentTopicIdx < topics.length - 1) {
      setCurrentTopicIdx((prevTopicIdx) => {
        const newTopicIdx = prevTopicIdx + 1;
        const nextTopic = topics[newTopicIdx];
        setSelectedTopic(nextTopic.name);
        setSelectedSubTopic(null);
        setSelectedSubTopicId(null);

        // Check if the next topic has subtopics and expand it
        if (nextTopic.subtopics && nextTopic.subtopics.length > 0) {
          toggleTopic(nextTopic.id); // Ensure the topic is expanded
        }
        return newTopicIdx;
      });
    } else if (currentChapterIdx < localChapters.length - 1) {
      setCurrentChapterIdx((prevChapterIdx) => {
        const newChapterIdx = prevChapterIdx + 1;
        setCurrentTopicIdx(0);
        const nextChapter = localChapters[newChapterIdx];
        const nextTopic = nextChapter.topics[0];
        setSelectedTopic(nextTopic.name);
        setSelectedSubTopic(null);
        setSelectedSubTopicId(null);

        // Check if the next topic has subtopics and expand it
        if (nextTopic.subtopics && nextTopic.subtopics.length > 0) {
          toggleTopic(nextTopic.id); // Ensure the topic is expanded
        }
        return newChapterIdx;
      });
    }
  };

  //----------------------------------------------------------------
  //Function for Previous topic or sub topic selection
  //----------------------------------------------------------------
  const handlePrevious = () => {
    const localChapters = chaptersBySubject[selectedSubject] || [];
    const currentChapter = localChapters[currentChapterIdx];
    const topics = currentChapter?.topics || [];
    const currentTopic = topics[currentTopicIdx];

    // If there are subtopics and we're not on the first subtopic, move to the previous subtopic
    if (currentTopic.subtopics && currentTopic.subtopics.length > 0) {
      const currentSubTopicIdx = currentTopic.subtopics.findIndex(sub => sub.id === selectedSubTopicId);
      if (currentSubTopicIdx > 0) {
        const previousSubTopic = currentTopic.subtopics[currentSubTopicIdx - 1];
        setSelectedSubTopic(previousSubTopic.name);
        setSelectedSubTopicId(previousSubTopic.id);
        return;
      }
    }
    // If no more subtopics, move to the previous topic
    if (currentTopicIdx > 0) {
      setCurrentTopicIdx((prevTopicIdx) => {
        const newTopicIdx = prevTopicIdx - 1;
        const previousTopic = topics[newTopicIdx];
        setSelectedTopic(previousTopic.name);
        setSelectedSubTopic(null);
        setSelectedSubTopicId(null);

        // Check if the previous topic has subtopics and expand it
        if (previousTopic.subtopics && previousTopic.subtopics.length > 0) {
          toggleTopic(previousTopic.id); // Ensure the topic is expanded
        }
        return newTopicIdx;
      });
    } else if (currentChapterIdx > 0) {
      setCurrentChapterIdx((prevChapterIdx) => {
        const newChapterIdx = prevChapterIdx - 1;
        const previousChapter = localChapters[newChapterIdx];
        const lastTopicIdx = previousChapter.topics.length - 1;
        setCurrentTopicIdx(lastTopicIdx);
        const lastTopic = previousChapter.topics[lastTopicIdx];
        setSelectedTopic(lastTopic.name);
        setSelectedSubTopic(null);
        setSelectedSubTopicId(null);

        // Check if the last topic has subtopics and expand it
        if (lastTopic.subtopics && lastTopic.subtopics.length > 0) {
          toggleTopic(lastTopic.id); // Ensure the topic is expanded
        }
        return newChapterIdx;
      });
    }
  };

  return (
    <div className="app">
      {/* _____________Top Scroll bar_____________ */}
      <ScrollProgress />

      {/* __________Top Navigation bar____________ */}
      <ContentTopNav
        subjects={subjects}
        setSelectedSubject={setSelectedSubject}
        onMenuClick={handleMenuClick}
      />

      <div className="main-layout">
        {!isMobile && (
          <>
            {/* ______________Side Navigation bar_______________ */}
            <ContentSideNav
              chapters={selectedSubject ? chaptersBySubject[selectedSubject] : []}
              sections={sections.filter(section => section.subject === selectedSubject)}
              setSelectedTopic={handleTopicSelection}
              setSelectedSubTopic={handleSubTopicSelection}
              setSelectedSubTopicId={setSelectedSubTopicId}
              openTopics={openTopics}
              toggleTopic={toggleTopic}
              setOpenTopics={setOpenTopics}
              className="side-nav-desktop"
            />

            {/* _____________Content render section____________ */}
            <ContentMainSection
              sections={sections.filter(
                section =>
                  section.subject === selectedSubject &&
                  section.topic === selectedTopic ||
                  section.subtopic === selectedSubTopic
              )}
              selectedSubTopic={selectedSubTopic}
              selectedSubTopicId={selectedSubTopicId}
              updateSections={setSections}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />

            {/* ____________Ads Section______________ */}
            <ContentAdsSection className="ads-section-desktop" />
            <BackToTop />
          </>
        )}

        {/* _____________Drawer Menu for mobile devices_______________ */}
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          <div className="drawer-content">

            {/* ______________Side Navigation bar_______________ */}
            <ContentSideNav
              chapters={selectedSubject ? chaptersBySubject[selectedSubject] : []}
              sections={sections.filter(section => section.subject === selectedSubject)}
              setSelectedTopic={handleTopicSelection}
              setSelectedSubTopic={handleSubTopicSelection}
              setSelectedSubTopicId={setSelectedSubTopicId}
              openTopics={openTopics}
              toggleTopic={toggleTopic}
              setOpenTopics={setOpenTopics}
            />
          </div>
        </Drawer>

        {/* _____________Content render section____________ */}
        {isMobile && (
          <ContentMainSection
            sections={sections.filter(section => section.subject === selectedSubject && section.topic === selectedTopic)}
            selectedSubTopic={selectedSubTopic}
            selectedSubTopicId={selectedSubTopicId}
            updateSections={setSections}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
      </div>
    </div>
  );
}

export default ContentMain;
