import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  IconButton,
  Tooltip,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";
import "./coursetemplate2.css";

const CourseTemplate2FAQ = ({
  isChecked,
  selectedCategories,
  setSelectedCategories,
  selectedFAQs,
  setSelectedFAQs,
}) => {
  const [expanded, setExpanded] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allFAQs, setAllFAQs] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isExpand, setIsExpand] = useState(true);
  const autocompleteRef = useRef(null);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  //--------------------------------------------------------------------------------
  // Fetch all FAQ data
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/FAQ_fetch_all`);
        const data = response.data.filter(
          (item) => parseInt(item.IsApproved) === 1
        );
        setAllFAQs(data);

        // Extract unique categories
        const uniqueCategories = [
          ...new Set(data.flatMap((item) => JSON.parse(item.Category))),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    };

    fetchData();
  }, []);

  //--------------------------------------------------------------------------------
  // Toggle expand/collapse
  //--------------------------------------------------------------------------------

  const handleExpand = () => {
    setIsExpand((prev) => !prev);
  };

  //--------------------------------------------------------------------------------
  // Handle category selection
  //--------------------------------------------------------------------------------

  const handleCategorySelect = (event, newValue) => {
    if (newValue && !selectedCategories.includes(newValue)) {
      setSelectedCategories([...selectedCategories, newValue]);
      setIsAdding(false); // Hide autocomplete
    }
  };

  //--------------------------------------------------------------------------------
  // Remove selected category chip
  //--------------------------------------------------------------------------------

  const handleRemoveCategory = (category) => {
    setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
  };

  //--------------------------------------------------------------------------------
  // Render all FAQs for selected categories
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const selectedFAQs = allFAQs.filter((faq) => {
      const categories = JSON.parse(faq.Category);
      return selectedCategories.some((cat) => categories.includes(cat));
    });
    setSelectedFAQs(selectedFAQs);
  }, [selectedCategories, allFAQs]);

  //--------------------------------------------------------------------------------
  // Open and close the Autocomplate field
  //--------------------------------------------------------------------------------

  const toggleAutocomplete = () => {
    if (categories.length !== selectedCategories.length) {
      setIsAdding(!isAdding);
      setAutocompleteValue("");
    } else {
      customAlert("", "No categories available", "warning");
    }
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            isExpand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpand}
            >
              <h6 className="coursetemplete2-title">FAQs</h6>
              <div>
                <IconButton>
                  {isExpand ? (
                    <ExpandLessIcon className="course-template-expand-icon-color" />
                  ) : (
                    <ExpandMoreIcon className="expand-icon-color" />
                  )}
                </IconButton>
                {!isAdding && isChecked && (
                  <Tooltip title="Add Category" arrow>
                    <IconButton onClick={toggleAutocomplete}>
                      <AddIcon
                        className="courseTemplate2-edit-icon-color"
                        variant="contained"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          {/* ______________________________Auto compleate textfield_____________ */}
          {isAdding && (
            <Autocomplete
              options={categories.filter(
                (cat) => !selectedCategories.includes(cat)
              )}
              value={autocompleteValue || ""}
              onChange={(event, newValue) => {
                setAutocompleteValue(newValue);
                handleCategorySelect(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select or Search Category"
                  variant="outlined"
                  fullWidth
                  ref={autocompleteRef}
                />
              )}
            />
          )}
          {/* ______________________________end_____________________ */}

          {/* ______________________________Render the chips in the conatiner area_____________________ */}
          <div className="selected-categories-container">
            {selectedCategories &&
              selectedCategories.map((category, index) => (
                <Chip
                  key={index}
                  className="coursetemplate2-chip-background-color"
                  label={category}
                  onDelete={() => handleRemoveCategory(category)}
                />
              ))}
          </div>

          {/* ______________________________end_____________________ */}
          {/*_________ Render selected FAQs for all selected categories and expand and collaps_________________ */}
          {isExpand &&
            selectedFAQs &&
            selectedFAQs.map((selectedFAQ, faqIndex) => (
              <div
                className={`coursetemplate2-subtitle-and-expand-container-overall ${
                  expanded.includes(faqIndex) ? "active" : ""
                }`}
                key={faqIndex}
              >
                <div
                  className="coursetemplate2-title-and-expand-container"
                  onClick={() =>
                    setExpanded((prevExpanded) => {
                      const newExpanded = [...prevExpanded];
                      if (newExpanded.includes(faqIndex)) {
                        newExpanded.splice(newExpanded.indexOf(faqIndex), 1);
                      } else {
                        newExpanded.push(faqIndex);
                      }
                      return newExpanded;
                    })
                  }
                >
                  <div className="coursetemplate2-title-expandicon">
                    <h6 className="coursetemplete2-subtitle">
                      {selectedFAQ.Question}
                    </h6>
                    <IconButton>
                      {expanded.includes(faqIndex) ? (
                        <ExpandLessIcon className="expand-icon-color" />
                      ) : (
                        <ExpandMoreIcon className="expand-icon-color" />
                      )}
                    </IconButton>
                  </div>
                </div>
                {expanded.includes(faqIndex) && (
                  <div className="coursetemplate2-content-render-conatiner">
                    <div className="coursetemplate2-checkicon-and-points">
                      {selectedFAQ.Answer}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        {/* _______________________________end_______________________ */}
      </div>
    </>
  );
};

export default CourseTemplate2FAQ;
