import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import { baseURL } from "../../http";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.red,
  color: theme.palette.text.red,
}));

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

function ProductOwnerDashboard() {
  const [openModalAddPO, setOpenModalAddPO] = React.useState(false);

  const handleClickOpen = () => {
    setOpenModalAddPO(true);
  };
  const handleClose = () => {
    setOpenModalAddPO(false);
  };

  function addCustomer() {}

  return (
    <>
      <Box sx={{ bgcolor: "", height: "100vh" }}>
        <Button variant="outlined" onClick={handleClickOpen}>
          Add Customer
        </Button>

        <MDBModal
          tabIndex="-1"
          open={openModalAddPO}
          setOpen={setOpenModalAddPO}
        >
          <MDBModalDialog centered size="lg">
            <MDBModalContent className="qbModal">
              <MDBModalHeader>
                <MDBModalTitle className="qbModalTitle">
                  Add Customer
                </MDBModalTitle>
                <button
                  className="modalXbtn "
                  onClick={() => setOpenModalAddPO(false)}
                >
                  x
                </button>
                {/* <MDBBtn className='btn-close' color='none' onClick={toggleManageChapterModal}></MDBBtn> */}
              </MDBModalHeader>
              <MDBModalBody>
                <Box sx={{ flexGrow: 1 }}>
                  <br />

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        autoComplete="given-name"
                        name="name"
                        required
                        fullWidth
                        id="name"
                        label="Name" // value={''}
                        autoFocus
                        onChange={(e) => e.target.value}
                      />
                      <br />
                      <br />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        autoComplete="given-name"
                        name="email"
                        required
                        fullWidth
                        id="email"
                        label="Phone Number" // value={''}
                        autoFocus
                        onChange={(e) => e.target.value}
                      />
                      <br /> <br />
                    </Grid>
                  </Grid>

                  <TextField
                    multiline
                    rows={1}
                    maxRows={1}
                    fullWidth
                    label="Address Line 1"
                    required
                    aria-label="maximum height"
                    defaultValue=""
                  />
                  <br />
                  <br />
                  <TextField
                    multiline
                    rows={1}
                    maxRows={1}
                    fullWidth
                    label="Address Line 2"
                    aria-label="maximum height"
                    defaultValue=""
                  />
                  <br />
                  <br />

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        autoComplete="given-name"
                        name="State"
                        required
                        fullWidth
                        id="State"
                        label="State" // value={''}
                        autoFocus
                        onChange={(e) => e.target.value}
                      />
                      <br /> <br />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        autoComplete="given-name"
                        name="country"
                        required
                        fullWidth
                        id="country"
                        label="Country" // value={''}
                        autoFocus
                        onChange={(e) => e.target.value}
                      />
                      <br />
                      <br />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        autoComplete="given-name"
                        name="Pincode"
                        required
                        fullWidth
                        id="Pincode"
                        label="Pincode" // value={''}
                        autoFocus
                        onChange={(e) => e.target.value}
                      />
                      <br /> <br />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        autoComplete="given-name"
                        name="email"
                        required
                        fullWidth
                        id="email"
                        label="Mail ID" // value={''}
                        autoFocus
                        onChange={(e) => e.target.value}
                      />
                      <br />
                      <br />
                    </Grid>
                  </Grid>

                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" color="success">
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenModalAddPO(false)}
                    >
                      Cancel
                    </Button>
                  </Stack>

                  <br />
                </Box>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </Box>
    </>
  );
}

export default ProductOwnerDashboard;
