import React, { useState, useEffect } from 'react';

function CA_SideNav_CalenderRender({ onDateSelect }) {

  //-------------------------------------------------------------------------
  //State Handles
  //-------------------------------------------------------------------------
    const [highlightDay, setHighlightDay] = useState(null);
    const [highlightMonth, setHighlightMonth] = useState(null);
    const [highlightYear, setHighlightYear] = useState(null);
  
    const currentYear = new Date().getFullYear();
  //-------------------------------------------------------------------------
  // Years Array (below years) hold from current date to prevous 21 Years
  //-------------------------------------------------------------------------
    const years = Array.from({ length: 21 }, (_, index) => currentYear - index);
  
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'  ];
  
    const getDaysInMonth = (year, month) => {
      return new Date(year, month + 1, 0).getDate();
    };
  
    const formatMonthNumber = (month) => {
      const monthIndex = months.indexOf(month);
      if (monthIndex === -1) {
        return null; // Return null or handle the error as needed
      }
      // Adding 1 because months array is zero-indexed, and pad the result to ensure two digits
      return String(monthIndex + 1).padStart(2, '0');
    };

  //-------------------------------------------------------------------------
  //Genetate No.of .Days in Month
  //-------------------------------------------------------------------------
    const generateDays = (year, month) => {
      const daysInMonth = getDaysInMonth(year, month);
      const days = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        days.push({ day, isSunday: date.getDay() === 0 });
      }
      return days;
    };
  
   
   //-------------------------------------------------------------------------
  //Select the date to display content
  //-------------------------------------------------------------------------
    const [openItems, setOpenItems] = useState({});
  
    const toggleItem = (item, dd, mm, yy) => {
      setOpenItems((prevOpenItems) => ({
        ...prevOpenItems,
        [item]: !prevOpenItems[item],
      }));

      //  function to year and month wise
      setHighlightDay(dd);
      setHighlightMonth(mm);
      setHighlightYear(yy);

      onDateSelect(dd, mm, yy)
    };
  
  //-------------------------------------------------------------------------
  //Select  date to display Content
  //-------------------------------------------------------------------------
    const handleDateSelect = (day, month, year) => {
      setOpenItems({});
  
      setOpenItems({
        [year]: true,
        [`${year}-${month}`]: true
      });
  
      setHighlightDay(day);
      setHighlightMonth(month);
      setHighlightYear(year);

      onDateSelect(day, month, year);
      // console.log('datesele='+day+'-'+month+'-'+year);
    };

  //-------------------------------------------------------------------------
  //Select Today date active when Launch
  //-------------------------------------------------------------------------
    useEffect(() => {
      const today = new Date();
       handleDateSelect(today.getDate(), months[today.getMonth()], today.getFullYear());
 
    }, []);
  
  return (
      <>
   {/* // handleDateSelect = (day, month, year)  */}
        {years.map((year) => (
                <li key={year} style={{listStyleType:'none'}}>
                    <span
                    className={`caret ${openItems[year] ? 'caret-down' : ''}  
                    ${highlightYear === year ? 'selectedYear' : ''}`}
                    onClick={() =>  
                    {

                      toggleItem(year, 'dd', 'mm', year)  }
                    }
                    >
                    {year}
                    </span>
                    <ul className={`nested ${openItems[year] ? 'active' : ''}`}>
                    {months.map((month, monthIndex) => (
                        <li key={month}>
                        <span
                            className={`caret ${openItems[`${year}-${month}`] ? 'caret-down' : ''} 
                            ${highlightMonth === month && highlightYear === year ? 'selectedMonth' : ''}`}
                            onClick={() =>
                            {

                              toggleItem(`${year}-${month}`,  'dd',  month, year)
                            }
                              }
                        >
                            {month}
                        </span>
                        <ul className={`nested ${openItems[`${year}-${month}`] ? 'active' : ''}`}>
                            {generateDays(year, monthIndex).map((dayObj) => (
                            <li key={dayObj.day}>
                                <span
                                className={`day ${highlightDay === dayObj.day && highlightMonth === month && highlightYear === year ? 'selectedCalenderDate' : ''}`}
                                onClick={() => 
                                  {
                                    handleDateSelect(dayObj.day, month, year)
                                  }
                                  }
                                >
                                {dayObj.day}
                                </span>
                            </li>
                            ))}
                        </ul>
                        </li>
                    ))}
                    </ul>
                </li>
                ))}
    </>
  )
}

export default CA_SideNav_CalenderRender;
