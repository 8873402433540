// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Paper,
//   Grid,
//   Typography,
//   Button,
//   Divider,
// } from "@mui/material";
// import { Link } from "react-router-dom";
// import logo from "../../static/download.png";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { BsPeople } from "react-icons/bs";

// function HeaderLayout({ pageTitle, totalTests, buttonText, handleButtonClick, lists, handleAddTestSeries }) {
//     const [lastUpdateDate, setLastUpdateDate] = useState(
//         new Date().toLocaleDateString()
//       );

//       useEffect(() => {
//         const formattedLastUpdate = new Intl.DateTimeFormat("en-US", {
//           month: "short",
//           day: "numeric",
//           year: "numeric",
//         }).format(new Date());

//         setLastUpdateDate(formattedLastUpdate);
//       }, []);

//   return (
//     <div>
//       <Container>
//       <Paper>
//         <div className="testSeriesHeader">
//           <Grid container spacing={2}>
//             <Grid item xs={8}>
//               <div className="breadcrumb" style={{ fontSize: "12px" }}>
//                 <Link to="/">Home</Link> {">"}
//                 <Link to="/SSC_CGL">SSC CGL</Link> {">"}
//                 <span>{pageTitle}</span>
//               </div>
//               <div className="heading-container" style={{ marginBottom: "20px" }}>
//                 <img src={logo} className="circular-logo" alt="Logo" />
//                 <Typography variant="h5" className="heading-text">
//                   {pageTitle}
//                 </Typography>
//               </div>
//               <div>
//                 <p style={{ marginBottom: "20px" }}>
//                   <AccessTimeIcon
//                     style={{
//                       fontSize: 13,
//                       color: "black",
//                       marginRight: "5px",
//                     }}
//                   />
//                   Last updated on {lastUpdateDate}
//                 </p>
//               </div>
//               <p>
//                 <strong>{totalTests} Total Tests</strong> &nbsp;|&nbsp;
//                 <button className="free-tests-button">3 FREE TESTS</button>{" "}
//                 &nbsp;|&nbsp; <BsPeople /> 132.1k Users &nbsp;|&nbsp; English
//               </p>
//               <Divider
//                 variant="fullWidth"
//                 sx={{
//                   margin: "20px 0",
//                   backgroundColor: "white",
//                   height: "4px",
//                 }}
//               />
//               <Grid container direction="row" spacing={1}>
//                 {lists.map((item, index) => (
//                   <Grid item key={index} xs={12} sm={6} md={4}>
//                     <div
//                       className="list-item"
//                       style={{
//                         width: "163px",
//                         margin: "0 10px",
//                         marginRight: "10px",
//                         overflow: "hidden",
//                         whiteSpace: "nowrap",
//                         textOverflow: "ellipsis",
//                       }}
//                       onClick={() => handleButtonClick(index)}
//                     >
//                       <span style={{ marginRight: "5px" }}>•</span>
//                       {item.includes("PRO") ? (
//                         <span>
//                           {item.replace("PRO", "")}{" "}
//                           <span className="pro-box">PRO </span>
//                         </span>
//                       ) : (
//                         item
//                       )}
//                     </div>
//                   </Grid>
//                 ))}
//               </Grid>
//               <button
//                 className="add-test-series-button"
//                 onClick={handleAddTestSeries}
//                 style={{ width: "400px", marginTop: "20px" }}
//               >
//                 {buttonText}
//               </button>
//             </Grid>
//           </Grid>
//         </div>
//       </Paper>
//     </Container>
//     </div>
//   )
// }

// export default HeaderLayout

// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Paper,
//   Grid,
//   Typography,
//   Button,
//   Divider,
// } from "@mui/material";
// import { Link } from "react-router-dom";
// import logo from "../../static/download.png";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { BsPeople } from "react-icons/bs";
// import "./TestSeries.css";

// function HeaderLayout({ testData }) {
//     const [lastUpdateDate, setLastUpdateDate] = useState(
//         new Date().toLocaleDateString()
//       );

//       useEffect(() => {
//         const formattedLastUpdate = new Intl.DateTimeFormat("en-US", {
//           month: "short",
//           day: "numeric",
//           year: "numeric",
//         }).format(new Date());

//         setLastUpdateDate(formattedLastUpdate);
//       }, []);

//       if (!testData) {
//         return <div>Loading...</div>; // or handle the loading state accordingly
//       }

//   return (
//     <div>
//       <Container>
//       <Paper>
//         <div className="testSeriesHeader">
//           <Grid container spacing={2}>
//             <Grid item xs={8}>
//               <div className="breadcrumb" >
//                 <Link to="/">Home</Link> {">"}
//                 <Link to="/SSC_CGL">SSC CGL</Link> {">"}
//                 <span>{testData.pageTitle}</span>
//               </div>
//               <div
//                 className="heading-container"
//               >
//                 <img
//                   src={logo}
//                   className="circular-logo"
//                   alt="Logo"
//                 />
//                 <Typography variant="h5" className="heading-text">
//                   {testData.pageTitle}
//                 </Typography>
//               </div>
//               <div>
//                 <p style={{ marginBottom: "20px" }}>
//                   <AccessTimeIcon
//                     style={{
//                       fontSize: 13,
//                       color: "black",
//                       marginRight: "5px",
//                     }}
//                   />
//                   Last updated on {lastUpdateDate}
//                 </p>
//               </div>
//               <p>
//                 <strong>{testData.totalTests} Total Tests</strong> &nbsp;|&nbsp;
//                 <button className="free-tests-button">3 FREE TESTS</button>{" "}
//                 &nbsp;|&nbsp; <BsPeople /> 132.1k Users &nbsp;|&nbsp; English
//               </p>
//               <Divider
//                 variant="fullWidth"
//                 sx={{
//                   margin: "20px 0",
//                   backgroundColor: "white",
//                   height: "4px",
//                 }}
//               />
//               <Grid container direction="row" spacing={1}>
//                 {testData.lists.map((item, index) => (
//                   <Grid item key={index} xs={12} sm={6} md={4}>
//                     <div
//                       className="list-item"
//                       onClick={() => testData.handleButtonClick(index)}
//                     >
//                       <span style={{ marginRight: "5px" }}>•</span>
//                       {item.includes("PRO") ? (
//                         <span>
//                           {item.replace("PRO", "")}{" "}
//                           <span className="pro-box">PRO </span>
//                         </span>
//                       ) : (
//                         item
//                       )}
//                     </div>
//                   </Grid>
//                 ))}
//               </Grid>
//               <button
//                 className="add-test-series-button"
//                 onClick={testData.handleAddTestSeries}
//               >
//                 {testData.buttonText}
//               </button>
//             </Grid>
//           </Grid>
//         </div>
//       </Paper>
//     </Container>
//     </div>
//   )
// }

// export default HeaderLayout

// HeaderLayout.js
import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../static/download.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { BsPeople } from "react-icons/bs";
import "./TestSeries.css";
import SignUp from "./SignUp";
import LanguageIcon from "@mui/icons-material/Language";
import { fontSize } from "@mui/system";

function HeaderLayout({ testData }) {
  const [lastUpdateDate, setLastUpdateDate] = useState(
    new Date().toLocaleDateString()
  );

  useEffect(() => {
    const formattedLastUpdate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(new Date());

    setLastUpdateDate(formattedLastUpdate);
  }, []);

  if (!testData) {
    return <div>Loading...</div>; // or handle the loading state accordingly
  }

  return (
    <div>
      <Container >
        <Paper elevation={5}>
          <div className="testSeriesHeader" style={{ border: "1px solid #ccc", padding: "25px", marginTop:"20px", marginBottom:"20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="breadcrumb">
                  <Link to="/">Home</Link> &nbsp; {">"}
                  <Link to="/SSC_CGL"> &nbsp; SSC CGL</Link>&nbsp; {">"}
                  <span> &nbsp; {testData.pageTitle}</span>
                </div>
                <div className="heading-container">
                  <img src={logo} className="circular-logo" alt="Logo" />
                  <Typography variant="h5" className="heading-text">
                    <strong>{testData.pageTitle}</strong>
                  </Typography>
                </div>
                <div>
                  <p style={{ marginBottom: "20px", fontSize:"12px" }}>
                    <AccessTimeIcon
                      style={{
                        fontSize: 13,
                        color: "black",
                        marginRight: "5px",
                      }}
                    />
                    Last updated on {lastUpdateDate}
                  </p>
                </div>
                <p style={{fontSize:"12px"}}>
                  <strong>{testData.totalTests} Total Tests</strong>{" "}
                  &nbsp;|&nbsp;
                  <button className="free-tests-button">
                    3 FREE TESTS
                  </button>{" "}
                  &nbsp;|&nbsp; <BsPeople /> 132.1k Users &nbsp;|&nbsp;
                  <LanguageIcon
                    style={{
                      fontSize: 11,
                      color: "rgba(9,177,236, 0.8)",
                    }}
                  />{" "}
                  English
                </p>
                <Divider
                  variant="fullWidth"
                  sx={{
                    margin: "20px 0",
                    backgroundColor: "gray",
                    height: "4px",
                  }}
                />
                <Grid container direction="row" spacing={1}>
                  {testData.lists.map((item, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                      <div
                        className="list-item"
                        onClick={() => testData.handleButtonClick(index)}>
                        <span style={{ marginRight: "5px" }}>•</span>
                        {item.includes("PRO") ? (
                          <span>
                            {item.replace("PRO", "")}{" "}
                            <span className="pro-box">PRO </span>
                          </span>
                        ) : (
                          item
                        )}
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <button
                  className="add-test-series-button"
                  onClick={testData.handleAddTestSeries}>
                  {testData.buttonText}
                </button>
              </Grid>
              <Grid item xs={8} sm={4}>
                <SignUp />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Container>
    </div>
  );
}

export default HeaderLayout;
