import React, { useEffect } from "react";
import PoHeader from "./PoHeader";
import PoSideNav from "./1PoSideNav";

function PoDashboard() {
  useEffect(() => {
    document.title = "PO-Dashboard";
  });

  return (
    <div>
      <PoHeader />
      <PoSideNav />
    </div>
  );
}

export default PoDashboard;
