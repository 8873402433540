import React, { useState } from "react";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCourseName from "./AddCourseName";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "./tpacoursepage.css";
import DownloadTemplate from "./DownloadTemplate";
import UploadFromTemplate from "./UploadFromTemplate";

function CourseNameTemplate({
  openTemplateModal,
  setOpenTemplateModal,
  SetDataBaseUpdate,
  columns,
}) {
  const [openAddModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  //--------------------------------------------------------------------------------
  //handle clik for the DownladTempalte
  //--------------------------------------------------------------------------------

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //--------------------------------------------------------------------------------
  //handle clik for the add course button
  //--------------------------------------------------------------------------------
  const handleClickAddCourse = () => {
    setOpenModal(true);
    handleModalClose();
  };

  //--------------------------------------------------------------------------------
  //Close the Template modal
  //--------------------------------------------------------------------------------
  const handleModalClose = () => {
    setOpenTemplateModal(false);
  };

  return (
    <div>
      <Modal
        open={openTemplateModal}
        onClose={handleModalClose}
        className="course-template-modal"
      >
        {/* _________________________Header_______________________ */}
        <div className="course-template-modal-container">
          <div className="course-name-template-container-with-content">
            <IconButton
              id="button-zoom"
              className="course-name-template-close-button"
              onClick={handleModalClose}
            >
              <CloseIcon className="course-name-template-close-button-x" />
            </IconButton>
            <h5 className="course-name-template-header">Course Management</h5>
            {/* _________________________End_______________________ */}
            {/* _________________________Button,upload,download,add_______________________ */}
            <div className="course-name-template-add-course-icon">
              <div onClick={handleClickAddCourse}>
                <AddBoxIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />

                <div>
                  Add<br></br> Course
                </div>
              </div>

              <div onClick={handleMenuOpen}>
                <FileDownloadIcon
                  className="course-name-template-addboxixon-style"
                  color="primary"
                  fontSize="large"
                />

                <div>
                  Download <br></br>Template
                </div>
              </div>

              <div>
                <UploadFromTemplate
                  DBApi="/uploadcourses"
                  update={SetDataBaseUpdate}
                  close={handleModalClose}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ___________________________end_____________________________________ */}
      </Modal>

      {/* __________________________Add Manually coursename_____________________ */}
      <AddCourseName
        openModal={openAddModal}
        setOpenModal={setOpenModal}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />
      {/* ________________________________end_____________________________________*/}

      {/* __________________________Download Template ___________________________ */}

      <DownloadTemplate
        columns={columns}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      {/* ________________________________end_____________________________________*/}
    </div>
  );
}

export default CourseNameTemplate;
