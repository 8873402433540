import React from 'react'

// --------------------------------------------------
//  JobPortals common dropdowns
// --------------------------------------------------
// export const employmentTypesOptions = [
//   "Full-time",
//   "Part-time",
//   "Contract",
//   "Internship",
//   "Temporary",
//   "Freelance"
// ];
export const employmentTypesOptions = [
  { value: "full_time", label: "Full-time" },
  { value: "part_time", label: "Part-time" },
  { value: "contract", label: "Contract" },
  { value: "internship", label: "Internship" },
  { value: "temporary", label: "Temporary" },
  { value: "freelance", label: "Freelance" }
];


// export const industrialTypesOptions = [
//   "Information Technology",
//   "Healthcare",
//   "Finance",
//   "Education",
//   "Manufacturing",
//   "Retail"
// ];
export const industrialTypesOptions = [
  { value: "information_technology", label: "Information Technology" },
  { value: "healthcare", label: "Healthcare" },
  { value: "finance", label: "Finance" },
  { value: "education", label: "Education" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "retail", label: "Retail" }
];

export const roleCategoryOptions = [
  "Information Technology (IT)",
  "Business Process Outsourcing (BPO)",
  "Metal",
  "Healthcare",
  "Finance",
  "Education",
  "Automotive",
  "Construction",
  "Retail",
  "Telecommunications",
  "Hospitality",
  "Manufacturing",
  "Energy",
  "Pharmaceutical",
  "Aerospace",
  // Add more options as needed
];

export const jobRolesOptions = [
  "Software Engineer",
  "Data Analyst",
  "Product Manager",
  "Marketing Specialist",
  "Sales Representative",
  "Customer Support"
];

export const Options = [
  "Software Engineer",
  "Data Analyst",
  "Product Manager",
  "Marketing Specialist",
  "Sales Representative",
  "Customer Support"
];
export const departmentOptions = [
  "Engineering",
  "Data Analytics",
  "Product Management ",
  "Marketing",
  "Sales",
  "Customer Support",
  "Human Resources",
  "Finance",
  "Operations",
  "Legal",
  "IT",
  "Research and Development",
  "Supply Chain",
  "Quality Assurance",
  "Public Relations",
  "Administration",
  "Design",
  "Content",
  "Training",
  "Business Development"
];

export const requiredEducationOptions = [
  "Master Degree",
  "Bachelor Degree",
  "Diplomo",
  "12th",
  "SSLC",
];
export const UG_EducationOptions = [
  "Computer Science Engineering",
  "Information Technology",
  "Mechanical Engineering",
  "Civil Engineering",
  "Electrical Engineering",
  "Electronics and Communication Engineering",
  "Computer Science",
  "Information Technology",
  "English",
  "Commerce",
  "Law",
  "Education",
  "Business Administration",
  "Computer Applications",
  "Fine Arts",
  "Architecture",
  "Pharmacy",
  "Dental Surgery",
  "Physiotherapy",
  "Veterinary Science",
  "Diploma in Mechanical Engineering",
  "Diploma in Civil Engineering",
  "Diploma in Computer Science Engineering",
  "12th",
  "SSLC",
];

export const PG_EducationOptions = [
  "Computer Science Engineering",
  "Information Technology",
  "Mechanical Engineering",
  "Civil Engineering",
  "Electrical Engineering",
  "Electronics and Communication Engineering",
  "Computer Science",
  "Information Technology",
  "English",
  "Commerce",
  "Finance",
  "Marketing",
  "Human Resources",
  "Law",
  "Education",
  "Fine Arts",
  "Social Work",
  "Pharmacy",
  "Public Health",
  "Computer Science",
  "Mechanical Engineering",
  "Management",
  "Computer Applications",
];

export const designationOptions = [
  "Software Engineer",
  "Project Manager",
  "Business Analyst",
  "Data Scientist",
  "Product Manager",
  "System Administrator",
  "UX/UI Designer",
  "DevOps Engineer",
  "Quality Assurance Engineer",
  "Technical Support Specialist",
  "Network Engineer",
  "Database Administrator",
  "Security Analyst",
  "IT Consultant",
  "Frontend Developer",
  "Backend Developer",
  "Full Stack Developer",
  "IT Manager",
  "Cloud Architect",
  "Mobile Developer",
  "Web Developer",
  "Scrum Master",
  "IT Director",
  "Chief Technology Officer (CTO)",
  "Technical Lead",
  "Application Developer",
  "Software Tester",
  "IT Support Engineer",
  "Machine Learning Engineer",
  "AI Specialist"
];

export const companyNameOptions = [
  "HTES",
  "Wipro",
  "Infosys",
  "Zeptoh",
  "TCS",
];
export const requiredSkillsOptions = [
  "React",
  "Angular",
  "HTML",
  "CSS",
  "JavaScript",
];
export const requiredKeySkillsOptions = [
  { value: "React", label: "React" },
  { value: "Angular", label: "Angular" },
  { value: "HTML", label: "HTML" },
  { value: "CSS", label: "CSS" },
  { value: "JavaScript", label: "JavaScript" },
];
export const educationRequiredOptions = [
  { value: "HighSchool", label: "High School" },
  { value: "Diploma", label: "Diploma" },
  { value: "AssociateDegree", label: "Associate Degree" },
  { value: "BachelorsDegree", label: "Bachelor's Degree" },
  { value: "MastersDegree", label: "Master's Degree" },
  { value: "Doctorate", label: "Doctorate" },
  { value: "MBA", label: "MBA" },
  { value: "MCA", label: "MCA" },
  { value: "BTech", label: "B.Tech" },
  { value: "MTech", label: "M.Tech" },
  { value: "BSc", label: "B.Sc" },
  { value: "MSc", label: "M.Sc" },
  { value: "BCom", label: "B.Com" },
  { value: "MCom", label: "M.Com" },
  { value: "BA", label: "B.A" },
  { value: "MA", label: "M.A" },
  { value: "LLB", label: "LLB" },
  { value: "LLM", label: "LLM" },
];

//-----------------------------------------------------
// Career Profile Modal
//-----------------------------------------------------
export const locationsPreferred = [
  { value: "Amaravati", label: "Amaravati" },
  { value: "Itanagar", label: "Itanagar" },
  { value: "Dispur", label: "Dispur" },
  { value: "Patna", label: "Patna" },
  { value: "Raipur", label: "Raipur" },
  { value: "Panaji", label: "Panaji" },
  { value: "Gandhinagar", label: "Gandhinagar" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Shimla", label: "Shimla" },
  { value: "Srinagar", label: "Srinagar" },
  { value: "Ranchi", label: "Ranchi" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
  { value: "Bhopal", label: "Bhopal" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Imphal", label: "Imphal" },
  { value: "Shillong", label: "Shillong" },
  { value: "Aizawl", label: "Aizawl" },
  { value: "Kohima", label: "Kohima" },
  { value: "Bhubaneswar", label: "Bhubaneswar" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Gangtok", label: "Gangtok" },
  { value: "Chennai", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Agartala", label: "Agartala" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Dehradun", label: "Dehradun" },
  { value: "Kolkata", label: "Kolkata" },
];
export const companyTypes = [
  { value: "MNC", label: "MNC" },
  { value: "International", label: "International" },
  { value: "Startup", label: "Startup" },
];

export const headQuaterLocationOptions = [
  { value: "Amaravati", label: "Amaravati" },
  { value: "Itanagar", label: "Itanagar" },
  { value: "Dispur", label: "Dispur" },
  { value: "Patna", label: "Patna" },
  { value: "Raipur", label: "Raipur" },
  { value: "Panaji", label: "Panaji" },
  { value: "Gandhinagar", label: "Gandhinagar" },
  { value: "Shimla", label: "Shimla" },
  { value: "Srinagar", label: "Srinagar" },
  { value: "Ranchi", label: "Ranchi" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
  { value: "Bhopal", label: "Bhopal" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Imphal", label: "Imphal" },
  { value: "Shillong", label: "Shillong" },
  { value: "Aizawl", label: "Aizawl" },
  { value: "Kohima", label: "Kohima" },
  { value: "Bhubaneswar", label: "Bhubaneswar" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Gangtok", label: "Gangtok" },
  { value: "Chennai", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Agartala", label: "Agartala" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Dehradun", label: "Dehradun" },
  { value: "Kolkata", label: "Kolkata" },
];

export const stateNameOptions = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];


