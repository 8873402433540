import React, {useState,useEffect, useRef} from 'react'
import Editor1 from '../Editor/Editor1'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import { useLocation } from 'react-router-dom';

function ContentEditor() {

    const [heading, setHeading] = useState("");
    const [content, setContent] = useState("");
    const [outputContent, setOtputContent] = useState("");
    const [htmlContent, setHtmlContent] = useState("");
    const [editID, setEditID] = useState('');
    const navigateTo = useNavigate();
    const location = useLocation();
    const contentEditorRef = useRef(null);

  //-------------------------------------------------------------------------
  // TO Parse the Html Content
  //-------------------------------------------------------------------------

    const parseHTML = (htmlString) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        return tempDiv.innerText;
      };

  //-------------------------------------------------------------------------
  //clean ((resize )) attribute before submit
  //-------------------------------------------------------------------------

      const cleanContentImageResize = (content) => {
        // Create a temporary container to hold the content
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = content;
    
        // Find all image containers and remove the resize style
        const imgContainers = tempDiv.querySelectorAll(".image-container");
        imgContainers.forEach((container) => {
          container.style.resize = null;
        });
    
        return tempDiv.innerHTML;
      };
      const cleanContentContentEditable = (content) => {
        // Create a temporary container to hold the content
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = content;
    
        const editableDivs = tempDiv.querySelectorAll(".language-display, .code-viewer-content");
        editableDivs.forEach((div) => {
          div.setAttribute("contenteditable", "false");
          });
        
        

          return tempDiv.innerHTML;
      };
   //-------------------------------------------------------------------------
   //clean ((resize )) attribute before submit
   //-------------------------------------------------------------------------

  const cleanContentCodeResize = (content) => {
    // Create a temporary container to hold the content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    // Find all image containers and remove the resize style
    const imgContainers = tempDiv.querySelectorAll(".code-viewer-container");
    imgContainers.forEach((container) => {
      container.style.resize = null;
    });

    return tempDiv.innerHTML;
  };

  //-------------------------------------------------------------------------
  //Saving the Content to DB
  //-------------------------------------------------------------------------
    const saveContent = async () => {
    const rawContent = content;
    const cleanedContent_ImgRz = cleanContentImageResize(rawContent);
    const cleanContentContentEditablecontent = cleanContentContentEditable(cleanedContent_ImgRz);
    const cleanedContent = cleanContentCodeResize(cleanContentContentEditablecontent);
    try {
      const sendData = {
        heading: heading,
        normalContent: parseHTML(cleanedContent),
        htmlContent: cleanedContent,
        roadMapID: "roadMapID",
        topic:selectedTopic,
        createdByWho: localStorage.getItem('HTES_user_id'),
      };

      const response = await axios.post(`${baseURL}/createContent`, sendData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        console.log("Content Added success");
        customAlert("", "Content Added", "success").then(() => {
          navigateTo(`/dashboard`, { state: { selectedTopic } });
        });
      } else {
        console.error("Error adding content");
      }
    } catch (error) {
      console.error("Network Error while adding content:", error);
    }
  };

  //-------------------------------------------------------------------------
  //Saving the Edited Content to DB
  //-------------------------------------------------------------------------
  const saveEditContent = async () => {
    if (contentEditorRef.current) {
      const contentEditor = contentEditorRef.current;
      const editableDivs = contentEditor.querySelectorAll(".language-display, .code-viewer-content");
      editableDivs.forEach((div) => {
        div.setAttribute("contenteditable", "false");
      });
    }

    const rawContent = content;
    const cleanedContent_ImgRz = cleanContentImageResize(rawContent);

    const cleanContentContentEditablecontent = cleanContentContentEditable(cleanedContent_ImgRz);
    const cleanedContent = cleanContentCodeResize(cleanContentContentEditablecontent);

    try {
      const sendData = {
        editID: editID,
        heading: heading,
        normalContent: parseHTML(cleanedContent),
        htmlContent: cleanedContent,
        ModifiedByWho: localStorage.getItem('HTES_user_id'),
      };

      const response = await axios.post(`${baseURL}/editContent`, sendData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.status === 200) {
        console.log("Content Added success");
        customAlert("", "Content Edited", "success");
        navigateTo(`/dashboard`, { state: { selectedTopic } });
      } else {
        console.error("Error adding content");
      }
    } catch (error) {
      console.error("Network Error while adding content:", error);
    }
  };
  const[selectedTopic, setSelectedTopic]=useState('');
  useEffect(() => {
    if (location.state) {
      const {
        editID,
        editHeading,
        editHtmlContent,
        editNormalContent,
        selectedTopic,
      } = location.state;

      setHeading(editHeading || '');
      setEditID(editID || '');
      setHtmlContent(editHtmlContent || '');
      setContent(editNormalContent || '');
      setSelectedTopic(selectedTopic || '');

    } 
  }, [location.state]);
  return (
    <div>
      <Editor1
          isHeadingRequird={true}
          headingValue={heading}
          setHeadingValue={setHeading}
          height="60vh"
          content={content}
          setContent={setContent}
          setOtputContent={setOtputContent}
          htmlContent={htmlContent}
          setHtmlContent={setHtmlContent}
          is_tag_required={false}
          Tag_value= {''}
          setTag_value={''}
          // return_location="/dashboard"
          is_tag_Option_required={false}
          Tag_Options={''}
          setTag_Options={''}
           placeholder=''
          return_location="/dashboard" 

          editID={editID}
          Button_Name={((selectedTopic)?'Save':'Update')}
          onSave={((selectedTopic)?saveContent:saveEditContent)}
          showActionButtons={true}

      />
      {/* topi-{selectedTopic} */}
    </div>
  )
}

export default ContentEditor
