import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import AddGeneralInfo from "./AddGeneralInfo";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageTest from "./ManageTest";
import TestInstructionLink from "./TestInstructionLink";
import ArticleIcon from '@mui/icons-material/Article';
import PDF_Landing_Page from "./Download_PDF/PDF_Landing_Page";

function CreateTestLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [Open, setOpen] = useState(false);
  const [dataGridVisible, setdataGridVisible] = useState(false);
  const [editTestData, setEditTestData] = useState();
  const [openManageTest, setOpenManageTest] = useState(false);
  const [startTest, setStartTest] = useState(false);
  const [PDFview,setPdfView] =useState(false)

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/gettestgeninfo`);
        const data = response.data;
        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    setEditTestData(rowdata);
    setdataGridVisible(true);
  };

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  //--------------------------------------------------------------------------------
  //Test Manage and Start test Functions
  //--------------------------------------------------------------------------------
  const handleManageTest = (rowdata) => {
    setEditTestData(rowdata);
    setOpenManageTest(true);
  };

  const handleCloseManageTest = () => {
    setEditTestData("");
    setOpenManageTest(false);
  };

  const handleStartTest = (rowdata) => {
    setEditTestData(rowdata);
    setStartTest(true);
  };

  const handleCloseTest = () => {
    setEditTestData("");
    setStartTest(false);
  };

  const handleManageQuestionPaper=(rowdata)=>{
    setEditTestData(rowdata);
    setPdfView(true)
  }

  const handleManageQuestionPaperclose=(rowdata)=>{
    setEditTestData(rowdata);
    setPdfView(false)
  }
  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: PlayArrowIcon, //icon Name (import from the Mui)
      label: "Start Test", //Name of the MenuItem
      onClick: handleStartTest, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
    {
      icon: ManageAccountsIcon,
      label: "Manage Test",
      onClick: handleManageTest,
      IconColor: "blue",
    },
    {
      icon: ArticleIcon,
      label: "Question Paper View",
      onClick: handleManageQuestionPaper,
      IconColor: "green",
    },
  ];

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      {!dataGridVisible && !PDFview && (
        <TableHeaderTemplate
          columns={renderColumns} // Columns render with default column if empty
          rows={renderRows} // Rows render
          customMenuItems={customMenuItems} // Addition menu items
          handleEdit={handleEdit} //Handle the Edit function
          deleteApi="deletegetinfo" // Delete Api
          tableNameForExport="Education-RoadMap" // Export the Data to excel/csv (If name requred for the Excel or Csv)
          is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
          handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
          Manage_button_name="Create Test" //Name of the Manage button(in center of the header)
          // switchColumnName="IsNegativeMarking" // Swithc required in the Table(one of the column)
          // swithcUpdateApi="updatecourse" // Api to handle the Toggle of the swithc
          // dropdownColumnName="FreeOrPaid" // Yes or No Dropdown required in the Table(one of the column)
          // dropdownUpdateApi="updatecourse"// Api to handle the Dropdown of the (yes or no)
          SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
          DefaultColumnNames={["TestName", "TestDuration", "NoOfQuestions"]} // Add to show the columns in a default render
          CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
        />
      )}
      {/* _______________________End_____________________________ */}

      {/* ______________Addinfo Modal button_____________ */}
      <AddGeneralInfo
        Open={Open}
        handleClose={handleClose}
        dataGridVisible={dataGridVisible}
        setdataGridVisible={setdataGridVisible}
        SetDataBaseUpdate={SetDataBaseUpdate}
        editTestData={editTestData}
        setEditTestData={setEditTestData}
      />

      {/* ____________Manage Test___________________ */}
      <ManageTest
        open={openManageTest}
        handleClose={handleCloseManageTest}
        editTestData={editTestData}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />

      {/* ________________start test trail_____________ */}
      <TestInstructionLink
        open1={startTest}
        handleClose={handleCloseTest}
        testinfo={editTestData}
      />

      {/* ______________PDF page View and Download________ */}
      {PDFview &&
        <PDF_Landing_Page   editTestData={editTestData}  handleManageQuestionPaperclose={handleManageQuestionPaperclose} />
      }
    </div>
  );
}

export default CreateTestLandingPage;
