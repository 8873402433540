import React, { useState, useEffect } from "react";
import { Avatar, IconButton, Button, Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./CreateTest.css"; // Ensure you create this file for the CSS
import axios from "axios";
import { baseURL } from "../../http";
import SearchIcon from "@mui/icons-material/Search";
import FilterButton from "./FilterButton";
import CreateTestQuestionView from "./CreateTestQuestionView";
import CreateTestQuestionView1 from "./CreateTestQuestionView1";
import SaveIcon from "@mui/icons-material/Save";
import AddtoQuestionBank from "../QuestionBank/AddtoQuestionBank";
import { Update } from "@mui/icons-material";

function AllComponentRenderPage({
  selectedPatten,
  SetDataBaseUpdate,
  handleSave,
  editTestData,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [AiGeneratedQuestionBank, setAiGeneratedQuestionBank] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sortedData, setSortedData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestions, SetSeletedQuestion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [editData, setEditData] = useState();
  const [questionBankUpdate, setquestionBankUpdate] = useState(false);


  //--------------------------------------------------------------------------------
  //Toggle side bar Question view
  //--------------------------------------------------------------------------------
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  //--------------------------------------------------------------------------------
  // Reset the selected questions to an empty array
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (!selectedPatten) {
      SetSeletedQuestion([]);
    }
  }, [selectedPatten]);
  //--------------------------------------------------------------------------------
  // Fetching AI-generated questions when the component mounts
  //--------------------------------------------------------------------------------
  // Adjusting the `useEffect` to handle pre-selecting questions based on QuestionIds in edit mode
  useEffect(() => {
    const fetchTablesList = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/getAllAiGeneratedQuestions`
        );
        const data = response.data;
        setAiGeneratedQuestionBank(data);
        setSortedData(data);

        // Populate columns dynamically if data is available
        if (data.length > 0) {
          const columnKeys = Object.keys(data[0]).map((key) => ({
            field: key,
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
          }));
          setColumns(columnKeys);
        }

        // Pre-select questions based on editTestData.QuestionIds if available
        if (editTestData && editTestData.QuestionIds) {
          const preSelectedQuestions = data.filter((question) =>
            editTestData.QuestionIds.includes(question.id)
          );

          // Combine and filter out duplicates for `selectedQuestions`
          const uniqueSelectedQuestions = [
            ...new Map(
              [...selectedQuestions, ...preSelectedQuestions].map((q) => [
                q.id,
                q,
              ])
            ).values(),
          ];

          SetSeletedQuestion(uniqueSelectedQuestions);
        } else {
          // Update selected questions with newly fetched questions if not in edit mode
          const updatedSelectedQuestions = selectedQuestions.map((selected) => {
            const updatedQuestion = data.find(
              (question) => question.id === selected.id
            );
            return updatedQuestion ? updatedQuestion : selected;
          });
          SetSeletedQuestion(updatedSelectedQuestions);
        }
      } catch (error) {
        console.log("Error fetching questions:", error);
        setTimeout(() => {
          fetchTablesList();
        }, 5000); // Retry after 5 seconds if there's an error
      }
    };

    fetchTablesList();
    setquestionBankUpdate(false);
  }, [questionBankUpdate, SetDataBaseUpdate, editTestData]);


  //--------------------------------------------------------------------------------
  // Filter function based on the search term
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const filteredData = AiGeneratedQuestionBank.filter((question) =>
      question.Questions.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSortedData(filteredData);
  }, [searchTerm, AiGeneratedQuestionBank]); // Filtered every time the search term or the question bank changes

  //--------------------------------------------------------------------------------
  // Function to handle search input change
  //--------------------------------------------------------------------------------
  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Update the search term
  };

  //--------------------------------------------------------------------------------
  // Function to handle search input change
  //--------------------------------------------------------------------------------
  const onEdit = (Data) => {
    setEditData(Data);
    setEditQuestion(true);
  };

  //--------------------------------------------------------------------------------
  // close the edit question
  //--------------------------------------------------------------------------------
  const handleCloseEditQuestion = () => {
    setEditQuestion(false);
    // setquestionBankUpdate(false);
  };

  //--------------------------------------------------------------------------------
  // Save the Test and call this function
  //--------------------------------------------------------------------------------
  const handleSaveTest = () => {
    handleSave(selectedQuestions);
  };

  //--------------------------------------------------------------------------------
  // filter the questions based on the pattern
  //--------------------------------------------------------------------------------
  function filterQuestionBank(AiGeneratedQuestionBank, parsedPatternArray) {
    let filteredQuestions = [];

    for (let pattern of parsedPatternArray) {
      let selectedQuestions = AiGeneratedQuestionBank.filter((question) => {
        // Construct filter criteria dynamically
        let criteria = true;
        for (let key of Object.keys(pattern)) {
          // Exclude 'id', 'Easy', 'Medium', and 'Hard' from comparisons
          if (
            key !== "id" &&
            key !== "Easy" &&
            key !== "Medium" &&
            key !== "Hard"
          ) {
            // Log comparison for debugging
            console.log(
              `Matching pattern key "${key}" with value "${pattern[key]}" against question key "${key}" with value "${question[key]}"`
            );

            // Handle undefined, null, and trimmed string comparison
            if (
              question[key] == null ||
              pattern[key] == null ||
              question[key].trim() !== pattern[key].trim()
            ) {
              console.log(
                `Mismatch found for key "${key}": pattern value = "${pattern[key]}", question value = "${question[key]}"`
              );
              criteria = false;
              break;
            }
          }
        }
        return criteria;
      });

      for (let difficultyLevel of ["Easy", "Medium", "Hard"]) {
        if (
          pattern.hasOwnProperty(difficultyLevel) &&
          pattern[difficultyLevel] > 0
        ) {
          let quantity = pattern[difficultyLevel];
          let questionsOfDifficultyLevel = selectedQuestions.filter(
            (question) => {
              // Assuming DifficultyLevel in AiGeneratedQuestionBank is represented as strings ("Easy", "Medium", "Hard")
              return question.DifficultyLevels === difficultyLevel;
            }
          );
          // Shuffle the questions randomly
          questionsOfDifficultyLevel.sort(() => Math.random() - 0.5);
          // Ensure there are enough questions available to meet the quantity
          let selectedQuestionsCount = Math.min(
            quantity,
            questionsOfDifficultyLevel.length
          );
          filteredQuestions.push(
            ...questionsOfDifficultyLevel.slice(0, selectedQuestionsCount)
          );
        }
      }
    }
    return filteredQuestions;
  }

  //--------------------------------------------------------------------------------
  // check the pattern found or not and call the function
  //--------------------------------------------------------------------------------
  useEffect(() => {
    // Check if PatternArray exists and if AiGeneratedQuestionBank has data
    if (
      !selectedPatten ||
      !selectedPatten.PatternArray ||
      AiGeneratedQuestionBank.length === 0
    ) {
      return; // Exit if no selected pattern or no question bank data
    }

    if (selectedPatten.PatternName === "General") {
      return; // Do nothing if the pattern is "General"
    }

    const fetchData = async () => {
      setLoading(true);
      const parsedPatternArray = JSON.parse(selectedPatten.PatternArray);

      console.log(
        "AiGeneratedQuestionBank:",
        AiGeneratedQuestionBank,
        "Parsed PatternArray:",
        parsedPatternArray
      );

      if (parsedPatternArray !== null) {
        // Filter the questions based on the pattern array and question bank
        let filteredQuestions = filterQuestionBank(
          AiGeneratedQuestionBank,
          parsedPatternArray
        );

        // Avoid duplicate questions by checking against existing selected questions
        const newSelectedQuestions = [
          ...filteredQuestions.filter(
            (question) =>
              !selectedQuestions.some((selected) => selected.id === question.id)
          ),
        ];

        // Update the selected questions
        SetSeletedQuestion(newSelectedQuestions);
        setLoading(false);
      } else {
        console.log("Pattern not found.");
        setLoading(false); // Make sure loading is turned off in all cases
      }
    };

    // Fetch data only when both conditions are met
    fetchData();

    // Trigger the effect whenever `selectedPatten` or `AiGeneratedQuestionBank` changes
  }, [selectedPatten, AiGeneratedQuestionBank.length]);

  return (
    <>
      {!editQuestion && (
        <div className="main-container-all-component-render">
          {/* Left container, dynamically adjusts between 70% and 100% width */}

          <div
            className={`left-container ${isOpen ? "expanded" : "full-width"}`}
          >
            <div className="Avatar-and-Button-conatiner-in-question-view-container">
              {!isOpen && (
                <Tooltip title="Total selected Question" arrow>
                  <Avatar className="avatar-alignemnt-in-add-to-test">
                    {selectedQuestions?.length}
                  </Avatar>
                </Tooltip>
              )}
            </div>
            <div className="Avatar-and-Button-conatiner-in-question-view-container1">
              <Button
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleSaveTest}
              >
                {editTestData ? (
                  <Update className="buttonicons" />
                ) : (
                  <SaveIcon className="buttonicons" />
                )}
                &nbsp;{editTestData ? "Update Test" : "Save Test"}
              </Button>
            </div>
            <div
              className={`selected-question-view-container ${
                isOpen ? "expanded" : "full-width"
              }`}
            >
              {/* Check if selectedQuestions is empty */}
              {selectedQuestions.length === 0 ? (
                <div className="no-questions-message">
                  Please add the test questions.
                </div>
              ) : (
                <CreateTestQuestionView1
                  rows={selectedQuestions}
                  SetSeletedQuestion={SetSeletedQuestion}
                  selectedQuestions={selectedQuestions}
                  onEdit={onEdit}
                  loading={loading}
                />
              )}
            </div>
          </div>

          {/* Right 30% container, can slide in/out */}
          {isOpen && !editQuestion && (
            <div className={`right-container ${isOpen ? "open" : "closed"}`}>
              <Tooltip title="Close the Question Bank" arrow>
                <IconButton className="toggle-button" onClick={toggleSidebar}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Tooltip>
              <div className="container-for-filter-search-all-component-render">
                <div className="common-page-search-container-all-component-render">
                  <span className="common-page-search-icon-all-component-render">
                    <SearchIcon />
                  </span>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="search-input-all-component-render"
                  />
                </div>
                <div>
                  <Tooltip title="Total Question in QuestionBank" arrow>
                    <Avatar className="avatar-alignemnt-in-add-to-test">
                      {sortedData?.length}
                    </Avatar>
                  </Tooltip>
                </div>
                <div>
                  <FilterButton
                    columns={columns}
                    rows={AiGeneratedQuestionBank}
                    setSortedData={setSortedData}
                  />
                </div>
              </div>
              <div className="sidebar-content">
                <CreateTestQuestionView
                  rows={sortedData}
                  SetSeletedQuestion={SetSeletedQuestion}
                  selectedQuestions={selectedQuestions}
                  loading={loading}
                />
              </div>
            </div>
          )}

          {!isOpen && (
            <span>
              <Tooltip title="Open the Question Bank" arrow>
                <IconButton
                  className="toggle-button closed"
                  onClick={toggleSidebar}
                >
                  <ArrowBackIosIcon className="close-the-container-arrow-alignment" />
                </IconButton>
              </Tooltip>
            </span>
          )}
        </div>
      )}
      {editQuestion && (
        <AddtoQuestionBank
          onClose={handleCloseEditQuestion}
          update1={setquestionBankUpdate}
          editData={editData}
        />
      )}
    </>
  );
}

export default AllComponentRenderPage;
