import React, { useState } from "react";
import "./pdfpage.css"; // Import external CSS file
import Layout1 from "./Layouts/Layout1";
import Layout2 from "./Layouts/Layout2";
import LayoutsMain1 from "./LayoutsMain/LayoutsMain1";
import LayoutsMain2 from "./LayoutsMain/LayoutsMain2";

function PDF_layout_and_renderpage({filteredQuestions}) {

    console.log('filteredQuestions',filteredQuestions)
  const [selectedLayout, setSelectedLayout] = useState(null); // Track the selected layout

  const handleLayoutClick = (layout) => {
    setSelectedLayout(layout); // Update the selected layout
  };

  return (
    <div className="container-pdf-page">
      {/* Left Section (Fixed 20%) */}
      <div className="left-section-pdf-page">
        <div className="sample-headeing-pdf-download">Layouts</div>
        <div className="layouts-render-in-the-layout-section">
          <div
            className={`layout-item ${selectedLayout === "Layout1" ? "selected" : ""}`}
            onClick={() => handleLayoutClick("Layout1")}
          >
            <Layout1 />
            {selectedLayout === "Layout1" && <span className="checkmark">✔</span>} {/* Right mark when selected */}
          </div>

          <div
            className={`layout-item ${selectedLayout === "Layout2" ? "selected" : ""}`}
            onClick={() => handleLayoutClick("Layout2")}
          >
            <Layout2 />
            {selectedLayout === "Layout2" && <span className="checkmark">✔</span>} {/* Right mark when selected */}
          </div>
        </div>
      </div>

      {/* Right Section (Scrollable 80%) */}
      <div className="right-section-pdf-page">
        {selectedLayout === "Layout1" && <LayoutsMain1  filteredQuestions={filteredQuestions}/>}
        {selectedLayout === "Layout2" && <LayoutsMain2 filteredQuestions={filteredQuestions} />}
      </div>
    </div>
  );
}

export default PDF_layout_and_renderpage;
