import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseURL } from "../../../http";
import TableHeaderTemplate from "../../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import EmployeeListManageTemplate from "./EmployeeListManageTemplate";
import EmployeeListAddEditModal from "./EmployeeListAddEditModal";
import SendEmailForm from "./SendEmail";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';


function EmployeeListLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [open, SetOpen] = useState(false);
  const [openEdit, SetOpenEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [sendRequest, setSendRequest] = useState(false);

  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/Employees_fetch_all`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------

  const handleAddMoreFunctionality = () => {
    SetOpen(true);
  };

  const handleClose = () => {
    SetOpen(false);
  };

  //--------------------------------------------------------------------------------
  //Additional Functions if the custom menu added(custommenuitems)
  //--------------------------------------------------------------------------------

  const handleRequestSent = (row, rowid) => {
    setEditData(row);
    setSendRequest(true);
  };

  const handleModalClose = () => {
    setSendRequest(false);
  };



  //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------
  const customMenuItems = [
    {
      icon: AttachEmailIcon, //icon Name (import from the Mui)
      label: "Send Request", //Name of the MenuItem
      onClick: handleRequestSent, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    }
   
  ];

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------

  const handleEdit = (rowdata) => {
    SetOpenEdit(true);
    setEditData(rowdata);
  };

  const handdleModalCloseEdit = () => {
    SetOpenEdit(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      <TableHeaderTemplate
        columns={renderColumns} // Columns render with default column if empty
        rows={renderRows} // Rows render
        customMenuItems={customMenuItems} // Addition menu items
        handleEdit={handleEdit} //Handle the Edit function
        deleteApi="Employees_delete" // Delete Api
        tableNameForExport="Education-RoadMap" // Export the Data to excel/csv (If name requred for the Excel or Csv)
        is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
        handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
        Manage_button_name="Manage Employee" //Name of the Manage button(in center of the header)
        SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
        DefaultColumnNames={[
          "EmployeeName",
          "EmployeeEmail",
          "EmployeePhoneNumber",
          "EmployeeStatus",
        ]} // Add to show the columns in a default render
        CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
      />
      {/* _______________________End_____________________________ */}

      {/* __________________Manage More Functionality_________________ */}
      <EmployeeListManageTemplate
        open={open}
        close={handleClose}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />
      {/* _____________________end__________________________________ */}

      {/* _______________________Edit and Update the employee list_________ */}
      <EmployeeListAddEditModal
        openAddModal={openEdit}
        editData={editData}
        handleModalClose={handdleModalCloseEdit}
        SetDataBaseUpdate={SetDataBaseUpdate}
      />

      {/* _____________________end__________________________________ */}

      {/* __________________________Sendemail Form_____________________ */}
      <SendEmailForm
        editData={editData}
        sendRequest={sendRequest}
        handleModalClose={handleModalClose}
        setEditData={setEditData}
      />
    </div>
  );
}

export default EmployeeListLandingPage;
