import React, { useState, useEffect } from 'react'
import { baseURL } from "../../http";
import axios from "axios";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";

function HtesReachOut() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);

  useEffect(() => {
    const fetchReachOutData = async () => {
      try {
        const response = await axios.get(`${baseURL}/reach_out-get`);
        const data = response.data;

        // Assuming data contains blog fields
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));

        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Use index as unique ID for table row
          ...row,
        }));
        setRenderRows(rows);
        setDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchReachOutData();
  }, [dataBaseUpdate]);

  const handleEdit = (rowdata) => {
    // setEditData(rowdata);
    // setOpenEdit(true);
    console.log("handle Edit functions", rowdata);
  };


  return (
    <div>
      <TableHeaderTemplate
        columns={renderColumns} //Columns render
        rows={renderRows} //rows render
        handleEdit={handleEdit}
        deleteApi="reach_out-delete" //Delete Api
        tableNameForExport='Reach Out'//optionl
        is_Manage_Button_Required={true}//Mange button view if addition Items add
        Manage_button_name='Reach Out'
        SetDataBaseUpdate={setDataBaseUpdate}
        DefaultColumnNames={["Name", "Email", "Phone", "Services", "Message"]} // Add to show the columns in a default render
      />
    </div>
  )
}

export default HtesReachOut;
