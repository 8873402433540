import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import ExamPatternAddEditModal from "./ExamPatternAddEditModal";
import PDFQuestionPaper from "./PdfForQuestionPaer/PDFQuestionPaper";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

function ExamPatternLandingPage() {
  const [renderColumns, setRenderColumns] = useState([]); //Set the columns
  const [renderRows, setRenderRows] = useState([]); //Set the rows
  const [dataBaseUpdate, SetDataBaseUpdate] = useState(false); //set the tru every fuctionality completed and Reset to false once get api call
  const [CloseActionDialog, setCloseActionDialog] = useState(false); //Need to close the Action menu dropdown(once fuctionlaity triggered)
  const [open, setOpen] = useState(false);
  const [editData, setEditdata] = useState("");
  const [pdfData, setPdfData] = useState(null); // State to hold row data for PDF view
  //--------------------------------------------------------------------------------
  //UseEffect To render the Data From the Backend
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getpattern`);
        const data = response.data;

        // Assuming data contains `fillable` and the row data
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);

        // Format rows data
        const rows = data.map((row, index) => ({
          id: index + 1, // Assuming no 'id' field, use the index as a unique identifier
          ...row,
        }));
        setRenderRows(rows);
        SetDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTableData();
  }, [dataBaseUpdate]);

  //--------------------------------------------------------------------------------
  //Handle Edit function in edit(Action Dropdown)
  //--------------------------------------------------------------------------------
  const handleEdit = (rowdata) => {
    setEditdata(rowdata);
    setOpen(true);
  };
  //--------------------------------------------------------------------------------
  //Handle Manage Button Function
  //--------------------------------------------------------------------------------
  const handleAddMoreFunctionality = () => {
    setEditdata("");
    setOpen(true);
  };

  const handleCloseExam = () => {
    setOpen(false);
  };

  //--------------------------------------------------------------------------------
  //Handle Action dialog (menu)close
  //--------------------------------------------------------------------------------
  const handleActionClose = () => {
    setCloseActionDialog((prevState) => !prevState);
  };

   //--------------------------------------------------------------------------------
  //Add the aditional Customs Menus format(in action menu dropdown)
  //--------------------------------------------------------------------------------

  const handlePdf = (row) => {
    setPdfData(row); // Set the data for the PDF
  };
  const customMenuItems = [
    {
      icon: PictureAsPdfIcon, //icon Name (import from the Mui)
      label: "View", //Name of the MenuItem
      onClick: handlePdf, //Handle the Function(for working )
      IconColor: "green", //Color of the Icon
    },
  ];

  return (
    <div>
      {/* _______________________Main Component call____________ */}
      {!open &&  !pdfData &&(
        <TableHeaderTemplate
          columns={renderColumns} // Columns render with default column if empty
          rows={renderRows} // Rows render
          customMenuItems={customMenuItems}
          handleEdit={handleEdit} //Handle the Edit function
          deleteApi="deletetpattern" // Delete Api
          tableNameForExport="Exam_Pattern" // Export the Data to excel/csv (If name requred for the Excel or Csv)
          is_Manage_Button_Required={true} // Manage button view if addition Items add(in center of the header )
          handleManageButtonClick={handleAddMoreFunctionality} // If optional data required(in center of the header click function )
          Manage_button_name="Create Pattern" //Name of the Manage button(in center of the header)
          SetDataBaseUpdate={SetDataBaseUpdate} //Make the Table update when(delete)
          DefaultColumnNames={[ "PatternName", "TotalQuestions"]} // Add to show the columns in a default render
          CloseActionDialog={handleActionClose} //Handle the action dialog close when the functionality complete(action menu container)
        />
      )}
      {/* _______________________End_____________________________ */}

      {/* _________________create Exam pattern__________  */}
      {open && (
        <ExamPatternAddEditModal
          // open={open}
          handleClose1={handleCloseExam}
          DBupdate1={SetDataBaseUpdate}
          editData={editData}
        />
      )}

        {/* _____________________ PDF View Modal ____________ */}
        {pdfData && (
        <PDFQuestionPaper
          editData={pdfData} // Pass the row data to the PDFQuestionPaper component
          closePdf={() => setPdfData(null)} // Close the PDF view by setting pdfData to null
        />
      )}
    </div>
  );
}

export default ExamPatternLandingPage;
