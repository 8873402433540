import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, TextField, MenuItem, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import {
  getCurrentDateTime,
  getLocationInfo,
  getUtcTime,
} from "../../Common/Utilities_Function";
import "./enquiryform.css";
import { baseURL } from "../../../http";
import { customAlert } from "../../SweetAlertCommon/Custom";

const EnquiryFormModal = ({
  open,
  SetDataBaseUpdate,
  handleClose,
  initialFormData,
  EnquiryHeading,
  handleActionClose,
}) => {
  //-------------------------------------------------------------------------
  //States
  //-------------------------------------------------------------------------
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    Name: initialFormData?.Name || "",
    Email: initialFormData?.Email || "",
    Phone: initialFormData?.Phone || "",
    EnquiryType: initialFormData?.EnquiryType || "General",
    Message: initialFormData?.Message || "",
    Latitude: initialFormData?.Latitude || "",
    Longitude: initialFormData?.Longitude || "",
    UTC_Time: initialFormData?.UTC_Time || getUtcTime(),
  });

  //-------------------------------------------------------------------------
  // Update formData when initialFormData changes
  //-------------------------------------------------------------------------

  useEffect(() => {
    if (initialFormData) {
      setFormData({
        ...formData,
        ...initialFormData,
      });
    }
  }, [initialFormData, open]);

  //-------------------------------------------------------------------------
  //Effect to get location info
  //-------------------------------------------------------------------------
  useEffect(() => {
    const fetchLocation = async () => {
      if (!formData.Longitude) {
        const location = await getLocationInfo();
        if (location) {
          setFormData((prevData) => ({
            ...prevData,
            Latitude: location.latitude,
            Longitude: location.longitude,
          }));
        }
      }
    };

    fetchLocation();
  }, [formData.Latitude]);

  //-------------------------------------------------------------------------
  //Handle the Text fields Changes function
  //-------------------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //-------------------------------------------------------------------------
  //Validate form fields
  //-------------------------------------------------------------------------
  const validate = () => {
    let tempErrors = {};
    tempErrors.Name = formData.Name ? "" : "Name is required";
    tempErrors.Email = formData.Email
      ? /\S+@\S+\.\S+/.test(formData.Email)
        ? ""
        : "Email is not valid"
      : "Email is required";
    tempErrors.Phone = formData.Phone
      ? /^[0-9]{10}$/.test(formData.Phone)
        ? ""
        : "Phone number is not valid"
      : "Phone number is required";
    tempErrors.Message = formData.Message ? "" : "Question is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  //-------------------------------------------------------------------------
  //Handle the API for sending the enquiry
  //-------------------------------------------------------------------------
  const handleSubmit = async () => {
    if (validate()) {
      const updatedFormData = {
        ...formData,
        UTC_Time: getUtcTime(), // Update the UTC time before submitting
        Modified_by: localStorage.getItem("HTES_user_id"),
        Modified_date: getCurrentDateTime(),
      };

      try {
        const response = await axios.put(
          baseURL + `/enquiry_update/${initialFormData.id}`,
          updatedFormData
        );
        customAlert("", "Enquiry Modified SuccessFully", "success");
        handleClose();
        handleActionClose();
        SetDataBaseUpdate();
      } catch (error) {
        console.error("There was an error submitting the form!", error);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="enquiry-form-modal"
      aria-describedby="enquiry-form-description"
    >
      {/* ______________________Modal header and x icon_____________________ */}
      <div className="enquiry-form-container">
        <div className="enquiry-form-content-container">
          <div>
            <IconButton
              id="button-zoom"
              className="addeducationalMapmodalcancel"
              onClick={handleClose}
            >
              <CloseIcon className="addeducationalMapmodalcancelX" />
            </IconButton>
          </div>
          <div className="enquiry-table-header">
            Edit For - {EnquiryHeading ? EnquiryHeading : formData.EnquiryType}
          </div>
          {/* ______________________End_____________________ */}
          {/* _________________Grid Container all the Text fields_________________ */}
          <div className="enquiry-table-grid-container">
            <TextField
              label="Name"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.Name}
              helperText={errors.Name}
            />
            <TextField
              label="Email"
              name="Email"
              type="email"
              value={formData.Email}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.Email}
              helperText={errors.Email}
            />
            <TextField
              label="Phone"
              name="Phone"
              type="number"
              value={formData.Phone}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.Phone}
              helperText={errors.Phone}
              inputProps={{ min: 0, pattern: "[0-9]{10}" }}
            />
            <TextField
              select
              label="Enquiry For"
              name="EnquiryType"
              value={formData.EnquiryType}
              onChange={handleChange}
              fullWidth
              required
            >
              <MenuItem value="Course">Course</MenuItem>
              <MenuItem value="Instructor">Instructor</MenuItem>
              <MenuItem value="Internship">Internship</MenuItem>
              <MenuItem value="Training">Training</MenuItem>
              <MenuItem value="General">General</MenuItem>
            </TextField>
            <TextField
              label="Your Question"
              name="Message"
              multiline
              rows={3.7}
              value={formData.Message}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.Message}
              helperText={errors.Message}
            />
          </div>
          {/* ________________________end____________________ */}
          {/* _________________Button section_________________ */}
          <div className="enqury-table-button-alignment">
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              id="button-zoom"
            >
              <UpdateIcon className="buttonicons" /> Update
            </Button>
          </div>
          {/* ________________________End______________________*/}
        </div>
      </div>
    </Modal>
  );
};

export default EnquiryFormModal;
