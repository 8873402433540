import React from "react";
import "../pdfpage.css"; // Import external CSS for styling

function Layout1() {
  return (
    <div className="layout-container-download-pdf">
      <div className="image-box-download-pdf">
        <img
          src="https://via.placeholder.com/150" // Replace with your image URL
          alt="Placeholder"
          className="image-download-pdf"
        />
      </div>
    </div>
  );
}

export default Layout1;
