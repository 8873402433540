import React, { useEffect, useState, useRef } from "react";
import { TextField, MenuItem, Button, Avatar } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
import { baseURL } from "../../../http";
// import "./employeeformsend.css";
import { customAlert } from "../../SweetAlertCommon/Custom";
import {
  getCurrentDateTime,
  getUtcTime,
} from "../../Common/Utilities_Function";
import UpdateIcon from "@mui/icons-material/Update";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function EmployeePhase1InfoEdit() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    EmployeeName: "",
    DateOfBirth: "",
    Gender: "",
    AdhaarNumber: "",
    PersonalMailId: "",
    PhoneNumber: "",
    TenthMarksCard: "",
    PreUGMarksCard: "",
    UGMarksCard: "",
    CompletionCertificate: "",
    AadhaarDocument: "",
    Created_by: "",
    Created_date: getCurrentDateTime(),
    Modified_by: "",
    Modified_date: getCurrentDateTime(),
    UTC_Time: getUtcTime(),
    PanDocument: "",
    BankAccountDocument: "",
    PfAccountDocument: "",
    Resume: "",
    Photo:'',
    BloodGroup:'',
    DocumentStatus: {
      EmployeeName: { Status: "", Comment: "" },
      DateOfBirth: { Status: "", Comment: "" },
      Gender: { Status: "", Comment: "" },
      PhoneNumber: { Status: "", Comment: "" },
      AdhaarNumber: { Status: "", Comment: "" },
      PersonalMailId: { Status: "", Comment: "" },
      TenthMarksCard: { Status: "", Comment: "" },
      PreUGMarksCard: { Status: "", Comment: "" },
      UGMarksCard: { Status: "", Comment: "" },
      CompletionCertificate: { Status: "", Comment: "" },
      AadhaarDocument: { Status: "", Comment: "" },
      PanDocument: { Status: "", Comment: "" },
      BankAccountDocument: { Status: "", Comment: "" },
      PfAccountDocument: { Status: "", Comment: "" },
      Resume: { Status: "", Comment: "" },
      PanNumber: { Status: "", Comment: "" },
      BankAccountNumber: { Status: "", Comment: "" },
      PfAccountNumber: { Status: "", Comment: "" },
      IfscCode: { Status: "", Comment: "" },
      BloodGroup:{ Status: "", Comment: "" },
      Photo:{ Status: "", Comment: "" },
      // other fields as needed
    },
    PanNumber: "",
    BankAccountNumber: "",
    PfAccountNumber: "",
    IfscCode: "",
  });
  const employeeEditId = localStorage.getItem("Employee_Edit_Id");

  // Fetch employee data when component mounts
  useEffect(() => {
    if (employeeEditId) {
      axios
        .get(baseURL + `/Employees_fetch/${employeeEditId}`)
        .then((response) => {
          // Assuming the data comes in a similar structure
          const fetchedData = response.data;
          console.log("fetchedData", fetchedData);

          // Parse DocumentStatus if it's a string
          let documentStatus = fetchedData.DocumentStatus;

          if (typeof documentStatus === "string") {
            try {
              documentStatus = JSON.parse(documentStatus); // Convert to JSON object
            } catch (error) {
              console.error("Error parsing DocumentStatus:", error);
              documentStatus = {}; // Default to an empty object if parsing fails
            }
          }

          // Map the fetched data to the formData state
          setFormData({
            EmployeeName: fetchedData.EmployeeName || "",
            DateOfBirth: fetchedData.DateOfBirth || "",
            Gender: fetchedData.Gender || "",
            AdhaarNumber: fetchedData.AdhaarNumber || "",
            PersonalMailId: fetchedData.PersonalMailId || "",
            PhoneNumber: fetchedData.PhoneNumber || "",
            TenthMarksCard: fetchedData.TenthMarksCard || "",
            PreUGMarksCard: fetchedData.PreUGMarksCard || "",
            UGMarksCard: fetchedData.UGMarksCard || "",
            CompletionCertificate: fetchedData.CompletionCertificate || "",
            AadhaarDocument: fetchedData.AadhaarDocument || "",
            PanDocument: fetchedData.PanDocument || "",
            BankAccountDocument: fetchedData.BankAccountDocument || "",
            PfAccountDocument: fetchedData.PfAccountDocument || "",
            Resume: fetchedData.Resume || "",
            DocumentStatus: documentStatus, // Use parsed document status
            PanNumber: fetchedData.PanNumber || "",
            BankAccountNumber: fetchedData.BankAccountNumber || "",
            PfAccountNumber: fetchedData.PfAccountNumber || "",
            IfscCode: fetchedData.IfscCode || "",
            Photo:fetchedData.Photo || '',
            BloodGroup: fetchedData.BloodGroup || "",
          });

          const formattedPhoneNumber = `+${fetchedData.PhoneNumber}`;
          setPhone(formattedPhoneNumber);
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
        });
    }
  }, [employeeEditId]);

  console.log(formData)
  const [errors, setErrors] = useState({});
  const [errors1, setErrors1] = useState({});
  const [countryCode, setCountryCode] = useState("in");
  const textFields1 = ["PanNumber", "IfscCode"];
  const textFields = ["EmployeeName", "PersonalMailId"];
  const numberFields = ["AdhaarNumber", "BankAccountNumber", "PfAccountNumber"];
  const dateFields = ["DateOfBirth"];
  const dropdownFields = ["Gender"];
  const dropdownFields1 = ["BloodGroup"];
  const documentFields = [
    'Photo',
    "AadhaarDocument",
    "Resume",
    "TenthMarksCard",
    "PreUGMarksCard",
    "UGMarksCard",
    "CompletionCertificate",
    "PanDocument",
    "BankAccountDocument",
    "PfAccountDocument",
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Allowed file types and size limit (in bytes)
    const allowedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const maxSize = 2048 * 1024; // 2048 KB in bytes

    // Check if the event is for a file input
    if (files && files.length > 0) {
      const file = files[0];

      // Check file size
      if (file.size > maxSize) {
        customAlert(
          "",
          "File size exceeds the maximum limit of 2048 KB.",
          "warning"
        );
        return;
      }

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        customAlert(
          "",
          "Invalid file type. Only doc, docx, jpg, jpeg, png, and pdf files are allowed.",
          "warning"
        );
        return;
      }

      // Update formData with the selected file and change the status to "Pending"
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          [name]: {
            Status: "Pending", // Set the status to "Pending" when a file is selected
            Comment: prevData.DocumentStatus[name]?.Comment || "",
          },
        },
      }));
    } else {
      // Handle regular input fields (non-file inputs)
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          [name]: {
            Status: "Pending", // Set the status to "Pending" when a file is selected
            Comment: prevData.DocumentStatus[name]?.Comment || "",
          },
        },
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const tempErrors = {};

    if (!formData.EmployeeName) {
      newErrors.EmployeeName = "Employee Name is required";
    }

    if (!formData.AdhaarNumber || formData.AdhaarNumber.length !== 12) {
      newErrors.AdhaarNumber = "Aadhaar number must be 12 digits";
    }

    const phoneStr = String(phone).trim();
    const phoneNumber = parsePhoneNumberFromString(phoneStr, countryCode);
    if (phoneNumber) {
      if (!phoneNumber.isValid()) {
        tempErrors.PhoneNumber = "Phone number is not valid";
      }
    } else {
      tempErrors.PhoneNumber = "Phone number is required";
    }

    if (!formData.DateOfBirth) {
      newErrors.DateOfBirth = "Date of Birth is required";
    }

    if (!formData.Gender) {
      newErrors.Gender = "Gender is required";
    }

    if (
      !formData.PersonalMailId ||
      !/\S+@\S+\.\S+/.test(formData.PersonalMailId)
    ) {
      newErrors.PersonalMailId = "Valid email is required";
    }

    setErrors(newErrors);
    setErrors1(tempErrors);

    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(tempErrors).length === 0
    );
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.countryCode.toUpperCase());

    // Parse and validate the phone number
    const phoneNumber = parsePhoneNumberFromString(
      value,
      country.countryCode.toUpperCase()
    );

    if (phoneNumber && phoneNumber.isValid()) {
      // Update formData with a valid phone number and set status to "Pending"
      setFormData((prevData) => ({
        ...prevData,
        PhoneNumber: value,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          PhoneNumber: {
            Status: "Pending", // Set status to "Pending" for the phone number
            Comment: prevData.DocumentStatus?.PhoneNumber?.Comment || "",
          },
        },
      }));

      // Clear any phone number errors
      setErrors1((prev) => ({ ...prev, PhoneNumber: "" }));
    } else {
      // Set error if the phone number is not valid
      setErrors1((prev) => ({
        ...prev,
        PhoneNumber: "Phone number is not valid",
      }));
    }
  };

  const checkRequiredFields = () => {
    const requiredFields = [
      "EmployeeName",
      "DateOfBirth",
      "Gender",
      "AdhaarNumber",
      "PersonalMailId",
      "PhoneNumber",
      "TenthMarksCard",
      "PreUGMarksCard",
      "UGMarksCard",
    ];

    const missingFields = requiredFields.filter(
      (field) => !formData[field] || formData[field] === ""
    );

    if (missingFields.length > 0) {
      return `Missing fields: ${missingFields.join(", ")}`;
    }
    return null;
  };

  const handlCloseEditMode = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const missingFieldsError = checkRequiredFields();
    if (missingFieldsError) {
      customAlert("", missingFieldsError, "warning");
      return;
    }

    const payload = {
      ...formData,
      Created_by: formData.PersonalMailId, // Set 'Created_by' to 'PersonalMailId'
      Modified_by: formData.PersonalMailId, // Set 'Modified_by' to 'PersonalMailId'
      Created_date: getCurrentDateTime(), // Get the current date-time for 'Created_date'
      Modified_date: getCurrentDateTime(), // Get the current date-time for 'Modified_date'
      UTC_Time: getUtcTime(), // Get UTC time for 'UTC_Time'
      DocumentStatus: JSON.stringify(formData.DocumentStatus), // Convert DocumentStatus to JSON string
    };

    try {
      const response = await axios.post(baseURL + "/Employee_Data", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      customAlert(
        "",
        "Employee Basic Data has been successfully Updated",
        "success"
      );
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting form:", error.response.data);
    }
  };

  const renderTextFields = () =>
    textFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
  <div
    className="status-text-alignment"
    style={{
      color:
        formData.DocumentStatus[field]?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus[field]?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}
  >
    {formData.DocumentStatus[field]?.Status || "NA"}
  </div>
</div>

      </div>
    ));

  const renderTextFields1 = () =>
    textFields1.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment"style={{
      color:
        formData.DocumentStatus[field]?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus[field]?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}>
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderNumberFields = () =>
    numberFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
            type="number"
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment" style={{
      color:
        formData.DocumentStatus[field]?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus[field]?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}>
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderDateFields = () =>
    dateFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            required
            type="date"
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors[field]}
            helperText={errors[field]}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment" style={{
      color:
        formData.DocumentStatus[field]?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus[field]?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}>
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderDropdownFields = () =>
    dropdownFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            select
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </TextField>
        </div>
        <div className="status1">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment1">
          <div className="status-text-alignment" style={{
      color:
        formData.DocumentStatus[field]?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus[field]?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}>
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));


    const renderDropdownFields1 = () =>
      dropdownFields1.map((field, idx) => (
        <div className="text-field-status-comment" key={idx}>
          <div className="slno-and-text-fileds">
            <TextField
              key={idx}
              select
              label={field.replace(/([A-Z])/g, " $1").trim()}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors[field]}
              helperText={errors[field]}
            >
             <MenuItem value="A+ (A Positive)">A+ (A Positive)</MenuItem>
              <MenuItem value="A- (A Negative)">A- (A Negative)</MenuItem>
              <MenuItem value="B+ (B Positive)">B+ (B Positive)</MenuItem>
              <MenuItem value="B- (B Negative)">B- (B Negative)</MenuItem>
              <MenuItem value="AB+ (AB Positive)">AB+ (AB Positive)</MenuItem>
              <MenuItem value="AB- (AB Negative)">AB- (AB Negative)</MenuItem>
              <MenuItem value="O+ (O Positive)">O+ (O Positive)</MenuItem>
              <MenuItem value="AB- (AB Negative)">O- (O Negative)</MenuItem>
            </TextField>
          </div>
          <div className="status1">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment1">
          <div className="status-text-alignment" style={{
      color:
        formData.DocumentStatus[field]?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus[field]?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}>
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));
  const adjustedBaseURL = baseURL.slice(0, -4);
 
  
  const handleDeleteFile = (field) => {
    // Update formData to remove the file path
    setFormData(prevData => ({
        ...prevData,
        [field]: null, // Clear the file from formData
        DocumentStatus: {
            ...prevData.DocumentStatus,
            [field]: {
                Status: "Pending", // Reset status
                Comment: prevData.DocumentStatus[field]?.Comment || "",
            },
        },
    }));
};



  
  const renderDocumentFields = () =>
    documentFields.map((field, idx) => (
      <div className="text-field-status-comment1" key={idx}>
        <div className="slno-and-text-fileds">
          <div key={idx}>
            <label className="align-items-heading" htmlFor={field}>
              {`Upload ${field.replace(/([A-Z])/g, " $1").trim()}`}
            </label>
  
            <input
              type="file"
              id={field}
              name={field}
              onChange={handleChange}
              style={{ display: "none" }}
            />
  
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => document.getElementById(field).click()}
              >
                Choose File
              </Button>
  
              <span>
                &nbsp;
                {formData[field]?.name ? (
                  formData[field].name
                ) : formData[field] && typeof formData[field] === "string" ? (
                  <>
                    {/* Display link if document exists */}
                    <a
                      href={`${adjustedBaseURL}/uploads/${formData[field]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                     <span>{formData[field].split("/").pop()}</span>
                    </a>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteFile(field)}
                      aria-label="delete"
                    >
                      <DeleteIcon className="buttonicons"  color="error"/>
                    </IconButton>
                  </>
                ) : (
                  "No file chosen"
                )}
              </span>
  
              {/* Hidden input field to send document path if editing */}
              {formData[field] && typeof formData[field] === "string" && (
                <input
                  type="hidden"
                  name={`${field}_existing`}
                  value={formData[field]}
                />
              )}
            </div>
          </div>
        </div>
  
        {/* Comment Section */}
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
  
        {/* Status Text */}
        <div className="comment">
          <div className="status-text-alignment" style={{
      color:
        formData.DocumentStatus[field]?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus[field]?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}>
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

    const countFilledFields = (formData) => {
      // Define the fields you want to track
      const fields = [
        "EmployeeName",
        "DateOfBirth",
        "Gender",
        "AdhaarNumber",
        "PersonalMailId",
        "PhoneNumber",
        "TenthMarksCard",
        "PreUGMarksCard",
        "UGMarksCard",
        "CompletionCertificate",
        "AadhaarDocument",
        "PanDocument",
        "BankAccountDocument",
        "PfAccountDocument",
        "Resume",
        "PanNumber",
        "BankAccountNumber",
        "PfAccountNumber",
        "IfscCode",
        'BloodGroup',
        'Photo'
      ];
  
      let filledCount = 0;
      let totalCount = fields.length;
  
      fields.forEach((field) => {
        if (formData[field]) {
          filledCount += 1;
        }
      });
  
      return `${filledCount}/${totalCount}`;
    };
  
    const FilledCounts = countFilledFields(formData);
  return (
    <div className="phse1-employee-info-background-color">
      <div className="employee_phase1_container">
        <div className="employee_phase1_heading">
          <div>Edit Basic Information</div>
        </div>

        <div className="employee_phase1_main_container">
          <div className="employee_phase1_main_grid_container1">
            <div className="text-field-status-comment2">
              <div className="slno-and-text-fileds">Basic Data Fields</div>
              <div className="status">Comment</div>
              <div className="comment">Status&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </div>
          </div>

          <div className="employee_phase1_main_grid_container">
            {renderTextFields()}
            <div className="text-field-status-comment1">
              <div className="slno-and-text-fileds">
                <div className={errors1.PhoneNumber ? "phone-input-error" : ""}>
                  <PhoneInput
                    country={countryCode.toLowerCase()}
                    value={phone}
                    onChange={handlePhoneChange}
                    inputStyle={{
                      width: "100%",
                      height: "55px",
                      fontSize: "16px",
                      outline: errors1.PhoneNumber ? "1px solid red" : "",
                    }}
                    buttonStyle={{
                      background: "white",
                      borderLeft: errors1.PhoneNumber ? "2px solid red" : "",
                      borderTop: errors1.PhoneNumber ? "2px solid red" : "",
                      borderBottom: errors1.PhoneNumber ? "2px solid red" : "",
                      borderRight: "none",
                    }}
                    dropdownStyle={{
                      fontSize: "16px",
                    }}
                    placeholder="Employee Phone Number"
                  />
                  {errors1.PhoneNumber && (
                    <div className="helper-text-phone-container">
                      {errors1.PhoneNumber}
                    </div>
                  )}
                </div>
              </div>

              <div className="status">
                <TextField
                  name="comment-PhoneNumber"
                  value={formData.DocumentStatus.PhoneNumber?.Comment || ""}
                  onChange={(e) => {
                    const newComment = e.target.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      DocumentStatus: {
                        ...prevData.DocumentStatus,
                        PhoneNumber: {
                          // Retain the existing Status, only update Comment
                          Status:
                            prevData.DocumentStatus.PhoneNumber?.Status ||
                            "Pending",
                          Comment: newComment,
                        },
                      },
                    }));
                  }}
                  fullWidth
                  multiline
                  rows={1}
                  variant="outlined"
                  placeholder="Add your comment"
                />
              </div>

              <div className="comment">
                <div className="status-text-alignment" style={{
      color:
        formData.DocumentStatus['PhoneNumber']?.Status === "Approved"
          ? "green"
          : formData.DocumentStatus['PhoneNumber']?.Status === "Rejected"
          ? "red"
          : "black", // Default color for other statuses or "NA"
    }}>
                  {formData.DocumentStatus.PhoneNumber?.Status || "NA"}
                </div>
              </div>
            </div>

            {renderDateFields()}
            {renderDropdownFields()}
            {renderDropdownFields1()}
            {renderNumberFields()}
            {renderTextFields1()}
            {renderDocumentFields()}
          </div>
        </div>
        <div className="form-submit-button-of-employee-phase1">
        <div className="form-data-status-display">
            Filled Fields:
            <Avatar className="filled-fields-fontsize">{FilledCounts}</Avatar>
          </div>
          <div>
            <Button
              type="submit"
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handlCloseEditMode}
            >
              <ArrowBackIcon className="buttonicons" />
              &nbsp; Back
            </Button>
            &nbsp;
            <Button
              type="submit"
              variant="contained"
              color="success"
              id="button-zoom"
              onClick={handleSubmit}
            >
              <UpdateIcon className="buttonicons" />
              &nbsp; Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeePhase1InfoEdit;
