import React, { useState, useEffect } from 'react';
import { CardContent, Typography, Button, Box, Avatar, Paper, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ForumIcon from '@mui/icons-material/Forum';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './QABlog.css';
import axios from 'axios';
import { baseURL } from '../../http';
import { ReplyCard, ReplyDrawer } from './QABlog_userComment_card';

//----------------------------------------------------------------
//Function for date format
//----------------------------------------------------------------

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    }).replace(/ /g, '-');
}

function QuestionDetail({ questionData, onGoBack, questionId }) {

    //----------------------------------------------------------
    //State handle
    //----------------------------------------------------------

    const tags = Array.isArray(questionData.tags) ? questionData.tags.join(', ') : '';
    const [isReplyOpen, setReplyOpen] = useState(false);
    const [replyText, setReplyText] = useState('');
    const [replies, setReplies] = useState([]);
    const [replyCount, setReplyCount] = useState(0);
    const [selectedReply, setSelectedReply] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [likes, setLikes] = useState(questionData.like_count || 0);
    const [dislikes, setDislikes] = useState(questionData.dislike_count || 0);
    const [hasLiked, setHasLiked] = useState(false);
    const [hasDisliked, setHasDisliked] = useState(false);
    const [user, setUser] = useState("John");

    //----------------------------------------------------------------
    // Fetch replies when the component mounts
    //----------------------------------------------------------------

    useEffect(() => {
        const fetchReplies = async () => {
            try {
                const response = await axios.get(`${baseURL}/questions/${questionId}/replies`);
                setReplies(response.data.replies);
                setReplyCount(response.data.replies.length);
            } catch (error) {
                console.error('Error fetching replies:', error);
            }
        };
        fetchReplies();
    }, [questionId]);

    //----------------------------------------------------------------
    //Function to open and close reply button
    //----------------------------------------------------------------

    const handleReplyOpen = () => {
        setReplyOpen(!isReplyOpen);
    };

    const handleReplyClose = () => {
        setReplyOpen(false);
        setEditMode(false);
        setReplyText('');
        setSelectedReply(null);
    };

    //----------------------------------------------------------------
    //To submit the comment and edit and delete the comment
    //----------------------------------------------------------------

    const handleReplySubmit = async (replyText, htmlContent) => {
        try {
            let response;
            if (editMode && selectedReply) {
                response = await axios.put(`${baseURL}/questions/${questionId}/replies/${selectedReply.id}`, {
                    normalContent: replyText,
                    htmlContent,
                    user: user
                });
            } else {
                response = await axios.post(`${baseURL}/questions/${questionId}/replies`, {
                    normalContent: replyText,
                    htmlContent,
                    user: user
                });
            }

            if (response.status === 200 || response.status === 201) {
                const updatedReply = response.data.reply;

                setReplies((prevReplies) => {
                    if (editMode && selectedReply) {
                        return prevReplies.map((reply) =>
                            reply.id === selectedReply.id ? updatedReply : reply
                        );
                    } else {
                        return [...prevReplies, updatedReply];
                    }
                });

                if (!editMode) {
                    setReplyCount((prevCount) => prevCount + 1);
                }

                handleReplyClose();
                setReplyText('');
            }
        } catch (error) {
            console.error('Error adding reply:', error);
        }
    };

    //----------------------------------------------------------------
    //Edit the submitted reply
    //----------------------------------------------------------------

    const handleEditReply = (reply) => {
        setReplyText(reply.normalContent);
        setEditMode(true);
        setSelectedReply(reply);
        setReplyOpen(true);
    };

    //----------------------------------------------------------------
    //Delete the submitted reply
    //----------------------------------------------------------------

    const handleDeleteReply = async (reply) => {
        if (!reply) return;

        try {
            const response = await axios.delete(`${baseURL}/questions/${questionData.id}/replies/${reply.id}`);
            if (response.status === 200) {
                setReplies((prevReplies) => prevReplies.filter((r) => r.id !== reply.id));
                setReplyCount((prevCount) => prevCount - 1);
            } else {
                console.error('Failed to delete reply:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting reply:', error);
        }
    };

    //----------------------------------------------------------------
    //Api for like and dislike for each question
    //----------------------------------------------------------------

    const reactToQuestion = async (questionId, reaction) => {
        try {
            const response = await axios.post(`${baseURL}/questionblog/${questionId}/react`, { reaction });
            const updatedQuestion = response.data.question;
            setLikes(updatedQuestion.like_count);
            setDislikes(updatedQuestion.dislike_count);
        } catch (error) {
            console.error('Error reacting to question:', error);
        }
    };

    //----------------------------------------------------------------
    //Function to handle like
    //----------------------------------------------------------------

    const handleLike = () => {
        if (hasLiked) {
            setLikes(likes - 1);
            setHasLiked(false);
        } else {
            setLikes(likes + 1);
            if (hasDisliked) {
                setDislikes(dislikes - 1);
                setHasDisliked(false);
            }
            setHasLiked(true);
            reactToQuestion(questionData.id, 'like');
        }
    };

    //----------------------------------------------------------------
    //Function to handle dislike
    //----------------------------------------------------------------

    const handleDislike = () => {
        if (hasDisliked) {
            setDislikes(dislikes - 1);
            setHasDisliked(false);
        } else {
            setDislikes(dislikes + 1);
            if (hasLiked) {
                setLikes(likes - 1);
                setHasLiked(false);
            }
            setHasDisliked(true);
            reactToQuestion(questionData.id, 'dislike');
        }
    };

    return (
        <Box className="question-detail-container">

            {/* ________Go back and reply button__________ */}

            <div className="top-buttons-container">
                <Button variant="contained" color="success" onClick={onGoBack} className="go-back-button">
                    <ArrowBackIosIcon /> Go Back
                </Button>
                <Button variant="contained" color="success" onClick={handleReplyOpen} className="reply-top-button">
                    <AddCommentIcon sx={{ mr: 2 }} /> Reply to this topic
                </Button>
            </div>

            {/* _____Avatar and details for each card______ */}

            <Paper elevation={4} className='main-container'>
                <CardContent>
                    <Box className="avatar-container">
                        <Avatar className="avatar-margin">{questionData.user.charAt(0)}</Avatar>
                        <Box>
                            <Typography variant="body1">{questionData.user}</Typography>
                            <Typography variant="caption" color="text.secondary">
                                Asked on: {formatDate(questionData.last_modified_date)}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="h5" component="div" className="question-typography">
                        {questionData.normalContent}
                    </Typography>

                    {/*_____________________Tags______________________*/}

                    <Box className="box-space-between">
                        <Box>
                            <Typography variant="body2">
                                Tags: {tags}
                            </Typography>
                        </Box>
                    </Box>
                    <hr className='Question_FooterLine' />

                    {/* ____________________Options for other details___________________________ */}

                    <Box className="box-align-center">
                        <Box className="box-column-center">
                            <ForumIcon />
                            <Typography variant="body2">
                                Replies
                            </Typography>
                            <Typography variant="body2">
                                {replyCount}
                            </Typography>
                        </Box>
                        <Box className="box-column-center">
                            <VisibilityIcon />
                            <Typography variant="body2">
                                Views
                            </Typography>
                            <Typography variant="body2">
                                {questionData.view_count}
                            </Typography>
                        </Box>
                        <Box className="box-column-center">
                            <ThumbUpIcon onClick={handleLike} style={{ color: hasLiked ? 'blue' : 'inherit' }} />
                            <Typography variant="body2">
                                Likes
                            </Typography>
                            <Typography variant="body2">
                                {likes}
                            </Typography>
                        </Box>
                        <Box className="box-column-center">
                            <ThumbDownIcon onClick={handleDislike} style={{ color: hasDisliked ? 'red' : 'inherit' }} />
                            <Typography variant="body2">
                                Dislikes
                            </Typography>
                            <Typography variant="body2">
                                {dislikes}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Paper>

            {/* ___________________Reply card_______________________ */}

            {replies.map((reply) => (
                <ReplyCard
                    key={reply.id}
                    reply={reply}
                    onEdit={handleEditReply}
                    onDelete={handleDeleteReply}
                />
            ))}

            {/*________________Button for replying to the question______________*/}

            <Box className={`reply-button-fixed ${isReplyOpen ? 'reply-button-fixed-open' : ''}`}>
                <Button variant="contained" color="success" onClick={handleReplyOpen}>
                    <AddCommentIcon sx={{ mr: 2 }} /> Reply to this topic
                </Button>
            </Box>

            {/* ____________Drawer______________ */}

            <ReplyDrawer
                isOpen={isReplyOpen}
                onClose={handleReplyClose}
                onSubmit={handleReplySubmit}
                replyText={replyText}
                setReplyText={setReplyText}
                editMode={editMode}
                questionId={questionId}
                replyId={selectedReply ? selectedReply.id : null}
            />
        </Box>
    );
}

export default QuestionDetail;
