import React, { useState } from "react";
import { IconButton, Tooltip, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { customAlert } from "../../SweetAlertCommon/Custom";
import "./coursetemplate2.css";

const Coursetemplate2Content = ({
  isChecked,
  title,
  courseContent,
  setCourseContent,
}) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState();
  const [header, setHeader] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [expanded, setExpanded] = useState([]); // State to track expanded items
  const [expand, setExpand] = useState(true);

  //--------------------------------------------------------------------------------
  // Handle Form Submission both update and post
  //--------------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
    setText("");
    setHeader("");
    setEditingIndex(null); // Reset editing index
  };

  //--------------------------------------------------------------------------------
  // Handle the text change in the text area
  //--------------------------------------------------------------------------------

  const handleTextChange = (e) => {
    const value = e.target.value;
    setText(value);
  };
  //--------------------------------------------------------------------------------
  // Handle key down events for text area (supporting bullet points)
  //--------------------------------------------------------------------------------

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const { selectionStart, selectionEnd, value } = e.target;
      const beforeCaret = value.slice(0, selectionStart);
      const afterCaret = value.slice(selectionEnd);
      setText(`${beforeCaret}\n• ${afterCaret}`);
      setTimeout(() => {
        e.target.selectionStart = selectionStart + 3;
        e.target.selectionEnd = selectionStart + 3;
      }, 0);
    }
  };

  //--------------------------------------------------------------------------------
  // Save the Text as an object with title and content
  //--------------------------------------------------------------------------------

  const handleSave = () => {


  const points = text
    .split("\n")
    .map((point) => point.trim())
    .filter((point) => point !== "•")
    .map((point) => point.replace(/^•\s*/, ""));

  // if (points.length === 0) {
  //   customAlert('', "Content cannot be empty", 'warning');
  //   return;
  // }

  const newContent = [...courseContent];
  const contentItem = { header, points };

  if (editingIndex !== null) {
    newContent[editingIndex] = contentItem;
  } else {
    newContent.push(contentItem);
  }

  setCourseContent(newContent);
  handleClose();
};

  

  //--------------------------------------------------------------------------------
  // Edit existing content
  //--------------------------------------------------------------------------------

  const handleEdit = (index) => {
    setEditingIndex(index);
    setHeader(courseContent[index].header);
    const existingPoints = courseContent[index].points
      .map((point) => `• ${point}`)
      .join("\n");
    setText(existingPoints);
    setOpen(true);
  };

  //--------------------------------------------------------------------------------
  // Delete a section by index
  //--------------------------------------------------------------------------------

  const handleDelete = (index) => {
    const newContent = courseContent.filter((_, i) => i !== index);
    setCourseContent(newContent);
  };

  //--------------------------------------------------------------------------------
  // Toggle expand/collapse (subaccordion)
  //--------------------------------------------------------------------------------

  const handleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      if (newExpanded.includes(index)) {
        newExpanded.splice(newExpanded.indexOf(index), 1);
      } else {
        newExpanded.push(index);
      }
      return newExpanded;
    });
  };

  //--------------------------------------------------------------------------------
  //Toggle the expand the container
  //--------------------------------------------------------------------------------

  const handleExpandMain = () => {
    setExpand((prev) => !prev);
  };

  //--------------------------------------------------------------------------------
  // //   handle delete all content
  //--------------------------------------------------------------------------------
  const handleDeleteAll = () => {
    setCourseContent([]);
  };

  return (
    <>
      {/* ______________________________Heading and Buttons_____________ */}
      <div className="coursetemplate2-general-first-three-tabs">
        <div
          className={`coursetemplate2-title-and-expand-container-overall ${
            expand ? "" : "active"
          }`}
        >
          <div className="coursetemplate2-title-and-expand-container">
            <div
              className="coursetemplate2-title-expandicon"
              onClick={handleExpandMain}
            >
              <h6 className="coursetemplete2-title">{title}</h6>
              <IconButton>
                {expand ? (
                  <ExpandLessIcon className="course-template-expand-icon-color" />
                ) : (
                  <ExpandMoreIcon className="expand-icon-color" />
                )}
              </IconButton>
            </div>

            <div>
              {open ? (
                <Tooltip title="Save" arrow>
                  <IconButton onClick={handleSave}>
                    <SaveIcon color="success" />
                  </IconButton>
                </Tooltip>
              ) : (
                isChecked && (
                  <Tooltip title="Add" arrow>
                    <IconButton onClick={() => setOpen(true)}>
                      <AddIcon className="courseTemplate2-edit-icon-color" />
                    </IconButton>
                  </Tooltip>
                )
              )}
              {isChecked && (
                <Tooltip title="Delete" arrow>
                  <IconButton onClick={handleDeleteAll}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div>
            {/* ______________________________Header textfiled and Textarea_____________ */}
            {open && (
              <div className="coursetemplate2-content-textare-and-textfiled-coantainer">
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Content Header"
                  value={header}
                  onChange={(e) => setHeader(e.target.value)}
                  className="coursetemplate2-content-textfiled-fontsize"
                />
                <textarea
                  className="cousetemplate2-content-textarea"
                  value={text}
                  onChange={handleTextChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter content here..."
                />
              </div>
            )}
            {/* ______________________________sub accrodion expand collaps_____________ */}
            {expand &&
              courseContent.map((item, index) => (
                <div
                  className={`coursetemplate2-subtitle-and-expand-container-overall ${
                    expanded.includes(index) ? "active" : ""
                  }`}
                >
                  <div
                    key={index}
                    className="coursetemplate2-title-and-expand-container"
                  >
                    <div
                      className="coursetemplate2-title-expandicon"
                      onClick={() => handleExpand(index)}
                    >
                      <div className="content-dropdown-and-header">
                        <h6 className="coursetemplete2-subtitle">
                          {item.header}
                        </h6>
                      </div>
                      <div>
                        <IconButton>
                          {expanded.includes(index) ? (
                            <ExpandLessIcon className="expand-icon-color" />
                          ) : (
                            <ExpandMoreIcon className="expand-icon-color" />
                          )}
                        </IconButton>
                        {isChecked && (
                          <Tooltip title="Edit" arrow>
                            <IconButton onClick={() => handleEdit(index)}>
                              <EditIcon className="buttoncolorEdit" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isChecked && (
                          <Tooltip title="Delete" arrow>
                            <IconButton onClick={() => handleDelete(index)}>
                              <DeleteIcon
                                className="buttoncolorEdit"
                                color="error"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* ______________________________Text Render area container_____________ */}
                  {expanded.includes(index) && (
                    <div className="coursetemplate2-content-render-conatiner">
                      {item.points.map((point, pointIndex) => (
                        <div className="coursetemplate2-checkicon-and-points">
                          <div>
                            <FiberManualRecordIcon className="buttetpoints" />
                          </div>
                          &nbsp;
                          {point}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            {/* _______________________________end________________________ */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Coursetemplate2Content;
