import React, { useEffect, useState, useRef } from "react";
import { TextField, MenuItem, Button, Avatar } from "@mui/material";
import NearMeIcon from "@mui/icons-material/NearMe";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";
import { baseURL } from "../../../http";
import { useNavigate } from "react-router-dom";

import "./employeeformsend.css";
import { customAlert } from "../../SweetAlertCommon/Custom";
import {
  getCurrentDateTime,
  getUtcTime,
} from "../../Common/Utilities_Function";
import EmailEnterModal from "./EmailEnterModal";

function EmployeePhase1Info() {
  const [formData, setFormData] = useState({
    EmployeeName: "",
    DateOfBirth: "",
    Gender: "",
    AdhaarNumber: "",
    PersonalMailId: "",
    PhoneNumber: "",
    TenthMarksCard: "",
    PreUGMarksCard: "",
    UGMarksCard: "",
    CompletionCertificate: "",
    AadhaarDocument: "",
    Photo: "",
    Created_by: "",
    Created_date: getCurrentDateTime(),
    Modified_by: "",
    Modified_date: getCurrentDateTime(),
    UTC_Time: getUtcTime(),
    PanDocument: "",
    BankAccountDocument: "",
    PfAccountDocument: "",
    Resume: "",
    MedicalInsuranceNumber: "",
    BloodGroup: "",
    DocumentStatus: {
      EmployeeName: { Status: "", Comment: "" },
      DateOfBirth: { Status: "", Comment: "" },
      Gender: { Status: "", Comment: "" },
      PhoneNumber: { Status: "", Comment: "" },
      AdhaarNumber: { Status: "", Comment: "" },
      PersonalMailId: { Status: "", Comment: "" },
      TenthMarksCard: { Status: "", Comment: "" },
      PreUGMarksCard: { Status: "", Comment: "" },
      UGMarksCard: { Status: "", Comment: "" },
      CompletionCertificate: { Status: "", Comment: "" },
      AadhaarDocument: { Status: "", Comment: "" },
      PanDocument: { Status: "", Comment: "" },
      BankAccountDocument: { Status: "", Comment: "" },
      PfAccountDocument: { Status: "", Comment: "" },
      Resume: { Status: "", Comment: "" },
      PanNumber: { Status: "", Comment: "" },
      BankAccountNumber: { Status: "", Comment: "" },
      PfAccountNumber: { Status: "", Comment: "" },
      IfscCode: { Status: "", Comment: "" },
      Photo: { Status: "", Comment: "" },
      BloodGroup: { Status: "", Comment: "" },
      // other fields as needed
    },
    PanNumber: "",
    BankAccountNumber: "",
    PfAccountNumber: "",
    IfscCode: "",
  });

  const [tokenValid, setTokenValid] = useState(false);
  const history = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [EmployeeDataFetch, setEmployeeDataFetch] = useState([]);
  const [employeeEditId, setemployeeEditId] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const [errors1, setErrors1] = useState({});
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("IN");
  const textFields1 = ["PanNumber"];
  const textFields2 = ["IfscCode"];
  const textFields = ["PersonalMailId"];
  const textFields3 = ["EmployeeName"];
  const numberFields = ["AdhaarNumber"];
  const numberFields1 = ["BankAccountNumber"];
  const numberFields2 = ["PfAccountNumber"];
  const dateFields = ["DateOfBirth"];
  const dropdownFields = ["Gender"];
  const dropdownFields1 = ["BloodGroup"];
  const documentFields = [
    "Photo",
    "AadhaarDocument",
    "Resume",
    "TenthMarksCard",
    "PreUGMarksCard",
    "UGMarksCard",
    "CompletionCertificate",
    "PanDocument",
    "BankAccountDocument",
    "PfAccountDocument",
  ];

  useEffect(() => {
    if(employeeEditId ){
    // Get token from URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    console.log(token);
    // If no token found, display an error
    if (!token) {
      customAlert("", "Invalid or missing token.", "error");
      return;
    }

    // Send token to backend for validation
    axios
      .post(`${baseURL}/check_for_validity`, { token,email })
      .then((response) => {
        if (response.status === 200) {
          setTokenValid(true);
        }
      })
      .catch((error) => {
        customAlert(
          "",
          "This link is invalid or has already been used, please contact HR Team",
          "warning"
        );
      });
    }
  }, [history,email, employeeEditId]);

  console.log("tokenValid", tokenValid);

  const countFilledFields = (formData) => {
    // Define the fields you want to track
    const fields = [
      "EmployeeName",
      "DateOfBirth",
      "Gender",
      "AdhaarNumber",
      "PersonalMailId",
      "PhoneNumber",
      "TenthMarksCard",
      "PreUGMarksCard",
      "UGMarksCard",
      "CompletionCertificate",
      "AadhaarDocument",
      "PanDocument",
      "BankAccountDocument",
      "PfAccountDocument",
      "Resume",
      "PanNumber",
      "BankAccountNumber",
      "PfAccountNumber",
      "IfscCode",
      "BloodGroup",
      "Photo",
    ];

    let filledCount = 0;
    let totalCount = fields.length;

    fields.forEach((field) => {
      if (formData[field]) {
        filledCount += 1;
      }
    });

    return `${filledCount}/${totalCount}`;
  };

  const FilledCounts = countFilledFields(formData);

  const handleModalClose = () => {
    setOpen(false);
  };

  const validateEmail = (email) => {
    // Simple regex for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitEmail = async () => {
    if (validateEmail(email)) {
      try {
        // Fetch all employee data
        const response = await axios.get(`${baseURL}/Employees_fetch_all`);
        const employees = response.data; // Assuming API returns employee data as an array

        // Find the employee with the entered email
        const matchedEmployee = employees.find(
          (employee) => employee.EmployeeEmail === email
        );

        if (matchedEmployee) {
          // Store the data of the matched employee
          setEmployeeDataFetch(matchedEmployee);

          // Proceed with the submission if email is found
          setEmail(email); // Call parent function to handle email submission
          handleModalClose(); // Close modal after submission
          setemployeeEditId(true); // Set the employee edit state as needed
        } else {
          // If email does not exist, show a custom alert
          customAlert(
            "",
            "Employee mail id not found. Please enter a valid employee email.",
            "warning"
          );
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
        // Handle API error here
        setEmailError(
          "An error occurred while validating the email. Please try again."
        );
      }
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Check if the event is for a file input
    if (files && files.length > 0) {
      const file = files[0];

      // Update formData with the selected file and change the status to "Pending"
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          [name]: {
            Status: "Pending", // Set the status to "Pending" when a file is selected
            Comment: prevData.DocumentStatus[name]?.Comment || "",
          },
        },
      }));
    } else {
      // Handle regular input fields (non-file inputs)
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          [name]: {
            Status: "Pending", // Set the status to "Pending" when a file is selected
            Comment: prevData.DocumentStatus[name]?.Comment || "",
          },
        },
      }));
    }
  };

  useEffect(() => {
    if (employeeEditId && EmployeeDataFetch) {
      // Only update EmployeeName, PersonalMailId, and PhoneNumber
      setFormData((prevFormData) => ({
        ...prevFormData, // Keep the rest of the formData unchanged
        EmployeeName: EmployeeDataFetch.EmployeeName || "",
        PersonalMailId: EmployeeDataFetch.EmployeeEmail || "",
        PhoneNumber: EmployeeDataFetch.EmployeePhoneNumber || "",
      }));
      setPhone(`+${EmployeeDataFetch.EmployeePhoneNumber} || ''`);
    }
  }, [employeeEditId, EmployeeDataFetch]);

  const validateForm = () => {
    const newErrors = {};
    const tempErrors = {};

    if (!formData.EmployeeName) {
      newErrors.EmployeeName = "Employee Name is required";
    }

    if (!formData.AdhaarNumber || formData.AdhaarNumber.length !== 12) {
      newErrors.AdhaarNumber = "Aadhaar number must be 12 digits";
    }

    // const phoneStr = String(phone).trim();
    // const phoneNumber = parsePhoneNumberFromString(phoneStr, countryCode);
    // if (phoneNumber) {
    //   if (!phoneNumber.isValid()) {
    //     tempErrors.PhoneNumber = "Phone number is not valid";
    //   }
    // } else {
    //   tempErrors.PhoneNumber = "Phone number is required";
    // }

    if (!formData.DateOfBirth) {
      newErrors.DateOfBirth = "Date of Birth is required";
    }

    if (!formData.Gender) {
      newErrors.Gender = "Gender is required";
    }

    if (
      !formData.PersonalMailId ||
      !/\S+@\S+\.\S+/.test(formData.PersonalMailId)
    ) {
      newErrors.PersonalMailId = "Valid email is required";
    }

    setErrors(newErrors);
    setErrors1(tempErrors);

    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(tempErrors).length === 0
    );
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.countryCode.toUpperCase());

    // Parse and validate the phone number
    const phoneNumber = parsePhoneNumberFromString(
      value,
      country.countryCode.toUpperCase()
    );

    if (phoneNumber && phoneNumber.isValid()) {
      // Update formData with a valid phone number and set status to "Pending"
      setFormData((prevData) => ({
        ...prevData,
        PhoneNumber: value,
        DocumentStatus: {
          ...prevData.DocumentStatus,
          PhoneNumber: {
            Status: "Pending", // Set status to "Pending" for the phone number
            Comment: prevData.DocumentStatus?.PhoneNumber?.Comment || "",
          },
        },
      }));

      // Clear any phone number errors
      setErrors1((prev) => ({ ...prev, PhoneNumber: "" }));
    } else {
      // Set error if the phone number is not valid
      setErrors1((prev) => ({
        ...prev,
        PhoneNumber: "Phone number is not valid",
      }));
    }
  };

  const checkRequiredFields = () => {
    const requiredFields = [
      "EmployeeName",
      "DateOfBirth",
      "Gender",
      "AdhaarNumber",
      "PersonalMailId",
      "PhoneNumber",
      "TenthMarksCard",
      "PreUGMarksCard",
      "UGMarksCard",
    ];

    const missingFields = requiredFields.filter(
      (field) => !formData[field] || formData[field] === ""
    );

    if (missingFields.length > 0) {
      return `Missing fields: ${missingFields.join(", ")}`;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate the form
    if (!validateForm()) {
      return; // Exit if validation fails
    }
  
    const missingFieldsError = checkRequiredFields();
    if (missingFieldsError) {
      customAlert("", missingFieldsError, "warning");
      return;
    }
  
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
  
    const payload = {
      ...formData,
      Created_by: formData.PersonalMailId, // Set 'Created_by' to 'PersonalMailId'
      Modified_by: formData.PersonalMailId, // Set 'Modified_by' to 'PersonalMailId'
      Created_date: getCurrentDateTime(), // Get the current date-time for 'Created_date'
      Modified_date: getCurrentDateTime(), // Get the current date-time for 'Modified_date'
      UTC_Time: getUtcTime(), // Get UTC time for 'UTC_Time'
      DocumentStatus: JSON.stringify(formData.DocumentStatus), // Convert DocumentStatus to JSON string
    };
  
    try {
      // Validate the token
      const tokenResponse = await axios.post(baseURL + "/validate_Tokens", { token });
      
      if (tokenResponse.status === 200) {
        // Token is valid, proceed with form submission
        const response = await axios.post(baseURL + "/Employee_Data", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        
        customAlert(
          "",
          "Employee Basic Data has been successfully saved. ",
          "success"
        );
        setTimeout(() => {
          window.close();
        }, 2000);
        
      } else {
        customAlert("", "Invalid or expired token.", "warning");
        
      }
    } catch (error) {
      console.error("Error during token validation or form submission:", error.response?.data || error.message);
      customAlert("", "Failed to submit form or validate token.", "error");
    }
  };
  

  console.log(EmployeeDataFetch);

  const renderTextFields = () =>
    textFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            disabled
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "Approved"}
          </div>
        </div>
      </div>
    ));

  const renderTextFields2 = () =>
    textFields2.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderTextFields3 = () =>
    textFields3.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderTextFields1 = () =>
    textFields1.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderNumberFields = () =>
    numberFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
            type="number"
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderNumberFields1 = () =>
    numberFields1.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
            type="number"
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));
  const renderNumberFields2 = () =>
    numberFields2.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
            type="number"
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderDateFields = () =>
    dateFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            required
            type="date"
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors[field]}
            helperText={errors[field]}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderDropdownFields = () =>
    dropdownFields.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            select
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </TextField>
        </div>
        <div className="status1">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment1">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderDropdownFields1 = () =>
    dropdownFields1.map((field, idx) => (
      <div className="text-field-status-comment" key={idx}>
        <div className="slno-and-text-fileds">
          <TextField
            key={idx}
            select
            label={field.replace(/([A-Z])/g, " $1").trim()}
            name={field}
            value={formData[field]}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors[field]}
            helperText={errors[field]}
          >
            <MenuItem value="A+ (A Positive)">A+ (A Positive)</MenuItem>
            <MenuItem value="A- (A Negative)">A- (A Negative)</MenuItem>
            <MenuItem value="B+ (B Positive)">B+ (B Positive)</MenuItem>
            <MenuItem value="B- (B Negative)">B- (B Negative)</MenuItem>
            <MenuItem value="AB+ (AB Positive)">AB+ (AB Positive)</MenuItem>
            <MenuItem value="AB- (AB Negative)">AB- (AB Negative)</MenuItem>
            <MenuItem value="O+ (O Positive)">O+ (O Positive)</MenuItem>
            <MenuItem value="AB- (AB Negative)">O- (O Negative)</MenuItem>
          </TextField>
        </div>
        <div className="status1">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment1">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  const renderDocumentFields = () =>
    documentFields.map((field, idx) => (
      <div className="text-field-status-comment1" key={idx}>
        <div className="slno-and-text-fileds">
          <div key={idx}>
            <label
              className="align-items-heading"
              htmlFor={field}
            >{`Upload ${field.replace(/([A-Z])/g, " $1").trim()}`}</label>
            <input
              type="file"
              id={field}
              name={field}
              onChange={handleChange}
              style={{ display: "none" }}
            />
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => document.getElementById(field).click()}
              >
                Choose File
              </Button>
              <span>
                &nbsp;
                {formData[field] ? formData[field].name : "No file chosen"}
              </span>
            </div>
          </div>
        </div>
        <div className="status">
          <TextField
            name={`comment-${field}`}
            value={formData.DocumentStatus[field]?.Comment || ""}
            onChange={(e) => {
              const newComment = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                DocumentStatus: {
                  ...prevData.DocumentStatus,
                  [field]: {
                    // Retain the existing Status, only update Comment
                    Status: prevData.DocumentStatus[field]?.Status || "Pending",
                    Comment: newComment,
                  },
                },
              }));
            }}
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            placeholder="Add your comment"
          />
        </div>
        <div className="comment">
          <div className="status-text-alignment">
            {formData.DocumentStatus[field]?.Status || "NA"}
          </div>
        </div>
      </div>
    ));

  return (
    <>
      {employeeEditId && tokenValid && (
        <div className="phse1-employee-info-background-color">
          <div className="employee_phase1_container">
            <div className="employee_phase1_heading">
              <div>Employee Basic Information</div>
            </div>

            <div className="employee_phase1_main_container">
              <div className="employee_phase1_main_grid_container1">
                <div className="text-field-status-comment2">
                  <div className="slno-and-text-fileds">Basic Data Fields</div>
                  <div className="status">Comment</div>
                  <div className="comment">Status&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
              </div>

              <div className="employee_phase1_main_grid_container">
                {renderTextFields()}
                <div className="text-field-status-comment1">
                  <div className="slno-and-text-fileds">
                    <div
                      className={errors1.PhoneNumber ? "phone-input-error" : ""}
                    >
                      <PhoneInput
                        disabled
                        country={countryCode.toLowerCase()}
                        value={phone}
                        onChange={handlePhoneChange}
                        inputStyle={{
                          width: "100%",
                          height: "55px",
                          fontSize: "16px",
                          outline: errors1.PhoneNumber ? "1px solid red" : "",
                        }}
                        buttonStyle={{
                          background: "white",
                          borderLeft: errors1.PhoneNumber
                            ? "2px solid red"
                            : "",
                          borderTop: errors1.PhoneNumber ? "2px solid red" : "",
                          borderBottom: errors1.PhoneNumber
                            ? "2px solid red"
                            : "",
                          borderRight: "none",
                        }}
                        dropdownStyle={{
                          fontSize: "16px",
                        }}
                        placeholder="Employee Phone Number"
                      />
                      {errors1.PhoneNumber && (
                        <div className="helper-text-phone-container">
                          {errors1.PhoneNumber}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="status">
                    <TextField
                      name="comment-PhoneNumber" // Adjusted name
                      value={formData.DocumentStatus.PhoneNumber?.Comment || ""} // Fixed access
                      onChange={(e) => {
                        const newComment = e.target.value;
                        setFormData((prevData) => ({
                          ...prevData,
                          DocumentStatus: {
                            ...prevData.DocumentStatus,
                            PhoneNumber: {
                              Status:
                                prevData.DocumentStatus.PhoneNumber?.Status ||
                                "Pending",
                              Comment: newComment,
                            },
                          },
                        }));
                      }}
                      fullWidth
                      multiline
                      rows={1}
                      variant="outlined"
                      placeholder="Add your comment"
                    />
                  </div>
                  <div className="comment">
                    <div className="status-text-alignment">
                      {formData.DocumentStatus.PhoneNumber?.Status ||
                        "Approved"}
                    </div>
                  </div>
                </div>
                {renderTextFields3()}
                {renderDateFields()}
                {renderDropdownFields()}
                {renderDropdownFields1()}

                {renderNumberFields()}
                {renderTextFields1()}
                {renderNumberFields1()}
                {renderTextFields2()}

                {renderNumberFields2()}

                {renderDocumentFields()}
              </div>
            </div>
            <div className="form-submit-button-of-employee-phase1">
              <div className="form-data-status-display">
                Filled Fields:
                <Avatar className="filled-fields-fontsize">
                  {FilledCounts}
                </Avatar>
              </div>
              <Button
                type="submit"
                variant="contained"
                color="success"
                id="button-zoom"
                onClick={handleSubmit}
              >
                <NearMeIcon className="buttonicons" />
                &nbsp; Submit
              </Button>
            </div>
          </div>
        </div>
      )}
      
        <EmailEnterModal
          handleModalClose={handleModalClose}
          email={email}
          setEmail={setEmail}
          handleSubmit={handleSubmitEmail}
          open={open}
          setOpen={setOpen}
          emailError={emailError}
          setEmailError={setEmailError}
        />
     
    </>
  );
}

export default EmployeePhase1Info;
