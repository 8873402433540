import React, { useState, useEffect, useRef } from "react";
import "./coursetemplate2.css";
import CourseTemplate2OverView from "./CourseTemplate2OverView";
import CourseTemplate2Description from "./CourseTemplate2Description";
import Coursetemplate2Content from "./Coursetemplate2Content";
import CourseTemplate2Instructor from "./CourseTemplate2Instructor";
import CourseTemplate2FAQ from "./CourseTemplate2FAQ";
import UploadBrochure from "./UploadBrochure";
import Coursetemplate2Tags from "./Coursetemplate2Tags";

function Coursetemplate2Tabs({
  isChecked,
  selectedCategories,
  setSelectedCategories,
  files,
  setFiles,
  tags,
  courseTags,
  setCourseTags,
  selectedFAQs,
  setSelectedFAQs,
  selectedInstructors,
  setSelectedInstructors,
  courseContent,
  setCourseContent,
  description,
  setDescription,
  learnersecontent,
  setLearnersContent,
  requirementscontent,
  setRequirementsContent,
  skillscontent,
  setSkillsContent,
  objectivecontent,
  setObjectiveContent,
}) {
  const [activeTab, setActiveTab] = useState("Skills");
  const observer = useRef(null);

  //--------------------------------------------------------------------------------
  // Adjust scroll-margin-top for each section
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const sections = document.querySelectorAll(
      ".coursetemplate2-tab-alignament-cntainer, #Requirements, #Learners, #Description, #Content, #Instructor, #FAQ, #UploadBrochure, #Tags"
    );
    sections.forEach((section) => {
      section.style.scrollMarginTop = "80px"; // Adjust this value based on your sticky navbar height
    });

    // Intersection Observer to change active tab based on scroll
    const options = {
      root: null,
      rootMargin: "-80px 0px 0px 0px", // Adjust this based on your sticky navbar height
      threshold: 0.9, // Trigger when 25% of the section is visible
    };

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    // Cleanup observer on unmount
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  //--------------------------------------------------------------------------------
  // Clikc the tab to open the active one
  //--------------------------------------------------------------------------------

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    document.getElementById(tab).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="coursetemplate2-tabs-container">
      {/* __________________Tabs Sections__________________ */}
      <div className="coursetemplate2-tabs-navbar">
        {[
          "Objective",
          "Skills",
          "Requirements",
          "Learners",
          "Description",
          "Content",
          "Instructor",
          "FAQ",
          "UploadBrochure",
          "Tags",
        ].map((tab) => (
          <div
            key={tab}
            className={`coursetemplate2-each-tabs ${
              activeTab === tab ? "active" : ""
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      {/* __________________Tabs Sections__________________ */}

      {/* __________________What you will learn_________________ */}
      <div id="Objective" className="coursetemplate2-tab-alignament-cntainer">
        <CourseTemplate2OverView
          isChecked={isChecked}
          title="Objective"
          setContent={setObjectiveContent}
          content={objectivecontent}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________What you will learn_________________ */}
      <div id="Skills" className="coursetemplate2-tab-alignament-cntainer">
        <CourseTemplate2OverView
          isChecked={isChecked}
          title="Skills You Will Gain?"
          content={skillscontent}
          setContent={setSkillsContent}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________Needed skills for the course?_________________ */}
      <div id="Requirements">
        <CourseTemplate2OverView
          isChecked={isChecked}
          title="Skills Required for This Course?"
          content={requirementscontent}
          setContent={setRequirementsContent}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________Who can benefit from this course?_________________ */}
      <div id="Learners">
        <CourseTemplate2OverView
          isChecked={isChecked}
          title="Who Should Take This Course?"
          content={learnersecontent}
          setContent={setLearnersContent}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________Description_________________ */}
      <div id="Description">
        <CourseTemplate2Description
          isChecked={isChecked}
          title="Description"
          content={description}
          setContent={setDescription}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________Content_________________ */}
      <div id="Content">
        <Coursetemplate2Content
          isChecked={isChecked}
          title="Course Content"
          courseContent={courseContent}
          setCourseContent={setCourseContent}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________Instructor_________________ */}
      <div id="Instructor">
        <CourseTemplate2Instructor
          isChecked={isChecked}
          title="Instructor"
          selectedInstructors={selectedInstructors}
          setSelectedInstructors={setSelectedInstructors}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________FAQ(s)_________________ */}
      <div id="FAQ">
        <CourseTemplate2FAQ
          isChecked={isChecked}
          title="FAQ"
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedFAQs={selectedFAQs}
          setSelectedFAQs={setSelectedFAQs}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________UploadBrochure_________________ */}
      <div id="UploadBrochure">
        <UploadBrochure
          title="UploadBrochure"
          isChecked={isChecked}
          files={files}
          setFiles={setFiles}
        />
      </div>
      {/* _________________________end__________________________ */}

      {/* __________________Course Tags_________________ */}
      <div id="Tags" className="coursetemplate2-tab-alignament-cntainer">
        <Coursetemplate2Tags
          isChecked={isChecked}
          title="Tags"
          courseTags={courseTags}
          setCourseTags={setCourseTags}
          tags={tags}
        />
      </div>
      {/* _________________________end__________________________ */}
    </div>
  );
}

export default Coursetemplate2Tabs;
