import React, { useState, useEffect } from "react";
import { baseURL } from "../../http";
import TableHeaderTemplate from "../GeneralTemplateComponent/TableHeaderTemplate";
import axios from "axios";
import ContentNameTemplate from "./ContentNameTemplate";
import AddContentName from "./AddContentName";

function ContentNamePage() {
  const [renderColumns, setRenderColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openEditMode, setOpenEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState();
  const [dataBaseUpdate, setDataBaseUpdate] = useState(false);

  // Fetch the data from the database initially
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getcontent`);
        const data = response.data;
        const columnNames = Object.keys(data[0] || {});
        const columns = columnNames.map((columnName) => ({
          field: columnName,
          headerName: columnName,
        }));
        setRenderColumns(columns);
  
        // Flatten nested fields for React compatibility
        const rows = data.map((row, index) => {
          const flattenedRow = { id: index + 1 };
          for (let key in row) {
            flattenedRow[key] = typeof row[key] === 'object' 
              ? JSON.stringify(row[key]) // Convert objects to JSON strings
              : row[key];
          }
          return flattenedRow;
        });
        
        setRenderRows(rows);
        setDataBaseUpdate(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };
  
    fetchTableData();
  }, [dataBaseUpdate]);

  // Handle edit function (triggered from table action icon)
  const handleEdit = (rowdata) => {
    console.log("Edit button clicked for content:", rowdata); 
    setInitialFormData(rowdata); // Set the initial form data to the selected row's data
    setOpenEditMode(true); // Open the edit modal
};

  // Handle 'Manage Content' button click
  const handleAddMoreFunctionality = () => {
    setOpenTemplateModal(true);
  };

  return (
    <div>
      <TableHeaderTemplate
        columns={renderColumns}
        rows={renderRows}
        handleEdit={handleEdit}
        deleteApi="deletecontent"
        is_Manage_Button_Required={true}
        handleManageButtonClick={handleAddMoreFunctionality}
        Manage_button_name="Manage Content"
        dropdownUpdateApi="updatecontent"
        SetDataBaseUpdate={setDataBaseUpdate}
        DefaultColumnNames={["subject", "chapter", "topic", "sub_topic"]}
      />
      <ContentNameTemplate
        openTemplateModal={openTemplateModal}
        setOpenTemplateModal={setOpenTemplateModal}
        SetDataBaseUpdate={setDataBaseUpdate}
        columns={renderColumns}
      />
      <AddContentName
        initialFormData={initialFormData}
        openModal={openEditMode}
        setOpenModal={setOpenEditMode}
        SetDataBaseUpdate={setDataBaseUpdate}
      />
    </div>
  );
}

export default ContentNamePage;
