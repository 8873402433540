import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  Button,
  Tooltip,
  Select,
  MenuItem,
  Avatar,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { baseURL } from "../../http";
import "./ExamPattern.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExamPatternName from "./ExamPatternName";
import { customAlert } from "../SweetAlertCommon/Custom";
import { Update } from "@mui/icons-material";

function ExamPatternAddEditModal({ handleClose1, DBupdate1, editData }) {
  const [patternName, setPatternName] = useState("");
  const [rows, setRows] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [open1, SetOpen1] = useState(false);
  const [availableQuantities, setAvailableQuantities] = useState({});
  const prevRowsRef = useRef([]);
  //--------------------------------------------------------------------------------
  //Used to Render the dat back
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData) {
      setPatternName(editData.PatternName);
      if (editData.PatternArray) {
        const patternArray = JSON.parse(editData.PatternArray);
        if (Array.isArray(patternArray)) {
          setRows(patternArray); // Set rows to the parsed array
        }
      }
    }
  }, [editData]);
  //--------------------------------------------------------------------------------
  //Used to open the PatternName modal
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (editData.length === 0) {
      SetOpen1(true);
    } else {
      SetOpen1(false);
    }
  }, [editData]);

  console.log(editData.length);
  //--------------------------------------------------------------------------------
  // useEffect triggers when rows change and fetch the questions
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          baseURL + "/getAllAiGeneratedQuestions"
        );
        const { data } = response;
        const updatedQuantities = {};
        rows.forEach((row) => {
          let easyCount = 0;
          let mediumCount = 0;
          let hardCount = 0;
          let filteredData = data.filter(
            (item) => item.QuestionType === row.QuestionType
          );
          specificData.forEach((field) => {
            if (row[field]) {
              filteredData = filteredData.filter(
                (item) => item[field] === row[field]
              );
            }
          });
          easyCount += filteredData.filter(
            (item) => item.DifficultyLevels === "Easy"
          ).length;
          mediumCount += filteredData.filter(
            (item) => item.DifficultyLevels === "Medium"
          ).length;
          hardCount += filteredData.filter(
            (item) => item.DifficultyLevels === "Hard"
          ).length;
          updatedQuantities[row.id] = {
            Easy: easyCount,
            Medium: mediumCount,
            Hard: hardCount,
          };
        });
        setAvailableQuantities(updatedQuantities);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [rows]);
  //--------------------------------------------------------------------------------
  // Track QuestionType changes and reset Easy, Medium, Hard when it changes
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const resetDifficultyOnQuestionTypeChange = () => {
      const updatedRows = rows.map((row, index) => {
        const previousRow = prevRows[index];

        // If QuestionType has changed, reset Easy, Medium, and Hard to 0
        if (previousRow?.QuestionType !== row.QuestionType) {
          return {
            ...row,
            Easy: 0,
            Medium: 0,
            Hard: 0,
          };
        }
        return row;
      });
      setRows(updatedRows);
    };
    const prevRows = prevRowsRef.current;
    prevRowsRef.current = rows; // Keep the rows in sync with latest state
    if (
      rows.length &&
      prevRows.length &&
      rows.some(
        (row, index) => row.QuestionType !== prevRows[index]?.QuestionType
      )
    ) {
      resetDifficultyOnQuestionTypeChange();
    }
  }, [rows]);

  //--------------------------------------------------------------------------------
  // Open and close the Pattern Maodal
  //--------------------------------------------------------------------------------
  const handleOpen = () => {
    SetOpen1(true);
  };
  const handleClose = () => {
    SetOpen1(false);
  };
  //--------------------------------------------------------------------------------
  // Fileds of the Rows
  //--------------------------------------------------------------------------------
  const specificData = [
    "QuestionType",
    "Syllabus",
    "Education",
    "Branch/FieldOfStudy",
    "Class/Sem",
    "Subject",
    "ChapterName",
    "TopicName",
  ];
  //--------------------------------------------------------------------------------
  // Types of the questionType
  //--------------------------------------------------------------------------------
  const questionTypeOptions = [
    "MCQs",
    "Question and Answer",
    "Fill in the Blanks",
    "True/False",
  ];
  //--------------------------------------------------------------------------------
  // Fetch dropdown options from the server
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseURL + "/dropdownOptions");
        const optionsData = response.data;
        const uniqueOptions = {};
        specificData.forEach((field) => {
          uniqueOptions[field] = [
            ...new Set(optionsData.map((row) => row[field])),
          ];
        });
        setDropdownOptions(uniqueOptions);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  //--------------------------------------------------------------------------------
  // Handle the row change in the dropdownselection
  //--------------------------------------------------------------------------------
  const handleRowChange = (id, key, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [key]: value } : row))
    );
  };
  //--------------------------------------------------------------------------------
  // Add a new row to the table
  //--------------------------------------------------------------------------------
  const handleAddRow = () => {
    const newRowId =
      rows.length > 0 ? Math.max(...rows.map((row) => row.id)) + 1 : 1;
    const newRow = {
      id: newRowId,
      QuestionType: "",
      Syllabus: "",
      Education: "",
      "Branch/FieldOfStudy": "",
      "Class/Sem": "",
      Subject: "",
      ChapterName: "",
      TopicName: "",
      Easy: 0,
      Medium: 0,
      Hard: 0,
    };
    setRows((prevRows) => [...prevRows, newRow]);
  };
  //--------------------------------------------------------------------------------
  // Calculate the Total question selection in the Pattern
  //--------------------------------------------------------------------------------
  const calculateTotalQuestions = () => {
    if (!Array.isArray(rows)) return 0; // Safety check
    return rows.reduce((total, { Easy = 0, Medium = 0, Hard = 0 }) => {
      return total + parseInt(Easy) + parseInt(Medium) + parseInt(Hard);
    }, 0);
  };
  const totalQuestions = calculateTotalQuestions();
  //--------------------------------------------------------------------------------
  // Delete a row from the table
  //--------------------------------------------------------------------------------
  const handleDeleteRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };
  //--------------------------------------------------------------------------------
  // function to return the table rows
  //--------------------------------------------------------------------------------
  const renderSelect = (row, fieldName, options, handleChange) => {
    if (!options || !Array.isArray(options)) {
      console.warn("Options is undefined or not an array");
      return null; // Return null or a fallback UI if options are invalid
    }
    return (
      <Select
        value={row[fieldName]}
        onChange={(e) => handleChange(row.id, fieldName, e.target.value)}
        className="drop-down-width-contol-exam-pattern-addedit"
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };
  //--------------------------------------------------------------------------------
  // Save rows data (placeholder for actual implementation)
  //--------------------------------------------------------------------------------
  const handleSave = async () => {
    if (totalQuestions === 0) {
      customAlert(
        "",
        "Please add some questions to create the test pattern.",
        "warning"
      );
      return;
    }
    const data = {
      patternName: patternName,
      totalQuestions: totalQuestions,
      patternArray: rows,
    };
    try {
      if (editData) {
        // Update existing pattern (PUT request)
        const response = await axios.put(
          `${baseURL}/updatepattern/${editData.id}`,
          data
        );
        customAlert("", "Test pattern updated successfully!", "success");
      } else {
        // Create a new pattern (POST request)
        const response = await axios.post(`${baseURL}/createpattern`, data);
        customAlert("", "Test pattern created successfully!", "success");
      }
      DBupdate1(true); // Trigger update in the database
      handleClose1(); // Close modal or reset form
    } catch (error) {
      if (error.response && error.response.status === 400) {
        customAlert("", "Pattern name already exists", "warning");
      } else if (error.response && error.response.status === 401) {
        customAlert("", "Duplicate pattern row found", "warning");
      } else {
        customAlert(
          "",
          "Something went wrong. Please check database connectivity.",
          "error"
        );
      }
    }
  };

  return (
    <div className="exam-pattern-add-edit-modal">
      <div className="exam-pattern-add-edit-modal-header">
        {/* ______________________Header section_____________ */}
        <div className="arror-and-pattern-name-alignment">
          <Tooltip title="Back" arrow>
            <IconButton onClick={handleClose1} id="button-zoom">
              <ArrowBackIcon className="arrow-color-back-button" />
            </IconButton>
          </Tooltip>
          &nbsp;
          <div className="patter-name-alignemnt-and-overflw-control">
           <div className="patternanme-heading">PatternName:</div> 
            <div className="exam-pattern-name-span">&nbsp;{patternName}</div>
            <Tooltip title="Add/Edit Pattern Name" arrow>
              <IconButton onClick={handleOpen}>
                <EditIcon className="exam-patter-add-edit-Editicon-size-and-color" />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="add-button-alignment-exampattern-header">
          <Tooltip title="Add Pattern Categories(Rows)" arrow>
            <IconButton
              onClick={handleAddRow}
              className="exam-pattern-add-edit-modal-add-iconbutton"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>

        <div className="exampatternnewpattern5">
          <Avatar className="exan-edit-add-avatar-background-color">
            {calculateTotalQuestions()}
          </Avatar>
         
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            startIcon={editData ? <Update /> : <SaveIcon />}
            id="button-zoom"
          >
            {editData ? "Update" : "Save"}
          </Button>
        </div>
      </div>

      <div className="table-container-exam-pattern-create">
        <div className="table-header-row-in-create-pattern">
          <table>
            <thead>
              {/* __________table header__________________ */}
              <tr>
                {[...specificData, "Easy", "Medium", "Hard", "Actions"].map(
                  (header) => (
                    <td key={header} className="table-header-cell">
                      {header}
                    </td>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {/* _______________fileds function render____________________ */}
              {rows.map((row) => (
                <tr>
                  <td className="table-cell table-cell-question-type">
                    {renderSelect(
                      row,
                      "QuestionType",
                      questionTypeOptions,
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-syllabus">
                    {renderSelect(
                      row,
                      "Syllabus",
                      dropdownOptions.Syllabus,
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-education">
                    {renderSelect(
                      row,
                      "Education",
                      dropdownOptions.Education,
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-branch-field">
                    {renderSelect(
                      row,
                      "Branch/FieldOfStudy",
                      dropdownOptions["Branch/FieldOfStudy"],
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-class-sem">
                    {renderSelect(
                      row,
                      "Class/Sem",
                      dropdownOptions["Class/Sem"],
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-subject">
                    {renderSelect(
                      row,
                      "Subject",
                      dropdownOptions.Subject,
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-chapter-name">
                    {renderSelect(
                      row,
                      "ChapterName",
                      dropdownOptions.ChapterName,
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-topic-name">
                    {renderSelect(
                      row,
                      "TopicName",
                      dropdownOptions.TopicName,
                      handleRowChange
                    )}
                  </td>
                  <td className="table-cell table-cell-easy">
                    <TextField
                      type="number"
                      value={row.Easy}
                      onChange={(e) =>
                        handleRowChange(row.id, "Easy", e.target.value)
                      }
                      className="drop-down-width-contol-exam-pattern-easy-medium-hard"
                      inputProps={{
                        min: 0,
                        max: availableQuantities[row.id]?.Easy || 0,
                      }}
                    />
                    <span className="available-qty-showing">
                      Aval.Qty: {availableQuantities[row.id]?.Easy || 0}
                    </span>{" "}
                  </td>
                  <td className="table-cell table-cell-medium">
                    <TextField
                      type="number"
                      value={row.Medium}
                      onChange={(e) =>
                        handleRowChange(row.id, "Medium", e.target.value)
                      }
                      className="drop-down-width-contol-exam-pattern-easy-medium-hard"
                      inputProps={{
                        min: 0,
                        max: availableQuantities[row.id]?.Medium || 0,
                      }} // Set min and max using inputProps
                    />
                    <span className="available-qty-showing">
                      Aval.Qty: {availableQuantities[row.id]?.Medium || 0}
                    </span>{" "}
                  </td>
                  <td className="table-cell table-cell-hard">
                    <TextField
                      type="number"
                      value={row.Hard}
                      onChange={(e) =>
                        handleRowChange(row.id, "Hard", e.target.value)
                      }
                      className="drop-down-width-contol-exam-pattern-easy-medium-hard"
                      inputProps={{
                        min: 0,
                        max: availableQuantities[row.id]?.Hard || 0,
                      }} // Set min and max using inputProps
                    />
                    <span className="available-qty-showing">
                      Aval.Qty: {availableQuantities[row.id]?.Hard || 0}
                    </span>{" "}
                  </td>
                  <td className="table-cell table-cell-actions">
                    <Tooltip title="Delete-Row" arrow>
                      <IconButton
                        className="deleteicon-alignement"
                        onClick={() => handleDeleteRow(row.id)}
                      >
                        <DeleteIcon color="error" className="buttonicons" />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* _______________________Button section____________________________ */}
      <div className="exam-pattern-add-edit-modal-save-icon-alignment"></div>
      {/* ______________PatternName Modal___________ */}
      <ExamPatternName
        open={open1}
        handleClose={handleClose}
        patternName={patternName}
        setPatternName={setPatternName}
        editData={editData}
      />
    </div>
  );
}
export default ExamPatternAddEditModal;
