import React, { useEffect, useState } from "react";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL } from "../../http";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { customAlert } from "../SweetAlertCommon/Custom";
import EditUser from "./EditUser";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Chip from "@mui/material/Chip";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import "./usermanagement.css";
import KeyIcon from "@mui/icons-material/Key";
import GrantAccess2 from "./GrantAccess2";

const UserManagement = ({
  loginData,
  setLoginData,
  setFilteredData,
  deleteSelected,
  selectedRows,
  setSelectedRows,
  dropdownOptions
}) => {
  const [loading, setLoading] = useState(false);
  const [editUserModalStatus, setEditUserModalStatus] = useState(false);
  const [GiveAccesModalStatus, setGiveAccesModalStatus] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editItemID, setEditItemID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null); // New state variable
  const [editData, setEditData] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    usertype: "",
    isBlocked: "",
    last_Login_time: "",
    last_logout_time: "",
    last_logout_Status: "",
    is_LocalData_Login: "",
    last_login_device_name: "",
    number_of_device_login: "",
    user_id: "",
    UTC_Time: "",
    IP_Addres: "",
    Latitude: "",
    Longitude: "",
    PinCode_Login: "",
    Current_Location: "",
  });



  //___________________Open and close the Menu Items____________//

  const handleClick = (event, id) => {
    // Update to include id parameter
    setSelectedRowId(id); // Set the id of the row for which the menu is open
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //________________________________end__________________________//

  //________________Close the Edit user Modal(send as a prob)___________//

  const handleCloseEditModal = () => {
    setEditUserModalStatus(false);
  };

  //________________________________end_________________________________//

  //__________________________Edit the User model open with data___________//

  const handleEdit = (id) => {
    const item = loginData.find((user) => user.id === id);
    if (item) {
      setEditUserModalStatus(true);
      setEditItemID(id);
      setEditData({
        name: item.name,
        email: item.email,
        mobile: item.mobile,
        password: item.password,
        usertype: item.usertype,
        isBlocked: item.isBlocked,
        last_Login_time: item.last_Login_time,
        last_logout_time: item.last_logout_time,
        last_logout_Status: item.last_logout_Status,
        is_LocalData_Login: item.is_LocalData_Login,
        last_login_device_name: item.last_login_device_name,
        number_of_device_login: item.number_of_device_login,
        user_id: item.user_id,
        UTC_Time: item.UTC_Time,
        IP_Addres: item.IP_Addres,
        Latitude: item.Latitude,
        Longitude: item.Longitude,
        PinCode_Login: item.PinCode_Login,
        Current_Location: item.Current_Location,
      });
    }
  };

  //________________________________End_________________________________//

  //___________Handle the isBlocked Column in the DataGrid_______________//

  const handleBlock = (id) => {
    // Handle delete functionality
    console.log("Block row with id:", id);

    axios
      .post(`${baseURL}/blockUserByAdmin/${id}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status === 200) {
          customAlert("", response.data.message, "success");
          fetchData();
        } else {
          customAlert("", "Error", "Failed to update item", "error");
        }
      })
      .catch((error) => {
        customAlert("", "Server Down", "Error");
      });
  };

  //_____________________________End____________________________________//

  //_____________________Delete the User from the database______________//

  const handleDelete = (id = null) => {
    setLoading(true); // Set loading to true when deletion process starts

    if (id) {
      // Handle delete functionality for a single row
      console.log("Delete row with id:", id);
      const updatedList = loginData.filter((item) => item.id !== id);
      setLoginData(updatedList);

      axios
        .delete(`${baseURL}/deleteUser/${id}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            customAlert("", "Deleted successfully", "success");
            fetchData();
            handleClose();
          } else {
            customAlert("", "Failed to delete item", "Error");
          }
        })
        .catch((error) => {
          customAlert("", "Server Down", "Error");
        })
        .finally(() => {
          setLoading(false); // Set loading to false after deletion process completes
        });
    } else {
      if (!selectedRows || selectedRows.length === 0) {
        // Check if selectedRows is empty
        setLoading(false); // Set loading to false
        return;
      }
      // Handle delete functionality for multiple selected rows
      let deletionCount = 0; // Track the number of deletions
      selectedRows.forEach((selectedId, index, array) => {
        console.log("Delete row with id:", selectedId);
        const updatedList = loginData.filter((item) => item.id !== selectedId);
        setLoginData(updatedList);

        axios
          .delete(`${baseURL}/deleteUser/${selectedId}`, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              deletionCount++; // Increment deletion count
              if (deletionCount === array.length) {
                // If all deletions are completed, trigger alert
                customAlert(
                  "",
                  "Deleted Selected Users successfully",
                  "success"
                );
                fetchData();
                setLoading(false); // Set loading to false after deletion process completes
              }
            } else {
              customAlert("", "Failed to delete item", "Error");
            }
          })
          .catch((error) => {
            customAlert("", "Server Down", "Error");
          });
      });

      // Clear the selectedRows state after deletion
      setSelectedRows([]);
    }
  };

  //_____________________________End____________________________//
  //________________Trigger the Function based on the Selected delete________//

  useEffect(() => {
    handleDelete();
  }, [deleteSelected]);

  //____________________________end__________________________________//

  //________________________Fetch the data from the Database_____________//

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}/getLoginTable`);
      setLoginData(response.data);
      setFilteredData(response.data);
      setEditUser(false);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching login data:", error);
    }
  };

  //_______________________End_____________________________________//

  //________________________Initial render the data from the databse____________//

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser]);

  //___________________________end___________________________//

  //______________Checlbox selected row ids___________________//

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        // If checkbox is checked, add the ID to the selectedRows state
        return [...prevSelectedRows, id];
      } else {
        // If checkbox is unchecked, remove the ID from the selectedRows state
        return prevSelectedRows.filter((rowId) => rowId !== id);
      }
    });
  };
  //___________________________________end____________________________________//

  //_______________________handle open the Give the access model open________//

  const handleOpenGiveAccess = () => {
    setGiveAccesModalStatus(true);
  };

  //________________________________end_______________________________________//

  return (
    <>
      {/* _________________user Management table ___________ */}

      <div className="outer-wraper">
        <div className="table-wraper">
          {loginData.length === 0 ? (
            <div className="Data-not-avilable-showing-container">
              No User available
            </div>
          ) : (
            <table>
              <thead>
                <th className="tableheaderborderradius">UserInfo</th>
                <th className="tableisblocked">IsBlocked</th>
                <th className="tabledataeofjining">Date of Joining</th>
                <th className="tableheaderborderradius">Actions</th>
              </thead>
              <tbody>
                {loginData.map((row) => (
                  <tr
                    key={row.id}
                    className={
                      selectedRowId === row.id && Boolean(anchorEl)
                        ? "tr-hover-active"
                        : ""
                    }
                  >
                    <td>
                      <div className="userinfoalignment">
                        <div className="checkboxmargin">
                          <input
                            type="checkbox"
                            className="usermanagemnettablecheckbox"
                            onChange={(e) => handleCheckboxChange(e, row.id)}
                            checked={(selectedRows ?? []).includes(row.id)} // Check if the current row ID is in the selectedRows state
                          />
                        </div>
                        <div className="userinformationanamephonealignment">
                          <Tooltip title={row.email} arrow>
                          <Avatar className="usermanagementavatar">
                            {row.name ? row.name.charAt(0).toUpperCase() : ""}
                          </Avatar>
                          </Tooltip>
                          &nbsp;&nbsp;
                          <div>
                            <div className="fontcolorbold">{row.name}</div>
                            <div className="fontcolor">
                              {row.mobile || "Not Available"}
                            </div>
                          </div>
                          &nbsp;
                          <Chip
                            label={row.usertype}
                            className="chipsizealignment"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="alignitemscenter">
                      <Switch
                        checked={row.isBlocked === 1 ? true : false} // Check if isBlocked is '1' (true) or '0' (false)
                        color="primary"
                        onClick={() => handleBlock(row.id)} // Handle click to toggle isBlocked
                        style={{ color: row.isBlocked ? "red" : "green" }} // Change color based on isBlocked
                      />
                    </td>
                    <td>
                      <div className="dateofjoiningalignment">
                        {row.created_at.substring(0, 10)}
                      </div>
                    </td>
                    <td>
                      <div className="tablemoreiconaligncenter">
                        <IconButton
                          className={`usermanagementtemplateiconfiterside ${
                            selectedRowId === row.id && Boolean(anchorEl)
                              ? "active"
                              : ""
                          }`}
                          onClick={(event) => handleClick(event, row.id)}
                        >
                          <MoreVertIcon  className="actionbuttoniconcolor" id="button-zoom" />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedRowId === row.id}
                          onClose={handleClose}
                          className="alignthemenumodal"
                        >
                          <MenuItem onClick={() => handleEdit(selectedRowId)}>
                            <EditIcon
                              className="buttonicons"
                              id="batchcardediticon"
                            />
                            &nbsp;&nbsp;
                            <span className="menuitemscolor">Edit</span>
                          </MenuItem>
                          {loginData.find((user) => user.id === selectedRowId)
                            ?.usertype === "Student" && (
                            <MenuItem>
                              <WorkspacePremiumIcon
                                className="buttonicons"
                                id="batchcardediticon"
                              />
                              &nbsp;&nbsp;
                              <span className="menuitemscolor">
                                Give Certificate
                              </span>
                            </MenuItem>
                          )}

                          {loginData.find((user) => user.id === selectedRowId)
                            ?.usertype !== "Admin" && (
                            <MenuItem onClick={() => handleOpenGiveAccess()}>
                              <KeyIcon
                                className="buttonicons"
                                id="batchcardediticon"
                              />
                              &nbsp;&nbsp;
                              <span className="menuitemscolor">
                                Grant Access
                              </span>
                            </MenuItem>
                          )}
                          <MenuItem onClick={() => handleDelete(selectedRowId)}>
                            <DeleteIcon
                              className="buttonicons"
                              id="batchcarddeleteicon"
                            />
                            &nbsp;&nbsp;
                            <span className="menuitemscolor">Delete</span>
                          </MenuItem>

                          
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* _______________________end____________________________ */}

      {/*___________________ Edit User__________________ */}

      <EditUser
        editItemID={editItemID}
        editUserModalStatus={editUserModalStatus}
        handleCloseEditModal={handleCloseEditModal}
        editData={editData}
        setEditData={setEditData}
        usertypeOptions={dropdownOptions}
        setEditUser={setEditUser}
      />

      {/* _______________________end____________________________ */}

      {/* _______________________________Giv the access component call_________ */}

      <GrantAccess2
        selectedRowId={selectedRowId}
        GiveAccesModalStatus={GiveAccesModalStatus}
        close={setGiveAccesModalStatus}
        loginData={loginData}
      />

      {/* _______________________________end_____________________________________*/}
    </>
  );
};

export default UserManagement;
