import React, { useState } from "react";
import StudentProfilePage from "./Pm_StudentProfilePage";
import './1ProfileManagement.css';
import PmScrollspy from "./NewScrollspy";

function ProfileManagement() {

  const [PercentageUpdate, setPercentageUpdate] = useState(false);

  //--------------------------------------------------------------------------------
  // Fetching the Profile Percentage when changes in each tabs
  //--------------------------------------------------------------------------------
  const callPercentage = () => {
    setPercentageUpdate((prev) => !prev);
  }
  
  return (
    <div  className="proflieContainer">
      <StudentProfilePage 
      PercentageUpdate={PercentageUpdate}
      />
      <PmScrollspy 
      callPercentage={callPercentage}
      />
    </div>
  );
}

export default ProfileManagement;
