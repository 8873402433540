import React, { useState, useEffect, useRef } from "react";
import AdminHeader from "./AdminHeader";
import navItems from "../DashboardAdmin/navBarItems";
import axios from "axios";
import { baseURL } from "../../http";
import { Tooltip } from "@mui/material";
import "./Dashboard.css";

function AdminSideNav() {
  const [isActive, setIsActive] = useState({});
  const [userType, setUserType] = useState("");
  const [userData, setUserData] = useState();
  const [showIconsOnly, setShowIconsOnly] = useState(false);
  const [heading, setHeading] = useState(localStorage.getItem('SubItemHeading')||localStorage.getItem("Heading"));
  const [hoveredItem, setHoveredItem] = useState(null);
  const [subMenuPosition, setSubMenuPosition] = useState({ x: 0, y: 0 });
  const dropdownRef = useRef(null);

  //--------------------------------------------------------------------------------
  // Set active tab to render from localStorage
  //--------------------------------------------------------------------------------
  const [activeTab, setActiveTab] = useState(() => {
    const storedTab = localStorage.getItem("activeTab");
    return storedTab ? parseInt(storedTab, 10) : (navItems.length > 0 ? navItems[0].id : null);
  });

  //--------------------------------------------------------------------------------
  // Set active subtab to render from localStorage
  //--------------------------------------------------------------------------------
  const [activeSubItem, setActiveSubItem] = useState(() => {
    const storedSubItem = localStorage.getItem("activeSubItem");
    return storedSubItem ? parseFloat(storedSubItem) : null;
  });

  //--------------------------------------------------------------------------------
  // Handle the main tabs click
  //--------------------------------------------------------------------------------
  const handleTabClick = (tabId, tabTitle) => {
    setActiveTab(tabId);
    setActiveSubItem(null);
    setHeading(tabTitle);
    localStorage.setItem("activeTab", tabId);
    localStorage.setItem("Heading", tabTitle);
    localStorage.removeItem("activeSubItem");
    localStorage.removeItem("SubItemHeading");
  };

  //--------------------------------------------------------------------------------
  // Handle the subtabs tabs click
  //--------------------------------------------------------------------------------
  const handleSubItemClick = (tabId, subItemId, subItemTitle) => {
    setActiveTab(tabId);
    setActiveSubItem(subItemId);
    setHeading(subItemTitle);
    localStorage.setItem("activeTab", tabId);
    localStorage.setItem("activeSubItem", subItemId);
    localStorage.setItem("SubItemHeading", subItemTitle);
  };

  //--------------------------------------------------------------------------------
  // Render the usertable data
  //--------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/getLoginTable`);
        const userData = response.data.find(
          (user) => user.email === localStorage.getItem("HTES_user_id"),
          

        );
        if (userData) {
          localStorage.setItem("readOnlyTabs", userData.readonlytabs);
          setUserType(userData.usertype);
          setUserData(userData);
        }
      } catch (error) {
        console.error("Error fetching login data:", error);
      }
    };

    fetchData();
  }, []);

  //--------------------------------------------------------------------------------
  // Function to remove the String
  //--------------------------------------------------------------------------------
  const parseArrayFromString = (str) => {
    try {
      // Remove the first and last double quotes if they exist
      const cleanedStr = str.replace(/^"|"$/g, '');
      // Parse the cleaned string into a JSON array
      const parsedArray = JSON.parse(cleanedStr);
      return Array.isArray(parsedArray) ? parsedArray : [];
    } catch (error) {
      console.error("Failed to parse array:", error);
      return [];
    }
  };
  
  //--------------------------------------------------------------------------------
  // Filter the tabs based on the usertype and Tabids
  //--------------------------------------------------------------------------------

  const userDataTabIds = userData ? parseArrayFromString(userData.tabids) : [];


  const createIdMap = (tabHierarchy) => {
    return tabHierarchy.reduce((map, group) => {
      const [mainId, ...subIds] = group;
      map[mainId] = new Set(subIds);
      return map;
    }, {});
  };

  const idMap = createIdMap(userDataTabIds);

  const filteredNavItems = navItems.map((tab) => {
    if (userType === "Admin") {
      // If user is Admin, include all tabs and subtabs
      return tab;
    } else {
      const hasTabId = idMap[tab.id];
      const isMainIdValid = userDataTabIds.some(group => group[0] === tab.id);
      const isSubIdValid = hasTabId && Array.from(hasTabId).some(subId => 
        userDataTabIds.some(group => group.includes(subId))
      );
  
      // Determine if this tab or its subtabs should be included
      if (isMainIdValid || isSubIdValid) {
        // Filter and include subtabs if they are valid
        const filteredSubItems = tab.subItems
          ? tab.subItems.filter(subItem =>
              userDataTabIds.some(group => group.includes(subItem.id))
            )
          : [];
          
        return {
          ...tab,
          subItems: filteredSubItems
        };
      } else {
        // Exclude tab if neither the main ID nor any sub IDs are valid
        return null;
      }
    }
  }).filter(Boolean); // Remove null values
  

  //--------------------------------------------------------------------------------
  // Toggle the icon show only when click the close nav
  //--------------------------------------------------------------------------------
  const toggleIconsOnly = () => {
    setShowIconsOnly((prevState) => !prevState);
  };

  //--------------------------------------------------------------------------------
  // Mouse enter open the side subitems menu
  //--------------------------------------------------------------------------------
  const handleMouseEnter = (event, tabId) => {
    const tabItem = filteredNavItems.find((tab) => tab.id === tabId);
    if (!tabItem || !tabItem.subItems || tabItem.subItems.length === 0) {
      setHoveredItem(null);
      return;
    }

    const { target } = event;
    const { right, top } = target.getBoundingClientRect();
    setSubMenuPosition({ x: right, y: top });
    setHoveredItem(tabId);
  };

  //--------------------------------------------------------------------------------
  // Mouse leave close the side subitems menu
  //--------------------------------------------------------------------------------
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <>
      {/*________________Call the Admin Header component_____________________ */}
      <AdminHeader
        showIconsOnly={showIconsOnly}
        userType={userType}
        toggleIconsOnly={toggleIconsOnly}
        filteredNavItems={filteredNavItems}
        handleTabClick={handleTabClick}
        handleSubItemClick={handleSubItemClick}
        heading={heading}
        isActive={isActive}
        activeSubItem={activeSubItem}
        activeTab={activeTab}
      />
      {/* _______________________end__________________________________*/}
      <div className="vertical-tabs-container">
        {/* ___________________Navigation Bar Container___________________ */}
        <div
          className={`navigation-wrapper ${showIconsOnly ? "show-icons-only" : ""}`}
        >
          <div className="tab-navigation" ref={dropdownRef}>
            {filteredNavItems.map((tab) => (
              <div
                key={tab.id}
                className={`hovercontainer ${tab.id === activeTab ? "active" : ""}`}
                onMouseEnter={(event) => handleMouseEnter(event, tab.id)}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className="align-icon-and-text-center"
                  onClick={() => !tab.subItems && handleTabClick(tab.id, tab.title)}
                >
                  <span className="fontcolorandsize">
                    {!showIconsOnly ? (
                      <>
                        {tab.icon}
                        {tab.title}
                      </>
                    ) : (
                      <Tooltip title={tab.title} arrow>
                        <span
                          className={`icons-background-color ${
                            showIconsOnly && tab.id === activeTab
                              ? "icons-background-color-when-closed-nav"
                              : ""
                          }`}
                        >
                          {tab.icon}
                        </span>
                      </Tooltip>
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {hoveredItem && (
          <div
            className="sub-menu"
            style={{
              top: `${subMenuPosition.y}px`,
              left: `${subMenuPosition.x}px`,
            }}
            onMouseEnter={() => setHoveredItem(hoveredItem)}
            onMouseLeave={handleMouseLeave}
          >
            {filteredNavItems
              .find((tab) => tab.id === hoveredItem)
              ?.subItems?.map((subItem) => (
                <div
                  className="subitemsblock"
                  key={subItem.id}
                  onClick={() =>
                    handleSubItemClick(hoveredItem, subItem.id, subItem.subtitle)
                  }
                >
                  <span
                    className={`subitemsblocknames ${subItem.id === activeSubItem ? "active" : ""}`}
                  >
                   
                    {subItem.subtitle}
                  </span>
                </div>
              ))}
          </div>
        )}
        {/* ___________________________________end______________________________________ */}
        {/* _____________________________Tab content fetch container (right side)_______________ */}
        <div className="tab-content">
          {filteredNavItems.map((tab) => (
            <div key={tab.id} className={`tab-pane ${tab.id === activeTab ? "active" : ""}`}>
              {tab.id === activeTab && !activeSubItem && tab.content}
              {tab.subItems &&
                tab.subItems.map((subItem) => (
                  <div key={subItem.id} className="subitem-content">
                    {subItem.id === activeSubItem && subItem.content}
                  </div>
                ))}
            </div>
          ))}
        </div>
        {/* __________________________end______________________________________ */}
      </div>
    </>
  );
}

export default AdminSideNav;
