import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Autocomplete,
  TextField,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { baseURL } from "../../../http";
import InstructorCardTemplate from "../../InstructorManagement/InstructorCardTemplate";
import "./coursecreatetemplate.css";

function InstructorTab({ selectedInstructors, setSelectedInstructors }) {
  const [instructors, setInstructors] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const autocompleteRef = useRef();

  //--------------------------------------------------------------------------------
  // Fetch all instructor data
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await axios.get(`${baseURL}/Instructor_fetch_all`);
        setInstructors(response.data);
      } catch (error) {
        console.error("Error fetching instructors:", error);
      }
    };

    fetchInstructors();
  }, []);

  //--------------------------------------------------------------------------------
  // Handle selecting an instructor
  //--------------------------------------------------------------------------------

  const handleAddInstructor = (event, newValue) => {
    if (newValue && !selectedInstructors.includes(newValue)) {
      setSelectedInstructors([...selectedInstructors, newValue]);
    }
    setIsAdding(false); // Change button text to "Add Instructor" after selection
  };

  //--------------------------------------------------------------------------------
  // Handle deleting an instructor
  //--------------------------------------------------------------------------------

  const handleDeleteInstructor = (email) => {
    setSelectedInstructors(
      selectedInstructors.filter((inst) => inst.instructorEmail !== email)
    );
  };

  //--------------------------------------------------------------------------------
  // Toggle the Autocomplete input
  //--------------------------------------------------------------------------------

  const toggleAutocomplete = () => {
    setIsAdding(!isAdding);
  };

  //--------------------------------------------------------------------------------
  // Filter out instructors that have already been selected
  //--------------------------------------------------------------------------------

  const filteredInstructors = instructors.filter(
    (instructor) =>
      !selectedInstructors.some(
        (selected) => selected.instructorEmail === instructor.instructorEmail
      )
  );

  return (
    <>
      <div className="Header-tab-course-name">
        <div className="instructortab-flex-container">
          <div className="autocomplete-container-width">
            <div className="overview-header-of-the-container-color">
              {selectedInstructors.length === 0 ? "Add Instructor" : ""}
            </div>

            {isAdding ? (
              <Autocomplete
                disableClearable
                options={filteredInstructors} // Use filtered instructors
                getOptionLabel={(option) => option.instructorEmail}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Instructor Email"
                    fullWidth
                  />
                )}
                onChange={handleAddInstructor}
                ref={autocompleteRef}
              />
            ) : (
              selectedInstructors.map((instructor) => (
                <Chip
                  className="chip-background-color"
                  key={instructor.instructorEmail}
                  label={instructor.instructorEmail}
                  onDelete={() =>
                    handleDeleteInstructor(instructor.instructorEmail)
                  }
                  style={{ margin: "5px" }}
                />
              ))
            )}
          </div>

          <div>
            {!isAdding && (
              <Tooltip title="Add Instructor" arrow>
                <IconButton onClick={toggleAutocomplete}>
                  <AddIcon className="buttoncolorEdit" variant="contained">
                    {isAdding ? "" : "Add Instructor"}
                  </AddIcon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {selectedInstructors.length !== 0 &&
        selectedInstructors.map((instructor, index) => (
          <InstructorCardTemplate key={index} instructorData={instructor} />
        ))}
    </>
  );
}

export default InstructorTab;
