import React, { useEffect } from "react";
import { Chip } from "@mui/material";

function ResultCard({ filteredQuestions, ResultData, selectedQuestionIndex }) {
  //--------------------------------------------------------------------------------
  //useeffect to change the active card
  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (selectedQuestionIndex !== null) {
      const element = document.getElementById(
        `question-card-${selectedQuestionIndex}`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" }); // Scroll to the selected question card
      }
    }
  }, [selectedQuestionIndex]);

  //--------------------------------------------------------------------------------
  // Render nothing if ExamStatus is not available
  //--------------------------------------------------------------------------------
  if (!ResultData?.ExamStatus) {
    return null;
  }

  const examStatus =
    typeof ResultData.ExamStatus === "string"
      ? JSON.parse(ResultData.ExamStatus)
      : ResultData.ExamStatus;

  const firstExamStatus = Array.isArray(examStatus) ? examStatus[0] : null;

  if (!firstExamStatus) {
    return null; // Return nothing if the array is empty or the first element is undefined
  }

  const { SeletedOptions, resultArray } = firstExamStatus || {};

  return (
    <div className="accordion-container-add-totest-result-card">
      {filteredQuestions &&
        filteredQuestions.map((question, index) => (
          <div
            id={`question-card-${index}`}
            key={index}
            className={`all-component-render-page-outer-container-result-card ${
              selectedQuestionIndex === index ? "highlighted" : ""
            }`}
          >
            <div className="accordion-summary-of-the-question-display-result-card">
              Q{index + 1}.&nbsp;
              <div>{question.Questions}</div>
            </div>

            <div>
              <div>
                <div className="display-options-and-answer-result-card">
                  {!question.OptionA &&
                    !question.OptionB &&
                    !question.OptionC &&
                    !question.OptionD && (
                      <span className="color-change-for-the-answer-result-card">
                        <span>Answer:</span> {question.Answer}
                      </span>
                    )}

                  {["OptionA", "OptionB", "OptionC", "OptionD"].map(
                    (option, i) => {
                      const optionText = question[option];
                      if (!optionText) return null;
                      const isCorrect = question.Answer === optionText;
                      const isSelected = SeletedOptions.includes(optionText);

                      return (
                        <div
                          key={option}
                          className={`option-line-result-card ${
                            isCorrect
                              ? "highlight-green-result-card"
                              : isSelected && !isCorrect
                              ? "highlight-red-result-card"
                              : ""
                          }`}
                        >
                          <span className="color-dimming-for-the-options-result-card">
                            {String.fromCharCode(65 + i)}.&nbsp;
                          </span>
                          <div>
                            {optionText}
                            {isSelected && (
                              <Chip className="chip-selcted-for-the-answer-selected-from-user" />
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}

                  <div className="add-icon-and-right-icon-placement-result-card">
                    <Chip
                      className="difficulty-level-shown-color-font1-result-card"
                      label={question.DifficultyLevels}
                      style={{
                        backgroundColor:
                          question.DifficultyLevels === "Easy"
                            ? "green"
                            : question.DifficultyLevels === "Medium"
                            ? "orange"
                            : question.DifficultyLevels === "Hard"
                            ? "red"
                            : "default",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="question-tag-div-result-card">
                    <span>{question.Hint}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ResultCard;
