import React, { useState } from "react";
import { baseURL } from "../../http";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { customAlert } from "../SweetAlertCommon/Custom";
import SaveIcon from "@mui/icons-material/Save";
import { TextField,  Button } from "@mui/material";
  import Tooltip from '@mui/material/Tooltip';
import { IconButton} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import UpdateIcon from '@mui/icons-material/Update';

function Stscroll7ITSkillsAddModal({  itData, setITData,
  handleItemDelete, fetchData,setModalStatus,callPercentage
 }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------
  const userEmail = localStorage.getItem("HTES_user_id") || "";

  const [invalidFields, setInvalidFields] = useState({});
  //--------------------------------------------------------------------------------
  // Save Data API
  //--------------------------------------------------------------------------------
  const saveITData = () => {
    const updates = {
      email: userEmail,
      id: itData.id,
      skills: itData.skills,
      version: itData.version,
      lastUsed: itData.lastUsed,
      itExpYear: itData.itExpYear,
      itExpMonth: itData.itExpMonth,
    };

      // Define required fields for validation
  const requiredFields = [
    'skills',
    'version',
    'lastUsed',
    'itExpYear',
    'itExpMonth'
  ];

  // Validate required fields
  const newInvalidFields = {};
  for (const field of requiredFields) {
    if (!itData[field]) {
      newInvalidFields[field] = true;
    }
  }

  if (Object.keys(newInvalidFields).length > 0) {
    setInvalidFields(newInvalidFields);
    return; 
  }

  setInvalidFields({});
  
    const api = itData.id !== 0 ? `updateItSkills/${itData.id}` : 'createITSkills';
    const alert = itData.id !== 0 ? `Edited` : 'Added';
  
    fetch(`${baseURL}/${api}`, {
      method: "POST",
      body: JSON.stringify(updates),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          customAlert("", `Item ${alert}`, "success");
          setModalStatus(false);
          callPercentage();
          console.log("Item updated successfully");
        } else {
          console.error("Failed to update item");
        }
      })
      .then(fetchData())
      .catch((error) => {
        console.error("Item updating error:", error);
      });
  };
  

  return (
    <div>
      <div className="profileModal">
      <div className="pmModalContainer">

        <MDBRow>
          {/* _____________________________ Software Name _______________________ */}
          <MDBCol>
            <label htmlFor="exampleFormControlSelect1" className="pmLabel">
              Skill / software name
            </label>
            <TextField
              fullWidth
              value={itData.skills || ""}
              onChange={(e) =>
                setITData({ ...itData, skills: e.target.value })
              }
              error={invalidFields.skills}
              helperText={invalidFields.skills ? "* mandatory" : ""}
            />
            <br></br>
            <br></br>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {/* _____________________________ Software Version  _______________________ */}
          <MDBCol className="pmModField">
            <label htmlFor="exampleFormControlSelect2" className="pmLabel">
              Software Version
            </label>

            <TextField
              fullWidth
              value={itData.version}
              onChange={(e) =>
                setITData({ ...itData, version: e.target.value })
              }
              error={invalidFields.version}
                helperText={invalidFields.version ? "* mandatory" : ""}
            />
          </MDBCol>
          {/* _____________________________    Last Used     _______________________ */}
          <MDBCol>
            <label htmlFor="exampleFormControlSelect3" className="pmLabel">
              Last Used
            </label>

             <TextField
                    fullWidth
                    value={itData.lastUsed|| ''}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d{0,4}$/.test(inputValue)) {
                        setITData({ ...itData, lastUsed: inputValue });
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      
                      },
                      endAdornment: <InputAdornment position="end">Year</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    error={invalidFields.lastUsed}
                    helperText={invalidFields.lastUsed ? "* mandatory" : ""}
                    />
          </MDBCol>
        </MDBRow>{" "}
        <br></br>
        {/* _____________________________    Total Experience  _______________________ */}
        <div className="">
          <label htmlFor="exampleFormControlSelect2" className="pmLabel">
            Total Experience
          </label>
          <br></br>

          <MDBRow>
            <MDBCol className="pmModField">
              <label htmlFor="exampleFormControlSelect2" className="pmLabel">
                Years
              </label>
              
              <TextField
                    fullWidth
                    value={itData.itExpYear || ''}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setITData({ ...itData, itExpYear: inputValue });
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      },
                      endAdornment: <InputAdornment position="end">Year(s)</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    error={invalidFields.itExpYear}
                    helperText={invalidFields.itExpYear ? "* mandatory" : ""}
                    />
            </MDBCol>
            <MDBCol>
              <label htmlFor="exampleFormControlSelect2" className="pmLabel">
                Months
              </label>
              <TextField
                    fullWidth
                    value={itData.itExpMonth || ''}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && (inputValue) <= 11) {
                        setITData({ ...itData, itExpMonth: inputValue });

                      }
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      
                      },
                      endAdornment: <InputAdornment position="end">Month(s)</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    error={invalidFields.itExpMonth}
                  helperText={invalidFields.itExpMonth ? "* mandatory" : ""}
                    />
           
            </MDBCol>
          </MDBRow>
          <br></br>
        </div>
      </div>

      {/* _____________________________     Save  &  Cancel   _______________________ */}

      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={saveITData}
        >
         {itData.id !== 0 ? (<><UpdateIcon className="buttonicons" /> Update</>
          ) : (
              <><SaveIcon className="buttonicons" /> Save</>
          )}

        </Button>
      </div>
    </div>
    </div>
  );
}

export default Stscroll7ITSkillsAddModal;
