import React, { useState } from "react";
import { MDBCol, MDBRow, MDBRadio,  } from "mdb-react-ui-kit";
import { baseURL } from "../../http";
import { customAlert } from "../SweetAlertCommon/Custom";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from '@mui/material/InputAdornment';
import CustomMultiSelect from "../Templates/MultiSelect/CustomMultiSelect";



function PmTab5EmploymentAddModal({ togglingModal, fetchStudentEmpList,
  employmentData,setEmploymentData, keySkillOptions,ClearAddData,callPercentage
 }) {
  //--------------------------------------------------------------------------------
  //  State Handles
  //--------------------------------------------------------------------------------

  const userEmail = localStorage.getItem("HTES_user_id") || "";
  const [selectedOptionsKeySkills, setSelectedOptionsKeySkills] =useState([]);
  const [invalidFields, setInvalidFields] = useState({});

  const [displayYear, setDisplayYear] = useState(null);
  const [displayMonth, setDisplayMonth] = useState(null);


  //--------------------------------------------------------------------------------
  // Save Data API
  //--------------------------------------------------------------------------------
  const addEmpDetails = () => {
    const sendData = {
        email: userEmail,
        curEmp: employmentData.curEmp,
        empType: employmentData.empType,
        fullExpYears: employmentData.empType === "Full-Time" ? employmentData.fullExpYears : "",
        fullExpMonths: employmentData.empType === "Full-Time"  ? employmentData.fullExpMonths : "",
        currentCompanyname: employmentData.currentCompanyname,
        currentDesignation: employmentData.currentDesignation,
        internshipLocation: employmentData.empType === "Internship" ? employmentData.internshipLocation : "",
        internshipWorkFrom: employmentData.internshipWorkFrom,
        internshipWorkTill: employmentData.internshipWorkTill,
        monthlyStipend: employmentData.empType === "Internship" ? employmentData.monthlyStipend : "",
        fulltimeWorkFrom: employmentData.fulltimeWorkFrom,
        fulltimeWorkTill: employmentData.fulltimeWorkTill,
        jobProfile: employmentData.empType === "Full-Time" ? employmentData.jobProfile : "",
        monthlyStipendCurrency: employmentData.monthlyStipendCurrency,
        skillsUsed: selectedOptionsKeySkills.map((option) => option.value),
        curSal: employmentData.curSal,
        internshipDepartment: employmentData.internshipDepartment,
    };
    console.log('enter')
 // Fields to validate
  // Define base required fields
  let requiredFields = [
    'curEmp',
    'empType',
    'currentCompanyname',
    'currentDesignation',
  ];

  // Conditionally add fields based on empType
  if (employmentData.empType === 'Full-Time') {
    requiredFields = [
      ...requiredFields,
      'fulltimeWorkFrom',
     
    ];
  }
  if (employmentData.empType === 'Internship') {
    requiredFields = [
      ...requiredFields,
      'internshipWorkFrom',
     
    ];
  }
  if (employmentData.empType === 'Internship' && employmentData.curEmp==='No') {
    requiredFields = [
      ...requiredFields,
      'internshipWorkTill',
     
    ];
  }


// Validate required fields
const newInvalidFields = {};
for (const field of requiredFields) {
console.log(!sendData[field])

  if (!sendData[field]) {
    newInvalidFields[field] = true;
  }
}
console.log(Object.keys(newInvalidFields).length)
if (Object.keys(newInvalidFields).length > 0) {
  setInvalidFields(newInvalidFields);
  return; 
}
setInvalidFields({});
    fetch(`${baseURL}/createStudentEmployment`, {
        method: "POST",
        body: JSON.stringify(sendData),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
    .then((response) => {
        console.log('Response status:', response.status); // Log the response status
       if(response.status==200){
        customAlert("", "Item Added", "success");
        ClearAddData();
        callPercentage();
       }
       else{
        customAlert("", "API Error", "warning");
       }
    })
    .catch((error) => {
      console.error('Error caught:', error); // Log the error
      customAlert("", "Server Down !", "warning");

    });
        fetchStudentEmpList();
        togglingModal();
   
};

//--------------------------------------------------------------------------------
//  Calculate the years and month duration (Automatically from datepicker)
//--------------------------------------------------------------------------------
const handleCalcDuration = (startDate, endDate) => {
  // if (!startDate || !endDate) return;
  if (!startDate ) return;

    let start;
    let end;

  if(employmentData.curEmp=='Yes'){
    start = new Date(startDate);
    end = new Date(); // Set end date to today
  }
  else{
    start = new Date(startDate);
    end = new Date(endDate);
  }
console.log(startDate+"---"+endDate)
  let years = end.getFullYear() - start.getFullYear();
  let months = end.getMonth() - start.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }
console.log('years=',start)
console.log('months=',end)
console.log('years=',years)
console.log('months=',months)
  setEmploymentData((prevData) => ({
    ...prevData,
    fullExpYears: years,
    fullExpMonths: months,
  }));

  setDisplayMonth(months);
  setDisplayYear(years);
};

  return (
    <div className="profileModal">
      <h3 className="pmTabHeadings">Add Employment</h3>
      <IconButton 
              id="button-zoom"
              className="modal-close-iconButton-right-top-corner"
              onClick={togglingModal}
            >
              <CloseIcon className="modal-close-iconButton-right-top-corner-symbol" />
            </IconButton>
     
      <div className="pmModalContainer">
        {/* ___________________________________  Current Employment_______________________________ */}
        <MDBRow>
          {/* <label className="pmLabel">Is this your current employment ?</label> */}
          <label className={`pmLabel ${invalidFields.curEmp ? 'error_Pmlabel' : ''}`}>Is this your current employment ?</label>
          <MDBCol  className="pmRadioModal">
             <MDBRadio
              name="cc"
              id="inlineRadio1"
             value="Yes"
              label="Yes"
              inline
              checked={employmentData.curEmp === "Yes"}
              onChange={() => 
                {
                  const currentDate = new Date().toISOString().slice(0, 10);
                  setEmploymentData({ ...employmentData, curEmp: "Yes" })
                  handleCalcDuration(employmentData.fulltimeWorkFrom, currentDate );
                  
                }
              }
            />
            <MDBRadio
              name="cc"
              id="inlineRadio2"
              value="No"
              label="No"
              inline
              checked={employmentData.curEmp === "No"}
              onChange={() => setEmploymentData({ ...employmentData, curEmp: "No" })}
            />
          </MDBCol>
        </MDBRow>
        <br></br>
        {/* ___________________________________   Employment Type _______________________________ */}
        <MDBRow>
          {/* <label className="pmLabel">Employment type</label> */}
          <label className={`pmLabel ${invalidFields.empType ? 'error_Pmlabel' : ''}`}>Employment type</label>

          <MDBCol className="pmRadioModal">
            <MDBRadio
              name="empType"
              id="inlineRadio3"
              value="Full-Time"
              label="Full-Time"
              inline
              checked={employmentData.empType === "Full-Time"}
              onChange={() => setEmploymentData({ ...employmentData, empType: "Full-Time" })}
            />
            <MDBRadio
              name="empType"
              id="inlineRadio4"
              value="Internship"
              label="Internship"
              inline
              checked={employmentData.empType === "Internship"}
              onChange={() => setEmploymentData({ ...employmentData, empType: "Internship" })}
            />
          </MDBCol>
        </MDBRow>
        <br></br>
        

        {/* ___________________________________   Current Company _______________________________ */}
        <MDBRow>
          <MDBCol >
            {employmentData.curEmp === "Yes" ? (
              <label className="pmLabel">Current Company Name</label>
            ) : (
              <label className="pmLabel">Previous Company Name</label>
            )}
            <div >
              <TextField
                fullWidth
                value={employmentData.currentCompanyname || ""}
                onChange={(e) =>
                  setEmploymentData({ ...employmentData, currentCompanyname: e.target.value })
                }
                error={invalidFields.currentCompanyname}
                helperText={invalidFields.currentCompanyname ? "* mandatory" : ""}
             
              />
            </div>
            <br></br>
          </MDBCol>
        </MDBRow>

          {/*_______ Current & Previous Designation _______ */}
          <MDBRow>
              <MDBCol style={{ width: "800px" }}>
                {employmentData.curEmp === "Yes" ? (
                  <label className="pmLabel">Current Designation</label>
                ) : (
                  <label className="pmLabel">Previous Designation</label>
                )}
                <div className="formProfile">
                <TextField
                fullWidth
                value={employmentData.currentDesignation || ""}
                    onChange={(e) =>setEmploymentData({...employmentData,currentDesignation: e.target.value,})}
                    error={invalidFields.currentDesignation}
                    helperText={invalidFields.currentDesignation ? "* mandatory" : ""}
             />
                </div>
                <br></br>
              </MDBCol>
            </MDBRow>


            {/*___________ Joining Date -Full Time - datePic ___________ */}
            {employmentData.empType === "Full-Time" && (
          <div className="">
          <label className="pmLabel">Full-Time</label>
          <br />
          <MDBRow>
          <MDBCol>
            <label className="pmLabel">
              From
              <span style={{ fontSize: '9px' }}>
                {employmentData.curEmp === 'Yes' ? ' (Current Employment)' : ''}
              </span>
            </label>
            <TextField
              required
              type="date"
              fullWidth
              value={employmentData.fulltimeWorkFrom} // YYYY-MM-DD - format required on database
              onChange={(e) => {
                setEmploymentData({ ...employmentData, fulltimeWorkFrom: e.target.value });
                handleCalcDuration(e.target.value, employmentData.fulltimeWorkTill);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  style: { textTransform: "uppercase" },
                  max: employmentData.curEmp === 'Yes'
                    ? new Date().toISOString().split('T')[0]
                    :  employmentData.curEmp === 'No'? 
                    employmentData.fulltimeWorkTill:''
                    
                },
              }}
              error={invalidFields.fulltimeWorkFrom}
              helperText={invalidFields.fulltimeWorkFrom ? '* mandatory' : ''}
            />
            
          </MDBCol>

        
            {/* ___End Date____ */}
            {employmentData.curEmp === 'No' && (
              <MDBCol>
                <label className="pmLabel">Till </label>
                <TextField
                  required
                  type="date"
                  fullWidth
                  value={employmentData.fulltimeWorkTill} // YYYY-MM-DD - format required on database
                  onChange={(e) => {
                    setEmploymentData({ ...employmentData, fulltimeWorkTill: e.target.value });
                    handleCalcDuration(employmentData.fulltimeWorkFrom, e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { 
                      style: { textTransform: "uppercase" },
                      min: employmentData.fulltimeWorkFrom,
                      max: new Date().toISOString().split('T')[0],
                     }, // Ensure the start date cannot be after the end date
                  }}
                />
              </MDBCol>
            )}
          </MDBRow>
          <br />
        </div>
        
          )}
        {/* ___________________________________   Employment Experience __________________________ */}
            {employmentData.empType === "Full-Time" && (
        <div className="">
            <label className="pmLabel">Total Experience</label>
            <MDBRow>
              <MDBCol>
                <label className="pmLabel">Years </label>
                <TextField
                    fullWidth
                    disabled
                    value={displayYear}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      
                      },
                      endAdornment: <InputAdornment position="end">Year(s)</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    />
              </MDBCol>
              <MDBCol>
                <label className="pmLabel">Months</label>
                <TextField
                    fullWidth
                    disabled
                    value={displayMonth}
                    
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'left' }, // Align text to the right
                      
                      },
                      endAdornment: <InputAdornment position="end">Month(s)</InputAdornment>,
                      inputComponent: 'input', 
                    }}
                    />
              </MDBCol>
            </MDBRow>
            <br></br>
          </div>
            )}

        
        {/* ___________________________________   Designation, fulltime_DatePic join ____________________________________ */}

        {employmentData.empType === "Full-Time" ? (
          <>
          
            
         
            {/*___________ Current Salary -(Input)  ___________ */}
            <MDBRow>
              <MDBCol >
                <label className="pmLabel">Current Salary</label>
                <div className="formProfile">
                  <TextField
                fullWidth
                value={employmentData.curSal || ""}
                    onChange={(e) =>{
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setEmploymentData({ ...employmentData, curSal: inputValue })
                      }
                    }}
                   
              />
                </div>
                <br></br>
              </MDBCol>
            </MDBRow>

            {/*_______ Skills used -(Multi select input) + Component Call______ */}
            <MDBRow>  
              <MDBCol style={{ width: "800px" }}>
                <label className="pmLabel">Skills Used 
                  </label>

                      {/* ______ Component Call with Props____ */}
                  <CustomMultiSelect
                   options={keySkillOptions}
                   selectedOption={selectedOptionsKeySkills}
                   setSelectedOption={setSelectedOptionsKeySkills}
                   chipClassName="jobPostLocationtag"
                   chipDeleteClassName="jobPostLocationDeleteX"
                   placeholder="Type to Select KeySkill(s)"
                  />
                <br></br>
              </MDBCol>
            </MDBRow>
          </>
        ) : (
          <>
            {/* ___________________________________  Location (internship) - input _______________ */}
            {employmentData.empType === "Internship" && (
              <MDBRow>
                <MDBCol style={{ width: "800px" }}>
                  <label className="pmLabel">Location</label>
                  <div className="formProfile">
                  <TextField
                fullWidth
                value={employmentData.internshipLocation || ""}
                onChange={(e) =>
                  setEmploymentData({ ...employmentData, internshipLocation: e.target.value })
                }
              />
               
                  </div>
                  <br></br>
                </MDBCol>
              </MDBRow>
            )}

            {/* ___________________________________  Department (internship) - input ______________ */}
            <MDBRow>
              <MDBCol style={{ width: "800px" }}>
                <label className="pmLabel">Department</label>
                <div className="formProfile">
                <TextField
                fullWidth
                value={employmentData.internshipDepartment || ""}
                onChange={(e) =>
                  setEmploymentData({ ...employmentData, internshipDepartment: e.target.value })
                }
              />
                </div>
                <br></br>
              </MDBCol>
            </MDBRow>

            {/* ________________________________ WorkingFrom (internship) - dropdown ______________ */}
            <div>
              <label className="pmLabel">Internship  </label>
              <br></br>
              <MDBRow>
                <MDBCol>
              <label className="pmLabel">From 
              <span style={{fontSize:'9px'}}>
                {(employmentData.curEmp=='Yes' ? 
                  " (Current Employment)" :''
                )}
                </span>
              </label>
              <TextField
              required
              type="date"
              fullWidth
              value={employmentData.internshipWorkFrom} // YYYY-MM-DD - format required on database
              onChange={(e) =>{
                setEmploymentData({...employmentData,internshipWorkFrom: e.target.value,})
              }
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { 
                  style: { textTransform: "uppercase" },
                  max: employmentData.curEmp === 'Yes'
                  ? new Date().toISOString().split('T')[0]
                  :  employmentData.curEmp === 'No'? 
                  employmentData.internshipWorkFrom:''
                  
              },
            }}
              error={invalidFields.internshipWorkFrom}
              helperText={invalidFields.internshipWorkFrom ? "* mandatory" : ""}
            />
                </MDBCol>

                {employmentData.curEmp === "No" && (
                <MDBCol>
              <label className="pmLabel">Till </label>
                 <TextField
              required
              type="date"
              fullWidth
              // value={'2024-07-08'}   // YYYY-MM-DD - format required on database
              value={employmentData.internshipWorkTill}
              onChange={(e) =>{
                setEmploymentData({...employmentData,internshipWorkTill: e.target.value,})
              }
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { style: { textTransform: "uppercase" },
                min: employmentData.internshipWorkFrom,
                max: new Date().toISOString().split('T')[0],
              }, 
              }}


              error={invalidFields.internshipWorkTill}
              helperText={invalidFields.internshipWorkTill ? "* mandatory" : ""}
            />
                </MDBCol>
                )}
              </MDBRow>
            </div>
            <br></br>
          </>
        )}


        {/* ________________________________       Job profile         _________________________ */}
        {employmentData.empType === "Full-Time" && (
          <MDBRow>
            <MDBCol className="empJobProfile">
              <label className="pmLabel">Job profile</label>
                
                <TextField  fullWidth value={employmentData.jobProfile || ""}
                onChange={(e) => setEmploymentData({ ...employmentData, jobProfile: e.target.value })}
                multiline
                // rows={3}
                minRows={3}
                maxRows={8}
                InputProps={{
                  className: 'PPEmpJobProfilecustomInput', 
                }}
                />
                

                
             
            </MDBCol>
          </MDBRow>
        )}

        {/* ________________________________       Monthly Stipend         ______________________ */}
        {employmentData.empType === "Internship" && (
          <MDBRow>
            <MDBCol>
              <label className="pmLabel">Stipend</label>
              <div className="formProfile">
              <TextField
                fullWidth
                value={employmentData.monthlyStipend || ""}
                onChange={(e) =>{
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue)) {
                    setEmploymentData({ ...employmentData, monthlyStipend: inputValue })
                  }
                }}
              />
              </div>
              <br></br>
            </MDBCol>
          </MDBRow>
        )}
      </div>

        {/* ________________________________ Save  ______________________________________________ */}
      <div className="createteslayoutmodel6">
        <Button
          id="button-zoom"
          variant="contained"
          color="success"
          onClick={addEmpDetails}
        >
          <SaveIcon className="buttonicons" />
          Save
        </Button>
      </div>
      
    </div>
  );
}

export default PmTab5EmploymentAddModal;
